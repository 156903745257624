import React, { useState, useEffect } from 'react';
import DatePicker, { CalendarContainer } from "react-datepicker";
import Editicon from '../images/icons/edit-bg.png';
import Tick from '../images/icons/tick.png';
import Close from '../images/icons/close.png';
import newStock from '../images/icons/newStock.png';
import { callingAPI } from '../config';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import NoData from '../Common/noDataFound';
import { Redshift } from 'aws-sdk';
import moment from 'moment';
import Loader from '../Common/loader';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function HospitalInventory() {
	const navigate = useNavigate();

	const [filterName, setFilterInput] = useState("");
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [hospitalInventoryList, setHospitalInventoryListData] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [count, setCount] = useState('');
	const [pageOffset, setPageOffset] = useState(0);
	const [dataFlag, setDataFlag] = useState(false);
	const [CategoryData, setCategoryData] = useState([])
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");

	var date = new Date();
	var requiredDate = moment(date).format('DD-MM-YYYY');
	var toDay = requiredDate.split('-');
	toDay = new Date(toDay[2], toDay[1] - 1, toDay[0]);

	const statusChange = (id, status) => {
		var x = false;
		if (status === true) {
			x = window.confirm('Do you want to Enable this?');
		} else {
			x = window.confirm('Do you want to Disable this?');
		}
		if (x === true) {
			const satusData = {
				pmHospitalMedicineId: id,
				status: status == false ? false : true,
				userId: window.sessionStorage.getItem('userId'),
			};

			callingAPI('inventoryStockUpdate/inventoryStockUpdateStatusChange', satusData).then((data) => {
				if (data.data.success === '1') {
					alert('Status Changed Successfully');
					inventorylisting();
				}
				if (data.data.success === '0') {
					alert(data.data.errorMessage);
				}
			});
		}
	};

	const MyContainerFromDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const MyContainerToDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const FromDateClear = () => {
		setStartDate("")

	};

	const ToDateClear = () => {
		setEndDate("")

	};

	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}
	const newBatchInventoryClick = (data) => {
		navigate('/settings/newBatchInventory', { state: data })
	};
	const handlePageChange = (pageNumber) => {
		//	setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) { document.getElementById('selectName').value = "" }
		if (!booliansearchValue) { document.getElementById('searchText').value = "" }
		if (!boolianfromDate) { setStartDate("") }
		if (!booliantoDate) { setEndDate("") }
		window.sessionStorage.setItem("pageNumberInventoryDetails", pageNumber);
		window.sessionStorage.setItem("offsetInventoryDetails", offset);
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: max,
			offset: offset,
			searchId: document.getElementById('selectName').value,
			searchValue: document.getElementById('searchText').value,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
			type: '',
		};

		callingAPI('inventoryStockUpdate', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setHospitalInventoryListData(data.data.result.inventoryList);
					setCount(data.data.result.totalCount);
				}
				else {
					setHospitalInventoryListData([]);
					setCount([]);
				}
			})
			.catch();
	};
	const changeText = (value) => {
		setFilterInput(value);
		if (document.getElementById('selectName').value !== '') {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		} else {
			document.getElementById('searchText').disabled = true;
		}
		document.getElementById('searchText').value = '';
	};
	const searchValueChange = () => {
		if (startDate && endDate) {
			if (startDate > endDate) {
				toast.error('End date must be greater than start date');
				document.getElementById("todate").focus();
				return false;
			}
		}
		if (document.getElementById('selectName').value !== '' && document.getElementById('searchText').value === '') {
			var t = document.getElementById('selectName');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('searchText').focus();
			toast.error('Enter ' + selectedText);
			return false;
		}
		setActivePage(1);
		setbooliansearchValue(document.getElementById('searchText').value);
		setboolianfilterName(document.getElementById('selectName').value);
		setboolianfromDate(startDate);
		setbooliantoDate(endDate);
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: 0,
			searchId: document.getElementById('selectName').value,
			searchValue: document.getElementById('searchText').value,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
			type: '',
		};
		callingAPI('inventoryStockUpdate', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setHospitalInventoryListData(data.data.result.inventoryList);
					setCount(data.data.result.totalCount);
					setPageOffset(0);
				}
				else {
					setHospitalInventoryListData([]);
					setCount([]);
				}
			})
			.catch();
	};
	const editInventoryClick = (data) => {
		navigate('/settings/editInventory', { state: data })
	}
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}
	useEffect(() => {
		categoryDropDownApi();
		//	changeText();
		inventorylisting();
	}, []);
	const categoryDropDownApi = () => {
		callingAPI('inventoryStockUpdate/categoryListing',
		).then((response) => {
			if (response.data.success === "1") {
				setCategoryData(response.data.result.categoryList)
			}
		}
		)
			.catch()
	}
	const inventorylisting = () => {
		setActivePage(Number(window.sessionStorage.getItem("pageNumberInventoryDetails")));
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: window.sessionStorage.getItem("offsetInventoryDetails"),
			type: '',
			searchId: document.getElementById('selectName').value,
			searchValue: document.getElementById('searchText').value,
		};

		callingAPI('inventoryStockUpdate', PostData)
			.then((data) => {
				if (data.data.success === "1") {
					setHospitalInventoryListData(data.data.result.inventoryList);
					setCount(data.data.result.totalCount);
					setDataFlag(true);
				}
				else {
					setHospitalInventoryListData([]);
					setCount(0);
					setDataFlag(true)
				}
			})
			.catch();
	};
	const enterKey = (e) => {
		if (e.key === 'Enter') {
			searchValueChange();
		}
	}
	return (
		<div className="dashboardCover">
			<ToastContainer position="bottom-right" />
			<div className="row">
				<div className="col-md-9">
					<div className="dashboardTitle">Inventory Stock Update</div>
				</div>
				<div className="col-md-3 dflexend">
					<a
						href="/settings/addInventory"
						style={{ color: '#393939', textDecoration: 'none', width: '215px', padding: '6px 5px' }}
					>
						{' '}
						<span className="dashButtonBorder">Add Inventory</span>
					</a>
				</div>
			</div>
			{count > 10 && (
				<div className="paginationSection">
					<Pagination
						// hideNavigation
						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePageChange}
					/>
				</div>
			)}
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select
									autocomplete="off"
									className="input-design inputField"
									id="selectName"
									name="selectName"
									onChange={(e) => changeText(e.target.value)}
								>
									<option value="">Select Category</option>

									{CategoryData.map((item, i) => (
										<option value={item.id}>{item.categoryName}</option>
									))}
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<input
									autocomplete="off"
									className="input-design inputField"
									type="text"
									id="searchText"
									name="searchText"
									placeholder="Enter Item Name"
									disabled={filterName === "" ? true : false}
									style={{ background: filterName === "" ? "transparent" : "white" }}
									onKeyDown={enterKey}
								/>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<DatePicker
									required
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									className="Datepicker pa2 inputField  "
									placeholderText="Expiry From Date"
									calendarClassName="rasta-stripes"
									selected={startDate}
									dateFormat="dd-MM-yyyy"
									autoComplete='off'
									onChange={(date) => setStartDate(date)}
									id="fromdate"
									onChangeRaw={handleDateChangeRaw}
									calendarContainer={MyContainerFromDate}
								/>{' '}
								<label
									className="icon iconCalendar calendar plcalendar"
									style={{ fontSize: '16px' }}
									htmlFor="fromdate"
								>
									<FontAwesomeIcon icon={faCalendarAlt} />
								</label>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<DatePicker
									required
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									className="Datepicker pa2 inputField"
									// maxDate={new Date()}
									placeholderText="Expiry To Date"
									calendarClassName="rasta-stripes"
									selected={endDate}
									dateFormat="dd-MM-yyyy"
									autoComplete='off'
									onChange={(date) => setEndDate(date)}
									id="todate"
									onChangeRaw={handleDateChangeRaw}
									calendarContainer={MyContainerToDate}
								/>{' '}
								<label
									className="icon iconCalendar calendar plcalendar"
									style={{ fontSize: '16px' }}
									htmlFor="todate"
								>
									<FontAwesomeIcon icon={faCalendarAlt} />
								</label>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<button

									className="dashButtonBorder" onClick={searchValueChange} style={{ width: '100%' }}>
									Search
								</button>
							</div>
						</div>
					</div>
					{dataFlag == false ? (
						<Loader />
					) : hospitalInventoryList.length > 0 ? (
						<div className="dashboardtable">
							<table>
								<thead>
									<tr>
										<th>Category</th>
										<th style={{ width: "164px" }}>  Name</th>
										<th>Batch No</th>
										<th style={{ width: "65px", textAlign: "center" }}>Qty</th>
										<th style={{ textAlign: "right" }} >MRP</th>
										<th style={{ width: "150px", textAlign: "right" }}> MRP Unit Price</th>
										<th style={{ textAlign: "right" }}>Price</th>
										<th style={{ textAlign: "right" }} >Unit Price</th>
										<th style={{ width: "103px" }}>Expiry Date</th>
										<th style={{ width: "100px" }}>Actions</th>
									</tr>
								</thead>
								<tbody>
									{hospitalInventoryList.map((item, index) => (
										<tr>
											<td>
												<span title={item.hospitalInventoryCategoryName}
													className="refundReason elipsis" style={{ width: "100%" }}>
													{item.hospitalInventoryCategoryName}
												</span>
											</td>
											{item.hospitalInventoryCategoryId == 1 ? (
												<td>
													<span title={item.medicine + "-" + item.medicineTypeName + "-" + item.medicineStrength + "-" + item.medicineUnitName}
														className="refundReason elipsis" style={{ width: "100%" }}>{item.medicine + "-" + item.medicineTypeName + "-" + item.medicineStrength + "-" + item.medicineUnitName}</span>
												</td>
											) : (
												<td>
													<span title={item.hospitalInventoryMasterName}
														className="refundReason elipsis" style={{ width: "100%" }}>
														{item.hospitalInventoryMasterName}
													</span>
												</td>
											)}

											<td>
												<span title={item.pmHospitalMedicineBatchNo}
													className="refundReason elipsis">
													{item.pmHospitalMedicineBatchNo}
												</span>
											</td>

											<td style={{ textAlign: "center" }}
												className={
													parseFloat(item.pmHospitalMedicineThresholdNo) >=
														parseFloat(item.pmHospitalMedicineStockQty) ? (
														'class-red'
													) : (
														''
													)
												}
											>
												<span title={item.pmHospitalMedicineStockQty}
													className="refundReason elipsis" style={{ textAlign: "center", width: "100%" }}>
													{' '}
													{item.pmHospitalMedicineStockQty}
												</span>
											</td>
											<td style={{ textAlign: "right" }} >
												<span title={item.pmHospitalMedicineMrp} style={{ textAlign: "right" }} >
													&#8377; {item.pmHospitalMedicineMrp}
												</span>
											</td>
											<td style={{ textAlign: "right" }} >
												<span title={item.pmHospitalMedicineMrpUnitPrice} style={{ textAlign: "right" }}
												>
													&#8377; {item.pmHospitalMedicineMrpUnitPrice}
												</span>
											</td>
											<td style={{ textAlign: "right" }} >
												<span title={item.pmHospitalMedicinePrice} style={{ textAlign: "right" }}
												>
													&#8377; {item.pmHospitalMedicinePrice}
												</span>
											</td>
											<td style={{ textAlign: "right" }} >
												<span title={item.pmHospitalMedicineUnitPrice}
													style={{ textAlign: "right" }} >
													&#8377; {item.pmHospitalMedicineUnitPrice}
												</span>
											</td>
											<td
												title={item.pmHospitalMedicineExpiryDate}
												className={
													new Date(
														parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[2]),
														parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[1] - 1),
														parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[0])
													) <= toDay ? (
														'class-red'
													) : (
														''
													)
												}
											>
												{item.pmHospitalMedicineExpiryDateNew}
											</td>
											<td style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
												<nav onClick={() => editInventoryClick({
													pmhospitalMedicineId: item.pmHospitalMedicineId
												})}
												>
													<img src={Editicon} title="Edit" />
												</nav>
												{item.pmHospitalMedicineIsActive === 1 ? (
													<img
														src={Tick}
														title="Click to Deactivate"
														onClick={() => statusChange(item.pmHospitalMedicineId, false)}
														style={{ width: '20px' }}
													/>
												) : (
													<img
														src={Close}
														title="Click to Activate"
														onClick={() => statusChange(item.pmHospitalMedicineId, true)}
														style={{ width: '20px' }}
													/>
												)}
												<nav onClick={() => newBatchInventoryClick({ pmhospitalMedicineId: item.pmHospitalMedicineId })}

												>
													<img src={newStock} style={{ marginLeft: '6px' }} title="Add New Batch" />
												</nav>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<p>
							<NoData />
						</p>
					)}
				</div>
			</div>
			{count > 10 && (
				<div className="paginationSection">
					<Pagination
						// hideNavigation
						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePageChange}
					/>
				</div>
			)}
		</div>
	);
}

export default HospitalInventory;
