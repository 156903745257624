import React, { useState, useEffect } from "react";
import { callingAPI } from "../config";
import Datepicker from "react-datepicker";
import moment from "moment";
import AWS from "aws-sdk";
import girl from "../images/media/noimageGirl.png";
import boy from "../images/media/noimageBoy.png";
import WebcamCapture from "./webcam";
import { useParams } from "react-router";
import Loader from "../Common/loader";
import { get } from "jquery";
import Add from "../images/icons/Add_img.png";
import Delete from "../images/icons/inactive.png";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var s3;
var age2;
var getS3folder =
  process.env.REACT_APP_S3_URI +
  process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
  process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;
function Profile(props) {
  let { showEdit } = useParams();
  const [state, setState] = useState({
    gender: "",
    memberImageName: "",
    memberIsSmsAlert: "",
    memberIsEmailAlert: "",
  });
  const [getBloodGroup, setBloodGroup] = useState("");
  const [getCAD, setCAD] = useState("");
  const [getDyslipidemia, setDyslipidemia] = useState("");
  const [getDiabetes, setDiabetes] = useState("");
  const [getPatientGender, setPatientGender] = useState("");
  const [getCurrently, setCurrently] = useState();
  const [getDob, setDob] = useState("");
  const [getMens, setMens] = useState("");
  const [getMens1, setMens1] = useState("");
  const [getAge, setAge] = useState("");
  const [getAge1, setAge1] = useState("");
  const [abc, setAbc] = useState({});
  const [getHypertension, setHypertension] = useState("");
  const [getLivebirth, setLivebirth] = useState("");
  const [getCsection, setCsection] = useState("");
  const [getStillbirth, setStillbirth] = useState("");
  const [getTerminated, setTerminated] = useState("");
  const [getMiscarriage, setMiscarriage] = useState("");
  const [getCompleted, setCompleted] = useState("");
  const [getZipCode, setZipCode] = useState("");
  const [getFirstName, setFirstName] = useState("");
  const [getLastName, setLastName] = useState("");
  const [getMrNo, setMrNo] = useState("");
  const [getPrNo, setPrNo] = useState("");
  const [getPrNoSet, setPrNoSet] = useState("");
  const [getAdhaarId, setAdhaarId] = useState("");
  const [getPrevPregDetail, setPrevPregDetail] = useState("");
  const [getPrevPregComp, setPrevPregComp] = useState("");
  const [getMedicalProb, setMedicalProb] = useState("");
  const [getCurrentcondition, setCurrentcondition] = useState("");
  const [getMobileNo, setMobileNo] = useState("");
  const [getPhoneNo, setPhoneNo] = useState("");
  const [getAddress, setAddress] = useState("");
  const [getEmailId, setEmailId] = useState("");
  const [getAreaCode, setAreaCode] = useState('+91');
  const [getcity1, setCity1] = useState("");
  const [getState1, setState1] = useState("");
  const [getCountry1, setCountry1] = useState("");
  const [patientDOB, setPatientDOB] = useState("");
  const [lastmens, setLastmens] = useState("");
  const [lastmens1, setLastmens1] = useState("");
  const [smsAlertCheck, setSmsAlertCheck] = useState(false);
  const [pregSmsAlertCheck, setPregSmsAlertCheck] = useState(false);
  const [showEditDiv, setShowEditDiv] = useState(false);
  const [emailAlertCheck, setEmailAlertCheck] = useState(false);
  const [pregEmailAlertCheck, setPregEmailAlertCheck] = useState(false);
  //	const [ getAge, setAge ] = useState('');
  const [nodata] = useState("No Data Found");
  const [display, setDisplay] = useState(false);
  const [freeArticleData1, setFreeArticleData1] = useState([]);
  const [file, setFile] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [capturedData, setData] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [isData] = useState(0);
  const [image, setImage] = useState("");
  const [base64, setBase64] = useState("");
  const [web, setWeb] = useState("");
  const [getData] = useState(props);
  const [display1, setDisplay1] = useState(false);
  const [load, setLoad] = useState(false);
  const [freeArticleData12, setFreeArticleData12] = useState([]);
  const [freeArticleData123, setFreeArticleData123] = useState([]);
  const [freeArticleData1234, setFreeArticleData1234] = useState([]);
  const [memberName122, setMemberName122] = useState("");
  const [memberName1223, setMemberName1223] = useState("");
  const [addValue1, setAddValue1] = useState([]);
  const [getLanguage, setLanguage] = useState([]);
  const [getPregnancyDetails, setPregnancyDetails] = useState([]);
  const [getChildDetails, setChildDetails] = useState([]);
  const [name, setName] = useState("");
  const [childDOB, setChildDOB] = useState("");
  const [pregVaccine, setPregVaccine] = useState(0);
  const [disposableitemsaddedarr, setDisposableItemsAddedArr] = useState([]);
  const [getLangList, setLangList] = useState([]);
  const [balanceAmount, setBalanceAmount] = useState('');
  // const [ quantity, setQuantity ] = useState('');
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    region: "us-east-1",
  });
  var bucketParams = {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
  };
  s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
  const abcA = (a) => {
    if (a === "true") {
      setState({
        isData: 0,
      });
    }
    setWeb(a)
  };
  const isMrNo = (event) => {
    setMrNo(event.target.value);
  };
  const isPrNo = (event) => {

    setPrNo(event.target.value);
  };

  const isSMSChange = (event) => {
    setSmsAlertCheck(event.target.checked);
  };
  const isPregSMSChange = (event) => {
    setPregSmsAlertCheck(event.target.checked);
  };
  const isEmailChange = (event) => {
    setEmailAlertCheck(event.target.checked);
  };
  const isPregEmailChange = (event) => {
    setPregEmailAlertCheck(event.target.checked);
  };
  const changeImage = () => {
    setDisplay(true);
    setDisplay1(true);
    setState({
      isData: 0,
    });
  };
  const add = () => {
    setState({
      isData: 1,
    });
    setDisplay1(false);
  };
  const cancelUpdate = () => {
    // setShowEditDiv(false)
    window.location.href = "/patients/patientconsult";
  };
  const ageChange12 = (val) => {
    setAge("");
  };
  const ageChange1 = (val) => {
    // if(val!==getAge){
    if (getDob !== "") {
      setPatientDOB("");
    } else {
      setPatientDOB(dateOfBirth);
    }
    setAge(val);
    // + 5.256e+9
    var age = document.getElementById("getAge").value * 31556926000; //in Millis
    // -8.64e+7
    var dateOfBirth = new Date(new Date().getTime() - age);
    if (
      (0 == dateOfBirth.getFullYear() % 4 && 0 != dateOfBirth.getFullYear() % 100) || 0 == dateOfBirth.getFullYear() % 400) {

      setPatientDOB(dateOfBirth - 8.64e+7);
    } else {

      setPatientDOB(dateOfBirth - 8.64e+7);
    }
    //	}
  };
  const mensChange = (date) => {
    var b = new Date(date).getTime()
    var c = b + 2.628e+9 * 10
    setLastmens(date);
    setLastmens1(c);
  };
  const mensChange1 = (date) => {
    var b = new Date(date).getTime()
    var c = b - 2.628e+9 * 9
    setLastmens1(date);
    setLastmens(c)
  };
  const ageChange = (date) => {
    setPatientDOB(date);

    var dateString = moment(date).format("MM/DD/YYYY");
    var now = new Date();
    var today = new Date(now.getYear(), now.getMonth(), now.getDate());
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();
    var dob = new Date(
      dateString.substring(6, 10),
      dateString.substring(0, 2) - 1,
      dateString.substring(3, 5)
    );
    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearString = "";
    var monthString = "";
    var dayString = "";
    var yearAge = yearNow - yearDob;
    if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
    else {
      yearAge--;
      var monthAge = 12 + monthNow - monthDob;
    }
    if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
    else {
      monthAge--;
      var dateAge = 31 + dateNow - dateDob;
      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge,
    };
    // if (age.years > 1) yearString = " years";
    // else yearString = " year";
    // if (age.months > 1) monthString = " months";
    // else monthString = " month";
    // if (age.days > 1) dayString = " days";
    // else dayString = " day";
    // if (age.years > 0 && age.months > 0 && age.days > 0)
    //   ageString =
    //     age.years +
    //     yearString +
    //     ", " +
    //     age.months +
    //     monthString +
    //     ", and " +
    //     age.days +
    //     dayString +
    //     " old.";
    // else if (age.years == 0 && age.months == 0 && age.days > 0)
    //   ageString = age.days + dayString + " old";
    // //   ageString = "Only " + age.days + dayString + " old";
    // else if (age.years > 0 && age.months == 0 && age.days == 0)
    //   ageString = age.years + yearString + " old";
    // else if (age.years > 0 && age.months > 0 && age.days == 0)
    //   ageString =
    //     age.years + yearString + " and " + age.months + monthString + " old.";
    // else if (age.years == 0 && age.months > 0 && age.days > 0)
    //   ageString =
    //     age.months + monthString + " and " + age.days + dayString + " old.";
    // else if (age.years > 0 && age.months == 0 && age.days > 0)
    //   ageString =
    //     age.years + yearString + " and " + age.days + dayString + " old.";
    // else if (age.years == 0 && age.months > 0 && age.days == 0)
    //   ageString = age.months + monthString + " old.";
    // else ageString = "1 day old.";
    if (age.years === 1) {
      setAge(age.years + " " + "year")
    }
    else if (age.years > 1) {
      setAge(age.years + " " + "years")
    } else if (age.years === 0 && age.months === 1) {
      setAge(age.months + " " + "month");
    } else if (age.years === 0 && age.months > 1) {
      setAge(age.months + " " + "months");
    } else if (age.years === 0 && age.months === 0 && age.days === 1) {
      setAge(age.days + " " + "day");
    } else if (age.years === 0 && age.months === 0 && age.days > 1) {
      setAge(age.days + " " + "days");
    }

  };
  const handleImageUploadOnChange = (e) => {
    setData({});
    setImage("");
    document.getElementById("imageThumb1").style.display = "none";
    setFile(e.target.files[0]);
    setImageName(e.target.files[0].name.replace(/ /g, "-"));
    setImagePreview(URL.createObjectURL(e.target.files[0]));

    // setDisplay1(false);
  };
  const webCamImageFunction = (image, base64) => {
    setImage(image);
    setFile('')
    setImageName('')
    setImagePreview(base64)
    setBase64(base64);
    setState({
      isData: 0,
    });
    document.getElementById("imageThumb1").style.display = "none";
  };
  const imageS3Save = (e) => {
    if (file !== "") {
      var S3BucketMainFolder =
        process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
        process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;
      var fullFilePath = S3BucketMainFolder + "/" + imageName;
      s3.upload(
        {
          Key: fullFilePath,
          Body: file,
          ACL: "public-read",
        },
        (err, data) => {
          if (err) {
            toast.error("There was an error uploading your photo: " + err.message);
            return null;
          } else {
            // alert("Profile Uploaded Successfully")
          }
        }
      );
    }
    else if (Object.keys(capturedData).length !== 0) {
      s3.putObject(capturedData, (err, data) => {
        if (err) {
          toast.error("There was an error uploading your photo: " + err.message);
        } else {
          // alert("File Uploaded ")
          // alert("Profile Uploaded Successfully")
        }
      });
    }
  };
  const saveMemberDetails = async () => {
    setLoad(false);
    if (file != "" || image != "") {
      await imageS3Save();
    }
    await saveMemberDetails1();
    //await costAndQuantityCalculation(j)
  };
  const saveMemberDetails1 = () => {


    var memberAge = "";
    if (patientDOB === "") {
      if (getDob != "") {
        var fromDateSplit = getDob.split("/");
        fromDateSplit =
          fromDateSplit[2] + "-" + fromDateSplit[1] + "-" + fromDateSplit[0];
        memberAge = fromDateSplit;
      }
      else {
        memberAge = "";
      }
    } else {
      memberAge = moment(patientDOB).format("YYYY-MM-DD");
    }
    var lastMenDate = "";
    if (lastmens === "") {
      var fromDateSplit = getMens.split("-");
      fromDateSplit =
        fromDateSplit[2] + "-" + fromDateSplit[1] + "-" + fromDateSplit[0];
      lastMenDate = fromDateSplit;
    } else {
      lastMenDate = moment(lastmens).format("YYYY-MM-DD");
    }
    var lastMenDate1 = "";
    if (lastmens1 === "") {
      var fromDateSplit = getMens1.split("-");
      fromDateSplit =
        fromDateSplit[2] + "-" + fromDateSplit[1] + "-" + fromDateSplit[0];
      lastMenDate1 = fromDateSplit;
    } else {
      lastMenDate1 = moment(lastmens1).format("YYYY-MM-DD");
    }

    var langData = [];
    if (addValue1.length === 0) {
      // memberName1223
      if (memberName122 !== "0" || 0) {
        langData.push(memberName122);
      }
    } else if (memberName122.length === 0) {
      for (var i = 0; i < addValue1.length; i++) {
        var y = addValue1[i].id;
        langData.push(y);
        // langData.push(memberName1223)
      }
    } else if (addValue1.length > 0 && memberName122.length !== 0) {
      for (var i = 0; i < addValue1.length; i++) {
        var y = addValue1[i].id;
        langData.push(y);
      }
      if (memberName122 !== "0" || 0) {
        langData.push(memberName122);
      }
    }
    // var fromDateSplit1 = disposableitemsaddedarr[i]. childNameDob.split('-')
    // fromDateSplit1 = fromDateSplit[2] + "-" + fromDateSplit[1] + "-" + fromDateSplit[0]
    // disposableitemsaddedarr[i].childNameDob = fromDateSplit1
    // if(disposableitemsaddedarr.length>0){
    // if(document.getElementById("txtchdName1").value!==""&&document.getElementById("txtchdDOB").value!==""){
    // 	var child = [];
    // 	var b = {

    // 		"childName":document.getElementById("txtchdName1").value,
    // 		// "childDateOfBirth":moment(disposableitemsaddedarr[i].childNameDob).format("YYYY-MM-DD"),
    // 		// "childDateOfBirth":disposableitemsaddedarr[i].childNameDob,
    // 		"childDateOfBirth":document.getElementById("txtchdDOB").value,
    // 		"hasVaccinationPlanner":pregVaccine
    // 	}
    // 	child.push(b);
    // }else
    if (document.getElementsByClassName("PregnancyProfile").length > 0) {
      if (window.sessionStorage.getItem(
        "medicineBranchId"
      ) !== "4") {
        if (
          document.getElementById("txtchdName1").value !== "" &&
          document.getElementById("txtchdDOB").value !== "" &&
          disposableitemsaddedarr.length > 0
        ) {
          var child = [];
          for (var i = 0; i < disposableitemsaddedarr.length; i++) {
            // var c=moment(disposableitemsaddedarr[i].childNameDob).format("YYYY-MM-DD")
            var a = {
              childName: disposableitemsaddedarr[i].childNamelist,
              // "childDateOfBirth":moment(disposableitemsaddedarr[i].childNameDob).format("YYYY-MM-DD"),
              // "childDateOfBirth":disposableitemsaddedarr[i].childNameDob,
              childDateOfBirth: document.getElementById("childNameDob" + i).value,
              hasVaccinationPlanner: disposableitemsaddedarr[i].childNameVaccine,
            };
            child.push(a);
          }
          var b = {
            childName: document.getElementById("txtchdName1").value,
            // "childDateOfBirth":moment(disposableitemsaddedarr[i].childNameDob).format("YYYY-MM-DD"),
            // "childDateOfBirth":disposableitemsaddedarr[i].childNameDob,
            childDateOfBirth: moment(
              document.getElementById("txtchdDOB").value
            ).format("YYYY-MM-DD"),
            hasVaccinationPlanner: pregVaccine,
          };
          child.push(b);
        } else if (disposableitemsaddedarr.length > 0) {
          var child = [];
          for (var i = 0; i < disposableitemsaddedarr.length; i++) {
            // var c=moment(disposableitemsaddedarr[i].childNameDob).format("YYYY-MM-DD")
            var c = {
              childName: disposableitemsaddedarr[i].childNamelist,
              // "childDateOfBirth":moment(disposableitemsaddedarr[i].childNameDob).format("YYYY-MM-DD"),
              // "childDateOfBirth":disposableitemsaddedarr[i].childNameDob,
              childDateOfBirth: document.getElementById("childNameDob" + i).value,
              hasVaccinationPlanner: disposableitemsaddedarr[i].childNameVaccine,
            };
            child.push(c);
          }
        } else if (
          disposableitemsaddedarr.length === 0 &&
          document.getElementById("txtchdName1").value !== "" &&
          document.getElementById("txtchdDOB").value !== ""
        ) {
          child = [];
          var b = {
            childName: document.getElementById("txtchdName1").value,
            // "childDateOfBirth":moment(disposableitemsaddedarr[i].childNameDob).format("YYYY-MM-DD"),
            // "childDateOfBirth":disposableitemsaddedarr[i].childNameDob,
            childDateOfBirth: moment(
              document.getElementById("txtchdDOB").value
            ).format("YYYY-MM-DD"),
            hasVaccinationPlanner: pregVaccine,
          };
          child.push(b);
        } else {
          child = [];
        }
      }
    }
    var flag = true;
    var characters =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //var email = $("#emailId").val();
    var email = document.getElementById("getEmailId").value;
    var splitemail = email.split(/\r|\r\n|\n/);
    var emailcount = splitemail.length;
    if (!getFirstName) {
      document.getElementById("getFirstName").focus();
      toast.error("First Name Required");
      flag = false;
      setLoad(true);
    } else if (!getMrNo) {
      document.getElementById("getMrNo").focus();
      toast.error("MR Number Required");
      flag = false;
      setLoad(true);
    }
    else if (!getAreaCode) {
      document.getElementById("getAreaCode1").focus();
      toast.error("Area Code Required");
      window.scrollTo(0, 400);
      flag = false;
      setLoad(true);
    }
    else if (getPrNoSet.length > 0 && document.getElementById("getPrNo").value === "") {
      document.getElementById("getPrNo").focus();
      toast.error("PR Number Required");
      flag = false;
      setLoad(true);
      // document.getElementById("getPrNo").value=getPrNoSet
    } else if (getMrNo === "0") {
      document.getElementById("getMrNo").focus();
      toast.error("MR Number should be greater than zero");
      flag = false;
      setLoad(true);
      // document.getElementById("getPrNo").value=getPrNoSet
    }
    else if (getPrNo === "0") {
      document.getElementById("getPrNo").focus();
      toast.error("PR Number should be greater than zero");
      flag = false;
      setLoad(true);
      // document.getElementById("getPrNo").value=getPrNoSet
    }

    else if (!getMobileNo) {


      toast.error("Mobile Number Required");
      flag = false;
      setLoad(true);
      document.getElementById("getMobileNo").focus();
      window.scrollTo(0, 400);
    } else if (document.getElementById("getMobileNo").value.length < 10) {
      document.getElementById("getMobileNo").focus();
      toast.error("Mobile Number must be 10 Digits");
      flag = false;
      setLoad(true);
    } else if (
      characters.test(splitemail[emailcount - 1]) === false &&
      document.getElementById("getEmailId").value !== ""
    ) {

      toast.error("Invalid Email Id");
      //$("#emailId").focus();
      document.getElementById("getEmailId").focus();
      flag = false;
      setLoad(true);
    }
    else if (getCurrently === false) {

      lastMenDate1 = ""
      lastMenDate = ''
      flag = true;

    }
    var selected = addValue1.find(
      (object) => object.id == memberName122
    );

    if (selected !== undefined) {
      if (
        selected.id == memberName122
        // document.getElementById("freeArticleData1234").value
      ) {
        toast.error(selected.memberName122 + " " + "is already added");
        document.getElementById("freeArticleData1234").focus();
        document.getElementById("freeArticleData1234").value = "0";
        // selected.memberName122 = ""
        // selected.id = ""
        setMemberName122("0");
        setLoad(true);
        flag = false;
        // 		// alertlanguage()
        // 		// window.scrollTo(0, 0)
      }
    }
    if (flag === true) {
      const valuesToSend = {
        memberId: window.sessionStorage.getItem("memberId"),
        hospitalId: localStorage.getItem("hospitalId"),
        memberFirstName: getFirstName,
        memberLastName: getLastName,
        memberImageName: image ? image : imageName,
        memberIsSmsAlert: smsAlertCheck,
        memberIsEmailAlert: emailAlertCheck,
        mrNo: getMrNo,
        prNumber: getPrNo,
        memberAadharNo: getAdhaarId,
        memberGender: getPatientGender,
        memberAge: memberAge,
        memberDateOfBirth: memberAge,
        memberBloodGroup: getBloodGroup,
        memberLanguages: langData.toString().replace(/[\[\]']/g, ""),
        secUserMobileNumber: getMobileNo,
        secUserAreaCode: getAreaCode,
        secUserEmailId: getEmailId,
        memberPhoneNumber: getPhoneNo,
        memberAddress: getAddress,
        memberCity: document.getElementById("freeArticleData1").value,
        memberState: document.getElementById("freeArticleData12").value,
        memberCountry: document.getElementById("freeArticleData123").value,
        memberZipCode: getZipCode,
        memberHypertension: getHypertension,
        memberDyslipidemia: getDyslipidemia,
        memberDiabetes: getDiabetes,
        memberCad: getCAD,
        showPregnancyData: true,
        isPregnancy: getCurrently,
        userId: window.sessionStorage.getItem("userId"),
        dueDate: lastMenDate1 === "undefined-undefined-" ? "" : lastMenDate1,
        lastDueDate: lastMenDate === "undefined-undefined-" ? "" : lastMenDate,
        prevPregnancyDetails: getPrevPregDetail,
        currentCondition: getCurrentcondition,
        medicalProblems: getMedicalProb,
        noOfcsection: getCsection,
        noOfCompletedPregnancies: getCompleted,
        noOfLiveBirth: getLivebirth,
        noOfMisCarriage: getMiscarriage,
        noOfStillBirth: getStillbirth,
        noOfTerminationOfPregnancy: getTerminated,
        prevPregnancyComplications: getPrevPregComp,
        sendEmail: pregEmailAlertCheck,
        sendSms: pregSmsAlertCheck,
        childNameDetails: child === undefined ? [] : child,
        // childNameDetails : [
        //     {
        //         childName:"Ashok S",
        //         childDateOfBirth:"2022-02-26",
        //         hasVaccinationPlanner: true
        //     }
        // ]
      };

      // setLoad(true)
      callingAPI("patientProfile/updatePatientProfile", valuesToSend)
        .then((res) => {
          if (res.data.success === "1") {

            toast.success("Profile updated Successfully");
            window.location.href = "/patients/patientconsult";
          } else {
            setLoad(true);
            toast.error(res.data.errorMessage);
          }
        })
        .catch();
    }
  };
  const editPatientProfile = () => {
    setLoad(false);

    setShowEditDiv(true);
    // setDob('')
    // setAge('')
    const PostData = {
      memberId: window.sessionStorage.getItem("memberId"),
      hospitalId: localStorage.getItem("hospitalId"),
    };
    callingAPI("patientProfile/getPatientProfile", PostData)
      .then((data) => {

        if (data.data.success === "1") {
          if (data.data.result.pregnancyDetails !== "") {
            setPregnancyDetails(data.data.result.pregnancyDetails);
            setChildDetails(data.data.result.ChildDetails);
            setPregSmsAlertCheck(data.data.result.pregnancyDetails[0].sendSms);
            setPregEmailAlertCheck(
              data.data.result.pregnancyDetails[0].sendEmail
            );
            if (data.data.result.isPregnancy === true) {
              document.getElementById("chkpreg").checked = true;
            }
            if (data.data.result.isPregnancy === false) {
              document.getElementById("chkpreg1").checked = true;
            }
            setPrevPregDetail(
              data.data.result.pregnancyDetails[0].prevPregnancyDetails
            );
            setPrevPregComp(
              data.data.result.pregnancyDetails[0].prevPregnancyComplications
            );
            setMedicalProb(
              data.data.result.pregnancyDetails[0].medicalProblems
            );
            setCurrentcondition(
              data.data.result.pregnancyDetails[0].currentCondition
            );

            if (
              data.data.result.pregnancyDetails[0].isPregnancy === 1) {
              // var dateofbirths1;
              var fromDateSplit3 =
                data.data.result.pregnancyDetails[0].lastDueDate.split("-");

              // dateofbirths1 = 
              // fromDateSplit3;
              setLastmens(
                new Date(
                  fromDateSplit3[2] +
                  "," +
                  fromDateSplit3[1] +
                  "," +
                  fromDateSplit3[0]
                )
              );
              // var dateofbirths1;
              var fromDateSplit4 =
                data.data.result.pregnancyDetails[0].dueDate.split("-");

              // dateofbirths1 = 
              // fromDateSplit3;
              setLastmens1(
                new Date(
                  fromDateSplit4[2] +
                  "," +
                  fromDateSplit4[1] +
                  "," +
                  fromDateSplit4[0]
                )
              );

              // setMens1(data.data.result.pregnancyDetails[0].dueDate);
              // setMens(data.data.result.pregnancyDetails[0].lastDueDate);

            }
            else {
              setMens("")
              setMens1("")
            }
            setCsection(data.data.result.pregnancyDetails[0].noOfcsection);
            setLivebirth(data.data.result.pregnancyDetails[0].noOfLiveBirth);
            setStillbirth(data.data.result.pregnancyDetails[0].noOfStillBirth);
            setTerminated(
              data.data.result.pregnancyDetails[0].noOfTerminationOfPregnancy
            );
            setMiscarriage(
              data.data.result.pregnancyDetails[0].noOfMisCarriage
            );
            setCompleted(
              data.data.result.pregnancyDetails[0].noOfCompletedPregnancies
            );
            setCurrently(data.data.result.pregnancyDetails[0].isPregnancy);
            if (document.getElementsByClassName("gynecologyContentSelect").length > 0) {
              if (
                data.data.result.pregnancyDetails[0].isPregnancy === 1) {
                document.getElementById("chkpreg").checked = true;
              }
              else {
                document.getElementById("chkpreg1").checked = true;
                setMens("")
                setMens1("")
              }
            }
          } else {
            setPregnancyDetails([]);
          }
          setAge1(data.data.result.list[0].memberAge);
          if (data.data.result.list[0].memberImageName !== "" && data.data.result.list[0].memberImageName !== "null" && data.data.result.list[0].memberImageName !== null) {
            setImagePath(
              getS3folder + "/" + data.data.result.list[0].memberImageName
            );
          } else {
            if (data.data.result.list[0].memberGender === "Male") {
              setImagePath(boy);
            } else {
              setImagePath(girl);
            }
          }
          setImageName(data.data.result.list[0].memberImageName);
          if (
            data.data.result.list[0].memberGender === "Male" ||
            data.data.result.list[0].memberGender === "male"
          ) {
            document.getElementById("radio1").checked = true;
          }
          if (
            data.data.result.list[0].memberGender === "Female" ||
            data.data.result.list[0].memberGender === "female"
          ) {
            document.getElementById("radio2").checked = true;
          }
          setEmailAlertCheck(data.data.result.list[0].memberIsEmailAlert);
          setSmsAlertCheck(data.data.result.list[0].memberIsSmsAlert);
          setFirstName(data.data.result.list[0].memberFirstName);
          setLastName(data.data.result.list[0].memberLastName);
          setMrNo(data.data.result.list[0].mrNo);
          setPrNo(data.data.result.list[0].prNumber);
          setPrNoSet(data.data.result.list[0].prNumber);
          setAdhaarId(data.data.result.list[0].memberAadharNo);

          setPatientGender(data.data.result.list[0].memberGender);
          document.getElementById("freeArticleData123").value =
            data.data.result.list[0].memberCountry;
          document.getElementById("freeArticleData1").value =
            data.data.result.list[0].memberCity;
          document.getElementById("freeArticleData12").value =
            data.data.result.list[0].memberState;
          // if(data.data.result.list[0].memberDateOfBirth === getDob){
          // 	setAge(data.data.result.list[0].memberAge);
          // setDob(data.data.result.list[0].memberDateOfBirth)
          // }
          // else{
          // ageChange1(data.data.result.list[0].memberAge)
          // }
          //	memberDateOfBirth
          // setPatientDOB(data.data.result.list[0].memberDateOfBirth)
          //	setAge(data.data.result.list[0].memberAge);
          // {data.age !== '' && data.dob !== "01-Jan-1900"? (
          if (
            data.data.result.list[0].memberAge !== "" &&
            data.data.result.list[0].memberDateOfBirth !== "01/01/1900"
          ) {
            var dateofbirths1;
            var fromDateSplit3 =
              data.data.result.list[0].memberDateOfBirth.split("/");

            dateofbirths1 = fromDateSplit3;
            setPatientDOB(
              new Date(
                fromDateSplit3[2] +
                "," +
                fromDateSplit3[1] +
                "," +
                fromDateSplit3[0]
              )
            );
            setDob(data.data.result.list[0].memberDateOfBirth);
            // var date=data.data.result.list[0].memberDateOfBirth
            // var date=document.getElementById("dobId").value
            // var dateString = (moment(date).format("MM/DD/YYYY"))
            // var dateString = document.getElementById("dobId").value
            if (data.data.result.list[0].memberAge === 0) {
              var fromDateSplit =
                data.data.result.list[0].memberDateOfBirth.split("/");
              var day = new Date(
                fromDateSplit[2] +
                "," +
                fromDateSplit[1] +
                "," +
                fromDateSplit[0]
              );

              var dateString = moment(day).format("MM/DD/YYYY");
              var now = new Date();
              var today = new Date(
                now.getYear(),
                now.getMonth(),
                now.getDate()
              );
              var yearNow = now.getYear();
              var monthNow = now.getMonth();
              var dateNow = now.getDate();
              var dob = new Date(
                dateString.substring(6, 10),
                dateString.substring(0, 2) - 1,
                dateString.substring(3, 5)
              );
              var yearDob = dob.getYear();
              var monthDob = dob.getMonth();
              var dateDob = dob.getDate();
              var age = {};
              var ageString = "";
              var yearString = "";
              var monthString = "";
              var dayString = "";
              var yearAge = yearNow - yearDob;
              if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
              else {
                yearAge--;
                var monthAge = 12 + monthNow - monthDob;
              }
              if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
              else {
                monthAge--;
                var dateAge = 31 + dateNow - dateDob;
                if (monthAge < 0) {
                  monthAge = 11;
                  yearAge--;
                }
              }
              age = {
                years: yearAge,
                months: monthAge,
                days: dateAge,
              };
              // if (age.years > 1) yearString = " years";
              // else yearString = " year";
              // if (age.months > 1) monthString = " months";
              // else monthString = " month";
              // if (age.days > 1) dayString = " days";
              // else dayString = " day";
              // if (age.years > 0 && age.months > 0 && age.days > 0)
              //   ageString =
              //     age.years +
              //     yearString +
              //     ", " +
              //     age.months +
              //     monthString +
              //     ", and " +
              //     age.days +
              //     dayString +
              //     " old.";
              // else if (age.years == 0 && age.months == 0 && age.days > 0)
              //   ageString = age.days + dayString + " old";
              // //   ageString = "Only " + age.days + dayString + " old";
              // else if (age.years > 0 && age.months == 0 && age.days == 0)
              //   ageString = age.years + yearString + " old";
              // else if (age.years > 0 && age.months > 0 && age.days == 0)
              //   ageString =
              //     age.years +
              //     yearString +
              //     " and " +
              //     age.months +
              //     monthString +
              //     " old.";
              // else if (age.years == 0 && age.months > 0 && age.days > 0)
              //   ageString =
              //     age.months +
              //     monthString +
              //     " and " +
              //     age.days +
              //     dayString +
              //     " old.";
              // else if (age.years > 0 && age.months == 0 && age.days > 0)
              //   ageString =
              //     age.years +
              //     yearString +
              //     " and " +
              //     age.days +
              //     dayString +
              //     " old.";
              // else if (age.years == 0 && age.months > 0 && age.days == 0)
              //   ageString = age.months + monthString + " old.";
              // else ageString = "1 day old.";
              // setAge(ageString);
              if (age.years === 1) {
                setAge(age.years + " " + "year")
              }
              else if (age.years > 1) {
                setAge(age.years + " " + "years")
              } else if (age.years === 0 && age.months === 1) {
                setAge(age.months + " " + "month");
              } else if (age.years === 0 && age.months > 1) {
                setAge(age.months + " " + "months");
              } else if (age.years === 0 && age.months === 0 && age.days === 1) {
                setAge(age.days + " " + "day");
              } else if (age.years === 0 && age.months === 0 && age.days > 1) {
                setAge(age.days + " " + "days");
              }
            } else {
              var fromDateSplit3 =
                data.data.result.list[0].memberDateOfBirth.split("/");
              setPatientDOB(
                new Date(
                  fromDateSplit3[2] +
                  "," +
                  fromDateSplit3[1] +
                  "," +
                  fromDateSplit3[0]
                )
              );
              setAge(data.data.result.list[0].memberAge === 1 ? data.data.result.list[0].memberAge + " " + "year" : data.data.result.list[0].memberAge + " " + "years");
            }
            // setAge(data.data.result.list[0].memberAge);
          } else {
            data.data.result.list[0].memberDateOfBirth === "1900-01-01" &&
              setDob("");
            data.data.result.list[0].memberDateOfBirth === "1900-01-01" &&
              setAge("");
          }
          // ageChange1(data.data.result.list[0].memberAge)
          setBloodGroup(data.data.result.list[0].memberBloodGroup);
          setCAD(data.data.result.list[0].memberCad);
          setDyslipidemia(data.data.result.list[0].memberDyslipidemia);
          setDiabetes(data.data.result.list[0].memberDiabetes);
          setHypertension(data.data.result.list[0].memberHypertension);
          setZipCode(data.data.result.list[0].memberZipCode);
          setMobileNo(data.data.result.list[0].secUserMobileNumber);
          setPhoneNo(data.data.result.list[0].memberPhoneNumber);
          setAddress(data.data.result.list[0].memberAddress);
          setEmailId(data.data.result.list[0].secUserEmailId);
          setAreaCode(data.data.result.list[0].secUserAreaCode);

          var copy = [...addValue1];
          for (let i = 0; i < data.data.result.memberLanguages.length; i++) {
            var obj = {
              memberName122:
                data.data.result.memberLanguages[i].memberLanguageDescription,
              id: data.data.result.memberLanguages[i].memberLanguageId,
            };
            copy.push(obj);
          }
          // copy.push(obj);
          setAddValue1(copy);
          var copy1 = [...disposableitemsaddedarr];
          for (let i = 0; i < data.data.result.ChildDetails.length; i++) {
            var childa = "";
            var fromDateSplit =
              data.data.result.ChildDetails[i].childDateOfBirth.split("/");
            fromDateSplit =
              fromDateSplit[2] +
              "-" +
              fromDateSplit[1] +
              "-" +
              fromDateSplit[0];
            childa = fromDateSplit;
            var obj1 = {
              childNamelist: data.data.result.ChildDetails[i].childName,
              childNameDob: childa,
              childNameVaccine:
                data.data.result.ChildDetails[i].hasVaccinationPlanner,
              memberPregnancyChildDetailsId:
                data.data.result.ChildDetails[i].memberPregnancyChildDetailsId,
            };
            copy1.push(obj1);
          }
          // copy.push(obj);
          setDisposableItemsAddedArr(copy1);
          //setAddValue1(data.data.result.memberLanguages)
          setLoad(true);
        }
      })
      .catch();
  };
  const qaz = () => {

    document.getElementById("locationSearch1").style.display = "none";
    document.getElementById("locationSearch12").style.display = "none";
    document.getElementById("locationSearch123").style.display = "none";
  };
  const langDropDown = () => {
    let PostData = {
      "searchKeyword": ""
    };
    callingAPI('addPatient/autoCompleteGetLanguages', PostData)
      .then((data) => {
        if (data.data.success === '1') {
          setLangList(data.data.result);
        }
      })

  }
  useEffect(() => {

    langDropDown()
    if (showEdit === "Edit") {
      setShowEditDiv(true);
      const PostData = {
        memberId: window.sessionStorage.getItem("memberId"),
        hospitalId: localStorage.getItem("hospitalId"),
      };
      callingAPI("patientProfile/getPatientProfile", PostData)
        .then((data) => {
          if (data.data.success === "1") {

            setLoad(true);
            setAge1(data.data.result.list[0].memberAge);
            if (data.data.result.pregnancyDetails !== "") {
              setChildDetails(data.data.result.ChildDetails);
              setPregnancyDetails(data.data.result.pregnancyDetails);
              setPregSmsAlertCheck(
                data.data.result.pregnancyDetails[0].sendSms
              );
              setPregEmailAlertCheck(
                data.data.result.pregnancyDetails[0].sendEmail
              );
              if (
                data.data.result.pregnancyDetails[0].isPregnancy === 1) {
                var fromDateSplit3 =
                  data.data.result.pregnancyDetails[0].lastDueDate.split("-");

                // dateofbirths1 = 
                // fromDateSplit3;
                setLastmens(
                  new Date(
                    fromDateSplit3[2] +
                    "," +
                    fromDateSplit3[1] +
                    "," +
                    fromDateSplit3[0]
                  )
                );
                // var dateofbirths1;
                var fromDateSplit4 =
                  data.data.result.pregnancyDetails[0].dueDate.split("-");

                // dateofbirths1 = 
                // fromDateSplit3;
                setLastmens1(
                  new Date(
                    fromDateSplit4[2] +
                    "," +
                    fromDateSplit4[1] +
                    "," +
                    fromDateSplit4[0]
                  )
                );
              }
              else {
                setMens("")
                setMens1("")
              }
              setPrevPregDetail(
                data.data.result.pregnancyDetails[0].prevPregnancyDetails
              );
              setPrevPregComp(
                data.data.result.pregnancyDetails[0].prevPregnancyComplications
              );
              setMedicalProb(
                data.data.result.pregnancyDetails[0].medicalProblems
              );
              setCurrentcondition(
                data.data.result.pregnancyDetails[0].currentCondition
              );
              setCsection(data.data.result.pregnancyDetails[0].noOfcsection);
              setLivebirth(data.data.result.pregnancyDetails[0].noOfLiveBirth);
              setStillbirth(
                data.data.result.pregnancyDetails[0].noOfStillBirth
              );
              setTerminated(
                data.data.result.pregnancyDetails[0].noOfTerminationOfPregnancy
              );
              setMiscarriage(
                data.data.result.pregnancyDetails[0].noOfMisCarriage
              );
              setCompleted(
                data.data.result.pregnancyDetails[0].noOfCompletedPregnancies
              );
              setCurrently(data.data.result.pregnancyDetails[0].isPregnancy);
              if (document.getElementsByClassName("gynecologyContentSelect").length > 0) {
                if (
                  data.data.result.pregnancyDetails[0].isPregnancy === 1) {
                  document.getElementById("chkpreg").checked = true;
                }
                else {
                  document.getElementById("chkpreg1").checked = true;
                  setMens("")
                  setMens1("")
                }
              }
            } else {
              setPregnancyDetails([]);
            }
            setEmailAlertCheck(data.data.result.list[0].memberIsEmailAlert);
            setSmsAlertCheck(data.data.result.list[0].memberIsSmsAlert);

            // (data.data.result.list[0].memberAge);
            if (data.data.result.list[0].memberImageName !== "" && data.data.result.list[0].memberImageName !== "null" && data.data.result.list[0].memberImageName !== null) {
              setImagePath(
                getS3folder + "/" + data.data.result.list[0].memberImageName
              );
            } else {
              if (data.data.result.list[0].memberGender === "Male") {
                setImagePath(boy);
              } else {
                setImagePath(girl);
              }
            }
            setImageName(data.data.result.list[0].memberImageName);
            if (
              data.data.result.list[0].memberGender === "Male" ||
              data.data.result.list[0].memberGender === "male"
            ) {
              document.getElementById("radio1").checked = true;
            }
            if (
              data.data.result.list[0].memberGender === "Female" ||
              data.data.result.list[0].memberGender === "female"
            ) {
              document.getElementById("radio2").checked = true;
            }
            setFirstName(data.data.result.list[0].memberFirstName);
            setLastName(data.data.result.list[0].memberLastName);
            setMrNo(data.data.result.list[0].mrNo);
            setPrNo(data.data.result.list[0].prNumber);
            setPrNoSet(data.data.result.list[0].prNumber);
            setAdhaarId(data.data.result.list[0].memberAadharNo);
            // setAge1(data.data.result.list[0].memberAge);
            setPatientGender(data.data.result.list[0].memberGender);
            document.getElementById("freeArticleData123").value =
              data.data.result.list[0].memberCountry;
            document.getElementById("freeArticleData1").value =
              data.data.result.list[0].memberCity;
            document.getElementById("freeArticleData12").value =
              data.data.result.list[0].memberState;
            // if(data.data.result.list[0].memberDateOfBirth === ""){
            // 	setDob('');
            // }
            // else{
            // 	setDob(new Date(data.data.result.list[0].memberDateOfBirth));
            // }
            //	memberDateOfBirth
            // setAge(data.data.result.list[0].memberAge);
            // setDob(data.data.result.list[0].memberDateOfBirth)
            // var dateofbirths1;
            // var fromDateSplit = data.data.result.list[0].memberDateOfBirth.split("-");
            // dateofbirths1 = fromDateSplit;
            // setPatientDOB(new Date(fromDateSplit[2] + "," + fromDateSplit[1] + "," + fromDateSplit[0]))
            // var date=data.data.result.list[0].memberDateOfBirth
            // var dateString = (moment(date).format("MM/DD/YYYY"))
            // var dateString = document.getElementById("dobId").value
            //     var now = new Date();
            //     var today = new Date(now.getYear(),now.getMonth(),now.getDate());
            //     var yearNow = now.getYear();
            //     var monthNow = now.getMonth();
            //     var dateNow = now.getDate();
            //     var dob = new Date(dateString.substring(6,10),
            //                        dateString.substring(0,2)-1,
            //                        dateString.substring(3,5)
            //                        );
            //     var yearDob = dob.getYear();
            //     var monthDob = dob.getMonth();
            //     var dateDob = dob.getDate();
            //     var age = {};
            //     var ageString = "";
            //     var yearString = "";
            //     var monthString = "";
            //     var dayString = "";
            //     var yearAge = yearNow - yearDob;
            //     if (monthNow >= monthDob)
            //       var monthAge = monthNow - monthDob;
            //     else {
            //       yearAge--;
            //       var monthAge = 12 + monthNow -monthDob;
            //     }
            //     if (dateNow >= dateDob)
            //       var dateAge = dateNow - dateDob;
            //     else {
            //       monthAge--;
            //       var dateAge = 31 + dateNow - dateDob;
            //       if (monthAge < 0) {
            //         monthAge = 11;
            //         yearAge--;
            //       }
            //     }
            //     age = {
            //         years: yearAge,
            //         months: monthAge,
            //         days: dateAge
            //         };
            //     if ( age.years > 1 ) yearString = " years";
            //     else yearString = " year";
            //     if ( age.months> 1 ) monthString = " months";
            //     else monthString = " month";
            //     if ( age.days > 1 ) dayString = " days";
            //     else dayString = " day";
            //     if ( (age.years > 0) && (age.months > 0) && (age.days > 0) )
            //       ageString = age.years + yearString + ", " + age.months + monthString + ", and " + age.days + dayString + " old.";
            //     else if ( (age.years == 0) && (age.months == 0) && (age.days > 0) )
            //       ageString =age.days + dayString + " old";
            // 	//   ageString = "Only " + age.days + dayString + " old";
            //     else if ( (age.years > 0) && (age.months == 0) && (age.days == 0) )
            //       ageString = age.years + yearString + " old";
            //     else if ( (age.years > 0) && (age.months > 0) && (age.days == 0) )
            //       ageString = age.years + yearString + " and " + age.months + monthString + " old.";
            //     else if ( (age.years == 0) && (age.months > 0) && (age.days > 0) )
            //       ageString = age.months + monthString + " and " + age.days + dayString + " old.";
            //     else if ( (age.years > 0) && (age.months == 0) && (age.days > 0) )
            //       ageString = age.years + yearString + " and " + age.days + dayString + " old.";
            //     else if ( (age.years == 0) && (age.months > 0) && (age.days == 0) )
            //       ageString = age.months + monthString + " old.";
            //     else ageString = "Oops! Could not calculate age!";
            // 	setAge(ageString)
            if (
              data.data.result.list[0].memberAge !== "" &&
              data.data.result.list[0].memberDateOfBirth !== "01/01/1900"
            ) {
              // setDob(data.data.result.list[0].memberDateOfBirth)
              var dateofbirths1;
              var fromDateSplit3 =
                data.data.result.list[0].memberDateOfBirth.split("/");
              dateofbirths1 = fromDateSplit3;
              setPatientDOB(
                new Date(
                  fromDateSplit3[2] +
                  "," +
                  fromDateSplit3[1] +
                  "," +
                  fromDateSplit3[0]
                )
              );
              setDob(data.data.result.list[0].memberDateOfBirth);
              // var date=data.data.result.list[0].memberDateOfBirth
              // var dateString = (moment(date).format("MM/DD/YYYY"))
              // var dateString = document.getElementById("dobId").value
              if (data.data.result.list[0].memberAge === 0) {
                var fromDateSplit =
                  data.data.result.list[0].memberDateOfBirth.split("/");
                var day = new Date(
                  fromDateSplit[2] +
                  "," +
                  fromDateSplit[1] +
                  "," +
                  fromDateSplit[0]
                );
                var dateString = moment(day).format("MM/DD/YYYY");
                var now = new Date();
                var today = new Date(
                  now.getYear(),
                  now.getMonth(),
                  now.getDate()
                );
                var yearNow = now.getYear();
                var monthNow = now.getMonth();
                var dateNow = now.getDate();
                var dob = new Date(
                  dateString.substring(6, 10),
                  dateString.substring(0, 2) - 1,
                  dateString.substring(3, 5)
                );
                var yearDob = dob.getYear();
                var monthDob = dob.getMonth();
                var dateDob = dob.getDate();
                var age = {};
                var ageString = "";
                var yearString = "";
                var monthString = "";
                var dayString = "";
                var yearAge = yearNow - yearDob;
                if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
                else {
                  yearAge--;
                  var monthAge = 12 + monthNow - monthDob;
                }
                if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
                else {
                  monthAge--;
                  var dateAge = 31 + dateNow - dateDob;
                  if (monthAge < 0) {
                    monthAge = 11;
                    yearAge--;
                    age2 = yearAge;
                  }
                }
                age = {
                  years: yearAge,
                  months: monthAge,
                  days: dateAge,
                };
                // if (age.years > 1) yearString = " years";
                // else yearString = " year";
                // if (age.months > 1) monthString = " months";
                // else monthString = " month";
                // if (age.days > 1) dayString = " days";
                // else dayString = " day";
                // if (age.years > 0 && age.months > 0 && age.days > 0)
                //   ageString =
                //     age.years +
                //     yearString +
                //     ", " +
                //     age.months +
                //     monthString +
                //     ", and " +
                //     age.days +
                //     dayString +
                //     " old.";
                // else if (age.years == 0 && age.months == 0 && age.days > 0)
                //   ageString = age.days + dayString + " old";
                // //   ageString = "Only " + age.days + dayString + " old";
                // else if (age.years > 0 && age.months == 0 && age.days == 0)
                //   ageString = age.years + yearString + " old";
                // else if (age.years > 0 && age.months > 0 && age.days == 0)
                //   ageString =
                //     age.years +
                //     yearString +
                //     " and " +
                //     age.months +
                //     monthString +
                //     " old.";
                // else if (age.years == 0 && age.months > 0 && age.days > 0)
                //   ageString =
                //     age.months +
                //     monthString +
                //     " and " +
                //     age.days +
                //     dayString +
                //     " old.";
                // else if (age.years > 0 && age.months == 0 && age.days > 0)
                //   ageString =
                //     age.years +
                //     yearString +
                //     " and " +
                //     age.days +
                //     dayString +
                //     " old.";
                // else if (age.years == 0 && age.months > 0 && age.days == 0)
                //   ageString = age.months + monthString + " old.";
                // else ageString = "1 day old.";
                // setAge(ageString);
                if (age.years === 1) {
                  setAge(age.years + " " + "year")
                }
                else if (age.years > 1) {
                  setAge(age.years + " " + "years")
                } else if (age.years === 0 && age.months === 1) {
                  setAge(age.months + " " + "month");
                } else if (age.years === 0 && age.months > 1) {
                  setAge(age.months + " " + "months");
                } else if (age.years === 0 && age.months === 0 && age.days === 1) {
                  setAge(age.days + " " + "day");
                } else if (age.years === 0 && age.months === 0 && age.days > 1) {
                  setAge(age.days + " " + "days");
                }
              } else {
                setAge(data.data.result.list[0].memberAge === 1 ? data.data.result.list[0].memberAge + " " + "year" : data.data.result.list[0].memberAge + " " + "years");
                var fromDateSplit3 =
                  data.data.result.list[0].memberDateOfBirth.split("/");

                setPatientDOB(
                  new Date(
                    fromDateSplit3[2] +
                    "," +
                    fromDateSplit3[1] +
                    "," +
                    fromDateSplit3[0]
                  )
                );
              }
              // setAge(data.data.result.list[0].memberAge);
            } else {
              setDob("");
              setAge("");
            }
            // ageChange1(data.data.result.list[0].memberAge)
            setBloodGroup(data.data.result.list[0].memberBloodGroup);
            setCAD(data.data.result.list[0].memberCad);
            setDyslipidemia(data.data.result.list[0].memberDyslipidemia);
            setDiabetes(data.data.result.list[0].memberDiabetes);
            setHypertension(data.data.result.list[0].memberHypertension);
            setZipCode(data.data.result.list[0].memberZipCode);
            setMobileNo(data.data.result.list[0].secUserMobileNumber);
            setPhoneNo(data.data.result.list[0].memberPhoneNumber);
            setAddress(data.data.result.list[0].memberAddress);
            setEmailId(data.data.result.list[0].secUserEmailId);
            setAreaCode(data.data.result.list[0].secUserAreaCode);
            var copy = [...addValue1];
            for (let i = 0; i < data.data.result.memberLanguages.length; i++) {
              var obj = {
                memberName122:
                  data.data.result.memberLanguages[i].memberLanguageDescription,
                id: data.data.result.memberLanguages[i].memberLanguageId,
              };
              copy.push(obj);
            }
            // copy.push(obj);
            setAddValue1(copy);
          }
        })
        .catch();
    } else {
      setShowEditDiv(false);
    }
    const PostData = {
      memberId: window.sessionStorage.getItem("memberId"),
      hospitalId: localStorage.getItem("hospitalId"),
    };
    callingAPI("patientProfile/getPatientProfile", PostData)
      .then((data) => {
        if (data.data.success === "1") {

          setLoad(true);
          setAge1(data.data.result.list[0].memberAge);
          // setPregnancyDetails(data.data.result.pregnancyDetails)
          if (data.data.result.pregnancyDetails !== "") {
            setChildDetails(data.data.result.ChildDetails);
            setPregnancyDetails(data.data.result.pregnancyDetails);
            setPregSmsAlertCheck(data.data.result.pregnancyDetails[0].sendSms);
            setPregEmailAlertCheck(
              data.data.result.pregnancyDetails[0].sendEmail
            );
            setPrevPregDetail(
              data.data.result.pregnancyDetails[0].prevPregnancyDetails
            );
            setPrevPregComp(
              data.data.result.pregnancyDetails[0].prevPregnancyComplications
            );
            setMedicalProb(
              data.data.result.pregnancyDetails[0].medicalProblems
            );
            setCsection(data.data.result.pregnancyDetails[0].noOfcsection);
            setLivebirth(data.data.result.pregnancyDetails[0].noOfLiveBirth);
            setStillbirth(data.data.result.pregnancyDetails[0].noOfStillBirth);
            setTerminated(
              data.data.result.pregnancyDetails[0].noOfTerminationOfPregnancy
            );
            setMiscarriage(
              data.data.result.pregnancyDetails[0].noOfMisCarriage
            );
            if (
              data.data.result.pregnancyDetails[0].isPregnancy === 1) {
              setMens1(moment(data.data.result.pregnancyDetails[0].dueDate).format("MM/DD/YYYY"));
              setMens(moment(data.data.result.pregnancyDetails[0].lastDueDate).format("MM/DD/YYYY"));
            }
            else {
              setMens("")
              setMens1("")
            }
            setCompleted(
              data.data.result.pregnancyDetails[0].noOfCompletedPregnancies
            );
            setCurrentcondition(
              data.data.result.pregnancyDetails[0].currentCondition
            );
            setCurrently(data.data.result.pregnancyDetails[0].isPregnancy);
            if (document.getElementsByClassName("gynecologyContentSelect").length > 0) {
              if (
                data.data.result.pregnancyDetails[0].isPregnancy === 1) {
                document.getElementById("chkpreg").checked = true;
              }
              else {
                document.getElementById("chkpreg1").checked = true;
                setMens("")
                setMens1("")

              }
            }
          } else {
            setPregnancyDetails([]);
          }
          if (data.data.result.list[0].memberImageName !== "" && data.data.result.list[0].memberImageName !== "null" && data.data.result.list[0].memberImageName !== null) {
            setImagePath(
              getS3folder + "/" + data.data.result.list[0].memberImageName
            );
          } else {
            if (data.data.result.list[0].memberGender === "Male") {
              setImagePath(boy);
            } else {
              setImagePath(girl);
            }
          }
          // setLoad(true);
          //	setImageName(getS3folder + '/' + data.data.result.list[0].memberImageName);

          if (data.data.result.list[0].memberGender === "Male") {
            document.getElementById("radio1").checked = true;
          }
          if (data.data.result.list[0].memberGender === "Female") {
            document.getElementById("radio2").checked = true;
          }
          setEmailAlertCheck(data.data.result.list[0].memberIsEmailAlert);
          setSmsAlertCheck(data.data.result.list[0].memberIsSmsAlert);
          setFirstName(data.data.result.list[0].memberFirstName);
          setLastName(data.data.result.list[0].memberLastName);
          setMrNo(data.data.result.list[0].mrNo);
          setPrNo(data.data.result.list[0].prNumber);
          setPrNoSet(data.data.result.list[0].prNumber);
          setAdhaarId(data.data.result.list[0].memberAadharNo);
          setPatientGender(data.data.result.list[0].memberGender);
          setCountry1(data.data.result.list[0].memberCountry);
          setCity1(data.data.result.list[0].memberCity);
          setState1(data.data.result.list[0].memberState);
          // setDob(data.data.result.list[0].memberDateOfBirth);
          setBloodGroup(data.data.result.list[0].memberBloodGroup);
          setCAD(data.data.result.list[0].memberCad);
          setDyslipidemia(data.data.result.list[0].memberDyslipidemia);
          setDiabetes(data.data.result.list[0].memberDiabetes);
          setHypertension(data.data.result.list[0].memberHypertension);
          setZipCode(data.data.result.list[0].memberZipCode);
          setMobileNo(data.data.result.list[0].secUserMobileNumber);
          setPhoneNo(data.data.result.list[0].memberPhoneNumber);
          setAddress(data.data.result.list[0].memberAddress);
          setEmailId(data.data.result.list[0].secUserEmailId);
          setAreaCode(data.data.result.list[0].secUserAreaCode);
          setLanguage(data.data.result.memberLanguages);
          // 		if(data.data.result.memberLanguages.length>0){
          // 		var sum = "";
          // for(var i=0; i<data.data.result.memberLanguages.length; i++){
          // 	sum = data.data.result.memberLanguages[i].memberLanguageDescription +",";
          // 	setLanguage(sum)
          // }}
          // else if(data.data.result.memberLanguages.length-1){
          // 	for(var i=0; i<data.data.result.memberLanguages.length; i++){
          // 	sum = data.data.result.memberLanguages[i].memberLanguageDescription;
          // 	setLanguage(sum)
          // }}
          // setAge(data.data.result.list[0].memberAge);
          // setPatientDOB(date);
          // alert(moment(date).format("DD/MM/YYYY"))
          // var date=data.data.result.list[0].memberDateOfBirth
          // var dateString = (moment(date).format("MM/DD/YYYY"))
          // var dateString = document.getElementById("dobId").value
          //     var now = new Date();
          //     var today = new Date(now.getYear(),now.getMonth(),now.getDate());
          //     var yearNow = now.getYear();
          //     var monthNow = now.getMonth();
          //     var dateNow = now.getDate();
          //     var dob = new Date(dateString.substring(6,10),
          //                        dateString.substring(0,2)-1,
          //                        dateString.substring(3,5)
          //                        );
          //     var yearDob = dob.getYear();
          //     var monthDob = dob.getMonth();
          //     var dateDob = dob.getDate();
          //     var age = {};
          //     var ageString = "";
          //     var yearString = "";
          //     var monthString = "";
          //     var dayString = "";
          //     var yearAge = yearNow - yearDob;
          //     if (monthNow >= monthDob)
          //       var monthAge = monthNow - monthDob;
          //     else {
          //       yearAge--;
          //       var monthAge = 12 + monthNow -monthDob;
          //     }
          //     if (dateNow >= dateDob)
          //       var dateAge = dateNow - dateDob;
          //     else {
          //       monthAge--;
          //       var dateAge = 31 + dateNow - dateDob;
          //       if (monthAge < 0) {
          //         monthAge = 11;
          //         yearAge--;
          //       }
          //     }
          //     age = {
          //         years: yearAge,
          //         months: monthAge,
          //         days: dateAge
          //         };
          //     if ( age.years > 1 ) yearString = " years";
          //     else yearString = " year";
          //     if ( age.months> 1 ) monthString = " months";
          //     else monthString = " month";
          //     if ( age.days > 1 ) dayString = " days";
          //     else dayString = " day";
          //     if ( (age.years > 0) && (age.months > 0) && (age.days > 0) )
          //       ageString = age.years + yearString + ", " + age.months + monthString + ", and " + age.days + dayString + " old.";
          //     else if ( (age.years == 0) && (age.months == 0) && (age.days > 0) )
          //       ageString =age.days + dayString + " old";
          // 	//   ageString = "Only " + age.days + dayString + " old";
          //     else if ( (age.years > 0) && (age.months == 0) && (age.days == 0) )
          //       ageString = age.years + yearString + " old";
          //     else if ( (age.years > 0) && (age.months > 0) && (age.days == 0) )
          //       ageString = age.years + yearString + " and " + age.months + monthString + " old.";
          //     else if ( (age.years == 0) && (age.months > 0) && (age.days > 0) )
          //       ageString = age.months + monthString + " and " + age.days + dayString + " old.";
          //     else if ( (age.years > 0) && (age.months == 0) && (age.days > 0) )
          //       ageString = age.years + yearString + " and " + age.days + dayString + " old.";
          //     else if ( (age.years == 0) && (age.months > 0) && (age.days == 0) )
          //       ageString = age.months + monthString + " old.";
          //     else ageString = "Oops! Could not calculate age!";
          // 	setAge(ageString)
          if (
            data.data.result.list[0].memberAge !== "" &&
            data.data.result.list[0].memberDateOfBirth !== "01/01/1900"
          ) {
            // alert(data.data.result.list[0].memberDateOfBirth)
            // setDob(data.data.result.list[0].memberDateOfBirth)
            var fromDateSplit3 =
              data.data.result.list[0].memberDateOfBirth.split("/");
            setPatientDOB(
              new Date(
                fromDateSplit3[2] +
                "," +
                fromDateSplit3[1] +
                "," +
                fromDateSplit3[0]
              )
            );
            if (data.data.result.list[0].memberAge === 0) {
              var dateofbirths1;
              dateofbirths1 = fromDateSplit3;
              setDob(data.data.result.list[0].memberDateOfBirth);
              var fromDateSplit =
                data.data.result.list[0].memberDateOfBirth.split("/");
              var day = new Date(
                fromDateSplit[2] +
                "," +
                fromDateSplit[1] +
                "," +
                fromDateSplit[0]
              );
              var dateString = moment(day).format("MM/DD/YYYY");
              // var dateString = (moment(date).format("MM/DD/YYYY"))
              // var dateString = document.getElementById("dobId").value
              var now = new Date();
              var today = new Date(
                now.getYear(),
                now.getMonth(),
                now.getDate()
              );
              var yearNow = now.getYear();
              var monthNow = now.getMonth();
              var dateNow = now.getDate();
              var dob = new Date(
                dateString.substring(6, 10),
                dateString.substring(0, 2) - 1,
                dateString.substring(3, 5)
              );
              var yearDob = dob.getYear();
              var monthDob = dob.getMonth();
              var dateDob = dob.getDate();
              var age = {};
              var ageString = "";
              var yearString = "";
              var monthString = "";
              var dayString = "";
              var yearAge = yearNow - yearDob;
              if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
              else {
                yearAge--;
                var monthAge = 12 + monthNow - monthDob;
              }
              if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
              else {
                monthAge--;
                var dateAge = 31 + dateNow - dateDob;
                if (monthAge < 0) {
                  monthAge = 11;
                  yearAge--;
                  age2 = yearAge;
                }
              }
              age = {
                years: yearAge,
                months: monthAge,
                days: dateAge,
              };
              // if (age.years > 1) yearString = " years";
              // else yearString = " year";
              // if (age.months > 1) monthString = " months";
              // else monthString = " month";
              // if (age.days > 1) dayString = " days";
              // else dayString = " day";
              // if (age.years > 0 && age.months > 0 && age.days > 0)
              //   ageString =
              //     age.years +
              //     yearString +
              //     ", " +
              //     age.months +
              //     monthString +
              //     ", and " +
              //     age.days +
              //     dayString +
              //     " old.";
              // else if (age.years == 0 && age.months == 0 && age.days > 0)
              //   ageString = age.days + dayString + " old";
              // //   ageString = "Only " + age.days + dayString + " old";
              // else if (age.years > 0 && age.months == 0 && age.days == 0)
              //   ageString = age.years + yearString + " old";
              // else if (age.years > 0 && age.months > 0 && age.days == 0)
              //   ageString =
              //     age.years +
              //     yearString +
              //     " and " +
              //     age.months +
              //     monthString +
              //     " old.";
              // else if (age.years == 0 && age.months > 0 && age.days > 0)
              //   ageString =
              //     age.months +
              //     monthString +
              //     " and " +
              //     age.days +
              //     dayString +
              //     " old.";
              // else if (age.years > 0 && age.months == 0 && age.days > 0)
              //   ageString =
              //     age.years +
              //     yearString +
              //     " and " +
              //     age.days +
              //     dayString +
              //     " old.";
              // else if (age.years == 0 && age.months > 0 && age.days == 0)
              //   ageString = age.months + monthString + " old.";
              // else ageString = "1 day old.";
              // setAge(ageString);
              if (age.years === 1) {
                setAge(age.years + " " + "year")
              }
              else if (age.years > 1) {
                setAge(age.years + " " + "years")
              } else if (age.years === 0 && age.months === 1) {
                setAge(age.months + " " + "month");
              } else if (age.years === 0 && age.months > 1) {
                setAge(age.months + " " + "months");
              } else if (age.years === 0 && age.months === 0 && age.days === 1) {
                setAge(age.days + " " + "day");
              } else if (age.years === 0 && age.months === 0 && age.days > 1) {
                setAge(age.days + " " + "days");
              }
            } else {
              setAge(data.data.result.list[0].memberAge === 1 ? data.data.result.list[0].memberAge + " " + "year" : data.data.result.list[0].memberAge + " " + "years");
              setDob(data.data.result.list[0].memberDateOfBirth);
              var fromDateSplit3 =
                data.data.result.list[0].memberDateOfBirth.split("/");
              // alert(new Date(fromDateSplit3[2] + "," + fromDateSplit3[1] + "," + fromDateSplit3[0]))
              setPatientDOB(
                new Date(
                  fromDateSplit3[2] +
                  "," +
                  fromDateSplit3[1] +
                  "," +
                  fromDateSplit3[0]
                )
              );
            }
            // setAge(data.data.result.list[0].memberAge);
          } else {
            setDob("");
            setAge("");
          }
        }
      })
      .catch();
  }, [props]);
  const onCaptureHandler = (captureData) => {
    setData(captureData);
    // alert(captureData);
  };
  const cityAutocomplete = (mode, dropdownId) => {
    const apiJson = {
      // searchKeyword: $("#" + mode).val()
      searchKeyword: document.getElementById(mode).value,
    };
    if (document.getElementById(mode).value.length > 1) {
      callingAPI("addPatient/autoCompleteGetCity", apiJson).then((data) => {
        if (data.data.success === "1") {
          document.getElementById(dropdownId).style.display = "block";
          setFreeArticleData1(data.data.result);
        } else {
          document.getElementById(dropdownId).style.display = "none";
        }
      });
    } else {
    }
  };
  const setFreeArticleData = (id, itemName, mode, dropdownId) => {
    //  $("#" + mode).val(itemName)
    document.getElementById(mode).value = itemName;
    setFreeArticleData1([]);
    //$("#" + dropdownId).hide()
    document.getElementById(dropdownId).style.display = "none";
  };
  const cityAutocomplete2 = (mode, dropdownId) => {
    const apiJson = {
      //  searchKeyword: $("#" + mode).val()
      searchKeyword: document.getElementById(mode).value,
    };
    if (document.getElementById(mode).value.length > 1) {
      callingAPI("addPatient/autoCompleteGetState", apiJson).then((data) => {
        if (data.data.success === "1") {
          //  $("#" + dropdownId).show()
          document.getElementById(dropdownId).style.display = "block";
          setFreeArticleData12(data.data.result);
        } else {
          //  $("#" + dropdownId).hide()
          document.getElementById(dropdownId).style.display = "none";
        }
      });
    } else {
    }
  };
  const setFreeArticleData2 = (id, itemName, mode, dropdownId) => {
    //  $("#" + mode).val(itemName)
    document.getElementById(mode).value = itemName;
    setFreeArticleData12([]);
    //$("#" + dropdownId).hide()
    document.getElementById(dropdownId).style.display = "none";
  };
  const cityAutocomplete23 = (mode, dropdownId) => {
    const apiJson = {
      //searchKeyword: $("#" + mode).val()
      searchKeyword: document.getElementById(mode).value,
    };
    if (document.getElementById(mode).value.length > 1) {
      callingAPI("addPatient/autoCompleteGetCountry", apiJson).then((data) => {
        if (data.data.success === "1") {
          // $("#" + dropdownId).show()
          document.getElementById(dropdownId).style.display = "block";
          setFreeArticleData123(data.data.result);
        } else {
          //  $("#" + dropdownId).hide()
          document.getElementById(dropdownId).style.display = "none";
        }
      });
    } else {
    }
  };
  const setFreeArticleData23 = (id, itemName, mode, dropdownId) => {
    document.getElementById(mode).value = itemName;
    // $("#" + mode).val(itemName)
    setFreeArticleData123([]);
    document.getElementById(dropdownId).style.display = "none";
    //$("#" + dropdownId).hide()
  };
  const addLang = () => {
    var flag = true;
    if (document.getElementById("freeArticleData1234").value === "0") {
      toast.error("Please Fill in This Field ");
      document.getElementById("freeArticleData1234").focus();
      flag = false;
    }
    // else if (document.getElementById("ui-id-5").style.display='none') {
    //   flag = true;
    // }
    // else if (document.getElementById("ui-id-5").style.display='block') {
    //   alert("Invalid Language ");
    //   document.getElementById("ui-id-5").display='none'
    //   document.getElementById("freeArticleData1234").focus();
    //   document.getElementById("freeArticleData1234").value=''

    //   flag = false;
    // }
    // var selected1 = freeArticleData1234.find(
    //   (object) => object.languageDescription === document.getElementById("freeArticleData1234").value
    // );
    // if(selected1===undefined){
    //   alert("Please Select Valid Language")
    //   // document.getElementById("ui-id-5").display='none'
    //   // document.getElementById("freeArticleData1234").value=''
    //   document.getElementById("freeArticleData1234").focus();

    //   flag =false
    // }
    // if (selected1 !== undefined) {
    //   if (selected.memberName122 == memberName122) {
    //     alert(memberName122 + " " + "is already added");
    //     document.getElementById("freeArticleData1234").focus();
    //     document.getElementById("freeArticleData1234").value = "";
    //     flag = false;
    //     // 		// alertlanguage()
    //     // 		// window.scrollTo(0, 0)
    //   }
    // }
    var selected = addValue1.find((object) => object.id == memberName122);
    var lang = getLangList.find((object) => object.languageId == memberName122);
    if (selected !== undefined) {
      if (selected.memberName122 == lang.languageDescription) {
        toast.error(lang.languageDescription + " " + "is already added");
        document.getElementById("freeArticleData1234").focus();
        document.getElementById("freeArticleData1234").value = "0";
        setMemberName122("0");
        flag = false;
        // 		// alertlanguage()
        // 		// window.scrollTo(0, 0)
      }
    }
    var lang = getLangList.find((object) => object.languageId == memberName122);
    if (flag === true) {
      let copy1 = JSON.parse(JSON.stringify(addValue1));
      copy1.push({ memberName122: lang.languageDescription, id: lang.languageId });
      setAddValue1(copy1);
      setMemberName122("");
      setMemberName1223("");
    }
  };
  const setFreeArticleData234 = (id, itemName, mode, dropdownId) => {
    // $("#" + mode).val(itemName)
    document.getElementById(mode).value = itemName;
    setMemberName1223(id);
    setMemberName122(document.getElementById("freeArticleData1234").value);
    //$("#" + dropdownId).hide()
    document.getElementById(dropdownId).style.display = "none";
  };
  const onDescChange1422 = (event) => {
    const { name, value } = event.target;
    setMemberName122(event.target.value);
  };
  const cityAutocomplete234 = (mode, dropdownId) => {
    const apiJson = {
      //  searchKeyword: $("#" + mode).val()
      searchKeyword: document.getElementById(mode).value,
    };
    // if (document.getElementById(mode).value.length > 0) {
    callingAPI("addPatient/autoCompleteGetLanguages", apiJson).then((data) => {
      if (data.data.success === "1") {
        //  $("#" + dropdownId).show()
        document.getElementById("locationSearch1234").style.display = "block";
        var proArray = data.data.result;
        var filteredData = [];
        if (document.getElementById(mode).value !== "") {
          filteredData = proArray.filter((item) => {
            return Object.values(item.languageDescription)
              .join("")
              .toLowerCase()
              .includes(document.getElementById(mode).value.toLowerCase());
          });
          setFreeArticleData1234([...filteredData]);
          document.addEventListener("mouseup", function (e) {
            var container = document.getElementById("locationSearch1234");
            if (container !== null) {
              if (!container.contains(e.target)) {
                container.style.display = "none";
              }
            }
          });
        } else if (document.getElementById(mode).value.length > 0) {
          document.getElementById("locationSearch1234").style.display = "block";
          // 	filteredData = proArray.filter((item) => {
          // 		return Object.values(item.languageDescription).join('').toLowerCase().includes(document.getElementById(mode).value.toLowerCase())
          //    })
          // 	setFreeArticleData1234([...filteredData]);
        } else {
          document.getElementById("locationSearch1234").style.display = "none";
        }
      } else {
        setFreeArticleData1234([]);
        // $("#" + dropdownId).hide()
        document.getElementById("locationSearch1234").style.display = "block";
      }
    });
    // } else {
    // }
  };
  const removeLanguage = (e, index) => {
    let newFormValues1 = [...addValue1];
    newFormValues1.splice(index, 1);
    setAddValue1(newFormValues1);
  };
  const childName = (event) => {
    setName(event.target.value);
  };
  const childDobChange = (date) => {
    setChildDOB(date);
  };
  const isVaccine = (event) => {
    setPregVaccine(event.target.checked);
  };
  const addAchievements = () => {
    let copy = JSON.parse(JSON.stringify(disposableitemsaddedarr));
    copy.push({
      // itemName: document.getElementById('itemName').value,
      childNamelist: name,
      childNameDob: moment(childDOB).format("YYYY-MM-DD"),
      childNameVaccine: pregVaccine,
      // hospitalInventoryCategoryId: categoryType,
    });
    setDisposableItemsAddedArr(copy);
    // setCategoryType('0');
    setName("");
    setChildDOB("");
    setPregVaccine("");
    // setItemName('');
    // document.getElementById('itemName').value = '';
    //document.getElementById("myText").value(addValue)
  };
  const removeAchievementItem = (e, i) => {
    let newChildDetail = [...disposableitemsaddedarr];
    newChildDetail.splice(i, 1);
    setDisposableItemsAddedArr(newChildDetail);
  };
  const saveBalanceAmount = () => {
    let flag = true;
    const re = /^[0-9\b]+$/;
    if (!balanceAmount) {
      toast.error("Enter Balance Amount")
      flag = false;
    }
    if (flag === true) {
      const postdata = {
        hospitalId: localStorage.getItem("hospitalId"),
        memberId: window.sessionStorage.getItem('memberId'),
        dueOrAdvanceAmount: balanceAmount,
        userId: window.sessionStorage.getItem('userId'),
      };
      callingAPI('commonUserFunctions/memberHospitalBalanceAmountSave', postdata)
        .then((res) => {
          if (res.data.success === '1') {
            setBalanceAmount("");
            toast.success("Balance Amount Added");
            window.location.href = "/patients/patientconsult";
          } else {
            toast.error(res.data.errorMessage);
          }
        })
        .catch();

    }
  }
  return (
    <div>
      <div className="treatmentProfile" style={{ background: "#ececec" }}>
        <div className="treatmentProfileCover">
          <div
            className="treatmentPlanHead"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            {/* {load === false ? <Loader /> : ""} */}
            <span className="treatmentPlanName mt-1">Patient Profile </span>
            {/* <span>
              <input className="inputField inputField_balnce" type="text" placeholder="Add Balance Amount"
                id="balanceAmount"
                name="balanceAmount"
                value={balanceAmount}
                onChange={(e) => setBalanceAmount(e.target.value)}
                onKeyPress={(event) => {
                  if (!/^[0-9\b .]+$/.test(event.key)) {
                    event.preventDefault();
                    alert("Only Numbers Accepted");
                  }
                }}
              />
            </span> */}
            {/* <span className="clinicalSave clinicalSave-margn" onClick={saveBalanceAmount}>Save</span> */}
            <div
              id="treatmentPlanBtnEdit"
              className="treatmentPlanBtn"
              style={{ display: !showEditDiv ? "" : "none" }}
            >
              <a href="/patients/addToGroup">
                <span className="practicementBtn">Add to Group</span>
              </a>
              <span
                className="practicementBtn"
                id="patientProfileEditId"
                onClick={editPatientProfile}
              >
                Edit Patient Profile
              </span>
            </div>
            <div
              id="treatmentPlanBtnSave"
              className="treatmentPlanBtn"
              style={{ display: showEditDiv ? "" : "none" }}
            >
              <span
                className="clinicalCancel"
                id="cancel"
                onClick={cancelUpdate}
              >
                Cancel
              </span>
              <span
                className="clinicalSave"
                id="save"
                onClick={saveMemberDetails}
              >
                Save
              </span>
            </div>
          </div>
          <div
            id="patientProfileId"
            className="treatmentPlanContent"
            style={{ display: !showEditDiv ? "" : "none" }}
          >
            <div className="profileCover">
              <div className="profileImg">
                {imagePath == girl || imagePath == boy ? (
                  <img title=" No Image" src={imagePath} />
                ) : (
                  <img title="" src={imagePath} />
                )}
              </div>

              <div className="profileDetails">
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    First Name <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">{getFirstName}</span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Last Name <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">{getLastName} </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    SMS Alert <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    {smsAlertCheck ? "YES" : "NO"}
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Email Alert <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    {emailAlertCheck ? "YES" : "NO"}
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    MR Number <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">{getMrNo}</span>
                </div>
                {window.sessionStorage.getItem('isPrNumEnabled') === "true" ?
                  getPrNoSet !== "" ? (
                    <div className="profileDetailList">
                      <label className="practiceLabel">
                        PR Number <span className="colspanLabel">:</span>
                      </label>
                      <span className="practiceContentText">{getPrNo}</span>
                    </div>

                  ) : (<div className="profileDetailList">
                    <label className="practiceLabel">
                      PR Number <span className="colspanLabel">:</span>
                    </label>
                    <span className="practiceContentText"></span>
                  </div>)
                  :
                  ""}
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Aadhaar ID <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">{getAdhaarId}</span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Gender <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    {getPatientGender}
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Date of Birth <span className="colspanLabel">:</span>
                  </label>
                  <span id="dobId" className="practiceContentText">
                    {getAge !== "" && getDob !== "01/01/1900" ? getDob : ""}
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Age <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    {getAge !== "" && getDob !== "01/01/1900" ? getAge : ""}
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Blood Group <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    {getBloodGroup === "Select" ? "" : getBloodGroup}
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Language(s) <span className="colspanLabel">:</span>
                  </label>
                  {getLanguage.length > 0 ? (
                    <span className="practiceContentText">
                      {/* {getLanguage.map((item,index)=>( */}
                      {Array.prototype.map
                        .call(getLanguage, function (item) {
                          return item.memberLanguageDescription;
                        })
                        .join(", ")}
                      {/* ))} */}
                      {/* <div>
									<table>
										<thead></thead>
									{getLanguage.map((item,index)=>(
										<tbody>
											<tr>
												<td>{item.memberLanguageDescription}</td>
											</tr>
										</tbody>
										))}
									</table>
									</div> */}
                      {/* {getLanguage} */}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {/* {window.sessionStorage.getItem('specializationId') === '479' ? ( */}
                <div>
                  <div className="profileDetailList">
                    <label className="practiceLabel">
                      Diabetes <span className="colspanLabel">:</span>
                    </label>
                    <span className="practiceContentText">
                      {getDiabetes ? getDiabetes + " Year(s)" : ""}
                    </span>
                  </div>
                  <div className="profileDetailList">
                    <label className="practiceLabel">
                      Dyslipidemia <span className="colspanLabel">:</span>
                    </label>
                    <span className="practiceContentText">
                      {getDyslipidemia ? getDyslipidemia + " Year(s)" : ""}
                    </span>
                  </div>
                  <div className="profileDetailList">
                    <label className="practiceLabel">
                      CAD <span className="colspanLabel">:</span>
                    </label>
                    <span className="practiceContentText">
                      {getCAD ? getCAD + " Year(s)" : ""}
                    </span>
                  </div>
                  <div className="profileDetailList">
                    <label className="practiceLabel">
                      Hypertension <span className="colspanLabel">:</span>
                    </label>
                    <span className="practiceContentText">
                      {getHypertension ? getHypertension + " Year(s)" : ""}
                    </span>
                  </div>
                </div>
                {/* ) : (
									''
								)} */}
                <div className="profileDetailList">
                  <h4>Contact Details</h4>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Mobile No <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    {getAreaCode + " " + getMobileNo}
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Phone No <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">{getPhoneNo}</span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Address <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">{getAddress}</span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Email Id <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">{getEmailId}</span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    City <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">{getcity1}</span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    State <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">{getState1}</span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Country <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">{getCountry1}</span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Zip/Pin <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">{getZipCode}</span>
                </div>
                {/* =========== PREGNANCY INFO BEGINS HERE  =========== */}
                {/* {getPregnancyDetails.length > 0 && getPatientGender=== "Female" && getAge1 >= 15 ?( */}
                {getPatientGender === "Female" &&
                  getAge1 >= 15 &&
                  getAge1 <= 60 ? (
                  <div>
                    <div className="profileDetailList">
                      <h4>Pregnancy Details</h4>
                    </div>
                    <div className="profileDetailList">
                      <label className="practiceLabel">
                        Due date <span className="colspanLabel">:</span>
                      </label>
                      <span className="practiceContentText">
                        {getMens1 !== "01-01-1900" && getMens1 !== "00-00-0000"
                          ? getMens1
                          : ""}
                      </span>
                    </div>
                    {window.sessionStorage.getItem(
                      "medicineBranchId"
                    ) !== "4" ? (
                      <div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            C-section <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {getCsection === 0 ? "" : getCsection}
                          </span>
                        </div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            Live birth <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {getLivebirth === 0 ? "" : getLivebirth}
                          </span>
                        </div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            Stillbirth <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {getStillbirth === 0 ? "" : getStillbirth}
                          </span>
                        </div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            Terminated <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {getTerminated === 0 ? "" : getTerminated}
                          </span>
                        </div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            Miscarriage <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {getMiscarriage === 0 ? "" : getMiscarriage}
                          </span>
                        </div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            Completed <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {getCompleted === 0 ? "" : getCompleted}
                          </span>
                        </div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            Previous Pregnancy Details{" "}
                            <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {getPrevPregDetail}
                          </span>
                        </div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            Previous Pregnancy Complications{" "}
                            <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {getPrevPregComp}
                          </span>
                        </div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            Medical Problems <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {getMedicalProb}
                          </span>
                        </div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            Current Condition{" "}
                            <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {getCurrentcondition}
                          </span>
                        </div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            SMS Alert Pregnancy/Vaccination{" "}
                            <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {pregSmsAlertCheck ? "YES" : "NO"}
                          </span>
                        </div>
                        <div className="profileDetailList">
                          <label className="practiceLabel">
                            Email Alert Pregnancy/Vaccination{" "}
                            <span className="colspanLabel">:</span>
                          </label>
                          <span className="practiceContentText">
                            {pregEmailAlertCheck ? "YES" : "NO"}
                          </span>
                        </div>
                        <div className="profileDetailList pregnancyInfo">
                          <label className="practiceLabel">
                            Children Details <span className="colspanLabel">:</span>
                          </label>
                          <span className="childrenDetails">
                            {getChildDetails.length > 0 ? (
                              <span class="childDetailShow">
                                <span class="childDetailTop">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th class="col-md-2 col-lg-2 thBg">Name</th>
                                        <th class="col-md-2 col-lg-2 thBg">DOB</th>
                                        <th class="col-md-2 col-lg-2 thBg">
                                          Vaccination
                                        </th>
                                      </tr>
                                    </thead>
                                    {getChildDetails.map((item, index) => (
                                      <tbody>
                                        <tr>
                                          <td class="col-md-2 col-lg-2 thBg">
                                            {item.childName}
                                          </td>
                                          <td class="col-md-2 col-lg-2 thBg">
                                            {item.childDateOfBirth}
                                          </td>
                                          <td class="col-md-2 col-lg-2 thBg">
                                            {item.hasVaccinationPlanner === 0
                                              ? "NO"
                                              : "YES"}
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </table>
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                          {/* <span className="practiceContentText">text</span> */}
                        </div>
                      </div>
                    ) : ("")}
                  </div>
                ) : (
                  ""
                )}
                {/* =========== PREGNANCY INFO ENDS HERE  =========== */}
              </div>
            </div>
          </div>
          <div
            onClick={(e) => qaz()}
            id="patientProfileEditId"
            className="treatmentPlanContent"
            style={{ display: showEditDiv ? "" : "none" }}
          >
            <div className="profileCover">
              <div className="profileImg">
                <div className="webCamSection">
                  <div className="webcamOpen1" id="results1" style={{ display: (imageName || imagePath) ? 'block' : 'none' }}>
                    <img
                      src={
                        base64 ? base64 : imageName || imageName ? imagePath : imagePath || image
                        // base64||image
                      }
                      id="imageThumb1"
                      className="webOpenImage1"
                      title="No Image"
                    />
                    {/* <span
                      className="changeImage changeImgPro"
                      style={{ display:"block" }}
                      onClick={changeImage}
                    >
                      Change Image{" "}
                    </span> */}
                  </div>
                  <div className="webcamOpen" id="results" style={{ display: (imagePreview) ? 'block' : 'none' }}>
                    <img
                      src={imagePreview}
                      id="imageThumb"
                      className="webOpenImage"
                    />
                  </div>
                  <span
                    className="changeImage changeImgPro"
                    style={{ display: "block" }}
                    onClick={changeImage}
                  >
                    Change Image{" "}
                  </span>

                  <div
                    className="webcamDiv"
                    style={{ display: !display ? "" : "block", marginTop: '30px' }}
                  >
                    <div className="myCamera" id="my_camera" />
                    <div className="webCamButton">
                      {state.isData === 1 ? (
                        <WebcamCapture
                          onCapture={onCaptureHandler}
                          webCamImage={webCamImageFunction}
                          web={abcA}
                        />
                      ) : (
                        ""
                      )}
                      <span
                        style={{ display: display1 ? "" : "none" }}
                        className="borderButton borderButtonSmall"
                        onClick={add}
                      >
                        Take Snapshot
                      </span>
                      <span
                        style={{ display: display1 ? "" : "none" }}
                        className="uploadButton uploadButtonSmall"
                      >
                        <input
                          type="file"
                          id="file1"
                          onChange={(e) => handleImageUploadOnChange(e)}
                        />
                        <label className="profileEditImg" for="file1">
                          Upload
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profileDetails">
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    First name<sup>*</sup>
                    <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      type="text"
                      value={getFirstName}
                      onChange={(e) =>
                        setFirstName(e.target.value.toUpperCase())
                      }
                      name="getFirstName"
                      id="getFirstName"
                      className="inputField"
                    />
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Last name<span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      type="text"
                      value={getLastName}
                      onChange={(e) =>
                        setLastName(e.target.value.toUpperCase())
                      }
                      name="getLastName"
                      id="getLastName"
                      className="inputField"
                    />
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    MR Number<sup>*</sup>
                    <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      type="text"
                      value={getMrNo}
                      onChange={(e) => isMrNo(e)}
                      name="getMrNo"
                      id="getMrNo"
                      className="inputField"
                    />
                  </span>
                </div>
                {getPrNoSet !== "" ? (
                  <div className="profileDetailList">
                    <label className="practiceLabel">
                      PR Number<sup>*</sup>
                      <span className="colspanLabel">:</span>
                    </label>
                    <span className="practiceContentText">
                      <input
                        type="text"

                        value={getPrNo}
                        onChange={(e) => isPrNo(e)}
                        name="getPrNo"
                        id="getPrNo"
                        className="inputField"
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Aadhaar ID <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      type="text"
                      value={getAdhaarId}
                      onChange={(e) => setAdhaarId(e.target.value)}
                      name="setAdhaarId"
                      id="setAdhaarId"
                      className="inputField"
                    />
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Gender<sup>*</sup>
                    <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <span class="radioSection1">
                      <input
                        type="radio"
                        onChange={(e) => setPatientGender(e.target.value)}
                        value="Male"
                        id="radio1"
                        name="gender"
                      />
                      <label for="radio1" />
                      Male
                    </span>
                    <span class="radioSection1">
                      <input
                        onChange={(e) => setPatientGender(e.target.value)}
                        type="radio"
                        value="Female"
                        id="radio2"
                        name="gender"
                      />
                      <label for="radio2" />
                      Female
                    </span>
                  </span>
                </div>
                <div className="profileDetailList" style={{ marginBottom: '10px' }}>
                  <label className="practiceLabel">
                    Date of Birth <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <Datepicker
                      required
                      id="dobId"
                      className="Datepicker pa2 inputField"
                      calendarClassName="rasta-stripes"
                      selected={patientDOB}
                      dateFormat="dd/MM/yyyy"
                      // maxDate={moment().toDate()}
                      maxDate={new Date()}
                      // value={patientDOB ? moment(patientDOB).format('DD/MM/YYYY') : getDob}
                      onChange={(date) => {
                        ageChange(date);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Age <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      type="text"
                      value={getAge}
                      onChange={(e) => ageChange1(e.target.value)}
                      //onChange={ageChange1}
                      name="getAge"
                      id="getAge"
                      maxLength={3}
                      onClick={(e) => ageChange12(e.target.value)}
                      className="inputField"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                          toast.error("Only Number Accepted");
                        }
                      }}
                    />
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Blood Group <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <select
                      onChange={(e) => setBloodGroup(e.target.value)}
                      value={getBloodGroup}
                      name="getBloodGroup"
                      id="getBloodGroup"
                      className="inputField"
                    >
                      <option>Select</option>
                      <option>A+</option>
                      <option>A-</option>
                      <option>B+</option>
                      <option>B-</option>
                      <option>AB+</option>
                      <option>AB-</option>
                      <option>O+</option>
                      <option>O-</option>
                    </select>
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Language(s) <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    {/* ================== */}
                    <div className="row rowMargin proAddLangInput">
                      {/* ------------------------------- */}
                      <div
                        className="col-md-6 col-lg-8 row-margin"
                        style={{ padding: "0px" }}
                      ><div class="newAppoinmentRightList" style={{ width: "100%", padding: "0px" }}>
                          {/* <label className="formLabel">Blood Group</label> */}
                          <div className="appointmentFormList">
                            <select
                              className="formInput"
                              id="freeArticleData1234"
                              value={memberName122}
                              name={memberName122}
                              onChange={onDescChange1422} style={{ width: '90%', border: '1px solid #dcdcdc' }}
                            >
                              <option value="0">Select Language</option>
                              {getLangList.map((obj, i) => (
                                <option value={obj.languageId}>{obj.languageDescription}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {/* <input
                          type="text"
                          id="freeArticleData1234"
                          placeholder="Type your Language"
                          className="input-design formInput inputField"
                          autoComplete="off"
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={memberName122}
                          name={memberName122}
                          onChange={onDescChange1422}
                          // name="languageDescription"
                          // placeholder="Type your Language"
                          // className="input-design formInput"
                          // autoComplete="off"
                          onKeyUp={() =>
                            cityAutocomplete234(
                              "freeArticleData1234",
                              "locationSearch1234"
                            )
                          }
                        /> */}
                        <ul
                          className="autoSearch1 autoSearch-p0 appointmentNumberSearch "
                          id="locationSearch1234"
                          style={{ display: "none" }}
                        >
                          {freeArticleData1234.length > 0 ? (
                            <div>
                              {freeArticleData1234.map(
                                (freeArticleDataObj, i) => (
                                  <li

                                    onClick={() =>
                                      setFreeArticleData234(
                                        freeArticleDataObj.languageId,
                                        freeArticleDataObj.languageDescription,
                                        "freeArticleData1234",
                                        "locationSearch1234"
                                      )
                                    }
                                  >
                                    {freeArticleDataObj.languageDescription}
                                  </li>
                                )
                              )}
                            </div>
                          ) : (
                            <div>
                              <li>
                                <div
                                  id="ui-id-5"
                                  tabindex="-1"
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    color: "#525252",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {nodata}
                                </div>
                              </li>
                            </div>
                          )}
                        </ul>
                      </div>
                      <div
                        className="col-md-1 col-1"
                        style={{
                          padding: "2px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div className="form-group proAddLanguage">
                          <span onClick={(e) => addLang(e)}>
                            <img
                              src={Add}
                              title="Add Language"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="table-responsive">
                          {/* <table
																class="table"
																id="tblAchievements1"
																style={{ display: 'table' }}
															> */}
                          {/* <thead>
																<tr id="grid-profile-docreg">
																	<th class="col-md-8">+ Add Language</th>
																</tr>
															</thead> */}
                          {/* <tbody> */}
                          {addValue1.map((item, index) => (
                            <span id="gridnew_docedit_achtrow " class="row LangList">
                              <span className="col-md-6 col-lg-4" style={{ marginBottom: '3px' }}>
                                <input
                                  disabled="disabled"
                                  type="text"
                                  name="achievementDes1"
                                  id="achievementDes1"
                                  value={item.memberName122}
                                  className="input-design formInput" style={{ marginBottom: '5px', border: '1px solid #dcdcdc' }}
                                />
                              </span>
                              <span className="col-md-6 col-lg-1 dalignCenter">
                                {/* <a id="remove" tabindex="4" href="#"> */}
                                <img
                                  style={{ width: "20px", marginTop: '-7px' }}
                                  src={Delete}
                                  title="Remove Language"
                                  onClick={(e) => removeLanguage(e, index)}
                                />
                                {/* </a> */}
                              </span>
                            </span>
                          ))}{" "}
                          {/* </tbody> */}
                          {/* </table> */}
                        </div>
                      </div>
                    </div>
                    {/* <div class="row">
															<div class="table-responsive langAddOption">
																	<span  class="row proAddLangInput">
																		<span className="col-md-6 col-lg-7" style={{padding:"0px"}}>
																			<input
																				disabled="disabled"
																				type="text"
																				name="achievementDes1"
																				id="achievementDes1"
																				value='Malayalam'
																				className="input-design formInput inputField"
																			/>
																		</span>
																		<span className="col-md-6 col-lg-1 dalignCenter">
																			<a id="remove" tabindex="4" href="#" className='form-group proRemoveLanguage'>
																				<img
																					style={{ width: '20px' }}
																					src={Delete}
																					title="Remove Language"/>
																			</a>
																		</span>
																	</span>
															</div>
														</div> */}
                    {/* <div class="row">
															<div class="table-responsive langAddOption">
																	<span  class="row proAddLangInput">
																		<span className="col-md-6 col-lg-7" style={{padding:"0px"}}>
																			<input
																				disabled="disabled"
																				type="text"
																				name="achievementDes1"
																				id="achievementDes1"
																				value='English'
																				className="input-design formInput inputField"
																			/>
																		</span>
																		<span className="col-md-6 col-lg-1 dalignCenter">
																			<a id="remove" tabindex="4" href="#" className='form-group proRemoveLanguage'>
																				<img
																					style={{ width: '20px' }}
																					src={Delete}
																					title="Remove Language"/>
																			</a>
																		</span>
																	</span>
															</div>
														</div> */}

                    {/* ================== */}
                  </span>
                </div>
                {/* {window.sessionStorage.getItem('specializationId') === '479' ? ( */}
                <div>
                  <div className="profileDetailList">
                    <label className="practiceLabel">
                      Diabetes <span className="colspanLabel">:</span>
                    </label>
                    <span className="practiceContentText">
                      <input
                        type="text"
                        maxLength={3}
                        onChange={(e) => setDiabetes(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={getDiabetes}
                        //	onChange={handleChange}
                        name="getDiabetes"
                        id="getDiabetes"
                        className="inputField inputFieldXtraSmall"
                      />
                      Year(s)
                    </span>
                  </div>
                  <div className="profileDetailList">
                    <label className="practiceLabel">
                      Dyslipidemia <span className="colspanLabel">:</span>
                    </label>
                    <span className="practiceContentText">
                      <input
                        type="text"
                        maxLength={3}
                        onChange={(e) => setDyslipidemia(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={getDyslipidemia}
                        //onChange={handleChange}
                        name="getDyslipidemia"
                        id="getDyslipidemia"
                        className="inputField inputFieldXtraSmall"
                      />
                      Year(s)
                    </span>
                  </div>
                  <div className="profileDetailList">
                    <label className="practiceLabel">
                      CAD <span className="colspanLabel">:</span>
                    </label>
                    <span className="practiceContentText">
                      <input
                        type="text"
                        onChange={(e) => setCAD(e.target.value)}
                        maxLength={3}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={getCAD}
                        //onChange={handleChange}
                        name="getCAD"
                        id="getCAD"
                        className="inputField inputFieldXtraSmall"
                      />
                      Year(s)
                    </span>
                  </div>
                  <div className="profileDetailList">
                    <label className="practiceLabel">
                      Hypertension <span className="colspanLabel">:</span>
                    </label>
                    <span className="practiceContentText">
                      <input
                        type="text"
                        onChange={(e) => setHypertension(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={getHypertension}
                        maxLength={3}
                        //	onChange={handleChange}
                        name="getHypertension"
                        id="getHypertension"
                        className="inputField inputFieldXtraSmall"
                      />
                      Year(s)
                    </span>
                  </div>
                </div>
                {/* ) : (
									''
								)} */}
                <div className="profileDetailList">
                  <h4>Contact Details</h4>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Mobile No <sup>*</sup>
                    <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <span class="mobileCountryCode">
                      <input
                        onChange={(e) => setAreaCode(e.target.value)}
                        type="text"
                        class="inputField"
                        value={getAreaCode}
                        name="getAreaCode"
                        id="getAreaCode1"
                      />
                    </span>
                    <span class="mobileNumberTxt">
                      <input
                        onChange={(e) => setMobileNo(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            toast.error("Only Numbers Accepted");
                          }
                        }}
                        type="text"
                        value={getMobileNo}
                        //onChange={handleChange}
                        name="getMobileNo"
                        id="getMobileNo"
                        placeholder="Mobile Number"
                        pattern="[0-9]*"
                        maxLength="10"
                        class="inputField"
                      />
                    </span>
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Phone No <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      onChange={(e) => setPhoneNo(e.target.value)}
                      maxLength={12}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                          toast.error("Only Numbers Accepted");
                        }
                      }}
                      type="text"
                      value={getPhoneNo}
                      //	onChange={handleChange}
                      name="getPhoneNo"
                      id="getPhoneNo"
                      class="inputField"
                    />
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Address <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      onChange={(e) => setAddress(e.target.value)}
                      type="text"
                      value={getAddress}
                      name="getAddress"
                      id="getAddress"
                      class="inputField"
                    />
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Email Id <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      onChange={(e) => setEmailId(e.target.value)}
                      type="text"
                      value={getEmailId}
                      name="getEmailId"
                      id="getEmailId"
                      class="inputField"
                    />
                  </span>
                </div>
                <div class="profileDetailList">
                  <label className="practiceLabel">
                    City <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      type="text"
                      id="freeArticleData1"
                      name="cityName"
                      // placeholder="Type your City"
                      className="inputField"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (/[0-9]/.test(event.key)) {
                          event.preventDefault();
                          // alert("Only Number Accepted")
                        }
                      }}
                      onKeyUp={() =>
                        cityAutocomplete("freeArticleData1", "locationSearch1")
                      }
                    />
                    <ul
                      className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
                      id="locationSearch1"
                      style={{ display: "none" }}
                    >
                      {freeArticleData1.map((freeArticleDataObj, i) => (
                        <li
                          onClick={() =>
                            setFreeArticleData(
                              freeArticleDataObj.cityName,
                              freeArticleDataObj.cityName,
                              "freeArticleData1",
                              "locationSearch1"
                            )
                          }
                        >
                          {freeArticleDataObj.cityName}
                        </li>
                      ))}
                    </ul>
                  </span>
                </div>
                <div class="profileDetailList">
                  <label className="practiceLabel">
                    State <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      type="text"
                      id="freeArticleData12"
                      name="stateName"
                      // placeholder="Type Your State"
                      className="inputField"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (/[0-9]/.test(event.key)) {
                          event.preventDefault();
                          // alert("Only Number Accepted")
                        }
                      }}
                      onKeyUp={() =>
                        cityAutocomplete2(
                          "freeArticleData12",
                          "locationSearch12"
                        )
                      }
                    />
                    <ul
                      className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
                      id="locationSearch12"
                      style={{ display: "none" }}
                    >
                      {freeArticleData12.map((freeArticleDataObj, i) => (
                        <li
                          onClick={() =>
                            setFreeArticleData2(
                              freeArticleDataObj.stateName,
                              freeArticleDataObj.stateName,
                              "freeArticleData12",
                              "locationSearch12"
                            )
                          }
                        >
                          {freeArticleDataObj.stateName}
                        </li>
                      ))}
                    </ul>
                  </span>
                </div>
                <div class="profileDetailList">
                  <label className="practiceLabel">
                    Country <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      type="text"
                      id="freeArticleData123"
                      name="countryName"
                      // placeholder="Type Your Country"
                      className="inputField"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (/[0-9]/.test(event.key)) {
                          event.preventDefault();
                          // alert("Only Number Accepted")
                        }
                      }}
                      onKeyUp={() =>
                        cityAutocomplete23(
                          "freeArticleData123",
                          "locationSearch123"
                        )
                      }
                    />
                    <ul
                      className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
                      id="locationSearch123"
                      style={{ display: "none" }}
                    >
                      {freeArticleData123.map((freeArticleDataObj, i) => (
                        <li
                          onClick={() =>
                            setFreeArticleData23(
                              freeArticleDataObj.countryName,
                              freeArticleDataObj.countryName,
                              "freeArticleData123",
                              "locationSearch123"
                            )
                          }
                        >
                          {freeArticleDataObj.countryName}
                        </li>
                      ))}
                    </ul>
                  </span>
                </div>
                {/* <div className="profileDetailList">
									<label className="practiceLabel">
										City <span className="colspanLabel">:</span>
									</label>
									<span className="practiceContentText">
										<input
											type="text"
											value={state.memberCity}
											onChange={(e) =>
												EditDataOnChange(e)}
											name="memberCity"
											id="memberCity"
											class="inputField"
											onKeyUp={() =>
												cityAutocomplete('memberCity', 'locationSearch1')}
										/>
										<ul
													className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
													id="locationSearch1"
													style={{ display: 'none' }}
												>
													{freeArticleData1.map((freeArticleDataObj, i) => (
														<li
															onClick={() =>
																setFreeArticleData(
																	freeArticleDataObj.cityName,
																	freeArticleDataObj.cityName,
																	'memberCity',
																	'locationSearch1'
																)}
														>
															{freeArticleDataObj.cityName}
														</li>
													))}
												</ul>
									</span>
								</div>
								<div className="profileDetailList">
									<label className="practiceLabel">
										State <span className="colspanLabel">:</span>
									</label>
									<span className="practiceContentText">
										<input
											type="text"
											value={state.memberState}
											onChange={(e) =>
												EditDataOnChange(e)}
											name="memberState"
											id="memberState"
											class="inputField"
											onKeyUp={() =>
												cityAutocomplete2('memberState', 'locationSearch12')}
										/>
										<ul
													className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
													id="locationSearch12"
													style={{ display: 'none' }}
												>
													{freeArticleData12.map((freeArticleDataObj, i) => (
														<li
															onClick={() =>
																setFreeArticleData2(
																	freeArticleDataObj.stateName,
																	freeArticleDataObj.stateName,
																	'memberState',
																	'locationSearch12'
																)}
														>
															{freeArticleDataObj.stateName}
														</li>
													))}
												</ul>
									</span>
								</div>
								<div className="profileDetailList">
									<label className="practiceLabel">
										Country <span className="colspanLabel">:</span>
									</label>
									<span className="practiceContentText">
										<input
											type="text"
											value={state.memberCountry}
											//onChange={EditDataOnChange}
											onChange={(e) =>
												EditDataOnChange(e)}
											name="memberCountry"
											id="memberCountry"
											class="inputField"
											onKeyUp={() =>
												cityAutocomplete23('memberCountry', 'locationSearch123')}
										/>
										<ul
													className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
													id="locationSearch123"
													style={{ display: 'none' }}
												>
													{freeArticleData123.map((freeArticleDataObj, i) => (
														<li
															onClick={() =>
																setFreeArticleData23(
																	freeArticleDataObj.countryName,
																	freeArticleDataObj.countryName,
																	'memberCountry',
																	'locationSearch123'
																)}
														>
															{freeArticleDataObj.countryName}
														</li>
													))}
												</ul>
									</span>
								</div> */}
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    PinCode/ZipCode<span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <input
                      onChange={(e) => setZipCode(e.target.value)}
                      type="text"
                      value={getZipCode}
                      name="getZipCode"
                      id="getZipCode"
                      class="inputField"
                    />
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    SMS Alert <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <span class="checkSection">
                      <input
                        type="checkbox"
                        id="checkAlert"
                        name="checkAlert"
                        checked={smsAlertCheck}
                        onChange={isSMSChange}
                      />
                      <label for="checkAlert" />
                    </span>
                  </span>
                </div>
                <div className="profileDetailList">
                  <label className="practiceLabel">
                    Email Alert <span className="colspanLabel">:</span>
                  </label>
                  <span className="practiceContentText">
                    <span class="checkSection">
                      <input
                        type="checkbox"
                        id="checkEmailAlert"
                        name="chekckEmailAlert"
                        checked={emailAlertCheck}
                        onChange={isEmailChange}
                      />
                      <label for="checkEmailAlert" />
                    </span>
                  </span>
                </div>
                {/* ========== PRGNANCY INFO EDIT BEGINS HERE =============== */}
                {getPatientGender === "Female" &&
                  getAge1 >= 15 &&
                  getAge1 <= 60 ? (
                  <div className="PregnancyProfile">
                    <div className="profileDetailList">
                      <h4>Pregnancy Details</h4>
                    </div>
                    <div className="prgnancyDetailsWrap">
                      {/* {window.sessionStorage.getItem("medicineBranchId") === */}
                      {/* "4" ? ( */}
                      <div class="gynecologyContentDiv">
                        <span class="gynecologyContentTxt">
                          Currently Pregnant
                        </span>
                        <div class="gynecologyContentSelect">
                          <input
                            type="radio"
                            name="chkpreg"
                            id="chkpreg"
                            onChange={(e) =>
                              setCurrently((e.target.value = true))
                            }
                          />
                          <label>Yes</label>
                          <input
                            type="radio"
                            name="chkpreg"
                            id="chkpreg1"
                            onChange={(e) =>
                              setCurrently((e.target.value = false))
                            }
                          />
                          <label>No</label>
                        </div>
                      </div>
                      {/* ) : ( */}
                      {/* "" */}
                      {/* )} */}
                      {getCurrently === true || getCurrently === 1 ? (
                        <div>
                          <div id="dateLast" class="gynecologyContentDiv">
                            {/* {window.sessionStorage.getItem( */}
                            {/* "medicineBranchId" */}
                            {/* ) !== "4" ? ( */}
                            <div>
                              <span class="gynecologyContentTxt">
                                First day of Last Menstruation Period
                              </span>
                              {/* <input type="text" name="fromDate" id="fromDate" placeholder="Last menstrual period" style={{ background: "none" }} /> */}
                              <Datepicker
                                required
                                className="Datepicker pa2 inputField"
                                calendarClassName="rasta-stripes"
                                selected={lastmens}
                                dateFormat="dd/MM/yyyy"
                                maxDate={moment().toDate()}
                                // value={
                                //   lastmens
                                //     ? moment(lastmens).format("DD/MM/YYYY")
                                //     : getMens !== "01-01-1900" &&
                                //       getMens !== "00-00-0000"
                                //     ?  moment(getMens).format("DD/MM/YYYY")
                                //     : ""
                                // }
                                onChange={(date) => {
                                  mensChange(date);
                                }}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onKeyDown={(e) => {
                                  // e.preventDefault();
                                  toast.error("Please select date");
                                }}
                                placeholderText="Last Mensturation Period"
                                style={{ float: 'left' }} />
                              <span
                                id="EDD"
                                class="gynecologyContentDiv"
                                style={{ width: "auto", marginBottom: "0px" }}
                              >
                                <span class="gynecologyContentTxt">
                                  Expected Due Date
                                </span>
                                <Datepicker
                                  required
                                  className="Datepicker pa2 inputField"
                                  calendarClassName="rasta-stripes"
                                  selected={lastmens1}
                                  dateFormat="dd/MM/yyyy"
                                  // maxDate={moment().toDate()}
                                  // value={
                                  //   lastmens1
                                  //     ? moment(lastmens1).format("DD/MM/YYYY")
                                  //     : getMens1 !== "01-01-1900" &&
                                  //       getMens1 !== "00-00-0000"
                                  //     ? getMens1
                                  //     : ""
                                  // }
                                  onChange={(date) => {
                                    mensChange1(date);
                                  }}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  placeholderText="Due Date"
                                />
                              </span>
                            </div>
                            {/* ) : ( */}
                            {/* "" */}
                            {/* )} */}
                          </div>
                          {window.sessionStorage.getItem(
                            "medicineBranchId"
                          ) !== "4" ? (
                            <div id="smsEmail" class="gynecologyContentDiv">
                              <div class="gynecologyContentSelect">
                                <label>Sms :</label>
                                <input
                                  type="checkbox"
                                  name="hasSms"
                                  id="hasSms"
                                  checked={pregSmsAlertCheck}
                                  onChange={isPregSMSChange}
                                />
                                <label>Email :</label>
                                <input
                                  type="checkbox"
                                  name="hasEmail"
                                  id="hasEmail"
                                  checked={pregEmailAlertCheck}
                                  onChange={isPregEmailChange}
                                />
                              </div>
                            </div>) : ("")}
                        </div>
                      ) : (
                        ""
                      )}
                      {window.sessionStorage.getItem(
                        "medicineBranchId"
                      ) !== "4" ? (
                        <div class="gynecologyWrap">
                          <div class="gynecologySplit">
                            <div class="gynecologyCheckArea">
                              <span>
                                <label>C-section :</label>
                                <input
                                  type="text"
                                  onChange={(e) => setCsection(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={getCsection === 0 ? "" : getCsection}
                                  //	onChange={handleChange}
                                  name="getCsection"
                                  id="getCsection"
                                //  name="noOfCSection"
                                />
                              </span>
                              <span>
                                <label>Live birth :</label>
                                <input
                                  type="text"
                                  onChange={(e) => setLivebirth(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={getLivebirth === 0 ? "" : getLivebirth}
                                  //	onChange={handleChange}
                                  name="getLivebirth"
                                  id="getLivebirth"
                                // name="noOfLiveBirth"
                                />
                              </span>
                              <span>
                                <label>Stillbirth :</label>
                                <input
                                  type="text"
                                  onChange={(e) => setStillbirth(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={getStillbirth === 0 ? "" : getStillbirth}
                                  //	onChange={handleChange}
                                  name="getStillbirth"
                                  id="getStillbirth"
                                // name="noOfStillBirth"
                                />
                              </span>
                              <span>
                                <label>Terminated :</label>
                                <input
                                  type="text"
                                  onChange={(e) => setTerminated(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={getTerminated === 0 ? "" : getTerminated}
                                  //	onChange={handleChange}
                                  name="getTerminated"
                                  id="getTerminated"
                                //  name="noOfTerminationOfPregnancy"
                                />
                              </span>
                              <span>
                                <label>Miscarriage :</label>
                                <input
                                  type="text"
                                  onChange={(e) => setMiscarriage(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={
                                    getMiscarriage === 0 ? "" : getMiscarriage
                                  }
                                  //	onChange={handleChange}
                                  name="getMiscarriage"
                                  id="getMiscarriage"
                                //  name="noOfMisCarriage"
                                />
                              </span>
                              <span>
                                <label>Completed :</label>
                                <input
                                  type="text"
                                  onChange={(e) => setCompleted(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={getCompleted === 0 ? "" : getCompleted}
                                  //	onChange={handleChange}
                                  name="getCompleted"
                                  id="getCompleted"
                                //  name="noOfCompletedPregnancies"
                                />
                              </span>
                            </div>
                          </div>
                        </div>) : ("")}
                      {window.sessionStorage.getItem(
                        "medicineBranchId"
                      ) !== "4" ? (
                        <div class="gynecologyInputBox">
                          <span class="gynecologyLabel">
                            <label>Previous Pregnancy Details</label>
                            <span class="profileSpan">
                              <input
                                type="text"
                                value={getPrevPregDetail}
                                onChange={(e) =>
                                  setPrevPregDetail(e.target.value)
                                }
                                name="setPrevPregDetail"
                                id="setPrevPregDetail"
                                placeholder="Previous Pregnancy Details"
                                style={{ width: "99%" }}
                              />
                            </span>
                          </span>
                          <span class="gynecologyLabel">
                            <label>Previous Pregnancy Complications :</label>
                            <span class="profileSpan">
                              <input
                                type="text"
                                value={getPrevPregComp}
                                onChange={(e) => setPrevPregComp(e.target.value)}
                                name="setPrevPregComp"
                                id="setPrevPregComp"
                                placeholder="Previous Pregnancy Complications"
                                style={{ width: "99%" }}
                              />
                            </span>
                          </span>
                          <span class="gynecologyLabel">
                            <label>Medical Problems :</label>
                            <span>
                              <input
                                type="text"
                                value={getMedicalProb}
                                onChange={(e) => setMedicalProb(e.target.value)}
                                name="setMedicalProb"
                                id="setMedicalProb"
                                placeholder="Medical Problems"
                              />
                            </span>
                          </span>
                          <span class="gynecologyLabel">
                            <label>Current Condition :</label>
                            <span>
                              <input
                                type="text"
                                value={getCurrentcondition}
                                onChange={(e) =>
                                  setCurrentcondition(e.target.value)
                                }
                                name="setCurrentcondition"
                                id="setCurrentcondition"
                                placeholder="Current Condition"
                              />
                            </span>
                          </span>
                        </div>) : ("")}
                      {window.sessionStorage.getItem(
                        "medicineBranchId"
                      ) !== "4" ? (
                        <div class="profileDetailList childListCvrDiv">
                          <label>Children Details :</label>
                          <div class="gynecologyChildList">
                            <div class="childListDiv ">
                              <span class="childNameSec">
                                <input
                                  type="text"
                                  name="txtchdName1"
                                  id="txtchdName1"
                                  onChange={childName}
                                  value={name}
                                  placeholder="Child Name"
                                />
                              </span>
                              <span class="childDobSec">
                                <Datepicker
                                  required
                                  className="Datepicker pa2 inputField"
                                  calendarClassName="rasta-stripes"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  id="txtchdDOB"
                                  name="txtchdDOB"
                                  selected={childDOB}
                                  autoComplete="off"
                                  maxDate={new Date()}
                                  // onChange={childDobChange}
                                  onChange={(date) => {
                                    childDobChange(date);
                                  }}
                                  defaultValue={moment(childDOB).format(
                                    "DD/MM/YYYY"
                                  )}
                                  dropdownMode="select"
                                  placeholderText="DOB"
                                />
                              </span>
                              <label style={{ paddingLeft: "15px" }}>
                                Vaccination
                              </label>
                              <input
                                type="checkbox"
                                name="chkVac"
                                id="chkVac"
                                checked={pregVaccine}
                                onChange={isVaccine}
                                title="Vaccination"
                              />
                              <span
                                onClick={(e) => addAchievements(e)}
                                class="trtmntPlanBtn pregnInfoAppend"
                                id="addChildButton"
                              >
                                Add +
                              </span>
                            </div>
                            <div id="appendDiv">
                              {disposableitemsaddedarr.length > 0 ? (
                                <div id="divClone">
                                  <table>
                                    <tbody>
                                      {disposableitemsaddedarr.map(
                                        (item, idx) => (
                                          <div class="childListDiv childListDivAppendTable">
                                            <tr>
                                              <td>
                                                <span class="childNameSec">
                                                  <input
                                                    type="text"
                                                    id={"childNamelist" + idx}
                                                    placeholder="Child Name"
                                                    name="childNamelist"
                                                    // value="test"
                                                    value={item.childNamelist}
                                                    style={{ width: "97%" }}
                                                  />
                                                </span>
                                              </td>
                                              <td>
                                                <span class="childNameSec">
                                                  <input
                                                    type="text"
                                                    id={"childNameDob" + idx}
                                                    placeholder="Child DOB"
                                                    name="childNameDob"
                                                    // value="test"
                                                    value={item.childNameDob}
                                                    style={{ width: "63%" }}
                                                  />
                                                </span>
                                              </td>
                                              <span className="vaccineTxtWrap">
                                                <td>
                                                  <label
                                                    style={{
                                                      paddingLeft: "15px",
                                                    }}
                                                  >
                                                    Vaccination
                                                  </label>
                                                  <input
                                                    type="checkbox"
                                                    // name="chkVacve"
                                                    // id="chkVacve"
                                                    id={"childNameVaccine" + idx}
                                                    // placeholder="Child DOB"
                                                    name="childNameVaccine"
                                                    title="Vaccination"
                                                    // defaultValue={
                                                    // 	item.childNameDob
                                                    // }
                                                    checked={
                                                      item.childNameVaccine
                                                    }
                                                  />
                                                </td>
                                                <span
                                                  class="removeClose"
                                                  id="removeClose"
                                                  onClick={(e) =>
                                                    removeAchievementItem(e, idx)
                                                  }
                                                >
                                                  x
                                                </span>
                                              </span>
                                            </tr>
                                          </div>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ) : ("")}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* ========== PRGNANCY INFO EDIT ENDS HERE =============== */}
              </div>
            </div>
          </div>

          {/* ============  Add To Group Begins Here ================ */}

          {/* <div id="patientProfileId" className="treatmentPlanContent">
						<div
							class="treatmentProfileCover memberProfileDiv_PTour"
							id="patientGroup"
							style={{
								background: '#fff',
								padding: '30px 15px',
							}}
						> */}
          {/* <div class="patientGroupDiv" id="showGroups">
								<div class="patientGroupTop" style={{ paddingLeft: '90px' }}>
									<label>Active Groups : &nbsp;</label>
									<span>
										<select
											name="hospitalGroup"
											class="dentalFilesSelectType_PTour"
											id="hospitalGroup"
											style={{ width: '50%' }}
										>
											<option value="0">Select Group</option>
										</select>
										<span class="GroupAddButton">
											<input type="button" value="Add" class="commonButton" />
											<input type="button" value="Cancel" class="commonButtonCancel" />
										</span>
									</span>
								</div>
								<br />
								<div id="patientsInGroups" class="patientGroupSec">
									<div class="patientGroupItem">
										<span class="patientGroupName">hospitalPatientGroupName</span>
										<span class="patientClose">
											<img src={Inactive} title="Click to Activate" alt="" />
										</span>
									</div>
									<div class="patientGroupItem">
										<span class="patientGroupName">hospitalPatientGroupName</span>
										<span class="patientClose">
											<img
												src={Active}
												alt=""
												title="Click to Deactivate"
												className="mr-2  conf-cursorpointer"
												width="20"
											/>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div> */}
          {/* =============  Add To Group Ends Here =============== */}
        </div>
      </div>
    </div>
  );
}

export default Profile;
