import React, { useState, useEffect } from "react";
import { callingAPI } from "../config";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Leftmenu from "../Patients/leftmenu";
import Profiledetail from "../Patients/profiledetail";
import Patientright from "../Patients/patientright";
import img1 from "../images/icons/treatment.png";
import tivkmark from "../images/icons/tivkmark.png";
import Printer_icon from "../images/icons/printer_icon.png";
import Sms from "../images/icons/chat.png";
import Editicon from "../images/icons/edit-bg.png";
import Loader from "../Common/loader";
import { Link } from "react-router-dom";
import Delete from "../images/icons/delete_btn.png";
import sendEmail from "../images/icons/sendEmail.png";
import { useParams } from "react-router-dom";
import NoLaborder from "../Common/noLaborderFound";
import { SettingsBackupRestore } from "@material-ui/icons";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var moment = require("moment");
function LabOrder() {
  const navigate = useNavigate();

  let { forLabOrderPrint } = useParams();
  const [newResultsetData, setNewResultsetData] = useState([]);
  const [load, setLoad] = useState(false);
  const [getData, setData] = useState(false);
  const [dropDownCounter, setDropDownCounter] = useState({ id: "", count: 0 });
  const [procedureCheckIds, setprocedureCheckIds] = useState([]);
  const [orderId, setOrderId] = useState("");
  useEffect(() => {
    if (forLabOrderPrint === "1") {
      window.open(`${process.env.PUBLIC_URL}/patients/labOrderPrint`, "_blank");
    }
    listing();
  }, []);
  const test = (dateObj, VistIdObj, orderId, labOrderTestId1) => {
    var array1 = [];
    for (let i = 0; i < labOrderTestId1.length; i++) {
      array1.push(labOrderTestId1[i].labOrderTestId);
    }
    window.sessionStorage.setItem("singlePrintId", array1.toString());
    window.sessionStorage.setItem("getlabDate", dateObj);
    window.sessionStorage.setItem("getdoctorVisitId", VistIdObj);
    window.sessionStorage.setItem("getorderId", orderId);
    // window.location.href = "/patients/labOrderPrint";
    window.open(`${process.env.PUBLIC_URL}/patients/labOrderPrint/${window.sessionStorage.getItem("singlePrintId") + '/' + localStorage.getItem('hospitalId')}`, '_blank');
  };
  const listing = () => {
    const apiJson = {
      userId: window.sessionStorage.getItem("userId"),
      moduleName: "Lab Order",
      memberId: window.sessionStorage.getItem("memberId"),
      hospitalId: localStorage.getItem("hospitalId"),
      labOrderTestId: "",
    };
    callingAPI("adminReports/labOrderPayment", apiJson).then((data) => {
      if (data.data.success === "1") {
        setNewResultsetData(data.data.testData);
        if (data.data.testData.length) {
          setOrderId(data.data.testData[0].itemArray[0].data[0].orderId);
        }
        setData(true);
      }
      setLoad(true);
    });
  };
  const dropList = (divId, i) => {
    var elementDisplay = document.getElementById(divId + i);
    elementDisplay.style.display =
      dropDownCounter.id === divId + i && dropDownCounter.count === 1
        ? "none"
        : "block";
    setDropDownCounter({
      id: divId + i,
      count:
        dropDownCounter.id === divId + i && dropDownCounter.count === 1 ? 0 : 1,
    });
    document.addEventListener("mouseup", function (e) {
      var container = document.getElementById(divId + i);
      if (container !== null) {
        if (!container.contains(e.target)) {
          container.style.display = "none";
        }
      }
    });
  };
  const PatientInvoiceClick = (data) => {
    navigate('/patients/patientInvoiceAdd', { state: data })
  }
  const itemDelete = (divId, id) => {
    for (var i = 0; i < id.length; i++) {
      const apiJson = {
        doctorVisitLabOrderId: id[i].orderId,
      };
      callingAPI("adminReports/deletelabOrder", apiJson).then((data) => {
        if (data.data.success === "1") {
          // alert(data.data.successMessage);
          //alert('Lab Test Deleted Successfully');
          //dropList(divId,id)
          //listing();
        }
        //setLoad(true);
      });
    }
    toast.success("Lab Test Deleted Successfully");
    dropList(divId, id[0].orderId);
    listing();
  };
  const sendSms = () => {
    const postData = {
      userId: window.sessionStorage.getItem("memberId"),
      orderId: orderId,
      hospitalId: localStorage.getItem("hospitalId"),
      siteId: "",
    };
    callingAPI("commonUserFunctions/labOrderMedicalSendSms", postData)
      .then((data) => {
        if (data.data.success === "1") {
          const PostData = {
            functionName: "createAppointmentMail",
            siteId: "",
            smsObj: {
              mobileNumber: data.data.result.smsObj.mobileNumber,
              message: data.data.result.smsObj.message,
              userId: data.data.result.smsObj.userId,
              siteId: data.data.result.smsObj.siteId,
              hospitalId: data.data.result.smsObj.hospitalId,
              smsType: data.data.result.smsObj.smsType,
              smsTemplateId: data.data.result.smsObj.smsTemplateId,
            },
          };
          callingAPI("commonUserFunctions/createAppointmentMail", PostData)
            .then((response) => {
              if (response.data.success === "0") {
                toast.error("error message");
              } else if (response.data.success === "1") {
                toast.success("SMS Send Successfully");
              }
            })
            .catch();
        }
      })
      .catch();
  };
  const sendEmails = (docVisitId, date) => {
    moment(date).format("YYYY-MM-DD");
    const postData = {
      userId: window.sessionStorage.getItem("memberId"),
      doctorId: window.sessionStorage.getItem("doctorId"),
      hospitalId: localStorage.getItem("hospitalId"),
      doctorVisitId: docVisitId,
      labOrderTestDate: moment(date).format("YYYY-MM-DD"),
      orderId: orderId,
    };
    callingAPI("commonUserFunctions/labOrderMedicalSendSmsMail", postData)
      .then((data) => {
        if (data.data.success === "1") {
          const PostData = {
            jsonMailOptions: {
              to: data.data.result.jsonMailOptions.to,
              AdminEmailId: data.data.result.jsonMailOptions.AdminEmailId,
              SupportEmailId: data.data.result.jsonMailOptions.SupportEmailId,
              subject: data.data.result.jsonMailOptions.subject,
              html: data.data.result.jsonMailOptions.html,
              bccEmailRequired:
                data.data.result.jsonMailOptions.bccEmailRequired,
              ccEmailRequired: data.data.result.jsonMailOptions.ccEmailRequired,
            },
          };
          callingAPI("commonUserFunctions/createAppointmentMail", PostData)
            .then((response) => {
              if (response.data.success === "0") {
                toast.error("Something went wrong");
              } else if (response.data.success === "1") {
                toast.success("Email Send Successfully");
                window.location.href = "/patients/labOrder";
              }
            })
            .catch();
        }
      })
      .catch();
  };
  const invoiceClick = () => {
    document.getElementById("treatmentCompleteTick").click();
  };
  const checkedForInvoice = (labOrderTestId, i, j, e) => {
    var completedArray = [...procedureCheckIds];
    if (typeof completedArray === "undefined") {
      completedArray = [];
    }
    var treatmentArrayIndex = completedArray.indexOf(labOrderTestId);
    if (treatmentArrayIndex >= 0) {
      completedArray.splice(treatmentArrayIndex, 1);
    } else {
      completedArray.push(labOrderTestId);
    }
    setprocedureCheckIds(completedArray);
    if (completedArray.length > 0) {
      document.getElementById("invoice").classList.remove("test");
    } else {
      document.getElementById("invoice").classList.add("test");
    }
  };
  return (
    <div className="coverWraper">
      <ToastContainer position="bottom-right" />
      <Header />
      <div className="contentWraper contentWraperScroll patientPayment plInvoice plLabOrderChbx labOrderMainWrap">
        <Leftmenu />
        <div className="rightSection">
          <Profiledetail />
          <div className="practiceManagementWrap labOdrDpdn">
            <div className="row practiceManagementRow">
              <div className="col-lg-8 col-md-12 practiceLeftSection">
                {load === false ? <Loader /> : ""}
                <div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
                  <div id="divPatientContent" class="sideMenuContent">
                    {getData === true ? (
                      <div class="treatmentPlanCover" id="treatmentPlanListDiv">
                        <div class="treatmentPlanHead">
                          <span class="treatmentPlanName">
                            LAB ORDER &nbsp;
                          </span>
                          <span class="treatmentPlanBtn pl_blue_btn">
                            <a href="/patients/labOrderAdd">+ Add</a>
                          </span>
                          <span
                            class="treatmentPlanBtn pl_blue_btn test"
                            id="invoice"
                            onClick={() => invoiceClick()}
                          >
                            <nav onClick={() => PatientInvoiceClick({
                              labOrderInvoiceIds: procedureCheckIds,
                              hospitalId:
                                localStorage.getItem("hospitalId"),
                              memberId:
                                window.sessionStorage.getItem("memberId"),
                              isFlag: ""
                            })}
                              style={{ display: "none" }}
                              id={"treatmentCompleteTick"}
                            ></nav>
                            Create invoice for selected treatment
                          </span>
                        </div>
                        {newResultsetData.length > 0 ? (
                          <div>
                            {newResultsetData.map((data, i) => (
                              <div class="treatmentPlanList1">
                                <div class="treatmentPlanDate">
                                  {data.testDate}
                                </div>
                                <div class="treatmentTable invoiceTableWrap">
                                  <div class="invoiceTableCvrDiv">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th class="col-md-4">
                                            <div class="dentalProName">
                                              TEST NAME
                                            </div>
                                          </th>
                                          <th class="col-md-3">
                                            <div
                                              class="dentalProName"
                                              style={{ paddingLeft: "0px" }}
                                            >
                                              SPECIMEN
                                            </div>
                                          </th>
                                          <th class="col-md-2">
                                            <div
                                              class="dentalProName"
                                              style={{ paddingLeft: "0px" }}
                                            >
                                              TEST TYPE
                                            </div>
                                          </th>
                                          <th class="col-md-3">
                                            <div class="dentalProNote">
                                              NOTES
                                            </div>
                                          </th>
                                        </tr>
                                      </thead>
                                      {data.itemArray.map((item, j) => (
                                        <tbody>
                                          <tr style={{ border: "none" }}>
                                            <td colSpan={10}>
                                              <div
                                                class="dentalProNote paddingFive"
                                                style={{ padding: "0px" }}
                                              >
                                                <div class="dentalProNote">
                                                  <span
                                                    class="elipsis"
                                                    title=""
                                                  >
                                                    &nbsp;
                                                  </span>
                                                  <button className="pl_print">
                                                    <a target="new">
                                                      <img
                                                        src={Printer_icon}
                                                        onClick={() =>
                                                          test(
                                                            item.data[0]
                                                              .testDate,
                                                            item.data[0]
                                                              .docVisitId,
                                                            item.data[0]
                                                              .orderId,
                                                            item.data
                                                          )
                                                        }
                                                      />
                                                    </a>
                                                  </button>
                                                  <label class="dropdown">
                                                    <div
                                                      class="dd-button"
                                                      onClick={() =>
                                                        dropList(
                                                          "dropdown",
                                                          item.data.length === 1
                                                            ? item.data[0]
                                                              .orderId
                                                            : item.data[0]
                                                              .orderId
                                                        )
                                                      }
                                                      const
                                                      onBlur={() => {
                                                        setTimeout(
                                                          () => [],
                                                          100
                                                        );
                                                      }}
                                                    />
                                                    <input
                                                      type="checkbox"
                                                      class="dd-input"
                                                      id="test"
                                                    />
                                                    <ul
                                                      class="dd-menu"
                                                      id={
                                                        "dropdown" +
                                                        item.data[0].orderId
                                                      }
                                                    >
                                                      <li>
                                                        <Link
                                                          to={{
                                                            pathname:
                                                              "/patients/labOrderAdd",
                                                            state: {
                                                              data: item.data,
                                                            },
                                                          }}
                                                        >
                                                          <img
                                                            src={Editicon}
                                                            style={{
                                                              width: "15px",
                                                              marginRight:
                                                                "5px",
                                                            }}
                                                          />{" "}
                                                          {item.data.length ===
                                                            1
                                                            ? "Edit"
                                                            : "Edit All"}
                                                        </Link>
                                                      </li>
                                                      {item.data[0]
                                                        .invoiceStatus !==
                                                        "Invoice Generated" &&
                                                        item.data[0]
                                                          .invoiceStatus !==
                                                        "Completed" ? (
                                                        <li
                                                          onClick={() =>
                                                            itemDelete(
                                                              "dropdown",
                                                              item.data
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          <img
                                                            src={Delete}
                                                            style={{
                                                              width: "15px",
                                                              marginRight:
                                                                "5px",
                                                            }}
                                                          />
                                                          {item.data.length ===
                                                            1
                                                            ? "Delete"
                                                            : "Delete All"}
                                                        </li>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <li
                                                        onClick={() =>
                                                          sendSms()
                                                        }
                                                      >
                                                        {" "}
                                                        <img
                                                          src={Sms}
                                                          style={{
                                                            width: "15px",
                                                            marginRight: "5px",
                                                          }}
                                                        />
                                                        Sent Sms
                                                      </li>
                                                      <li
                                                        onClick={() =>
                                                          sendEmails(
                                                            item.data[0]
                                                              .docVisitId,
                                                            item.data[0]
                                                              .testDate
                                                          )
                                                        }
                                                      >
                                                        {" "}
                                                        <img
                                                          src={sendEmail}
                                                          style={{
                                                            width: "15px",
                                                            marginRight: "5px",
                                                          }}
                                                        />
                                                        Sent Email
                                                      </li>
                                                    </ul>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          {item.data.map((dataSub, i) => (
                                            <tr class="groupBrdr trLists">
                                              <td style={{ padding: "3px" }}>
                                                <span
                                                  className="notInHospital"
                                                  id="notInHospital"
                                                  style={{
                                                    display:
                                                      dataSub.buy_price == "" ||
                                                        dataSub.buy_price ==
                                                        null ||
                                                        dataSub.buy_price ==
                                                        "0" ||
                                                        dataSub.testStatus == 0
                                                        ? "block"
                                                        : "none",
                                                  }}
                                                >
                                                  Not in Hospital
                                                </span>
                                                <div
                                                  class="dentalProName paddingFiveLabOrder"
                                                  style={{ display: "flex" }}
                                                >
                                                  <span
                                                    class="optionWrapper"
                                                    style={{
                                                      display:
                                                        dataSub.buy_price ==
                                                          "" ||
                                                          dataSub.buy_price ==
                                                          null ||
                                                          dataSub.buy_price ==
                                                          "0" ||
                                                          dataSub.testStatus == 0
                                                          ? "none"
                                                          : "block",
                                                    }}
                                                  >
                                                    <div
                                                      class="optionWrapper"
                                                      style={{ margin: "0px" }}
                                                    >
                                                      {(() => {
                                                        if (
                                                          dataSub.invoiceStatus ===
                                                          "Completed"
                                                        ) {
                                                          return (
                                                            <img
                                                              src={tivkmark}
                                                              title="Completed Test"
                                                            />
                                                          );
                                                        } else if (
                                                          dataSub.invoiceStatus ===
                                                          "Invoice Generated"
                                                        ) {
                                                          return (
                                                            <img
                                                              src={img1}
                                                              title="Invoice Generated"
                                                            />
                                                          );
                                                        } else {
                                                          return (
                                                            <div
                                                              style={{
                                                                display:
                                                                  dataSub.buy_price ==
                                                                    "" ||
                                                                    dataSub.buy_price ==
                                                                    null ||
                                                                    dataSub.buy_price ==
                                                                    "0" ||
                                                                    dataSub.testStatus ==
                                                                    0
                                                                    ? "none"
                                                                    : "block",
                                                              }}
                                                            >
                                                              <input
                                                                type="checkbox"
                                                                title="Open"
                                                                id={
                                                                  "Open" +
                                                                  dataSub.labOrderTestId
                                                                }
                                                                onClick={(e) =>
                                                                  checkedForInvoice(
                                                                    dataSub.labOrderTestId,
                                                                    i,
                                                                    j,
                                                                    e
                                                                  )
                                                                }
                                                              />
                                                              <label
                                                                for={
                                                                  "Open" +
                                                                  dataSub.labOrderTestId
                                                                }
                                                              />
                                                            </div>
                                                          );
                                                        }
                                                      })()}
                                                    </div>
                                                    <label
                                                      for={"cool2855" + j}
                                                    />
                                                  </span>
                                                  <span
                                                    class="elipsis"
                                                    title={dataSub.testName}
                                                  >
                                                    {dataSub.testName}
                                                  </span>
                                                </div>
                                              </td>
                                              <td class="col-md-3">
                                                <div class="dentalProName paddingFive">
                                                  {dataSub.specimen}
                                                </div>
                                              </td>
                                              <td class="col-md-2">
                                                <div class="dentalProName paddingFive">
                                                  {dataSub.testType}
                                                </div>
                                              </td>
                                              <td class="col-md-3 lbOrderNotes">
                                                <div
                                                  class="dentalProNote paddingFive"
                                                  style={{ marginTop: "3px" }}
                                                >
                                                  <span
                                                    class="elipsis"
                                                    title={
                                                      dataSub.comments ===
                                                        "null"
                                                        ? ""
                                                        : dataSub.comments
                                                    }
                                                  >
                                                    &nbsp;
                                                    {dataSub.comments === "null"
                                                      ? ""
                                                      : dataSub.comments}
                                                  </span>
                                                </div>
                                              </td>
                                              <td class="col-md-3"></td>
                                            </tr>
                                          ))}
                                          <tr class="dentalProPlaned">
                                            <td colspan="2">
                                              <div class="plannedBy">
                                                Noted by{" "}
                                                <span>{item.Notedby}</span>
                                              </div>
                                            </td>
                                            <td colspan="3">
                                              <div class="estimatedPrice" />
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <NoLaborder />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 practiceRightSection">
                <Patientright />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default LabOrder;
