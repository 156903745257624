import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import user from "../images/icons/avatar.png";
import settings from "../images/icons/settings.png";
import ardwn from "../images/icons/ardwn.png";
import { callingAPI } from "../config";
import DoctorHeaderLinks from "./DoctorHeaderLinks";
import HospitalHeaderLinks from "./HospitalHeaderLinks";
import DoctorHeaderBottomLinks from "./DoctorHeaderBottomLinks";
import HospitalHeaderBottomLinks from "./HospitalHeaderBottomLinks";
import NurseHeaderLinks from "./NurseHeaderLinks";
import LabOrderDetails from "../labLogin/labOrderDetails";
import BlankPage from "../billingLogin/blankPage";
import PrescriptionDetails from "../pharmacyLogin/prescriptionDetails";
import { WindowScrollController } from "@fullcalendar/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import PoweredBy from '../images/media/powered-w.png'
import $ from "jquery"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Header() {
  const [patientsListing, setPatienListing] = useState([]);
  const [header, setdheader] = useState([]);
  const [roleData1, setRoleData1] = useState([]);
  const [roleHospitalData, setroleHospitalData] = useState([]);
  const [searchType, setSearchType] = useState(0);
  const [dropDownCounter, setDropDownCounter] = useState({ "count": 0 });
  const [numberOfHospital, setNumberOfHospital] = useState([]);
  const [popUpHospitals, setpopUpHospitals] = useState(false);
  const [isHospitalSelect, setisHospitalSelect] = useState(false);
  const [pharmacyHeader, setpharmacyHeader] = useState('');
  const [labHeader, setlabHeader] = useState('');
  const [billingHeader, setbillingHeader] = useState('');
  const [result, setResult] = useState([]);
  const [DoctorName, setDoctorName] = useState(
    sessionStorage.getItem("patientDoctorName")
  );
  const [DoctorSpecialisation, setDoctorSpecialisation] = useState(
    sessionStorage.getItem("patientDoctorSpecialation")
  );
  const [showUserLink, setUserLink] = useState(false);
  const seteftMenu = () => {
    if (document.getElementById("leftmenuRes").style.display != "block") {
      document.getElementById("leftmenuRes").style.display = "block";
    } else if (
      (document.getElementById("leftmenuRes").style.display = "block")
    ) {
      document.getElementById("leftmenuRes").style.display = "none";
    }
  };
  useEffect(() => {
    let siteId = window.sessionStorage.getItem("siteId")
    if (siteId === null || siteId === "null" || siteId === "") {
      removeLoginSessions()
      window.location.href = "/"
    }
    if (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALPHARMACY') {
      setpharmacyHeader('headerHospitalLab headerHospitalharmacy')
    } else {
      setpharmacyHeader('')
    }

    if (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALLAB') {
      setlabHeader('headerHospitalLab')
    } else {
      setlabHeader('')
    }
    if (window.sessionStorage.getItem('role') === 'ROLE_BILLINGADMIN') {
      setbillingHeader('headerHospitalLab headerHospitalBill')
    } else {
      setbillingHeader('')
    }

    if (window.sessionStorage.getItem("isLoggedIn") !== "true") {
      window.location.href = "/";
    }
    let PostData = {
      doctorId: window.sessionStorage.getItem("userId"),
      hospitalId: window.sessionStorage.getItem("loginHospitalIds"),
    };
    callingAPI("commonUserFunctions/changeRole", PostData).then((response) => {
      if (response.data.success === "1") {

        setNumberOfHospital(response.data.result.hospitalResult);
        if (response.data.result.hospitalResult.length === 1) {
          setRoleData1(response.data.result.hospitalResult[0].roleData);
          setroleHospitalData(response.data.result.hospitalResult)
        }
        // setdheader(response.data.result.hospitalResult.roleData)
      }
    });
    $("#notification_popup").hide();
    getNotification();
    openSelectOption();
  }, []);
  const openSelectOption = () => {
    let PostData = {
      hospitalId: localStorage.getItem("hospitalId"),
      selectCombo: "",
      inputSearch: "",
    };
    callingAPI("commonUserFunctions/doctorsList", PostData).then((response) => {

      if (response.data.success === "1") {
        setPatienListing(response.data.result.doctorsListArray);
      }
      else {
        toast.error("no doctors found")
      }
    });
    // var x = document.getElementById('doctorListDiv');
    // if (x.style.display === 'none') {
    // x.style.display = 'block';
    // } else {
    // x.style.display = 'none';
    // }
  };
  const revertRole = async () => {
    window.sessionStorage.setItem(
      "doctorId",
      window.sessionStorage.getItem("actualUserId")
    );
    window.sessionStorage.setItem("role", "ROLE_DOCTOR");
    window.sessionStorage.setItem(
      "userId",
      window.sessionStorage.getItem("actualUserId")
    );
    window.sessionStorage.setItem("isOwner", true);
    window.sessionStorage.setItem("medicineBranchId", window.sessionStorage.getItem("actualMedicineBranchId"))
    window.sessionStorage.setItem("userName", window.sessionStorage.getItem("actualDoctorName"))
    window.sessionStorage.setItem("userEmail", window.sessionStorage.getItem("actualDoctorMail"))
    // window.sessionStorage.setItem("loginRole", "Administrator");
    window.sessionStorage.setItem("loginRole", "Doctor");
    await calenderListingCount('ROLE_DOCTOR', window.sessionStorage.getItem("actualUserId"))
    window.sessionStorage.removeItem("actualUserId");
    window.sessionStorage.removeItem("actualDoctorName");
    window.sessionStorage.removeItem("actualDoctorMail");
    window.sessionStorage.removeItem("actualMedicineBranchId");
    // window.location.href = "/Dashboard/welcome";
    window.location.href = "/Dashboard/dashboardView";
  };
  const calenderListingCount = async (role, hospitalORdoctorId) => {
    return new Promise((resolve, reject) => {
      let postData = {}
      if (role === 'ROLE_DOCTOR') {
        postData = {
          "doctorId": hospitalORdoctorId,
          "hospitalId": ""
        }
      }
      else {
        postData = {
          "doctorId": "",
          "hospitalId": hospitalORdoctorId
        }
      }
      callingAPI("calenderListing/scheduleListCount", postData).then((data) => {
        localStorage.setItem("doctorAppointmentCount", data.data.result.totalCount);
        resolve(true)
      })
    })
  }
  const setUserRoleData = async (index) => {
    //let index = e.target.value;
    window.sessionStorage.removeItem("memberId")
    window.sessionStorage.removeItem("patientDoctorId")
    if (index === '-1') {
      window.sessionStorage.setItem("loginRole", "Administrator");
      window.sessionStorage.setItem("actualUserId", window.sessionStorage.getItem("userId"));
      window.sessionStorage.setItem("doctorId", window.sessionStorage.getItem("userId"));
      window.sessionStorage.setItem("actualMedicineBranchId", window.sessionStorage.getItem("medicineBranchId"))
      window.sessionStorage.setItem("actualDoctorName", window.sessionStorage.getItem("userName"))
      window.sessionStorage.setItem("actualDoctorMail", window.sessionStorage.getItem("userEmail"))
      window.sessionStorage.setItem("role", "ROLE_HOSPITALADMIN");
      window.sessionStorage.setItem("userId", roleHospitalData[0].hospitalAdminId);
      localStorage.setItem("hospitalId", roleHospitalData[0].hospitalId);
      window.sessionStorage.setItem("userName", roleHospitalData[0].hospitalName)
      window.sessionStorage.setItem("userEmail", roleHospitalData[0].hospitalEmailId)
      await calenderListingCount('', roleHospitalData[0].hospitalId)
      // window.location.href = "/hospital Admin/hospitalHome";
      window.location.href = "/Dashboard/dashboardView";
    }
    else {
      window.sessionStorage.setItem("loginRole", roleData1[index].name);
      window.sessionStorage.setItem("actualUserId", window.sessionStorage.getItem("userId"));
      window.sessionStorage.setItem("doctorId", window.sessionStorage.getItem("userId"));
      window.sessionStorage.setItem("actualMedicineBranchId", window.sessionStorage.getItem("medicineBranchId"))
      window.sessionStorage.setItem("actualDoctorName", window.sessionStorage.getItem("userName"))
      window.sessionStorage.setItem("actualDoctorMail", window.sessionStorage.getItem("userEmail"))
      window.sessionStorage.setItem("userName", roleData1[index].displayName)
      window.sessionStorage.setItem("userEmail", roleData1[index].email)
      window.sessionStorage.setItem("role", roleData1[index].role);
      window.sessionStorage.setItem("userId", roleData1[index].secUserId);
      localStorage.setItem("hospitalId", roleData1[index].hospitalId);
      await calenderListingCount('', roleData1[index].hospitalId)
      if (window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB") {
        window.location.href = "/labLogin/labOrderDetails";
      }
      else if (window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION") {
        // window.sessionStorage.setItem("clinicsHeader", "headerIconActive");
        window.sessionStorage.setItem("isOwner", false);
        // window.location.href = "/clinics/calendar";
        window.sessionStorage.setItem("homeHeader", "headerIconActive");
        window.location.href = "/Dashboard/dashboardView";
      }
      else if (window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN") {
        window.location.href = "/billingLogin/procedurePayment";
      }
      else if (window.sessionStorage.getItem("role") === "ROLE_HOSPITALPHARMACY") {
        window.location.href = "/pharmacyLogin/prescriptionDetails";
      }
      else {
        window.location.reload();
      }
    }
  }
  const setRoleData = (index) => {
    if (index === "-1") {
      window.sessionStorage.setItem("loginRole", "Administrator");
      window.sessionStorage.setItem(
        "actualUserId",
        window.sessionStorage.getItem("userId")
      );
      window.sessionStorage.setItem(
        "doctorId",
        window.sessionStorage.getItem("userId")
      );
      window.sessionStorage.setItem("actualMedicineBranchId", window.sessionStorage.getItem("medicineBranchId"))
      window.sessionStorage.setItem("actualDoctorName", window.sessionStorage.getItem("userName"))
      window.sessionStorage.setItem("actualDoctorMail", window.sessionStorage.getItem("userEmail"))
      window.sessionStorage.setItem("role", "ROLE_HOSPITALADMIN");
      window.sessionStorage.setItem("userId", header[0].hospitalAdminId);
      localStorage.setItem("hospitalId", header[0].hospitalId);
      window.sessionStorage.setItem("userName", header[0].displayName)
      window.sessionStorage.setItem("userEmail", header[0].email)
      window.sessionStorage.setItem("medicineBranchId", header[0].medicineBranchId)
      window.location.href = "/hospital Admin/hospitalHome";
    } else {
      window.sessionStorage.setItem("loginRole", header[index].name);
      window.sessionStorage.setItem(
        "actualUserId",
        window.sessionStorage.getItem("userId")
      );
      window.sessionStorage.setItem(
        "doctorId",
        window.sessionStorage.getItem("userId")
      );
      window.sessionStorage.setItem("actualMedicineBranchId", window.sessionStorage.getItem("medicineBranchId"))
      window.sessionStorage.setItem("actualDoctorName", window.sessionStorage.getItem("userName"))
      window.sessionStorage.setItem("actualDoctorMail", window.sessionStorage.getItem("userEmail"))
      window.sessionStorage.setItem("userName", header[index].displayName)
      window.sessionStorage.setItem("userEmail", header[index].email)
      window.sessionStorage.setItem("role", header[index].role);
      window.sessionStorage.setItem("userId", header[index].secUserId);
      localStorage.setItem("hospitalId", header[index].hospitalId);
      window.location.reload();
      if (window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB") {
        window.location.href = "/labLogin/labOrderDetails";
      }
      else if (window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION") {
        window.sessionStorage.setItem("clinicsHeader", "headerIconActive");
        window.location.href = "/clinics/calendar";
      }
      else if (window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN") {
        // window.location.href = "/billingLogin/procedurePayment";
        window.location.href = "/labLogin/labOrderDetails";
        //  window.location.href = "/pharmacyLogin/prescriptionDetails";
      }
      else if (window.sessionStorage.getItem("role") === "ROLE_HOSPITALPHARMACY") {
        window.location.href = "/pharmacyLogin/prescriptionDetails";
      }
    }
    // NewdoctorSelected();
  };
  const removeLoginSessions = () => {
    window.sessionStorage.removeItem('medicineBranchId')
    window.sessionStorage.removeItem('memberId')
    window.sessionStorage.removeItem('loginRole')
    window.sessionStorage.removeItem('patientDoctorId')
    window.sessionStorage.removeItem('isOwner')
    window.sessionStorage.removeItem('loginHospitalIds')
    window.sessionStorage.removeItem('patientDoctorSpecialation')
    window.sessionStorage.removeItem('patientDoctorName')
    window.sessionStorage.removeItem('setDoctorImage')
    window.sessionStorage.removeItem("actualUserId");
    window.sessionStorage.removeItem("actualDoctorMail")
    window.sessionStorage.removeItem("actualMedicineBranchId")
    window.sessionStorage.removeItem("actualDoctorName")
    window.sessionStorage.removeItem("doctorId");
    window.sessionStorage.removeItem("userId");
    localStorage.removeItem("hospitalId");
    localStorage.removeItem("doctorAppointmentCount");
    window.sessionStorage.removeItem("userName");
    window.sessionStorage.removeItem("userEmail");
    window.sessionStorage.removeItem("isLoggedIn");
    window.sessionStorage.removeItem("communicationsHeader");
    window.sessionStorage.removeItem("reportsHeader");
    window.sessionStorage.removeItem("appointmentsHeader");
    window.sessionStorage.removeItem("patientsHeader");
    window.sessionStorage.removeItem("doctorHeader");
    window.sessionStorage.removeItem("staffHeader");
    window.sessionStorage.removeItem("addpatientsHeader");
    window.sessionStorage.removeItem("treatmentHeader");
    window.sessionStorage.removeItem("clinicsHeader");
    window.sessionStorage.removeItem("dashboardHeader");
    window.sessionStorage.removeItem("reportHeader");
    window.sessionStorage.removeItem("role");
    window.sessionStorage.removeItem("isPrNumEnabled");
    let redirectURL = window.sessionStorage.getItem("redirectURL")
    if (redirectURL !== null && redirectURL !== "null" && redirectURL !== "") {
      window.location.href = redirectURL
    }
    else {
      window.location.href = "/"
    }
  };
  const setChangeDr = (doctorName, specialization, doctorId, medicineBranchId, hospId) => {
    setDoctorName(doctorName);
    setDoctorSpecialisation(specialization);
    sessionStorage.setItem("medicineBranchId", medicineBranchId);
    sessionStorage.setItem("patientDoctorName", doctorName);
    sessionStorage.setItem("patientDoctorId", doctorId);
    sessionStorage.setItem("patientDoctorSpecialation", specialization);
    localStorage.setItem('hospitalId', hospId);
    document.getElementById("testId").style.display = "none";
    window.location.href = "/patients/patientconsult";
  };
  const changeRoleDiv = (id) => {
    if (numberOfHospital.length > 1 && id === "changeRoleDiv") {
      // document.getElementById('multiHospital').style.display = 'block';
      setpopUpHospitals(true)
    }
    else {
      var elementDisplay = document.getElementById(id);
      elementDisplay.style.display = (dropDownCounter.count === 1) ? 'none' : 'block';
      setDropDownCounter({
        count: (dropDownCounter.count === 1) ? 0 : 1
      })
      document.addEventListener('mouseup', function (e) {
        var container = document.getElementById(id);
        if (container !== null) {
          if (!container.contains(e.target)) {
            container.style.display = 'none';
          }
        }
      });
    }
  };
  const getNotification = () => {
    let PostData = {
      "functionName": ""
    }
    callingAPI('commonUserFunctions/getNotification', PostData).then((result) => {
      if (result.data.success === '1') {

        setResult(result.data.result);
      }
    })
  }
  const dashboardMain = () => {
    // window.location.href = "/Dashboard/dashboardMain";
    window.location.href = "/Dashboard/dashboardView";
  };
  const profileMain = () => {
    window.location.href = "/Dashboard/profile";
  };
  const healthCardMain = () => {
    window.location.href = "/settings/healthcard";
    window.sessionStorage.removeItem("clinicsHeader");
  }
  const practiceDetailsMain = () => {
    window.location.href = "/settings/practiceDetails";
    window.sessionStorage.removeItem("clinicsHeader");
  }
  const locationvalues = (window.location.href.indexOf("patients/patientconsult") > -1 ||
    window.location.href.indexOf("patients/simplifiedForm") > -1 ||
    window.location.href.indexOf("patients/patientAppointments") > -1 ||
    window.location.href.indexOf("patients/casesheetMedical") > -1 ||
    window.location.href.indexOf("patients/casesheetDental") > -1 ||
    window.location.href.indexOf("patients/treatmentPlans") > -1 ||
    window.location.href.indexOf("patients/completedPlans") > -1 ||
    window.location.href.indexOf("patients/patientPriscription") > -1 ||
    window.location.href.indexOf("patients/labOrderDental") > -1 ||
    window.location.href.indexOf("patients/labOrder") > -1 ||
    window.location.href.indexOf("patients/testResult") > -1 ||
    window.location.href.indexOf("patients/patientInvoices") > -1 ||
    window.location.href.indexOf("patients/patientPayments") > -1 ||
    window.location.href.indexOf("patients/patientFiles") > -1 ||
    window.location.href.indexOf("patients/patientFilesMedical") > -1 ||
    window.location.href.indexOf("patients/historyInfo") > -1 ||
    window.location.href.indexOf("patients/patientHealthcard") > -1)
  const hospitalClick = (event) => {
    setisHospitalSelect(true)
    setSearchType(event.target.value);

    // let dataCheck = numberOfHospital.filter((data, index) => data.roleData == "").map((item) => item)
    let dataCheck = numberOfHospital.filter((obj, i) => obj.hospitalId == event.target.value)
    setRoleData1([...dataCheck[0].roleData])
    setroleHospitalData(dataCheck)
    //  if(numberOfHospital[i].roleData.length<0)
    //  {
    //    alert("yes")
    //  }
  }
  const notificationPopup = () => {
    $("#notification_popup").show();
    getNotification();
  }
  const notificationPopupclose = () => {
    $("#notification_popup").hide();
  }
  return (
    <div>
      <div className={"header headerScroll 121" + " " + pharmacyHeader + "" + labHeader + "" + billingHeader}>
        {/* headerHospitalLab headerHospitalharmacy  */}
        <ToastContainer position="bottom-right" />
        <div className="toggleOverlay" />
        <div
          className={
            "headerTop " +
            (window.sessionStorage.getItem("role") === "ROLE_HOSPITALADMIN"
              ? "headerTopGreen"
              : "")
          }
        >
          <div
            className="toggleHeader"
            id="toggleSetting"
            onClick={(e) => seteftMenu()}
          >
            <span />
          </div>
          <div className="position-relative">
            <div className="logo">
              <Link to="/">
                <img
                  src={
                    process.env.REACT_APP_S3_URI +
                    process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
                    "/" +
                    process.env.REACT_APP_S3_LOGO_PATH +
                    "/" +
                    window.sessionStorage.getItem("siteLogo")
                  }
                  alt=""
                />
              </Link>
            </div>
            {window.sessionStorage.getItem("siteBranchId") === "4" ?
              <div className="logo-powerdbyinner">
                <a href="https://www.purplehealth.com/" target="_blank" rel="noopener noreferrer">
                  <img src={PoweredBy} alt="Powered By PurpleHealth" />
                </a>
              </div>
              : ""}
          </div>
          {window.sessionStorage.getItem("role") === "ROLE_DOCTOR" && (
            <DoctorHeaderLinks />
          )}
          {window.sessionStorage.getItem("role") === "ROLE_HOSPITALADMIN" && (
            <HospitalHeaderLinks />
          )}
          {window.sessionStorage.getItem("role") === "ROLE_NURSE" && (
            <NurseHeaderLinks />
          )}
          {window.sessionStorage.getItem("role") === "ROLE_RECEPTION" && (
            <NurseHeaderLinks />
          )}
          {/* {window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" && (
            <LabOrderDetails />
          )} */}
          {/* {window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" && (
            // <BlankPage />
            // <LabOrderDetails />
          )} */}
          {/* {window.sessionStorage.getItem("role") === "ROLE_HOSPITALPHARMACY" && (
            <PrescriptionDetails />
          )} */}
          {(result.length > 0 ?
            <div className="notification_block">
              {/* video-icon */}
              <a class="notifi-icon video-icon" onClick={(e) => notificationPopup()}>
                <FontAwesomeIcon icon={faBell} />
              </a>
            </div>
            :
            <div className="notification_block">
              <a class="notifi-icon" onClick={(e) => notificationPopup()}>
                <FontAwesomeIcon icon={faBell} />
              </a>
            </div>
          )}

          <div className="headerRight" >
            {/* {window.sessionStorage.getItem("role") == "ROLE_DOCTOR" &&
              <span style={{ cursor: 'default', color: "white" }}>{"Logged in as "}
                <br />
                {window.sessionStorage.getItem("loginRole")}
              </span>} */}
            {(header.length > 0 ||
              window.sessionStorage.getItem("role") !== "ROLE_DOCTOR" || window.sessionStorage.getItem("isOwner") === "true") && (
                <div className="headerRightList">
                  {window.sessionStorage.getItem("role") === "ROLE_DOCTOR" &&
                    !window.sessionStorage.getItem("actualUserId") && (
                      <div
                        class="specializationSelection change-role-section"
                        id="doctorMainSelection"
                      >
                        {/*  */}
                        {window.sessionStorage.getItem("role") == "ROLE_DOCTOR" &&
                          <span style={{
                            cursor: 'default', color: "white", width: "100%",
                            display: "block",
                            paddingLeft: "25px", position: 'relative', top: '-10px'
                          }}>{"Logged in as "}
                            <br />
                            {window.sessionStorage.getItem("loginRole")}
                          </span>}
                        {/*  */}
                        <span class="specializationClk" onClick={() => changeRoleDiv("changeRoleDiv")}>
                          <span id="doctorMainSelectionText" class="elipsis" style={{ fontWeight: "600", fontSize: '13px' }}>
                            Change Role
                          </span>
                          <span class="cartClk" ></span>
                        </span>
                        {/* ==== If Loggined as administator ==== */}
                        {/* <span class="adminLoginHospital">Logged in as <br />Administrator</span><br/>
												<span class="adminLoginHospital adminbackHospital">
												<a href="/#">&lt; Back To Doctor</a>
												</span> */}
                        {/* ==== If Loggined as administator ==== */}
                        <div
                          class="treatmentDrNameList"
                          id="changeRoleDiv"
                          style={{ display: "none" }}
                        >
                          <ul>
                            <a onClick={() => setUserRoleData("-1")}>
                              <li>
                                <span>HOSPITAL ADMIN</span>
                              </li>
                            </a>
                            {roleData1.map((headerlist, i) => (
                              <a onClick={() => setUserRoleData(i)}>
                                <li>
                                  <span>{headerlist.name}</span>
                                </li>
                              </a>
                            ))}
                          </ul>
                        </div>
                      </div>
                      // <span className="roleChoose">
                      // 	<div class="specializationSelection" id="doctorMainSelection">
                      // 		<span class="specializationClk">
                      // 			<span id="doctorMainSelectionText" class="elipsis">
                      // 				<select
                      // 					className="inputField"
                      // 					id="selectFildtype"
                      // 					onChange={(e) => setRoleData(e.target.value)}
                      // 				>
                      // 					<option value="0">Change Role</option>
                      // 					<option value="-1">HOSPITAL ADMIN</option>
                      // 					{header.map((headerlist, i) => (
                      // 						<option value={i}>{headerlist.name}</option>
                      // 					))}
                      // 				</select>
                      // 			</span>
                      // 			<span class="cartClk" />
                      // 		</span>
                      // 	</div>
                      // </span>
                    )}
                  {window.sessionStorage.getItem("actualUserId") && (
                    <span >
                      {/* {"Logged in as"}
                      <br />
                      {window.sessionStorage.getItem("loginRole")} */}
                      <span style={{ cursor: 'default' }}>{"Logged in as "}
                        <br />
                        {window.sessionStorage.getItem("loginRole")}
                      </span>
                      <br />
                      {(window.sessionStorage.getItem("loginRole") !== "Receptionist" && window.sessionStorage.getItem("loginRole") !== "Nurse"
                        && window.sessionStorage.getItem("loginRole") !== "Pharmacy" && window.sessionStorage.getItem("loginRole") !== "Hospital Billing Admin" &&
                        window.sessionStorage.getItem("loginRole") !== "Hospital Lab") &&
                        <a onClick={() => revertRole()} style={{ borderBottom: "1px solid #f1f1f1" }}>
                          {"< Back To Doctor"}
                        </a>
                      }
                    </span>
                  )}
                  {!window.sessionStorage.getItem("actualUserId") &&
                    window.sessionStorage.getItem("role") ===
                    "ROLE_HOSPITALADMIN" && (
                      //     <span>
                      //       {"Logged in as"}
                      //       <br />
                      //       {window.sessionStorage.getItem("loginRole")}
                      //       {/* {'Logged in as Administrator'} */}
                      // </span>
                      <span style={{ cursor: 'default' }}>{"Logged in as "}
                        <br />
                        {window.sessionStorage.getItem("loginRole")}
                      </span>
                    )}
                  <div />
                </div>
              )}
            {(window.sessionStorage.getItem("role") === "ROLE_HOSPITALADMIN") && (
              <div className="headerRightList">
                <a href="#" onClick={() => practiceDetailsMain()}>
                  <img alt="" src={settings} />
                </a>
              </div>
            )}
            {(window.sessionStorage.getItem("role") === "ROLE_DOCTOR" &&
              window.sessionStorage.getItem("isOwner") === "true") && (
                <div className="headerRightList">
                  <a href="#" onClick={() => healthCardMain()}>
                    <img alt="" src={settings} />
                  </a>
                </div>
              )}
            <div className="headerRightList">
              <div onClick={() => changeRoleDiv("changeRoleDiv1")}>
                <span className="userClick">
                  <img src={user} alt="" />
                </span>
                <span className="downCaret" />
              </div>
              <div
                className={
                  "userDropDiv " +
                  (window.sessionStorage.getItem("role") ===
                    "ROLE_HOSPITALADMIN"
                    ? "headerTopGreen"
                    : "")
                }
                id="changeRoleDiv1"
                style={{ display: "none" }}
              >
                <ul>
                  <li className="userDropChild" onClick={() => document.getElementById("changeRoleDiv1").style.display = "none"}>
                    <span class="practiceuserName">
                      <span> {window.sessionStorage.getItem("userName")}</span>
                      <br />
                      <small>{window.sessionStorage.getItem("userEmail")}</small>
                    </span>
                  </li>
                  {window.sessionStorage.getItem("role") !== "ROLE_NURSE" && window.sessionStorage.getItem("role") !== "ROLE_RECEPTION" && window.sessionStorage.getItem("role") !== "ROLE_HOSPITALADMIN" ? (
                    <li>
                      <a href="#" onClick={() => profileMain()}>
                        Profile
                      </a>
                    </li>
                  ) : ""}
                  {window.sessionStorage.getItem("role") !== "ROLE_NURSE" && window.sessionStorage.getItem("role") !== "ROLE_RECEPTION" ? (
                    <li>
                      <a href="#" onClick={() => dashboardMain()}>
                        My Dashboard
                      </a>
                    </li>
                  ) : <li>
                    <a href="/Dashboard/changepassword" >
                      Change Password
                    </a>
                  </li>}
                  <li>
                    <a href="#" onClick={() => removeLoginSessions()}>
                      Sign Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "headerBottom " +
            (window.sessionStorage.getItem("role") === "ROLE_HOSPITALADMIN"
              ? "headerBottomGreen"
              : "")
          }
        >
          {window.sessionStorage.getItem("role") === "ROLE_DOCTOR" || window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" ? (
            <DoctorHeaderBottomLinks />
          ) : ""}
          {window.sessionStorage.getItem("role") === "ROLE_HOSPITALADMIN" && (
            <HospitalHeaderBottomLinks />
          )}

          <span
            class="treatmentGrandBy"
            className="DrNameView"
            style={{ position: "absolute" }}
          >
            {locationvalues && (
              <div>
                {/* <span id="doctorMainSelectionText" class="elipsis">
<select
className="inputField"
id="selectFildtype"
onChange={(e) => setRoleData(e.target.value)}
>
<option></option>
<option value="-1">HOSPITAL ADMIN</option>
{header.map((headerlist, i) => (
<option value={i}>{headerlist.name}</option>
))}
</select>
</span> */}
                {/* <span> */}
                {window.sessionStorage.getItem("role") === "ROLE_HOSPITALADMIN" && patientsListing.length > 1 && (
                  <div className="drNameViewArrow">
                    <span style={{ margin: "0px" }} onClick={(e) => changeRoleDiv('testId')}>
                      {DoctorName}

                      <img src={ardwn} alt="" />
                      <ul
                        className="autoSearch1 autoSearch-p0 appointmentNumberSearch ccc"
                        id="testId"
                        style={{ display: "none" }}
                      >
                        {patientsListing.map((data, i) => (
                          <ul>

                            <li
                              onClick={() =>
                                setChangeDr(data.doctorName, data.primarySpecializationName, data.doctorId, data.medicineBranchId, data.hospitalId)
                              }
                            >
                              {data.specialization.value !== "" ? (
                                <span>
                                  {data.doctorName}
                                  {"("}
                                  {/* {data.specialization.split(/[,]+/).join(', ')} */}
                                  {data.primarySpecializationName}
                                  {")"}
                                </span>
                              ) : (
                                <span>
                                  {data.doctorName}
                                  {data.primarySpecializationName}
                                  {/* {data.specialization.split(/[ ,]+/).join(', ')} */}
                                </span>)}
                            </li>
                          </ul>
                        ))}
                      </ul>
                      {/* </div> */}
                    </span>
                    {!DoctorSpecialisation ?
                      <small>
                        {DoctorSpecialisation}
                      </small>
                      :
                      <small>
                        {/* ({DoctorSpecialisation.split(/[ ,]+/).join(', ')}) */}
                        {DoctorSpecialisation}
                      </small>
                    }
                  </div>
                )}
                {/* </span> */}
              </div>
            )}
          </span>
        </div>
      </div>
      {popUpHospitals == true && (
        <div className="ploverlayy">
          <div className="plpopup plpopupList selectDr " style={{ width: '400px' }}>
            <h2>
              <a class="close" onClick={() => { setpopUpHospitals(false) }}>
                &times;
              </a>
            </h2>
            <div className="plcontent">
              {/* <label>
									Select Doctor<span className="mandatory">*</span>
								</label> */}
              <div className="addDataContent" style={{ padding: '4px 0 0' }}>
                <div className="addDataDiv" id="styled-select">
                  <select
                    id="default"
                    style={{ width: '100', marginBottom: "15px" }}
                    value={searchType}
                    onChange={(e) => hospitalClick(e)}
                  >
                    <option value="0">Hospital Name</option>
                    {numberOfHospital.map((item, i) => (
                      <option value={item.hospitalId}>
                        {item.hospitalName}
                        {/* ({item.hospitalName}) */}
                      </option>
                    ))}
                  </select>
                  <select
                    id="default"
                    style={{ width: '100%' }}
                    onChange={(e) => setUserRoleData(e.target.value)}
                  >
                    <option value="0">Select Role</option>
                    {isHospitalSelect && <option value="-1">HOSPITAL ADMIN</option>}
                    {roleData1.map((Popuplist, i) => (
                      <option value={i}>
                        {Popuplist.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="ploverlayy" id="notification_popup" style={{ display: "none" }}>
        <div className="plpopup plpopupList plpopupNotification" style={{ width: '400px' }}>
          <h2>
            <a class="close" onClick={(e) => notificationPopupclose()}>
              &times;
            </a>
          </h2>
          <h3 className="notif_head">Notification</h3>

          <div className="plcontent">
            {result.map((data, i) => (
              <ul className="notif_ul">
                <li className="notif_li">
                  <div className="notif_subhead">{data.title}</div>
                  <div className="notif_descri">
                    {data.description}
                  </div>
                </li>
              </ul>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
}
export default Header;
