import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Delete from '../images/icons/delete_btn.png';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { callingAPI, callingAPI_GET } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Achievements(props) {
	const [achievementtypecategory, setAchievementTypeCategory] = useState();
	const [achievementAddedArr, setAchievementAddedArr] = useState([]);
	const [achievementdescription, setAchievementDescription] = useState('');
	const [achievementType, setAchievementType] = useState([]);
	const [displayErrMsg1, setDisplayErrMsg1] = useState('');
	const [dataflag, setdataflag] = useState(false);
	const [display1, setDisplay1] = useState(false);
	const removeAchievementItem = (e, i) => {
		setDisplay1(true)
		let copyAchievementArrItems = [...achievementAddedArr];
		copyAchievementArrItems.splice(i, 1);
		setAchievementAddedArr(copyAchievementArrItems);
	};
	const achievementTypeOnChange = (event) => {
		setAchievementTypeCategory(event.target.value);
	};
	const achievementDescriptionOnChange = (event) => {
		setAchievementDescription(event.target.value);
	};
	const addAchievementsArr = () => {
		setDisplay1(true)
		let copy = JSON.parse(JSON.stringify(achievementType));
		let copyArr = JSON.parse(JSON.stringify(achievementAddedArr));
		var flag = true;
		if (!achievementtypecategory) {
			setDisplayErrMsg1('Please Select Achievement Type');
			alertlanguage()
			flag = false;
			document.getElementById('Saveachieve').style.display = "none"
		} else if (!achievementdescription) {
			setDisplayErrMsg1('Please Enter Description');
			alertlanguage()
			flag = false;
			document.getElementById('Saveachieve').style.display = "none"
			// window.scrollTo(0, 0)
		} else if (flag == true) {

			setDisplayErrMsg1('');
			for (let i = 0; i < achievementAddedArr.length; i++) {
				if (achievementAddedArr[i].achievementTypeDescription == achievementdescription && achievementAddedArr[i].achievementTypeId == achievementtypecategory) {
					setDisplayErrMsg1("Description Already Added")
					alertlanguage()
					return false
				}
			}
			copy.filter((item, i) => {
				if (achievementtypecategory == item.achievementTypeId) {
					copyArr.push({
						achievementTypeId: item.achievementTypeId,
						achievementTypeName: item.achievementTypeName,
						achievementTypeDescription: achievementdescription

					});
					return item;
				}
			});
			setAchievementAddedArr(copyArr);
			setAchievementTypeCategory(0);
			setAchievementDescription('');
			document.getElementById('Saveachieve').style.display = "block"
		}
	};

	const alertlanguage = () => {
		setTimeout(() => {
			setDisplayErrMsg1('');
		}, 2000);

	}
	const saveAchievementDetails = () => {
		var achievementTypeIds = [];
		var achievementDescription = [];
		var valid = false;
		setdataflag(true)
		achievementAddedArr.map((val, idx) => { });
		achievementTypeIds = achievementAddedArr.map((val, idx) => {
			return (achievementTypeIds[idx] = val.achievementTypeId);
		});
		achievementDescription = achievementAddedArr.map((val, idx) => {
			return (achievementDescription[idx] = val.achievementTypeDescription);
		});
		const postdata = {
			achievementTypeDescription: achievementDescription.toString(),
			achievementTypeId: achievementTypeIds.toString(),
			subFunctionName: 'updateAchievementDetails',
			doctorId: window.sessionStorage.getItem('doctorId'),
		};
		callingAPI('doctorProfile/updateDoctorProfile', postdata)
			.then((res) => {
				toast.success(res.data.successMessage);
				window.location.href = '/Dashboard/profile';
			})
			.catch();
	};
	const getAchievementTypeDropdownValues = () => {
		callingAPI('doctorProfile/getAchievementType')
			.then((res) => {
				setAchievementType(res.data.result.achievement);
			})
			.catch();
	};
	useEffect(
		() => {
			if (props.achievements.length) {
				setAchievementAddedArr(props.achievements);
			}
			getAchievementTypeDropdownValues();
			alertlanguage();
		},
		[props.achievements]
	);
	return (
		<div>
			<div className="homeProfileDetails">
				<ToastContainer position="bottom-right" />
				<div className="practiceManagementGrid profileForm">
					<div className="row rowMargin">
						<div className='col-md-12'>
							<p
								style={{
									display: 'flex',
									justifyContent: 'center',
									color: 'red',
									padding: '0px',
									margin: '0px', fontSize: "13px", paddingBottom: "15px"
								}}
							>
								{displayErrMsg1}
							</p>
						</div>
						<div className="col-md-6 col-lg-6 practiceManagementList">

							<label className="labelTextField labelMarginBottom">Type <sup>*</sup></label>
							<select
								className="inputField"
								value={achievementtypecategory}
								name="type"
								id="demo"
								onChange={achievementTypeOnChange}

							>
								<option value="">Select</option>
								{achievementType.map((val, idx) => (
									<option value={val.achievementTypeId} key={val.achievementTypeName}>
										{val.achievementTypeName}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-12 col-lg-12 practiceManagementList">
							<label className="labelTextField labelMarginBottom">Description <sup>*</sup></label>
							<textarea
								name="description"
								id="description"
								value={achievementdescription}
								onChange={achievementDescriptionOnChange}
								className="textareaField"
								placeholder='Description'
							/>
						</div>
						<div className="col-md-12 col-lg-12 practiceManagementList">
							<span
								class="borderButton borderButtonLarge legoButton_green AddButton"
								onClick={(e) => addAchievementsArr(e)}
								style={{
									width: 'auto',
								}}
							>
								Add
							</span>
						</div>
					</div>
				</div>
				{achievementAddedArr.length !== 0 ? (
					<div class="col-md-12">
						<div class="table-responsive">
							<table class="table" id="tblAchievements" style={{ display: 'table' }}>
								<thead>
									<tr id="grid-profile-docreg">
										<th class="col-md-5">Achievement Type</th>
										{/* <th class="col-md-4" /> */}
										<th class="col-md-5">Description</th>
									</tr>
								</thead>
								<tbody>
									{achievementAddedArr.map((item, index) => (
										<tr id="gridnew_docedit_achtrow" class="">
											<td>
												<input
													type="hidden"
													name="achievementId1"
													id="achievementId1"
													value={item.achievementTypeId}
													class="achievementTypeId inputField"
												/>
												<input
													type="text"
													name="achievementType"
													id="docedit_achievement_type"
													value={item.achievementTypeName}
													class="achievementType inputField"
												/>
											</td>

											<td>
												<input
													disabled="disabled"
													type="text"
													name="achievementDes1"
													id="achievementDes1"
													value={item.achievementTypeDescription}
													class="achievementDesc inputField"
												/>
											</td>

											<td>
												<a id="remove" tabindex="4" href="#">
													{/* <img src={Delete} onClick={(e) => removeAchievementItem(e, index)} title="Delete" /> */}
													<span className="languageIcons" style={{ position: 'relative' }}>
														<FontAwesomeIcon
															icon={faTrashAlt}
															title="Delete"
															onClick={(e) => removeAchievementItem(e, index)}
														/>
													</span>
												</a>
											</td>
										</tr>
									))}{' '}
								</tbody>
							</table>
						</div>
					</div>
				) : null}
				{/* {achievementAddedArr.length > 0 ? ( */}
				<div className="row">
					<div className=" col-md-4 col-lg-4 practiceManagementList" id="Saveachieve">
						<button
							disabled={dataflag}
							class="borderButton borderButtonLarge  legoButton_green "
							onClick={saveAchievementDetails}
							style={{ width: '100%', marginLeft: '10px', display: display1 ? '' : 'none' }}
						>
							Save
						</button>
					</div>
				</div>
				{/* ) : null} */}
			</div>
		</div>
	);
}
export default Achievements;
