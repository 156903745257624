import React, { useState, useEffect } from 'react';
import PharmacyHeader from './layout/header';
import Footer from './layout/footer';
import { callingAPI } from '../config';
import Datepicker, { CalendarContainer } from 'react-datepicker';
import PharmacyLeft from './pharmacyLeft'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function HospitalMedicineHistory() {
	const csvDownload = 'hospital medicine history';
	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState();
	const [count, setCount] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [Medicalhistory, setMedicalhistory] = useState([]);
	const [searchValue, setsearchValue] = useState('');
	const [searchstatus, setsearchstatus] = useState('');
	const [filterName, setFilterInput] = useState('');
	const [dataFlag, setDataFlag] = useState(false);
	const [pageOffset, setPageOffset] = useState(0);
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");
	const [booliansearchstatus, setbooliansearchstatus] = useState("");
	const [dateEnable, setDateEnable] = useState(true)
	const searchValueChange = (value) => {
		setsearchValue(value);
	};
	const dataPagination = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber == '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;

		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) {
			setFilterInput("");
		}
		if (!booliansearchValue) { setsearchValue("") }
		if (!boolianfromDate) { setFromDate("") }
		if (!booliantoDate) { setToDate("") }
		if (!booliansearchstatus) { setsearchstatus("") }
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			max: max.toString(),
			offset: offset.toString(),
			searchValue: booliansearchValue,
			searchId: boolianfilterName,
			searchStatus: booliansearchstatus,
			fromDate: boolianfromDate ? formatDate(fromDate) : '',
			toDate: booliantoDate ? formatDate(toDate) : '',
			roleName: window.sessionStorage.getItem('role'),
		};
		setDataFlag(false);
		callingAPI('medicinePrescription/hospitalMedicalHistory', PostData)
			.then((data) => {
				if (data.data.success === '0') {
					setMedicalhistory([]);
					setCount(0);
					setDataFlag(true);
				}
				if (data.data.success === '1') {
					setMedicalhistory(data.data.result);
					setCount(data.data.totalCount);
					setDataFlag(true);
				}
			})
			.catch();
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	const changeTextonTextArea = (value) => {
		setFilterInput(value);
		setsearchValue('');
		if (value === '') {
			setsearchValue('');
			document.getElementById('searchText').disabled = true;
		} else {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		}
	};
	const changeTextonTextAreaStatus = (value) => {
		setsearchstatus(value);

		if (value === '') {
			setDateEnable(true);
			document.getElementById('selectStatus').disabled = false;
		} else {
			setDateEnable(false);
			document.getElementById('selectStatus').disabled = false;
			document.getElementById('selectStatus').focus();
		}
	};
	const searchValueOnChangeOnSelect = () => {
		if (document.getElementById('selectName').value !== '' && document.getElementById('searchText').value === '') {
			var t = document.getElementById('selectName');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('selectName').focus();
			toast.error(selectedText + ' required');
			return false;
		}
		setbooliansearchValue(searchValue);
		setboolianfilterName(filterName);
		setboolianfromDate(fromDate);
		setbooliansearchstatus(searchstatus);
		setbooliantoDate(toDate);
		if (fromDate && toDate) {
			if (fromDate > toDate) {
				toast.error('End date must be greater than start date');
				document.getElementById("todate").focus()
				return false;
			}
		}
		setActivePage(1);
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			searchStatus: searchstatus,
			offset: '0',
			max: process.env.REACT_APP_PAGINATION_COUNT,
			searchId: filterName,
			searchValue: searchValue,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
			roleName: window.sessionStorage.getItem('role'),
		};
		callingAPI('medicinePrescription/hospitalMedicalHistory', PostData)
			.then((response) => {
				if (response.data.success === '0') {
					setMedicalhistory([]);
					setCount(0);
					setDataFlag(true);
				}
				if (response.data.success === '1') {
					setMedicalhistory(response.data.result);
					setCount(response.data.totalCount);
					setDataFlag(true);
				}
			})
			.catch();
	};
	useEffect(() => {
		const PostData = {
			userId: window.sessionStorage.getItem('userId'),
			searchStatus: searchstatus,
			offset: pageOffset.toString(),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			searchId: filterName,
			searchValue: searchValue,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
			roleName: window.sessionStorage.getItem('role'),
		};
		callingAPI('medicinePrescription/hospitalMedicalHistory', PostData)
			.then((response) => {
				if (response.data.success === '0') {
					setMedicalhistory([]);
					setCount(0);
					setDataFlag(true);
				}
				if (response.data.success === '1') {
					setDataFlag(true);
					setMedicalhistory(response.data.result);
					setCount(response.data.totalCount);
				}
			})
			.catch();
	}, []);
	const FromDateClear = () => {
		setFromDate("")
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>

				</div>
			</CalendarContainer>
		);
	};
	const ToDateClear = () => {
		setToDate("")
	};
	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}
	function csvFileDownload() {
		const csvRow = [];
		var MedicalhistoryList = [
			[
				'Medicine%20Name',
				'Batch%20Number',
				'Ordered%20Stock%20Qty',
				'Available%20Qty',
				'Purchase%20Date	',
				'Expiry%20Date',
				'Selling%20Price',
			],
		];
		var name = Medicalhistory;
		const PostData = {
			userId: window.sessionStorage.getItem('userId'),
			searchStatus: searchstatus,
			offset: 0,
			max: "",
			searchId: filterName,
			searchValue: searchValue,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
			roleName: window.sessionStorage.getItem('role'),
		};

		callingAPI('medicinePrescription/hospitalMedicalHistory', PostData)
			.then((response) => {
				if (response.data.success === '0') {
					toast.error("No Data to Export")
				}
				if (response.data.success === '1') {
					for (var item = 0; item < response.data.result.length; item++) {
						for (var j = 0; j < response.data.result[item].expiryDate.length; j++) {
							if (j == 0) {
								MedicalhistoryList.push([
									response.data.result[item].medicineName,
									response.data.result[item].bantchNumber[j],
									response.data.result[item].orderStockQuantity[j],
									response.data.result[item].availableQuantity[j].availableQuantity,
									response.data.result[item].purchaseDate[j].replace(/,/g, "%20").replace(/ /g, "%20"),
									response.data.result[item].expiryDate[j].expiryDate.replace(/,/g, "%20").replace(/ /g, "%20"),
									response.data.result[item].sellingPrice[j],
								]);
							}
							else {
								let purchaseDate = "%20"
								let orderStockQuantity = "%20"
								let availableQuantity = "%20"
								let expiryDate = "%20"
								let sellingPrice = "%20"
								if (typeof response.data.result[item].purchaseDate[j] !== "undefined") {
									purchaseDate = response.data.result[item].purchaseDate[j].toString().replace(/,/g, "%20").replace(/ /g, "%20")

								}

								else if (typeof response.data.result[item].orderStockQuantity[j] !== "undefined") {
									orderStockQuantity = response.data.result[item].orderStockQuantity[j].toString().replace(/,/g, "%20").replace(/ /g, "%20")
								}
								else if (typeof response.data.result[item].availableQuantity[j] !== "undefined") {
									availableQuantity = response.data.result[item].availableQuantity[j].toString().replace(/,/g, "%20").replace(/ /g, "%20")
								}
								else if (typeof response.data.result[item].expiryDate[j] !== "undefined") {
									expiryDate = response.data.result[item].expiryDate[j].toString().replace(/,/g, "%20").replace(/ /g, "%20")
								}
								else if (typeof response.data.result[item].sellingPrice[j] !== "undefined") {
									sellingPrice = response.data.result[item].sellingPrice[j].toString().replace(/,/g, "%20").replace(/ /g, "%20")
								}
								MedicalhistoryList.push([
									"%20",
									// response.data.result[item].bantchNumber[j],
									// response.data.result[item].orderStockQuantity[j],
									// response.data.result[item].availableQuantity[j].availableQuantity,
									// response.data.result[item].expiryDate[j].expiryDate.replace(/,/g, "%20").replace(/ /g, "%20"),
									// response.data.result[item].sellingPrice[j],

									response.data.result[item].bantchNumber[j],
									orderStockQuantity,
									availableQuantity,
									purchaseDate,
									expiryDate,
									sellingPrice

								]);
							}
						}
					}
					for (var i = 0; i < MedicalhistoryList.length; ++i) {
						csvRow.push(MedicalhistoryList[i].join(','));
					}
					var csvString = csvRow.join('%0A');
					var create = document.createElement('a');
					create.href = 'data:attachment/csv,' + csvString;
					create.download = csvDownload + '.csv';
					document.body.appendChild(create);
					create.click();
				}


			})

	}
	return (
		<div className="dashboardCover pharmacyManufactureWrap">
			<ToastContainer position="bottom-right" />
			<div className="row">
				<div className="col-md-8">
					<div className="dashboardTitle"> Hospital Medicine History</div>
				</div>
			</div>
			{count > 10 && (
				<div className="paginationSection">
					<Pagination
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={dataPagination}
					/>
				</div>
			)}
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select
									className="inputField"
									id="selectName"
									name="selectName"
									value={filterName}
									onChange={(e) => changeTextonTextArea(e.target.value)}
								>
									<option value="">Search In</option>
									<option value="1">Medicine Name</option>
									<option value="2">Batch Number</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<input
									type="text"
									className="inputField"
									id="searchText"
									name="searchText"
									style={{ background: filterName === "" ? "transparent" : "white" }}
									value={searchValue}
									onChange={(e) => searchValueChange(e.target.value)}
									disabled
								/>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<select
									className="inputField"
									id="selectStatus"
									name="selectStatus"
									value={searchstatus}
									onChange={(e) => changeTextonTextAreaStatus(e.target.value)}
								>
									<option value="">Date</option>
									<option value="1">Purchase Date</option>
									<option value="2">Expiry Date</option>
								</select>

							</div>
							<div className="col-md-2 dashboardSearchList">
								<div >
									<Datepicker
										required
										style={{ background: "transparent" }}
										disabled={dateEnable}
										autoComplete="off"
										className="Datepicker pa2 inputField"
										maxDate={new Date()}
										placeholderText="From Date"
										calendarClassName="rasta-stripes"
										selected={fromDate}
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setFromDate(date)}
										id="fromdate" calendarContainer={MyContainer}
										onChangeRaw={handleDateChangeRaw}
										peekNextMonth
										showMonthDropdown
										showYearDropdown

										dropdownMode="select"
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="fromdate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div >
									<Datepicker
										required
										autoComplete="off"
										disabled={dateEnable}
										style={{ background: "transparent" }}
										className="Datepicker pa2 inputField"
										selected={toDate}
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setToDate(date)}
										placeholderText="To Date"
										calendarClassName="rasta-stripes"
										id="todate" calendarContainer={MyContainerTo}
										onChangeRaw={handleDateChangeRaw}
										peekNextMonth
										showMonthDropdown
										showYearDropdown

										dropdownMode="select"
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="todate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-1 dashboardSearchList">
								<span className="dashButtonBorder" style={{ border: "1px solid gainsboro", padding: '6px 5px' }} onClick={searchValueOnChangeOnSelect} >
									Search
								</span>
							</div>
							<div className="col-md-1 dashboardSearchList">
								<span onClick={csvFileDownload} className="dashButtonBorder dashButtonBorderGrey" style={{ border: "1px solid gainsboro", padding: '6px 5px' }}>
									Export
								</span>
							</div>
						</div>
						<div className="row dflexend ">

							<div className="col-md-3">
								<div className="searchCount" style={{ margin: "0px" }}>
									Total Medicines  : {count}
								</div>
							</div>
						</div>
					</div>
					{dataFlag == false ? (
						<Loader />
					) : Medicalhistory.length > 0 ? (
						<div className="dashboardtable">

							<table>
								<thead>
									<tr>
										<th>Medicine <br /> Name</th>
										<th>Batch <br /> Number </th>
										<th>Ordered Stock Qty</th>
										<th>Available Qty</th>
										<th>Purchase Date</th>
										<th>Expiry Date</th>
										<th style={{ textAlign: 'right' }}>Selling Price</th>
									</tr>
								</thead>
								<tbody>
									{Medicalhistory.map((item, i) => (
										<tr>
											<td>{item.medicineName}</td>
											<td dangerouslySetInnerHTML={{ __html: item.bantchNumber.join('<br/>') }}></td>
											<td dangerouslySetInnerHTML={{ __html: item.orderStockQuantity.join('<br/>') }}></td>

											{item.availableQuantity.map((data, i) => (
												<div>
													{data.hasStock == 0 ?
														<td style={{ color: "red", padding: '8px 8px 0' }}>
															{data.availableQuantity}</td>
														:
														<td style={{ padding: '0px 8px 0' }}>{data.availableQuantity}</td>}

												</div>
											))}
											<td><span class="refundReason elipsis" dangerouslySetInnerHTML={{ __html: item.purchaseDate.join('<br/>') }}  ></span></td>

											{item.expiryDate.map((value, i) => (
												<>
													{value.hasExpiry == 0 ?
														<div><td style={{ color: "red", padding: '8px 8px 0' }}>
															{value.expiryDate}</td></div>
														:
														<div><td style={{ padding: '0px 8px 0' }}>{value.expiryDate}</td></div>}

												</>
											))}
											<td style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{ __html: item.sellingPrice.join('<br/> ') }} />
										</tr>



									))}

								</tbody>
							</table>
						</div>) : (
						<p>
							<NoData />
						</p>
					)}
				</div>
				{count > 10 && (
					<div className="paginationSection">
						<Pagination
							activePage={activePage}
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={count}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={dataPagination}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
export default HospitalMedicineHistory;
