import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Active from '../images/icons/active.png';
import Edit from '../images/icons/edit-bg.png';
import Inactive from '../images/icons/inactive.png';
import Youtube from '../images/icons/youtube.png';
import { callingAPI } from '../config';
import Pagination from 'react-js-pagination';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function DoctorsList(props) {
	const navigate = useNavigate();
	const [ListArray, setdoctorsListArray] = useState([]);
	const [searchValue, setsearchinput] = useState('');
	const [selectvalue, setselect] = useState('');
	const [dataflag, setdataflag] = useState(false);
	const [activePage, setActivePage] = useState(1);
	const [count, setCount] = useState([]);
	const [pageOffset, setPageOffset] = useState(0);
	const [booliansearchValue, setbooliansearchValue] = useState('');
	const [boolianselectvalue, setboolianselectvalue] = useState('');
	const Listing = () => {
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			selectCombo: selectvalue,
			inputSearch: searchValue,
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: window.sessionStorage.getItem("offsetDrDetails"),
			doctorAdd: 1
		};
		callingAPI('commonUserFunctions/doctorsList', PostData).then((response) => {
			if (response.data.success === '1') {
				setdoctorsListArray(response.data.result.doctorsListArray);
				setdataflag(true);
				setCount(response.data.result.count);
			}
			else {
				toast.error("no doctors found")
				setdoctorsListArray([]);
				setdataflag(true);
				setCount(0)
			}
		});
	};
	useEffect(() => {
		setActivePage(Number(window.sessionStorage.getItem("pageNumberDrDetails")));
		Listing();
	}, []);
	const searchFunction = () => {
		setActivePage(1);
		if (selectvalue != '4' && selectvalue != '5' && selectvalue != '' && searchValue == '') {
			document.getElementById('inputTxt').disabled = false;
			document.getElementById('inputTxt').focus();
			var t = document.getElementById('select');
			var selectedText = t.options[t.selectedIndex].text;
			toast.error(selectedText + ' Required');
		}
		setbooliansearchValue(selectvalue);
		setboolianselectvalue(searchValue);
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			selectCombo: selectvalue,
			inputSearch: searchValue,
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: pageOffset,
			doctorAdd: 1
		};
		callingAPI('commonUserFunctions/doctorsList', PostData).then((response) => {
			if (response.data.success === '1') {
				setdoctorsListArray(response.data.result.doctorsListArray);
				setdataflag(true);
				setCount(response.data.result.count);
			}
			else {
				toast.error("no doctors found")
			}
		});
	};
	const select = (value) => {
		setsearchinput("")
		setselect(value);
		if (value === '') {
			setselect('');
			document.getElementById('inputTxt').disabled = true;
			setsearchinput('');
		} else if (value == '4') {
			setselect('4');
			document.getElementById('inputTxt').disabled = true;
		} else if (value == '5') {
			setselect('5');
			document.getElementById('inputTxt').disabled = true;
		} else {
			document.getElementById('inputTxt').disabled = false;
			document.getElementById('inputTxt').focus();
		}
	};
	const searchInput = (value) => {
		setsearchinput(value);
		if (value === '') {
			setsearchinput('');
		}
	};
	const OnClickFunction = (doctorId, status) => {
		var x = false;
		if (status == true) {
			x = window.confirm('Do you want to Enable this?');
		} else {
			x = window.confirm('Do you want to disable this?');
		}
		if (x === true) {
			let PostData = {
				isVerified: status,
				isEnabled: status,
				doctorId: doctorId,
			};
			callingAPI('commonUserFunctions/activateDoctor', PostData).then((response) => {
				if (response.data.success === '1') {
					Listing();
				} else {
					setdoctorsListArray([]);
				}
			});
		}
	};
	const hospitalDoctorspermissionChk = (hospitalId, doctorPermission, doctorId) => {
		var y = 'false';
		if (doctorPermission === true) {
			y = window.confirm('Confirm Add Doctor Permission?');
		} else {
			y = window.confirm('Remove Add Doctor Permission?');
		}
		if (y === true) {
			let PostData = {
				hospitalId: hospitalId,
				doctorPermission: doctorPermission,
				doctorId: doctorId,
			};
			callingAPI('commonUserFunctions/setPermission', PostData).then((response) => {
				if (response.data.success == 1) {
					toast.success(response.data.successMessage);
					Listing();
				}
			});
		}
	};
	const handlePageChange = (pageNumber) => {
		// setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianselectvalue) { setselect("") }
		if (!booliansearchValue) { setsearchinput("") }
		window.sessionStorage.setItem("pageNumberDrDetails", pageNumber);
		window.sessionStorage.setItem("offsetDrDetails", offset);
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			selectCombo: boolianselectvalue,
			inputSearch: booliansearchValue,
			max: max,
			offset: offset,
			doctorAdd: 1
		};
		callingAPI('commonUserFunctions/doctorsList', PostData).then((response) => {
			if (response.data.success === '1') {
				setdoctorsListArray(response.data.result.doctorsListArray);
				setCount(response.data.result.count);
			}
			else {
				toast.error("no doctors found")
			}
		});
	};
	const editDoc = (data) => {
		navigate('/doctor/doctorListAdd', { state: data })
	}
	return (
		<div className="coverWraper">
			<ToastContainer position="bottom-right" />
			<Header />
			<div>
				{dataflag == false ? (
					<div align="center">
						<Loader />
					</div>
				) : (
					<div className="contentWraper contentWraperScroll pl_staffList drListTble">
						<div class="pulseContentCoverWraper">
							<div class="container">
								<br />
								<br />
								{count > 10 &&
									<div className="paginationSection">
										<Pagination
											//  hideNavigation
											hideDisabled
											activePage={activePage}
											itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
											totalItemsCount={count}
											pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
											onChange={handlePageChange}
										/>
									</div>
								}
								<div class="content4">
									<div class="container">
										<div />
										<div class="tableHolder StaffList-MainDiv">
											<div class="left" style={{ float: 'left', width: '100%' }}>
												{/* <a
													href=""
													class="practiceGreenButton"
													style={{
														marginTop: '10px',
														textDecoration: 'none',
														border: 'none',
													}}
												>
													<img src={Youtube} />
												</a> */}
												<a
													href="/doctor/doctorListAdd"
													class="practiceGreenButton"
													style={{
														marginRight: '0',
														marginTop: '10px',
														marginLeft: '8px',
														textDecoration: 'none',
													}}
												>
													Add New Doctor
												</a>
											</div>
											<h3>Doctors List</h3>
											<div class="tableheadHolder tableTopHold tableTopHoldButton">
												<select
													class="mdmField inputField"
													name="select"
													id="select"
													value={selectvalue}
													onChange={(e) => select(e.target.value)}
												>
													<option value="">Search In</option>
													<option value="2">Doctor Name</option>
													<option value="3">City</option>
													<option value="6">Mobile Number</option>
													<option value="7">Email Id</option>
													<option value="4">Active</option>
													<option value="5">Inactive</option>
												</select>
												<div class="selctFieldHolder">
													<input
														style={{ background: selectvalue === "" ? "#f2f2f2" : "white" }}
														class="mdmField inputField"
														id="inputTxt"
														placeholder=""
														type="text"
														value={searchValue}
														onChange={(e) => searchInput(e.target.value)}
														name="inputTxt"
													// disabled
													/>
												</div>
												<div class="selctFieldHolder pl_search">
													<input
														type="submit"
														name="_action_staffList"
														value="Search "
														class="btn_upload btn-primary btnOrange white"
														title="Search"
														onClick={searchFunction}
													/>
												</div>
											</div>
											<br />
											<br />
											<div class="col-md-12 overflow-tableOuter">
												<table class="table doctrListTble  ">
													<thead style={{ borderBottom: '1px solid' }}>
														<tr>
															<th class="tableCntWdth" colspan="2">
																Doctor Name
															</th>
															<th class="tableCntWdth" colspan="3">
																Mobile Number
															</th>
															<th class="tableCntWdth" colspan="3">
																Email Id
															</th>
															<th class="tableCntWdth" colspan="3">
																Specialisation
															</th>
															<th class="tableCntWdth" colspan="3">
																City
															</th>
															<th class="tableCntWdth" colspan="3">
																Actions
															</th>
															<th class="tableCntWdth" colspan="3">
																Add Doctor Permission
															</th>
														</tr>
													</thead>
													{ListArray.length > 0 &&
														dataflag == true && (
															<tbody>
																{ListArray.map((Arraylist, i) => (
																	<tr>
																		<td colspan="2">{Arraylist.doctorName}</td>
																		<td colspan="3">{Arraylist.secUserMobileNumber}</td>
																		<td colspan="3">{Arraylist.secUserEmailid}</td>
																		<td colspan="3">{Arraylist.specialization}</td>
																		<td colspan="3">{Arraylist.hospitalCity}</td>
																		<td colspan="2">
																			<span
																				class="edit_align"
																				title="Edit"
																				style={{
																					textAlign: 'left!important',
																					float: 'left',
																				}}
																			>
																				<span onClick={() => editDoc({ doctorEditId: Arraylist.doctorId })}>
																					{/* <Link
																						to={{
																							pathname:
																								'/doctor/doctorListAdd',
																							state: {
																								doctorEditId:
																									Arraylist.doctorId,
																							},
																						}}
																					> */}
																					<img src={Edit} />
																					{/* </Link> */}
																				</span>
																			</span>
																			<div style={{ float: 'left' }}>
																				{Arraylist.secUserIsVerified === true && Arraylist.secUserIsEnabled === true ? (
																					<div>
																						<span
																							class="edit_align"
																							title="Edit"
																							style={{
																								textAlign: 'left!important',
																							}}
																						>
																							&nbsp;<a
																								href="#"
																								title="Click to Dectivate"
																								style={{
																									height: '35px',
																									float: 'right',
																								}}
																							>
																								<div
																									onClick={(e) =>
																										OnClickFunction(
																											Arraylist.doctorId,
																											false
																										)}
																								>
																									<img src={Active} />
																								</div>
																							</a>
																						</span>
																					</div>
																				) : (
																					<div>
																						<span
																							class="edit_align"
																							title="Edit"
																							style={{
																								textAlign: 'left!important',
																							}}
																						>
																							&nbsp;<a
																								href="#"
																								title="Click to Activate"
																								style={{
																									height: '35px',
																									float: 'right',
																								}}
																							>
																								<div
																									onClick={(e) =>
																										OnClickFunction(
																											Arraylist.doctorId,
																											true
																										)}
																								>
																									<img src={Inactive} />
																								</div>
																							</a>
																						</span>
																					</div>
																				)}
																			</div>
																		</td>
																		<td colspan="4">
																			<div class="form-group">
																				<input
																					type="checkbox"
																					onChange={(e) =>
																						hospitalDoctorspermissionChk(
																							Arraylist.hospitalId,
																							e.target.checked,
																							Arraylist.doctorId
																						)}
																					checked={
																						Arraylist.hospitalDoctorspermission
																					}
																				/>
																			</div>
																		</td>
																	</tr>
																))}
															</tbody>
														)}
													<tr>
														<td colspan="20">
															{ListArray.length == 0 && dataflag == true && <NoData />}
														</td>
													</tr>
													<tfoot />
												</table>
												<div className="paginationSection">
													{count > 10 &&
														<div class="container">
															<div class="doc pagination prctmngmtPageWrap" />
															<Pagination
																//  hideNavigation
																hideDisabled
																activePage={activePage}
																itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
																totalItemsCount={count}
																pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
																onChange={handlePageChange}
															/>
														</div>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="dividerSml" />
						</div>
					</div>
				)}
			</div>
			<Footer />
		</div>
	);
}
export default DoctorsList;
