import React, { useState, useEffect } from 'react';
import Datepicker, { CalendarContainer } from 'react-datepicker';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import NoData from '../Common/noDataFound';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Refund() {
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [functionName, setFunctionName] = useState('refundDetails');
    const [refundDetails, setRefundDetails] = useState([]);
    const [totalRefund, setTotalRefund] = useState();
    const [filterName, setFilterInput] = useState(0);
    const [numberOfOrders, setNumberOfOrders] = useState();
    const [activePage, setActivePage] = useState(1);
    const [pageOffset, setPageOffset] = useState(0);
    const [isData, setIsData] = useState(0);
    const handlePagination = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === '1') {
            offset = '0';
            max = process.env.REACT_APP_PAGINATION_COUNT;
        } else {
            offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
            max = process.env.REACT_APP_PAGINATION_COUNT;
        }
        setActivePage(pageNumber);
        setPageOffset(offset)
        let PostData = {
            userId: window.sessionStorage.getItem("userId"),
            role: window.sessionStorage.getItem("role"),
            max: max,
            offset: offset,
            cmbSelect: document.querySelector('#selectName').value,
            txtInput: document.querySelector('#searchText').value,
            fromDate: fromDate ? formatDate(fromDate) : '',
            toDate: toDate ? formatDate(toDate) : '',
        };
        callingAPI('reports/refundDetails', PostData)
            .then((response) => {
                if (response.data.success === '1') {
                    setRefundDetails(response.data.result.refundDetails);
                    setTotalRefund(response.data.result.totalRefundAmount);
                    setNumberOfOrders(response.data.result.count);
                    setIsData(1);
                }
            })
            .catch();
    };
    const changeText = () => {
        setFilterInput(document.querySelector('#selectName').value)
        if (document.querySelector('#selectName').value !== '0') {
            document.getElementById('searchText').disabled = false;
            document.querySelector('input[name=searchText]').focus();
        } else {
            document.getElementById('searchText').disabled = true;
        }
        document.getElementById('searchText').value = '';
    };
    function formatDate(date) {
        var date = new Date(date),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }
    const searchValueChange = () => {
        if (document.querySelector('#selectName').value !== '0' && document.querySelector('#searchText').value === '') {
            var t = document.getElementById('selectName');
            var selectedText = t.options[t.selectedIndex].text;
            toast.error("Enter" + " " + selectedText)
            document.querySelector('#searchText').focus();
            return false;
        }

        if (fromDate && toDate) {
            if (fromDate > toDate) {
                toast.error(' End date must be greater than or equal to start date');
                document.getElementById("todate").focus()
                return (false);
            }
        }
        setActivePage(1)
        let PostData = {
            userId: window.sessionStorage.getItem("userId"),
            role: window.sessionStorage.getItem("role"),
            max: process.env.REACT_APP_PAGINATION_COUNT,
            offset: 0,
            cmbSelect: document.querySelector('#selectName').value,
            txtInput: document.querySelector('#searchText').value,
            fromDate: fromDate ? formatDate(fromDate) : '',
            toDate: toDate ? formatDate(toDate) : '',
        };
        callingAPI('reports/refundDetails', PostData)
            .then((response) => {
                if (response.data.success === '0') {
                    setRefundDetails([]);
                    setIsData(1);
                }
                if (response.data.success === '1') {
                    setRefundDetails(response.data.result.refundDetails);
                    setTotalRefund(response.data.result.totalRefundAmount);
                    setNumberOfOrders(response.data.result.count);
                    setIsData(1);
                }
            })
            .catch();
    };
    useEffect(() => {
        changeText();
        const PostData = {
            userId: window.sessionStorage.getItem("userId"),
            role: window.sessionStorage.getItem("role"),
            max: process.env.REACT_APP_PAGINATION_COUNT,
            offset: pageOffset,
        };

        callingAPI('reports/refundDetails', PostData)
            .then((response) => {

                if (response.data.success === '1') {
                    setRefundDetails(response.data.result.refundDetails);
                    setTotalRefund(response.data.result.totalRefundAmount);
                    setNumberOfOrders(response.data.result.count);
                    setIsData(1);
                }
                else {
                    setIsData(1);
                    setRefundDetails([]);

                }
            })
            .catch();
        setIsData(1);
    }, []);
    const setReceiptRecptno = (htmlData) => {

        var a = `${process.env.PUBLIC_URL}/patients/RecieptPrint/Refund/${htmlData + '|' + window.sessionStorage.getItem("doctorId") + '|' + window.localStorage.getItem('hospitalId')}`
        window.open(a, "_blank")
    }
    function csvFileDownload() {
        if (refundDetails.length === 0) {
            toast.error("No Data to Export");
        }
        else {
            const csvRow = [];
            var fileDownloadArray = [
                ['Receipt%20No:', 'Refunded%20By', 'Refunded%20To', 'Refunded%20Date', 'Amount', '	Reason'],
            ];
            var arrayDetails = refundDetails;
            let PostData = {
                userId: window.sessionStorage.getItem("userId"),
                role: window.sessionStorage.getItem("role"),
                max: "",
                offset: 0,
                cmbSelect: document.querySelector('#selectName').value,
                txtInput: document.querySelector('#searchText').value,
                fromDate: fromDate ? formatDate(fromDate) : '',
                toDate: toDate ? formatDate(toDate) : '',
            };
            callingAPI('reports/refundDetails', PostData)
                .then((response) => {
                    for (var j = 0; j < response.data.result.refundDetails.length; j++) {
                        fileDownloadArray.push([
                            response.data.result.refundDetails[j].memberProcedurePaymentReceiptRecptno,
                            response.data.result.refundDetails[j].secUserRefundedby.replace(/ /g, '%20'),
                            response.data.result.refundDetails[j].memberPatntname.replace(/ /g, '%20'),
                            response.data.result.refundDetails[j].memberProcedurePaymentReceiptDate.replace(/,/g, '%20').replace(/ /g, '%20'),
                            "Rs." + "" + response.data.result.refundDetails[j].memberProcedurePaymentReceiptAmount.replace(/,/g, '%20').replace(/ /g, '%20'),
                            response.data.result.refundDetails[j].memberProcedurePaymentReceiptReason.replace(/ /g, '%20'),
                        ]);
                    }
                    for (var i = 0; i < fileDownloadArray.length; ++i) {
                        csvRow.push(fileDownloadArray[i].join(','));
                    }
                    var csvString = csvRow.join("%0A");
                    var a = document.createElement("a");
                    a.href = "data:attachment/csv," + csvString;
                    a.target = "_Blank";
                    a.download = functionName + ".csv";
                    document.body.appendChild(a);
                    a.click();
                })
        }
    }
    const FromDateClear = () => {
        setFromDate("")
    };
    const MyContainer = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                <div >
                    <span className="clearCloseCalander">
                        {children}
                        <a className="clearCalander" onClick={FromDateClear} >Clear  </a>
                    </span>

                </div>
            </CalendarContainer>
        );
    };
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    const ToDateClear = () => {
        setToDate("")
    };

    const MyContainerTo = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                <div >
                    <span className="clearCloseCalander">
                        {children}
                        <a className="clearCalander" onClick={ToDateClear} >Clear  </a>
                        {/* <a  className="closeCalander">Close</a> */}
                    </span>
                </div>
            </CalendarContainer>
        );
    };
    return (
        <div className="dashboardCover">
            <ToastContainer position="bottom-right" />
            <div className="dashboardTitle">Refund</div>
            {/* {process.env.REACT_APP_PAGINATION_COUNT > 10 && ( */}
            {numberOfOrders > 10 && (
                <div className="paginationSection">
                    <Pagination
                        // hideNavigation
                        activePage={activePage}
                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                        totalItemsCount={numberOfOrders}
                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                        onChange={handlePagination}
                    />
                </div>)}
            {/* )} */}
            <div className="dashboardContent">
                <div className="reportWrap">
                    <div className="dashboardSearch">
                        <div className="row">
                            <div className="col-md-2 dashboardSearchList">
                                <select className="inputField" id="selectName" name="selectName" title="Search In"
                                    // value={filterName}

                                    onChange={changeText}>
                                    <option value="0" >Search In</option>
                                    <option value="1"> Refunded To </option>
                                    <option value="2"> Receipt No </option>
                                    <option value="3"> Refunded By </option>
                                </select>
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                {filterName == 0 ?
                                    <input type="text" className="inputField" id="searchText" name="searchText" style={{ background: "transparent" }} />
                                    : <input type="text" className="inputField" id="searchText" name="searchText" />}
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                <div>
                                    <Datepicker
                                        required
                                        className="Datepicker pa2 inputField"
                                        maxDate={new Date()}
                                        placeholderText="From"
                                        calendarClassName="rasta-stripes"
                                        selected={fromDate}
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => setFromDate(date)}
                                        id="fromdate"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        calendarContainer={MyContainer}
                                        onChangeRaw={handleDateChangeRaw}
                                    />
                                    <label
                                        className="icon iconCalendar calendar plReportsCalendar"
                                        style={{ fontSize: '16px' }}
                                        htmlFor="fromdate"
                                    >
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                <div>
                                    <Datepicker
                                        required
                                        className="Datepicker pa2 inputField"
                                        maxDate={new Date()}
                                        placeholderText="To"
                                        calendarClassName="rasta-stripes"
                                        selected={toDate}
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => setToDate(date)}
                                        id="todate"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }} calendarContainer={MyContainerTo}
                                        onChangeRaw={handleDateChangeRaw}
                                    />
                                    <label
                                        className="icon iconCalendar calendar plReportsCalendar"
                                        style={{ fontSize: '16px' }}
                                        htmlFor="todate"
                                    >
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                <span onClick={searchValueChange} title="search" className="dashButtonBorder">
                                    Search
                                </span>
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                <span onClick={csvFileDownload} title="export" className="dashButtonBorder dashButtonBorderGrey">
                                    Export
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="searchTxt" style={{ textAlign: 'right' }}>
                                    <b>Total Refund : &#8377; {refundDetails.length == 0 ? 0 : totalRefund}</b>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="searchCount">
                                    <b>Number of Refunds : {refundDetails.length == 0 ? 0 : numberOfOrders}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboardtable">
                        {isData == 0 ? (<Loader />
                        ) : refundDetails.length > 0 ?
                            <table>
                                <thead>
                                    <tr>
                                        <th>Receipt No</th>
                                        <th>Refunded By </th>
                                        <th>Refunded To</th>
                                        <th>Refunded Date</th>
                                        <th>Amount</th>
                                        <th>Reason</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {refundDetails.map((data, i) => (
                                        <tr>
                                            <td>
                                                {' '}
                                                {data.memberProcedurePaymentReceiptRecptno != '' ?
                                                    <Link to="#" onClick={() => setReceiptRecptno(data.memberProcedurePaymentReceiptId)}> {data.memberProcedurePaymentReceiptRecptno}</Link>
                                                    : ''}
                                                {/* <Link
                                                to={{
                                                    pathname: '/patients/RecieptPrint/Refund',
                                                    state: { memberProcedurePaymentReceiptId: data.memberProcedurePaymentReceiptId }
                                                }}
                                                className="mr-2 tableThumbAction"
                                            >
                                                {data.memberProcedurePaymentReceiptRecptno}
                                            </Link> */}
                                            </td>
                                            <td> {data.secUserRefundedby}</td>
                                            <td> {data.memberPatntname}</td>
                                            <td> {data.memberProcedurePaymentReceiptDate}</td>
                                            <td> &#8377; {data.memberProcedurePaymentReceiptAmount}</td>
                                            <td> {data.memberProcedurePaymentReceiptReason}</td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                            : <NoData />}
                    </div>
                </div>
            </div>
            {/* {process.env.REACT_APP_PAGINATION_COUNT > 10 && ( */}
            {/* {refundDetails.length > 0 && ( */}
            {numberOfOrders > 10 && (
                <div className="paginationSection">
                    <Pagination
                        // hideNavigation
                        activePage={activePage}
                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                        totalItemsCount={numberOfOrders}
                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                        onChange={handlePagination}
                    />
                </div>
            )}
            {/* )} */}
        </div>
    );
}
export default Refund;
