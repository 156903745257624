import React, { useEffect, useState } from 'react';
import { callingAPI } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Professionaldetails(props) {
	const [professionalDesc, setProfessionalDesc] = useState('');
	const [practiceYear, setPracticeYear] = useState('');
	const [yearValues, setYearvalues] = useState([]);
	const [displayErrMsg1, setDisplayErrMsg1] = useState('');
	const [dataflag, setdataflag] = useState(false);

	const descChange = (event) => {
		setProfessionalDesc(event.target.value);
	};
	const selectPracticeYearChange = (event) => {
		setPracticeYear(event.target.value);
	};
	const saveProfessionalDetails = () => {
		var flag = true;
		setdataflag(false)
		if (practiceYear == 0) {
			setDisplayErrMsg1('Please enter Practice Start Year');
			alertlanguage()
			setdataflag(false)
			// window.scrollTo(0, 0)
			document.getElementById('practiceyear').focus();
			flag = false;
		} else if (!professionalDesc) {
			setDisplayErrMsg1('Please enter Professional Statement');
			alertlanguage()
			setdataflag(false)
			// window.scrollTo(0, 0)
			document.getElementById('professionaldesc').focus();
			flag = false;
		} else if (flag == true) {
			setdataflag(true)
			const postData = {
				doctorPracticeStartYear: practiceYear,
				doctorProfessionalStatement: professionalDesc,
				subFunctionName: 'updateProfessionalDetails',
				doctorId: window.sessionStorage.getItem('doctorId'),
			};
			callingAPI('doctorProfile/updateDoctorProfile', postData)
				.then((res) => {
					toast.success(res.data.successMessage);
					window.location.href = '/Dashboard/profile';
				})
				.catch();
		}
	};
	const alertlanguage = () => {
		setTimeout(() => {
			setDisplayErrMsg1('');
		}, 2000);

	}
	const getyearValues = () => {
		var ddlYears = document.getElementById('practiceyear');
		var currentYear = new Date().getFullYear();

		var year = [];
		for (var i = 1950; i <= currentYear; i++) {
			var option = document.createElement('OPTION');
			option.innerHTML = i;
			option.value = i;
			ddlYears.appendChild(option);
		}

		setYearvalues(year);
	};
	useEffect(
		() => {
			getyearValues();
			alertlanguage()
			if (props.professionalDetails) {
				setPracticeYear(props.professionalDetails.doctorPracticeStartYear);
				setProfessionalDesc(props.professionalDetails.doctorProfessionalStatement);
			}
		},
		[props.professionalDetails]
	);
	return (
		<div>
			<div className="homeProfileDetails">
				<ToastContainer position="bottom-right" />
				<div className="practiceManagementGrid profileForm">
					<div className="row rowMargin">
						<div className='col-md-12'>
							<p
								style={{
									display: 'flex',
									justifyContent: 'center',
									color: 'red',
									padding: '0px',
									margin: '0px', fontSize: "13px", paddingBottom: "15px"
								}}
							>
								{displayErrMsg1}
							</p>
						</div>
						<div className="col-md-6 col-lg-6 practiceManagementList">

							<label className="labelTextField labelMarginBottom">
								Practice Start Year<sup>*</sup>
							</label>

							<select
								className="inputField"
								id="practiceyear"
								name="practiceyear"
								value={practiceYear}
								onChange={selectPracticeYearChange}
							>
								<option value="0">Year</option>
							</select>
						</div>
						<div className="col-md-12 col-lg-12 practiceManagementList">
							<label className="labelTextField labelMarginBottom">Professional Statement<sup>*</sup></label>
							<textarea
								value={professionalDesc}
								name="professionaldesc"
								id="professionaldesc"
								className="textareaField"
								onChange={descChange}
								placeholder={"Type Professional Statement"}
							/>
						</div>
						<div className="col-md-4 col-lg-4 practiceManagementList">
							<button
								disabled={dataflag}
								class="borderButton borderButtonLarge legoButton_green"
								onClick={saveProfessionalDetails}
								style={{ width: '100%' }}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Professionaldetails;
