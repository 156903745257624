import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { callingAPI, callingPostAPI } from '../config';
import moment from 'moment';
import Loader from '../Common/loader';import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewBatchMedicine() {
	const [isData, setIsData] = useState(true);
	const [taxCalculation, setTaxCalculation] = useState(0);
	const [manufactureAuto, setManufactureAuto] = useState([]);
	const [medicineAuto, setMedicineAuto] = useState([]);
	const [medicineUnits, setMedicineUnits] = useState([]);
	const [medicineTypes, setMedicineTypes] = useState([]);
	const [taxListdata, setTaxListData] = useState([]);
	const [onlycheckedTaxList, setOnlyCheckedTaxList] = useState([]);
	const [selectedTaxNewArray, setSelectedTaxNewArray] = useState([]);

	const [categoryType, setcategoryType] = useState(1);
	const [brandName, setBrandName] = useState('');
	const [medicineId, setMedicineId] = useState('');
	const [genericName, setGenericName] = useState('');
	const [hsnCode, setHsnCode] = useState('');
	const [medType, setMedType] = useState('');
	const [medTypeName, setMedTypeName] = useState('');
	const [strength, setStrength] = useState('');
	const [medUnit, setMedUnit] = useState('');
	const [medUnitName, setMedUnitName] = useState('');
	const [interactions, setInteractions] = useState('');
	const [drugReactions, setDrugReactions] = useState('');
	const [majorSideEffects, setMajorSideEffects] = useState('');
	const [dosage, setDosage] = useState('');
	const [precautions, setPrecautions] = useState('');
	const [stripCount, setStripCount] = useState('');
	const [batchNo, setBatchNo] = useState('');
	const [stockQuantity, setStockQuantity] = useState('');
	const [expireDate, setExpireDate] = useState('');
	const [reOrderQuantity, setReorderQuantitu] = useState('');
	const [mManufactureName, setmManufactureName] = useState('');
	const [mManufactureId, setmManufactureId] = useState('');


	// const [mrp, setMrp] = useState(0);
	// const [mrpUnitPrice, setmrpUnitPrice] = useState(0);
	// const [sellingPriceWithoutTax, setSellingPriceWithoutTax] = useState(0);
	// const [sellingUnitPriceWithoutTax, setSellingUnitPriceWithoutTax] = useState(0);
	// const [sellingPriceWithTax, setSellingPriceWithTax] = useState(0);
	// const [sellingUnitPriceWithTax, setSellingUnitPriceWithTax] = useState(0);

	const [mrp, setMrp] = useState("");
	const [mrpUnitPrice, setmrpUnitPrice] = useState("");
	const [sellingPriceWithoutTax, setSellingPriceWithoutTax] = useState("");
	const [sellingUnitPriceWithoutTax, setSellingUnitPriceWithoutTax] = useState("");
	const [sellingPriceWithTax, setSellingPriceWithTax] = useState("");
	const [sellingUnitPriceWithTax, setSellingUnitPriceWithTax] = useState("");
	const [isShowGeneric, setIsGeneric] = useState(false);

	// const [purchasePrice, setPurchasePrice] = useState("");
	// const [purchaseUnitPrice, setPurchaseUnitPrice] = useState("");
	// const [purchasePriceWithTax, setPurchasePriceWithTax] = useState("");
	// const [purchaseUnitPriceWithTax, setPurchaseUnitPriceWithTax] = useState("");
	const [popVisible, setPopVisible] = useState(false)
	const [pmhospitalMedicineId, setpmHospitalMedicineId] = useState('');
	const [manufactError, setManufactError] = useState('');

	var date = new Date();
	var requiredDate = moment(date).format('YYYY-MM');

	const location = useLocation();
	const [selectedTaxIdList, setSelectedTaxIdList] = useState([]);

	var selectedTaxListdata = [];


	const onChangeGenericName = (event) => {
		setGenericName(event.target.value);
	};
	const onChangeisGeneric = (event) => {
		setIsGeneric(event.target.checked);
	};
	const onChangeHsnCode = (event) => {
		setHsnCode(event.target.value);
	};
	const onChangeBatchNo = (event) => {
		setBatchNo(event.target.value);
	};
	const onChangeInteraction = (event) => {
		setInteractions(event.target.value);
	};
	const onChangeDrugReactions = (event) => {
		setDrugReactions(event.target.value);
	};
	const onChangeMajorSideEffects = (event) => {
		setMajorSideEffects(event.target.value);
	};
	const onChangeDosage = (event) => {
		setDosage(event.target.value);
	};
	const onChangePrecautions = (event) => {
		setPrecautions(event.target.value);
	};
	const onChangeStripCount = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setStripCount(event.target.value);
			setMrp('');
			setmrpUnitPrice('');
			setSellingPriceWithoutTax('');
			setSellingUnitPriceWithoutTax('');
			setSellingPriceWithTax('');
			setSellingUnitPriceWithTax('');
		} else {
			toast.error('Only Numbers Accepted');
		}
	};

	const onChangeStockQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setStockQuantity(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const onChangeExpireDate = (event) => {
		setExpireDate(event.target.value);
	};
	const onChangeReOrderQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setReorderQuantitu(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const onBlurBrandCategory = () => {
		document.getElementById('medicineList').style.display = 'none';
		setMedicineAuto([]);
	};
	const stripCountBy = () => {

		var price = mrp;
		var quantity = stripCount;
		var totalCost = price / quantity;
		setmrpUnitPrice(parseFloat(totalCost).toFixed(2));
		setSellingUnitPriceWithoutTax(parseFloat(totalCost).toFixed(2));
		setSellingUnitPriceWithTax(parseFloat(totalCost).toFixed(2));

	};

	const stripCountBySellingArea = (event) => {
		var quantity = stripCount;
		if (document.getElementById("sellingPricewithoutTax").value == "") {
			setSellingUnitPriceWithoutTax(sellingPriceWithoutTax);
			setSellingPriceWithTax(sellingPriceWithoutTax);
			setSellingUnitPriceWithTax(sellingPriceWithoutTax);
		}
		else if (taxListdata.length != 0) {
			let obj = [...taxListdata]
			let taxCalc1 = 0;
			var quantity = stripCount;
			var totalCost = 0;
			obj.map((val, index) => {
				if (val.isChecked == true) {
					taxCalc1 = taxCalc1 + (sellingPriceWithoutTax * Number(val.hospitalTaxDetailsTaxValue) / 100);
				}

				setSellingPriceWithTax(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1));
				setSellingUnitPriceWithTax(parseFloat(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1)).toFixed(2));
				setSellingUnitPriceWithoutTax((parseFloat(sellingPriceWithoutTax)))
				if (categoryType == 1) {
					setSellingPriceWithTax(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1));
					totalCost = (parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1)) / quantity;
					setSellingUnitPriceWithTax(parseFloat(totalCost).toFixed(2));
					setSellingUnitPriceWithoutTax((parseFloat(sellingPriceWithoutTax)) / quantity)
				}
			});
		}
		else {
			if (categoryType == 1) {
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value / quantity);
				setSellingUnitPriceWithoutTax(event.target.value / quantity);
			}
			else {
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
			}



		}
	};

	const stripCountBySellingAreawithTax = (event) => {
		var stripcount = stripCount;
		if (document.getElementById("sellingPricewithTax").value == "") {
			setSellingPriceWithoutTax(sellingPriceWithTax);
			setSellingUnitPriceWithoutTax(sellingPriceWithTax);
			setSellingUnitPriceWithTax(sellingPriceWithTax)
		}
		else if (taxListdata.length != 0) {
			let obj = [...taxListdata]
			let taxCalc = 0;
			let sellingPriceMultiple = 0;
			let totalTaxValue = 0;
			let totalTaxCalculation = 0;
			obj.map((val, index) => {
				if (val.isChecked == true) {
					taxCalc = taxCalc + Number(val.hospitalTaxDetailsTaxValue);
				}
				sellingPriceMultiple = sellingPriceWithTax * 100;
				totalTaxValue = taxCalc + 100;
				totalTaxCalculation = sellingPriceMultiple / totalTaxValue;

				setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
				setSellingUnitPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
				setSellingUnitPriceWithTax((parseFloat(sellingPriceWithTax)))
				if (categoryType == 1) {
					var stripcount = stripCount;
					var unitSellingPice = totalTaxCalculation / stripcount;
					setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
					setSellingUnitPriceWithoutTax(Math.round(unitSellingPice.toFixed(2) * 100) / 100);
					setSellingUnitPriceWithTax((parseFloat(sellingPriceWithTax)) / stripcount)
				}
			});
		}
		else {
			if (categoryType == 1) {
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value / stripCount);
				setSellingUnitPriceWithTax(event.target.value / stripCount);
			}
			else {
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			}
		}
	};

	const onChangemrp = (event) => {
		const re = /^[0-9.\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setMrp(event.target.value);
			setSellingPriceWithoutTax(event.target.value);
			setSellingPriceWithTax(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
			return
		}

		if (!stripCount) {
			toast.error('NO: of Units Required');
			document.getElementById('hdnMedicineQuantity').focus();
			setMrp('');
			setmrpUnitPrice('');
			setSellingPriceWithoutTax('');
			setSellingUnitPriceWithoutTax('');
			setSellingPriceWithTax('');
			setSellingUnitPriceWithTax('');
			setSelectedTaxNewArray([])
		}


		setSelectedTaxIdList([])

	};

	const onChangeSellingPriceAreawithTax = (event) => {
		if (categoryType == 1) {
			if (!stripCount) {
				toast.error('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				setMrp('');
				setmrpUnitPrice('');
				setSellingPriceWithoutTax('');
				setSellingUnitPriceWithoutTax('');
				setSellingPriceWithTax('');
				setSellingUnitPriceWithTax('');
			}
		}
		const re = /^[0-9.\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setSellingPriceWithTax(event.target.value);



		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const onChangeSellingPriceArea = (event) => {
		if (categoryType == 1) {
			if (!stripCount) {
				toast.error('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				setMrp('');
				setmrpUnitPrice('');
				setSellingPriceWithoutTax('');
				setSellingUnitPriceWithoutTax('');
				setSellingPriceWithTax('');
				setSellingUnitPriceWithTax('');
			}
		}
		const re = /^[0-9.\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setSellingPriceWithoutTax(event.target.value);



		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const updateMedicineDetails = () => {
		setIsData(false)
		let flag = true;
		if (!stripCount) {
			toast.error('NO: of Units Required');
			document.getElementById('hdnMedicineQuantity').focus();
			flag = false;
			setIsData(true)
			window.scrollTo(0, 250);
		} else if (!batchNo) {
			toast.error('Batch NO: Required');
			document.getElementById('hdnMedicineBatchNo').focus();
			flag = false;
			setIsData(true)
			window.scrollTo(0, 250);
		} else if (!stockQuantity) {
			toast.error('Stock Quantity Required');
			document.getElementById('hdnMedicineStockQuantity').focus();
			flag = false;
			setIsData(true)
			window.scrollTo(0, 250);
		} else if (!expireDate) {
			toast.error('Expire Date Required');
			document.getElementById('scheduleDate').focus();
			flag = false;
			setIsData(true)
			window.scrollTo(0, 250);
		} else if (!reOrderQuantity) {
			toast.error('Reorder quantity Required');
			document.getElementById('hdnMedicineThreshold').focus();
			flag = false;
			setIsData(true)
			window.scrollTo(0, 250);
		} else if (!mrp) {
			toast.error('MRP Required');
			document.getElementById('mrp').focus();
			flag = false;
			setIsData(true)
			window.scrollTo(0, 250);
		} else if (!mrpUnitPrice) {
			toast.error('MRP UnitPrice Required');
			document.getElementById('mrpUnitPrice').focus();
			flag = false;
			setIsData(true)
			window.scrollTo(0, 280);
		} else if (!sellingPriceWithoutTax) {
			toast.error('Selling Price WithoutTax Required');
			document.getElementById('sellingPricewithoutTax').focus();
			flag = false;
			setIsData(true)
			window.scrollTo(0, 280);
		} else if (!sellingUnitPriceWithoutTax) {
			toast.error('Selling UnitPrice WithoutTax Required');
			document.getElementById('sellingUnitPricewithoutTax').focus();
			flag = false;
			setIsData(true)
		} else if (!sellingPriceWithTax) {
			toast.error('Selling Price WithTax Required');
			document.getElementById('sellingPricewithTax').focus();
			flag = false;
			setIsData(true)
		} else if (!sellingUnitPriceWithTax) {
			toast.error('Selling UnitPrice WithTax Required');
			document.getElementById('sellingUnitPricewithTax').focus();
			flag = false;
			setIsData(true)
		}
		else if (parseFloat(sellingPriceWithoutTax) > parseFloat(mrp)) {
			toast.error('MRP should not be less than Selling Price');
			flag = false;
			setIsData(true);
		}
		else if (parseFloat(sellingPriceWithTax) > parseFloat(mrp)) {
			toast.error('MRP should not be less than Selling Price');
			flag = false;
			setIsData(true);
		}
		if (flag === true) {
			var selectedtaxvaluesArr = [];
			var selectedtaxvaluesArr = taxListdata.reduce(
				(a, o) => (o.isChecked && a.push(o.hospitalTaxDetailsId), a),
				[]
			);
			setSelectedTaxNewArray([...selectedtaxvaluesArr])
			var today = new Date();

			var currentDate = today.getDate();
			if(currentDate <= 9)
			{
				currentDate = '0'+currentDate;
			}
			const postData = {
				pmHospitalMedicineId: "",
				medicine: brandName,
				role: window.sessionStorage.getItem('role'),
				isPurchase: false,
				hospitalInventoryCategoryId: categoryType,
				cmbSpeci: '',
				medicineId: categoryType == 1 ? medicineId : '',
				medicineGenericName: genericName,
				medicineIndicationAndDosage: dosage,
				userId: window.sessionStorage.getItem('userId'),
				medicineBrandId: '',
				medicineQuantity: stockQuantity,
				medicineDrugReactions: drugReactions,
				medicineMajorSideEffects: majorSideEffects,
				// manufactureId: mManufactureId,
				manufactureId: document.getElementById('hiddenManufacId').value,
				medicinePrecautions: precautions,
				medicineTypeId: '' + medType,
				medicineUnitId: '' + medUnit,
				medicineStrength: '' + strength,
				medicineHsnCode: hsnCode,
				medicineForDentalAndMedical: '',
				medicineSpecializationId: '',
				medicineInteractions: interactions,
				pmHospitalMedicineBatchNo: batchNo,
				hospitalInventoryMasterHsnCode: '',
				hospitalInventoryMasterId: '',
				hospitalInventoryMasterCode: '',
				hospitalInventoryMasterName: '',
				hospitalInventoryMasterNoOfUse: '',
				isGeneric: categoryType == 1 ? isShowGeneric : false,
				pmHospitalMedicineMrp: mrp,
				pmHospitalMedicineMrpUnitPrice: mrpUnitPrice,
				pmHospitalMedicineQty: stripCount,
				pmHospitalMedicineCode: '',
				pmHospitalMedicineNoOfUse: '',
				pmHospitalMedicineIsDisposableItem: false,
				pmHospitalMedicineHsnCode: hsnCode,
				pmHospitalMedicineExpiryDate: expireDate + "-" + currentDate,
				pmHospitalMedicineThreshold: reOrderQuantity,
				pmHospitalMedicinePrice: sellingPriceWithoutTax,
				pmHospitalMedicineUnitPrice: sellingUnitPriceWithoutTax,
				pmHospitalMedicineStockQty: stockQuantity,
				hospitalId: localStorage.getItem("hospitalId"),
				// selectedTax: selectedTaxNewArray,
				selectedTax: selectedtaxvaluesArr,

				supplierInvoiceMedicinesMrp: '',
				supplierInvoiceMedicinesMrpUnitPrice: '',
				supplierInvoiceMedicinesSellingPrice: '',
				supplierInvoiceMedicinesSellingUnitPrice: '',
				supplierInvoiceId: '',
				supplierInvoiceMedicinesTotalTax: '',
				supplierInvoiceMedicinesPrice: '',
				supplierInvoiceMedicinesUnitPrice: '',
				supplierInvoiceMedicinesPriceWithTax: '',
				supplierInvoiceMedicinesUnitPriceWithTax: '',
			};

			callingAPI('inventoryStockUpdate/addInventoryStock', postData).then((data) => {
				setIsData(true)
				if (data.data.success === '1') {
					toast.success('Medicine Stock Updated Successfully');
					setTimeout(() => {
						window.location.href = '/settings/medicineStockUpdate';
					}, 3000);
				} else {
					toast.error(data.data.errorMessage);
					setTimeout(() => {
					  window.location.href = '/settings/medicineStockUpdate';
					}, 3000);
				}
			});
		}
	};

	const setManufactureClick = (name, id) => {
		document.getElementById('manufactureInput').value = name;
		document.getElementById('hiddenManufacId').value = id;
		setManufactureAuto([]);
		document.getElementById('manufactureList').style.display = 'none';
	};

	const taxesChange = (item, index) => {
		if (categoryType == 1) {
			if (!stripCount) {
				toast.error('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				return;
			} else if (!mrp) {
				toast.error('MRP Required');
				document.getElementById('mrp').focus();
				return;
			}
		}
		const checkedTaxArrCopy = [...taxListdata];
		checkedTaxArrCopy[index].isChecked = !item.isChecked;
		setTaxListData(checkedTaxArrCopy);
		let onlyCheckedTaxArrCopy = [...checkedTaxArrCopy];
		onlyCheckedTaxArrCopy = checkedTaxArrCopy.filter((val, idx) => {
			if (val.isChecked == true) {
				return true;
			} else {
				return false;
			}
		});
		setOnlyCheckedTaxList(onlyCheckedTaxArrCopy)

		////////////////////////    tax Calculation     ////////////////////////
		let taxCalc = 0;
		let sellingPriceMultiple = 0;
		let totalTaxValue = 0;
		let totalTaxCalculation = 0;

		checkedTaxArrCopy.map((val, index) => {
			if (val.isChecked == true) {
				taxCalc = taxCalc + Number(val.hospitalTaxDetailsTaxValue);
				setTaxCalculation(taxCalc);
			}

			sellingPriceMultiple = sellingPriceWithTax * 100;
			totalTaxValue = taxCalc + 100;
			totalTaxCalculation = sellingPriceMultiple / totalTaxValue;
			var stripcount = stripCount;
			var unitSellingPice = totalTaxCalculation / stripcount;
			setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
			setSellingUnitPriceWithoutTax(Math.round(unitSellingPice.toFixed(2) * 100) / 100);
		});
	};
	const getTaxListingDataValues = () => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};

		callingAPI('inventoryStockUpdate/taxListing', postdata)
			.then((res) => {
				var result = [];
				if (res.data.success === '1') {
				result = res.data.result.hospitalTaxDetailsList.map((val, idx) => {
					val.isChecked = false;
					return val;
				});
			}
				if (res.data.success === '1') {
					setTaxListData(res.data.result.hospitalTaxDetailsList);
				} else {
					setTaxListData([]);
				}
			})
			.catch();
	};
	const getEditedInventoryValues = () => {
		if (typeof location.state !== 'undefined') {
			const { pmhospitalMedicineId } = location.state;
			setpmHospitalMedicineId(pmhospitalMedicineId);
			const postdata = {
				pmHospitalMedicineId: pmhospitalMedicineId,
			};

			callingAPI('inventoryStockUpdate/id', postdata).then((data) => {
				if (data.data.success === '1') {
					let medicinecategoryId = data.data.result.inventoryList[0].hospitalInventoryCategoryId;
					let formattedExpireDate = formatDate(
						data.data.result.inventoryList[0].pmHospitalMedicineExpiryDate
					);

					data.data.result.inventoryList[0].taxDetails.map((val, idx) => {
						val.isChecked = false;
						return val;
					});

					selectedTaxListdata = data.data.result.inventoryList[0].taxDetails.map(
						(val) => {
							var id = val.hospitalTaxDetailsId.toString();
							return id
						}
					);

					setSelectedTaxIdList(selectedTaxListdata)
					if (medicinecategoryId == 1) {
						var mStrStripCount = data.data.result.inventoryList[0].pmHospitalMedicineQty.toString();
						setcategoryType(data.data.result.inventoryList[0].hospitalInventoryCategoryId);
						/////////medicine ////////
						setBrandName(data.data.result.inventoryList[0].medicine);
						setMedicineId(data.data.result.inventoryList[0].medicineId);
						setGenericName(data.data.result.inventoryList[0].medicineGenericName);
						setHsnCode(data.data.result.inventoryList[0].hsnCode);
						// setmManufactureName(data.data.result.inventoryList[0].manufactureName);
						// setmManufactureId(data.data.result.inventoryList[0].manufactureId);
						setManufactureClick(
							data.data.result.inventoryList[0].manufactureName,
							data.data.result.inventoryList[0].manufactureId
						);
						setMedType(data.data.result.inventoryList[0].medicineTypeId);
						setMedTypeName(data.data.result.inventoryList[0].medicineTypeName);
						setStrength(data.data.result.inventoryList[0].medicineStrength);
						setMedUnit(data.data.result.inventoryList[0].medicineUnitId);
						setMedUnitName(data.data.result.inventoryList[0].medicineUnitName);
						setInteractions(data.data.result.inventoryList[0].medicineInteractions);
						setDrugReactions(data.data.result.inventoryList[0].medicineDrugReactions);
						setMajorSideEffects(data.data.result.inventoryList[0].medicineMajorSideEffects);
						setDosage(data.data.result.inventoryList[0].medicineIndicationAndDosage);
						setPrecautions(data.data.result.inventoryList[0].medicinePrecautions);
						setStripCount('');
						setBatchNo("");
						setStockQuantity("");
						setExpireDate("");
						setReorderQuantitu("");
						setIsGeneric(false)
					}
					setMrp("");
					setmrpUnitPrice("");
					setSellingPriceWithoutTax("");
					setSellingUnitPriceWithoutTax("");
					setSellingPriceWithTax("");
					setSellingUnitPriceWithTax("");
				}
			});
		}
	};
	useEffect(async () => {
		await getEditedInventoryValues();
		await getTaxListingDataValues();
	}, []);

	const formatDate = (dateStr) => {
		const [day, month, year] = dateStr.split('-');
		let newDate = `${year}-${month}-${day}`;
		return newDate;
	};
	const getManufactureAutoComplete = () => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			keyWord: document.getElementById('manufactureInput').value,
		};
		if (document.getElementById('manufactureInput').value.length > 1) {

			callingAPI('inventoryStockUpdate/getManuFactureAutocomplete', postdata)
				.then((res) => {
					if (res.data.success === '1' && res.data.result.manufactureDetails.length > 0) {
						document.getElementById('manufactureList').style.display = 'block';
						setManufactureAuto(res.data.result.manufactureDetails);
						setManufactError('');
					} else {
						document.getElementById('manufactureList').style.display = 'none';
						setManufactureAuto([]);
						setManufactError('Please Add Manufacturer');
					}
				})
				.catch();
		}
		else if (document.getElementById('manufactureInput').value.length === 0) {
			document.getElementById('manufactureList').style.display = 'none';
			setManufactureAuto([]);
			setManufactError('Please Add Manufacturer');
		}
	};
	const addNewManufacture = () => {
		var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var phNum = /^\d{10}$/;
		let flagManufacture = true;
		if (!document.getElementById('manufactureName').value) {
			toast.error('Manufacturer Name Required');
			flagManufacture = false;
			return;
		}
		if (!document.getElementById('manufactureNumber').value) {
			toast.error('Contact Number Required');
			flagManufacture = false;
			return;
		}
		if (document.getElementById('manufactureNumber').value !== '') {
			if (!document.getElementById('manufactureNumber').value.match(phNum)) {
				toast.error('Valid Contact Number Required');
				document.getElementById('manufactureNumber').focus();
				return;
			}
		}
		if (document.getElementById('manufactureEmail').value !== '') {
			if (!document.getElementById('manufactureEmail').value.match(email)) {
				toast.error('Valid Email Required');
				document.getElementById('manufactureEmail').focus();
				return;
			}
		}
		if (flagManufacture === true) {
			const postdata = {
				manufactureName: document.getElementById('manufactureName').value,
				manufactureAddress: document.getElementById('manufactureAddress').value,
				manufactureEmail: document.getElementById('manufactureEmail').value,
				manufactureContactNo: document.getElementById('manufactureNumber').value,
				manufactureContactPerson: document.getElementById('manufactureContactPerson').value,
				hospitalId: localStorage.getItem("hospitalId"),
				userId: window.sessionStorage.getItem('userId'),
			};

			setIsData(false);
			callingAPI('manufacturer/manufacturerAddUpdate', postdata).then((res) => {
				if (res.data.success === '1') {
					setIsData(true);
					toast.success('Manufacturer Saved Successfully');
					ManufactureClose()
				} else if (res.data.success === '0') {
					toast.success(res.data.successMessage);

				}
			});
		}
	};
	const ManufacturePopUpClick = () => {
		setPopVisible(true)
	}
	const ManufactureClose = () => {
		setPopVisible(false)
	}
	return (
		<div class="pl_editInventory practiceManagementForm practiceManagementFormNew edtinv ">
			<ToastContainer position="bottom-right" style={{zIndex:"999999999"}}/>
			{isData === false ?
				<Loader />
				: ""}
			<h3 class="practiceHead" style={{ color: 'blue', fontSize: '16px' }}>
				Medicine Stock Update
			</h3>
			<div class="practiceManagementFormBlcok inventoryStockDiv">
				<h3 class="practiceHead">Medicine Details</h3>
				<form>
					<ul className="row">
						<div className="row">
							<li class="col-md-4 col-lg-4">
								<label id="medBid">Medicine/Brand Name*</label>
								<input
									autocomplete="off"
									type="text"
									id="hdnMedicineName"
									name="hdnMedicineName"
									autofocus="autofocus"
									value={brandName}
									readOnly={true} tabindex="-1"
								/>

							</li>

							<li class="col-md-4 col-lg-4">
								<label>Generic Name</label>
								<input
									autocomplete="off"
									type="text"
									id="hdnMedicineGenericName"
									name="hdnMedicineGenericName"
									autofocus="autofocus"
									value={genericName}
									onChange={onChangeGenericName} tabindex="-1"
								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Hsn Code</label>
								<input
									type="text"
									autocomplete="off"
									maxlength="100"
									id="hdnMedicineHsnCode"
									name="hdnMedicineHsnCode"
									autofocus="autofocus"
									value={hsnCode}
									onChange={onChangeHsnCode} tabindex="-1"
								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Manufacture</label>
								<input
									autocomplete="off"
									type="text"
									id="manufactureInput"
									name="cityName"
									placeholder="Item Name"
									className="input-design"
									autoComplete="off"
									onKeyUp={() => getManufactureAutoComplete()}
									style={{ marginBottom: '5px' }} tabindex="-1"
								//onBlur={onBlurManufacture}
								/>
								<ul
									className="autoSearch autoSearch-p0 appointmentNumberSearch itemNameSearch"
									id="manufactureList"
									style={{ display: 'none' }}
								>
									{manufactureAuto.map((item, i) => (
										<li
											onClick={() =>
												setManufactureClick(item.manufactureName, item.manufactureId)}
										>
											{item.manufactureName + " - " + item.phoneNumber}
										</li>
									))}
								</ul>
								<input type="hidden" id="hiddenManufacId" />
								<p
									id=""
									style={{
										color: 'red',
										display: 'flex',
										justifyContent: 'left',
										fontSize: '12px',
										marginBottom: '4px',
									}}
								>
									{manufactError}
								</p>
								<span class="addManufLink addLinkTxt">
									<a onClick={() => ManufacturePopUpClick()} style={{ color: '#0093cc', textDecoration: 'none' }}>
										Add Manufacturer +
									</a>
								</span>
							</li>

							<li class="col-md-4 col-lg-4">
								<label>Type*</label>

								<input
									autocomplete="off"
									class="fulWidthSelect"
									name="cmbMedicineType"
									id="cmbMedicineType"
									value={medTypeName}
									readOnly={true} tabindex="-1"

								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Strength</label>

								<input
									autocomplete="off"
									type="text"
									id="hdnMedicineStrength"
									name="hdnMedicineStrength"
									autofocus="autofocus"
									value={strength}
									readOnly={true}
									placeholder="Enter Strength" tabindex="-1"
								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Unit</label>

								<input
									autocomplete="off"
									class="fulWidthSelect"
									name="cmbMedicineType"
									id="cmbMedicineType"
									value={medUnitName}
									readOnly={true} tabindex="-1"

								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Interactions</label>

								<input
									autocomplete="off"
									type="text"
									id="hdnMedicineInteractions"
									name="hdnMedicineInteractions"
									autofocus="autofocus"
									value={interactions}
									onChange={onChangeInteraction}
									placeholder="Enter Interactions" tabindex="-1"
								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Drug reactions</label>

								<input
									autocomplete="off"
									type="text"
									id="hdnMedicineDrugReactions"
									name="hdnMedicineDrugReactions"
									autofocus="autofocus"
									value={drugReactions}
									onChange={onChangeDrugReactions}
									placeholder="Enter Drug reactions" tabindex="-1"
								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Major side effects</label>

								<input
									autocomplete="off"
									type="text"
									id="hdnMedicineSideEffect"
									name="hdnMedicineSideEffect"
									autofocus="autofocus"
									value={majorSideEffects}
									onChange={onChangeMajorSideEffects}
									placeholder="Enter Side Effect" tabindex="-1"
								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Indication and dosage</label>

								<input
									autocomplete="off"
									type="text"
									id="hdnMedicineIndication"
									name="hdnMedicineIndication"
									autofocus="autofocus"
									value={dosage}
									onChange={onChangeDosage}
									placeholder="Enter Indication" tabindex="-1"
								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Precautions</label>

								<input
									autocomplete="off"
									type="text"
									id="hdnMedicinePrecautions"
									name="hdnMedicinePrecautions"
									autofocus="autofocus"
									value={precautions}
									onChange={onChangePrecautions}
									placeholder="Enter Precautions" tabindex="-1"
								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Number Of Units per Strip/Box Required *</label>

								<input
									autocomplete="off"
									type="text"
									id="hdnMedicineQuantity"
									name="hdnMedicineQuantity"
									autofocus="autofocus"
									value={stripCount}
									onChange={onChangeStripCount}
									placeholder="Enter Quantity" tabindex="-1"
								/>
							</li>

							<li class="col-md-4 col-lg-4">
								<label>Batch No*</label>

								<input
									autocomplete="off"
									type="text"
									id="hdnMedicineBatchNo"
									name="hdnMedicineBatchNo"
									autofocus="autofocus"
									value={batchNo}
									onChange={onChangeBatchNo}
									placeholder="Enter Batch No" tabindex="-1"
								/>
							</li>

							<li class="col-md-4 col-lg-4">
								<label>Stock Quantity*</label>

								<input
									autocomplete="off"
									type="text"
									maxlength="100"
									id="hdnMedicineStockQuantity"
									name="hdnMedicineStockQuantity"
									autofocus="autofocus"
									value={stockQuantity}
									onChange={onChangeStockQuantity}
									placeholder="Enter Stock Quantity" tabindex="-1"
								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label id="expDatY">Expiry Date*</label>

								<input
									autocomplete="off"
									min={requiredDate}
									name="scheduleDate"
									id="scheduleDate"
									// value={expireDate}
									value={expireDate}
									onChange={onChangeExpireDate}
									onKeyDown={(e) => e.preventDefault()}
									type="month"
									placeholder='Expiry Date' tabindex="-1"
									// required
									style={{ display: "flex", justifyContent: "space-between" }}
								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Reorder Quantity*</label>
								<input
									autocomplete="off"
									type="text"
									id="hdnMedicineThreshold"
									name="hdnMedicineThreshold"
									autofocus="autofocus"
									value={reOrderQuantity}
									onChange={onChangeReOrderQuantity}
									placeholder="Enter Threshold" tabindex="-1"
								/>
							</li>
							<li class="col-md-4 col-lg-4">
								<label>Is generic</label>
								<div class="procedureCatType">
									<input
										autocomplete="off"
										type="checkbox"
										id="chkgeneric"
										name="chkgeneric"
										checked={isShowGeneric}
										onChange={onChangeisGeneric}
										style={{ width: "15px" }} tabindex="-1"
									/>
									<label for="Is generic"> </label>
								</div>
							</li>
						</div>
					</ul>

					<div class="borderSeparate">
						<span />
					</div>
					<h3 class="practiceHead" style={{ textAlign: "center" }}>Pricing Details</h3>
					<h3 class="practiceHead">Selling Details</h3>
					<ul className="row">
						<li class="col-md-4 col-lg-4">
							<label>MRP*</label>
							<input
								autocomplete="off"
								type="text"
								id="mrp"
								name="mrp"
								autofocus="autofocus"
								value={mrp}
								onChange={onChangemrp}
								onKeyUp={stripCountBy}
								placeholder="Enter MRP" tabindex="-1"
							/>
						</li>
						<li class="col-md-4 col-lg-4">
							<label>MRP Unit Price*</label>

							<input
								autocomplete="off"
								type="text"
								readonly=""
								id="mrpUnitPrice"
								name="mrpUnitPrice"
								autofocus="autofocus"
								value={mrpUnitPrice}
								//onChange={onChangeParamPrice}
								placeholder="MRP Unit Price" tabindex="-1"
							/>
						</li>
						<div class="borderSeparate">
							<span />
						</div>
						<div class="subTitileNAme">
							<h3 class="practiceHead">Taxes</h3>
						</div>

						<div class="borderSeparate">
							<span />
						</div>
						{onlycheckedTaxList.length > 0 ? (
							<div className="taxType" >
								<div className="taxDataOuter">
									{onlycheckedTaxList.map((option, idx) => {
										return (
											<div className="taxDataSelected">
												{option.isChecked ? (
													option.taxNames +
													': ' +
													(sellingPriceWithoutTax *
														(option.hospitalTaxDetailsTaxValue / 100)).toFixed(2)
												) : (
													''
												)}
											</div>
										);
									})}
								</div>
							</div>
						) :
							(
								''
							)
						}
						<div class="procedureCatList" id="divTaxDetails">
							<div
								class="applicabletaxDiv pl_applicableTax_scroll row"
								style={{ flexWrap: 'nowrap', margin: '0px' }}
							>
								<div class="col-lg-3 procedureCatName">Applicable Taxes</div>

								<div class="col-lg-9 procedureCatType procedureCatListWrap">
									<div className="row">
										{/* <div className="col-lg-3"> */}
										{taxListdata.map((option, idx) => {
											return (
												<div class="col-lg-4 procedureCatType1  procedureCatListWrap ">
													<input
														autocomplete="off"
														type="checkbox"
														id={option.hospitalTaxDetailsId}
														//id={option.hospitalTaxDetailsTaxValue}
														value={option.hospitalTaxDetailsTaxName}
														checked={option.isChecked}
														onChange={() => taxesChange(option, idx)} tabindex="-1"
													/>
													<label>{option.taxNames}</label>
												</div>
											);
										})}
										{/* </div> */}
									</div>
								</div>
							</div>
						</div>
						<div class="borderSeparate">
							<span />
						</div>
						{/* <div class="subTitileNAme">
							<h3 class="practiceHead">Selling Details</h3>
						</div> */}
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Medicine Price (without tax)*</label>

							<input
								autocomplete="off"
								type="text"
								id="sellingPricewithoutTax"
								name="sellingPricewithoutTax"
								autofocus="autofocus"
								value={sellingPriceWithoutTax}
								onChange={onChangeSellingPriceArea}
								onKeyUp={stripCountBySellingArea}
								placeholder="Enter Medicine Price" tabindex="-1"
							/>
						</li>
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Medicine Unit Price (without tax)*</label>

							<input
								autocomplete="off"
								type="text"
								readonly=""
								id="sellingUnitPricewithoutTax"
								name="sellingUnitPricewithoutTax"
								autofocus="autofocus"
								value={sellingUnitPriceWithoutTax}
								placeholder="Medicine Unit Price" tabindex="-1"
							/>
						</li>
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Medicine Price(with tax)*</label>
							<input
								autocomplete="off"
								type="text"
								id="sellingPricewithTax"
								name="sellingPricewithTax"
								autofocus="autofocus"
								value={sellingPriceWithTax}
								onChange={onChangeSellingPriceAreawithTax}
								onKeyUp={stripCountBySellingAreawithTax}
								placeholder="Enter Medicine Price" tabindex="-1"
							/>
						</li>
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Medicine Unit Price(with tax)*</label>
							<input
								autocomplete="off"
								type="text"
								readonly=""
								id="sellingUnitPricewithTax"
								name="sellingUnitPricewithTax"
								autofocus="autofocus"
								value={sellingUnitPriceWithTax}
								placeholder="Medicine Unit Price" tabindex="-1"
							/>
						</li>
					</ul>
					<div class="clearfix" />
					<br />

					<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
						<span
							className="dashButtonBorder" style={{ border: '1px solid #b22222' }}
							onClick={updateMedicineDetails} tabindex="-1"
						>
							Update{' '}
						</span>
					</div>
					<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
						<span className="dashButtonBorder " style={{ border: '1px solid #b22222' }}>
							<Link to="/settings/medicineStockUpdate" style={{ textDecoration: 'none', color: '#393939' }} tabindex="-1">
								Cancel
							</Link>
						</span>
					</div>
				</form>
			</div>

			{/* ====================== Category = Disposable ends here ====================== */}

			{popVisible === true &&
				<div id="plpopup12" class="ploverlay" style={{ visibility: 'visible', opacity: 1 }}>
					<div class="plpopup">
						<h2>
							Add Manufacturer{' '}
							<a class="close" onClick={() => ManufactureClose()}>
								&times;
							</a>
						</h2>
						<div class="plcontent">
							<div class="addDataContent">
								<div class="addDataDiv">
									<label>
										Manufacturer Name<span class="mandatory">*</span>
									</label>
									<input autocomplete="off" type="text" id="manufactureName"
										placeholder="Manufacture Name" />
								</div>
								<div class="addDataDiv">
									<label>Address</label>
									<textarea
										autocomplete="off"
										id="manufactureAddress"
										placeholder="Enter Address"
										style={{ height: '85px' }}
									/>
								</div>
								<div class="addDataDiv">
									<label>Email</label>
									<input autocomplete="off" type="text" id="manufactureEmail" placeholder="Enter Email" />
								</div>
								<div class="addDataDiv">
									<label>Contact Number<span class="mandatory">*</span></label>
									<input
										maxLength="10"
										autocomplete="off"
										class=""
										style={{ boxShadow: 'none' }}
										type="text"
										id="manufactureNumber"
										placeholder="Mobile Number"
										onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) event.preventDefault(); }}

									/>
								</div>
								<div class="addDataDiv">
									<label>Contact Person</label>
									<input
										autocomplete="off"
										class=""
										style={{ boxShadow: 'none' }}
										type="text"
										id="manufactureContactPerson"
										placeholder="Contact Person"
									/>
								</div>

								<div class="summaryBtnDiv">
									<input
										type="submit"
										value="Save"
										onClick={addNewManufacture}
										class="practiceGreenButton"
									/>

									<a class="close" onClick={() => ManufactureClose()}>
										<input type="submit" value="Cancel" class=" practiceGreyButton" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
}
export default NewBatchMedicine;
