import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'qrcode.react';
import healthcard_user from '../images/media/healthcard_user.png';
import Home from '../images/icons/homeicon-healthcard.png';
import Calls from '../images/icons/calls.png';
import Phoneicon from '../images/icons/phoneicon.png';
import Web from '../images/icons/web.png';
import Webmail from '../images/icons/webmail.png';
import Youtube from '../images/icons/youtube.png';
import Logo from '../images/avatarHospitalDefault.png';
import { callingAPI } from '../config';
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Healthcard(props) {
	const cardTitleEvent = (event) => {
		setCardTitle(event.target.value);
	};
	const mrNoEvent = (event) => {
		setMrnumber(event.target.value);
	};
	const showMrRadiochange = (event) => {
		setShowPr(false)
		setShowMr(true);
	};
	const ShowPrchange = (event) => {
		setShowMr(false)
		setShowPr(true);
	};
	var instructions = new Array(4);

	const [healthCardData, setHealthCardData] = useState({});
	const [cardTitle, setCardTitle] = useState('');
	const [Mrnumber, setMrnumber] = useState('');
	const [PatientName, setPatientName] = useState(false);
	const [PhoneNumber, setPhoneNumber] = useState(false);
	const [hospitalName, sethospitalName] = useState('');
	const [isBarcode, setBarcode] = useState(false);
	const [isBackside, setBackside] = useState(false);
	const [Email, setEmail] = useState('');
	const [Address, setAddress] = useState('');


	var getS3folderHospitalLogo =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE
		+ process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;

	const [isNoBackground, setBackground] = useState(false);
	const [showAddress, setShowAddress] = useState(false);
	const [defaultStyleCard, setdefaultStyleCard] = useState(false);
	const [showDob, setShowDob] = useState(false);
	const [showMoile, setShowMobile] = useState(false);
	const [showEmail, setShowEmail] = useState(false);
	const [showMr, setShowMr] = useState(false);
	const [showMrRadio, setshowMrRadio] = useState(false);
	const [showPr, setShowPr] = useState(false);
	const [showGender, setGender] = useState(false);
	const [showParentDoctor, setshowParentDoctor] = useState(false);
	const [cliniclogo, setCliniclogo] = useState(false);
	const [patientphoto, setPatientphoto] = useState(false);
	const [instructionOne, setInstructionOne] = useState('');
	const [instructionOnevalue, setInstructionOnevalue] = useState('');
	const [instructionTwovalue, setInstructionTwovalue] = useState('');
	const [instructionThreevalue, setInstructionThreevalue] = useState('');
	const [instructionFourvalue, setInstructionFourvalue] = useState('');
	const [instructionTwo, setInstructionTwo] = useState('');
	const [instructionThree, setInstructionThree] = useState('');
	const [hospitalLogo, setHospitalLogo] = useState('');
	const [instructionFour, setInstructionFour] = useState('');
	const [showInstructionOne, setShowInstructionOne] = useState(false);
	const [showInstructionTwo, setShowInstructionTwo] = useState(false);
	const [showInstructionThree, setShowInstructionThree] = useState(false);
	const [showInstructionFour, setShowInstructionFour] = useState(false);
	const [isPrNoEnabledHospital, setisPrNoEnabledHospital] = useState(false);
	const [hospitalImagename, sethospitalImagename] = useState('');
	
	var QRCodeValue = PatientName + '\n' + "MR-9999" + '\n' + PhoneNumber;

	useEffect(() => {

		var postData = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('healthCard', postData).then((res) => {
			setisPrNoEnabledHospital(res.data.isPrNoEnabledHospital)

			if (res.data.success == "1") {
				
				setMrnumber(res.data.result.healthCardList[0].idText);
				setCardTitle(res.data.result.healthCardList[0].titleText);
				setBarcode(res.data.result.healthCardList[0].barCode);
				setBackside(res.data.result.healthCardList[0].backSide);
				setBackground(res.data.result.healthCardList[0].hasBackground);
				setShowAddress(res.data.result.healthCardList[0].isAddress);
				setShowDob(res.data.result.healthCardList[0].isDob);
				setdefaultStyleCard(res.data.result.healthCardList[0].defaultStyle);
				setShowMobile(res.data.result.healthCardList[0].isMobileNumber);
				setShowEmail(res.data.result.healthCardList[0].isEmailId);
				setShowMr(res.data.result.healthCardList[0].isMro);
				setshowMrRadio(res.data.result.healthCardList[0].isMro);
				setShowPr(res.data.result.healthCardList[0].isPrNo);
				setGender(res.data.result.healthCardList[0].isGender);
				setshowParentDoctor(res.data.result.healthCardList[0].showParentDoctor);
				setCliniclogo(res.data.result.healthCardList[0].clinicLogo);
				setPatientphoto(res.data.result.healthCardList[0].patientPhoto);
				sethospitalName(res.data.result.healthCardList[0].hospitalName);
				setEmail(res.data.result.healthCardList[0].email);
				setAddress(res.data.result.healthCardList[0].address);
				setPatientName("Patient's Name");
				setPhoneNumber(res.data.result.healthCardList[0].phoneNo);
				setHealthCardData(res.data.result.healthCardList[0]);
				var allIntructions = res.data.result.healthCardList[0].instructionText;
				instructions = allIntructions.split('@@');
				setInstructionOnevalue(instructions[0])
				setInstructionTwovalue(instructions[1]);
				setInstructionThreevalue(instructions[2]);
				setInstructionFourvalue(instructions[3]);
				setInstructionOne(instructions[0]);
				setInstructionTwo(instructions[1]);
				setInstructionThree(instructions[2]);
				setInstructionFour(instructions[3]);
			}

		});
		callingAPI('commonUserFunctions/headerFooterLogoPrints', {
            hospitalId: props.hospitalDataId ? props.hospitalDataId : localStorage.getItem("hospitalId"),
            doctorId: ""
        }).then((res) => {

            sethospitalImagename(res.data.result.hospitalDetail[0].hospitalLogoImage);
            //setMarginTop()
        });
	}, []);
	const onsavebutton = () => {
		var postData = {
			healthCardId: healthCardData.healthCardId,
			backSide: isBackside,
			barCode: isBarcode,
			clinicLogo: cliniclogo,
			hospitalId: healthCardData.hospitalId,
			idText: Mrnumber,
			instructionText: instructionOnevalue + '@@' + instructionTwovalue + '@@' + instructionThreevalue + '@@' + instructionFourvalue,
			patientPhoto: patientphoto,
			qrCode: 1,
			titleText: cardTitle,
			modifiedBy: window.sessionStorage.getItem('userId'),
			modifiedOn: '20/10/2021',
			alignLeft: 1,
			hasBackground: isNoBackground,
			isDefault: 0,
			serviceProviderId: healthCardData.serviceProviderId,
			isAddress: showAddress,
			isCardId: healthCardData.isCardId,
			isDob: showDob,
			isEmailId: showEmail,
			isGender: showGender,
			isMobileNumber: showMoile,
			isMrno: showMr,
			isReferId: healthCardData.isReferId,
			showParentDoctor: showParentDoctor,
			defaultStyle: defaultStyleCard,
			isPrNo: showPr,
		};

		callingAPI('healthCard/healthCardUpdate', postData).then((res) => {
			if (res.data.success) {
				toast.success('Updated successfully');
				
				setTimeout(() => {
					window.location.reload();
				  }, 3000);
			} else {
				toast.error('failed');
			}
		});
	};

	return (
		<div className="dashboardCover settingsHealthCard">
			<ToastContainer position="bottom-right" />
			<div className="pl_youtube">
				{/* <img src={Youtube} /> */}
			</div>

			<div className="dashboardTitle">Health Card</div>
			<div className="dashboardContent">
				<div className="cardsettingLeft">
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={cliniclogo}
								onClick={() => setCliniclogo(!cliniclogo)}
								id="cliniclogo"
							/>
							<label for="cliniclogo" />
						</span>
						<b>Hospital / Clinic Logo</b>
						<div className="cardImageUpload">
							<span class="uploadButton uploadButtonImage">
								<input type="file" id="file1" />
								<label for="file1">Upload</label>
							</span>
						</div>
					</div>
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={patientphoto}
								onClick={() => setPatientphoto(!patientphoto)}
								id="patientphoto"
							/>
							<label for="patientphoto" />
						</span>

						<b>Patient Photo</b>
						<div className="cardImageUpload">
							<span class="uploadButton uploadButtonImage">
								<input type="file" id="file1" />
								<label for="file1">Upload</label>
							</span>
						</div>
					</div>
					<div className="cardsettingList">
						<label>Card Title</label>
						<input type="text" value={cardTitle} onChange={cardTitleEvent} className="inputField" />
					</div>
					<div className="cardsettingList">
						<label>MR No.</label>
						<input type="text" value={Mrnumber} onChange={mrNoEvent} className="inputField" />
					</div>
					<div className="mb-3 text-danger">Select any five of the below options for a proper view.</div>
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={isBarcode}
								onClick={() => setBarcode(!isBarcode)}
								id="barcode"
							/>
							<label for="barcode" />
						</span>
						QR Code
					</div>
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={isBackside}
								onClick={() => setBackside(!isBackside)}
								id="backside"
							/>
							<label for="backside" />
						</span>
						Back Side
					</div>
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={isNoBackground}
								onClick={() => {
									setBackground(!isNoBackground);
								}}
								id="nobackground"
							/>
							<label for="nobackground" />
						</span>
						No Background
					</div>
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={showAddress}
								onClick={() => setShowAddress(!showAddress)}
								id="showaddress"
							/>
							<label for="showaddress" />
						</span>
						Show Address
					</div>
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={showDob}
								onClick={() => setShowDob(!showDob)}
								id="showdob"
							/>
							<label for="showdob" />
						</span>
						Show Dob
					</div>
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={showMoile}
								onClick={() => setShowMobile(!showMoile)}
								id="showmobile"
							/>
							<label for="showmobile" />
						</span>
						Show Mobile Number
					</div>
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={showEmail}
								onClick={() => setShowEmail(!showEmail)}
								id="showemail"
							/>
							<label for="showemail" />
						</span>
						Show Email Id
					</div>
					{isPrNoEnabledHospital == false &&
						<div className="cardsettingList">
							<span class="checkSection">
								<input type="checkbox" checked={showMr} onClick={() => setShowMr(!showMr)} id="showmrno" />
								<label for="showmrno" />
							</span>
							Show Mr no
						</div>}
					{/*  */}
					{isPrNoEnabledHospital == true &&
						<div className="cardsettingList">
							<div className="appointmentFormList1 prnumappointmentFormList1">
								<span className="radioSection1" style={{ display: 'flex' }}>
									<label for="radio1" >
										<input
											name="patientLastName"
											type="radio"
											value="MrNum"
											id="radio1"
											checked={showMr}
											onClick={showMrRadiochange}

										/>
										&nbsp;Show MR no</label>
								</span>
								<span className="radioSection1" style={{ display: 'flex' }}>
									<label for="radio2" >
										<input
											name="patientLastName"
											type="radio"
											value="PrNum"
											id="radio2"
											checked={showPr}
											onClick={ShowPrchange}
										/>
										&nbsp;Show PR no</label>
								</span>
							</div>
						</div>}
					{/*  */}
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={showGender}
								onClick={() => setGender(!showGender)}
								id="showgender"
							/>
							<label for="showgender" />
						</span>
						Show Gender
					</div>
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={showParentDoctor}
								onClick={() => setshowParentDoctor(!showParentDoctor)}
								id="showparentdoctor"
							/>
							<label for="showparentdoctor" />
						</span>
						Show Parent Doctor
					</div>
					<div className="cardsettingList">
						<span class="checkSection">
							<input
								type="checkbox"
								checked={defaultStyleCard}
								onChange={() => setdefaultStyleCard(!defaultStyleCard)}
								id="defaultstyleCard"
							/>
							<label for="defaultstyleCard" />
						</span>
						Default Style
					</div>
					<div className="cardsettingList">
						<label>Instruction.</label>
					</div>
					<div className="cardsettingList">
						<ul>
							<li>
								<label id="cardsettingLAbel"> {instructionOnevalue}</label>
								{showInstructionOne == true && (
									<input
										type="text"
										maxlength="120"
										defaultValue={instructionOne}
										className="inputField"
										id="inputField1"
										onChange={(e) => setInstructionOnevalue(e.target.value)}
									/>
								)}

								<FontAwesomeIcon
									icon={faPen}
									onClick={() => setShowInstructionOne(!showInstructionOne)}
								/>
							</li>
							<li>
								<label>{instructionTwovalue}</label>
								{showInstructionTwo == true && (
									<input
										type="text"
										maxlength="120"
										defaultValue={instructionTwo}
										className="inputField"
										id="inputField2"
										onChange={(e) => setInstructionTwovalue(e.target.value)}
									/>
								)}
								<FontAwesomeIcon
									icon={faPen}
									onClick={() => setShowInstructionTwo(!showInstructionTwo)}
								/>
							</li>
							<li>
								<label>{instructionThreevalue}</label>
								{showInstructionThree == true && (
									<input
										type="text"
										maxlength="120"
										defaultValue={instructionThree}
										className="inputField"
										id="inputField3"
										onChange={(e) => setInstructionThreevalue(e.target.value)}
									/>
								)}

								<FontAwesomeIcon
									icon={faPen}
									onClick={() => setShowInstructionThree(!showInstructionThree)}
								/>
							</li>
							<li>
								<label>{instructionFourvalue}</label>
								{showInstructionFour == true && (
									<input
										type="text"
										maxlength="58120"
										defaultValue={instructionFour}
										className="inputField"
										id="inputField4"
										onChange={(e) => setInstructionFourvalue(e.target.value)}
									/>
								)}
								<FontAwesomeIcon
									icon={faPen}
									onClick={() => setShowInstructionFour(!showInstructionFour)}
								/>
							</li>
						</ul>
					</div>
					<div className="dashButtonDiv">
						<span className="borderButton" onClick={onsavebutton}>
							Save
						</span>
					</div>
				</div>
				<div className="cardsettingRight">
					<div class="registrationCardWrap settingsBg">
						<div class="registrationCard">
							<div>
								{defaultStyleCard &&
									isNoBackground ? <div class="registrationCardTop" style={{ opacity: '1', minHeight: "19px", }}>
									<span>{cardTitle}</span>
								</div> : (
									<div class="registrationCardTop" style={{ opacity: '1', minHeight: "19px", display: 'none', }}>
										<span>{cardTitle}</span>
									</div>
								)}
							</div>
							<div>
								{!defaultStyleCard &&
									isNoBackground && (
										<div class="registrationCardTop" style={{ opacity: '1', minHeight: '53px' }}>
											<span>{cardTitle}</span>
										</div>
									)}
							</div>
							<div class="registrationCardContent ">
								<div class="cardDetailLeft" style={{ float: 'left' }}>
									
								{showMr ?
										<div class="cardDetailList cardDetailListBold">
											<label>{Mrnumber}</label>
											<span class="cardDetailListTxt">
												<span>:</span>
												<span class="cardDetailListTxtCont">MR-9999</span>
											</span>
										</div>
									: "" }
									{showPr ? 
										<div class="cardDetailList cardDetailListBold">
											<label>PR No</label>
											<span class="cardDetailListTxt">
												<span>:</span>
												<span class="cardDetailListTxtCont">PRNP/2022/</span>
											</span>
										</div>
									: "" }
									<div class="cardDetailList cardDetailListBold">
										<label>Name</label>
										<span class="cardDetailListTxt">
											<span>:</span>
											<span class="cardDetailListTxtCont">Patient's Name</span>
										</span>
									</div>
									{showDob ? 
										<div class="cardDetailList cardDetailListBold">
											<label>DOB</label>
											<span class="cardDetailListTxt">
												<span>:</span>
												<span class="cardDetailListTxtCont">DD/MM/YYYY</span>
											</span>
										</div>
									: "" }
									{showGender ? 
										<div class="cardDetailList cardDetailListBold">
											<label>Gender</label>
											<span class="cardDetailListTxt">
												<span>:</span>
												<span class="cardDetailListTxtCont">Male/Female</span>
											</span>
										</div>
									: "" }
									{showParentDoctor ? 
										<div class="cardDetailList cardDetailListBold">
											<label>Doctor</label>
											<span class="cardDetailListTxt">
												<span>:</span>
												<span class="cardDetailListTxtCont">xxxxxxxxx</span>
											</span>
										</div>
									: "" }
									{showMoile ? 
										<div class="cardDetailList cardDetailListBold">
											<label>Mobile</label>
											<span class="cardDetailListTxt">
												<span>:</span>
												<span class="cardDetailListTxtCont">{PhoneNumber}</span>
											</span>
										</div>
									: "" }
									{showEmail ?
										<div class="cardDetailList cardDetailListBold">
											<label>Email</label>
											<span class="cardDetailListTxt">
												<span>:</span>
												<span class="cardDetailListTxtCont">{Email}</span>
											</span>
										</div>
									: "" }
									{/* {!healthCardData.isReferId && (
										<div class="cardDetailList cardDetailListBold">
											<label>Ref No</label>
											<span class="cardDetailListTxt">
												<span>:</span>
												<span class="cardDetailListTxtCont">1111</span>
											</span>
										</div>
									)} */}
									{showAddress ? 
										<div class="cardDetailList cardDetailListBold" >
										<span class="cardDetailListTxtCont">
											{hospitalName}&nbsp;{Address}
										</span>
									</div> : ""
									}
								</div>
								<div class="cardDetailRight">
									
									<div
										class="cardDetailLogo cardDetailLogo-preview"
										style={{ float: 'right', width: '100%', margin: '2px 0px' }}
									>
										{cliniclogo == true && (
											hospitalImagename != (null || "") ?
										
											<img
												src={
													
												getS3folderHospitalLogo + '/' + localStorage.getItem("hospitalId") + ".png "}
												style={{
													float: 'right',
													display: 'block',
													width: '55px',
													height: '55px', objectFit: 'contain', objectPosition: 'center'
												}}
											/>:<img
											src={
												
											Logo}
											style={{
												float: 'right',
												display: 'block',
												width: '55px',
												height: '55px', objectFit: 'contain', objectPosition: 'center'
											}}
										/>
										)}
									</div>
									<div class="cardDetailImg cardDetailImg-preview" style={{ float: 'right'}}>
										{patientphoto == true && (
											<img src={healthcard_user} style={{ display: 'block', width: '100%' }} />
										)}
									</div>
									<div
										class="cardDetailLogo QRcodeLogo"
										style={{ float: 'right', width: '100%', margin: '5px 0px' }}
									>
										{isBarcode == true && (
											<div>
												<QRCode
													value={QRCodeValue}
													style={{
														// marginRight: '-4px',
														height: '40px',
														width: '40px',
														float: 'right',
														display: 'block',
													}}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
							<br />
							{isNoBackground ? (
								<div class="registrationCardBtm" >
									<div class="registrationCardAddr">
										<span class="rgstrnAddrsIcon">
											{/* <i class="fa fa-home" aria-hidden="true"></i> */}
											<img src={Home} />
										</span>
										<span class="rgstrnAddrs">
											{hospitalName}&nbsp;{Address}
										</span>
									</div>
									{defaultStyleCard ?
										<div class="registrationCardContact">
											<div class="cardContactList">
												<img src={Calls} />
												+91 04712720740
											</div>
											<div class="cardContactList">
												<img src={Webmail} />
												drgins@gmail.com
											</div>
											<div class="cardContactList">
												<img src={Phoneicon} />
												+91 9037020740
											</div>
											<div class="cardContactList">
												<img src={Web} />
												wwww.dentaldesign.in
											</div>
										</div>
									: "" }
								</div>
							) : <div class="registrationCardBtm" style={{ visibility: 'hidden' }}>
								<div class="registrationCardAddr">
									<span class="rgstrnAddrsIcon">
										{/* <i class="fa fa-home" aria-hidden="true"></i> */}
										<img src={Home} />
									</span>
									<span class="rgstrnAddrs">
										{hospitalName}&nbsp;{Address}
									</span>
								</div>
								{defaultStyleCard && (
									<div class="registrationCardContact">
										<div class="cardContactList">
											<img src={Calls} />
											+91 04712720740
										</div>
										<div class="cardContactList">
											<img src={Webmail} />
											drgins@gmail.com
										</div>
										<div class="cardContactList">
											<img src={Phoneicon} />
											+91 9037020740
										</div>
										<div class="cardContactList">
											<img src={Web} />
											wwww.dentaldesign.in
										</div>
									</div>
								)}
							</div>}
						</div>
						{isBackside == true && (
							<div class="registrationCard" style={{ display: 'block' }}>
								<div class="cardInsructions">
									<span
										class="cardInstrnTitle"
										style={{ textAlign: 'center', textTransform: 'uppercase', fontWeight: '600' }}
									>
										Instruction
									</span>
									<ul style={{ paddingLeft: '0px' }}>
										<li class="elipsis" title="Valid only at tied up service providers        ">
											{instructionOnevalue}
										</li>
										<li class="elipsis" title="        This card is not transferable        ">
											{' '}
											{instructionTwovalue}
										</li>
										<li
											class="elipsis"
											title="        In case of loss of card,immediately inform Hospital / Clinic        "
										>
											{instructionThreevalue}
										</li>
										<li class="elipsis" title="        This card needs revalidation after expiry">
											{instructionFourvalue}
										</li>
									</ul>
									<div
										class="signatureDiv"
										style={{ bottom: '25px', textAlign: 'Right', fontWeight: '600' }}
									>
										Authorised Signatory
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
export default Healthcard;
