import Editicon from "../images/icons/edit-bg.png"
import Tick from "../images/icons/active.png"
import Close from "../images/icons/close.png"
import List from "../images/icons/list.png"
import LabHeader from '../labLogin/layout/header';
import Footer from '../labLogin/layout/footer';
import Add from '../images/icons/Add_img.png';
import LabLeft from '../labLogin/labLeft'
import NoData from '../Common/noDataFound';
import Pagination from 'react-js-pagination';
import React, { useEffect, useState } from 'react';
import Loader from '../Common/loader';
import { callingAPI } from '../config';
import zIndex from "@material-ui/core/styles/zIndex";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LabTestDetails() {
	var selectedTaxListdata = [];
	const [selectedTaxIdList, setSelectedTaxIdList] = useState([]);
	const [testResults, setTestResults] = useState([]);
	const [testCount, setTestCount] = useState(0);
	const [testId, setTestId] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [txtInput, setTxtInput] = useState('');
	const [getSupplierData, setSupplierData] = useState([]);
	const [filterName, setFilterInput] = useState("0");
	const [categoryType, setCategoryType] = useState(0);
	const [categoryValues, setCategoryValues] = useState([]);
	const [disposableitemsaddedarr, setDisposableItemsAddedArr] = useState([]);
	const [quantity, setQuantity] = useState('');
	const [disposableItemName, setDisposableItemName] = useState([]);
	const [popUpDoctorList, setPopUpDoactorList] = useState(false);
	const [getId, setId] = useState('');
	const [memberName, setMemberName] = useState('');
	const [memberAge, setMemberAge] = useState('');
	const [memberGender, setMemberGender] = useState('');
	const [addValue, setAddValue] = useState([]);
	const [itemName, setItemName] = useState('');
	const [buyerprice, setBuyerprice] = useState('');
	const [department, setDepartment] = useState('');
	const [testcode, setTestcode] = useState('');
	const [serviceProviderTestBuyPrice, setServiceProviderTestBuyPrice] = useState('');
	const [serviceProviderTestDepartment, setServiceProviderTestDepartment] = useState('');
	const [serviceProviderTestCode, setServiceProviderTestCode] = useState('');
	const [serviceProviderTestDescription, setServiceProviderTestDescription] = useState('');
	const [serviceProviderTestDetailsTestName, setServiceProviderTestDetailsTestName] = useState('');
	const [show, setShow] = useState(false);
	const [getOffset, setOffset] = useState('');
	const [showEditDiv, setShowEditDiv] = useState(false);
	const [load, setLoad] = useState(false);
	const [testname, setTestname] = useState('');
	const [taxListdata, setTaxListData] = useState([]);
	const [onlycheckedTaxList, setOnlyCheckedTaxList] = useState([]);
	//
	const taxesChange = (event, item, index) => {
		const checkedTaxArrCopy = [...taxListdata];
		checkedTaxArrCopy[index].isChecked = !item.isChecked;
		setTaxListData(checkedTaxArrCopy);
		if (event.target.checked == true) {
			var IdList = selectedTaxIdList;
			IdList.push(item.hospitalTaxDetailsId);
			setSelectedTaxIdList(IdList);
		}
		else {
			var IdList = selectedTaxIdList;
			var indexNumber = IdList.indexOf(item.hospitalTaxDetailsId);
			IdList.splice(indexNumber, 1);
			setSelectedTaxIdList(IdList);
		}
		let onlyCheckedTaxArrCopy = [...checkedTaxArrCopy];
		onlyCheckedTaxArrCopy = checkedTaxArrCopy.filter((val, idx) => {
			if (val.isChecked == true) {
				return true;
			} else {
				return false;
			}
		});
		setOnlyCheckedTaxList(onlyCheckedTaxArrCopy)


	};
	const getTaxListingDataValues1 = () => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('inventoryStockUpdate/taxListing', postdata)
			.then((res) => {
				var result = [];
				if (res.data.success === '1') {
				result = res.data.result.hospitalTaxDetailsList.map((val, idx) => {
					val.isChecked = false;
					return val;
				});
			}
				if (res.data.success === '1') {
					setTaxListData(res.data.result.hospitalTaxDetailsList);
				} else {
					setTaxListData([]);
				}
			})
			.catch();
	};

	const getTaxListingDataValues = (selectedTaxIdList) => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('inventoryStockUpdate/taxListing', postdata)
			.then((res) => {
				var result = [];
				if (res.data.success === '1') {
				result = res.data.result.hospitalTaxDetailsList.map((val, idx) => {
					if (selectedTaxIdList.includes(val.hospitalTaxDetailsId)) {
						val.isChecked = true;
					}
					else {
						val.isChecked = false;
					}
					return val;
				});
			}
				if (res.data.success === '1') {
					setTaxListData(res.data.result.hospitalTaxDetailsList);
				} else {
					setTaxListData([]);
				}
			})
			.catch();
	};
	const handleKeypress = (event) => {
		//it triggers by pressing the enter key
		//
		if (event.key === 'Enter') {
			searchFunction();
		}
	};
	const removeAchievementItem = (e, idx) => {
		let newFormValues = [...disposableitemsaddedarr];
		newFormValues.splice(idx, 1);
		setDisposableItemsAddedArr(newFormValues);
	};
	const closePopUp = () => {
		setPopUpDoactorList(false);
	};
	const parameterList = (data) => {
		window.sessionStorage.setItem('test_id', data.testDetailsId);
		window.sessionStorage.setItem('testName', data.testname);
		window.sessionStorage.setItem('activePage', activePage)
		window.sessionStorage.setItem('offset', getOffset)
		window.location.href = '/settings/LabTestParameters';
	}
	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		}
		setOffset(offset)
		window.sessionStorage.setItem('activePage', pageNumber)
		window.sessionStorage.setItem('offset', offset)
		let PostData = {
			userId: window.sessionStorage.getItem("userId"),
			roleName: window.sessionStorage.getItem("role"),
			max: max,
			offset: offset,
			"cmbSelect": "",
			"txtInput": ""
		};
		callingAPI('testDetails/testDetails', PostData)
			.then((data) => {

				if (data.data.success === '1') {
					setTestResults(data.data.result.testResults);
					setTestCount(data.data.result.testCount);
				}
			})
			.catch();
	};
	const onDescChange = (event) => {
		const { name, value } = event.target;
		setMemberName(event.target.value);
	};
	const onDescChange1 = (event) => {
		const re = /^[0-9\b]+$/;
		const { name, value } = event.target;
		setMemberAge(event.target.value);
	};
	const onDescChange2 = (event) => {
		const { name, value } = event.target;
		setMemberGender(event.target.value);
	};
	const addAchievements = () => {
		var flag1 = true;
		let copy = JSON.parse(JSON.stringify(disposableitemsaddedarr));
		if (categoryType === "0" || document.getElementById("hospitalInventoryCategoryId").value === "0") {
			toast.error("Category required")
			flag1 = false;
			setLoad(true);
			return;
		}
		else if (categoryType != "0" && document.getElementById('itemName').value == "") {
			toast.error("Disposable/Item Name required")
			flag1 = false;
			setLoad(true);
			return;
		}
		else if (categoryType != "0" && quantity === "") {
			toast.error("Quantity required")
			flag1 = false;
			setLoad(true);
			return;
		}

		copy.push({
			itemName: document.getElementById('itemName').value,
			pmHospitalTreatmentInventoryQuantity: quantity,
			hospitalInventoryCategoryId: categoryType,
		});
		// setTestId('')
		setDisposableItemsAddedArr(copy);
		setCategoryType('0');
		setQuantity('');
		setItemName('');
		document.getElementById('itemName').value = '';

	};
	const searchFunction = () => {
		setActivePage(1);
		setShow(true)
		if (filterName !== "0" && txtInput === "") {
			var t = document.getElementById("cmbSelect");
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById("txtInput").focus();
			toast.error(selectedText + " Required");

			return false;
		}
		const apiJson = {
			userId: window.sessionStorage.getItem("userId"),
			roleName: window.sessionStorage.getItem("role"),
			offset: 0,
			max: 10,
			cmbSelect: document.getElementById("cmbSelect").value,
			txtInput: document.getElementById("txtInput").value,
		};

		callingAPI("testDetails/testDetails", apiJson).then((data) => {

			if (data.data.success === "1") {

				setTestResults(data.data.result.testResults);
				setTestCount(data.data.result.testCount);
			} else {
				setTestResults([]);
				setTestCount(0);

			}
		});
	};
	const filterByPatient = (value) => {
		setTxtInput(value);
	};
	const changeText = (value) => {
		setFilterInput(value);
		if (value === "0") {
			//setOffset("0")
			setTxtInput('');
			document.getElementById('txtInput').disabled = true;
			// labTestListing()
		} else {
			document.getElementById('txtInput').disabled = false;
			document.getElementById('txtInput').focus();
			// labTestListing()
		}
	};

	useEffect(() => {

		labTestListing()
		getTaxListingDataValues1()
		categoryDropdownValues();
		setActivePage(Number(window.sessionStorage.getItem('activePage')))



	}, []);

	const
		labTestListing = () => {
			const apiJson = {
				userId: window.sessionStorage.getItem("userId"),
				roleName: window.sessionStorage.getItem("role"),
				"offset": window.sessionStorage.getItem('offset'),
				"max": 10,
				"cmbSelect": document.getElementById('cmbSelect').value,
				"txtInput": document.getElementById('txtInput').value,
			};
			callingAPI('testDetails/testDetails', apiJson).then((data) => {
				if (data.data.success === '1') {
					setTestResults(data.data.result.testResults);
					setTestCount(data.data.result.testCount);
					setLoad(true)
				}
				else {
					setLoad(true)
					setTestResults([]);
					setTestCount(0);
				}
			});
		};

	const statusChange = (id, status) => {
		var x = 0;
		if (status == 1) {
			x = window.confirm('Do you want to Disable this?');
		} else {
			x = window.confirm('Do you want to Enable this?');
		}
		if (x == 1) {
			const PostData = {
				userId: window.sessionStorage.getItem('userId'),
				testId: id,
				currentStatus: status == 1 ? 1 : 0
			};
			callingAPI('activateDeActivateLabTest/activateDeActivateLabTest', PostData).then((data) => {
				if (data.data.success === "1") {
					if (show === false) {
						labTestListing();
					}
					else {
						searchFunction()
					}

				}

			});
		} const closePopUp = () => {
			setPopUpDoactorList(false);
		};
	};
	const labTestSave = () => {
		setLoad(false)
		var selectedtaxvaluesArr = [];
		var selectedtaxvaluesArr = taxListdata.reduce(
			(a, o) => (o.isChecked && a.push(o.hospitalTaxDetailsId), a),
			[]
		);

		var itemDetailsArray = [];
		var flag1 = true;
		if (disposableitemsaddedarr.length === 0) {
			if (disposableitemsaddedarr.length === 0 && document.getElementById('hospitalInventoryCategoryId').value != "0" && document.getElementById('itemName').value == "") {
				toast.error("Disposable/Item Name required")
				flag1 = false;
				setLoad(true);
				return;
			}
			else if (document.getElementById('hospitalInventoryCategoryId').value != "0" && quantity === "") {
				toast.error("Quantity required")
				flag1 = false;
				setLoad(true);
				return;
			}

			// memberName1223
			var obj = {
				"txtCategoryItem": document.getElementById('itemName').value,
				"txtQuantity": quantity,
				"cmbCategory": categoryType,
				"txtHidMedicine": "",

			}
			itemDetailsArray.push(obj)
		}

		else if (document.getElementById('itemName').value === "" && quantity === "" && categoryType === "0") {
			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				if (disposableitemsaddedarr[i].itemName === "") {
					toast.error("Disposable/Item Name required")
					flag1 = false;
					setLoad(true);
					return;

				}
				else if (disposableitemsaddedarr[i].pmHospitalTreatmentInventoryQuantity === "") {
					toast.error("Quantity required")
					flag1 = false;
					setLoad(true);
					return;
				}
			}
			if (document.getElementById('hospitalInventoryCategoryId').value != "0" && document.getElementById('itemName').value == "") {
				toast.error("Disposable/Item Name required")
				flag1 = false;
				setLoad(true);
				return;
			}
			else if (document.getElementById('hospitalInventoryCategoryId').value != "0" && quantity === "") {
				toast.error("Quantity required")
				flag1 = false;
				setLoad(true);
				return;
			}
			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				var obj1 = {
					"cmbCategory": document.getElementById('hospitalInventoryCategoryId' + i).value,
					"txtCategoryItem": document.getElementById('itemName' + i).value,
					"txtHidMedicine": "",
					"txtQuantity": document.getElementById('pmHospitalTreatmentInventoryQuantity' + i).value
				}

				itemDetailsArray.push(obj1);
			}
		}

		else if (disposableitemsaddedarr.length > 0 && document.getElementById('itemName').value !== "" && document.getElementById('hospitalInventoryCategoryId').value !== "" && document.getElementById('quantity').value !== "") {
			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				if (disposableitemsaddedarr[i].itemName === "") {
					toast.error("Disposable/Item Name required")
					flag1 = false;
					setLoad(true);
					return;

				}
				else if (disposableitemsaddedarr[i].pmHospitalTreatmentInventoryQuantity === "") {
					toast.error("Quantity required")
					flag1 = false;
					setLoad(true);
					return;
				}
			}
			if (document.getElementById('hospitalInventoryCategoryId').value != "0" && document.getElementById('itemName').value == "") {
				toast.error("Disposable/Item Name required")
				flag1 = false;
				setLoad(true);
				return;
			}
			else if (document.getElementById('hospitalInventoryCategoryId').value != "0" && quantity === "") {
				toast.error("Quantity required")
				flag1 = false;
				setLoad(true);
				return;
			}
			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				var obj1 = {
					"cmbCategory": document.getElementById('hospitalInventoryCategoryId' + i).value,
					"txtCategoryItem": document.getElementById('itemName' + i).value,
					"txtHidMedicine": "",
					"txtQuantity": document.getElementById('pmHospitalTreatmentInventoryQuantity' + i).value
				}

				itemDetailsArray.push(obj1);
			}
			var obj = {
				"txtCategoryItem": document.getElementById('itemName').value,
				"txtQuantity": quantity,
				"cmbCategory": categoryType,
				"txtHidMedicine": "",

			}
			itemDetailsArray.push(obj)

		}
		else if (disposableitemsaddedarr.length > 0) {
			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				if (disposableitemsaddedarr[i].itemName === "") {
					toast.error("Disposable/Item Name required")
					flag1 = false;
					setLoad(true);
					return;

				}
				else if (disposableitemsaddedarr[i].pmHospitalTreatmentInventoryQuantity === "") {
					toast.error("Quantity required")
					flag1 = false;
					setLoad(true);
					return;
				}
			}
			if (document.getElementById('hospitalInventoryCategoryId').value != "0" && document.getElementById('itemName').value == "") {
				toast.error("Disposable/Item Name required")
				flag1 = false;
				setLoad(true);
				return;
			}
			else if (document.getElementById('hospitalInventoryCategoryId').value != "0" && quantity === "") {
				toast.error("Quantity required")
				flag1 = false;
				setLoad(true);
				return;

			}



			for (var i = 0; i < disposableitemsaddedarr.length; i++) {
				var obj4 = {
					"cmbCategory": document.getElementById('hospitalInventoryCategoryId' + i).value,
					"txtCategoryItem": document.getElementById('itemName' + i).value,
					"txtHidMedicine": "",
					"txtQuantity": document.getElementById('pmHospitalTreatmentInventoryQuantity' + i).value
				}
				itemDetailsArray.push(obj4);
			}
		}

		var hospitalInventoryMasterCategoryId = [];
		for (var i = 0; i < disposableitemsaddedarr.length; i++) {
			var a = document.getElementById('itemName' + i).value;

			hospitalInventoryMasterCategoryId.push(a);
		}
		var hospitalInventoryMasterItemName = [];
		for (var i = 0; i < disposableitemsaddedarr.length; i++) {
			var b = document.getElementById('pmHospitalTreatmentInventoryQuantity' + i).value;

			hospitalInventoryMasterItemName.push(b);
		}
		var hospitalInventoryMasterItemQuantity = [];
		for (var i = 0; i < disposableitemsaddedarr.length; i++) {
			var c = document.getElementById('hospitalInventoryCategoryId' + i).value;

			hospitalInventoryMasterItemQuantity.push(c);
		}
		var flag = true;
		//	serviceProviderTestDetailsTestName/serviceProviderTestBuyPrice/serviceProviderTestDescription
		if (serviceProviderTestDetailsTestName === '') {

			toast.error('Test Name Required');

			flag = false;
			document.getElementById('serviceProviderTestDetailsTestName').focus();
			setLoad(true)
		} else if (serviceProviderTestBuyPrice === '') {
			toast.error('Price Required');
			document.getElementById('serviceProviderTestBuyPrice').focus();
			flag = false;
			setLoad(true)
		} else if (serviceProviderTestDescription === '') {
			toast.error('Description Required');
			document.getElementById('serviceProviderTestDescription').focus();
			flag = false;
			setLoad(true)
		}


		if (flag === true) {
			const apiJson = {
				testId: testId,
				userId: window.sessionStorage.getItem('userId'),
				roleName: window.sessionStorage.getItem("role"),
				hospitalId: localStorage.getItem("hospitalId"),
				testName: serviceProviderTestDetailsTestName,
				txtCode: serviceProviderTestCode,
				txtDepartment: serviceProviderTestDepartment,
				txtBuyPrice: serviceProviderTestBuyPrice,
				txtDescription: serviceProviderTestDescription,
				taxDetails: selectedtaxvaluesArr,
				itemDetails: itemDetailsArray,


			}
			callingAPI('saveTestDetails/saveTestDetails', apiJson).then((data) => {
				if (data.data.success === '1') {
					if (testId !== '') {
						toast.success("Lab Test Details Updated Successfully")
					}
					else {
						setOffset("0")
						toast.success("Lab Test Details Added Successfully")

					}
					setPopUpDoactorList(false);
					labTestListing();
					setLoad(true)
				} else {
					toast.error("Test Name already exists");
					setPopUpDoactorList(true);
					labTestListing()
				}

			});
		}
	};
	const editLab = (id) => {
		setLoad(false)
		setShowEditDiv(false)
		setMemberName('');
		setCategoryType('')
		setQuantity('')
		setPopUpDoactorList(true);
		setTestId(id);
		setServiceProviderTestDetailsTestName('');
		setServiceProviderTestDepartment('');
		setServiceProviderTestBuyPrice('');
		setServiceProviderTestDescription('');
		setServiceProviderTestCode('');
		setSupplierData('');

		setDisposableItemsAddedArr([])
		const apiJson = {
			"userId": window.sessionStorage.getItem("userId"),
			"roleName": window.sessionStorage.getItem("role"),
			"offset": 0,
			"max": 10,
			"cmbSelect": document.getElementById('cmbSelect').value,
			"txtInput": document.getElementById('txtInput').value,
			"testId": id
		}

		callingAPI('testDetails/testDetails', apiJson).then((data) => {
			if (data.data.success === '1') {
				data.data.result.taxDetails.map((val, idx) => {
					val.isChecked = true;
					return val;
				});
				selectedTaxListdata = data.data.result.testTaxdetails.map(
					(val) => {
						var id = val.hospitalTaxDetailsId;
						return id
					}
				);
				setLoad(true)
				setSelectedTaxIdList(selectedTaxListdata)
				setServiceProviderTestDetailsTestName(data.data.result.testResults[0].testname);
				setServiceProviderTestDepartment(data.data.result.testResults[0].department);
				setServiceProviderTestBuyPrice(data.data.result.testResults[0].buyerprice);
				setServiceProviderTestDescription(data.data.result.testResults[0].descr);
				setServiceProviderTestCode(data.data.result.testResults[0].testcode);
				setSupplierData(data.data.result.itemDetails);
				getTaxListingDataValues(selectedTaxListdata)


				var quantity = [];
				for (var i = 0; i < data.data.result.itemDetails.length; i++) {
					var x = {
						itemName: data.data.result.itemDetails[i].name,
						pmHospitalTreatmentInventoryQuantity:
							data.data.result.itemDetails[i].Quantity,
						hospitalInventoryCategoryId: data.data.result.itemDetails[i].inventorycategoryId,
					};

					quantity.push(x);
				}
				setDisposableItemsAddedArr(quantity);
			} else {
			}

		});
	};


	const category1PopOpen = () => {
		setShowEditDiv(true)
		setTestId('')
		setPopUpDoactorList(true);
		setMemberName('');
		setCategoryType('')
		setQuantity('')
		setServiceProviderTestBuyPrice('');
		setServiceProviderTestDepartment('');
		setServiceProviderTestCode('');
		setServiceProviderTestDescription('');
		setServiceProviderTestDetailsTestName('');

		setSupplierData([]);
		setDisposableItemsAddedArr([]);

	};
	const categoryTypeChange = (event) => {
		setCategoryType(event.target.value);
	};
	const categoryDropdownValues = () => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('treatmentProcedure/getCategoryAndTaxDropdown', postdata)
			.then((res) => {
				setCategoryValues(res.data.result.categoryData);
			})
			.catch();
	};
	const categoryTypeNewChange = (e, i, Name) => {
		if (Name === "hospitalInventoryCategoryId") {
			document.getElementById("itemListDiv" + i).style.display = 'none';
			disposableitemsaddedarr[i].itemName = ""
			disposableitemsaddedarr[i].pmHospitalTreatmentInventoryQuantity = ""
			disposableitemsaddedarr[i].itemId = ""
		}
		const { name, value } = e.target;
		const values = [...disposableitemsaddedarr];
		values[i] = { ...values[i], [name]: value };
		setDisposableItemsAddedArr(values);

	};
	const quantityChange = (event) => {
		setQuantity(event.target.value);
	};
	const getDisposableItemNames = (i, id1, id2) => {
		setId(i);
		let postData = {
			hospitalId: localStorage.getItem("hospitalId"),
			searchKeyword: document.getElementById(id1).value,
			selectDropdownSearchType: document.getElementById('hospitalInventoryCategoryId' + i).value,
		};
		if (document.getElementById(id1).value.length > 1) {
			callingAPI('treatmentProcedure/getDisposableItemsByCategory', postData).then((data) => {
				if (data.data.success === '1') {
					document.getElementById(id2).style.display = 'block';
					setDisposableItemName(data.data.result);
				} else {
					document.getElementById(id2).style.display = 'none';
					setDisposableItemName([]);
				}
			});
		}
	};

	const addTest = () => {
		document.getElementById("labTestDetails").style.display = 'block';
	}
	const getItemIDFunction = (i, id1, id2, name, itemId) => {
		document.getElementById(id1 + getId).value = name;
		if (i === "") { setItemName(name); }
		document.getElementById(id2 + i).style.display = 'none'
		var exist = disposableitemsaddedarr.some(item => name === item.itemName);
		if (exist === true && i === "") {
			toast.error("Already exist")
			setItemName("");
			document.getElementById("itemName").value = ""
			return;
		}
		if (exist === true) {
			toast.error("Already exist")
			document.getElementById("itemName" + i).value = ""
			document.getElementById("itemId" + i).value = ""
			return;
		}
		const values = [...disposableitemsaddedarr];
		values[i] = { ...values[i], ["itemName"]: name };
		values[i] = { ...values[i], ["itemId"]: itemId };
		setDisposableItemsAddedArr(values)

	};
	return (
		<div className="dashboardCover labTestDtls">
			<ToastContainer position="bottom-right" style={{zIndex:"999999999"}}/>
			{load === false ? <Loader /> : ''}
			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle">Lab Test Details</div>
					{/* {process.env.REACT_APP_PAGINATION_COUNT > 10 && ( */}

					{/* )} */}
				</div>
				<div className="col-md-2">
					<a onClick={category1PopOpen}><span className="dashButtonBorder" style={{ background: "#22a2fd", color: "#fff", border: "1px solid #22a2fd" }}>Add +</span></a>
				</div>
				{testCount > 10 ? (
					<div className="paginationSection">
						<Pagination
							// hideNavigation
							hideDisabled
							activePage={activePage}
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={testCount}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={handlePageChange}
						/>
					</div>
				) : (
					''
				)}
			</div>

			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select className="inputField"
									id="cmbSelect"
									title="Search In"
									onChange={(e) => changeText(e.target.value)}>
									<option value="0">Search In</option>
									<option value="1">Test Name</option>
									<option value="2">Test Code</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<input type="text"
									className="inputField"
									id="txtInput"
									onKeyPress={handleKeypress}
									value={txtInput}
									onChange={(e) => filterByPatient(e.target.value)}
									disabled={filterName === "0" ? true : false}
									style={{ background: filterName === "0" ? "transparent" : "white" }} />
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span className="dashButtonBorder" onClick={searchFunction}>Search</span>
							</div>
							<div className="col-md-6 dashboardSearchList">
								<div className="searchCount"><b>Total : {testCount ? testCount : "0"}</b></div>
							</div>
						</div>
					</div>
					{testResults.length > 0 ? (
						<div className="dashboardtable">
							<table>
								<thead>
									<tr>
										<th>Id</th>
										<th>  Test Name	</th>
										<th>Department</th>
										<th>Price</th>
										<th>Test Code</th>
										<th> Description</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{testResults.map((data, i) => (
										<tr>
											<td>{data.id}</td>
											<td>{data.testname}</td>
											<td>{data.department}</td>
											<td>&#8377; {data.buyerprice.toFixed(2)
												.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
											<td>{data.testcode}</td>
											<td>{data.descr}</td>
											<td>
												<img
													src={List}
													title="Add Parameter"
													onClick={(e) => parameterList(data)}
												/>

												<a>
													<img
														src={Editicon}
														title="Edit"
														onClick={(e) => editLab(data.id)}
													/>
												</a>

												{data.isActive == 1 ? (
													<img
														src={Tick}
														title="Click to Deactivate"
														onClick={() => statusChange(data.id, data.isActive)}
														style={{ width: '20px' }}
													/>
												) : (
													<img
														src={Close}
														title="Click to Activate"
														onClick={() => statusChange(data.id, data.isActive)}
														style={{ width: '20px' }}
													/>
												)}

											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<NoData />
					)}
				</div>

				{testCount > 10 ? (
					<div className="paginationSection">
						<Pagination
							// hideNavigation
							hideDisabled
							activePage={activePage}
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={testCount}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={handlePageChange}
						/>
					</div>
				) : (
					''
				)}
			</div>

			<div>
				{popUpDoctorList == true && (
					<div className="ploverlayy">
						<div className="plpopup plpopupList ">
							{/* <h2>
								&nbsp;
								<a class="close" href="#" onClick={closePopUp}>
									&times;
								</a>
							</h2> */}
							<div class="addDataHead">
								<span id="spanAddSupplier">Add Test Details</span>
								<a href="#" id="btnCancel" class="close" onClick={closePopUp} style={{ right: '0px' }}>
									<span class="popUpClose">x</span>
								</a>
							</div>
							<div className="popBack" id="popup1">
								<div className="popOverlay" />
								<div className="row">
									<div className="customizationPop">
										<div className="customizationPopContentSec">
											<input type="hidden" id="txtHiddenCategoryId" />
											<div className="row">
												<div className="col-md-12 col-12">
													<div className="row">
														<div className="col-md-4">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<label className="labelField">
																		Test Name<sup>*</sup>
																	</label>
																</div>
															</div>
														</div>

														<div className="col-md-8">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<div className="formInputSection">
																		<input
																			type="text"
																			className="formInputField inputField"
																			id="serviceProviderTestDetailsTestName"
																			name="serviceProviderTestDetailsTestName"
																			value={serviceProviderTestDetailsTestName}
																			placeholder="Select Test Name"
																			onChange={(e) =>
																				setServiceProviderTestDetailsTestName(
																					e.target.value
																				)}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 col-12">
													<div className="row">
														<div className="col-md-4">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<label className="labelField">
																		Department<sup />
																	</label>
																</div>
															</div>
														</div>
														<div className="col-md-8">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<div className="formInputSection">
																		<input
																			type="text"
																			className="formInputField inputField"
																			id="serviceProviderTestDepartment"
																			name="serviceProviderTestDepartment"
																			value={serviceProviderTestDepartment}
																			placeholder="Department"
																			onChange={(e) =>
																				setServiceProviderTestDepartment(
																					e.target.value
																				)}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 col-12">
													<div className="row">
														<div className="col-md-4">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<label className="labelField">
																		Price<sup>*</sup>
																	</label>
																</div>
															</div>
														</div>
														<div className="col-md-8">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<div className="formInputSection">
																		<input
																			type="text"
																			className="formInputField inputField"
																			id="serviceProviderTestBuyPrice"
																			name="serviceProviderTestBuyPrice"
																			value={serviceProviderTestBuyPrice}
																			placeholder="Price"
																			onKeyPress={(event) => {
																				if (!/[0-9.]/.test(event.key)) {
																					event.preventDefault();
																					toast.error("Only numbers accepted")
																				}
																			}}
																			onChange={(e) =>
																				setServiceProviderTestBuyPrice(
																					e.target.value
																				)}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 col-12">
													<div className="row">
														<div className="col-md-4">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<label className="labelField">
																		Description<sup>*</sup>
																	</label>
																</div>
															</div>
														</div>
														<div className="col-md-8">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<div className="formInputSection">
																		<input
																			type="text"
																			className="formInputField inputField"
																			id="serviceProviderTestDescription"
																			name="serviceProviderTestDescription"
																			value={serviceProviderTestDescription}
																			placeholder="Description"
																			onChange={(e) =>
																				setServiceProviderTestDescription(
																					e.target.value
																				)}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 col-12">
													<div className="row">
														<div className="col-md-4">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<label className="labelField">
																		Test Code<sup />
																	</label>
																</div>
															</div>
														</div>
														<div className="col-md-8">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<div className="formInputSection">
																		<input
																			type="text"
																			className="formInputField inputField"
																			id="serviceProviderTestCode"
																			name="serviceProviderTestCode"
																			value={serviceProviderTestCode}
																			placeholder="Test Code"
																			onChange={(e) =>
																				setServiceProviderTestCode(
																					e.target.value
																				)}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 col-12">
													<div className="row">
														<div className="col-md-4">
															<div className="form-group">
																<div className="addStoreSectionList">
																	<label className="labelField">
																		Tax<sup />
																	</label>
																</div>
															</div>
														</div>
														<div className="col-md-8">
															<div className="row">
																<div className="addStoreSectionList">
																	<div className="formInputSection row labTestDetailsList taxInfoListSection">
																		{taxListdata.map((option, idx) => {
																			return (
																				<div class="col-lg-6 procedureCatType1  procedureCatListWrap ">
																					<label> <input
																						type="checkbox"
																						id={option.hospitalTaxDetailsId}
																						value={option.hospitalTaxDetailsId}
																						checked={selectedTaxIdList.indexOf(option.hospitalTaxDetailsId) >= 0 ? true : false}
																						onChange={(event) => taxesChange(event, option, idx)}
																					/>
																						&nbsp;{option.taxNames}</label>
																				</div>
																			);
																		})}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<label className="labelField" style={{ marginBottom: '10px' }}>
													Disposable/Items used
												</label>
												<div className="disposableWraperList">
													<div className="col-md-12">

														<div className="row">
															<div className="col-md-4 col-4">
																<div className="form-group">
																	{/* <select
																	name="kidGender"
																	class="smyLargstFieldnew formInputborderR inputField "
																	onChange={onDescChange2}
																	value={memberGender}
																>
																	<option value="0">Select Category</option>
																	<option value="1">Medicine</option>
																	<option value="2">Disposable</option>
																	<option value="3">Others</option>
																</select> */}
																	<select
																		name="hospitalInventoryCategoryId"
																		id="hospitalInventoryCategoryId"
																		value={categoryType}
																		onChange={categoryTypeChange}
																		style={{ width: '100%' }}
																		className="inputField"
																	>
																		<option value="0">Select Category</option>
																		{categoryValues.map((item, idix) => (
																			<option
																				value={item.hospitalInventoryCategoryId}
																			>
																				{item.hospitalInventoryCategoryName}
																			</option>
																		))}
																	</select>
																</div>
															</div>
															<div className="col-md-4 col-4">
																<div className="form-group">
																	{/* <input
																	type="text"
																	className="formInputField inputField"
																	name="memberName"
																	value={memberName}
																	onChange={onDescChange}
																	placeholder="Disposable/Items used"
																/> */}
																	<input
																		type="text"
																		defaultValue={memberName}
																		onChange={onDescChange}
																		name="memberName"
																		placeholder="Disposable/Item Name"
																		id="itemName"
																		className="inputField"
																		onKeyUp={() =>
																			getDisposableItemNames(
																				'',
																				'itemName',
																				'itemListDiv'
																			)}
																		// onChange={itemNameChange}
																		style={{ width: '100%' }}
																	/>
																	<ul
																		className="autoSearch autoSearch-p0 appointmentNumberSearch"
																		id="itemListDiv"
																		style={{ display: 'none' }}
																	>
																		{disposableItemName.map((item, i) => (
																			<li
																				onClick={() =>
																					getItemIDFunction(
																						'',
																						'itemName',
																						'itemListDiv',
																						item.itemName
																					)}
																			>
																				{item.itemName}
																			</li>
																		))}
																	</ul>
																</div>
															</div>
															<div className="col-md-3 col-3">
																<div className="form-group">
																	<input
																		type="text"
																		id="quantity"
																		onChange={quantityChange}
																		placeholder="Quantity"
																		name="quantity"
																		onKeyPress={(event) => {
																			if (!/[0-9.]/.test(event.key)) {
																				event.preventDefault();
																				toast.error("Only numbers accepted")
																			}
																		}}
																		value={quantity}
																		style={{ width: '100%' }}
																		className="inputField"
																	/>
																</div>
															</div>
															<div className="col-md-1 col-1" style={{ padding: '2px' }}>
																<div className="form-group">
																	<span onClick={(e) => addAchievements(e)}>
																		<img
																			src={Add}
																			style={{ cursor: 'pointer', marginLeft: '4px' }}
																		/>
																	</span>
																</div>
															</div>
														</div>
													</div>

													<div class="col-md-12">
														<div class="table-responsive">
															<table
																class="table"
																id="tblAchievements"
																style={{ display: 'grid' }}
															>
																<tbody>
																	{disposableitemsaddedarr.map((item, idx) => (
																		<tr
																			id="gridnew_docedit_achtrow"
																			class="plLabTestAppend"
																		>
																			<td style={{ width: '30%' }}>
																				<select
																					name="hospitalInventoryCategoryId"
																					id={'hospitalInventoryCategoryId' + idx}
																					value={item.hospitalInventoryCategoryId}
																					onChange={(e) =>
																						categoryTypeNewChange(e, idx, "hospitalInventoryCategoryId")}
																					style={{ width: '100%' }}
																					className="inputField"
																				>
																					<option value="0">
																						Select Category
																					</option>
																					{categoryValues.map((item, idx) => (
																						<option
																							value=
																							{
																								item.hospitalInventoryCategoryId
																							}
																						>
																							{
																								item.hospitalInventoryCategoryName
																							}
																						</option>
																					))}
																				</select>
																			</td>
																			<td style={{ width: '30%' }}>

																				<input
																					// disabled="disabled"
																					type="text"
																					name="itemName"
																					placeholder="Disposable/Item Name"
																					className="inputField lbTestDetlsItemName dataAppend labTestDetailsField"
																					id={'itemName' + idx}
																					value={item.itemName}
																					onChange={(e) => categoryTypeNewChange(e, idx, "itemName")}
																					// onChange={onDescChange}
																					onKeyUp={() =>
																						getDisposableItemNames(
																							idx,
																							'itemName' + idx,
																							'itemListDiv' + idx
																						)}
																					style={{ width: '100%' }}
																				/>
																				<ul
																					className="autoSearch autoSearch-p0 appointmentNumberSearch"
																					appointmentNumberSearch
																					id={'itemListDiv' + idx}
																					style={{ display: 'none' }}
																				>
																					{disposableItemName.map((item, i) => (
																						<li
																							onClick={() =>
																								getItemIDFunction(
																									idx,
																									'itemName',
																									'itemListDiv',
																									item.itemName,
																									item.itemId
																								)}
																						>
																							{item.itemName}
																						</li>
																					))}
																				</ul>
																			</td>
																			<td>
																				<input
																					// disabled="disabled"
																					type="text"
																					id={
																						'pmHospitalTreatmentInventoryQuantity' +
																						idx
																					}
																					onChange={(e) => categoryTypeNewChange(e, idx, "pmHospitalTreatmentInventoryQuantity")}
																					// onChange={quantityChange}
																					placeholder="Quantity"
																					onKeyPress={(event) => {
																						if (!/[0-9.]/.test(event.key)) {
																							event.preventDefault();
																							toast.error("Only numbers accepted")
																						}
																					}}
																					className="inputField lbTestDetlsQuantity"
																					name="pmHospitalTreatmentInventoryQuantity"
																					value={
																						item.pmHospitalTreatmentInventoryQuantity
																					}
																					style={{ width: '81%' }}
																				/>
																				{/* <input
																				// disabled="disabled"
																				type="text"
																				name="achievementDes1"
																				id="achievementDes1"
																				value={item.memberAge}
																				class="achievementDesc inputField plQuantity"
																			/> */}
																			</td>

																			<td>
																				<a id="remove" tabindex="4" href="#">
																					<img
																						src={Close}
																						style={{
																							width: '20px',
																							cursor: 'pointer', marginTop: "8px"
																						}}
																						onClick={(e) =>
																							removeAchievementItem(e, idx)}
																					/>
																				</a>
																			</td>
																		</tr>
																	))}{' '}
																</tbody>
															</table>
														</div>
													</div>
												</div>

												<div class="summaryBtnDiv">
													{/* <span id="treatmentPlanBtnEdit"
														className="treatmentPlanBtn"
														style={{ display: showEditDiv ? '' : 'none' }}> */}
													<button
														className="practiceGreenButton"
														id="addCategory1Save"
														title="Save"
														style={{ display: showEditDiv ? '' : 'none' }}
														onClick={() => labTestSave()}
													>
														Save
													</button>
													{/* </span> */}
													{/* <span
													id="treatmentPlanBtnEdit"
													className="treatmentPlanBtn"
													style={{ display: !showEditDiv ? '' : 'none' }}> */}
													<button
														className="practiceGreenButton"
														id="addCategory1Save"
														title="Save"
														style={{ display: !showEditDiv ? '' : 'none' }}
														onClick={() => labTestSave()}
													>
														Update
													</button>
													{/* </span> */}
													<span
														onClick={closePopUp}
														className="customisBtn practiceGreenButton"
														style={{ borderColor: '#b22222' }}
														title="Cancel"
														id="addCategory1Cancel"
													>
														Cancel
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default LabTestDetails;
