import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import girl from '../images/media/noimageGirl.png';
import boy from '../images/media/noimageBoy.png';
import profileEdit from '../images/icons/lab/labEdit.png';
import { callingAPI } from '../config';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	faMale,
	faPhoneAlt,
	faExclamation,
	faCalendarAlt,
	faEnvelope,
	faTint,
	faEdit,
} from '@fortawesome/free-solid-svg-icons';
// import girl from '../images/media/noimageGirl.png';
import Youtube from '../images/icons/youtube.png';
import HealthBackGroundSub from './healthBackground';
import ReactTooltip from 'react-tooltip';
import Loader from '../Common/loader';
import { Link, useLocation } from 'react-router-dom';
function ProfileDetail(props) {
	const [patientProfileDetails, setpatientProfileDetails] = useState([]);
	const [friendlyValue, setfriendlyValue] = useState('');
	const [clinicalBackgroundList, setclinicalBackgroundList] = useState([]);
	const [imagePath, setImagePath] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState("")
	const [age, setAge] = useState("")
	const [saveFlag, setSaveFlag] = useState(false)
	const [dueAmount, setDueAmount] = useState("")
	const [advanceAmount, setAdvanceAmount] = useState("")
	const [dueOtherAmount, setDueOtherAmount] = useState("")
	const location = useLocation();
	var s3;
	var getS3folder =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;
	let params = {
		typeOne: 'ClinicHistory',
		save: 'saveClinical',
		delete: 'removeClinical',
	};
	const friendlyNoteValue = (e) => {
		setfriendlyValue(e.target.value);
	};
	const friendlyNoteSave = () => {
		setSaveFlag(true)
		document.getElementById("btnSave").disabled = true
		let saveParams = {
			type: 'saveFriendlyNotes',
			history: friendlyValue,
			memberId: window.sessionStorage.getItem('memberId'),
			userId: window.sessionStorage.getItem('userId'),
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
		};
		callingAPI('clinicalBackground/saveRemoveClinicalBackground', saveParams)
			.then((response) => {
				if (response.data.success === '1') {

					friendlyNoteListing()
					toast.success('Saved Successfully');
					setSaveFlag(false)
				}
			})
			.catch((err) => toast.error(err, 'err'));
	};
	const patientDetails = () => {
		let PostData = {
			patientId: (props.memberId !== null && props.memberId !== "null" && typeof props.memberId !== "undefined") ? props.memberId : window.sessionStorage.getItem('memberId'),
		};
		callingAPI('commonUserFunctions/patinetProfileSub', PostData)
			.then((response) => {
				if (response.data.success === '1') {
					setpatientProfileDetails(response.data.result.patinetProfileDetails);
					window.sessionStorage.setItem("PATIENTNAME", response.data.result.patinetProfileDetails[0].patientName)
					window.sessionStorage.setItem("PATIENTEMAIL", response.data.result.patinetProfileDetails[0].email)
					window.sessionStorage.setItem("PATIENTMOB", response.data.result.patinetProfileDetails[0].mobNumber)
					setDateOfBirth(response.data.result.patinetProfileDetails[0].dateOfBirth)
					if (response.data.result.patinetProfileDetails[0].age === 0) {
						var fromDateSplit = response.data.result.patinetProfileDetails[0].dateOfBirth.split("/");
						var day = new Date(fromDateSplit[2] + "," + fromDateSplit[1] + "," + fromDateSplit[0])
						var dateString = (moment(day).format("MM/DD/YYYY"))
						var now = new Date();
						var today = new Date(now.getYear(), now.getMonth(), now.getDate());
						var yearNow = now.getYear();
						var monthNow = now.getMonth();
						var dateNow = now.getDate();
						var dob = new Date(dateString.substring(6, 10),
							dateString.substring(0, 2) - 1,
							dateString.substring(3, 5)
						);
						var yearDob = dob.getYear();
						var monthDob = dob.getMonth();
						var dateDob = dob.getDate();
						var age = {};
						var ageString = "";
						var yearString = "";
						var monthString = "";
						var dayString = "";
						var yearAge = yearNow - yearDob;
						if (monthNow >= monthDob)
							var monthAge = monthNow - monthDob;
						else {
							yearAge--;
							var monthAge = 12 + monthNow - monthDob;
						}
						if (dateNow >= dateDob)
							var dateAge = dateNow - dateDob;
						else {
							monthAge--;
							var dateAge = 31 + dateNow - dateDob;
							if (monthAge < 0) {
								monthAge = 11;
								yearAge--;
							}
						}
						age = {
							years: yearAge,
							months: monthAge,
							days: dateAge
						};
						if (age.years > 1) yearString = " years";
						else yearString = " year";
						if (age.months > 1) monthString = " months";
						else monthString = " month";
						if (age.days > 1) dayString = " days";
						else dayString = " day";
						if ((age.years > 0) && (age.months > 0) && (age.days > 0))
							ageString = age.years + yearString
						else if ((age.years == 0) && (age.months == 0) && (age.days > 0))
							ageString = age.days + dayString;
						//   ageString = "Only " + age.days + dayString + " old";
						else if ((age.years > 0) && (age.months == 0) && (age.days == 0))
							ageString = age.years + yearString;
						else if ((age.years > 0) && (age.months > 0) && (age.days == 0))
							ageString = age.years + yearString
						else if ((age.years == 0) && (age.months > 0) && (age.days > 0))
							ageString = age.months + monthString
						else if ((age.years > 0) && (age.months == 0) && (age.days > 0))
							ageString = age.years + yearString
						else if ((age.years == 0) && (age.months > 0) && (age.days == 0))
							ageString = age.months + monthString;
						else ageString = "1 Day Old";
						setAge(ageString)
					}
					else {
						setAge(response.data.result.patinetProfileDetails[0].age === 1 ? response.data.result.patinetProfileDetails[0].age + " " + "year" : response.data.result.patinetProfileDetails[0].age + " " + "years")
					}
					//setImagePath(getS3folder +"/"+ response.data.result.patinetProfileDetails[0].memberImageName)
					if (response.data.result.patinetProfileDetails[0].memberImageName !== '' && response.data.result.patinetProfileDetails[0].memberImageName !== "null" && response.data.result.patinetProfileDetails[0].memberImageName !== null) {

						setImagePath(getS3folder + '/' + response.data.result.patinetProfileDetails[0].memberImageName);
					} else {
						if (response.data.result.patinetProfileDetails[0].gender === 'Male') {
							setImagePath(boy);
							//document.getElementById("images").title="No Image"
						} else {
							setImagePath(girl);
							//document.getElementById("images").title="No Image"
						}
						if (document.getElementById("images") != null) {
							document.getElementById("images").title = "No Image"
						}
					}
				}
			})

			.catch();
	};
	const noteView = (id) => {
		if (document.getElementById("txtFriendly").style.display === "none") {
			document.getElementById("txtFriendly").style.display = "block"
			document.getElementById("shwContent").style.display = "none"
		}
		else if (document.getElementById("txtFriendly").style.display === "block") {
			document.getElementById("txtFriendly").style.display = "none"
			document.getElementById("shwContent").style.display = "block"
			// var element = document.getElementById("friendlyNoteView");
			//  element.classList.add("viewFriendlyNote");
		}
		// if(friendlyValue!="")
		// {
		// 	document.getElementById("friendlyNoteView").style.minHeight = "100px"
		// }
		// else{
		// 	document.getElementById("friendlyNoteView").style.minHeight = "10px"
		// }

	}
	const friendlyNoteListing = () => {
		const noteList = {
			history: 'Clinicalsecond',
			type: 'FriendlyNotes',
			patientId: window.sessionStorage.getItem('memberId'),
			userId: window.sessionStorage.getItem('userId'),
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
		};
		callingAPI('clinicalBackground/clinicalBackgroundList', noteList)
			.then((response) => {
				if (response.data.success === '1' && response.data.result.clinicalBackgroundList.length > 0) {
					setfriendlyValue(response.data.result.clinicalBackgroundList[0].name);
					setclinicalBackgroundList(response.data.result.clinicalBackgroundList);
				}
			})
			.catch();
	};
	const dueInr = () => {
		const postdata = {
			hospitalId: localStorage.getItem('hospitalId'),
			memberId: window.sessionStorage.getItem('memberId'),
		};
		callingAPI('commonUserFunctions/getPatientBalanceAmountDetails', postdata)
			.then((response) => {
				if (response.data.success === '1') {
					setDueAmount(response.data.result[0].dueAmount)
					setAdvanceAmount(response.data.result[0].advanceAmount)
					setDueOtherAmount(response.data.result[0].dueAdvanceAmountFromOtherSite)
				}
			})
			.catch();
	};

	useEffect(() => {
		{ ((props.memberId !== null && props.memberId !== "null" && typeof props.memberId !== "undefined" ? props.memberId : window.sessionStorage.getItem('memberId')) !== "") && (props.memberId !== null && props.memberId !== "null" && typeof props.memberId !== "undefined" ? props.memberId : window.sessionStorage.getItem('memberId')) !== null && patientDetails() }
		friendlyNoteListing();
		dueInr();
	}, []);
	const popup = (memberId, url) => {
		window.sessionStorage.setItem('memberId', memberId);
		window.location.href = url;
	};
	return (
		<div className="profileDetail profrndlynte" id="profileMainDiv">
			<ToastContainer position="bottom-right" />
			<div className="pl_youtube pl_ptnts">{/* <img src={Youtube} /> */}</div>
			<div className="profileDetailContent">
				{patientProfileDetails.slice(0, 1).map((data) => (
					<div>
						<div id="images" className="gridImage  profilegridImage">
							<img src={imagePath} />
							{/* <div className='bloodgrp_sec'>{data.bloodGroup && (
								<div className="gridIconItem">
									<FontAwesomeIcon icon={faTint} />
									<span>Blood Group&nbsp;</span> : {data.bloodGroup}
								</div>
							)}
							</div> */}
						</div>

						<div className="gridContent">
							<div className="gridTop grid_flex">
								<span className="gridPatientName" style={{ width: "36%" }}> {data.patientName}</span>
								<div style={{ width: "18%" }}>
									{dueAmount && dueAmount !== "0" && dueAmount !== "0.00" ?
										<span className='gridIconItem gridIconItem-padd'>
											<span className='patientDueInr'>Due&nbsp;</span> : <span className='dueamounttxt' onClick={(e) => popup(window.sessionStorage.getItem('memberId'), '/patients/patientInvoices')}>{dueAmount}</span>
										</span>
										: ""}
								</div>
								<div style={{ width: "18%" }}>
									{advanceAmount && advanceAmount !== "0" && advanceAmount !== "0.00" ?
										<span className='gridIconItem gridIconItem-padd' >
											<span className='patientDueInr'>Advance&nbsp;</span> : <span className='dueamounttxt advAmountTxt'>{advanceAmount}</span>
										</span>
										: ""}
								</div>
								{/* <div  style={{width:"28%"}}>
								{dueOtherAmount && dueOtherAmount !== "0" && dueOtherAmount !== "0.00" ?
									<span className='gridIconItem gridIconItem-padd'>
										<span className='patientDueInr'>Due from other Vendor&nbsp;</span> : <span className='dueamounttxt'>{dueOtherAmount}</span>
									</span>
								:""}
								</div> */}

							</div>

							<div className="gridIconsList">

								{/*  */}
								<div className='gridIconItem gridIconItemLarge prNumberGridItem PrGridOnly elipsis'>
									{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
										(data.memberMrNo && <div className="prNumberGridItem  elipsis" title={data.memberMrNo}>
											<b><span>MR No</span> : {data.memberMrNo}</b>
										</div>) :
										(data.memberPrNo &&
											<div className="prNumberGridItem PrGridOnly elipsis" title="PR Number" >
												<b><span>PR No</span> : {data.memberPrNo}</b>
											</div>
										)}
								</div>
								<div className='bloodgrp_secOLD gridIconItem gridIconItemLarge prNumberGridItem  elipsis ' style={{ textAlign: "right" }}>
									{data.bloodGroup && (
										<div className="gridIconItemOLD">
											<FontAwesomeIcon icon={faTint} />
											<span>Blood Group&nbsp;</span> : {data.bloodGroup}
										</div>
									)}
								</div>
								{/*  */}


							</div>

							<div className="gridIconsList gridIconsListBottomInfo ">
								{data.lastVisit && (
									<div className="gridIconItem">



										<span class="visitDate greenColor"><FontAwesomeIcon icon={faCalendarAlt} /> Last Visited Date : {data.lastVisit}</span>

									</div>
								)}

								{data.gender && (
									<div className="gridIconItem gridSmallWidth  ">
										<FontAwesomeIcon icon={faMale} />
										<span>Gender</span> : {data.gender}
									</div>
								)}
								{(data.age !== '' && data.dateOfBirth !== "01-Jan-1900") ? (
									<div className="gridIconItem gridSmallWidth">
										<FontAwesomeIcon icon={faCalendarAlt} />
										<span>Age</span> : {age}
									</div>
								) : ''}

								{data.mobNumber && (
									<div className="gridIconItem gridSmallWidth">
										<FontAwesomeIcon icon={faPhoneAlt} />
										{data.mobNumber}
									</div>
								)}


								{/* =============== UPDATED FRIENDLY NOTE BEGINS HERE =============== */}
								<span class="friendlyNoteDiv  " style={{ fontSize: "13px" }}>
									<span>Friendly Note
										<img src={profileEdit} className="frindlynoteEdtImg" onClick={(e) => noteView("friendlyNoteSave")} title="Click To Edit" />
										{/* <i class="fa fa-pencil" aria-hidden="true" onclick="return showFriendly()" title="Click To Edit"></i> */}
									</span>

									<span class="friendlyNoteText">
										<span onClick={(e) => noteView("friendlyNoteView")} id="shwContent" >{friendlyValue}</span>
										<span id="txtFriendly" style={{ display: "none" }}>
											<textarea
												id="friendlyNoteView"
												onChange={friendlyNoteValue}
												value={friendlyValue}
												// value={noteList.name}
												className="inputField friendlyNoteViewSaveTxtArea "
												style={{ height: 'auto', resize: 'none' }}
											// placeholder={noteList.name}
											/>
											<span class="friendlyBtns" id="divButtons" style={{ display: "block" }}>
												<button disabled={saveFlag} onClick={friendlyNoteSave} id="btnSave" class="saveButton" style={{ cursor: "pointer" }} >Save</button>
											</span>
											{/* onclick="return saveFriendly()" */}
										</span>
									</span>
								</span>
								{/* =============== UPDATED FRIENDLY NOTE ENDS HERE =============== */}


							</div>
						</div>
					</div>
				))}
			</div>
			{(props.memberId !== null && props.memberId !== "null" && typeof props.memberId !== "undefined") ? "" :
				<div className="profileRightTag">
					<div className="practiceHeadTitle">Clinical Background</div>
					<div className="profileRightInput">
						{/* =============  Clinical Background Added list begins here =========== */}
						{/* =========== Clinical Background Added list begins here ===========  */}
						<HealthBackGroundSub type="ClinicHistory" />
					</div>
				</div>
			}
		</div>
	);
}
export default ProfileDetail;
