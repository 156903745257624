import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import { useParams } from 'react-router-dom';
import HeaderPrint from '../Settings/headerPrint';
import FooterPrint from '../Settings/footerPrint';
import moment from 'moment';
function SimplifiedFormPrintNew() {
	let { membId, doctorId, printId } = useParams();
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');
	const [pagesizeid, setpagesizeid] = useState('');
	const [hospitalDetails, setHospitalDetails] = useState([]);
	const [doctorDetails, setDoctorDetails] = useState([]);
	const [memberDetails, setMemberDetails] = useState([]);
	const [memberFirstName, setMemberFirstName] = useState([]);
	const [hospitalName, sethospitalName] = useState('');
	const [hospitalAddress, sethospitalAddress] = useState('');
	const [hospitalCity, sethospitalCity] = useState('');
	const [hospitalPhoneNumber, sethospitalPhoneNumber] = useState('');
	const [memberLastName, setMemberLastName] = useState([]);
	const [listDetails, setListDetails] = useState([]);
	const [familyHistoryResult, setFamilyHistoryResult] = useState([]);
	const [socialHistoryResult, setSocialHistoryResult] = useState([]);
	const [illnessHistoryResult, setIllnessHistoryResult] = useState([]);
	const [drugAllergyResult, setDrugAllergyResult] = useState("");
	const [allergyResult, setAllergyResult] = useState("");
	const [HospitalLogo, setHospitalLogo] = useState([]);
	const [pregnancyHistoryResult, setPregnancyHistoryResult] = useState([]);
	const [age, setAge] = useState('');
	const [hospitalCountryName, sethospitalCountryName] = useState('')
	const [hospitalpincode, sethospitalpincode] = useState('')
	const [hospitalStateName, sethospitalStateName] = useState('');
	const [gender, setGender] = useState('');
	const [date, setDate] = useState('');
	const [pmKeyData, setPmKeyData] = useState({});

	const [simplifiedPrint, setSimplifiedPrint] = useState([])
	const [isChecked4, setIsChecked4] = useState(false);
	const [isChecked5, setIsChecked5] = useState(false);
	const [isChecked7, setIsChecked7] = useState(false);
	const [isChecked8, setIsChecked8] = useState(false);

	const [isObservations, setObservation] = useState(false)
	const [isInvestigations, setInvestigation] = useState(false)
	const [isAssociatedComplaints, setAssociatedComplaint] = useState(false)
	const [isSystemReview, setSystemReview] = useState(false)
	const [isChiefPresentingComplaint, setChiefPresentingComplaint] = useState(false)
	const [isKnbownCase, setKnownCase] = useState(false)



	const [logoPath, setlogoPath] = useState(
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH
	);
	function styleValues() {
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			doctorId: ""
		};

		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {

				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);
				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);

			});

	}
	const simplifiedListing = () => {
		const postdata = {

			"doctorId": window.sessionStorage.getItem('userId'),
			"role": window.sessionStorage.getItem('role'),
			"hospitalId": localStorage.getItem('hospitalId'),

		};
		callingAPI('commonUserFunctions/simplifiedSettingsList', postdata).then((res) => {
			setObservation(res.data.result.main[0].sudArray[0].isActive)
			setInvestigation(res.data.result.main[0].sudArray[1].isActive)
			setAssociatedComplaint(res.data.result.main[0].sudArray[2].isActive)
			setSystemReview(res.data.result.main[0].sudArray[3].isActive)
			setChiefPresentingComplaint(res.data.result.main[0].sudArray[4].isActive)
			setKnownCase(res.data.result.main[0].sudArray[5].isActive)




		});
	}
	useEffect(() => {
		simplifiedListing();
		styleValues()
		var a = localStorage.getItem('simplifiedPrint')
		if (a != null && a != 'null' && a != '') {
			var printData = JSON.parse(a);
			setSimplifiedPrint(printData)
			localStorage.removeItem('simplifiedPrint')
		}
		// else {
		//     window.location.href = "/";
		// }
	})

	const printSimplifiedForm = () => {
		const section4 = document.getElementById('section4');
		const section51 = document.getElementById('section5.1');
		const section52 = document.getElementById('section5.2');
		const section7 = document.getElementById('section7');
		const section8 = document.getElementById('section8');
		// Hide unchecked sections before printing

		if (isChecked4) {
			section4.style.display = 'none';
		}
		if (isChecked5) {
			section51.style.display = 'none';
			section52.style.display = 'none';
		}
		if (isChecked7) {
			section7.style.display = 'none';
		}
		if (isChecked8) {
			section8.style.display = 'none';
		}
		document.getElementById('printButton').style.display = 'none';
		window.print();
		document.getElementById('printButton').style.display = 'block';

		section4.style.display = '';
		section51.style.display = '';
		section52.style.display = '';
		section7.style.display = '';
		section8.style.display = '';
	};
	if (pagesizeid == '1') {
		var a4sizewidth = '595px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '595px'
		var a4sizeheight = '842px'
	}
	return (
		<>
			{simplifiedPrint.map((hospDetails, i) => (
				<div class="caseSheetPrintWrap  PrintSimpleForm"
					align="center" style={{
						fontFamily: 'SourceSansPro-Regular', width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
						height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
						marginLeft: '0px',
						marginright: '0px',
						marginTop: '0px',
						marginBottom: '0px', margin: '0 auto'
					}}>
					<div
						align="center"

					>
						<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0" className='caseSheetDentalPrintTble'>
							<thead>
								<HeaderPrint hospitalDataId={printId.toString().split('|')[0]} setIsChecked4={setIsChecked4} isChecked4={isChecked4} setIsChecked5={setIsChecked5} isChecked5={isChecked5} />
								<tr>
									<th style={{ fontWeight: '400', color: '#000', fontFamily: 'SourceSansPro-Regular' }}>
										<div class="caseSheetTop">
											<div class="printOutHeaderSec ">

											</div>
											{/* {doctorDetails.map((item, i) => ( */}
											{/* {simplifiedPrint.map((hospDetails, i) => ( */}
											<div class="caseDoctor">
												<p>{hospDetails.doctorDetails.doctorDisplayName} {hospDetails.doctorDetails.education}</p>
												<p>Council Reg. No.</p>
											</div>

										</div>

										{/* <div class="patientDetails caseSheetpatientDetails">
						<div class="patientDetailItem">
							<div class="patientNAme">
								<span class="patientNAmeLeft">PATIENT'S NAME :</span>
								<span class="patientNAmeRight">{hospDetails.memberDetails[i].memberFirstName} . {hospDetails.memberDetails[i].memberLastName}</span>
							</div>
							<div class="patientAge">
								<span class="patientAgeLeft">AGE :</span>
								<span class="patientAgeRight">{hospDetails.memberDetails[i].memberAge}</span>
							</div>
							<div class="patientSex">
								<span class="patientSexLeft">SEX :</span>
								<span class="patientSexRight">{hospDetails.memberDetails[i].memberGender}</span>
							</div>
						</div>
						<div class="patientDetailItem">
							<div class="patientAddress">
								<span class="patientAddressLeft">ADDRESS :</span>
								<span class="patientAddressRight">{hospDetails.memberDetails[i].memberAddress}</span>
							</div>
						</div>
						<div class="patientDetailItem">
							<div class="patientCase">
								<span class="patientCaseLeft">CASE RECORD NO :</span>
								<span class="patientCaseRight">{hospDetails.memberDetails[i].memberMrNo}</span>
							</div>
							<div class="patientRef">
								<span class="patientRefLeft">Ref.by :</span>
								<span class="patientRefRight" />
							</div>
							<div class="patientDate">
								<span class="patientDateLeft">DATE :</span>
								<span class="patientDateRight">{hospDetails.resultData.currentDate}</span>
							</div>
						</div>
					</div> */}



										{/* {memberDetails.map((data, i) => (

									<div class="patientDetails caseSheetpatientDetails">

										<div class="patientDetailItem">
											<div class="patientNAme">
												<span class="patientNAmeLeft" style={{ width: 'auto' }}>PATIENT'S NAME : &nbsp;{data.memberFirstName} {data.memberLastName}</span>
												 
											</div>
											<div class="patientAge">
												<span class="patientAgeLeft">AGE :&nbsp;</span>
												{data.memberAge !== 0 ?
													<span class="patientAgeRight">{data.memberAge}</span> : null}
											</div>
											<div class="patientSex">
												<span class="patientSexLeft">SEX :</span>
												<span class="patientSexRight">&nbsp;{data.memberGender}</span>
											</div>
										</div>
										<div class="patientDetailItem">
											<div class="patientAddress">
												<span class="patientAddressLeft" style={{ width: 'auto' }}>ADDRESS :</span>
												<span class="patientAddressRight">
													{data.memberAddress} {data.memberCity} {data.memberState} {data.memberCountry} {data.memberZip}
												</span>
											</div>
										</div>
										<div class="patientDetailItem">
											<div class="patientCase">
												<span class="patientCaseLeft" style={{ width: 'auto' }}>CASE RECORD NO : {data.memberMrNo}</span>
												<span class="patientCaseRight"></span>
											</div>
											<div class="patientRef">
												<span class="patientRefLeft">Ref.by :</span>
												<span class="patientRefRight" />
											</div>
											<div class="patientDate">
												<span class="patientDateLeft">DATE : </span>
												<span class="patientDateRight">{date}</span>
											</div>
										</div>
									</div>
								))} */}
									</th>
								</tr>
							</thead>

							{/* --- */}

							{/* <div class="patientDetails">
						<div class="patientDetailItem">
							<div class="patientNAme">
								<span class="patientNAmeLeft">PATIENT'S NAME :</span>
								<span class="patientNAmeRight">{hospDetails.memberDetails[i].memberFirstName} . {hospDetails.memberDetails[i].memberLastName}</span>
							</div>
							<div class="patientAge">
								<span class="patientAgeLeft">AGE :</span>
								<span class="patientAgeRight">{hospDetails.memberDetails[i].memberAge}</span>
							</div>
							<div class="patientSex">
								<span class="patientSexLeft">SEX :</span>
								<span class="patientSexRight">{hospDetails.memberDetails[i].memberGender}</span>
							</div>
						</div>
						<div class="patientDetailItem">
							<div class="patientAddress">
								<span class="patientAddressLeft">ADDRESS :</span>
								<span class="patientAddressRight">{hospDetails.memberDetails[i].memberAddress}</span>
							</div>
						</div>
						<div class="patientDetailItem">
							<div class="patientCase">
								<span class="patientCaseLeft">CASE RECORD NO :</span>
								<span class="patientCaseRight">{hospDetails.memberDetails[i].memberMrNo}</span>
							</div>
							<div class="patientRef">
								<span class="patientRefLeft">Ref.by :</span>
								<span class="patientRefRight" />
							</div>
							<div class="patientDate">
								<span class="patientDateLeft">DATE :</span>
								<span class="patientDateRight">{hospDetails.resultData.currentDate}</span>
							</div>
						</div>
					</div> */}

							{/*  */}
							{/* <div class="patientComplaint casesheetpatientComplaint">
						<div class="patientComplaintItem">
							<span class="patientComplaintLabel">
								CHIEF COMPLAINT
							</span>
							<span class="patientComplainttXT">
								<span class="colan" style={{ float: "left" }}>:</span>
								<span > &nbsp;{listDetails.Chiefpresentingcomplainttag}</span>
							</span>

						</div>
						{listDetails.Chiefpresentingcomplaintdescription ?
							<div class="patientComplaintItem">
								<span class="patientComplaintLabel"> </span>
								<span class="patientComplainttXT">
									<span className='printDescription'><span><b style={{ float: "left" }}> Description&nbsp;:&nbsp;</b>
										{listDetails.Chiefpresentingcomplaintdescription}</span></span>
								</span>
							</div> : null}

						<div class="patientComplaintItem">
							<span class="patientComplaintLabel" >ASSOCIATED COMPLAINT</span>
							<span class="patientComplainttXT">
								<span class="colan" style={{ float: "left" }}>:</span>
								<span >&nbsp;{listDetails.Associatedcomplaintstag}</span>
							</span>
						</div>
						{listDetails.Associatedcomplaintsdescription ?
							<div class="patientComplaintItem">
								<span class="patientComplaintLabel"> </span>
								<span class="patientComplainttXT">
									<span className='printDescription'><span><b style={{ float: "left" }}> Description&nbsp;:&nbsp;</b>
										{listDetails.Associatedcomplaintsdescription}</span></span>
								</span>
							</div> : null}
					</div> */}

							{/* <div class="patientMedicalHistory casesheetpatientComplaint">
						<span class="medicalHeding">RELEVANT MEDICAL HISTORY</span>
						<div class="patientHistorySec">
							{illnessHistoryResult.length > 0 ? (
								<div>
									{illnessHistoryResult.map((data, i) => (
										<div class="patientDetailItem">
											<span class="patientMedical">Systemic Illness</span>
											<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
											<span class="patientMedicalTxt">
												 
												{data.illnessHistory}
												<span />
											</span>
										</div>))}
								</div>) : (<div class="patientDetailItem">
									<span class="patientMedical">Systemic Illness</span>
									<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
									<span class="patientMedicalTxt">
										 
										<span />
									</span>
								</div>)}
 							<div>
 								<div class="patientDetailItem">
									<span class="patientMedical">Drug History</span>
									<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
									<span class="patientMedicalTxt">
 										{drugAllergyResult}
										<span />
									</span>
								</div>
							</div>
							<div>
 								<div class="patientDetailItem">
									<span class="patientMedical">Allergy</span>
									<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
									<span class="patientMedicalTxt">
 										{allergyResult}
										<span />
									</span>
								</div>
							</div>
							 
							{age >= 15 && gender == 'Female' ? (
								<div>
									{pregnancyHistoryResult.map((data, i) => (
										<div class="patientDetailItem">
											<span class="patientMedical">Pregnancy</span>
											<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
											<span class="patientMedicalTxt">

												{data.pregnancyHistory}
												<span />
											</span>
										</div>))}
								</div>) : ('')}
							{familyHistoryResult.length > 0 ? (
								<div>
									{familyHistoryResult.map((data, i) => (
										<div class="patientDetailItem">
											<span class="patientMedical">Family History</span>
											<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
											<span class="patientMedicalTxt">

												{data.history}
												<span />
											</span>
										</div>))}
								</div>) : (<div class="patientDetailItem">
									<span class="patientMedical">Family History</span>
									<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
									<span class="patientMedicalTxt">
										<span />
									</span>
								</div>)}
							{socialHistoryResult.length > 0 ? (
								<div>
									{socialHistoryResult.map((data, i) => (
										<div class="patientDetailItem">
											<span class="patientMedical">Habits (Smoking etc)</span>
											<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
											<span class="patientMedicalTxt">

												{data.history}
												<span />
											</span>
										</div>))}
								</div>) : (<div class="patientDetailItem">
									<span class="patientMedical">Habits (Smoking etc)</span>
									<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
									<span class="patientMedicalTxt">

										<span />
									</span>
								</div>)}
						</div>
					</div> */}
							{/*  */}
							{/* ======================= */}

						</table>
					</div>
					{/* {memberDetails.map((data, i) => ( */}

					<div class="patientDetails  ">

						<div class="patientDetailItem">
							<div class="patientNAme">
								<span class="patientNAmeLeft" style={{ width: 'auto' }}>PATIENT'S NAME : &nbsp; {hospDetails.memberDetails[i].memberFirstName} . {hospDetails.memberDetails[i].memberLastName}</span>
								{/* <span class="patientNAmeRight">
				&nbsp;{data.memberFirstName} {data.memberLastName}
			</span> */}
							</div>
							<div class="patientAge">
								<span class="patientAgeLeft">AGE :&nbsp;</span>

								<span class="patientAgeRight">{hospDetails.memberDetails[i].memberAge}</span>
							</div>
							<div class="patientSex">
								<span class="patientSexLeft">SEX :</span>
								<span class="patientSexRight">&nbsp;{hospDetails.memberDetails[i].memberGender}</span>
							</div>
						</div>
						<div class="patientDetailItem">
							<div class="patientAddress">
								<span class="patientAddressLeft" style={{ width: 'auto' }}>ADDRESS :</span>
								<span class="patientAddressRight" style={{ textAlign: "left" }}>
									&nbsp;{hospDetails.memberDetails[i].memberAddress}
								</span>
							</div>
						</div>
						<div class="patientDetailItem" style={{ textAlign: "left" }}>
							<div class="patientCase">
								<span class="patientCaseLeft" style={{ width: 'auto' }}>CASE RECORD NO : </span>
								<span class="patientCaseRight">&nbsp;{hospDetails.memberDetails[i].memberMrNo}</span>
							</div>
							<div class="patientRef">
								<span class="patientRefLeft">Ref.by :</span>
								<span class="patientRefRight" />
							</div>
							<div class="patientDate">
								<span class="patientDateLeft">DATE : </span>
								<span class="patientDateRight">&nbsp; {moment(hospDetails.resultData.currentDate).format('DD-MM-YYYY')}</span>
							</div>
						</div>
					</div>
					{/* // ))} */}
					{hospDetails.resultData.casesheet.length > 0 ?
						<>
							<div class="patientComplaint">
								{isChiefPresentingComplaint == true &&
									<div class="patientComplaintItem">
										<span class="patientComplaintLabel"><b>CHIEF COMPLAINT </b>: </span>
										<span class="patientComplainttXT" style={{ textAlign: "left" }}>&nbsp;{hospDetails.resultData.casesheet[0].tagCheifComp}
											<span />
										</span>
									</div>
								}

								{isAssociatedComplaints == true &&
									<div class="patientComplaintItem">
										<span class="patientHistory"><b>ASSOCIATED COMPLAINT </b> : </span>
										<span class="patientHistoryTxt">&nbsp;{hospDetails.resultData.casesheet[0].tagAssocComp}
											<span />
										</span>
									</div>
								}
								{isKnbownCase == true &&
									<div class="patientComplaintItem">
										<span class="patientHistory"><b>KNOWN CASE </b>: </span>
										<span class="patientHistoryTxt">&nbsp;{hospDetails.resultData.casesheet[0].tagKnowCase}
											<span />
										</span>
									</div>
								}
							</div>
							{isObservations == true &&
								<div class="specialisationTwo">
									<span class="medicalTitle" style={{ textAlign: "left", width: "100%" }}><b>OBSERVATION</b> :&nbsp; {hospDetails.resultData.casesheet[0].txtObservations}</span>
									<span />
								</div>
							}
							<div class="specialisationTwo">
								<span class="medicalTitle" style={{ textAlign: "left", width: "100%" }}><b>DIAGNOSIS &amp; TREATMENT PLAN/ EXPLAINED OPTIONS </b>:&nbsp; {hospDetails.resultData.casesheet[0].txtDiagnosis}</span>
								{/* <span >{hospDetails.resultData.casesheet[0].txtDiagnosis} 	</span> */}
							</div>

							<div class="specialisationTwo">
								<span class="medicalTitle" style={{ textAlign: "left", width: "100%" }}><b>TREATMENT DONE</b> :&nbsp; {hospDetails.resultData.casesheet[0].txtTreatmentDone}</span>
								<span />
							</div>
						</>
						: hospDetails.resultData.casesheetMedical.length > 0 ?
							<>
								<div class="patientComplaint">
									{isChiefPresentingComplaint == true &&
										<div class="patientComplaintItem">
											<span class="patientComplaintLabel"><b>CHIEF COMPLAINT :</b> </span>
											<span class="patientComplainttXT" style={{ textAlign: "left" }}>&nbsp;{hospDetails.resultData.casesheetMedical[0].tag1}
												<span />
											</span>
										</div>
									}
									{isAssociatedComplaints == true &&
										<div class="patientComplaintItem">
											<span class="patientHistory"><b>ASSOCIATED COMPLAINT : </b></span>
											<span class="patientHistoryTxt">&nbsp;{hospDetails.resultData.casesheetMedical[0].tag2}
												<span />
											</span>
										</div>
									}
									{isKnbownCase == true &&
										<div class="patientComplaintItem">
											<span class="patientHistory"><b>KNOWN CASE : </b></span>
											<span class="patientHistoryTxt">&nbsp;{hospDetails.resultData.casesheetMedical[0].tag3}
												<span />
											</span>
										</div>
									}
								</div>
								{isInvestigations == true &&
									<div class="specialisationTwo">
										<span class="medicalTitle" style={{ textAlign: "left", width: "auto" }}><b>INVESTIGATION</b>:</span>
										<span style={{ textAlign: "left", width: "100%" }}>&nbsp; {hospDetails.resultData.casesheetMedical[0].txtInvestigations} 	</span>
									</div>
								}
								<div class="specialisationTwo">
									<span class="medicalTitle" style={{ textAlign: "left", width: "auto" }}><b>DIAGNOSIS &amp; TREATMENT PLAN/ EXPLAINED OPTIONS </b>:</span>
									<span style={{ textAlign: "left", width: "100%" }}>&nbsp; {hospDetails.resultData.casesheetMedical[0].diagnosisAndTreatmentPlan} 	</span>
								</div>
								<div class="specialisationTwo">
									<span class="medicalTitle" style={{ textAlign: "left", width: "auto" }}><b>TREATMENT DONE</b>:</span>
									<span style={{ textAlign: "left", width: "100%" }}>&nbsp; {hospDetails.resultData.casesheetMedical[0].txtTreatmentDone} 	</span>
								</div>
							</>
							: ""}

					<div>

						{hospDetails.resultData.medicineData ?
							<tbody>
								<tr>
									<td>
										<div class="printOutSettingContent">
											<table style={{ width: '100%' }}>
												<tbody>
													<tr>
														<td align="left" style={{ width: '100%' }}>
															Rx
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>

								<tr>
									<td>
										<div class="printOutSettingContent">
											<table style={{ width: '100%', borderBottom: '1px solid #d2d2d2' }}>
												<tbody>
													<tr style={{ borderBottom: '2px solid black' }}>
														<td
															class="col-md-3"
															style={{
																borderBottom: '2px solid black',
																textAlign: 'left',
																fontWeight: '500',
																fontSize: '12px',
																paddingBottom: '5px',
																width: '20%',
															}}
														>
															Medicine
														</td>
														<td
															class="col-md-3"
															style={{
																borderBottom: '2px solid black',
																textAlign: 'left',
																fontWeight: '500',
																fontSize: '12px',
																paddingBottom: '5px',
																width: ' 25%',
															}}
														>
															Frequency & Dosage
														</td>
														<td
															class="col-md-3"
															style={{
																borderBottom: '2px solid black',
																textAlign: 'left',
																fontWeight: '500',
																fontSize: '12px',
																paddingBottom: '5px',
																width: ' 15%',
															}}
														>
															Intake
														</td>
														<td
															class="col-md-3"
															style={{
																borderBottom: '2px solid black',
																textAlign: 'left',
																fontWeight: '500',
																fontSize: '12px',
																paddingBottom: '5px',
																width: ' 8%',
															}}
														>
															Qty
														</td>
														<td
															class="col-md-3"
															style={{
																borderBottom: '2px solid black',
																textAlign: 'left',
																fontWeight: '500',
																fontSize: '12px',
																paddingBottom: '5px',
																width: ' 8%',
															}}
														>
															Days
														</td>
														<td
															class="col-md-3"
															style={{
																borderBottom: '2px solid black',
																textAlign: 'left',
																fontWeight: '500',
																fontSize: '12px',
																paddingBottom: '5px',
																width: ' 14%',
															}}
														>
															Start Date
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>

								<tr>
									<td>

										<div class="printOutSettingContent">

											{hospDetails.resultData.medicineData.map((hospDetails1, i) => (
												<table style={{ width: '100%', borderBottom: '1px solid #d2d2d2' }}>
													<tbody>
														<tr>
															<td
																class="col-md-3"
																style={{
																	width: '20%',
																	margin: '0',
																	color: '#616161',
																	lineHeight: '15px',
																	wordBreak: 'break-word',
																	fontSize: '11px',
																}}
															>
																<p
																	class="sml_matter"
																	style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																>
																	{hospDetails1.medicineName}
																</p>
															</td>
															<td
																class="col-md-3"
																style={{
																	width: ' 25%',
																	margin: '0',
																	color: '#616161',
																	lineHeight: '15px',
																	wordBreak: 'break-word',
																	fontSize: '11px',
																}}
															>
																<p
																	class="sml_matter"
																	style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																>
																	{hospDetails1.frequency} - {hospDetails1.dosage}
																</p>
															</td>
															<td
																class="col-md-3"
																style={{
																	width: ' 15%',
																	margin: '0',
																	color: '#616161',
																	lineHeight: '15px',
																	wordBreak: 'break-word',
																	fontSize: '11px',
																}}
															>
																<p
																	class="sml_matter"
																	style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																>
																	{hospDetails1.intake}
																</p>
															</td>
															<td
																class="col-md-3"
																style={{
																	width: ' 8%',
																	margin: '0',
																	color: '#616161',
																	lineHeight: '15px',
																	wordBreak: 'break-word',
																	fontSize: '11px',
																}}
															>
																<p
																	class="sml_matter"
																	style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																>
																	{hospDetails1.quantity}
																</p>
															</td>
															<td
																class="col-md-3"
																style={{
																	width: ' 8%',
																	margin: '0',
																	color: '#616161',
																	lineHeight: '15px',
																	wordBreak: 'break-word',
																	fontSize: '11px',
																}}
															>
																<p
																	class="sml_matter"
																	style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																>
																	{hospDetails1.duration}
																</p>
															</td>
															<td
																class="col-md-3"
																style={{
																	width: ' 14%',
																	margin: '0',
																	color: '#616161',
																	lineHeight: '15px',
																	wordBreak: 'break-word',
																	fontSize: '11px',
																}}
															>
																<p
																	class="sml_matter"
																	style={{ margin: ' 3px 0', fontSize: '11px!important' }}
																>
																	{hospDetails1.startDate}
																</p>
															</td>
														</tr>
														<tr>
															<td
																colspan="6"
																style={{
																	textAlign: 'left',
																	fontSize: '11px',
																	lineHeight: '15px',
																	padding: ' 0px 2px 0px 0',
																	display: 'inline-block',
																	boxSizing: 'border-box',
																	color: '#393939',
																}}
															>
																<p
																	style={{
																		wordBreak: 'break-all',
																		margin: '3px 0',
																		fontSize: '11px!important',
																		wordBreak: ' break-word',
																	}}
																>
																	{' '}
																	<span style={{ fontWeight: '600' }}>Instructions:{hospDetails1.instruction}</span>{' '}
																</p>
															</td>
														</tr>
													</tbody>
												</table>
											))}
										</div>

									</td>
								</tr>
							</tbody>

							: ""}

						<div className="no-print form-group cus-checkbox-wrpr" style={{ textAlign: 'left' }}>
							<input checked={isChecked8} id='isChecked8' type="checkbox" onChange={(e) => setIsChecked8(e.target.checked)} title="Hide the consent text while printing." />
							<label for="isChecked8" title="Hide the consent text while printing."></label>
						</div>
						<div class="specialisationConsent" id='section8'>
							<span class="medicalTitle">CONSENT :</span>
							<span>
								I the understand in my full sense give my consent for the above procedure explained and advised
								to me in this clinic. The doctor has explained the risk involved in this procedure and satisfied
								my queries.
							</span>
							<div class="specialisationNames">
								<span>Name :{hospDetails.memberDetails[i].memberFirstName}</span>
								<span>Signature :</span>
							</div>
						</div>

					</div>
					{/* =================================== */}

					<span className='FooterPrintCasesheet' style={{ textAlign: 'left' }}>
						<FooterPrint setIsChecked7={setIsChecked7} isChecked7={isChecked7} /></span>
					<div class="printBtm" style={{ textAlign: 'center' }}>
						<p
							id="printButton"
							style={{
								width: '70px',
								textAlign: 'center',
								paddingTop: '5px',
								display: 'inline-block',
								fontWeight: 'bold',
								fontSize: '14px',
								color: 'white',
								background: 'rebeccapurple none repeat scroll 0% 0%',
								marginTop: '2px',
								cursor: 'pointer',
								borderRadius: '5px',
								marginBottom: '2px',
								height: '25px',
								fontFamily: '  Verdana',
								lineHeight: '15px',
							}}
							onClick={printSimplifiedForm}
						>
							Print
						</p>
					</div>
				</div>
			))}
		</>
	);
}
export default SimplifiedFormPrintNew;
