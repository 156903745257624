import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import Pagination from 'react-js-pagination';
import Datepicker, { CalendarContainer } from 'react-datepicker';
import NoData from '../Common/noDataFound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Common/loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Pendingpaymentdetails() {
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [functionName] = useState('Pendingpaymentdetails');
	const [totalPayment, settotalPayment] = useState('');
	const [pendingPayment, setPendingPayment] = useState([]);
	const [totalCount, setTotalCount] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [searchValue, setSearchValue] = useState('');
	const [searchType, setSearchType] = useState(0);
	const [pageOffset, setPageOffset] = useState(0);
	const [isEnabled, setIsEnabled] = useState(true);
	const [dataFlag, setDataFlag] = useState(false);
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");

	const exportFunction = () => {
		if (pendingPayment.length === 0) {
			toast.error("No Data to Export");
		}
		else {
			const csvRow = [];
			var pendingPaymentArray = [['Patient%20Name', 'Invoice%20Number', 'Date', 'Balance%20Amount']];
			var arrayDetails = pendingPayment;
			const postData = {
				userId: window.sessionStorage.getItem("userId"),
				role: window.sessionStorage.getItem("role"),
				max: "",
				offset: 0,
				cmbSelect: searchType,
				txtInput: searchValue,
				fromDate: startDate ? formatDate(startDate) : '',
				toDate: endDate ? formatDate(endDate) : '',
			};
			callingAPI('reports/pendingPayment', postData).then((data) => {
				for (var item = 0; item < data.data.result.patientPaymentDetails.length; item++) {
					pendingPaymentArray.push([
						data.data.result.patientPaymentDetails[item].memberName.replace(/,/g, '/').replace(/ /g, '%20'),
						data.data.result.patientPaymentDetails[item].memberProedureInvoiceInvoiceNo,
						data.data.result.patientPaymentDetails[item].memberProedureInvoiceGeneratedOn.replace(/,/g, '%20').replace(/ /g, '%20'),
						"Rs." + "" + data.data.result.patientPaymentDetails[item].memberProedureInvoiceTotalBalanceAmount.replace(/,/g, ''),
					]);
				}
				for (var i = 0; i < pendingPaymentArray.length; ++i) {
					csvRow.push(pendingPaymentArray[i].join('|'));
				}
				var csvString = csvRow.join('%0A');
				var a = document.createElement('a');
				a.href = 'data:attachment/csv, ' + csvString;
				a.target = '_Blank';
				a.download = functionName + '.csv';
				document.body.appendChild(a);
				a.click();
			})
		}
	};
	const handlePagination = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset)
		if (!boolianfilterName) { setSearchType("") }
		if (!booliansearchValue) { setSearchValue("") }
		if (!boolianfromDate) { setStartDate("") }
		if (!booliantoDate) { setEndDate("") }
		let postData = {
			userId: window.sessionStorage.getItem("userId"),
			role: window.sessionStorage.getItem("role"),
			max: max,
			offset: offset,
			cmbSelect: boolianfilterName,
			txtInput: booliansearchValue,
			fromDate: boolianfromDate ? formatDate(startDate) : '',
			toDate: booliantoDate ? formatDate(endDate) : '',
		};
		callingAPI('reports/pendingPayment', postData).then((data) => {
			if (data.data.success === '1') {
				setPendingPayment(data.data.result.patientPaymentDetails);
				setTotalCount(data.data.result.count);
				settotalPayment(data.data.result.totalPatientPayment);
			}
		});
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	const searchTypeOnChange = (event) => {
		if (event.target.value == 0) {
			setIsEnabled(true);
			setSearchType(event.target.value);
			setSearchValue('');
		} else {
			setSearchType(event.target.value);
			setIsEnabled(false);
		}
	};
	const searchValueFunction = (event) => {
		setSearchValue(event.target.value);
	};
	const searchClickFunction = () => {
		if (startDate && endDate) {
			if (startDate > endDate) {
				toast.error('End date must be greater than or equal to start date ');
				document.getElementById("todate").focus();
				return false;
			}
		}
		if (searchType !== 0 && searchValue.split('').length === 0) {
			if (searchType === '1') {
				toast.error("Enter Patient Name")
				return false;
			}
			else if (searchType === '2') {
				toast.error("Enter Invoice Number")
				return false;
			}
		}
		setActivePage(1)
		setbooliansearchValue(searchValue);
		setboolianfilterName(searchType);
		setboolianfromDate(startDate);
		setbooliantoDate(endDate);
		let postData = {
			userId: window.sessionStorage.getItem("userId"),
			role: window.sessionStorage.getItem("role"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: 0,
			cmbSelect: searchType,
			txtInput: searchValue,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
		};
		callingAPI('reports/pendingPayment', postData)
			.then((response) => {
				if (response.data.success === '0') {
					setPendingPayment([]);
					setTotalCount(0)
					settotalPayment(0)

				}
				if (response.data.success === '1') {
					setPendingPayment(response.data.result.patientPaymentDetails);
					setTotalCount(response.data.result.count);
					settotalPayment(response.data.result.totalPatientPayment);
				}
			})
			.catch();
	};
	useEffect(() => {
		const postData = {
			userId: window.sessionStorage.getItem("userId"),
			role: window.sessionStorage.getItem("role"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: pageOffset,
		};
		callingAPI('reports/pendingPayment', postData).then((data) => {
			setPendingPayment(data.data.result.patientPaymentDetails);
			setTotalCount(data.data.result.count);
			settotalPayment(data.data.result.totalPatientPayment);
			setDataFlag(true);
		});
	}, []);

	const FromDateClear = () => {
		setStartDate("")
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>

				</div>
			</CalendarContainer>
		);
	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	const ToDateClear = () => {
		setEndDate("")
	};

	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	return (
		<div className="dashboardCover">
			<ToastContainer position="bottom-right" />
			<div className="dashboardTitle">Pending Payment Details</div>
			{totalCount > 10 && (
				<div className="paginationSection">
					<Pagination
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={totalCount}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePagination}
					/>
				</div>
			)}
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select
									className="inputField"
									name="selectName"
									value={searchType}
									onChange={searchTypeOnChange}
								>
									<option value="0">Search In</option>
									<option value="1">Patient Name</option>
									<option value="2">Invoice Number</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								{searchType == "0" ?
									<input
										type="text"
										name="searchText"
										className="inputField"
										value={searchValue}
										disabled={isEnabled}
										style={{ background: "transparent" }}
										// onChange={searchValueFunction}
										autoComplete='off'
									/>
									:
									<input
										type="text"
										name="searchText"
										className="inputField"
										value={searchValue}
										onChange={searchValueFunction}
										autoComplete='off'
									/>
								}
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div>
									<Datepicker
										required
										className="Datepicker pa2 inputField"
										placeholderText="From"
										calendarClassName="rasta-stripes"
										selected={startDate}
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setStartDate(date)}
										id="fromdate" calendarContainer={MyContainer}
										onChangeRaw={handleDateChangeRaw}
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="fromdate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div>
									<Datepicker
										required
										selected={endDate}
										className="Datepicker pa2 inputField"
										placeholderText="To"
										calendarClassName="rasta-stripes"
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setEndDate(date)}
										id="todate" calendarContainer={MyContainerTo}
										onChangeRaw={handleDateChangeRaw}
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="todate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span className="dashButtonBorder" onClick={searchClickFunction}>
									Search
								</span>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span onClick={exportFunction} className="dashButtonBorder dashButtonBorderGrey">
									Export
								</span>
							</div>
						</div>
						<div className="row">
							<div className="col-md-9">
								<div className="searchTxt" style={{ textAlign: 'right' }}>
									<b>Total Balance Amount : &#8377; {pendingPayment.length == 0 ? 0 : totalPayment}</b>
								</div>
							</div>
							<div className="col-md-3">
								<div className="searchCount">
									<b>Total pending payment : {pendingPayment.length == 0 ? 0 : totalCount}</b>
								</div>
							</div>
						</div>
					</div>
					{dataFlag == false ?
						<Loader />
						: pendingPayment.length > 0 ?
							<div className="dashboardtable">
								<table>
									<thead>
										<tr>
											<th>Patient Name</th>
											<th>Invoice Number </th>
											<th>Date</th>
											<th style={{ textAlign: 'right' }}>Balance Amount</th>
										</tr>
									</thead>
									<tbody>
										{pendingPayment.map((item, index) => (
											<tr>
												<td> {item.memberName}</td>
												<td>{item.memberProedureInvoiceInvoiceNo}</td>
												<td>{item.memberProedureInvoiceGeneratedOn}</td>
												<td style={{ textAlign: 'right' }}>
													&#8377; {item.memberProedureInvoiceTotalBalanceAmount}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div> : <p><NoData /></p>}
				</div>
				{totalCount > 10 && (
					<div className="paginationSection">
						<Pagination
							activePage={activePage}
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={totalCount}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={handlePagination}
						/>
					</div>
				)}
				{/* {pendingPayment.length == 0 && <NoData />} */}
			</div>
		</div>
	);
}
export default Pendingpaymentdetails;
