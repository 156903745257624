import React, { useState, useEffect } from 'react';
import Datepicker, { CalendarContainer } from 'react-datepicker';
import Pagination from 'react-js-pagination';
import { callingAPI } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function SupplierInvoice() {
	const csvDownload = 'supplier invoice details';
	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState();
	const [count, setCount] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [supplierinvoice, setSupplierInvoice] = useState([]);
	const [searchValue, setsearchValue] = useState('');
	const [filterName, setFilterInput] = useState('');
	const [grandTotal, setGrandTotal] = useState('0');
	const [lastpagevalue, setlastpagevalue] = useState();
	const [grandTotalTax, setGrandTotalTax] = useState(0);
	const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
	const [TotalInvoiceAmountWithTax, setTotalInvoiceAmountWithTax] = useState(0);
	const [dataFlag, setDataFlag] = useState(false);
	const [pageOffset, setPageOffset] = useState(0);
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");
	const [result, setResult] = useState([]);

	//   const [exportData, setExportData] = useState("");
	const searchValueChange = (value) => {
		setsearchValue(value);
	};

	const dataPagination = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === "1") {
			offset = "0";
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset =
				parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) { setFilterInput("") }
		if (!booliansearchValue) { setsearchValue("") }
		if (!boolianfromDate) { setFromDate("") }
		if (!booliantoDate) { setToDate("") }
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: max.toString(),
			offset: offset.toString(),
			searchValue: booliansearchValue,
			searchId: boolianfilterName,
			fromDate: boolianfromDate ? formatDate(fromDate) : '',
			toDate: booliantoDate ? formatDate(toDate) : '',
		};
		setDataFlag(false);
		callingAPI('adminReports/supplierInvoice', PostData)
			.then((response) => {
				if (response.data.success === '1') {
					setSupplierInvoice(response.data.result.supplierdetailsnew);
					setCount(response.data.result.TotalCount);
					setGrandTotal(response.data.result.grandtotal);
					setGrandTotalTax(response.data.result.grandtotaltax);
					setResult(response.data.result);
					setTotalInvoiceAmount(response.data.result.totalInvoiceAmount.toFixed(2));
					setTotalInvoiceAmountWithTax(response.data.result.totalinvoiceamnttax.toFixed(2));
					setDataFlag(true);
				}
				if (response.data.success === '0') {
					setSupplierInvoice([]);
					setCount(0);
					setGrandTotal('0')
					setGrandTotalTax('0')
					setTotalInvoiceAmount('0')
					setTotalInvoiceAmountWithTax('0')
					setDataFlag(true);
				}

			})
			.catch();
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}
	const changeTextonTextArea = (value) => {
		setFilterInput(value);
		setsearchValue('');
		if (value === '') {
			setsearchValue('');
			document.getElementById('searchText').disabled = true;
		} else {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		}
	};
	const searchValueOnChangeOnSelect = () => {
		if (document.getElementById('selectName').value !== '' && document.getElementById('searchText').value === '') {
			var t = document.getElementById('selectName');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('selectName').focus();
			toast.error(selectedText + ' required');
			return false;
		}
		setbooliansearchValue(searchValue);
		setboolianfilterName(filterName);
		setboolianfromDate(fromDate);
		setbooliantoDate(toDate);
		if (fromDate && toDate) {
			if (fromDate > toDate) {
				toast.error('End date must be greater than start date');
				document.getElementById("todate").focus()
				return (false);
			}
		}
		setActivePage(1);
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			offset: '0',
			max: process.env.REACT_APP_PAGINATION_COUNT,
			searchId: filterName,
			searchValue: searchValue,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
		};
		callingAPI('adminReports/supplierInvoice', PostData)
			.then((response) => {
				if (response.data.success === '1') {
					setSupplierInvoice(response.data.result.supplierdetailsnew);
					setCount(response.data.result.TotalCount);
					setGrandTotal(response.data.result.grandtotal);
					setGrandTotalTax(response.data.result.grandtotaltax);
					setResult(response.data.result);
					setTotalInvoiceAmount(response.data.result.totalInvoiceAmount.toFixed(2));
					setTotalInvoiceAmountWithTax(response.data.result.totalinvoiceamnttax.toFixed(2));
					setDataFlag(true);
				}
				if (response.data.success === '0') {
					setSupplierInvoice([]);
					setCount(0);
					setGrandTotal('0')
					setGrandTotalTax('0')
					setTotalInvoiceAmount('0')
					setTotalInvoiceAmountWithTax('0')
					setDataFlag(true);
				}

			})
			.catch();
	};
	useEffect(() => {



		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			offset: pageOffset.toString(),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			searchId: filterName,
			searchValue: searchValue,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
		};

		callingAPI('adminReports/supplierInvoice', PostData)
			.then((response) => {
				if (response.data.success === '0') {
					setSupplierInvoice([]);
					setCount(0);
					setDataFlag(true);
				}
				if (response.data.success === '1') {
					setDataFlag(true);
					setSupplierInvoice(response.data.result.supplierdetailsnew);
					setCount(response.data.result.TotalCount);
					setGrandTotal(response.data.result.grandtotal);
					setGrandTotalTax(response.data.result.grandtotaltax);
					setResult(response.data.result);
					setTotalInvoiceAmount(response.data.result.totalInvoiceAmount.toFixed(2));
					setTotalInvoiceAmountWithTax(response.data.result.totalinvoiceamnttax.toFixed(2));
				}
			})
			.catch();
	}, []);
	function csvFileDownload() {
		if (supplierinvoice.length === 0) {
			toast.error("No Data to Export");
		}
		else {
			const csvRow = [];
			var supplierinvoiceList = [
				[
					'Invoice%20No',
					'Supplier',
					'Date',
					'Category',
					'Item	',
					'HSN',
					'Qty',
					'Amount',
					'Tax',
					'Invoice%20Amount',
					'Invoice%20Amount%20with%20Tax',
					'Status',
				],
			];
			var name = supplierinvoice;
			let PostData = {
				hospitalId: localStorage.getItem("hospitalId"),
				offset: '0',
				max: "",
				searchId: filterName,
				searchValue: searchValue,
				fromDate: fromDate ? formatDate(fromDate) : '',
				toDate: toDate ? formatDate(toDate) : '',
			};
			callingAPI('adminReports/supplierInvoice', PostData)
				.then((response) => {

					for (var item = 0; item < response.data.result.supplierdetailsnew.length; item++) {
						supplierinvoiceList.push([
							response.data.result.supplierdetailsnew[item].invoiceNo.includes(' ') === true ? response.data.result.supplierdetailsnew[item].invoiceNo.replace(/ /g, '%20') : response.data.result.supplierdetailsnew[item].invoiceNo,
							response.data.result.supplierdetailsnew[item].supplierName.includes(' ') === true ? response.data.result.supplierdetailsnew[item].supplierName.replace(/ /g, '%20') : response.data.result.supplierdetailsnew[item].supplierName,
							response.data.result.supplierdetailsnew[item].dateValue.replace(/,/g, '').replace(/ /g, '%20'),
							response.data.result.supplierdetailsnew[item].category,
							response.data.result.supplierdetailsnew[item].item,
							response.data.result.supplierdetailsnew[item].hsn,
							response.data.result.supplierdetailsnew[item].quantity,
							"Rs." + "" + response.data.result.supplierdetailsnew[item].invoiceamnt,
							"Rs." + "" + response.data.result.supplierdetailsnew[item].tax,
							"Rs." + "" + response.data.result.supplierdetailsnew[item].invoiceamnt,
							"Rs." + "" + response.data.result.supplierdetailsnew[item].invoiceamnttax,
							response.data.result.supplierdetailsnew[item].status,
						]);
					}
					for (var i = 0; i < supplierinvoiceList.length; ++i) {
						csvRow.push(supplierinvoiceList[i].join(','));
					}
					var csvString = csvRow.join('%0A');
					var create = document.createElement('a');
					create.href = 'data:attachment/csv,' + csvString;
					// create.target = '_Blank';
					create.download = csvDownload + '.csv';
					document.body.appendChild(create);
					create.click();
				}
				)
		}
	}

	const FromDateClear = () => {
		setFromDate("")
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>

				</div>
			</CalendarContainer>
		);
	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	const ToDateClear = () => {
		setToDate("")
	};

	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	return (
		<div className="dashboardCover">
			<ToastContainer position="bottom-right" />
			<div className="dashboardTitle">Supplier Invoice</div>
			{count > 10 && (
				<div className="paginationSection">
					<Pagination

						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={dataPagination}
					/>



				</div>
			)}
			<div>
				<div className="dashboardContent supplierInvoiceWrap">
					<div className="reportWrap">
						<div className="dashboardSearch">
							<div className="row">
								<div className="col-md-2 dashboardSearchList">
									<select
										className="inputField"
										id="selectName"
										name="selectName"
										value={filterName}
										onChange={(e) => changeTextonTextArea(e.target.value)}
									>
										<option value="">Search In</option>
										<option value="1">Supplier Name</option>
										<option value="2">Invoice Number</option>
									</select>
								</div>
								<div className="col-md-2 dashboardSearchList">
									{filterName == "" ? (
										<input
											type="text"
											className="inputField"
											value={searchValue}
											onChange={(e) => searchValueChange(e.target.value)}
											id="searchText"
											name="searchText"
											style={{ background: "transparent" }}
											disabled
										/>
									) : (
										<input
											type="text"
											className="inputField"
											value={searchValue}
											onChange={(e) => searchValueChange(e.target.value)}
											id="searchText"
											name="searchText"
											disabled
											autofocus="autofocus"
											style={{ background: "white" }}
										/>
									)}
								</div>
								{/* <div className="col-md-2 dashboardSearchList">
									<input
										type="text"
										className="inputField"
										value={searchValue}
										onChange={(e) => searchValueChange(e.target.value)}
										disabled
										id="searchText"
										name="searchText"
									/>
								</div> */}
								<div className="col-md-2 dashboardSearchList">
									{/* <Datepicker
                        required
                        className="Datepicker pa2 inputField"
                        maxDate={new Date()}
                        placeholderText="From"
                        calendarClassName="rasta-stripes"
                        selected={fromDate}
                        dateFormat="dd-MM-yyyy"
                        onChange={(date) => setFromDate(date)}
                      /> */}
									<div>
										<Datepicker
											autoComplete='off'
											required
											className="Datepicker pa2 inputField"
											// maxDate={new Date()}
											placeholderText="From Date"
											calendarClassName="rasta-stripes"
											selected={fromDate}
											dateFormat="dd-MM-yyyy"
											onChange={(date) => setFromDate(date)}
											id="fromdate"
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
											calendarContainer={MyContainer}
											onChangeRaw={handleDateChangeRaw}
										/>
										<label
											className="icon iconCalendar calendar plReportsCalendar"
											style={{ fontSize: '16px' }}
											htmlFor="fromdate"
										>
											<FontAwesomeIcon icon={faCalendarAlt} />
										</label>
									</div>
								</div>
								<div className="col-md-2 dashboardSearchList">
									{/* <Datepicker
                        required
                        className="Datepicker pa2 inputField"
                        selected={toDate}
                        dateFormat="dd-MM-yyyy"
                        onChange={(date) => setToDate(date)}
                        placeholderText="To"
                        calendarClassName="rasta-stripes"
                      /> */}
									<div>
										<Datepicker
											autoComplete='off'
											required
											className="Datepicker pa2 inputField"
											selected={toDate}
											dateFormat="dd-MM-yyyy"
											onChange={(date) => setToDate(date)}
											placeholderText="To Date"
											calendarClassName="rasta-stripes"
											id="todate"
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
											calendarContainer={MyContainerTo}
											onChangeRaw={handleDateChangeRaw}
										/>
										<label
											className="icon iconCalendar calendar plReportsCalendar"
											style={{ fontSize: '16px' }}
											htmlFor="todate"
										>
											<FontAwesomeIcon icon={faCalendarAlt} />
										</label>
									</div>
								</div>
								<div className="col-md-2 dashboardSearchList">
									<span onClick={searchValueOnChangeOnSelect} className="dashButtonBorder">
										Search
									</span>
								</div>
								<div className="col-md-2 dashboardSearchList">
									<span onClick={csvFileDownload} className="dashButtonBorder dashButtonBorderGrey">
										Export
									</span>
								</div>
							</div>
							{/* {result.length == 0 ? 0 : ( */}

							<div className="row">
								<div className="col-md-4">

									<div className="searchTxt">Total Amount : &#8377; {grandTotal}</div>

									<div className="searchTxt">Total Tax : &#8377; {parseFloat(grandTotalTax).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</div>

								</div>
								<div className="col-md-4">
									<div className="searchTxt">
										<div className="searchTxt">Total Invoice Amount : &#8377; {parseFloat(totalInvoiceAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</div>

										Total Invoice Amount With Tax : &#8377; {parseFloat(TotalInvoiceAmountWithTax).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
									</div>
								</div>

								<div className="col-md-4">
									<div className="searchCount">
										<b>Total Invoice : {count}</b>
									</div>
								</div>
							</div>
							{/* )} */}
						</div>
						{dataFlag == false ? (
							<Loader />
						) : supplierinvoice.length > 0 ? (
							<div className="dashboardtable">
								<table>
									<thead>
										<tr>
											<th>Invoice No</th>
											<th>Supplier </th>
											<th style={{ width: '95px' }}>Date</th>
											<th>Category</th>
											<th>Item</th>
											<th>HSN</th>
											<th>Qty</th>
											<th style={{ textAlign: 'right' }}>Amount</th>
											<th style={{ textAlign: 'right', width: '80px' }}>Tax</th>
											<th style={{ textAlign: 'right' }}>Invoice Amount</th>
											<th style={{ textAlign: 'right' }}>Invoice Amount with Tax</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>
										{supplierinvoice.map((data, i) => (
											<tr>
												<td>{data.invoiceNo}</td>
												<td>{data.supplierName}</td>
												<td>{data.dateValue}</td>
												<td>{data.category}</td>
												<td>{data.item}</td>
												<td>{data.hsn}</td>
												<td>{data.quantity}</td>
												<td style={{ textAlign: 'right' }}>&#8377; {data.amount} </td>
												<td style={{ textAlign: 'right' }}>&#8377; {data.tax} </td>
												<td style={{ textAlign: 'right' }}>&#8377; {data.invoiceamnt}</td>
												<td style={{ textAlign: 'right' }}>&#8377; {data.invoiceamnttax} </td>
												<td>{data.status}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<p>
								<NoData />
							</p>
						)}
					</div>
					{count > 10 && (
						<div className="paginationSection">

							<Pagination
								hideDisabled
								activePage={activePage}
								itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
								totalItemsCount={count}
								pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
								onChange={dataPagination}
							/>
						</div>
					)}
				</div>

				{/* {supplierinvoice.length === 0 &&
					dataFlag === true &&
						<div align="center">
							<NoData/>
						</div>
					} */}
			</div>
		</div>
	);
}

export default SupplierInvoice;
