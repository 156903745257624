import React, { useState, useEffect } from 'react';
import NoData from '../Common/noDataFound';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import LabHeader from '../labLogin/layout/header';
import Footer from '../labLogin/layout/footer';
import { Route } from 'react-router-dom';
import LabLeft from '../labLogin/labLeft'
import Datepicker, { CalendarContainer } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";
import Eyeicon from '../images/icons/eye.png';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import eyeTable from '../images/icons/lab/eyeTable.png';
function OutsideLabOrderDetails() {
	const navigate = useNavigate();

	const [labDetailsResult, setLabDetailsResult] = useState([]);
	const [functionName, setFunctionName] = useState('Outside Lab Order Details');
	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState();
	const [totalCount, setTotalCount] = useState();
	const [activePage, setActivePage] = useState(1);
	const [pageOffset, setPageOffset] = useState(0);
	const [isData, setIsData] = useState(0);
	const [freeArticleData123, setFreeArticleData123] = useState([]);
	const [freeArticleData1, setFreeArticleData1] = useState([]);
	const [getServiceProviderId, setServiceProviderId] = useState('');
	const [doctorId, setDoctorId] = useState(window.sessionStorage.getItem('patientDoctorId'));
	const [doctorName, setDoctor] = useState(window.sessionStorage.getItem('patientDoctorName'));
	const [doctorList, setDoctorList] = useState([]);
	const [getHidDocId, setHidDocId] = useState(
		window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? window.sessionStorage.getItem('userId') : ''
	);
	const [getSelectDoctor, setSelectDoctor] = useState(
		window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
			? window.sessionStorage.getItem('userName')
			// +'(' +
			// window.sessionStorage.getItem('specializationName') +
			// ')'
			: ''
	);
	const handlePagination = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem("role"),
			max: max,
			offset: offset,
			pharmacy: document.getElementById('freeArticleData1').value,
			doctor: getSelectDoctor,
			hospitalId: window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
				? localStorage.getItem("hospitalId")
				: ''
		};
		callingAPI('clinicalBackground/OutsideLab', PostData)
			.then((data) => {
				setLabDetailsResult(data.data.result.labDetailsResult);
				setTotalCount(data.data.result.totalCount);
				setIsData(1);
			})
			.catch();
	};
	function formatDate(date) {
		var date = new Date(date),
			month = '' + (date.getMonth() + 1),
			day = '' + date.getDate(),
			year = date.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	const searchFunction = () => {
		// setIsData(0);
		if (fromDate && toDate) {
			if (fromDate > toDate) {
				toast.error('End date must be greater than start date');
				document.getElementById("todate").focus()
				return (false);
			}
		}
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem("role"),
			max: "10",
			offset: 0,
			pharmacy: document.getElementById('freeArticleData1').value,
			doctor: getSelectDoctor,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
			hospitalId: window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
				? localStorage.getItem("hospitalId")
				: ''
		};
		callingAPI('clinicalBackground/OutsideLab', PostData)
			.then((data) => {
				if (data.data.success === '0') {
					setLabDetailsResult([]);
					setIsData(1);
				}
				if (data.data.success === '1') {
					setLabDetailsResult(data.data.result.labDetailsResult);
					setTotalCount(data.data.result.totalCount);
					setIsData(1);
				}
			})
			.catch();
	};
	useEffect(() => {
		const PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem("role"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: pageOffset,
			pharmacy: document.getElementById('freeArticleData1').value,
			doctor: getSelectDoctor,
			hospitalId: window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
				? localStorage.getItem("hospitalId")
				: ''
		};
		callingAPI('clinicalBackground/OutsideLab', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setLabDetailsResult(data.data.result.labDetailsResult);
					setTotalCount(data.data.result.totalCount);
					setIsData(1);
				}
				else {
					setIsData(1)
					setLabDetailsResult([])
					setTotalCount(0);
				}
			})
			.catch();
	}, []);
	function csvFileDownload() {
		if (labDetailsResult.length === 0) {
			toast.error("No Data to Export");
		}
		else {
			const csvRow = [];
			var fileDownloadArray = [
				['Patient', 'Lab%20Name', 'Doctor%20Name', 'Lab%20Order%20Date']];
			var arrayDetails = labDetailsResult;
			let PostData = {
				userId: window.sessionStorage.getItem('userId'),
				role: window.sessionStorage.getItem("role"),
				max: "",
				offset: "",
				pharmacy: document.getElementById('freeArticleData1').value,
				doctor: getSelectDoctor,
				fromDate: fromDate ? formatDate(fromDate) : '',
				toDate: toDate ? formatDate(toDate) : '',
				hospitalId: window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
					? localStorage.getItem("hospitalId")
					: ''
			};
			callingAPI('clinicalBackground/OutsideLab', PostData)
				.then((response) => {
					// if (response.data.success === '0'    ) {
					// 	alert("No Data to Export")
					//   }
					if (response.data.success === '1') {
						for (var j = 0; j < response.data.result.labDetailsResult.length; j++) {
							fileDownloadArray.push([
								response.data.result.labDetailsResult[j].patientname.replace(/ /g, "%20"),
								response.data.result.labDetailsResult[j].serviceprovidername.replace(/ /g, "%20"),
								response.data.result.labDetailsResult[j].doctorname.replace(/ /g, "%20"),
								response.data.result.labDetailsResult[j].createdDate.replace(/ /g, "%20").replace(/,/g, "%20"),
							]);
						}
						for (var i = 0; i < fileDownloadArray.length; ++i) {
							csvRow.push(fileDownloadArray[i].join('|'));
						}
						var csvString = csvRow.join("%0A");
						var a = document.createElement("a");
						a.href = "data:attachment/csv," + csvString;
						a.target = "_Blank";
						a.download = functionName + ".csv";
						document.body.appendChild(a);
						a.click();
					}

				})
		}
	}

	const SelectDoctor = (value) => {
		setSelectDoctor(value)
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
			selectCombo: '',
			inputSearch: document.getElementById('searchTextDr').value,
			max: 100,
			offset: 0,
		};
		document.getElementById('doctorListDiv').style.display = 'block';
		if (document.getElementById('searchTextDr').value.length > 2) {
			callingAPI('commonUserFunctions/doctorsList', apiJson).then((data) => {
				if (data.data.success === '1') {
					setDoctorList(data.data.result.doctorsListArray);
					// const filteredData1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
				}
				else {
					toast.error("no doctors found")
				}
			});
		}
		else {
			document.getElementById('doctorListDiv').style.display = 'none'
			setDoctorList([])
		}
	};
	const setDoctorName = (doctorObj, i) => {
		setSelectDoctor(doctorObj.doctorName)
		setDoctor(doctorObj.doctorName);
		setDoctorId(doctorObj.doctorId);
		document.getElementById('doctorListDiv').style.display = 'none';
	};
	const onBlurItemCategory = (e) => {
		if (!["react-datepicker__month-select", "react-datepicker__year-select"].includes(String(e.target.className))) {
			document.getElementById('doctorListDiv').style.display = 'none';
			document.getElementById('locationSearch1').style.display = 'none';
			setDoctorList([]);
			setFreeArticleData1([]);
		}
	};
	// const getDoctorList = () => {
	// 	const postData = {
	// 		role: 'ROLE_HOSPITALADMIN',
	// 		userId: window.sessionStorage.getItem('userId'),
	// 		hospitalId: localStorage.getItem("hospitalId"),
	// 		doctorName: $('#doctorName').val(),
	// 	};
	// 	callingAPI('calenderListing', postData)
	// 		.then((res) => {
	// 			allDoc = true;
	// 			let docArray = res.data.result.map((item) => item.doctorId)
	// 			doctor = docArray
	// 			docDataSet = res.data.result
	// 			setDocHosListList(res.data.result);
	// 			if (dataFlag == true) {
	// 				appointmentDataSet();
	// 			}
	// 		})
	// };
	const orderAt = (mode, dropdownId) => {
		const apiJson = {
			keyWord: document.getElementById(mode).value,
			hospitalId: localStorage.getItem("hospitalId")
		};
		callingAPI('adminReports/getLabDetails', apiJson).then((data) => {
			if (data.data.success === '1') {
				document.getElementById(dropdownId).style.display = 'block';
				setFreeArticleData1(data.data.autoLabData);
				document.addEventListener('mouseup', function (e) {
					var container = document.getElementById(dropdownId);
					if (container !== null) {
						if (!container.contains(e.target)) {
							container.style.display = 'none';
						}
					}
				});
			}
		});
	};
	const setFreeArticleData = (ServiceProviderId, itemName, mode, dropdownId) => {
		document.getElementById(mode).value = itemName;
		setFreeArticleData1([]);
		setServiceProviderId(ServiceProviderId);
	};
	const FromDateClear = () => {
		setFromDate("")
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>
				</div>
			</CalendarContainer>
		);
	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}
	const ToDateClear = () => {
		setToDate("")
	};
	const outsideLabOrderClick = (data) => {
		navigate('/labLogin/outsideLabOrderDetailsView', { state: data })
	}
	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	return (
		<div className="coverWraper innerCoverWraper hospitalLabWraper hospitalLabOrder exportCalndrSec">
			<ToastContainer position="bottom-right" />
			<LabHeader />
			<div className="contentWraper contentWraperScroll">
				<LabLeft />
				<div className="settingsRight">
					<div className="dashboardCover pharmacyManufactureWrap">
						<div className="row">
							<div className="col-md-10">
								<div className="dashboardTitle">Outside Lab Order Details</div>
							</div>
						</div>
						<div className="paginationSection">
							{totalCount > 10 && (
								<Pagination
									hideNavigation
									activePage={activePage}
									itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
									totalItemsCount={totalCount}
									pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
									onChange={handlePagination}
								/>
							)}
						</div>
						<div className="dashboardContent">
							<div className="reportWrap">
								<div className="dashboardSearch" onClick={onBlurItemCategory}>
									<div className="row">
										<div className="col-md-2 dashboardSearchList ">
											<div className="appointmentFormList">
												<input
													type="text"
													value={getSelectDoctor}
													id="searchTextDr"
													name="searchTextDr"
													autoComplete="off"
													class="ui-autocomplete-input inputField"
													placeholder="Select Doctor"
													// onClick={doctorDropdown}
													onChange={(e) =>
														SelectDoctor(
															e.target.value
														)}
												/>
												{/* const onBlur = {() => {
															setTimeout(() => {
																setDoctorName([])
																},100);
															}} */}
												<div>
													<ul
														className="autoSearch1 autoSearch-p0 appointmentNumberSearch bgBlueAutosearch"
														id="doctorListDiv"
														style={{ display: 'none' }}
													>
														{doctorList.map((doctorObj, i) => (
															<ul>
																<li onClick={(e) => setDoctorName(doctorObj, i)}>
																	<span id={'doctorNameLabel' + i}>
																		{doctorObj.doctorName}
																	</span>
																</li>
															</ul>
														))}
													</ul>
												</div>
											</div>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<ul>
												<li class="col-md-12 col-lg-12 PaddingGridFix12 labOrderTestEditingOrderAtDetails_PTour">
													<input
														type="text"
														id="freeArticleData1"
														name="txtLabName"
														tabindex="6"
														autocomplete="off"
														placeholder="Select Lab"
														maxlength="100"
														class="ui-autocomplete-input inputField"
														onClick={() =>
															orderAt(
																'freeArticleData1',
																'locationSearch1'
															)}
														style={{ height: '34px' }}
														onKeyUp={() =>
															orderAt(
																'freeArticleData1',
																'locationSearch1'
															)}
													/>
													<ul
														className="autoSearch1 autoSearch-p0 appointmentNumberSearch bgBlueAutosearch"
														id="locationSearch1"
														style={{ display: 'none' }}
													>
														{freeArticleData1.map(
															(
																freeArticleDataObj,
																i
															) => (
																<li
																	onClick={() =>
																		setFreeArticleData(
																			freeArticleDataObj.ServiceProviderId,
																			freeArticleDataObj.labNameOnly,
																			'freeArticleData1',
																			'locationSearch1'
																		)}
																>
																	{
																		freeArticleDataObj.labNameOnly
																	}
																</li>
															)
														)}
													</ul>
												</li>
											</ul>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<div>
												<Datepicker
													autoComplete='off'
													required
													className="Datepicker pa2 inputField"
													calendarClassName="rasta-stripes"
													maxDate={new Date()}
													placeholderText="From Date"
													selected={fromDate}
													dateFormat="dd-MM-yyyy"
													onChange={(date) => setFromDate(date)}
													id="fromdate"
													peekNextMonth
													showMonthDropdown
													showYearDropdown
													calendarContainer={MyContainer}
													onChangeRaw={handleDateChangeRaw}
													dropdownMode="select"
													onKeyDown={(e) => {
														e.preventDefault();
													}}
												/>
												<label
													className="icon iconCalendar calendar plReportsCalendar"
													style={{ fontSize: '16px' }}
													htmlFor="fromdate"
												>
													<FontAwesomeIcon icon={faCalendarAlt} />
												</label>
											</div>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<div>
												<Datepicker
													autoComplete='off'
													required
													className="Datepicker pa2 inputField"
													placeholderText="To Date"
													maxDate={new Date()}
													calendarClassName="rasta-stripes"
													selected={toDate}
													dateFormat="dd-MM-yyyy"
													onChange={(date) => setToDate(date)}
													id="todate"
													peekNextMonth
													showMonthDropdown
													showYearDropdown
													calendarContainer={MyContainerTo}
													dropdownMode="select"
													onKeyDown={(e) => {
														e.preventDefault();
													}}
												/>
												<label
													className="icon iconCalendar calendar plReportsCalendar"
													style={{ fontSize: '16px' }}
													htmlFor="todate"
												>
													<FontAwesomeIcon icon={faCalendarAlt} />
												</label>
											</div>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<span className="dashButtonBorder" onClick={searchFunction}>
												Search
											</span>
										</div>

										{/* {!labDetailsResult.length ? (
										<div className="col-md-2 dashboardSearchList">
											<button
											type="button"
											style={{ width: "100%" }}
											id="export"
											className="dashButtonBorder dashButtonBorderGrey 111"
											onClick={noSearchData}
											>
											Export
											</button>
										</div>
										) : (
										<div className="col-md-2 dashboardSearchList">
											<button
											type="button" 
											style={{ border: "1px solid gainsboro" ,width: "100%" }} onClick={csvFileDownload}
											id="export"
											className="dashButtonBorder dashButtonBorderGrey 222"
											>
											Export
											</button>
										</div>
										)} */}
										<div className="col-md-2 dashboardSearchList">
											<span className="dashButtonBorder dashButtonBorderGrey" style={{ border: "1px solid gainsboro" }} onClick={csvFileDownload}>
												Export
											</span>
										</div>
									</div>
									<div className='row displayFlxEnd'>
										<div className="col-md-2">
											<div className="searchCount">
												Total Lab Order :{' '}
												{labDetailsResult.length == 0 ? 0 : totalCount}
											</div>
										</div>
									</div>
								</div>
								<div className="dashboardtable">
									{isData == 0 ? (
										<Loader />
									) : labDetailsResult.length > 0 ? (
										<table>
											<thead>
												<tr>
													<th>Patient Name </th>
													<th>Lab Name</th>
													<th>Doctor Name</th>
													<th>Lab Order Date</th>
													<th>View</th>
												</tr>
											</thead>
											<tbody>
												{labDetailsResult.map((data) => (
													<tr>
														<td>{data.patientname}</td>
														<td>{data.serviceprovidername}</td>
														<td>{data.doctorname}</td>
														<td>{data.createdDate}</td>
														<td>
															{' '}
															<nav
																onClick={() => outsideLabOrderClick({ data: data.labOrderId })}
															>
																<img src={Eyeicon} />
															</nav>{' '}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									) : (
										<NoData />
									)}
								</div>
							</div>
							<div className="paginationSection">
								{totalCount > 10 && (
									<Pagination
										hideNavigation
										activePage={activePage}
										itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
										totalItemsCount={totalCount}
										pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
										onChange={handlePagination}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default OutsideLabOrderDetails;
