import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function TreatmentChart() {
	const [treatmentChartList, setTreatmentChartList] = useState([]);
	const [load, setLoad] = useState(false);
	useEffect(() => {

		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('clinicalBackground/treatmentChartList', PostData)
			.then((data) => {
				if (data.data.success === "1") {
					setTreatmentChartList(data.data.result.treatmentChartList);
					setLoad(true)
				}
				else {
					setLoad(true)
					setTreatmentChartList([])
				}
			})
			.catch();
	}, []);
	const qaz = () => {
		var specialisationsSelect = [];
		for (var s = 0; s < treatmentChartList.length; s++) {
			if (document.getElementById(treatmentChartList[s].pmHospitalTreatmentDetails).checked === true) {
				var a = treatmentChartList[s].pmHospitalTreatmentDetails;
				specialisationsSelect.push(a);
			}
		}
		window.sessionStorage.setItem('procedureId', specialisationsSelect.toString().replace(/[\[\]']/g, ''));
		if (specialisationsSelect.length == 0) {
			toast.error('Please select alteast one procedure');
		} else {
			window.location.href = '/clinics/treatmentChartPrint';
		}
	};
	return (
		<div className="coverWraper">
			<Header />
			<ToastContainer position="bottom-right" />
			<div className="contentWraper contentWraperScroll pl_staffList ">
				{load === false ? <Loader /> : ''}
				<div class="pulseContentCoverWraper">
					<div class="container">
						<div class="content4">
							<div class="container">
								<div class="container">
									<div />

									<div class="tableHolder StaffList-MainDiv">
										<div class="left" style={{ float: 'left', width: '100%' }}>
											<a
												onClick={() => qaz()}
												// href="/clinics/treatmentChartPrint"
												class="practiceGreenButton"
												style={{ marginTop: '10px', textDecoration: 'none' }}
											>
												Print Treatment Chart
											</a>
										</div>
										<h3>Treatment Chart</h3>

										<br />
										<div class="col-md-12 overflow-tableOuter">
											{/*  */}
											<div className="dashboardtable">
												<table className="treatmentProcedureTble">
													<thead>
														<tr>
															<th class="tableCntWdth">Procedure Name</th>

															<th class="tableCntWdth">Procedure Code</th>
															<th class="tableCntWdth">Cost</th>
														</tr>
													</thead>
													{treatmentChartList.length > 0 ?
														<tbody>
															{treatmentChartList.map((item, index) => (
																<tr>
																	<td>
																		{/* {' '} */}
																		<label style={{ cursor: "pointer" }}><input
																			type="checkbox"
																			id={item.pmHospitalTreatmentDetails}
																		/>{' '}
																			&nbsp;{item.procedure_name}</label>
																	</td>

																	<td>{item.procedure_code}</td>
																	<td>{item.price}</td>
																</tr>
															))}
														</tbody>
														: <NoData />}
												</table>
											</div>
											{/*  */}
										</div>

										{/* <div class="container">
											<div class="doc pagination prctmngmtPageWrap" />
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="dividerSml" />
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default TreatmentChart;
