import React, { useState, useEffect } from 'react';
import Add from '../images/icons/add.png';
import Close from '../images/icons/close-sm.png';
import Editicon from '../images/icons/edit-bg.png';
import Delete from '../images/icons/delete_btn.png';
import Pagination from 'react-js-pagination';
import $ from "jquery";
import { callingAPI, callingPostAPI, callingGetAPI } from '../config';
import Tick from '../images/icons/tick.png';
import InActive from '../images/icons/close.png';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import zIndex from '@material-ui/core/styles/zIndex';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddWorkType() {
    const [dataFlag, setDataFlag] = useState(false);
    const [saveFlag, setSaveFlag] = useState(false);
    const [addLabWorkType, setAddLabWorkType] = useState([])
    const [workTypeName, setWorkTypeName] = useState("");
    const [workTypeSearch, setWorkTypeSearch] = useState("");
    const [status, setStatus] = useState(true);

    const [workTypeId, setWorkTypeId] = useState("");
    const [count, setCount] = useState('');
    const [activePage, setActivePage] = useState(parseInt(window.sessionStorage.getItem('labTestactivePage')));
    const [pageOffset, setPageOffset] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const addLabWorkClick = () => {
        $("#plpopup1").css("visibility", "visible");
        $("#plpopup1").css("opacity", "1");
        setWorkTypeName("")
        setWorkTypeId("")
    }
    const closePopup = () => {
        setSaveFlag(false)
        $("#plpopup1").css("visibility", "hidden");
        $("#plpopup1").css("opacity", "0");
    }
    const editLablist = (data) => {
        $("#plpopup1").css("visibility", "visible");
        $("#plpopup1").css("opacity", "1");
        setWorkTypeName(data.name);
        setWorkTypeId(data.id)
        setStatus(data.is_active)
    }
    useEffect(() => {

        labworkTypeList();

    }, []);
    const labworkTypeList = () => {
        const PostData =
        {
            "searchInput": "",
            "offset": window.sessionStorage.getItem("labTestoffset"),
            "max": 10,
            "hospitalId": localStorage.getItem("hospitalId"),
        };
        callingAPI('commonUserFunctions/LabworkTypeList', PostData).then((response) => {

            console.log("response.data.result.totalCount", response.data.result.totalcount);
            if (response.data.success === '1') {
                setDataFlag(true);
                setAddLabWorkType(response.data.result.labWorkTypeList);
                setCount(response.data.result.totalcount);


            }
            else {
                setDataFlag(true);
                setAddLabWorkType([]);
                setCount("");
            }
        })
            .catch();
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === '1') {
            offset = '0';
            max = process.env.REACT_APP_PAGINATION_COUNT;
        } else {
            offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
            max = process.env.REACT_APP_PAGINATION_COUNT;
        }
        // setActivePage(pageNumber);
        // setPageOffset(offset);
        window.sessionStorage.setItem("labTestactivePage", pageNumber)
        window.sessionStorage.setItem('labTestoffset', offset)
        const PostData = {
            "searchInput": workTypeSearch === "" ? "" : workTypeSearch,
            "offset": offset,
            "max": max,
            "hospitalId": localStorage.getItem("hospitalId"),
        };
        callingAPI('commonUserFunctions/LabworkTypeList', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                setAddLabWorkType(response.data.result.labWorkTypeList);
                setCount(response.data.result.totalcount);
            }
            else {
                setDataFlag(true);
                setAddLabWorkType([]);
                setCount("");
            }
        })
            .catch();
    };

    const enterKey = (e) => {
        if (e.key === 'Enter') {
            searchValueChange();
        }
    }

    const searchValueChange = () => {
        const PostData = {
            "searchInput": workTypeSearch === "" ? "" : workTypeSearch,
            "offset": 0,
            "max": process.env.REACT_APP_PAGINATION_COUNT,
            "hospitalId": localStorage.getItem("hospitalId"),
        };
        callingAPI('commonUserFunctions/LabworkTypeList', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                window.sessionStorage.setItem("labTestactivePage", 1)
                window.sessionStorage.setItem('labTestoffset', 0)
                setAddLabWorkType(response.data.result.labWorkTypeList);
                setCount(response.data.result.totalcount);
                setActivePage(1);


            }
            else {
                setDataFlag(true);
                setAddLabWorkType([]);
                setCount("");
            }
        })
            .catch();

    }

    const statusChange = (id, status) => {
        var x = false;
        if (status === true) {
            x = window.confirm('Do you want to Enable this?');
        } else {
            x = window.confirm('Do you want to Disable this?');
        }
        if (x === true) {
            const satusData = {
                workTypeId: id,
                workType: "",
                isActive: status == false ? false : true,
                hospitalId: localStorage.getItem("hospitalId"),
            };

            callingAPI('commonUserFunctions/addLabworkType', satusData).then((data) => {
                if (data.data.success === '1') {
                    toast.success('Status Changed Successfully');
                    labworkTypeList();
                }
                if (data.data.success === '0') {
                    toast.error(data.data.errorMessage);
                }
            });
        }
    };


    const labWorkTypeAddSave = () => {
        setIsButtonDisabled(true);
        let flags = true;
        if (!workTypeName) {
            toast.error('Enter New Work Type');
            document.getElementById('workTypeName').focus();
            setIsButtonDisabled(false);
            flags = false;
        }

        else {
            if (flags === true) {
                let postData = {
                    "workTypeId": workTypeId,
                    "workType": workTypeName,
                    "isActive": status,
                    "hospitalId": localStorage.getItem("hospitalId"),
                }

                callingAPI('commonUserFunctions/addLabworkType', postData).then((response) => {

                    if (response.data.success === "1") {
                        labworkTypeList();
                        setWorkTypeName("")
                        closePopup();
                        setWorkTypeId("")
                        if (workTypeId === "") {
                            toast.success("Added Successfully");
                            setIsButtonDisabled(false);
                        }
                        else {
                            toast.success("Updated Successfully");
                            setIsButtonDisabled(false);
                        }

                    }
                    else {
                        toast.error(response.data.errorMessage);
                    }
                })
            }
        }
    }

    return (
        <div className="dashboardCover">
            <ToastContainer position="bottom-right" style={{zIndex:"999999999"}}/>
            <div className="row">
                <div className="col-md-10">
                    <div className="dashboardTitle">Work Type</div>
                </div>

                <div className="col-md-2">
                    <div onClick={() => { addLabWorkClick(); }} style={{ color: '#fff', textDecoration: 'none' }}>
                        <span
                            className="dashButtonBorder"
                            style={{ background: '#22a2fd', border: 'none', color: '#fff', padding: "6px 4px", fontSize: "13px" }}
                        >
                            Add New Work Type
                        </span>
                    </div>
                </div>
            </div>
            {console.log("........................count", count)}
            {count > 10 && (
                <div className="paginationSection">
                    <Pagination
                        // hideNavigation
                        hideDisabled
                        activePage={activePage}
                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                        totalItemsCount={count}
                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                        onChange={handlePageChange}
                    />
                </div>
            )}
            <div className="dashboardContent">
                <div className="reportWrap">
                    <div className="dashboardSearch">
                        <div className="row">
                            <div className="col-md-2 dashboardSearchList">
                                <input
                                    id="workTypeSearch"
                                    type="text"
                                    className="inputField"
                                    placeholder='Enter Work Type'
                                    autoComplete="off"
                                    value={workTypeSearch}
                                    onChange={(e) => setWorkTypeSearch(e.target.value)}

                                />
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                <span className="dashButtonBorder"
                                    onClick={searchValueChange}
                                    title="Search"
                                    onKeyDown={enterKey}>
                                    Search
                                </span>
                            </div>
                        </div>
                    </div>
                    {dataFlag == false ? (
                        <Loader />
                    ) : addLabWorkType.length > 0 ? (
                        <div className="dashboardtable">
                            <table>
                                <thead>
                                    <th>Work Type</th>
                                    <th>Status</th>
                                    <th style={{ width: "10%" }}></th>
                                </thead>
                                <tbody>
                                    {addLabWorkType.map((data) => (
                                        <tr>
                                            <td className='vertical-middle'>{data.name}</td>
                                            {data.is_active === true ? (<td className='vertical-middle'>Active</td>) : (<td className='vertical-middle'>Inactive</td>)}
                                            <td className='vertical-middle' style={{ width: "10%" }}>
                                                <img src={Editicon} onClick={() => editLablist(data)} alt="Edit" className='img-fluid' style={{ marginRight: "7px" }} title='Edit' />
                                                {data.is_active === true ? (
                                                    <img
                                                        src={Tick}
                                                        alt="Active"
                                                        className='img-fluid'
                                                        width={20}
                                                        title="Click to Deactivate"
                                                        onClick={() => statusChange(data.id, false)}
                                                    />

                                                ) : (
                                                    <img
                                                        src={InActive}
                                                        alt="InActive"
                                                        className='img-fluid'
                                                        width={20}
                                                        title="Click to Activate"
                                                        onClick={() => statusChange(data.id, true)} />
                                                )}


                                            </td>

                                        </tr>

                                    ))}
                                </tbody>
                            </table>
                            <br />
                        </div>
                    ) : (
                        <p>
                            <NoData />
                        </p>
                    )}
                </div>
            </div>
            {count > 10 && (
                <div className="paginationSection">
                    <Pagination
                        // hideNavigation
                        hideDisabled
                        activePage={activePage}
                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                        totalItemsCount={count}
                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                        onChange={handlePageChange}
                    />
                </div>
            )}

            {/* Popup Begins */}
            <div id="plpopup1" class="ploverlay">
                <div class="plpopup addLabpopup">
                    <h2>Add New Work Type</h2>
                    <div class="close" onClick={closePopup}><span class="popUpClose">x</span></div>
                    <div class="plcontent">
                        <div class="addDataContent">
                            <div class="row">
                                <div className="col-md-3">
                                    <label>Work Type<span class="mandatory">*</span></label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        className='inputField'
                                        placeholder="Enter New Work Type"
                                        id="workTypeName"
                                        autoComplete="off"
                                        name='workTypeName'
                                        value={workTypeName}
                                        onChange={(e) => setWorkTypeName(e.target.value)} />
                                </div>

                            </div>


                            <div class="summaryBtnDiv">
                                {saveFlag === true ?
                                    <button type="submit" class="practiceGreenButton" onClick={labWorkTypeAddSave} style={{ color: '#046b29' }} disabled={isButtonDisabled}>Update</button> :
                                    <button type="submit" class="practiceGreenButton" onClick={labWorkTypeAddSave} style={{ color: '#046b29' }} disabled={isButtonDisabled}>Save</button>}
                                <button type="submit" class="practiceGreenButton" onClick={closePopup} style={{ color: 'red', border: "1px solid red" }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Popup Ends */}

        </div>
    );
}
export default AddWorkType;
