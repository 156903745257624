import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { callingAPI, callingAPI_GET } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Language(props) {
	const [selectedLanguageDropdownValues, setLanguageDropdownValues] = useState([]);
	const [selectedLanguageName, setSelectedLanguageName] = useState('');
	const [selectedLanguageArrValues, setSelectedLanguageArrValues] = useState([]);
	const [displayErrMsg1, setDisplayErrMsg1] = useState('');
	const [dataflag, setdataflag] = useState(false);
	const onChange = (event) => {
		setSelectedLanguageName(event.target.value);
	};
	const addLanguageValues = () => {
		let copyDropdownValues = [...selectedLanguageDropdownValues];
		let copySelectedValues = [...selectedLanguageArrValues];
		if (!selectedLanguageName) {
			setDisplayErrMsg1('Please Select Language');
			alertlanguage()
			// window.scrollTo(0, 0)
		} else {
			var selected = copySelectedValues.find((object) => object.doctorLanguageId == selectedLanguageName);
			if (selected !== undefined) {
				if (selected.doctorLanguageId == selectedLanguageName) {
					setDisplayErrMsg1(selected.language + ' is already added');
					alertlanguage()
					// window.scrollTo(0, 0)
				}
			} else {
				var selectedLanguage = copyDropdownValues.find((object) => object.languageId == selectedLanguageName);
				copySelectedValues.push({
					doctorLanguageId: selectedLanguage.languageId,
					language: selectedLanguage.languageDescription,
				});
				setSelectedLanguageArrValues(copySelectedValues);
				setDisplayErrMsg1('');
			}
		}
		setSelectedLanguageName('');
		alertlanguage()

	};
	const alertlanguage = () => {
		setTimeout(() => {
			setDisplayErrMsg1('');
		}, 2000);

	}
	const removeLanguageArr = (e, i, index) => {
		let languages = [...selectedLanguageArrValues];
		languages.splice(index, 1);
		setSelectedLanguageArrValues(languages);
	};
	const saveLanguages = () => {

		var flag = true
		setdataflag(false)
		var ids = [];
		ids = selectedLanguageArrValues.map((val, idx) => {
			return (ids[idx] = val.doctorLanguageId);
		});
		if (ids.length === 0) {
			setdataflag(false)
			toast.error("Please add language")
			flag = false
		}
		if (flag === true) {
			setdataflag(true)
			const postData = {
				doctorLanguageId: ids.toString(),
				subFunctionName: 'updateLanguageDetails',
				doctorId: window.sessionStorage.getItem('doctorId'),
			};
			callingAPI('doctorProfile/updateDoctorProfile', postData)
				.then((res) => {
					toast.success(res.data.successMessage);
					window.location.href = '/Dashboard/profile';
				})
				.catch();
		};
	}
	const getLanguageDropdownValues = () => {
		callingAPI('doctorProfile/getLanguages')
			.then((res) => {
				setLanguageDropdownValues(res.data.result.language);
			})
			.catch();
	};
	useEffect(
		() => {
			getLanguageDropdownValues();
			if (props.languages.length) {
				setSelectedLanguageArrValues(props.languages);
			}
		},
		[props.languages]
	);
	return (
		<div>
			<div className="homeProfileDetails languageProfileDiv">
				<ToastContainer position="bottom-right" />
				<div className="practiceManagementGrid profileForm">
					<div className="row rowMargin">
						<div className='col-md-12'>
							<p
								style={{
									display: 'flex',
									justifyContent: 'center',
									color: 'red',
									padding: '0px',
									margin: '0px', fontSize: "13px", paddingBottom: "15px"
								}}
							>
								{displayErrMsg1}
							</p>
						</div>
						<div className="col-md-6 col-lg-6 practiceManagementList">

							<select
								className="inputField"
								value={selectedLanguageName}
								name="languageName"
								onChange={onChange}
							>
								<option value="0">Languages Known</option>
								{selectedLanguageDropdownValues.map((lan, index) => (
									<option value={lan.languageId}>{lan.languageDescription}</option>
								))}
							</select>
							<span className="languageIcons">
								<FontAwesomeIcon onClick={(e) => addLanguageValues(e)} icon={faPlus} title="Add" />
							</span>
						</div>
						{selectedLanguageArrValues.length !== 0 ? (
							<div className="col-md-12 col-lg-12 practiceManagementList">
								<div className="addOnSection">
									<div className="addOnSectionTitle">Languages Known</div>

									<div className="addOnContent">
										{selectedLanguageArrValues.map((lang, index) => (
											<div className="addOnContentList">
												<input type="text" value={lang.language} className="inputField" />
												<span className="languageIcons">
													<FontAwesomeIcon
														icon={faTrashAlt}
														title="Delete"
														onClick={(e) => {
															removeLanguageArr(e, lang.doctorLanguageId, index);
														}}
													/>
												</span>
											</div>
										))}
									</div>

								</div>
							</div>
						) : null}
					</div>
					<button
						disabled={dataflag}
						class="borderButton borderButtonLarge legoButton_green"
						onClick={saveLanguages}
					>
						Save
					</button>
				</div>
			</div>
		</div>
	);
}

export default Language;
