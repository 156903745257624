import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { callingAPI } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function TreatmentDetails() {
  const [TreatmentList, setTreatmentListData] = useState([]);


  const [searchData, setSearchData] = useState([]);
  const downloadName = "TreatmentList";
  const [count, setCount] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [dataFlag, setDataFlag] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [filterName, setFilterInput] = useState('');
  const [searchValue, setsearchValue] = useState('');
  const [booliansearchValue, setbooliansearchValue] = useState("");
  const [boolianfilterName, setboolianfilterName] = useState("");
  const getpatientsList = () => {
    let PostData = {
      "roleName": window.sessionStorage.getItem("role"),
      "userId": window.sessionStorage.getItem("userId"),
      "max": process.env.REACT_APP_PAGINATION_COUNT,
      "offset": pageOffset
    }
    callingAPI("treatmentProcedure/treatmentDetails", PostData).then((response) => {
      if (response.data.success === "1") {
        setTreatmentListData(response.data.result.treatmentDetails);
        setCount(response.data.result.totalCount);
        setDataFlag(true);
      } else { setDataFlag(true); }
    });
  };
  useEffect(() => {
    getpatientsList();
  }, []);
  function patientListDownload() {
    const csvRow = [];
    var patientlist = [
      [
        "Sl%20No",
        "Treatment%20Name",
        "Treatment%20Code",
      ],
    ];
    var name = TreatmentList;
    let PostData = {
      roleName: window.sessionStorage.getItem("role"),
      userId: window.sessionStorage.getItem("userId"),
      max: "",
      offset: 0,
      cmbSearch: filterName,
      serchKeyword: searchValue,
    };
    callingAPI("treatmentProcedure/treatmentDetails", PostData).then((response) => {
      for (var item = 0; item < response.data.result.treatmentDetails.length; item++) {
        patientlist.push([
          item + 1,
          response.data.result.treatmentDetails[item].pmTreatmentName.replace(/ /g, '%20'),
          response.data.result.treatmentDetails[item].pmHospitalTreatmentDetailsProcedureCode.replace(/,/g, '%20').replace(/ /g, '%20'),
        ]);
      }
      for (var i = 0; i < patientlist.length; ++i) {
        csvRow.push(patientlist[i].join(","));
      }
      var csvString = csvRow.join("%0A");
      var creat = document.createElement("a");
      creat.href = "data:attachment/csv," + csvString;
      creat.target = "_Blank";
      creat.download = downloadName + ".csv";
      document.body.appendChild(creat);
      creat.click();
    })
  }

  // =============
  const searchValueChange = (value) => {
    setsearchValue(value);
  };
  const changeTextonTextArea = (value) => {
    setFilterInput(value);
    setsearchValue('');
    if (value === '') {
      setsearchValue('');
      document.getElementById('searchText').disabled = true;
    } else {
      document.getElementById('searchText').disabled = false;
      document.getElementById('searchText').focus();
    }
  };
  const searchValueOnChangeOnSelect = () => {

    if (document.getElementById('selectName').value !== '' && document.getElementById('searchText').value === '') {
      var t = document.getElementById('selectName');
      var selectedText = t.options[t.selectedIndex].text;
      document.getElementById('selectName').focus();
      toast.error(selectedText + ' required');
      return false;
    }
    setbooliansearchValue(searchValue);
    setboolianfilterName(filterName);
    let PostData = {
      roleName: window.sessionStorage.getItem("role"),
      userId: window.sessionStorage.getItem("userId"),
      cmbSearch: filterName,
      serchKeyword: searchValue,
      // max: process.env.REACT_APP_PAGINATION_COUNT,
      // offset: 0
      max: 10,
      offset: 0,

    };
    callingAPI('treatmentProcedure/treatmentDetails', PostData)
      .then((response) => {
        console.log("------ response", PostData, response);

        if (response.data.success === '1') {
          setTreatmentListData(response.data.result.treatmentDetails);
          setCount(response.data.result.totalCount);

        }
      })
      .catch();
  };
  //pagegination function
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    let PostData = {
      "roleName": window.sessionStorage.getItem("role"),
      "userId": window.sessionStorage.getItem("userId"),
      // "max": process.env.REACT_APP_PAGINATION_COUNT,
      // "offset": offset, 
      "max": max,
      "offset": offset,

      "cmbSearch": filterName,
      "serchKeyword": searchValue,
    };
    callingAPI("treatmentProcedure/treatmentDetails", PostData).then((response) => {
      console.log("response", response);
      if (response.data.success === "1") {
        setTreatmentListData(response.data.result.treatmentDetails);
        setCount(response.data.result.totalCount);
      }
    });
  };
  return (
    <div className="dashboardCover patientDetailsTable" id="dashboardCover">
      <ToastContainer position="bottom-right" />
      <div className="dashboardTitle">Treatment Details</div>
      {dataFlag == false ? (
        <div align="center">
          <Loader />
        </div>
      ) : (
        <div>
          {dataFlag == true && (
            <div className="dashboardContent">
              {TreatmentList.length !== 0 && (
                <div>
                  {count > 10 && (
                    <div className="paginationSection">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                        totalItemsCount={count}
                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
                        onChange={handlePageChange}
                        //hideNavigation
                        hideDisabled
                      />
                    </div>)}
                </div>)}
              {/* <div className="reportWrap">
                <div className="dashboardSearch">
                  <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>

                    {!TreatmentList.length ? (
                      <div className="col-md-2 dashboardSearchList">
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          id="export"
                          className="dashButtonBorder dashButtonBorderGrey"
                          disabled
                        >
                          Export
                        </button>
                      </div>
                    ) : (
                      <div className="col-md-2 dashboardSearchList">
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          onClick={patientListDownload}
                          id="export"
                          className="dashButtonBorder dashButtonBorderGrey"
                        >
                          Export
                        </button>
                      </div>
                    )}
                    <div className="col-md-3 dashboardSearchList">
                      <div className="searchCount">Total Treatment Count :&nbsp;{count}</div>
                    </div>
                  </div>
                </div>
                <div className="dashboardtable">
                  <table>
                    <thead>
                      <tr>
                        <th>SI No</th>
                        <th >Treatment Name</th>
                        <th>Treatment Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TreatmentList.map((list, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{list.pmTreatmentName}</td>
                          <td>{list.pmHospitalTreatmentDetailsProcedureCode}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div> */}

              <div className="reportWrap">
                {/* <div className="dashboardSearch">
                  <div className="row">
                    <div className="col-md-2 dashboardSearchList">
                      <select
                        className="inputField"
                        id="statusData"
                        name="statusData"
                      // value={statusData}
                      // onChange={changeStatus}
                      // onKeyDown={onKeyDown}
                      >
                        <option value="treatmentCode">Treatment Name </option>
                        <option value="treatmentName">Treatment Code </option> 
                      </select>
                    </div> 
                    <div className="col-md-2 dashboardSearchList"> 
                      <input
                        type="text"
                        className="inputField"
                        id="searchText"
                        style={{ background: "transparent" }}
                        name="searchText"
                        // value={searchValue}
                        // onKeyDown={onKeyDown}
                        // onChange={(e) => filterByPatient(e.target.value)}
                        disabled
                      />
                    </div> 
                    <div className="col-md-2 dashboardSearchList">
                      <span
                        className="dashButtonBorder"
                        // onClick={searchFunction}
                      // onKeyDown={onKeyDown}
                      >
                        Search
                      </span>
                    </div>

                    {!TreatmentList.length ? (
                      <div className="col-md-2 dashboardSearchList">
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          id="export"
                          className="dashButtonBorder dashButtonBorderGrey"
                          disabled
                        >
                          Export
                        </button>
                      </div>
                    ) : (
                      <div className="col-md-2 dashboardSearchList">
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          onClick={patientListDownload}
                          id="export"
                          className="dashButtonBorder dashButtonBorderGrey"
                        >
                          Export
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="searchCount">Total Appointment : {count}</div>
                </div> */}
                <div className="dashboardSearch">
                  <div className="row">
                    <div className="col-md-2 dashboardSearchList">
                      <select
                        className="inputField"
                        id="selectName"
                        name="selectName"
                        value={filterName}
                        onChange={(e) => changeTextonTextArea(e.target.value)}
                      >
                        <option value="">Search In</option>
                        <option value="treatmentName">Treatment Name</option>
                        <option value="treatmentCode">Treatment Code</option>
                      </select>
                    </div>
                    <div className="col-md-2 dashboardSearchList">
                      {filterName == "" ? (
                        <input
                          className="inputField"
                          type="text"
                          disabled
                          style={{ background: "transparent" }}
                          value={searchValue}
                          id="searchText"
                          name="searchText"
                        />
                      ) : (
                        <input
                          className="inputField"
                          type="text"
                          value={searchValue}
                          style={{ background: "white" }}
                          onChange={(e) => searchValueChange(e.target.value)}
                          id="searchText"
                          name="searchText"
                        />
                      )}
                    </div>

                    <div className="col-md-2 dashboardSearchList">
                      <span onClick={searchValueOnChangeOnSelect} className="dashButtonBorder">
                        Search
                      </span>
                    </div>
                    <div className="col-md-2 dashboardSearchList">
                      <span
                        onClick={patientListDownload}
                        className="dashButtonBorder dashButtonBorderGrey">
                        Export
                      </span>
                    </div>
                    <div className="col-md-3">
                      <div className="searchCount" style={{ textAlign: 'right' }}>
                        Number of Treatments : {count}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dashboardtable">
                  <table>
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th >Treatment Name </th>
                        <th>Treatment Code </th>
                      </tr>
                    </thead>
                    <tbody>
                      {TreatmentList.map((list, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{list.pmTreatmentName}</td>
                          <td>{list.pmHospitalTreatmentDetailsProcedureCode}</td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
              </div>

              {count > 10 && (
                <div className="paginationSection">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                    totalItemsCount={count}
                    pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
                    onChange={handlePageChange}
                    //hideNavigation
                    hideDisabled
                  />
                </div>
              )}
            </div>
          )}
          {TreatmentList.length == 0 && dataFlag == true && <NoData />}
        </div>
      )}
    </div>
  );
}
export default TreatmentDetails;
