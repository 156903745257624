import React, { useState, useEffect } from 'react';
import Location from '../images/icons/Location.png';
import Edit from '../images/icons/edit-bg.png';
import Delete from '../images/icons/delete_btn.png';
import { callingAPI } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LocationBlue from '../images/icons/locationBlue.png';
function PatientFilesRight(props) {
	const [annotationList, setAnnotationList] = useState([]);
	const [annotationFolderDetailsList, setAnnotationFolderDetailsList] = useState([]);
	const [MedicalDescription, setDescription] = useState('');
	const [annotationCount, setAnnotationCount] = useState('');
	const [allowApiCall, setAllowApiCall] = useState(true);

	const [healthRecordName, setHealthRecordName] = useState('');
	const [folderName, setFolderName] = useState('');
	const [annotationListEdit, setAnnotationListEdit] = useState([]);

	const onChangeHealthRecordName = (event) => {
		setAllowApiCall(false);
		setHealthRecordName(event.target.value);

	};

	const onChangeFolderName = (event) => {
		setAllowApiCall(false);
		setFolderName(event.target.value);

	};
	const onChangeDescription = (e, i) => {
		const { value } = e.target
		let values = [...annotationList]
		values[i].description = value
		setDescription(values);
	};
	useEffect(() => {

		if (allowApiCall) {
			listing();
			editFolderDetails();
		}

	}, [props]);

	const listing = () => {
		const apiJson = {
			emrPatientRecordItemId: props.emrMedicalData,
		};
		callingAPI('clinicalBackground/annotationList', apiJson).then((data) => {

			if (data.data.success === '1') {
				setAnnotationList(data.data.result.annotationData);
				setAnnotationCount(data.data.result.annotationData.length)
				props.listupdated(annotationList);
			}
			else {
				setAnnotationList([]);
			}
		});
	};

	const editFolderDetails = () => {

		const apiJson = {
			recordId: props.emrMedicalData,
			memberId: window.sessionStorage.getItem("memberId"),
		};
		callingAPI('clinicalBackground/editRecords', apiJson).then((data) => {
			if (data.data.success === '1') {
				setAnnotationFolderDetailsList(data.data.result.detailsArray);
				{
					annotationFolderDetailsList.map((annotated, i) => (

						setHealthRecordName(data.data.result.detailsArray[i].descriptions),
						setFolderName(data.data.result.detailsArray[i].folderName)
					))
				}
			}
			else {
				setAnnotationFolderDetailsList([]);
			}
		});

	};
	const onUpdateArea = (id, i, blueId, blackId) => {
		let copy = [...annotationList]
		var medicaldesc;
		copy.map((item, index) => {
			if (item.emr_patient_record_item_id == id) {
				medicaldesc = item.description;
				return item;
			}
		})
		const apiJson = {
			descriptions: medicaldesc,
			specializationRecordsId: "",
			annotationId: id
		};
		callingAPI('clinicalBackground/updateAnnotation', apiJson).then((data) => {
			if (data.data.success === '1') {
				toast.success('Updated Successfully');
				document.getElementById('editDescArea' + id).style.display = 'none';
				listing();
			}
			if (data.data.success === '0') {
				toast.error(data.data.errorMessage);
			}
		});
		setAllowApiCall(true);
		document.getElementById(blueId).style.display = 'none';
		document.getElementById(blackId).style.display = 'block';
	}
	const onEditDescArea = (descriptionMedical, record_item_id, blueId, blackId) => {
		document.getElementById(blueId).style.display = 'block';
		document.getElementById(blackId).style.display = 'none';
		setAllowApiCall(false);
		setDescription(descriptionMedical)

		document.getElementById('editDescArea' + record_item_id).style.display = 'block';


	}
	const onChangeCancelDescArea = (record_item_id, blueId, blackId) => {
		document.getElementById('editDescArea' + record_item_id).style.display = 'none';
		document.getElementById(blueId).style.display = 'none';
		document.getElementById(blackId).style.display = 'block';

	}
	const deleteChange = (id) => {
		var x = false;
		x = window.confirm('Do you want to Delete?');
		if (x === true) {
			const satusData = {
				annotationId: id,
			};
			callingAPI('clinicalBackground/deleteAnnotation', satusData).then((data) => {
				if (data.data.success === '1') {
					alert('Deleted Successfully');
					listing();
				}
				if (data.data.success === '0') {
					alert(data.data.errorMessage);
				}
			});
		}
	};
	return (
		<div class="treatmentProcedureList completedPlanProce_PTour proceduresinner_PTour testResultDetails_PTour">
			<div class="treatmentListtop imageRightHotSpot">
				<span class="friendlyNoteDiv">
					<span>Health Record Name
						{/* {props.emrMedicalData} */}
					</span>
				</span>

				<textarea
					id="txtHealthName"
					name="txtHealthName"
					style={{ minHeight: '35px', maxHeight: '11px', marginTop: '10px' }}
					placeholder="Enter Name"
					className="textareaField"
					value={healthRecordName}
					onChange={onChangeHealthRecordName}
				/>
				<span class="friendlyNoteDiv">
					<span>Folder Name</span>
				</span>

				<textarea
					id="txtFolderName"
					name="txtFolderName"
					style={{ minHeight: '35px', maxHeight: '11px', marginTop: '10px' }}
					placeholder="Enter Folder Name"
					className="textareaField"
					value={folderName}
					onChange={onChangeFolderName}
				/>

				<div class="treatmentListHead txtCenter">
					<span id="invoiceTxt" class="inVoiceTxtLabel">
						Annotations
					</span>
				</div>
			</div>

			<div class="treatmentListSide imageRightHotSpot">
				{annotationList.length === 0 ?

					<div class="treatmentNameList">
						<div class="inVoiceSideWraper" id="annptationDiv">
							<span class="rightMainSubHeading">No annotations yet, click on image to annotate</span>
						</div>
					</div>
					:

					<div class="treatmentNameList">
						{annotationList.map((listObj, i) => (
							<div class="inVoiceSideWraper">
								<div class="inVoiceSideBlck">
									<div class="cropBoxItem">
										<div class="cropBoxContent">
											<div class="cropBoxTop">
												<div class="cropBoxRightImage">
													<p class="marker-text">{i + 1}</p>
													<img src={Location} />
												</div>
												<div class="cropBoxName">
													<span class="elipsis" title="test2">
														{listObj.title}
													</span>
												</div>
												<div class="cropBoxDate">
													<span>{listObj.created_on}</span>
												</div>
											</div>
											<div class="cropContentSec">
												<span style={{ display: 'block' }}>{listObj.description}</span>
												<div id={"editDescArea" + listObj.emr_patient_record_item_id} style={{ display: 'none' }}>
													<textarea
														name="description"
														// defaultValue={listObj.description}
														value={listObj.description}
														//	value={MedicalDescription}
														onChange={(e) => onChangeDescription(e, i)}
														style={{ display: 'block' }}>


													</textarea>
													<span class="cropContentSecBtn">
														<a
															onClick={() => onChangeCancelDescArea(listObj.emr_patient_record_item_id, "blueId" + i, "blackId" + i)}
															href="javascript:void(0);"
															class="annoDes55"
															style={{ display: 'block' }}
														>
															Cancel
														</a>
														<a
															onClick={() => onUpdateArea(listObj.emr_patient_record_item_id, i, "blueId" + i, "blackId" + i)}
															class="annoDes55"
															href="javascript:void(0);"
															style={{ display: 'block' }}
														>
															Update
														</a>
													</span>
												</div>
											</div>
										</div>
										<div class="cropBoxBtns">
											<span class="cropBoxEdit" title="Edit" >
												<img src={Edit}
													onClick={() => onEditDescArea(listObj.description, listObj.emr_patient_record_item_id, "blueId" + i, "blackId" + i)}
													style={{ width: '20px' }} />
											</span>
											<span class="cropBoxDelete" title="Delete">
												<img src={Delete}
													style={{ width: '18px' }}
													title="Click to Delete "
													onClick={() => deleteChange(listObj.emr_patient_record_item_id)} />
											</span>
										</div>
									</div>
								</div>
							</div>

						))}

					</div>

				}

			</div>
		</div>
	);
}

export default PatientFilesRight;
