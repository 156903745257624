import React, { useState, useEffect } from "react";
import { callingAPI } from "../config";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Leftmenu from "../Patients/leftmenu";
import Profiledetail from "../Patients/profiledetail";
import Patientright from "../Patients/patientright";
import Eye from "../images/icons/eye.png";
import Action from "../images/icons/action.png";
import actionActive from "../images/icons/actionActive.png";
import Loader from "../Common/loader";
import NoLaborder from "../Common/noLaborderFound";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function LabOrderDental() {
  const [allDataResult, setAllDataResult] = useState([]);
  const [load, setLoad] = useState(false);
  const [getData, setData] = useState(false);
  useEffect(() => {
    window.sessionStorage.setItem("labFormId", "");
    dentalListing();
  }, []);
  const statusChange = (action, id) => {
    var x = true;
    if (x === true) {
      const apiJson = {
        labOrderTestId: id,
        action: action,
      };
      callingAPI("adminReports/activateOrDeactivateDental", apiJson).then(
        (data) => {
          if (data.data.success === "1") {
            setLoad(true);
            toast.success("Status Changed Successfully");
            dentalListing();
          }
          if (data.data.success === "0") {
            toast.error(data.data.errorMessage);
          }
          setLoad(true);
        }
      );
    }
  };
  const dentalListing = () => {
    const apiJson = {
      hospitalId: localStorage.getItem("hospitalId"),
      memberId: window.sessionStorage.getItem("memberId"),
    };
    callingAPI("adminReports/testDental", apiJson).then((data) => {
      if (data.data.success === "1") {
        setAllDataResult(data.data.result.datasetLabRes);
        setData(true);
      }
      setLoad(true);
    });
  };
  const sessionClear = () => {
    window.sessionStorage.setItem("labReqId", "");
    window.sessionStorage.setItem("labFormId", "");
    window.sessionStorage.setItem("servprvid", "");
  };
  const Edit = (labReqId, labFormId, servprvid) => {
    if (labFormId === 1) {
      window.sessionStorage.setItem("labReqId", labReqId);
      window.sessionStorage.setItem("labFormId", labFormId);
      window.sessionStorage.setItem("ServiceProviderId", servprvid);
      window.open(
        `${process.env.PUBLIC_URL}/patients/labOrderDentalPrint`,
        "_blank"
      );
    }
    if (labFormId === 2) {
      window.sessionStorage.setItem("labReqId", labReqId);
      window.sessionStorage.setItem("labFormId", labFormId);
      window.sessionStorage.setItem("ServiceProviderId", servprvid);
      window.open(
        `${process.env.PUBLIC_URL}/patients/labOrderMetalFreeCeramic`,
        "_blank"
      );
    }
    if (labFormId === 3) {
      window.sessionStorage.setItem("labReqId", labReqId);
      window.sessionStorage.setItem("labFormId", labFormId);
      window.sessionStorage.setItem("ServiceProviderId", servprvid);
      window.open(
        `${process.env.PUBLIC_URL}/patients/labOrderMetalCeramic`,
        "_blank"
      );
    }
    if (labFormId === 5) {
      window.sessionStorage.setItem("labReqId", labReqId);
      window.sessionStorage.setItem("labFormId", labFormId);
      window.sessionStorage.setItem("ServiceProviderId", servprvid);
      window.open(
        `${process.env.PUBLIC_URL}/patients/labOrderOrthoCreation`,
        "_blank"
      );
    }
  };
  return (
    <div className="coverWraper">
      <ToastContainer position="bottom-right" />
      <Header />
      <div className="contentWraper contentWraperScroll patientPayment plInvoice labOrderDentalTbles">
        <Leftmenu />
        <div className="rightSection">
          <Profiledetail />
          <div className="practiceManagementWrap">
            <div className="row practiceManagementRow">
              <div className="col-lg-8 col-md-12 practiceLeftSection">
                {load === false ? <Loader /> : ""}
                <div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
                  <div id="divPatientContent" class="sideMenuContent">
                    {getData === true ? (
                      <div class="treatmentPlanCover" id="treatmentPlanListDiv">
                        <div class="treatmentPlanHead">
                          <span class="treatmentPlanName">
                            LAB ORDER &nbsp;
                          </span>
                          <a
                            onClick={() => sessionClear()}
                            href="/patients/labOrderDentalAdd"
                          >
                            <span class="treatmentPlanBtn pl_blue_btn">
                              + Add
                            </span>
                          </a>
                        </div>
                        {allDataResult.length > 0 ? (
                          <div>
                            {allDataResult.map((data, j) => (
                              <div class="treatmentPlanList1">
                                <div class="treatmentPlanDate">
                                  {data.order_date}
                                </div>
                                <div class="treatmentTable invoiceTableWrap">
                                  <div class="invoiceTableCvrDiv">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th class="col-md-4">
                                            <div class="dentalProName">
                                              Lab Name
                                            </div>
                                          </th>
                                          <th class="col-md-3">
                                            <div class="dentalProName">
                                              {" "}
                                              Test Name
                                            </div>
                                          </th>
                                          <th class="col-md-2">
                                            <div class="dentalProName">
                                              View
                                            </div>
                                          </th>
                                          <th class="col-md-3">
                                            <div class="dentalProNote">
                                              Status
                                            </div>
                                          </th>
                                          <th class="col-md-3">
                                            <div class="dentalProNote">
                                              Action
                                            </div>
                                          </th>
                                        </tr>
                                      </thead>
                                      {data.labOrderDetails.map((obj, k) => (
                                        <tbody>
                                          <tr class="groupBrdr">
                                            <td
                                              class="col-md-4"
                                              style={{ padding: "3px" }}
                                            >
                                              <div class="dentalProName paddingFiveLabOrder">
                                                <span
                                                  class="elipsis"
                                                  title={obj.labName}
                                                >
                                                  {obj.labName}
                                                </span>
                                              </div>
                                            </td>
                                            <td class="col-md-3">
                                              <div class="dentalProName paddingFive">
                                                {obj.lab_form_display_name}
                                              </div>
                                            </td>
                                            <td class="col-md-2">
                                              <div class="dentalProName paddingFive">
                                                <a
                                                  onClick={(e) =>
                                                    Edit(
                                                      obj.labReqId,
                                                      obj.labFormId,
                                                      obj.service_provider_id
                                                    )
                                                  }
                                                  target="new"
                                                  title="View Lab Order"
                                                >
                                                  <img src={Eye} />
                                                </a>
                                              </div>
                                            </td>
                                            <td class="col-md-3">
                                              <div class="dentalProNote paddingFive">
                                                <span class="elipsis pl_success">
                                                  {obj.status === 1 ? (
                                                    <span
                                                      style={{
                                                        color: "red",
                                                      }}
                                                    >
                                                      Pending
                                                    </span>
                                                  ) : (
                                                    "Received"
                                                  )}
                                                </span>
                                              </div>
                                            </td>
                                            <td class="col-md-3">
                                              <div class="dentalProNote paddingFive">
                                                {obj.status === 1 ? (
                                                  <img
                                                    src={Action}
                                                    style={{
                                                      width: "20px",
                                                    }}
                                                    title="Click to change status - Received"
                                                    onClick={() =>
                                                      statusChange(
                                                        2,
                                                        obj.labReqId
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <img
                                                    src={actionActive}
                                                    style={{
                                                      width: "20px",
                                                    }}
                                                    title="Click to change status - Pending"
                                                    onClick={() =>
                                                      statusChange(
                                                        1,
                                                        obj.labReqId
                                                      )
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <NoLaborder />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 practiceRightSection">
                <Patientright />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default LabOrderDental;
