import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import img1 from '../images/icons/treatment.png';
import NoData from '../Common/noDataFound';
import Printer_icon from '../images/icons/printer_icon.png';
import { callingAPI } from '../config';
import Sms from '../images/icons/chat.png';
import { Link } from 'react-router-dom';
import Editicon from '../images/icons/edit-bg.png';
import Delete from '../images/icons/delete_btn.png';
import sendEmail from '../images/icons/sendEmail.png';
import Loader from '../Common/loader';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function PatientInvoices() {
	const navigate = useNavigate();
	const [patientInvoice, setPatientInvoice] = useState([]);
	const [discount, setDiscount] = useState("")
	const [invoicePayNowIds, setinvoicePayNowIds] = useState([])
	const [isData, setIsData] = useState(false);
	const [dropDownCounter, setDropDownCounter] = useState({ "id": "", "count": 0 });
	useEffect(() => {
		listing();
	}, []);
	const listing = () => {
		//setIsData(false);
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
			memberId: window.sessionStorage.getItem('memberId'),
			userId: window.sessionStorage.getItem('userId'),
		};
		callingAPI('payment/invoiceList', apiJson).then((data) => {
			if (data.data.success === '1') {
				setPatientInvoice(data.data.result);
				window.sessionStorage.setItem('advanceAmt', data.data.result[0].advanceAmount);
				//setIsData(true);
				payNow(data.data.result[0].finalArray)
			} else {
				//setIsData(true);
			}
		});
	};
	const discountSave = (k, j, memberProcedureInvoiceId, balanceAmount) => {
		//setIsData(false);
		var a = parseFloat(document.getElementById("txtDiscountList" + memberProcedureInvoiceId).value).toFixed(2)

		if (!document.getElementById("txtDiscountList" + memberProcedureInvoiceId).value) {
			document.getElementById("txtDiscountList" + memberProcedureInvoiceId).focus()
		} else if (Number(a) > Number(balanceAmount)) {
			toast.error("Discount amount should be less than or equal to balance amount")
			document.getElementById("txtDiscountList" + memberProcedureInvoiceId).value = ""
			//setIsData(true);
		} else {
			const postData = {
				"memberProcedureInvoiceId": memberProcedureInvoiceId,
				"memberProcedureInvoiceDiscountAmount": document.getElementById("txtDiscountList" + memberProcedureInvoiceId).value
			}
			callingAPI('payment/saveInvoiceDiscount', postData).then((data) => {
				if (data.data.success === '1') {
					document.getElementById("txtDiscountList" + memberProcedureInvoiceId).value = ""
					listing()
				}
			});
		}

	}
	const setDiscountAmt = (event, memberProcedureInvoiceId) => {
		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		const { name, value } = event.target;
		if (event.target.value === '' || re.test(event.target.value) || event.target.value === '.') {
			document.getElementById("txtDiscountList" + memberProcedureInvoiceId).value = event.target.value

		} else {
			document.getElementById("txtDiscountList" + memberProcedureInvoiceId).value = ""
			toast.error("Only Number accepted")
		}
	}
	const editPatientInvoice = (data) => {
		console.log("invoicedata.....", data)
		navigate('/patients/patientInvoiceAdd', { state: data })
	}
	const addPaymentClick = (data) => {
		navigate('/patients/addPayments', { state: data })
	}
	const droplist = (divId, i) => {
		var elementDisplay = document.getElementById(divId + i);
		elementDisplay.style.display = (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 'none' : 'block';
		setDropDownCounter({
			id: divId + i,
			count: (dropDownCounter.id === divId + i && dropDownCounter.count === 1) ? 0 : 1
		})

		document.addEventListener('mouseup', function (e) {
			var container = document.getElementById(divId + i);
			if (container !== null) {
				if (!container.contains(e.target)) {
					container.style.display = 'none';
				}
			}
		});
	}
	const deleteInvoice = (memberProcedureInvoiceId, divId) => {
		var x = window.confirm('Do you want to remove the invoice?');

		if (x) {
			const postData = {
				"userId": window.sessionStorage.getItem('userId'),
				"invoiceId": memberProcedureInvoiceId
			}
			callingAPI('commonUserFunctions/removeInvoice', postData).then((data) => {
				if (data.data.success === '1') {
					droplist(divId, memberProcedureInvoiceId)
					listing()
				}
			});
		}

	}
	const sendSms = (memberProcedureInvoiceId) => {
		if (window.sessionStorage.getItem("PATIENTMOB") === "") {
			toast.error("Please Add a Valid MobileNumber")
		}
		else {

			const postData = {

				"userId": window.sessionStorage.getItem('userId'),
				"invoiceId": memberProcedureInvoiceId,
				"mode": "Invoice",
				"hospitalId": localStorage.getItem('hospitalId'),
				"siteId": window.sessionStorage.getItem("siteId")
			}
			callingAPI('commonUserFunctions/sendTreatmentPlanSMSToUser', postData).then((data) => {
				if (data.data.success === '1') {
					if (data.data.result !== "") {
						const PostData = {
							functionName: "createAppointmentMail",
							siteId: window.sessionStorage.getItem("siteId"),
							smsObj: {
								mobileNumber: data.data.result.smsObj.mobileNumber,
								message: data.data.result.smsObj.message,
								userId: data.data.result.smsObj.userId,
								siteId: data.data.result.smsObj.siteId,
								hospitalId: data.data.result.smsObj.hospitalId,
								smsType: data.data.result.smsObj.smsType,
								smsTemplateId: data.data.result.smsObj.smsTemplateId
							},
						};
						callingAPI("commonUserFunctions/createAppointmentMail", PostData).then((response) => {
							if (response.data.success === "0") {
								//alert(response.data.errorMessage)
								// var popup = document.getElementById("myPopupSmsError");
								// popup.classList.add("show");
								// if (popup.classList.contains("show"))
								// 	setTimeout(() => popup.classList.remove("show"), 3000)
							}
							else if (response.data.success === "1") {
								toast.success("SMS sent Successfully")
								// var popup = document.getElementById("myPopupSms");
								// popup.classList.add("show");
								// if (popup.classList.contains("show"))
								// 	setTimeout(() => popup.classList.remove("show"), 3000)
								//window.location.reload()
							}
						})
							.catch();
					}

				} else {
					// toast.error("Invalid Mobile number")
					toast.error(data.data.errorMessage)
				}
			});
		}


	}
	const sendMail = (memberProcedureInvoiceId) => {
		if (window.sessionStorage.getItem("PATIENTEMAIL") === "") {
			toast.error("Please Add a Valid EmailId")
		}
		else {
			const postData = {

				"userId": window.sessionStorage.getItem('userId'),
				"invoiceId": memberProcedureInvoiceId,
				"mode": "Invoice",
				"hospitalId": localStorage.getItem('hospitalId'),
				"siteId": window.sessionStorage.getItem("siteId")
			}
			callingAPI('commonUserFunctions/sendTreatmentPlanMailToUser', postData).then((data) => {
				if (data.data.success === '1') {
					if (data.data.result !== "") {
						const PostData = {
							jsonMailOptions: {
								to: data.data.result.jsonMailOptions.to,
								AdminEmailId: data.data.result.jsonMailOptions.AdminEmailId,
								SupportEmailId: data.data.result.jsonMailOptions.SupportEmailId,
								subject: data.data.result.jsonMailOptions.subject,
								html: data.data.result.jsonMailOptions.html,
								bccEmailRequired: data.data.result.jsonMailOptions.bccEmailRequired,
								ccEmailRequired: data.data.result.jsonMailOptions.ccEmailRequired
							},
						};
						callingAPI("commonUserFunctions/createAppointmentMail", PostData).then((response) => {
							if (response.data.success === "0") {
								//alert("Something went wrong")
								// var popup = document.getElementById("myPopupError");
								// popup.classList.add("show");
								// if (popup.classList.contains("show"))
								// 	setTimeout(() => popup.classList.remove("show"), 3000)
							}
							else if (response.data.success === "1") {
								toast.success("Email sent Successfully")
								// var popup = document.getElementById("myPopup");
								// popup.classList.add("show");
								// if (popup.classList.contains("show"))
								// 	setTimeout(() => popup.classList.remove("show"), 3000)

								//window.location.reload()
							}
						})
							.catch();
					} else {
						//alert("Invalid Email id")
						toast.error("Something went wrong")
					}
				}
			});
		}




	}
	const payNow = (data) => {
		var invoiceIds = [...data]

		var a = []
		for (var i = 0; i < invoiceIds.length; i++) {
			for (let index = 0; index < invoiceIds[i].main.length; index++) {
				const element = invoiceIds[i].main[index].invoiceList.balanceAmount;
				if (element > 0) {
					a.push(invoiceIds[i].main[index].invoiceList.memberProcedureInvoiceId)

				}
			}
		}
		setinvoicePayNowIds(a)

	}

	return (
		<div className="coverWraper">
			<ToastContainer position="bottom-right" />
			<Header />
			<div className="contentWraper contentWraperScroll patientPayment plInvoice ptInvTble">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />

					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									{/* {isData === false ? <Loader /> : ''} */}
									<div id="divPatientContent" class="sideMenuContent">
										{patientInvoice.map((invoiceObj, i) => (
											<div class="treatmentPlanCover" id="treatmentPlanListDiv">
												<div class="treatmentPlanHead">
													<span class="treatmentPlanName">
														INVOICES &nbsp;
														{invoiceObj.advanceAmount > 0.00 &&
															<span className="treatmentHeadDue">
																Advance (INR) :{' '}
																<span style={{ color: '#008000' }}>
																	{invoiceObj.advanceAmount}
																</span>
															</span>
														}
														{invoiceObj.dueAmount > 0.00 &&
															<span className="treatmentHeadDue">
																Due (INR) :{' '}
																<span style={{ color: '#f00' }}>
																	{invoiceObj.dueAmount}
																</span>
															</span>
														}
													</span>

													<span class="treatmentPlanBtn pl_blue_btn">
														{/* <a href="/patients/patientInvoiceAdd">+ Add</a> */}
														<nav onClick={() => editPatientInvoice({ hospitalId: localStorage.getItem('hospitalId'), memberId: window.sessionStorage.getItem('memberId'), isFlag: "" })}
														>+ Add</nav>
													</span>
													{/* <span class="treatmentPlanBtn pl_blue_btn">
														<a href="/patients/invoicePayNow">Pay Now</a>
													</span> */}
													{invoiceObj.dueAmount > 0.00 ?
														<span className="treatmentPlanBtn pl_blue_btn " >
															<nav
																onClick={() => addPaymentClick({ memberProcedureInvoiceId: invoicePayNowIds.join(",") })}
															>Pay Now</nav>

														</span>
														:
														<span className="treatmentPlanBtn pl_blue_btn test">
															<a href="/patients/addPayments">Pay Now</a>
														</span>
													}
												</div>
												{invoiceObj.finalArray.length > 0 ? (
													invoiceObj.finalArray.map((finalObj, k) => (
														<div class="treatmentPlanList1">
															<div class="treatmentPlanDate">{finalObj.memberProcedureInvoiceDate && moment(finalObj.memberProcedureInvoiceDate).format("DD, MMM YYYY")}</div>

															<div class="treatmentTable invoiceTableWrap">
																<div class="invoiceTableCvrDiv">
																	{finalObj.main.map((obj, j) => (
																		<table class="invoiceWrapTable">
																			<thead>
																				<tr class="invoiceTableHeaderBrdr">
																					<th class="col-md-2">
																						<div class="dentalProName">
																							INVOICE NUMBER
																						</div>
																					</th>
																					<th
																						colspan="4"
																						class="col-md-9"
																						style={{ padding: '0' }}
																					>
																						<div class="dentalProCost invoiceInnerTable">
																							<table>
																								<thead>
																									<tr>
																										<th class="col-md-4">
																											<div class="dentalProName"  >
																												TOWARDS
																											</div>
																										</th>
																										<th class="col-md-1">
																											<div class="dentalProDis" style={{ textAlign: "center" }}>
																												QTY
																											</div>
																										</th>
																										<th class="col-md-2">
																											<div class="dentalProDis" style={{ textAlign: "right" }}>
																												UNIT PRICE
																											</div>
																										</th>
																										<th class="col-md-2">
																											<div class="dentalProDis" style={{ textAlign: "right" }}>
																												COST
																											</div>
																										</th>

																										<th class="col-md-1">
																											<div class="dentalProDis" style={{ textAlign: "right" }}>
																												TAX
																											</div>
																										</th>
																										<th class="col-md-2">
																											<div class="dentalProTotal" style={{ textAlign: "right" }}>
																												TOTAL
																											</div>
																										</th>
																									</tr>
																								</thead>
																							</table>
																						</div>
																					</th>
																					<th class="col-md-1">
																						<div class="dentalProNote">
																							<span class="elipsis" title="">
																								&nbsp;
																							</span>
																							<button className="pl_print">
																								<a
																									href={'/patients/patientInvoicePrint/' +
																										obj.invoiceList.memberProcedureInvoiceId + "|" + window.sessionStorage.getItem('userId') + "|" + localStorage.getItem('hospitalId')
																									}
																									target="new"
																								>
																									<img
																										src={Printer_icon}
																									/>
																								</a>
																							</button>
																							<label class="dropdown" >
																								<div class="dd-button" onClick={() => droplist("dropdown", obj.invoiceList.memberProcedureInvoiceId)} />

																								<input
																									type="checkbox"
																									class="dd-input"
																									id="test"
																								/>

																								<ul class="dd-menu" id={"dropdown" + obj.invoiceList.memberProcedureInvoiceId}>
																									{obj.invoiceList.isInvoiceEdit === "1" &&
																										<div>
																											<li>
																												<nav onClick={() => editPatientInvoice({

																													memberProcedureInvoiceId:
																														obj.invoiceList.memberProcedureInvoiceId,
																													generatedOnDate: patientInvoice[0]?.finalArray[j]?.memberProcedureInvoiceDate
																												})}
																												>
																													<img src={Editicon} style={{ width: '15px', marginRight: '5px' }} />Edit
																												</nav>
																											</li>
																											<li onClick={() => deleteInvoice(obj.invoiceList.memberProcedureInvoiceId, "dropdown")}>
																												<img src={Delete} style={{ width: '15px', marginRight: '5px' }} />Delete
																											</li>
																										</div>
																									}
																									<li onClick={() => sendSms(obj.invoiceList.memberProcedureInvoiceId)}>
																										<img src={Sms} style={{ width: '15px', marginRight: '5px' }} />Send
																										SMS
																										<span class="popuptext" id="myPopupSms">SMS sent Successfully</span>
																										<span class="popuptext" style={{ display: 'none' }} id="myPopupSmsError">Something went wrong</span>
																									</li>
																									<li onClick={() => sendMail(obj.invoiceList.memberProcedureInvoiceId)}>
																										<img src={sendEmail} style={{ width: '15px', marginRight: '5px' }} />Send
																										Email
																										<span class="popuptext" id="myPopupError">Something went wrong</span>
																										<span class="popuptext" id="myPopup">Email sent Successfully</span>
																									</li>
																								</ul>
																							</label>
																						</div>
																					</th>
																				</tr>
																			</thead>



																			<tbody>
																				<tr class="groupBrdr">
																					<td
																						class="col-md-2"
																						style={{ position: 'relative' }}
																					>
																						<div class="dentalProName dentalProNameTop">
																							{obj.invoiceList.isInvoice == '1' &&
																								<img
																									src={img1}
																									title="Invoice Generated"
																								/>}
																							<span class="elipsis">
																								&nbsp;{obj.memberProcedureInvoiceNumber}
																							</span>
																						</div>
																					</td>

																					<td colspan="6">
																						<div class="dentalProCost invoiceInnerTable invoiceInnerTableBr" style={{ paddingRight: "0px" }}>
																							<table>
																								<tbody>
																									{obj.item.map(
																										(item, m) => (
																											<div>
																												<tr class="procedureInvoiceDiv plInvFirstTblRw">
																													<td colspan="6">
																														<div class="invoiceDivHeading invoiceDivHeadName">
																															{
																																item.itemType
																															}
																														</div>
																													</td>
																												</tr>
																												{item.item.map(
																													(
																														itemSub,
																														p
																													) => (
																														<tr class="procedureInvoiceDiv">
																															<span className='ptInvTbleSpan'>
																																<td class="col-md-4">
																																	<div class="dentalProName" style={{ paddingLeft: '5px' }}>
																																		<p
																																		>
																																			<b
																																				class="elipsis"
																																				title={
																																					itemSub.itemName
																																				}
																																			>
																																				{
																																					itemSub.itemName
																																				}
																																			</b>
																																		</p>
																																		<p
																																			class="elipsis"
																																			title={
																																				itemSub.itemCode
																																			}
																																		>
																																			{
																																				itemSub.itemCode
																																			}
																																		</p>
																																		{/* <br/> */}
																																		{item.itemType === "Procedure" &&
																																			itemSub.teethDetails !== '' &&
																																			itemSub.teethDetails !== null &&
																																			itemSub.teethDetails !==
																																			'None Selected' ? (
																																			<span
																																				class="elipsis"
																																				title={
																																					itemSub.teethDetails
																																				}
																																				style={{
																																					fontSize: '11px',
																																					color: '#004d75',
																																				}}
																																			>
																																				Teeth :{' '}
																																				{itemSub.teethDetails}
																																			</span>
																																		) : ""}

																																	</div>
																																</td>
																																<td class="col-md-1">
																																	<div class="dentalProDis" style={{ textAlign: "center" }}>
																																		{
																																			itemSub.quantity
																																		}
																																	</div>
																																</td>
																																<td class="col-md-2">
																																	<div class="dentalProDis" style={{ textAlign: "right" }}>
																																		{
																																			itemSub.unitPrice
																																		}
																																	</div>
																																</td>
																																<td class="col-md-2">
																																	<div class="dentalProDis" style={{ textAlign: "right" }}>
																																		{
																																			itemSub.cost
																																		}
																																	</div>
																																</td>
																																<td class="col-md-1">
																																	<div class="dentalProDis" style={{ textAlign: "right" }}>
																																		{
																																			itemSub.taxAmount
																																		}
																																	</div>
																																</td>
																																<td class="col-md-2">
																																	<div class="dentalProTotal dentalInvoiceTotal" style={{ textAlign: "right" }}>
																																		{
																																			itemSub.totalAmount
																																		}
																																	</div>
																																</td>
																															</span>
																														</tr>
																													)
																												)}
																											</div>
																										)
																									)}
																								</tbody>
																							</table>
																						</div>
																					</td>
																					{/* <td class="col-md-1">
																						<div class="dentalProNote">
																							<span class="elipsis">
																								&nbsp;
																							</span>
																						</div>
																					</td> */}
																				</tr>
																				<tr class="groupBrdr groupBg">

																					<td class="col-md-3" colspan="2">
																						{obj.invoiceList.balanceAmount > 0.00 &&
																							<div
																								class="dentalProName"
																								id="discountSection00"
																							>
																								<div class="inVoiceTotal" style={{ paddingLeft: "5px" }}>
																									<span>
																										<input
																											type="text"
																											id={"txtDiscountList" + obj.invoiceList.memberProcedureInvoiceId}
																											// value={obj.invoiceList}
																											placeholder="Discount"
																											name={"txtDiscountList"}
																											maxlength="10"
																											onChange={(e) => setDiscountAmt(e, obj.invoiceList.memberProcedureInvoiceId)}
																										/>
																									</span>

																									<span class="inrSpan">
																										INR
																									</span>
																									<div class="saVeBtn" onClick={() => discountSave(k, j, obj.invoiceList.memberProcedureInvoiceId, obj.invoiceList.balanceAmount)}>
																										Apply
																									</div>
																								</div>
																							</div>}
																					</td>
																					<td colspan="4">
																						<div class="dentalInvoiceAmount">
																							<div class="inVoiceTotal">
																								<span>
																									Total Amount :
																								</span>

																								<span id="totalAmtLbl00">
																									{
																										obj.invoiceList
																											.totalAmount
																									}
																								</span>
																							</div>
																							<div class="inVoiceTotal">
																								<span>
																									Discount Amount :
																								</span>

																								<span id="disAmtLbl00">
																									{
																										obj.invoiceList
																											.discountAmount
																									}
																								</span>
																							</div>
																							<div class="inVoicePaid">
																								<span>
																									Paid Amount :
																								</span>

																								<span id="paidAmtLbl00">
																									{
																										parseFloat(obj.invoiceList
																											.paidAmount).toFixed(2)
																									}
																								</span>
																							</div>
																							<div class="inVoiceBal">
																								<span>
																									Balance Amount :
																								</span>

																								<span id="balAmt00" style={{ color: "red" }}>
																									{
																										parseFloat(obj.invoiceList
																											.balanceAmount).toFixed(2)
																									}
																								</span>
																							</div>
																						</div>
																					</td>
																				</tr>

																				<tr
																					class="groupBrdr groupBg"
																					style={{
																						borderTop: '1px solid #D8D8D8',
																					}}
																				>
																					<td colspan="6">
																						<div class="dentalProName">
																							<span class="paymentBy paymentByDiv" style={{ paddingLeft: "5px" }}>
																								<b>Invoiced by :</b>
																								{
																									obj.invoiceList
																										.secUserDisplayName
																								}{' '}
																							</span>
																							{obj.invoiceList.memberProcedureInvoiceRefundAmount > 0 ?
																								<span class="paymentBy paymentByDiv" >
																									<b>Refund Amount (INR) : </b>
																									<span className='paymentRefund' style={{ color: "red" }}>{
																										obj.invoiceList
																											.memberProcedureInvoiceRefundAmount
																									}{' '}</span>

																								</span>
																								: ""}
																						</div>
																						{ }

																					</td>
																				</tr>
																			</tbody>


																		</table>
																	))}
																</div>
															</div>
														</div>
													))
												) : (
													//<NoData />
													<span>
														<span class="treatmentCommonTxt1">
															<span>
																{' '}
																No Invoice Found
															</span>
														</span>
													</span>

												)}
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default PatientInvoices;
