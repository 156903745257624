import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import tick from '../images/icons/tick.png';
import Close from '../images/icons/close-sm.png';
import Editicon from '../images/icons/edit-bg.png';
import Pagination from 'react-js-pagination';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import zIndex from '@material-ui/core/styles/zIndex';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LabTestType() {
	const [testList, setTestList] = useState([]);
	const [totalCount, setTotalCount] = useState([]);
	const [popupHeading, setPopupHeading] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [searchType, setSearchType] = useState("0");
	const [saveValue, setSaveValue] = useState('');
	const [isEnabled, setIsEnabled] = useState(true);
	const [displayName, setDisplayname] = useState('');
	const [labTestTypeId, setLabTestTypeId] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [pageOffset, setPageOffset] = useState(0);
	const [dataFlag, setDataFlag] = useState(false);
	const [disable, setDisable] = useState(false);


	const addButtonClick = (name) => {
		document.getElementById('plpopup1').style.display = '';
		if (name == 'Save') {
			setDisplayname('Save');
			setSaveValue('');
			document.getElementById('add').focus();
		}
		else if (name == 'Update') {
			setDisplayname('Update');
			document.getElementById('add').focus();
			// document.getElementById('plpopup1').style.display = 'none'
		}
	};
	const searchTypeOnChange = (event) => {
		if (event.target.value === "0") {
			setSearchType(event.target.value);
			setIsEnabled(true);
			getTestListingDataValues()
			setSearchValue('');
		} else if (event.target.value == "1") {
			document.getElementById("templateCode").focus()
			setSearchType(event.target.value);
			setIsEnabled(false);
		}
		if (document.getElementById('selectName').value !== '') {
			document.getElementById('templateCode').disabled = false;
			document.getElementById('templateCode').focus();
		} else {
			document.getElementById('templateCode').disabled = true;
		}
		document.getElementById('templateCode').value = '';
	};
	const searchValueFunction = (event) => {
		event.target.value = event.target.value.split('').length === 1 ? event.target.value.replace(/\s/, '') : event.target.value;
		setSearchValue(event.target.value);
	};
	const searchClickFunction = () => {
		setActivePage(1);
		if (searchType === '1' && searchValue.split('').length === 0) {
			toast.error('Enter Test Type');
			document.getElementById("templateCode").focus()
			return false;
		}
		const postData = {
			hospitalId: localStorage.getItem("hospitalId"),
			offset: 0,
			max: 10,
			searchValue: searchValue,
			searchId: searchType,
		};
		callingAPI('labTestType/labTestTypeList', postData).then((data) => {
			if (data.data.success === '1') {
				setPageOffset(0);
				setTestList(data.data.result.testDetails);
				setTotalCount(data.data.result.totalCount);
			} else {
				setTestList([]);
			}
		}).catch();
	};
	const selectedStatusTypeChange = (id, status) => {
		var x = false;
		if (status === true) {
			x = window.confirm('Do you want to Disable this?');
		} else {
			x = window.confirm('Do you want to Enable this?');
		}
		if (x === true) {
			const postData = {
				labTestTypeId: id,
				labTestTypeIsActive: status == true ? false : true,
			};
			callingAPI('labTestType/activateTestType', postData).then((data) => {
				if (data.data.success === '1') {
					getTestListingDataValues();
				} else {
					setTestList([]);
				}
			}).catch();
		}
	};
	const getTestListingDataValues = () => {
		const postData = {
			hospitalId: localStorage.getItem("hospitalId"),
			offset: pageOffset,
			max: process.env.REACT_APP_PAGINATION_COUNT,
			searchId: searchType,
			searchValue: searchValue,
		};
		callingAPI('labTestType/labTestTypeList', postData).then((data) => {
			setTestList(data.data.result.testDetails);
			setTotalCount(data.data.result.totalCount);
			setDataFlag(true);
		}).catch();
	};
	useEffect(() => {
		getTestListingDataValues();
		document.getElementById('plpopup1').style.display = 'none';
	}, []);
	const saveValueFunction = (event) => {
		event.target.value = event.target.value.split('').length === 1 ? event.target.value.replace(/\s/, '') : event.target.value;
		setSaveValue(event.target.value);
	};
	const editButtonClick = (id, name) => {
		document.getElementById('plpopup1').style.display = '';
		document.getElementById('add').focus();
		setLabTestTypeId(id);
		setSaveValue(name);
	};
	const popUpSave = () => {
		setDataFlag(false)
		document.getElementById('plpopup1').style.display = 'none';
		document.getElementById('add').focus();
		if (displayName == 'Save') {
			if (saveValue.split('').length === 0) {
				toast.error('Enter Test Type');
				setDataFlag(true)
				document.getElementById('plpopup1').style.display = 'block';
				document.getElementById('add').focus();
				return false;
			}
			const postData = {
				hospitalId: localStorage.getItem("hospitalId"),
				labTestTypeName: saveValue,
			};
			callingAPI('labTestType/saveTestType', postData).then((data) => {
				if (data.data.success === '1') {
					document.getElementById('plpopup1').style.display = 'none';
					document.getElementById('add').focus();
					toast.success('Test Type Saved Successfully');
					setDataFlag(true)
					getTestListingDataValues();
					
					setTimeout(() => {
						window.location.reload()
					  }, 3000);
				} else if (data.data.success === '0') {
					setPageOffset(0);
					document.getElementById('plpopup1').style.display = 'block';
					document.getElementById('add').focus();
					setDataFlag(true)
					setSaveValue('');
					toast.error('Test Type Already Exist');
					setDataFlag(true)
					document.getElementById('plpopup1').style.display = 'block';
					document.getElementById('add').focus();
					return false;
				}
			}).catch();

		} else {
			if (saveValue.split('').length === 0) {
				toast.error("Enter Lab Test Type");
				setDataFlag(true)
				document.getElementById('plpopup1').style.display = 'block';
				document.getElementById('add').focus();
				return false;
			}
			const postData = {
				hospitalId: localStorage.getItem("hospitalId"),
				labTestTypeName: saveValue,
				labTestTypeId: labTestTypeId,
			};
			callingAPI('labTestType/saveTestType', postData).then((data) => {
				if (data.data.success === '1') {
					document.getElementById('add').focus();
					setSaveValue('');
					getTestListingDataValues();
					toast.success('Test Type Updated Successfully');
					setDataFlag(true)
				} else {
					if (data.data.success === '0') {
						toast.error('Test Type Already Exist');
						document.getElementById('plpopup1').style.display = 'block';
						document.getElementById('add').focus();
						getTestListingDataValues();
						setDataFlag(true)
					}
				}
			});
		}
	};
	const paginationHandling = (pageNumber) => {
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setPageOffset(offset);
		setActivePage(pageNumber);
		let postData = {
			hospitalId: localStorage.getItem("hospitalId"),
			offset: offset,
			max: max,
			searchId: searchType,
			searchValue: searchValue,
		};
		callingAPI('labTestType/labTestTypeList', postData).then((data) => {
			if (data.data.success === '1') {
				setTestList(data.data.result.testDetails);
				setTotalCount(data.data.result.totalCount);
			}
		});
	};
	const enterKey = (e) => {
		if (e.key === 'Enter') {
			searchClickFunction();
		}
	}
	return (
		<div className="dashboardCover">
			<ToastContainer position="bottom-right" style={{zIndex:"999999999"}}/>
			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle">Lab Test Type</div>
				</div>

				<div className="col-md-2">
					<a
						href="#plpopup1"
						onClick={() => {
							addButtonClick('Save');
							setPopupHeading('Add Lab Test Type');
						}}
						style={{ color: '#fff', textDecoration: 'none' }}
					>
						<span
							className="dashButtonBorder"
							style={{ background: '#22a2fd', border: 'none', color: '#fff' }}
						>
							Add +
						</span>
					</a>
				</div>
				{totalCount > 10 && (
					<div className="paginationSection">
						<Pagination
							// hideNavigation
							activePage={activePage}
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={totalCount}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={paginationHandling}
							hideDisabled
						/>
					</div>
				)}
			</div>
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select className="inputField" id="selectName" value={searchType} onChange={searchTypeOnChange}>
									<option value="0">Search In</option>
									<option value="1">Test Type</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<input
									id="templateCode"
									type="text"
									className="inputField"
									value={searchValue}
									disabled={isEnabled}
									onKeyDown={enterKey}
									autocomplete="off"
									onChange={searchValueFunction}
									style={{ background: searchType === "0" ? "transparent" : "white" }}
								/>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span className="dashButtonBorder" value={saveValue} onClick={searchClickFunction} title="Search">
									Search
								</span>
							</div>
							<div className="col-md-6 dashboardSearchList">
								<div className="searchCount">
									<b>Total : {testList.length == 0 ? 0 : totalCount}</b>
								</div>
							</div>
						</div>
					</div>
					<div className="dashboardtable">
						{dataFlag == false ? (
							<Loader />
						) : testList.length > 0 ? (
							<table>
								<thead>
									<tr>
										<th>Test Type</th>
										<th> Status </th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{testList.map((item, index) => (
										<tr>
											<td>{item.labTestTypeName}</td>
											<td>{item.labTestTypeIsActive === true ? 'ACTIVE' : 'INACTIVE'}</td>
											<td>
												<a
													href="#plpopup1"
													onClick={() => {
														editButtonClick(item.labTestTypeId, item.labTestTypeName);
														addButtonClick('Update');
														setPopupHeading('Edit Lab Test Type');
													}}
												>
													<img src={Editicon} title="Edit" />
												</a>
												{item.labTestTypeIsActive === true ? (
													<img
														src={tick}
														alt=""
														title="Click to Deactivate"
														className="mr-2  conf-cursorpointer"
														width="20"
														onClick={() => {
															selectedStatusTypeChange(item.labTestTypeId, true);
														}}
													/>
												) : (
													<img
														src={Close}
														title="Click to Activate"
														alt=""
														onClick={() => {
															selectedStatusTypeChange(item.labTestTypeId, false);
														}}
														style={{ width: '20px' }}
													/>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<p>
								<NoData />
							</p>
						)}
						{/* {testList.length == 0 && (
							<NoData />
						)} */}
						<br />
						{totalCount > 10 && (
							<div className="paginationSection">
								<Pagination
									// hideNavigation
									activePage={activePage}
									itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
									totalItemsCount={totalCount}
									pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
									onChange={paginationHandling}
									hideDisabled
								/>
							</div>
						)}
						{/* Popup Begins */}
						<div id="plpopup1" class="ploverlay">
							<div class="plpopup ">
								<h2>
									{popupHeading}{' '}
									<a class="close" href="#">
										<span class="popUpClose">x</span>
									</a>
								</h2>
								<div class="plcontent">
									<div class="addDataContent">
										<div class="addDataDiv">
											<label>
												Test Type<span class="mandatory">*</span>
											</label>
											<input
												type="text"
												placeholder="Enter Test Type"
												id="add"
												value={saveValue}
												onChange={saveValueFunction}
											/>
										</div>
										<div class="summaryBtnDiv">
											<button type="submit" disabled={disable} onClick={popUpSave} class="practiceGreenButton">
												{displayName}
											</button>
											<a class="close" href="#">
												<input
													type="submit"
													value="Cancel"
													class=" practiceGreyButton"
													style={{ fontFamily: 'Montserrat-SemiBold' }}
												/>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Popup Ends */}
					</div>
				</div>
			</div>
		</div>
	);
}
export default LabTestType;
