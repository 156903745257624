import React, { useEffect, useState } from 'react';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
import { callingAPI } from '../config';
import LabHeader from '../labLogin/layout/header';
import Footer from '../labLogin/layout/footer';
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';
import LabLeft from '../labLogin/labLeft'
import Datepicker, { CalendarContainer } from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";
import BillingLeft from '../billingLogin/billingLeft';
import BillingHeader from '../billingLogin/layout/header';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function LabOrderPayment() {
	const [memberPlanData, setMemberPlanData] = useState([]);
	const [memberPlanCountData, setMemberPlanCountData] = useState();
	const [functionName] = useState('Lab Order Payment');
	const [totalPrice, setTotalPrice] = useState('');
	const [totalTax, setTotalTax] = useState('');
	const [netAmount, setNetAmount] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState();
	const [filterName, setFilterInput] = useState('');
	const [searchText, setSearchText] = useState('');
	const [isData, setIsData] = useState(0);
	const exportFunction = () => {

		if (memberPlanData.length === 0) {
			toast.error("No Data to Export");
		}
		else {
			const csvRow = [];
			var memberPlanData11 = [['Date', 'Patient', 'Receipt%20Number', 'Invoice%20Number', 'Price', 'Tax', 'Amount']];
			var arrayDetails = memberPlanData11;
			const postData = {
				fromDate: fromDate ? formatDate(fromDate) : '',
				toDate: toDate ? formatDate(toDate) : '',
				cmbSelect: document.getElementById('selectName').value,
				txtInput: document.getElementById('searchText').value,
				roleName: window.sessionStorage.getItem("role"),
				userId: window.sessionStorage.getItem("userId"),
				max: "",
				offset: 0,
			};
			callingAPI('labOrderPayment/labOrderPayment', postData).then((response) => {
				// if (response.data.success === '0') {
				// 	alert("No Data to Export")
				//   }
				if (response.data.success === '1') {
					for (var item = 0; item < response.data.result.memberPlanData.length; item++) {
						memberPlanData11.push([
							String(response.data.result.memberPlanData[item].createddate).replace(/ /g, "%20").replace(/,/g, "%20"),
							String(response.data.result.memberPlanData[item].pateint).replace("null", "").replace(/ /g, "%20"),
							String(response.data.result.memberPlanData[item].recpt).replace(/ /g, "%20"),
							String(response.data.result.memberPlanData[item].invono).replace(/ /g, "%20").replace(/,/g, "-"),
							response.data.result.memberPlanData[item].totalprice,
							response.data.result.memberPlanData[item].totaltax,
							response.data.result.memberPlanData[item].ntamnt,
						]);
					}
				}
				for (var i = 0; i < memberPlanData11.length; ++i) {
					csvRow.push(memberPlanData11[i].join('|'));
					// csvRow.push(memberPlanData[i].replace(",","|"));
				}
				var csvString = csvRow.join('%0A');
				var a = document.createElement('a');
				a.href = 'data:attachment/csv, ' + csvString;
				a.target = '_Blank';
				a.download = functionName + '.csv';
				document.body.appendChild(a);
				a.click();
			})
		}
	};

	useEffect(() => {
		// changeText();
		let postData = {
			"fromDate": "",
			"toDate": "",
			"cmbSelect": "",
			"txtInput": "",
			"roleName": window.sessionStorage.getItem("role"),
			"userId": window.sessionStorage.getItem('userId'),
			"offset": 0,
			"max": 10,
		};
		callingAPI('labOrderPayment/labOrderPayment', postData)
			.then((response) => {
				if (response.data.success === '1') {
					setMemberPlanData(response.data.result.memberPlanData)
					setMemberPlanCountData(response.data.result.memberPlanCountData);
					setTotalPrice(response.data.result.totalPrice)
					setTotalTax(response.data.result.totalTax)
					setNetAmount(response.data.result.netAmount)
					setIsData(1);
				}
				else {
					setMemberPlanData([]);
					setMemberPlanCountData(0);
					setTotalPrice(0);
					setTotalTax(0);
					setNetAmount(0);
					setIsData(1);
				}
			})
			.catch();
	}, []);
	const searchClickFunction = () => {
		if (filterName !== '' && searchText === '') {
			var t = document.getElementById('selectName');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('searchText').focus();
			toast.error("Enter" + " " + selectedText);
			return false;
		}
		if (fromDate && toDate) {
			if (fromDate > toDate) {
				toast.error('End date must be greater than start date');
				document.getElementById("toDate").focus();
				return (false);
			}
		}
		setActivePage(1);
		const postData = {
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
			"cmbSelect": document.getElementById('selectName').value,
			"txtInput": document.getElementById('searchText').value,
			"roleName": window.sessionStorage.getItem("role"),
			"userId": window.sessionStorage.getItem("userId"),
			"offset": 0,
			"max": 10,
		};
		callingAPI('labOrderPayment/labOrderPayment', postData)
			.then((response) => {
				if (response.data.success === '0') {
					setMemberPlanData([]);
					setMemberPlanCountData(0);
					setTotalPrice(0);
					setTotalTax(0);
					setNetAmount(0);
					setIsData(1);
				}
				if (response.data.success === '1') {
					setMemberPlanData(response.data.result.memberPlanData)
					setMemberPlanCountData(response.data.result.memberPlanCountData);
					setTotalPrice(response.data.result.totalPrice)
					setTotalTax(response.data.result.totalTax)
					setNetAmount(response.data.result.netAmount)
					setIsData(1);
				}
			})
			.catch();
	};
	const handlePagination = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		}
		let postData = {
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
			"cmbSelect": "",
			"txtInput": "",
			"roleName": window.sessionStorage.getItem("role"),
			"userId": window.sessionStorage.getItem("userId"),
			max: max,
			offset: offset,
		};
		callingAPI('labOrderPayment/labOrderPayment', postData)
			.then((response) => {
				if (response.data.success === '1') {
					setMemberPlanData(response.data.result.memberPlanData)
					setMemberPlanCountData(response.data.result.memberPlanCountData);
					setTotalPrice(response.data.result.totalPrice)
					setTotalTax(response.data.result.totalTax)
					setNetAmount(response.data.result.netAmount)
					setIsData(1);
				}
			})
			.catch();
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	const filterByPatient = (value) => {
		setSearchText(value);
	}
	const changeText = (value) => {
		setFilterInput(value);
		setSearchText('');
		if (value === '') {
			setSearchText('');
			document.getElementById('searchText').disabled = true;
		} else {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		}
	};
	const onKeyDown = (event) => {
		if (event.key === 'Enter') {
			// alert("ssssssssss")
			searchClickFunction();
		}
	};
	// CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE BEGINS HERE
	const FromDateClear = () => {
		setFromDate("")
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>
				</div>
			</CalendarContainer>
		);
	};
	// CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
	const ToDateClear = () => {
		setToDate("")
	};
	// CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE BEGINS HERE
	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
					</span>
				</div>
			</CalendarContainer>
		);
	};
	const setReceiptRecptno = (htmlData) => {
		// window.sessionStorage.setItem('htmlData', htmlData);
		var a = `${process.env.PUBLIC_URL}/patients/RecieptPrint/Payment/${htmlData}`
		window.open(a, "_blank")
	};
	// CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
	return (
		<div className="coverWraper innerCoverWraper hospitalLabWraper hospitalLabOrder exportCalndrSec">
			<ToastContainer position="bottom-right" />
			{window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" ? <BillingHeader /> : <LabHeader />}
			<div className="contentWraper contentWraperScroll">
				{window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" ? <BillingLeft /> : <LabLeft />}
				<div className="settingsRight">
					<div className="dashboardCover pharmacyManufactureWrap">
						<div className="row">
							<div className="col-md-10">
								<div className="dashboardTitle">Lab Order Payment</div>
							</div>
						</div>
						<div className="paginationSection">
							{memberPlanCountData > 10 ? (
								<div className="paginationSection">
									<Pagination
										// hideNavigation
										activePage={activePage}
										hideDisabled
										itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
										totalItemsCount={memberPlanCountData}
										pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
										onChange={handlePagination} />
								</div>
							) : (
								''
							)}
						</div>
						<div className="dashboardContent">
							<div className="reportWrap">
								<div className="dashboardSearch">
									<div className="row">
										<div className="col-md-2 dashboardSearchList">
											<select className="inputField"
												id="selectName"
												name="selectName"
												value={filterName}
												onKeyDown={onKeyDown}
												title="Search In"
												onChange={(e) => changeText(e.target.value)}
											>
												<option value="">Search In</option>
												<option value="1">Patient Name</option>
												<option value="3">Invoice Number</option>
												<option value="2">Receipt Number</option>
											</select>
										</div>
										<div className="col-md-2 dashboardSearchList">
											{filterName == "" ? (
												<input
													type="text"
													className="inputField"
													value={searchText}
													autoComplete='off'
													onKeyDown={onKeyDown}
													onChange={(e) => filterByPatient(e.target.value)}
													id="searchText"
													style={{ background: "transparent" }}
													name="searchText"
													disabled
												/>
											) : (
												<input
													type="text"
													className="inputField"
													value={searchText}
													autoComplete='off'
													onKeyDown={onKeyDown}
													onChange={(e) => filterByPatient(e.target.value)}
													id="searchText"
													name="searchText"
													// maxLength={filterName == "3"&& "10"}
													autofocus="autofocus"
													disabled
												/>
											)}
										</div>
										<div className="col-md-2 dashboardSearchList">
											<div>
												<Datepicker
													autoComplete='off'
													required
													className="Datepicker pa2 inputField"
													calendarClassName="rasta-stripes"
													maxDate={new Date()}
													placeholderText="From Date"
													selected={fromDate}
													dateFormat="dd-MM-yyyy"
													onChange={(date) => setFromDate(date)}
													id="fromDate"
													peekNextMonth
													calendarContainer={MyContainer}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
													onKeyDown={(e) => {
														e.preventDefault();
													}}
												/>
												<label
													className="icon iconCalendar calendar plReportsCalendar"
													style={{ fontSize: '16px' }}
													htmlFor="fromdate"
												>
													<FontAwesomeIcon icon={faCalendarAlt} />
												</label>
											</div>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<div>
												<Datepicker
													autoComplete='off'
													required
													className="Datepicker pa2 inputField"
													placeholderText="To Date"
													maxDate={new Date()}
													calendarClassName="rasta-stripes"
													selected={toDate}
													dateFormat="dd-MM-yyyy"
													onChange={(date) => setToDate(date)}
													id="toDate"
													peekNextMonth
													showMonthDropdown
													calendarContainer={MyContainerTo}
													showYearDropdown
													dropdownMode="select"
													onKeyDown={(e) => {
														e.preventDefault();
													}}
												/>
												<label
													className="icon iconCalendar calendar plReportsCalendar"
													style={{ fontSize: '16px' }}
													htmlFor="todate"
												>
													<FontAwesomeIcon icon={faCalendarAlt} />
												</label>
											</div>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<span className="dashButtonBorder" onClick={searchClickFunction} onKeyDown={onKeyDown}>
												Search
											</span>
										</div>
										{/* {!memberPlanData.length ? (
										<div className="col-md-2 dashboardSearchList">
											<button
											type="button"
											style={{ width: "100%" }}
											id="export"
											className="dashButtonBorder dashButtonBorderGrey 111"
											onClick={noSearchData}
											>
											Export
											</button>
										</div>
										) : (
										<div className="col-md-2 dashboardSearchList">
											<button
											type="button" 
											style={{ border: "1px solid gainsboro" ,width: "100%" }} onClick={exportFunction}
											id="export"
											className="dashButtonBorder dashButtonBorderGrey 222"
											>
											Export
											</button>
										</div>
										)} */}
										<div className="col-md-2 dashboardSearchList">
											<span onClick={exportFunction} className="dashButtonBorder dashButtonBorderGrey" style={{ border: "1px solid gainsboro" }}>
												Export
											</span>
										</div>
									</div>
									<div className="row displayFlxEnd">
										<div className="gridItemLab ">
											<div className="searchTxt" style={{ textAlign: 'right' }}>
												<b>Total Price</b> : &#8377; {totalPrice}
											</div>
										</div>
										<div className=" gridItemLab">
											<div className="searchTxt" style={{ textAlign: 'right' }}>
												<b>Total Tax</b> : &#8377; {totalTax}
											</div>
										</div>
										<div className="gridItemLab ">
											<div className="searchTxt" style={{ textAlign: 'right' }}>
												<b>Net Amount</b> : &#8377; {netAmount}
											</div>
										</div>
										<div className=" gridItemLab">
											<div className="searchCount">
												No. of lab orders : {memberPlanCountData}
											</div>
										</div>
									</div>
								</div>
								{/* memberPlanData.length > 0 ? ( */}
								<div className="dashboardtable">
									{isData == 0 ? (<Loader />
									) : memberPlanData.length > 0 ?
										<table>
											<thead>
												<tr>
													<th>Date</th>
													<th>Patient Name </th>
													<th>Receipt Number</th>
													<th>Invoice Number</th>
													<th style={{ textAlign: 'right' }}>Price</th>
													<th style={{ textAlign: 'right' }}>Tax</th>
													<th style={{ textAlign: 'right' }}>Amount</th>
												</tr>
											</thead>
											<tbody>
												{memberPlanData.map((data, i) => (
													<tr>
														<td>{data.createddate}</td>
														<td>{data.pateint}</td>
														<td>
															<Link
																to="#"
																onClick={() =>
																	setReceiptRecptno(data.receiptId)}
															>{data.recpt}</Link>
														</td>
														{/* <td>{data.recpt}</td> */}
														<td>{data.invono}</td>
														<td style={{ textAlign: 'right' }}>
															&#8377; {data.totalprice}
														</td>
														<td style={{ textAlign: 'right' }}>
															&#8377; {data.totaltax}
														</td>
														<td style={{ textAlign: 'right' }}>
															&#8377; {data.ntamnt}
														</td>
													</tr>
												))}
											</tbody>
										</table>
										: <NoData />}
								</div>
							</div>
							<div className="paginationSection">
								{memberPlanCountData > 10 ? (
									<div className="paginationSection">
										<Pagination
											// hideNavigation
											hideDisabled
											activePage={activePage}
											itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
											totalItemsCount={memberPlanCountData}
											pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
											onChange={handlePagination} />
									</div>
								) : (
									''
								)}
							</div>
							{/* <div align="center" style={{ textAlign: 'center', marginTop: "15px" }}>
								<span class="successMessageRed successMessageRedBottom" style={{ clear: 'both' }}>
									* Total Amount Excluded Of Discount,PromoCode And Other Discount Coupons
								</span>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default LabOrderPayment;
