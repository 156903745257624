import Eyeicon from '../images/icons/eye.png';
import Datepicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-js-pagination';
import React, { useState, useEffect } from 'react';
import NoData from '../Common/noDataFound';
import { callingAPI } from '../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Common/loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function PrescriptionAmount() {
	const navigate = useNavigate();

	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState();
	const [prescriptionAmountDetails, setPrescriptionAmountDetails] = useState([]);
	const [totalCount, setTotalCount] = useState();
	const [doctorId, setDoctorId] = useState();
	const [activePage, setActivePage] = useState(1);
	const [pageOffset, setPageOffset] = useState(0);
	const [isData, setIsData] = useState(0);
	const [result, setResult] = useState([]);
	const handlePagination = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: max,
			offset: offset,
		};
		callingAPI('reports/prescriptionAmount', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setPrescriptionAmountDetails(data.data.result.prescriptionAmountDetails);
					setTotalCount(data.data.result.count);
					setIsData(1);
				}
			})
			.catch();
	};
	function formatDate(date) {
		var date = new Date(date),
			month = '' + (date.getMonth() + 1),
			day = '' + date.getDate(),
			year = date.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	const searchFunction = () => {
		// setIsData(0);
		if (fromDate && toDate) {
			if (fromDate > toDate) {
				toast.error('End date must be greater than start date');
				document.getElementById("todate").focus()
				return (false);
			}
		}
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: 0,
			cmbDoctor: document.querySelector('#selectDoctor').value,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
		};
		callingAPI('reports/prescriptionAmount', PostData)
			.then((data) => {
				if (data.data.success === '0') {
					setPrescriptionAmountDetails([]);
					setIsData(1);
				}
				if (data.data.success === '1') {
					setPrescriptionAmountDetails(data.data.result.prescriptionAmountDetails);
					setTotalCount(data.data.result.count);
					setIsData(1);
				}
			})
			.catch();
	};
	useEffect(() => {
		doctorApiFunction();
		const PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: pageOffset,
		};
		callingAPI('reports/prescriptionAmount', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setPrescriptionAmountDetails(data.data.result.prescriptionAmountDetails);
					setTotalCount(data.data.result.count);
					setDoctorId(data.data.result.prescriptionAmountDetails.doctorId);
					setIsData(1);
				}
				else {
					setPrescriptionAmountDetails([]);
					setTotalCount(0);
					setIsData(1);
				}
			})
			.catch();
	}, []);
	const doctorApiFunction = () => {
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			doctorAdd: 1
		};
		callingAPI('commonUserFunctions/doctorsList', PostData)
			.then((data) => {
				if (data.data.success === "1") {
					setResult(data.data.result.doctorsListArray);
				}
				else {
					toast.error("no doctors found")
				}

			})
			.catch();
	};

	const FromDateClear = () => {
		setFromDate("")
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>

				</div>
			</CalendarContainer>
		);
	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	const ToDateClear = () => {
		setToDate("")
	};
	const prescriptionAmountClick = (data) => {
		navigate('/reports/prescriptionAmountDetails', { state: data })
	}

	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};


	return (
		<div className="dashboardCover">
			<ToastContainer position="bottom-right" />
			<div className="dashboardTitle">Prescription(s) Amount</div>
			{/* {process.env.REACT_APP_PAGINATION_COUNT > 10 && ( */}
			{/* {prescriptionAmountDetails.length > 0 && (  */}
			{totalCount > 10 && (
				<div className="paginationSection">
					<Pagination
						// hideNavigation
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={totalCount}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePagination}
					/>
				</div>
			)}
			{/* )} */}
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select
									className="inputField"
									id="selectDoctor"
									title="Select Doctor"
									name="selectDoctor"
								>
									<option value="">Select Doctor</option>
									{result.map((data) => <option value={data.doctorId}>{data.doctorName}</option>)}
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div>
									<Datepicker
										autoComplete='off'
										required
										className="Datepicker pa2 inputField"
										calendarClassName="rasta-stripes"
										// maxDate={new Date()}
										placeholderText="From Date"
										selected={fromDate}
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setFromDate(date)}
										id="fromdate"
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										onKeyDown={(e) => {
											e.preventDefault();
										}}
										calendarContainer={MyContainer}
										onChangeRaw={handleDateChangeRaw}
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="fromdate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div>
									<Datepicker
										autoComplete='off'
										required
										className="Datepicker pa2 inputField"
										placeholderText="To Date"
										// maxDate={new Date()}
										calendarClassName="rasta-stripes"
										selected={toDate}
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setToDate(date)}
										id="todate"
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										onKeyDown={(e) => {
											e.preventDefault();
										}}
										calendarContainer={MyContainerTo}
										onChangeRaw={handleDateChangeRaw}
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="todate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span className="dashButtonBorder" onClick={searchFunction} title="Search">
									Search
								</span>
							</div>
							<div className="col-md-4 dashboardSearchList">
								<div className="searchCount">
									<b>
										Total Prescription count :{' '}
										{prescriptionAmountDetails.length == 0 ? 0 : totalCount}
									</b>
								</div>
							</div>
						</div>
					</div>
					<div className="dashboardtable">
						{isData == 0 ? (
							<Loader />
						) : prescriptionAmountDetails.length > 0 ? (
							<table className="PrescriptionAmnt">
								<thead>
									<tr>
										<th>Doctor</th>
										<th>Date </th>
										<th style={{ textAlign: 'right' }}>Total Amount</th>
										<th>View</th>
									</tr>
								</thead>
								<tbody>
									{prescriptionAmountDetails.map((index) => (
										<tr>
											<td>{index.doctorName}</td>
											<td>{index.doctorVisitMedicineVisitDate}</td>
											<td style={{ textAlign: 'right' }}>
												&#8377; {index.prescriptionTotalAmount === "" ? "0.00" : index.prescriptionTotalAmount}
											</td>
											{/* <td>&#8377;{(index.doctorVisitMedicineTotalAmount === "") ?"0.00":index.doctorVisitMedicineTotalAmount}</td> */}
											<td>
												{' '}
												{fromDate || toDate ?
													<nav
														onClick={() => prescriptionAmountClick({ data: index.doctorId, fDate: formatDate(fromDate), tDate: formatDate(toDate) })}
													>
														<img src={Eyeicon} />
													</nav>
													: <nav
														onClick={() => prescriptionAmountClick({ data: index.doctorId })}
													>
														<img src={Eyeicon} />
													</nav>
												}

											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<NoData />
						)}
					</div>
				</div>
				{/* {process.env.REACT_APP_PAGINATION_COUNT > 10 && ( */}
				{/* {prescriptionAmountDetails.length > 0 && (  */}
				{totalCount > 10 && (
					<div className="paginationSection">
						<Pagination
							// hideNavigation
							activePage={activePage}
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={totalCount}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={handlePagination}
						/>
					</div>
				)}
				{/* )}  */}
			</div>
		</div>
	);
}
export default PrescriptionAmount;
