import React, { useEffect, useState } from "react";
import PharmacyHeader from "./layout/header";
import Footer from "./layout/footer";
import { Route } from "react-router-dom";
import PharmacyLeft from "./pharmacyLeft";
import Datepicker, { CalendarContainer } from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import { callingAPI } from "../config";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function MedicineSalebyDay() {
  const csvDownload = "medicine Sale By Day";
  const [medicineListing, setMedicineListing] = useState([]);
  const [count, setCount] = useState("");
  const [totalBillingQuantity, setTotalBillingQuantity] = useState("");

  const [pageOffset, setPageOffset] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [booliansearchValue, setbooliansearchValue] = useState("");
  const [boolianfilterName, setboolianfilterName] = useState("");
  const [boolianfromDate, setboolianfromDate] = useState("");
  const [booliantoDate, setbooliantoDate] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [filterName, setFilterInput] = useState("");
  const [dataFlag, setDataFlag] = useState(false);
  const [drugListingArr, setDrugListingArr] = useState([]);
  const [idSearch, setIdSearch] = useState("0");
  const [active, setActive] = useState(false);


  const paginationView = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    if (!boolianfilterName) {
      setFilterInput("");
    }
    if (!booliansearchValue) {
      setSearchValue("");
    }
    if (!boolianfromDate) {
      setFromDate("");
    }
    if (!booliantoDate) {
      setToDate("");
    }

    const medicineParams = {
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      max: max,
      offset: offset,
      searchValue: booliansearchValue,
      searchId: boolianfilterName,
      txtInput: searchValue,
      fromDate: boolianfromDate ? formatDate(fromDate) : "",
      toDate: booliantoDate ? formatDate(toDate) : "",
    };
    setDataFlag(false);
    callingAPI("clinicalBackground/medicinedailyselling", medicineParams)
      .then((response) => {
        if (response.data.success === "0") {
          setMedicineListing([]);
          setCount(0);
          setTotalBillingQuantity(0)
          setDataFlag(true);
        }
        if (response.data.success === "1") {
          setMedicineListing(response.data.result.medicineDaySellingResult);
          setDataFlag(true);
        }
      })
      .catch();
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  //Listing
  useEffect(() => {
    const medicineParams = {
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: pageOffset,
    };
    callingAPI("clinicalBackground/medicinedailyselling", medicineParams).then(
      (response) => {
        if (response.data.success === "1") {
          setActive(true);
          setMedicineListing(response.data.result.medicineDaySellingResult);
          setCount(response.data.result.totalCount);
          setTotalBillingQuantity(response.data.result.totalBillingQuantity)
        } else {
          setActive(false);
          setMedicineListing([]);
          setCount(0);
          setTotalBillingQuantity(0)
        }
      }
    );
  }, []);
  const searchValueFunction = (event) => {
    setSearchValue(event.target.value);
    getPrecriptionMedicineAutoComplete();
  };
  const FromDateClear = () => {
    setFromDate("");
  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const ToDateClear = () => {
    setToDate("");
  };

  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  const getPrecriptionMedicineAutoComplete = () => {
    const postdata = {
      hospitalId: localStorage.getItem("hospitalId"),
      searchKeyword: document.getElementById("searchText").value,
      selectDropdownSearchType: "1",
    };
    if (document.getElementById("searchText").value.length > 1) {
      callingAPI(
        "treatmentProcedure/getDisposableItemsByCategory",
        postdata
      ).then((data) => {
        if (data.data.success === "1") {
          document.getElementById("prescriptionMedicineList").style.display =
            "block";
          setDrugListingArr(data.data.result);
        } else {
          document.getElementById("prescriptionMedicineList").style.display =
            "none";
          setDrugListingArr([]);
        }
      });
    }
  };
  const setMedicineName = (name, id) => {

    setSearchValue(name);
    setIdSearch(id);
    setDrugListingArr([]);
    document.getElementById("prescriptionMedicineList").style.display = "none";
  };
  const onBlurItemCategory = (e) => {
    if (!["react-datepicker__month-select", "react-datepicker__year-select"].includes(String(e.target.className))) {
      document.getElementById("prescriptionMedicineList").style.display = "none";
      setDrugListingArr([]);
    }
  };
  const searchValueOnChangeOnSelect = () => {

    setbooliansearchValue(searchValue);
    setboolianfilterName(filterName);
    setboolianfromDate(fromDate);
    setbooliantoDate(toDate);
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        toast.error("End date must be greater than start date");
        document.getElementById("todate").focus();
        return false;
      }
    }
    setActivePage(1);
    const searchParams = {
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      max: "10",
      offset: 0,
      searchValue: document.querySelector("#searchText").value,
      txtInput:
        document.querySelector("#searchText").value == "" ? "" : idSearch,
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
    };
    callingAPI("clinicalBackground/medicinedailyselling", searchParams)
      .then((response) => {
        if (response.data.success === "1") {
          setActive(true);
          // setIdSearch("0")
          setMedicineListing(response.data.result.medicineDaySellingResult);
          setCount(response.data.result.totalCount);
          setTotalBillingQuantity(response.data.result.totalBillingQuantity)
        }
      })
      .catch();
  };
  //csv download
  function csvFileDownload() {
    if (medicineListing.length === 0) {
      toast.error("No Data to Export");
    }
    else {
      const csvRow = [];
      var medicList = [["Date", "Medicine", "Billing%20Quantity"]];
      var name = medicineListing;
      let postData = {
        userId: window.sessionStorage.getItem("userId"),
        role: window.sessionStorage.getItem("role"),
        max: "",
        offset: 0,
        searchValue: document.querySelector("#searchText").value,
        txtInput: document.querySelector("#searchText").value == "" ? "" : idSearch,
        fromDate: fromDate ? formatDate(fromDate) : "",
        toDate: toDate ? formatDate(toDate) : "",
      };
      callingAPI("clinicalBackground/medicinedailyselling", postData).then(
        (response) => {

          for (
            var item = 0;
            item < response.data.result.medicineDaySellingResult.length;
            item++
          ) {
            medicList.push([
              response.data.result.medicineDaySellingResult[item].createddate
                .replace(/ /g, "%20")
                .replace(/,/g, "%20"),
              response.data.result.medicineDaySellingResult[item].medic
                .replace(/ /g, "%20")
                .replace(/,/g, "/ %20"),
              response.data.result.medicineDaySellingResult[item].sumTCost,
            ]);
          }
          for (var i = 0; i < medicList.length; ++i) {
            csvRow.push(medicList[i].join("|"));
          }
          var csvString = csvRow.join("%0A");
          var create = document.createElement("a");
          create.href = "data:attachment/csv," + csvString;
          // create.target = '_Blank';
          create.download = csvDownload + ".csv";
          document.body.appendChild(create);
          create.click();
        }
      );
    }
  }

  return (
    <div className="dashboardCover pharmacyManufactureWrap">
      <ToastContainer position="bottom-right" />
      <div className="row">
        <div className="col-md-10">
          <div className="dashboardTitle"> Medicine Sale by Day</div>
        </div>
      </div>
      {count > 10 && (
        <div className="paginationSection">
          <Pagination
            // hideNavigation
            hideDisabled
            activePage={activePage}
            itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
            totalItemsCount={count}
            pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
            onChange={paginationView}
          />
        </div>
      )}
      <div className="dashboardContent">
        <div className="reportWrap">
          <div className="dashboardSearch" onClick={onBlurItemCategory}>
            <div className="row">
              <div

                className="col-md-2 dashboardSearchList"
              >
                <input
                  autoComplete="off"
                  type="text"
                  className="inputField"
                  placeholder="Select Medicine"
                  id="searchText"
                  name="searchText"
                  value={searchValue}
                  // disabled={isEnabled}
                  onChange={searchValueFunction}
                  onKeyUp={() =>
                    getPrecriptionMedicineAutoComplete(
                      "",
                      "itemName",
                      "itemListDiv"
                    )
                  }
                />
                <ul
                  className="autoSearch autoSearch-p0 appointmentNumberSearch"
                  id="prescriptionMedicineList"
                  style={{ display: "none" }}
                >
                  {drugListingArr.map((item, i) => (
                    <li
                      onClick={() =>
                        setMedicineName(item.itemName, item.itemId)
                      }
                    >
                      {item.itemName}
                    </li>
                  ))}
                </ul>
                <input type="hidden" id="hiddenPrescriptionMedicineId" />
              </div>
              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    autoComplete="off"
                    required
                    id="fromdate"
                    calendarContainer={MyContainer}
                    onChangeRaw={handleDateChangeRaw}
                    className="Datepicker pa2 inputField"
                    calendarClassName="rasta-stripes"
                    // maxDate={new Date()}
                    placeholderText="From Date"
                    selected={fromDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => setFromDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="fromdate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    id="todate"
                    autoComplete="off"
                    className="Datepicker pa2 inputField"
                    placeholderText="To Date"
                    calendarClassName="rasta-stripes"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => setToDate(date)}
                    selected={toDate}
                    // maxDate={new Date()}
                    calendarContainer={MyContainerTo}
                    onChangeRaw={handleDateChangeRaw}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="todate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <span
                  onClick={searchValueOnChangeOnSelect}
                  className="dashButtonBorder"
                >
                  Search
                </span>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <span
                  onClick={csvFileDownload}
                  className="dashButtonBorder dashButtonBorderGrey"
                  style={{ border: "1px solid gainsboro" }}
                >
                  Export
                </span>
              </div>
            </div>
            <div className="row dflexend ">
              <div className="col-md-3">
                <div className="searchCount" style={{ margin: "0px" }}>
                  Total Billing Quantity : {totalBillingQuantity}
                </div>
              </div>
            </div>
          </div>
          { }
          <div className="dashboardtable">
            <div>
              {active == false ? (
                <div align="center">
                  <Loader />
                </div>
              ) : medicineListing.length > 0 && active == true ? (
                <table style={{ tableLayout: "fixed" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "230px" }}>Date</th>
                      <th>Medicine </th>
                      <th style={{ textAlign: "right" }}>Billing Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {medicineListing.map((data) => (
                      <tr>
                        <td> {data.createddate}</td>
                        <td>
                          <div
                            className="dashboardTableText elipsis"
                            title={data.medic}
                          >
                            {data.medic}
                          </div>
                        </td>
                        <td style={{ textAlign: "right" }}>{data.sumTCost}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoData />
              )}
            </div>
          </div>
        </div>
        {count > 10 && (
          <div className="paginationSection">
            <Pagination
              // hideNavigation
              hideDisabled
              activePage={activePage}
              itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
              totalItemsCount={count}
              pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
              onChange={paginationView}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default MedicineSalebyDay;
