import React, { useState, useEffect } from 'react';
import DatePicker, { CalendarContainer } from "react-datepicker";
import Editicon from '../images/icons/edit-bg.png';
import Tick from '../images/icons/active.png';
import Close from '../images/icons/close.png';
import { callingAPI } from '../config';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import NoData from '../Common/noDataFound';
import moment from 'moment';
import Loader from '../Common/loader';
import newStock from '../images/icons/newStock.png';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MedicineStockUpdate() {
	const navigate = useNavigate();

	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [hospitalInventoryList, setHospitalInventoryListData] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [count, setCount] = useState('');
	const [statusType, setStatusType] = useState('');
	const [pageOffset, setPageOffset] = useState(0);
	const [dataFlag, setDataFlag] = useState(false);

	var date = new Date();
	var requiredDate = moment(date).format('DD-MM-YYYY');
	var toDay = requiredDate.split('-');
	toDay = new Date(toDay[2], toDay[1] - 1, toDay[0]);
	// const enterKey = (e) => {
	//     if (e.key === 'Enter') {
	//         searchValueChange();
	//     }
	// }
	const statusChange = (id, status) => {
		var x = false;
		if (status === true) {
			x = window.confirm('Do you want to Enable this?');
		} else {
			x = window.confirm('Do you want to Disable this?');
		}
		if (x === true) {
			const satusData = {
				pmHospitalMedicineId: id,
				status: status,
				userId: window.sessionStorage.getItem('userId'),
			};

			callingAPI('inventoryStockUpdate/inventoryStockUpdateStatusChange', satusData).then((data) => {
				if (data.data.success === '1') {
					alert('Status Changed Successfully');
					inventorylisting();
				}
				if (data.data.success === '0') {
					alert(data.data.errorMessage);
				}
			});
		}
	};
	const onStatusChange = (e) => {
		setStatusType(e.target.value);
	};
	const MyContainerFromDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const MyContainerToDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const FromDateClear = () => {
		setStartDate("")

	};

	const ToDateClear = () => {
		setEndDate("")

	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}
	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		window.sessionStorage.setItem("pageNumberMedicineDetails", pageNumber);
		window.sessionStorage.setItem("offsetMedicineDetails", offset);
		let PostData = {
			cmbStatus: statusType,
			hospitalId: localStorage.getItem("hospitalId"),
			max: max,
			offset: offset,
			searchId: '1',
			searchValue: document.getElementById('searchText').value,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
			type: 'medicine',
		};

		callingAPI('inventoryStockUpdate', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setHospitalInventoryListData(data.data.result.inventoryList);
					setCount(data.data.result.totalCount);
				}
				else {
					setHospitalInventoryListData([]);
					setCount([]);
				}
			})
			.catch();
	};
	const changeText = () => {
		document.getElementById('searchText').value = '';
	};

	const newBatchMedicineClick = (data) => {
		navigate('/settings/newBatchMedicine', { state: data })
	};
	const editMedicineClick = (data) => {
		navigate('/settings/editMedicine', { state: data })
	};
	const searchValueChange = () => {
		if (startDate && endDate) {
			if (startDate > endDate) {
				toast.error('End date must be greater than start date');
				document.getElementById("todate").focus();
				return false;

			}
		}

		setActivePage(1);

		let PostData = {
			cmbStatus: statusType,
			hospitalId: localStorage.getItem("hospitalId"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: 0,
			searchId: '1',
			searchValue: document.getElementById('searchText').value,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
			type: 'medicine',
		};

		callingAPI('inventoryStockUpdate', PostData)
			.then((data) => {


				if (data.data.success === '1') {
					setHospitalInventoryListData(data.data.result.inventoryList);
					setCount(data.data.result.totalCount);
				}
				else {
					setHospitalInventoryListData([]);
					setCount([]);
				}
			})
			.catch();
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}
	useEffect(() => {
		changeText();



		inventorylisting()
	}, []);
	const inventorylisting = () => {
		setActivePage(Number(window.sessionStorage.getItem("pageNumberMedicineDetails")));
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: window.sessionStorage.getItem("offsetMedicineDetails"),
			type: 'medicine',
			searchId: '1',
			searchValue: document.getElementById('searchText').value,
		};

		callingAPI('inventoryStockUpdate', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setHospitalInventoryListData(data.data.result.inventoryList);
					setCount(data.data.result.totalCount);
					setDataFlag(true);
				}
				else {
					setHospitalInventoryListData([]);
					setCount(0);
					setDataFlag(true)
				}
			})
			.catch();
	};
	return (
		<div className="dashboardCover medicineStockUpdateWrap">
			<ToastContainer position="bottom-right" />
			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle">Medicine Stock Update</div>
				</div>
				<div className="col-md-2">
					<a href="/settings/addMedicine" style={{ textDecoration: 'none' }}>
						{' '}
						<span className="dashButtonBorder">Add Medicine</span>
					</a>
				</div>
			</div>
			{count > 10 && (
				<div className="paginationSection">
					<Pagination
						// hideNavigation
						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePageChange}
					/>
				</div>
			)}
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<input
									autocomplete="off"
									className="input-design inputField"
									type="text"
									id="searchText"
									name="searchText"
									placeholder="Enter Item Name"
								/>{' '}
							</div>
							<div className="col-md-2 dashboardSearchList">
								<select
									class="fulWidthSelect inputField"
									name="category"
									id="category"
									value={statusType}
									onChange={onStatusChange}
								>
									<option value="">Status</option>
									<option value="1">Active</option>
									<option value="2">InActive</option>
									<option value="3">Threshold</option>
								</select>
							</div>

							<div className="col-md-2 dashboardSearchList">
								<DatePicker
									required
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									className="Datepicker pa2 inputField  "
									placeholderText="Expiry From Date"
									calendarClassName="rasta-stripes"
									selected={startDate}
									dateFormat="dd-MM-yyyy"
									onChange={(date) => setStartDate(date)}
									id="fromdate"
									autoComplete='off'
									onChangeRaw={handleDateChangeRaw}
									calendarContainer={MyContainerFromDate}
								/>{' '}
								<label
									className="icon iconCalendar calendar plcalendar"
									style={{ fontSize: '16px' }}
									htmlFor="fromdate"
								>
									<FontAwesomeIcon icon={faCalendarAlt} />
								</label>
							</div>
							<div className="col-md-2 dashboardSearchList">

								<DatePicker
									required
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									className="Datepicker pa2 inputField"
									placeholderText="Expiry To Date"
									calendarClassName="rasta-stripes"
									selected={endDate}
									dateFormat="dd-MM-yyyy"
									autoComplete='off'
									onChange={(date) => setEndDate(date)}
									id="todate"
									onChangeRaw={handleDateChangeRaw}
									calendarContainer={MyContainerToDate}
								/>{' '}
								<label
									className="icon iconCalendar calendar plcalendar"
									style={{ fontSize: '16px' }}
									htmlFor="todate"
								>
									<FontAwesomeIcon icon={faCalendarAlt} />
								</label>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<button className="dashButtonBorder"
									onClick={searchValueChange}

									style={{ fontSize: "16px", fontFamily: "Montserrat-SemiBold", width: '100%' }}>
									Search
								</button>
							</div>
						</div>
					</div>
					{dataFlag == false ? (
						<Loader />
					) : hospitalInventoryList.length > 0 ? (
						<div className="dashboardtable">
							<table>
								<thead>
									<tr>
										<th style={{ width: "195px" }}>Medicine Name</th>
										<th>Batch No</th>
										<th style={{ textAlign: "center" }}>Quantity</th>
										<th style={{ textAlign: "right" }}>MRP</th>
										<th style={{ width: "150px", textAlign: "right" }}> MRP Unit Price</th>
										<th style={{ textAlign: "right" }}>Price</th>
										<th style={{ textAlign: "right" }}>Unit Price</th>
										<th>Expiry Date</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{hospitalInventoryList.map((item, index) => (
										<tr>
											<td >
												{' '}
												<span title={item.medicine + "-" + item.medicineTypeName + "-" + item.medicineStrength + "-" + item.medicineUnitName}
													// className="refundReason elipsis"
													className={
														new Date(
															parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[2]),
															parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[1]) - 1,
															parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[0])
														) <= toDay ? (
															'refundReason elipsis class-red'
														) : (
															'refundReason elipsis'
														)
													}
												>
													{item.medicine + "-" + item.medicineTypeName + "-" + item.medicineStrength + "-" + item.medicineUnitName}</span>
											</td>
											<td>
												{' '}
												<span title={item.pmHospitalMedicineBatchNo}
													className="refundReason elipsis">
													{item.pmHospitalMedicineBatchNo}
												</span>
											</td>
											<td style={{ textAlign: "center" }}
												className={
													parseFloat(item.pmHospitalMedicineThresholdNo) >=
														parseFloat(item.pmHospitalMedicineStockQty) ? (
														'class-red'
													) : (
														''
													)
												}
											>
												<span style={{ textAlign: "center", width: "100%" }} title={item.pmHospitalMedicineStockQty}
													className="refundReason elipsis">
													{item.pmHospitalMedicineStockQty}
												</span>
											</td>
											<td style={{ textAlign: "right" }}>
												<span title={item.pmHospitalMedicineMrp}
													className="refundReason  " style={{ textAlign: "right", width: "100%" }}>
													&#8377; {item.pmHospitalMedicineMrp}
												</span>
											</td>
											<td style={{ textAlign: "right" }}>
												<span title={item.pmHospitalMedicineMrpUnitPrice}
													className="refundReason  " style={{ textAlign: "right", width: "100%" }}>
													&#8377; {item.pmHospitalMedicineMrpUnitPrice}
												</span>
											</td>
											<td style={{ textAlign: "right" }}>
												<span title={item.pmHospitalMedicinePrice}
													className="refundReason  " style={{ textAlign: "right", width: "100%" }}>
													&#8377; {item.pmHospitalMedicinePrice}
												</span>
											</td>
											<td style={{ textAlign: "right" }}>
												<span title={item.pmHospitalMedicineUnitPrice}
													className="refundReason  " style={{ textAlign: "right", width: "100%" }}>
													&#8377; {item.pmHospitalMedicineUnitPrice}
												</span>
											</td>
											<td title={item.pmHospitalMedicineExpiryDate}
												// className={
												// 	item.pmHospitalMedicineExpiryDate >= requiredDate ? 'class-red' : ''
												// }
												className={
													new Date(
														parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[2]),
														parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[1]) - 1,
														parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[0])
													) <= toDay ? (
														'class-red'
													) : (
														''
													)
												}
											>
												{item.pmHospitalMedicineExpiryDateNew}
											</td>
											<td>
												<span
													onClick={() => editMedicineClick({ pmhospitalMedicineId: item.pmHospitalMedicineId })}
												>
													<img src={Editicon} title="Edit" style={{ width: '28px' }} />
												</span>
												{item.pmHospitalMedicineIsActive === 1 ? (
													<img
														src={Tick}
														title="Click to Deactivate "
														onClick={() => statusChange(item.pmHospitalMedicineId, false)}
														style={{ width: '20px' }}
													/>
												) : (
													<img
														src={Close}
														title="Click to Activate"
														onClick={() => statusChange(item.pmHospitalMedicineId, true)}
														style={{ width: '20px' }}
													/>
												)}
												<span
													onClick={() => newBatchMedicineClick({ pmhospitalMedicineId: item.pmHospitalMedicineId })}
												>
													<img src={newStock} style={{ marginLeft: '6px' }} title="Add New Batch" />
												</span>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<p>
							<NoData />
						</p>
					)}
				</div>
			</div>
			{count > 10 && (
				<div className="paginationSection">
					<Pagination
						// hideNavigation
						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePageChange}
					/>
				</div>
			)}
			{/* {hospitalInventoryList.length == 0 && <NoData />} */}
		</div>
	);
}

export default MedicineStockUpdate;
