import React, { useState, useEffect, useRef } from 'react';
import { callingAPI } from '../config';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import LabOrderRight from '../Patients/labOrderRight';
import Light from '../images/icons/light.png';
import Youtube from '../images/icons/youtube.png';
import $ from 'jquery';
import Loader from '../Common/loader';
import { Link, useLocation } from 'react-router-dom';
import LabLeft from '../labLogin/labLeft'
import LabHeader from '../labLogin/layout/header';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
let checkDate = ''
function TestResultAdd(prop) {
  $(document).ready(function () {
    $('#testResultAdd ').addClass("testResultAdd");

  })
  const [hospitalId, setHospitalId] = useState(localStorage.getItem("hospitalId"));
  const [userId, setUserId] = useState(window.sessionStorage.getItem('userId'));
  const [doctorId, setDoctorId] = useState(window.sessionStorage.getItem('doctorId'));
  const [actualUserId, setactualUserId] = useState(window.sessionStorage.getItem('actualUserId'));
  const [loader, setLoader] = useState(false);
  const [testResult, setTestResult] = useState([]);
  const [addArray, setAddArray] = useState([]);
  const [invoiceDataAppnd, testResultGroupDatanvoiceDataAppnd] = useState([]);
  const [disposableitemsaddedarr, setDisposableItemsAddedArr] = useState([]);
  const [disposableitemsAppend, setDisposableitemsAppend] = useState([]);
  const [data1, setData1] = useState('');
  const [testName, setTestName] = useState('');
  const [Normalvalue, setNormalvalue] = useState('');
  const [maxValue, setmaxValue] = useState('');
  const [Reading, setReading] = useState('');
  const [Unit, setUnit] = useState('');


  const [sptId, setsptId] = useState([]);
  const [showEditDiv, setShowEditDiv] = useState(false);
  const [customFlag, setcustomFlag] = useState("")
  const [editFlag, setEditFlag] = useState(false);
  const [flagvalue, setflagvalue] = useState(false);
  const location = useLocation();
  const [rightSection, setRightSection] = useState('');
  const [testmemberId, settestmemberId] = useState();
  const [istestFlag, setistestFlag] = useState("");
  const labResultAdd = (data) => {
    dataList(data);
  };
  useEffect(() => {

    if (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALLAB') {
      setRightSection('rightSection  settingsRight labloginMAin')

    } else if (window.sessionStorage.getItem('role') === 'ROLE_BILLINGADMIN') {
      setRightSection('rightSection  settingsRight BillingloginMAin')
    } else {
      setRightSection('')
    }

    //  if (window.sessionStorage.getItem('role') === 'ROLE_BILLINGADMIN') {
    //   setRightSection('rightSection  settingsRight BillingloginMAin')
    // } else {
    //   setRightSection('')
    // }

    if (location.state && Object.keys(location.state).length !== 0) {
      const { testmemberId } = location.state;
      const { testhospitalId } = location.state;
      const { istestFlag } = location.state;
      settestmemberId(testmemberId);
      setistestFlag(istestFlag)



      if (!istestFlag == "TestResult" || !istestFlag == "TestResultCollection" || !istestFlag == "TestResultLBD" || !istestFlag == "TestResultLBD1") {
        const { data, index, label } = location.state;
        dataEditArray(data, index)
        let editLabel = label === "Edit" ? true : false
        setEditFlag(editLabel)
      }

    }
  }, [])
  const dataEditArray = (val, index) => {
    if (val.labData[index].labDetails.length > 0) {
      let dateArray = val.labData[index].labDetails.sort((a, b) => a.parameter.localeCompare(b.parameter)).map((item) => {
        let data = {
          serviceProviderTestId: item.serviceProviderTestDetailsId,
          labOrderId: item.labOrderTestId,
          txtTestParam: item.parameter,
          txtNormVal: item.normalValue,
          txtMaxVal: '',
          cmbCategory: '',
          txtUnit: item.unitName == null ? '' : item.unitName,
          headerLblId: item.headId,
          hidnotedById: val.doctorId,
          txtPatRead: item.patientReadingValue,
          txtTestParamId: '',
          txtresultId: 0,
          hidDocVisitId: val.doctorVisitId,
          parameterHeadingName: item.headName
        }
        return data
      })
      var arrayObj = {
        doctorId: val.doctorId,
        docVisitId: val.doctorVisitId,
        createdOn: val.createdOn,
        testName: val.labData[index].testName,
        dateArray: dateArray,
        disposableitemsaddedarr: []
      }
      checkDate = val.createdOn
      testResult.push(arrayObj)
      setTestResult([...testResult])
    }
  }
  const dataList = (item) => {
    if (!addArray.includes(item.testDate + item.sptId)) {
      const PostData = {
        serviceProviderTestDetailsId: item.sptId,
        hospitalId: hospitalId
      };

      callingAPI('patientsListing/testResultAdd', PostData)
        .then((data) => {

          if (data.data.success === '1') {
            let dateArray = data.data.result.testResultParameter.sort((a, b) => a.parameterHeadingName.localeCompare(b.parameterHeadingName)).map((val) => {
              let data = {
                serviceProviderTestId: val.serviceProviderTestDetailsTestId,
                labOrderId: item.labOrderTestId,
                txtTestParam: val.parameterName,
                txtNormVal: val.parameterNormalvalue,
                txtMaxVal: '',
                cmbCategory: val.parameterNormaltype == "range" ? 1 : val.parameterNormaltype == "<" ? 2 : 3,
                txtUnit: val.parameterUnitName == null ? '' : val.parameterUnitName,
                headerLblId: 0,
                hidnotedById: doctorId != null ? doctorId : actualUserId,
                txtPatRead: '',
                txtTestParamId: '',
                txtresultId: 0,
                hidDocVisitId: item.docVisitId,
                parameterHeadingName: val.parameterHeadingName
              }
              return data
            })
            var arrayObj = {
              serviceProviderTestId: data.data.result.testResultParameter[0].serviceProviderTestDetailsTestId,
              doctorId: doctorId != null ? doctorId : actualUserId,
              labOrderId: item.labOrderTestId,
              txtTestParamId: '',
              docVisitId: item.docVisitId,
              createdOn: item.testDate,
              testName: item.testName,
              dateArray: dateArray,
              disposableitemsaddedarr: []
            }
            checkDate = item.testDate
            testResult.push(arrayObj)
            setTestResult([...testResult])
            setcustomFlag(data.data.result.testResultParameter.length)
            setsptId(data.data.result.testResultParameter[0].serviceProviderTestDetailsTestId)
          }
        })
        .catch();
      addArray.push(item.testDate + item.sptId)
    } else {

    }
  }
  const save = () => {
    let flag = true;
    if ($("#testName+i").val() === "") {
      toast.error("Fill Custom Reading")
      $("#testName+i").focus()
    }

    let newSetData = []
    let newSetDataDisposableArray = []
    for (let i = 0; i < testResult.length; i++) {

      testResult[i].dateArray.map((item, m) => {
        item.txtPatRead = $("#patientReading" + i + m).val()
        return item
      })

      newSetData.push(...testResult[i].dateArray)


      let newDisposableData = testResult[i].disposableitemsaddedarr.map((item, k) => {

        item.txtTestParam = $("#textName" + k).val()
        item.txtNormVal = $("#normalValue" + k).val()
        item.txtMaxVal = $("#maxValue" + k).val()
        item.cmbCategory = $("#Range" + k).val()
        item.txtUnit = $("#Unit" + k).val()
        item.headerLblId = 0

        item.txtPatRead = $("#Reading" + k).val()
        item.txtTestParamId = ''
        item.txtresultId = 0

        return item
      })

      newSetDataDisposableArray.push(...newDisposableData)
    }
    let dataCheck = newSetData.filter((data, index) => data.txtPatRead == "").map((item) => item)
    newSetData.push(...newSetDataDisposableArray)


    setLoader(true)
    let dataCheckArry = newSetData
    let finalArray = dataCheckArry.map((item) => {
      delete item.parameterHeadingName
      return item
    })

    let flag2 = true;
    if (dataCheck.length > 0) {
      flag = false
      setflagvalue(false)
      toast.error("Fill at least one reading in each Patient Readings")
      setLoader(false)
    }


    if (testName == '' || Reading === '' || Unit === '') {
      if (flagvalue == true) {
        toast.error("Fill Custom Reading")
        flag = false;
        setLoader(false)
        return;
      }

    }

    // else if (finalArray.length > 1) {
    //   for (let i = 0; i < finalArray.length; i++) {
    //     if (finalArray[i].txtTestParam == '' || finalArray[i].txtPatRead == '' || finalArray[i].txtUnit == '') {
    //       alert("Fill Custom Reading1")
    //       flag = false;
    //       setLoader(false)
    //       return;
    //     }

    //   }
    // }

    //


    //      else if(document.getElementsByName("textName").value==="") {
    //       alert('Fill Custom Readingvzx');
    //       flag = false;
    //       return;
    // }
    if (flag === true) {

      const postData = {
        userId: userId,
        hospitalId: hospitalId,
        labOrderData: finalArray
      };

      callingAPI('patientsListing/saveTestreadingsNew', postData)
        .then((data) => {
          setLoader(false)
          if (data.data.success === "1") {
            if (editFlag == false) {
              toast.success("Test Result Saved Successfully");
            }
            else {
              toast.success("Test Result Updated Successfully");
            }
            if (window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" && istestFlag === "TestResult" || istestFlag === "testResult") {
              window.location.href = "/labLogin/labTestBill"
            }
            else if (window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" && istestFlag === "TestResultCollection") {
              window.location.href = "/labLogin/labAddCollectionDate"
            }
            else if (window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" && istestFlag === "TestResultLBD") {
              window.location.href = "/labLogin/labOrderDetails"
            }
            else if (window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" && istestFlag === "TestResultLBD1") {
              window.location.href = "/labLogin/labOrderDetails"
            }
            else {
              window.sessionStorage.setItem("testresultmemebrid", testmemberId)
              window.location.href = "/patients/testResult"
            }
          }
          else {
            toast.error(data.data.errorMessage);
          }
        })
        .catch();
    }


  }
  const addRow = (i, item) => {
    setReading('')
    setTestName('')
    setUnit('')
    setflagvalue(true)
    const arrayobj = {
      serviceProviderTestId: editFlag == false ? item.serviceProviderTestId : item.dateArray[0].serviceProviderTestId,
      labOrderId: editFlag == false ? item.labOrderId : item.dateArray[0].labOrderId,
      txtTestParam: '',
      txtNormVal: '',
      txtMaxVal: '',
      cmbCategory: '',
      txtUnit: "",
      headerLblId: 0,
      hidnotedById: doctorId != null ? doctorId : actualUserId,
      txtPatRead: '',
      txtTestParamId: '',
      txtresultId: 0,
      hidDocVisitId: item.docVisitId
    }
    testResult[i].disposableitemsaddedarr.push(arrayobj)
    setTestResult([...testResult])

  };
  const removeTreatmentPlan = (index, item) => {
    let removed = [...testResult];
    removed.splice(index, 1);
    setTestResult(removed);
    var index = addArray.indexOf(item.createdOn + item.serviceProviderTestId);
    delete addArray[index];
  };
  const removeTreatmentPlanChild = (e, i, index) => {
    let removeArray = [...testResult]
    let removedArrayData = removeArray[index].disposableitemsaddedarr
    removedArrayData.splice(i, 1);
    removeArray[index].disposableitemsaddedarr = []
    removeArray[index].disposableitemsaddedarr.push(...removedArrayData)
    setTestResult(removeArray);
  };
  const rangeChange = (e, id) => {
    if (e.target.value !== "1") {
      $("#maxValue" + id).hide()
    } else {
      $("#maxValue" + id).show()
    }
  }
  const addNewTemplate = (idx, index) => {

    return (
      <div class="col-md-11" id={"customRow" + idx}>
        <ToastContainer position="bottom-right" />
        <div class="testResultRight row">
          <div class="col-md-3">
            <div class="testResultRightList">
              <input
                name="textName"
                id={"textName" + idx}
                type="text"
                placeholder="Test Name"
                onChange={(e) => { setTestName(e.target.value); }}
                className="inputField"
                required="true"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="testResultRightList">
              <input
                name="normalValue"
                id={"normalValue" + idx}
                type="text"
                onChange={(e) => { setNormalvalue(e.target.value); }}
                className="inputField"
                placeholder="Normal Value"
              />
            </div>
          </div>
          <div class="col-md-2" id="cmbCat42271">
            <div class="testResultRightList">
              <select
                class="mdmField inputField"
                name="Range"
                id={"Range" + idx}
                onChange={(e) => rangeChange(e, idx)}
              >
                <option value="1" selected="">
                  Range{" "}
                </option>
                <option value="2">&lt;</option>{" "}
                <option value="3">&gt;</option>
              </select>
            </div>
          </div>{" "}
          <div class="col-md-2" id="maxVal42271">
            <div class="testResultRightList">
              <input
                type="text"
                className="inputField"
                placeholder="Max Value"
                name="maxValue"
                onChange={(e) => { setmaxValue(e.target.value); }}
                id={"maxValue" + idx}
              />
            </div>
          </div>
          <div
            class="col-md-2  "
            id="patReadClass42271"
          >
            <div class="testResultRightList">
              <input
                type="text"
                className="inputField"
                placeholder="Reading"
                name="Reading"
                onChange={(e) => { setReading(e.target.value); }}
                id={"Reading" + idx}
              />
            </div>
          </div>
          <div class="col-md-1">
            <div class="testResultRightList">
              <input
                type="text"
                className="inputField"
                placeholder="Unit"
                name="Unit"
                onChange={(e) => { setUnit(e.target.value); }}
                id={"Unit" + idx} style={{ padding: '5px' }}
              />
            </div>
          </div>
          <div className="testResultClose">
            <span class="closeSuperAdmin" onClick={(e) => removeTreatmentPlanChild(e, idx, index)}>x</span>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="coverWraper">
      {window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" ? <LabHeader /> : <Header />}
      <div className="contentWraper contentWraperScroll pltestResult">
        {/* <Leftmenu /> */}
        {window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" ||
          window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" ? "" : <Leftmenu />}

        <div className={"rightSection" + " " + rightSection}>
          {window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" ? "" : <Profiledetail />}

          <div className="practiceManagementWrap">
            <div className="row practiceManagementRow">
              <div className="col-lg-8 col-md-12 practiceLeftSection">
                {loader === true ? <Loader /> : ''}
                <div
                  class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour"
                  style={{ background: "#fff", padding: '0px' }}
                >
                  <div id="divPatientContent" class="sideMenuContent">
                    <div class="treatmentPlanCover" id="treatmentPlanListDiv">
                      <div class="treatmentPlanHead" style={{ padding: '13px 10px 2px', marginBottom: '0px' }}>
                        {/* <h1>{data1}</h1> */}
                        <span class="treatmentPlanName">TEST RESULT</span>

                        {/* <span
                          class="treatmentPlanBtn pl_blue_btn"
                          style={{ background: "transparent", padding: "0" }}
                        >
                          <img src={Youtube} />
                        </span>
                        <span
                          class="treatmentPlanBtn pl_blue_btn"
                          style={{ background: "transparent", padding: "0" }}
                        >
                          <img src={Light} style={{ width: "25px" }} />
                        </span> */}
                        {testResult.length > 0 ?
                          <span onClick={(e) => save()} class="treatmentPlanBtn pl_blue_btn" style={{ background: "#5bb75b" }}>Save</span>
                          : null
                        }
                        <span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
                          {window.sessionStorage.getItem("role") === "ROLE_HOSPITALADMIN" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" ? (<a href="/patients/testResult">Cancel</a>) : (window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" && istestFlag === "TestResult" || istestFlag === "testResult" ?
                            <a href="/labLogin/labTestBill">Cancel</a> : window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" && istestFlag === "TestResultCollection" ? <a href="/labLogin/labAddCollectionDate">Cancel</a> : window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" && istestFlag === "TestResultLBD" ? <a href="/labLogin/labOrderDetails">Cancel</a> : window.sessionStorage.getItem("role") === "ROLE_HOSPITALLAB" && istestFlag === "TestResultLBD1" ? <a href="/labLogin/labOrderDetails">Cancel</a> : <a href="/labLogin/labOrderDetails">Cancel</a>)}
                        </span>
                      </div>
                      {/*  */}

                      <div
                        class="testResultAddCvrDiv"
                        style={{ background: "#fff", border: '1px solid #dadada', borderTop: "none" }}
                      >
                        <div class="testResultList testResultListHead row">
                          <div class="col-md-11">
                            <div class="testResultRight testResultparams_PTour row">
                              <div class="col-md-3">
                                <div class="testResultRightList">
                                  <label>Test Name </label>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="testResultRightList">
                                  <label>Normal Value</label>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="testResultRightList">
                                  <label >Patient Readings <sup class="mandatory" style={{ top: "8px" }}>*</sup></label>
                                </div>
                              </div>
                              <div class="col-md-1">
                                <div class="testResultRightList">
                                  <label>Units</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-1">
                            <div class="testResultLinkLst">
                              <label>&nbsp;</label>
                            </div>
                          </div>
                        </div>
                        <div></div>

                        {testResult.length > 0 ?
                          <div>
                            {testResult.map((item, index) => {
                              return (

                                <div id={"testWrap" + index} style={{ position: 'relative' }}>

                                  <div class="testResultList testResultListCustomCvr row">
                                    <div class="col-md-12 row">
                                      <div class="col-md-2" style={{ paddingRight: '0px' }}>
                                        <span class="testResultDateSpan" id="datePanel1908">{item.createdOn}</span>
                                      </div>
                                      <div class="col-md-8" style={{ paddingLeft: '0px' }}>
                                        <span class="testResultDateSpan">Test Name : {item.testName}</span>
                                      </div>
                                    </div>
                                    {item.dateArray.map((data, i) => {
                                      return (
                                        <div class="col-md-11">
                                          {i == 0 ?
                                            <div class="col-md-11 testResultSubHeading"><b>{data.parameterHeadingName}</b></div>
                                            :
                                            <div>
                                              {item.dateArray[i - 1].parameterHeadingName != data.parameterHeadingName ?
                                                <div class="col-md-11 testResultSubHeading"><b>{data.parameterHeadingName}</b></div>
                                                : null
                                              }
                                            </div>
                                          }
                                          {data.txtTestParam != "" &&
                                            <div class="testResultRight row ">

                                              <div class="col-md-3">
                                                <div class="testResultRightList" >
                                                  <input
                                                    id={"testName" + i}
                                                    type="text"
                                                    className="inputField"
                                                    readonly=""
                                                    value={data.txtTestParam}
                                                    required="true"
                                                  />
                                                </div>
                                              </div>

                                              <div class="col-md-4">
                                                <div class="testResultRightList">
                                                  <input
                                                    id={"Normaltype" + i}
                                                    type="text"
                                                    className="inputField"
                                                    readonly=""
                                                    value={data.txtNormVal}
                                                  />
                                                </div>
                                              </div>

                                              <div class="col-md-3">
                                                <div class="testResultRightList">
                                                  <input
                                                    id={"patientReading" + index + i}
                                                    type="text"
                                                    className="inputField"
                                                    defaultValue={data.txtPatRead}
                                                  //onChange={(e) => handleChange(e)}
                                                  />
                                                </div>
                                              </div>
                                              <div class="col-md-1">
                                                <div class="testResultRightList">
                                                  <input
                                                    id={"UnitName" + i}
                                                    type="text"
                                                    className="inputField"
                                                    readonly=""
                                                    value={data.txtUnit}
                                                  />
                                                </div>
                                              </div>
                                            </div>}
                                        </div>
                                      )
                                    })}
                                    <div class="col-md-1 testResultListCustom" style={{ paddingLeft: "0px" }}>
                                      <div class="testResultLink testResultCustomRead_PTour">
                                        <label>
                                          <a
                                            // href=""
                                            style={{
                                              color: "#23527c",
                                              textDecoration: "none",
                                            }}
                                            onClick={(e) => { addRow(index, item) }}

                                          >
                                            Custom Reading
                                          </a>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {item.disposableitemsaddedarr.length > 0 &&
                                    <div>
                                      {item.disposableitemsaddedarr.map((item, idx) => (
                                        <div>
                                          {addNewTemplate(idx, index)}
                                        </div>
                                      ))}
                                    </div>
                                  }
                                  <span
                                    class="treatmentContentClose"
                                    title="Remove"
                                    onClick={(p) => removeTreatmentPlan(index, item)}
                                  >
                                    x
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                          :
                          <div class="labOrderTestEditingProceDetails_PTour" style={{ display: 'block' }}>
                            <div class="prescriptionTrtmntListItem">
                              <span class="treatmentCommonTxt1 labTestMessage">
                                <span>Add a Lab Order from the right. Multiple item can be added</span>
                              </span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
              <div className="col-md-4 practiceRightSection">
                <LabOrderRight testMemberIdData={testmemberId} testIsFlagData={istestFlag} labOrderTestResult={labResultAdd} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TestResultAdd;
