import React, { useEffect, useState } from "react";
import LabHeader from '../labLogin/layout/header';
import Footer from '../labLogin/layout/footer';
import { Route } from 'react-router-dom';
import LabLeft from '../labLogin/labLeft'
import { callingAPI } from "../config";
import Datepicker, { CalendarContainer } from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function LabTestUnavailability() {
	const [labTestList, setlabTestList] = useState([]);
	const [labTestCount, setlabTestCount] = useState([]);
	const [labTestUnavilabilitySelectTest, setllabTestUnavilabilitySelectTest] = useState("");
	// const [labTestUnAvailability, setLabTestUnAvailability] = useState([]);
	const [labTestFromDate, setlabTestFromDate] = useState("");
	const [labTestToDate, setlabTestToDate] = useState("");
	const [pageOffset, setPageOffset] = useState(0);
	const [activePage, setActivePage] = useState(1);
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");
	const [dataFlag, setDataFlag] = useState(false);
	const getlabTestList = () => {
		let PostData = {
			userId: window.sessionStorage.getItem("userId"),
			role: window.sessionStorage.getItem("role"),
			cmbSelect: "",
			txtInput: "",
			max: 10,
			offset: 0,
			txtInput1: labTestUnavilabilitySelectTest,
			fromDate: labTestFromDate ? formatDate(labTestFromDate) : "",
			toDate: labTestToDate ? formatDate(labTestToDate) : "",
		};
		callingAPI("clinicalBackground/labTestUnavailability", PostData).then((response) => {
			if (response.data.success === "1") {
				setlabTestList(response.data.result.labUnAvailabilityResult);
				setlabTestCount(response.data.result.count);
				setDataFlag(true)
				//  setActivePage(1);
			}
			else {
				setlabTestList([])
				setlabTestCount(0);
				setDataFlag(true)
			}
		});
	};
	useEffect(() => {
		getlabTestList();
	}, []);
	const searchFunction = () => {
		if (labTestFromDate && labTestToDate) {
			if (labTestFromDate > labTestToDate) {
				toast.error("To date should be greater than or equal to from date");
				document.getElementById("todate").focus()
				return;
			}
		}
		setbooliansearchValue(labTestUnavilabilitySelectTest);
		setboolianfromDate(labTestFromDate);
		setbooliantoDate(labTestToDate);
		getlabTestList();
		setActivePage(1);
	};
	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === "1") {
			offset = "0";
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset =
				parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!booliansearchValue) { setllabTestUnavilabilitySelectTest("") }
		if (!boolianfromDate) { setlabTestFromDate("") }
		if (!booliantoDate) { setlabTestToDate("") }
		let PostData = {
			userId: window.sessionStorage.getItem("userId"),
			role: window.sessionStorage.getItem("role"),
			cmbSelect: "",
			txtInput: "",
			max: max,
			offset: offset,
			txtInput1: booliansearchValue,
			fromDate: boolianfromDate ? formatDate(labTestFromDate) : "",
			toDate: booliantoDate ? formatDate(labTestToDate) : "",
		};
		callingAPI("clinicalBackground/labTestUnavailability", PostData).then((response) => {
			if (response.data.success === "1") {
				setlabTestList(response.data.result.labUnAvailabilityResult);
				setDataFlag(true)
			}
		});
	};
	function formatDate(date) {
		var dates = new Date(date),
			month = "" + (dates.getMonth() + 1),
			day = "" + dates.getDate(),
			year = dates.getFullYear();
		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;
		return [year, month, day].join("-");
	}
	const downloadName = "labTestList";
	function exportListDownload() {
		if (labTestList.length === 0) {
			toast.error("No Data to Export");
		}
		else {
			const csvRow = [];
			var labTestlists = [
				[
					"Date",
					"Lab%20Test%20Name",
					"Prescribed%20Quantity",
				],
			];
			var name = labTestUnavilabilitySelectTest;
			let postData = {
				userId: window.sessionStorage.getItem("userId"),
				role: window.sessionStorage.getItem("role"),
				cmbSelect: "",
				txtInput: "",
				max: '',
				offset: 0,
				txtInput1: booliansearchValue,
				fromDate: boolianfromDate ? formatDate(labTestFromDate) : "",
				toDate: booliantoDate ? formatDate(labTestToDate) : ""
			};
			callingAPI('clinicalBackground/labTestUnavailability', postData).then((response) => {
				// if (response.data.success === '0') {
				// 	alert("No Data to Export")
				//   }
				if (response.data.success === '1') {
					for (var item = 0; item < response.data.result.labUnAvailabilityResult.length; item++) {
						labTestlists.push([
							response.data.result.labUnAvailabilityResult[item].cdate == null ? "" : response.data.result.labUnAvailabilityResult[item].cdate.replace(/,/g, '%20').replace(/ /g, '%20'),
							// .replace(/,/g, "%20")
							response.data.result.labUnAvailabilityResult[item].testname.replace(/[^a-zA-Z0-9/g]/g, " ").split(/\s{1,}/g).join(" %20").replace(/,/g, '%20').replace(/ /g, '%20'),
							response.data.result.labUnAvailabilityResult[item].tot,
						]);
					}
					for (var i = 0; i < labTestlists.length; ++i) {
						csvRow.push(labTestlists[i].join("|"));
					}
					var csvString = csvRow.join("%0A");
					var creat = document.createElement("a");
					creat.href = "data:attachment/csv," + csvString;
					// creat.target = "_Blank";
					creat.download = downloadName + ".csv";
					document.body.appendChild(creat);
					creat.click();
				}
			})
		}
	}
	// function noSearchData(){
	// 	alert("No Data to Export");
	//   }
	// CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE BEGINS HERE
	const FromDateClear = () => {
		setlabTestFromDate("")
		//document.getElementById("dashboardCover").click()
		// document.getElementById("fromdate").style.display ='none'
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	//    const handleDateChangeRaw = (e) => {
	// 	e.preventDefault();
	//   }
	// CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
	const ToDateClear = () => {
		setlabTestToDate("")
		// document.getElementById("fromdate").style.display ='none'
	};
	// CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE BEGINS HERE
	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	// CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
	return (
		<div className="coverWraper innerCoverWraper hospitalLabWraper hospitalLabOrder exportCalndrSec">
			<ToastContainer position="bottom-right" />
			<LabHeader />
			<div className="contentWraper contentWraperScroll">
				<LabLeft />
				<div className="settingsRight">
					<div className="dashboardCover pharmacyManufactureWrap">
						<div className="row">
							<div className="col-md-10">
								<div className="dashboardTitle">Lab Test Unavailability</div>
							</div>
						</div>
						<div className="paginationSection">
							{labTestCount > 10 &&
								<div className="paginationSection">
									<Pagination
										activePage={activePage}
										hideDisabled
										itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
										totalItemsCount={labTestCount}
										pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
										onChange={handlePageChange}
									/>
								</div>
							}
						</div>
						<div className="dashboardContent">
							<div className="reportWrap">
								<div className="dashboardSearch">
									<div className="row">
										<div className="col-md-2 dashboardSearchList" >
											<input
												type="text"
												className="inputField"
												placeholder="Enter Lab Test"
												value={labTestUnavilabilitySelectTest}
												onChange={(e) => setllabTestUnavilabilitySelectTest(e.target.value)}
												id="selectName"
												name="selectName"
											/>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<div>
												<Datepicker
													autoComplete='off'
													className="inputField"
													selected={labTestFromDate}
													dateFormat="dd-MM-yyyy"
													placeholderText="From Date"
													maxDate={new Date()}
													calendarContainer={MyContainer}
													onChange={(date) => setlabTestFromDate(date)}
													id="fromdate"
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<label
													className="icon iconCalendar calendar plReportsCalendar"
													style={{ fontSize: '16px' }}
													htmlFor="fromdate"
												>
													<FontAwesomeIcon icon={faCalendarAlt} />
												</label>
											</div>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<div>
												<Datepicker
													autoComplete='off'
													className="inputField"
													selected={labTestToDate}
													dateFormat="dd-MM-yyyy"
													placeholderText="To Date"
													maxDate={new Date()}
													calendarContainer={MyContainerTo}
													onChange={(date) => setlabTestToDate(date)}
													id="todate"
													showMonthDropdown
													onKeyDown={(e) => {
														e.preventDefault();
													}}
													showYearDropdown
													dropdownMode="select"
												/>
												<label
													className="icon iconCalendar calendar plReportsCalendar"
													style={{ fontSize: '16px' }}
													htmlFor="todate"
												>
													<FontAwesomeIcon icon={faCalendarAlt} />
												</label>
											</div>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<span className="dashButtonBorder" onClick={searchFunction}>
												Search
											</span>
										</div>
										{/* {!labTestList.length ? (
										<div className="col-md-2 dashboardSearchList">
											<button
											type="button"
											style={{ width: "100%" }}
											id="export"
											className="dashButtonBorder dashButtonBorderGrey 111"
											onClick={noSearchData}
											>
											Export
											</button>
										</div>
										) : (
										<div className="col-md-2 dashboardSearchList">
											<button
											type="button" 
											style={{ border: "1px solid gainsboro" ,width: "100%" }} onClick={exportListDownload}
											id="export"
											className="dashButtonBorder dashButtonBorderGrey 222"
											>
											Export
											</button>
										</div>
										)} */}
										<div className="col-md-2 dashboardSearchList">
											<span className="dashButtonBorder dashButtonBorderGrey" style={{ border: "1px solid gainsboro" }} onClick={exportListDownload}>
												Export
											</span>
										</div>
										<div className="col-md-2">
											<div className="searchCount">
												Total Lab Order : {labTestCount}
											</div>
										</div>
									</div>
								</div>
								<div className="dashboardtable">
									{dataFlag == false ? (
										<div align="center">
											<Loader />
										</div>
									) : (
										<div>
											{dataFlag == true && (
												<table>
													<thead>
														<tr>
															<th>Date</th>
															<th>Lab Test Name </th>
															<th>Prescribed Quantity</th>
														</tr>
													</thead>
													<tbody>
														{labTestList.map((itemlist, idx) => (
															<tr>
																<td>{itemlist.cdate}</td>
																<td>{itemlist.testname} </td>
																<td>{itemlist.tot} </td>
															</tr>
														))}
													</tbody>
												</table>
											)}
											{labTestList.length == 0 && dataFlag == true && <NoData />}
										</div>
									)}
								</div>
							</div>
							{labTestCount > 10 &&
								<div className="paginationSection">
									<Pagination
										activePage={activePage}
										hideDisabled
										itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
										totalItemsCount={labTestCount}
										pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
										onChange={handlePageChange}
									/>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default LabTestUnavailability;
