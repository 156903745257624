import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddTax() {
	const [category, setCategory] = useState('Hospital');
	const [taxName, setTaxName] = useState('');
	const [taxDisplayName, setTaxDisplayName] = useState('');
	const [description, setDescription] = useState('');
	const [taxValue, setTaxValue] = useState('');
	const [taxId, setTaxId] = useState('');
	const [isData, setIsData] = useState(true);
	const location = useLocation();
	useEffect(() => {
		if (location.state && Object.keys(location.state).length !== 0) {
			const { taxId, category } = location.state;
			setTaxId(taxId);
			//document.getElementById('categoryDiv').style.display = 'none';
			document.getElementById('addTaxLabel').innerHTML = 'Edit Tax Details';
			document.getElementById('saveTax').innerHTML = 'Update';
			const postdata = {
				taxId: taxId,
				category: category,
			};

			callingAPI('hospitalTaxDetails/hospitalTaxgetTaxDetails', postdata).then((data) => {

				if (data.data.success === '1') {
					setCategory(data.data.result.hospitaltaxDetails[0].category)
					setTaxName(data.data.result.hospitaltaxDetails[0].taxName);
					setTaxDisplayName(data.data.result.hospitaltaxDetails[0].taxDisplayName);
					setDescription(data.data.result.hospitaltaxDetails[0].description);
					setTaxValue(parseFloat(data.data.result.hospitaltaxDetails[0].taxValue).toFixed(2));
				}
			});
		}
	}, []);
	const saveTaxDetails = (e) => {
		setIsData(false);
		let flag = true;
		if (!taxName) {
			toast.error('Tax Name Required');
			document.getElementById('taxName').focus();
			flag = false;
			setIsData(true);
		} else if (!taxValue) {
			toast.error('Tax Value Required');
			document.getElementById('taxValue').focus();
			flag = false;
			setIsData(true);
		}
		if (flag === true) {
			const postData = {
				taxName: taxName,
				taxDisplayName: taxDisplayName,
				description: description,
				taxValue: taxValue,
				applyTaxOnId: '',
				category: category,
				hospitalId: localStorage.getItem("hospitalId"),
				taxId: taxId,
			};
			callingAPI('hospitalTaxDetails/hospitalTaxDetailsAdd', postData).then((data) => {
				setIsData(true);
				if (data.data.success === '1') {
					if (data.data.successMessage === "Success") {
						if (taxId === '') {
							toast.success('Tax Details Added Successfully');
						} else {
							toast.success('Tax Details Updated Successfully');
						}

						
						setTimeout(() => {
							window.location.href = '/settings/taxDetails';
						  }, 3000);
					}
					else {
						toast.success(data.data.successMessage)
						document.getElementById("taxValue").focus()
					}

				} else {
					toast.error(data.data.errorMessage);
				}
			});
		}
	};
	const numValidation = (e) => {
		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		if (e.target.value === '' || re.test(e.target.value)) {
			setTaxValue(e.target.value);
		}
	};
	return (
		<div className="dashboardCover">
			<ToastContainer position="bottom-right" />
			<div className="row">
				{isData === false ? <Loader /> : ''}
				<div className="col-md-10">
					<div className="dashboardTitle" id="addTaxLabel">
						{' '}
						Add Tax Details
					</div>
				</div>
				<div>
					<div class="practiceManagementForm" style={{ padding: '2px 0px' }}>
						<div class="practiceManagementFormBlcok">
							<form action="#" method="post">
								<ul className="row">
									{/* <li class="col-md-4 col-lg-4" id="categoryDiv">
									<label>Category </label>
										<input type="hidden" id="taxId" value={taxId} name="taxId" />

										<select
											name="category"
											id="category"
											value={category}
											onChange={(e) => setCategory(e.target.value)}
										>
											<option value="Hospital">Hospital</option>
											<option value="LAB">Lab</option>
										</select>
									</li> */}
									<li class="col-md-4 col-lg-4">
										<label>Tax Name *</label>
										<input
											type="text"
											id="taxName"
											name="taxName"
											autofocus="autofocus"
											placeholder="Name"
											value={taxName}
											onChange={(e) => setTaxName(e.target.value)}
										/>
									</li>
									<li class="col-md-4 col-lg-4">
										<label>Tax Display Name</label>
										<input
											type="text"
											id="taxDisplayName"
											value={taxDisplayName}
											onChange={(e) => setTaxDisplayName(e.target.value)}
											name="taxDisplayName"
											placeholder="Display Name"
										/>
									</li>
									<li class="col-md-4 col-lg-4">
										<label>Description</label>
										<input
											type="text"
											id="description"
											autofocus="autofocus"
											name="description"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											placeholder="Description"
										/>
									</li>
									<li class="col-md-4 col-lg-4">
										<label>Tax Value (%) *</label>
										<input
											type="text"
											id="taxValue"
											autofocus="autofocus"
											name="taxValue"
											placeholder="Value"
											value={taxValue}
											onChange={(e) => numValidation(e)}
											onKeyUp={(e) => setTaxValue(e.target.value)}
										/>
									</li>
									<br />
									<li class="col-md-12 col-lg-12 pull-right">
										<Link
											to="/settings/taxDetails"
											style={{ color: '#393939', textDecoration: 'none' }}
										>
											<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
												<span
													className="dashButtonBorder"
													style={{ border: '1px solid #b22222' }}
												>
													Cancel
												</span>
											</div>
										</Link>
										<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
											<span
												className="dashButtonBorder"
												id="saveTax"
												onClick={(e) => saveTaxDetails(e)}
											>
												Save{' '}
											</span>
										</div>
									</li>
								</ul>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddTax;
