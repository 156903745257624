import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Loader from '../Common/loader';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function StaffEdit() {
	const [staffList, setStaffList] = useState([]);
	const [staffData, setStaffData] = useState({
		secUserEmailId: '',
		secUserDisplayName: '',
		categoryId: '',
		password: '',
		ipAddress: '',
		macAddress: '',

	});
	const [dataFlag, setDataFlag] = useState(false);
	const [staffName, setstaffName] = useState('');
	const [staffemail, setstaffemail] = useState('');
	const [staffpassword, setstaffpassword] = useState('');
	const [categoryName, setcategoryName] = useState('');
	const [categoryId, setcategoryId] = useState('');
	const [macAddress, setmacAddress] = useState('');
	const [ipAddress, setipAddress] = useState('');
	const [hospitalStaffstaffId, sethospitalStaffstaffId] = useState('');
	const location = useLocation();
	const staffListDataforUpdation = (event) => {
		const { name, value } = event.target;
		setStaffData((prevState) => ({ ...prevState, [name]: value }));

	};
	var filter = /^(.([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*))@((\[[a-z]{1,3}\.[a-z]{1,3}\.[a-z]{1,3}\.[a-z]{1,3}])|(([a-z]+\.)+[a-z]{2,}))$/;
	useEffect(() => {
		if (location.state && Object.keys(location.state).length !== 0) {
			const {
				hospitalStaffstaffId,
				secUserEmailId,
				secUserDisplayName,
				categoryName,
				categoryId,
				macAddress,
				ipAddress,
				password,
			} = location.state;
			sethospitalStaffstaffId(hospitalStaffstaffId);
			setstaffemail(secUserEmailId);
			setstaffName(secUserDisplayName);
			setcategoryName(categoryName);
			setcategoryId(categoryId);
			setmacAddress(macAddress);
			setipAddress(ipAddress);
			setstaffpassword("");
			setStaffData({
				hospitalStaffstaffId: hospitalStaffstaffId,
				secUserEmailId: secUserEmailId,
				secUserDisplayName: secUserDisplayName,
				categoryId: categoryId,
				setcategoryId: categoryName,
				ipAddress: ipAddress,
				macAddress: macAddress,
				password: password,
			});
		}

		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			search: '',
			inputTxt: '',
		};
		callingAPI('staff/staffList', PostData).then((response) => {
			if (response.data.success === '1') {
				setDataFlag(true);
				setStaffList(response.data.StaffList);
			}
		})
			.catch();
	}, []);
	const editStaffData = () => {
		let flag = true;
		if (!staffName) {
			toast.error('Staff Name Required');
			document.getElementById('staffName').focus();
			flag = false;
		} else if (!staffemail) {
			toast.error('Email Required');
			document.getElementById('staffemail').focus();
			flag = false;
		} else if (!filter.test(staffemail)) {
			toast.error('Please provide a valid email address');
			document.getElementById('staffemail').focus();
			flag = false;
		}
		setDataFlag(false);
		// else if (!staffpassword) {
		// 	alert('Password Required');
		// 	document.getElementById('staffpassword').focus();
		// 	flag = false;
		// }

		if (flag === true) {
			const postData = {
				staffId: hospitalStaffstaffId,
				name: staffName,
				password: staffpassword,
				emailId: staffemail,
				categoryId: categoryId,
				ipAddress: ipAddress,
				macAddress: macAddress,
				hospitalId: localStorage.getItem("hospitalId"),
				pmSiteId: window.sessionStorage.getItem('siteId'),
				userId: window.sessionStorage.getItem('userId'),
			};
			callingAPI('staff/addStaffNew', postData).then((data) => {
				if (data.data.successMessage === 'Success') {
					toast.success('Staff Details Updated Successfully');
					setDataFlag(true);
					window.location.href = '/clinics/staffList';
				}
				else if (data.data.successMessage === 'Email Already Exists') {
					toast.error('Details not saved email id already exist');
					setDataFlag(true);
					// window.location.href = '/clinics/staffList';
				} else {
					toast.error(data.data.errorMessage);
				}
			});
		}
	};

	return (
		<div className="coverWraper">
			<ToastContainer position="bottom-right" />
			<Header />
			<div className="contentWraper contentWraperScroll pl_staffList">
				<div class="pulseContentCoverWraper">
					<div class="container">
						{dataFlag === false ? <Loader /> : ''}
						<div class="content4">
							<div class="container">
								<div class="serviceInfoWrap">
									<h4
										style={{
											paddingBottom: '8px',
											paddingLeft: '0px',
											fontSize: '20px',
											fontWeight: 'normal',
										}}
									>
										Staff Details
									</h4>
									<h5 style={{ paddingBottom: '0px', fontSize: '16px', fontWeight: 'normal' }}>
										Current Role : {categoryName}
									</h5>

									<h5 style={{ paddingBottom: '0px', fontSize: '16px', fontWeight: 'normal' }}>
										Staff ID : {hospitalStaffstaffId}
									</h5>
									<br />

									<div class="serviceListMain">
										<label for="" class="smalLbl">
											Staff Name
										</label>
										<input
											type="text"
											id="staffName"
											value={staffName}
											onChange={(e) => { setstaffName(e.target.value); staffListDataforUpdation(e); }}
											name="staffName"
										/>
									</div>

									<div class="serviceListMain">
										<label for="" class="smalLbl">
											Email
										</label>
										<input
											type="text"
											id="staffemail"
											value={staffemail}
											onChange={(e) => { setstaffemail(e.target.value); staffListDataforUpdation(e); }}
											name="staffemail"
										/>
									</div>

									<div class="serviceListMain">
										<label for="" class="smalLbl">
											Password
										</label>
										<input
											type="password"
											id="staffpassword"
											value={staffpassword}
											onChange={(e) => { setstaffpassword(e.target.value); staffListDataforUpdation(e); }}
											name="staffpassword"
										/>
										{/* <FontAwesomeIcon icon={faEye} onClick={togglePassword}/> */}
									</div>
									<div class="serviceListMain">
										<label for="" class="smalLbl">
											Mac Address
										</label>
										<input
											class="smyLargstField"
											type="text"
											autofocus="autofocus"
											id="macAddress"
											value={macAddress}
											onChange={(e) => { setmacAddress(e.target.value); staffListDataforUpdation(e); }}
											name="macAddress"
										/>
									</div>
									<div class="serviceListMain">
										<label for="" class="smalLbl">
											IP Address{' '}
										</label>
										<input
											class="smyLargstField"
											type="text"
											id="ipAddress"
											value={ipAddress}
											onChange={(e) => { setipAddress(e.target.value); staffListDataforUpdation(e); }}
											name="ipAddress"
											autofocus="autofocus"
										/>
									</div>
									<div class="right">
										<input
											type="submit"
											value="Update"
											href="#close"
											class="practiceGreenButton"
											title="Save"
											onClick={editStaffData}
										/>
										<a href="/clinics/staffList">
											<input
												type="submit"
												value="Cancel"
												class="practiceGreyButton"
												title="Cancel"
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="dividerSml" />
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default StaffEdit;
