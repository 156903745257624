import React from 'react';
import Logo from '../images/media/logo.png';
import { useEffect, useState } from 'react';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import AWS from 'aws-sdk';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var s3;

var getS3folder =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
function Emails() {
	const [file, setFile] = useState('');
	const [emailFormatData, setEmailFormat] = useState({});
	const [flag, setFlag] = useState(false);
	const [DisplayLogoFlag, setDisplayLogoFlag] = useState(false);
	const [DisplayLogoFormatFlag, setDisplayLogoFormatFlag] = useState(false);
	const [imageName, setImageName] = useState('');
	const [showEditDiv, setShowEditDiv] = useState(false);
	const [includeLogo, setIncludeLogo] = useState(true);
	const [logoType, setIncludeLogoType] = useState(0);
	const [flagsave, setflagsave] = useState(false);
	const [flagsave1, setflagsave1] = useState(false);
	const [printOutLogoTypes, setPrintOutLogoTypes] = useState([]);
	const [pictureForUpload, setPictureForUpload] = useState('');
	const [PictureName, setPictureName] = useState('');
	const [emailFormatHospitalHeaderTilte, setemailFormatHospitalHeaderTilte] = useState('');
	const [emailFormatHospitalHeaderLeftText, setemailFormatHospitalHeaderLeftText] = useState('');
	const [emailFormatHospitalHeaderRightText, setemailFormatHospitalHeaderRightText] = useState('');
	const [emailFormatHospitalFooterFullWidthContent, setemailFormatHospitalFooterFullWidthContent] = useState('');
	const [saveFlag, setSaveFlag] = useState(false)
	const [dataFlag, setDataFlag] = useState(false);
	AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
	const textchangetitle = (e) => {
		setemailFormatHospitalHeaderTilte(e.currentTarget.value);
	};
	const textchangeleft = (e) => {
		setemailFormatHospitalHeaderLeftText(e.currentTarget.value);
	};
	const textchangeright = (e) => {
		setemailFormatHospitalHeaderRightText(e.currentTarget.value);
	};
	const textchangefullwidth = (e) => {
		setemailFormatHospitalFooterFullWidthContent(e.currentTarget.value);
	};
	const cancelUpdate = () => {
		setShowEditDiv(false);
	};
	const savePrintHospitals = () => {
		if (document.getElementById('fileUpload').value === '') {
			setflagsave(false)
			setflagsave1(false)

		}
		saveEmailData()
		// if(flagsave == true){
		// 	setflagsave(false)
		// 	alert("Height and Width should be less than 50px")
		// }
		// else if(flagsave1 ==true){
		// 	setflagsave1(false)
		// 	alert('Please attach jpeg,jpg,gif,png files');
		// }

	}
	function IncludeLogo() {
		var x = document.getElementById('myDIV');
		document.getElementById('narrow').style.display = 'none';
		document.getElementById('wide').style.display = 'none';
		document.getElementById('square').style.display = 'none';
		x.style.display = 'block';
		document.getElementById('myDIVUpload').style.display = 'none';
		setFlag(false);
	}

	function NotIncludeLogo(e) {
		var x = document.getElementById('myDIV');
		document.getElementById('narrow').style.display = 'none';
		document.getElementById('wide').style.display = 'none';
		document.getElementById('square').style.display = 'none';
		x.style.display = 'none';
		document.getElementById('myDIVUpload').style.display = 'none';
		setFlag(true);
	}

	const onChangePictureUpload = (e) => {
		var x = document.getElementById('myDIV');
		document.getElementById('narrow').style.display = 'none';
		document.getElementById('wide').style.display = 'none';
		document.getElementById('square').style.display = 'none';
		x.style.display = 'block';
		setFile(e.target.files[0]);
		if (e.target.files.length !== 0) {
			setPictureForUpload(URL.createObjectURL(e.target.files[0]));
			setImageName(e.target.files[0].name.replace(/ /g, '-'));
		}
	};

	useEffect(() => {
		callingAPI('emailFormatHospital', {
			hospitalId: localStorage.getItem("hospitalId"),
		}).then((res) => {
			if (res.data.success === "1") {
				setPictureName(res.data.result.hospitalPrintOutList[0].hospitalImagesLogoImage);
				setEmailFormat(res.data.result.hospitalPrintOutList[0]);
				setemailFormatHospitalHeaderTilte(res.data.result.hospitalPrintOutList[0].emailFormatHospitalHeaderTilte);
				setemailFormatHospitalHeaderLeftText(
					res.data.result.hospitalPrintOutList[0].emailFormatHospitalHeaderLeftText
				);
				setemailFormatHospitalHeaderRightText(
					res.data.result.hospitalPrintOutList[0].emailFormatHospitalHeaderRightText
				);
				setemailFormatHospitalFooterFullWidthContent(
					res.data.result.hospitalPrintOutList[0].emailFormatHospitalFooterFullWidthContent
				);
				setIncludeLogo(res.data.result.hospitalPrintOutList[0].emailFormatHospitalHeaderIncludeLogo);
				setIncludeLogoType(res.data.result.hospitalPrintOutList[0].printOutLogoTypeId);
				setPrintOutLogoTypes(res.data.result.printOutLogoType);
				//setMarginTop()
				setDataFlag(true)
				window.scroll(0, 0);
			}
			else { setDataFlag(true) }
		});
	}, []);
	const saveEmailData = () => {
		window.scroll(0, 0);
		setSaveFlag(true)
		const postData = {
			emailFormatHospitalId: emailFormatData.emailFormatHospitalId,
			emailFormatHospitalFooterFullWidthContent: document.getElementById('footerFullWidthContent').value,
			emailFormatHospitalHeaderIncludeLogo: includeLogo,
			emailFormatHospitalHeaderLeftText: document.getElementById('headerLeftText').value,
			printOutLogoTypeId: logoType,
			emailFormatHospitalHeaderRightText: document.getElementById('headerRightText').value,
			emailFormatHospitalHeaderTilte: document.getElementById('headerTitle').value,
			hospitalId: localStorage.getItem("hospitalId"),
			userId: window.sessionStorage.getItem('userId'),
			emailFormatHospitalIsDefault: 0,
		};
		callingAPI('emailFormatHospital/emailFormatHospitalAddUpdate', postData).then((res) => {
			if (res.data.success == 1) {
				if (file != '') {
					imageS3Save();
				}

				toast.success('success');
				setTimeout(function () {
					window.location.reload(1);
				}, 1400);
			} else {
				toast.error('failed');
			}
		});
	};
	const getIncludeLogo = (e) => {
		if (e.currentTarget.value === 'Yes') {
			setIncludeLogo(true);
		} else {
			setIncludeLogo(false);
		}
	};

	const getLogoType = (e) => {
		setIncludeLogoType(e.currentTarget.value);
		if (e.currentTarget.value === '1') {
			setShowEditDiv(false);

			document.getElementById('narrow').style.display = 'none';
			document.getElementById('wide').style.display = 'none';
			document.getElementById('square').style.display = 'block';
			document.getElementById('myDIV').style.display = 'none';
		} else if (e.currentTarget.value === '2') {
			setShowEditDiv(false);

			document.getElementById('wide').style.display = 'none';
			document.getElementById('narrow').style.display = 'block';
			document.getElementById('square').style.display = 'none';
			document.getElementById('myDIV').style.display = 'none';
		} else {


			document.getElementById('wide').style.display = 'block';
			document.getElementById('narrow').style.display = 'none';
			document.getElementById('square').style.display = 'none';
			document.getElementById('myDIV').style.display = 'none';
		}
	};


	function UploadPictureValidation() {
		const DisplayLogo = () => {
			var x = document.getElementById('myDIV');
			x.style.display = 'none';
			document.getElementById('narrow').style.display = 'none';
			document.getElementById('wide').style.display = 'none';
			document.getElementById('square').style.display = 'none';
		};
		//Get reference of FileUpload.
		var fileUpload = document.getElementById('fileUpload');

		//Check whether the file is valid Image.
		var regex = new RegExp('([a-zA-Z0-9s_\\.-:])+(|.jpg|.png|.gif)$');
		if (regex.test(fileUpload.value.toLowerCase())) {

			//Check whether HTML5 is supported.
			if (typeof fileUpload.files != 'undefined') {
				//Initiate the FileReader object.
				var reader = new FileReader();
				//Read the contents of Image File.
				reader.readAsDataURL(fileUpload.files[0]);
				reader.onload = function (e) {
					//Initiate the JavaScript Image object.
					var image = new Image();

					//Set the Base64 string return from FileReader as source.
					image.src = e.target.result;

					//Validate the File Height and Width.
					image.onload = function () {
						var height = this.height;
						var width = this.width;

						const NotDisplayLogo = () => {
							var x = document.getElementById('myDIV');
							x.style.display = 'block';
							document.getElementById('narrow').style.display = 'none';
							document.getElementById('wide').style.display = 'none';
							document.getElementById('square').style.display = 'none';
						};
						const DisplayLogo = () => {
							var x = document.getElementById('myDIV');
							x.style.display = 'none';
							document.getElementById('narrow').style.display = 'none';
							document.getElementById('wide').style.display = 'none';
							document.getElementById('square').style.display = 'none';
						};
						if (height > 50 || width > 50) {
							setflagsave(true)
							setDisplayLogoFlag(height > 50 || width > 50)
							setTimeout(function () {
								toast.error('Width should be:50px and Height should be:50px');
								document.getElementById('fileUpload').value = ''
								setFile("")
							}, 80);
							return DisplayLogo();
						}
						setflagsave(false)
						setDisplayLogoFlag()
						return NotDisplayLogo;
					};
				};
			} else {
				toast.error('This browser does not support HTML5.');
				return DisplayLogo();
			}
		}
		else {
			setflagsave1(true)
			setTimeout(function () {
				toast.error('Please attach jpeg,jpg,gif,png files');
				document.getElementById('fileUpload').value = ''
				setFile("")
			}, 80);
			setDisplayLogoFormatFlag(true)
			return DisplayLogo();
		}
		setflagsave(false)
		setflagsave1(false)
		setDisplayLogoFormatFlag()
	}

	const imageS3Save = (e) => {
		var S3BucketMainFolder =
			process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
		var fullFilePath = S3BucketMainFolder + '/' + "hospitalLogo.png";

		s3.upload(
			{
				Key: fullFilePath,
				Body: file,
				ACL: 'public-read',
			},
			(err, data) => {
				if (err) {
					toast.error('There was an error uploading your photo: ' + err.message);
					return null;
				} else {


				}
			}
		);

	};

	const getLogoNochange = (e) => {
		if (flag == false) {
			getLogoType(e);
		} else {
			return false;
		}
	};
	return (
		<div className="practiceManagementForm printOutWrap">
			<ToastContainer position="bottom-right"/>
			<div className="col-md-12 col-lg-12">
				<h3 className="practiceHead">Emails </h3>
			</div>
			<div className="clearfix" />
			<div className="row">
				<div class="col-md-4 col-lg-4">
					<div class="practiceManagementFormBlcok">
						<form name="frm" enctype="multipart/form-data" id="frm">
							<input type="hidden" value="HOSPITAL" name="roleText" />
							<ul>
								<div class="LimitScrollDiv2" style={{ padding: '10px', paddingLeft: '0px' }}>
									{dataFlag === false ? <Loader /> : ''}
									<div class="clearfix">
										<h4 class="greenLink" style={{ fontWeight: 'normal', fontSize: '16px' }}>
											Header
										</h4>
										<li>
											<label style={{ fontWeight: 'normal' }}>
												Hospital Logo &nbsp;
												<font color="red">Maximum Size(50x50px)</font>
											</label>
											{DisplayLogoFlag || DisplayLogoFormatFlag ?

												<img id="imageThumb2" /> :
												<img id="imageThumb2" src={
													pictureForUpload || getS3folder + '/' + localStorage.getItem("hospitalId") + ".png"
												} />
											}

											<input
												type="file"
												onChange={(e) => {
													onChangePictureUpload(e);
													UploadPictureValidation();
												}}
												name="file"
												accept="image/x-png,image/gif,image/jpeg"
												id="fileUpload" title='Select Logo'
											/>
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Title</label>{' '}
											<input
												id="headerTitle"
												name="leftText"
												defaultValue={emailFormatData.emailFormatHospitalHeaderTilte}
												placeholder="Title"
												onChange={textchangetitle}
												type="text"
												maxlength="250"
											/>
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Left Text</label>{' '}
											<input
												id="headerLeftText"
												name="leftText"
												defaultValue={emailFormatData.emailFormatHospitalHeaderLeftText}
												placeholder="Left Text"
												onChange={textchangeleft}
												type="text"
												maxlength="250"
											/>
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Right Text</label>{' '}
											<input
												id="headerRightText"
												name="rightText"
												onChange={textchangeright}
												defaultValue={emailFormatData.emailFormatHospitalHeaderRightText}
												placeholder="Right Text"
												type="text"
												maxlength="250"
											/>
										</li>

										<li class="no-gutter row">
											<div class="col-md-6 col-lg-6">
												<label style={{ fontWeight: 'normal' }}>Include Logo </label>
												<div class="practiceRadio practiceRadio-primary practiceRadioSet">
													<div class="radio">
														<input
															onClick={getIncludeLogo}
															onChange={IncludeLogo}
															value="Yes"
															checked={includeLogo == true}
															id="radio-1"
															name="includeLogo"
															type="radio"
														/>
														<label for="radio-1" class="radio-label">
															Yes
														</label>
													</div>

													<div class="radio">
														<input
															onClick={getIncludeLogo}
															onChange={NotIncludeLogo}
															value="No"
															checked={includeLogo == false}
															id="radio-2"
															name="includeLogo"
															type="radio"
														/>
														<label for="radio-2" class="radio-label">
															No
														</label>
													</div>
												</div>
											</div>

											<div class="col-md-6 col-lg-6">
												<label style={{ fontWeight: 'normal' }}>Logo Type </label>
												<div class="practiceRadio practiceRadio-primary practiceRadioSet">
													{printOutLogoTypes.map((item) => (
														<div class="radio">
															<input
																id={'radio' + item.printOutLogoTypeId}
																value={item.printOutLogoTypeId}
																checked={logoType == item.printOutLogoTypeId}
																onChange={getLogoNochange}
																name={'logoType' + item.printOutLogoTypeId}
																type="radio"
															/>
															<label
																for={'radio' + item.printOutLogoTypeId}
																class="radio-label"
															>
																{item.printOutLogoTypeName}
															</label>
														</div>
													))}
												</div>
											</div>
										</li>
									</div>

									<div class="clearfix">
										<h4 class="greenLink" style={{ fontWeight: 'normal', fontSize: '16px' }}>
											Footer
										</h4>
										<li>
											<label style={{ fontWeight: 'normal' }}>Full Width Content</label>
											<input
												id="footerFullWidthContent"
												defaultValue={emailFormatData.emailFormatHospitalFooterFullWidthContent}
												placeholder="Full Width Content"
												onChange={textchangefullwidth}
												type="text"
												name="footerfullWidth"
												maxlength="255"
											/>
										</li>
									</div>
								</div>

								<li>
									{flagsave == false && flagsave1 == false ?
										<button
											type="button"
											disabled={saveFlag}
											class="practiceGreenButton"
											// onclick="return validateFunction()"
											onClick={() => {
												saveEmailData();
												cancelUpdate();
											}}
											value="Save"
										>
											Save
										</button> :
										<button
											type="button"
											disabled={saveFlag}
											class="practiceGreenButton"
											// onclick="return validateFunction()"
											onClick={() => {
												savePrintHospitals()
												cancelUpdate();
											}}
											value="Save"
										>
											Save
										</button>}
									{/* <!-- <button type="button" class="practiceGreyButton" value="Cancel">Cancel</button> --> */}
								</li>
							</ul>
						</form>
					</div>
				</div>
				<div class="col-md-8 col-lg-8 iframeBlockPractice">
					<div id="iFrameReport">
						<div class="iframePrintOutDiv" align="center" style={{ width: '100%', margin: '0px' }}>
							<table style={{ width: '100%', maxWidth: '100%', marginTop: '0px' }} border="0">
								<tbody>
									<tr height="80px">
										<td colspan="3" valign="middle" align="center">
											<div class="practicePrintlogoSquare">
												<p
													style={{
														fontWeight: '400',
														fontSize: '14px',
														clear: 'both',
														width: '100%',
														margin: '0',
													}}
												>
													<span
														style={{
															marginTop: '-5px',
															display: 'inline-block',
															width: '100%',
														}}
													>
														{/* <div id="s3image"> */}
														{/* <img src={getS3folder+"/"+""}  /> */}
														{/* </div>/ */}

														<div id="myDIV" style={{ display: showEditDiv ? '' : 'none' }}>
															{includeLogo == true &&
																logoType == '3' && (
																	<img
																		src={
																			pictureForUpload ||
																			getS3folder + '/' + localStorage.getItem("hospitalId") + ".png"
																		}
																		style={{
																			display: !showEditDiv ? '' : 'none',
																			width: (logoType == 3 ? "160px" : "", logoType == 3 ? "160px" : ""),
																			float: 'left',
																			height: 'auto',
																		}}
																		align="middle"
																	/>
																)}
															{includeLogo == true &&
																logoType == '2' && (
																	<img
																		src={
																			pictureForUpload ||
																			getS3folder + '/' + localStorage.getItem("hospitalId") + ".png"
																		}
																		style={{
																			display: !showEditDiv ? '' : 'none',
																			width: '140px',
																			height: 'auto',
																			float: 'left',
																		}}
																		align="middle"
																	/>
																)}
															{includeLogo == true &&
																logoType == '1' && (
																	<img
																		src={
																			pictureForUpload ||
																			getS3folder + '/' + localStorage.getItem("hospitalId") + ".png"
																		}
																		style={{
																			display: !showEditDiv ? '' : 'none',
																			width: '160px',
																			height: 'auto',
																			float: 'middle',
																		}}
																		align="middle"
																	/>
																)}
														</div>
														<div id="myDIVUpload">
															<img
																src={
																	pictureForUpload || getS3folder + '/' + localStorage.getItem("hospitalId") + ".png"
																}
																style={{ display: showEditDiv ? '' : 'none' }}
																align="middle"
															/>
														</div>

														<div
															id="narrow"
															style={{ display: !showEditDiv ? '' : 'none' }}
														>
															{logoType == '2' &&
																includeLogo == true && (
																	<div>
																		<img
																			src={
																				pictureForUpload ||
																				getS3folder + '/' + localStorage.getItem("hospitalId") + ".png"
																			}
																			style={{
																				width: '140px',
																				height: 'auto',
																				float: 'left',
																			}}
																			align="left"
																		/>
																		<div class="practicePrintName practiceNameSquare " style={{
																			float: "left",
																			width: "75%",
																			textAlign: "center"
																		}}>
																			<p
																				style={{
																					fontWeight: 'bold',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					marginBottom: '3px',
																				}}
																			>
																				{emailFormatHospitalHeaderTilte}
																			</p>
																			<p
																				style={{
																					fontWeight: '400',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					margin: '0px',
																				}}
																			>
																				{emailFormatData.hospitalAddress}
																			</p>
																		</div>
																	</div>
																)}
														</div>
														<div id="wide" style={{ display: !showEditDiv ? '' : 'none', width: "100%" }}>
															{logoType == '3' &&
																includeLogo == true && (
																	<div>
																		<img
																			src={
																				pictureForUpload ||
																				getS3folder + '/' + localStorage.getItem("hospitalId") + ".png"
																			}
																			style={{
																				width: '150px',
																				float: 'left',
																				height: 'auto',
																			}}
																			align="left"
																		/>
																		<div class="practicePrintName practiceNameSquare " style={{
																			float: "left",
																			width: "75%",
																			textAlign: "center"
																		}}>
																			<p
																				style={{
																					fontWeight: 'bold',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					marginBottom: '3px',
																				}}
																			>
																				{emailFormatHospitalHeaderTilte}
																			</p>
																			<p
																				style={{
																					fontWeight: '400',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					margin: '0px',
																				}}
																			>
																				{emailFormatData.hospitalAddress}
																			</p>
																		</div>
																	</div>
																)}
														</div>
														<div
															id="square"
															style={{ display: !showEditDiv ? '' : 'none' }}
														>
															{logoType == '1' &&
																includeLogo == true && (
																	<div>
																		<img
																			src={
																				pictureForUpload ||
																				getS3folder + '/' + localStorage.getItem("hospitalId") + ".png"
																			}
																			style={{
																				width: '160px',
																				height: 'auto',
																				float: 'middle',
																			}}
																			align="middle"
																		/>
																		<div class="practicePrintName practiceNameSquare ">
																			<p
																				style={{
																					fontWeight: 'bold',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					marginBottom: '3px',
																				}}
																			>
																				{emailFormatHospitalHeaderTilte}
																			</p>
																			<p
																				style={{
																					fontWeight: '400',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					margin: '0px',
																				}}
																			>
																				{emailFormatData.hospitalAddress}
																			</p>
																		</div>
																	</div>
																)}
														</div>
													</span>
												</p>
											</div>
											{/* <div class="practicePrintName practiceNameSquare ">
												<p
													style={{
														fontWeight: 'bold',
														fontSize: '14px',
														clear: 'both',
														width: '100%',
														marginBottom: '3px',
													}}
												>
													{emailFormatHospitalHeaderTilte}
												</p>
												<p
													style={{
														fontWeight: '400',
														fontSize: '14px',
														clear: 'both',
														width: '100%',
														margin: '0px',
													}}
												>
													{emailFormatData.hospitalAddress}
												</p>
											</div> */}
										</td>
									</tr>
									<tr style={{ display: 'table-row' }}>
										<td colspan="3">
											<hr color="#142536" />
										</td>
									</tr>
									<tr style={{ display: 'table-row' }}>
										<td colspan="1" style={{ textAlign: 'left', fontSize: '12px', width: "50%" }}>
											<span style={{ width: '95%', float: 'left', textAlign: 'left!important' }}>
												{emailFormatHospitalHeaderLeftText}
											</span>
										</td>
										{/* <td style={{ textAlign: 'center', paddingTop: '20px', fontSize: '12px' }}>
											<span
												style={{
													width: '100%',
													float: 'right',
													align: 'right',
													textAlign: 'right',
													width: '100px',
												}}
											/>
										</td> */}
										<td colspan="1" style={{ textAlign: 'right', fontSize: '12px', width: "50%" }}>
											<span
												style={{ width: '100%', float: 'left', textAlign: 'right!important' }}
											>
												{emailFormatHospitalHeaderRightText}
											</span>
										</td>
									</tr>

									<tr style={{ display: 'table-row' }}>
										<td colspan="3">
											<hr color="#142536" />
										</td>
									</tr>

									<tr>
										<td colspan="3">
											<table width="100%">
												<thead
													style={{
														background: 'none',
														height: '30px',
														fontSize: '0.9em',
														color: '#393939',
													}}
												>
													<tr>
														<th
															style={{
																textAlign: 'left',
																fontSize: '600',
															}}
														/>
														<th
															style={{
																textAlign: 'left',
																fontSize: '600',
															}}
														/>
														<th
															style={{
																textAlign: 'right',
																fontSize: '600',
																paddingRight: '10px',
															}}
														/>
													</tr>

													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter" />
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
												</thead>
											</table>
										</td>
									</tr>
									<tr>
										<td colspan="3">
											<hr color="#142536" />
										</td>
									</tr>
									<tr>
										<td colspan="3">
											<div align="justify" class="practiceFootBtm">
												<div style={{ float: 'left' }} />
												<div style={{ textAlign: 'center', fontSize: '14px' }}>
													{emailFormatHospitalFooterFullWidthContent}
												</div>
											</div>
										</td>
									</tr>

									<tr style={{ borderBottom: '.5px solid black' }}>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
												paddingTop: '40px',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
												paddingTop: '40px',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'right',
												fontSize: '600',
												paddingRight: '10px',
												paddingTop: '40px',
											}}
										/>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Emails;
