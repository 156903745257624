import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Light from '../images/icons/light.png';
import Youtube from '../images/icons/youtube.png';
import AWS from 'aws-sdk';
import med1 from '../images/icons/med1.png';
import med2 from '../images/icons/med2.png';
import med3 from '../images/icons/med3.png';
import med4 from '../images/icons/med4.png';
import med5 from '../images/icons/med5.png';
import med6 from '../images/icons/med6.png';
import med7 from '../images/icons/med7.png';
import med8 from '../images/icons/med8.png';
import Loader from '../Common/loader';
import Datepicker from 'react-datepicker';
import moment from 'moment';
import { callingAPI } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var s3;
var getS3folder =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH;

const styles = {
	border: '0.0625rem solid #9c9c9c',
	borderRadius: '0.25rem',
};
// function CasesheetMedicalAddWrite() {
const CasesheetMedicalAddWrite = forwardRef((props, ref) => {
	const canvas = React.createRef();
	const [imgName, setImgName] = useState(false);
	const [checkF, setChekF] = useState(false);
	const [sendImagedata, setImageData] = useState([]);
	const [getDrName, setDrName] = useState('');
	const [dataFlag, setDataFlag] = useState(false);
	const [imgArr, setImgArr] = useState([]);
	const [penColor, setPenColor] = useState('black');
	const [toggle, setToggle] = useState(false);
	const [nonGeneralData, setNonGeneralData] = useState([]);
	const [generalData, setGeneralData] = useState([]);
	const [doctorList, setDoctorList] = useState([]);
	const [general, setGeneral] = useState([]);
	const [nonGeneral, setNonGeneral] = useState([]);
	const [image, setImage] = useState([]);
	const [appointmentDate, setAppointmentDate] = useState('');
	const [appointmentNext, setAppointmentNext] = useState('');
	const [drawing, setDrawing] = useState([]);
	const [baseDrawing, setBaseDrawing] = useState([]);
	const [imageEdit, setImageEdit] = useState([]);
	const [disable, setDisable] = useState(false);
	const [followUpClick, setFollowUpClick] = useState(false);
	const [pmId, setpmId] = useState('');
	const [cmbStatus, setCmbStatus] = useState('3');
	const [futureDrVisitId, setFutureDrVisitId] = useState('')
	const [isChanged, setIsChanged] = useState(false);
	const [selectedAppointmentDate, setSelectedAppointmentDate] = useState('');
	const [futureAppointmentDate, setFutureAppointmentDate] = useState([]);
	const [convertedFutureDate, setConvertedFutureDate] = useState('');
	const [imgArr1, setImgArr1] = useState([]);
	const [imgFlag, setImgFlag] = useState(false);
	const [recallClick, setRecallClick] = useState(false);
	const [recallToggle, setRecallToggle] = useState(false);
	const [comments, setComments] = useState('');
	const [recallDate, setRecallDate] = useState('');
	AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });

	const removeImgArr = (e, i) => {
		let removed = [...imgArr];
		if (removed[i].imageId) {
			alert('Do you want to delete');
			const postData = {
				pmMemberComplaintsWriteId: removed[i].imageId,
			};
			callingAPI('commonUserFunctions/deleteImagePmmemberComplaintsWrite', postData)
				.then((data) => {
					if (data.data.success === '1') {
						removed.splice(i, 1);
						setImgArr(removed);
					}
				})
				.catch((err) => { });
		} else {
			removed.splice(i, 1);
			setImgArr(removed);
		}
	};
	useImperativeHandle(ref, () => ({
		canvasdetails() {
			return canvas;
		},
	}));
	const passData = async () => {
		await props.settingImageDate(props.imgArr, sendImagedata, canvas);
	};

	async function clearImage() {
		setChekF(false)
		canvas.current.clearCanvas();
		canvas.current.exportImage('png').then((data) => {
			if (checkF == false) {
				return false;
			}
			else if (data) {
				var copy = [...sendImagedata];
				var obj = {
					imageId: '',
					imageName: moment().valueOf() + 'MedicalCaseSheet.png',
					caseSheetImage: data,
				};
				var obj1 = {
					imageId: "",
					imageName: "",
					caseSheetImage: ""
				}
				setDrawing(obj.imageName);
				setBaseDrawing(obj.caseSheetImage);
				setImgArr([...imgArr, obj]);
				setImgArr1(obj1)
			}
		})
			.catch((e) => { });
	}
	const setImagePath = (e) => {
		var checkFlag = false;
		if (e.length > 0) {
			checkFlag = true;
			setChekF(true)
			canvas.current.exportImage('png').then((data) => {
				var obj = {
					imageId: '',
					imageName: moment().valueOf() + 'MedicalCaseSheet.png',
					caseSheetImage: data,
				}; setImgArr1(obj)
			})
		} else {
			checkFlag = false;
			setChekF(false)
		}
	};
	const clearCnvasImage = () => {
		canvas.current.clearCanvas();
		// props.isDrawnFunction(false)
	};
	const onChangelinkType = () => {
		window.location.href = '/patients/casesheetMedicalAdd';
	};
	const doctorClickFunction1 = (val) => {
		setDrName(val)
	};
	const doctorClickFunction = (event) => {
		const postData = {
			hospitalId: localStorage.getItem('hospitalId'),
		};
		callingAPI('commonUserFunctions/doctorList', postData)
			.then((data) => {
				if (data.data.success === '1') {
					setDoctorList(data.data.result.doctorList);
				} else {
					toast.error(data.data.errorMessage);
				}
			})
			.catch((err) => { });
	};
	useEffect(() => {
		doctorClickFunction();

		editingId();
		callingAPI('commonUserFunctions/reviewTypeView')
			.then((data) => {
				if (data.data.success === '1') {
					setGeneral(data.data.result.reviewTypeData);
					setNonGeneral(data.data.result.reviewGroupTypeData);
					setImage(data.data.result.reviewTypeImageData);
				} else {
					toast.error(data.data.errorMessage);
				}
			})
			.catch((err) => { });
	}, []);
	const generalClick = (event, checkedId, mainId) => {
		var symptomsIds = [];
		var tempData = generalData;
		var tempIndex = tempData.findIndex((x) => x.genMemberReviewTypeId == mainId);
		if (tempIndex == -1) {
			tempData.push({
				genMemberReviewTypeId: mainId,
				genSymptomsId: checkedId.toString(),
				genDescription: '',
			});
		} else {
			symptomsIds = tempData[tempIndex].genSymptomsId
				? tempData[tempIndex].genSymptomsId.toString().split(',')
				: [];
			if (event.target.checked == false) {
				var dataIndex = symptomsIds.findIndex((x) => x == checkedId);
				symptomsIds.splice(dataIndex, 1);
			} else {
				symptomsIds.push(checkedId.toString());
			}
			tempData[tempIndex].genSymptomsId = symptomsIds.toString();
		}
		setGeneralData(tempData);
	};
	const handleClickNonGeneral = (event, id, mainId) => {
		var symptomsIds = [];
		var tempData = nonGeneralData;
		var tempIndex = tempData.findIndex((x) => x.nonGenMemberReviewTypeId == mainId);
		if (tempIndex == -1) {
			tempData.push({
				nonGenMemberReviewTypeId: mainId,
				nonGenSymptomsId: id.toString(),
				nonGenDescription: '',
			});
		} else {
			symptomsIds = tempData[tempIndex].nonGenSymptomsId
				? tempData[tempIndex].nonGenSymptomsId.toString().split(',')
				: [];
			if (event.target.checked == false) {
				var dataIndex = symptomsIds.findIndex((x) => x == id);
				symptomsIds.splice(dataIndex, 1);
			} else {
				symptomsIds.push(id.toString());
			}
			tempData[tempIndex].nonGenSymptomsId = symptomsIds.toString();
		}
		setNonGeneralData(tempData);
	};
	const handleClick = (event, id) => {
		if (event.target.checked == false) {
			var tempData = nonGeneralData;
			var tempIndex = tempData.findIndex((x) => x.nonGenMemberReviewTypeId == id);
			tempData.splice(tempIndex, 1);
			setNonGeneralData(tempData);
		}
		if (document.getElementById('one' + id).checked === true) {
			document.getElementById('two' + id).style.display = 'block';
		} else {
			document.getElementById('two' + id).style.display = 'none';
		}
	};

	const recallClickSave = () => {

		let postData = {
			patientReacallId: "",
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId'),
			userId: window.sessionStorage.getItem('userId'),
			recallDateAndTime: recallDate ? Date.parse(moment(recallDate).format('YYYY-MM-DD hh:mm A')).toString() : "",
			status: "Pending",
			comments: comments
		}
		callingAPI('patientProfile/savePatientRecallDetails', postData).then((data) => {

		})
	}

	const saveClickFunction = async (e, type) => {
		if (!recallDate) {
			toast.error("Please Enter Recall date")
			return false
		}
		setDataFlag(false);
		if (disable) {
			return;
		}

		setDisable(true);
		var array1 = imgArr
		var selected = imgArr.find((object) => object.imageName);
		if (selected !== undefined) {
			if (selected.imageName === imgArr1.imageName) {
				setImgName(true)
			}
		}
		if (imgFlag === false) {
			if ([imgArr1].length > 0 && imgArr1.imageName !== '') {
				array1.push(imgArr1)
				setImgArr(array1)

			}
		}
		const base64 = baseDrawing;
		let fileName = drawing;
		let imageList = '';
		let S3BucketMainFolder =
			process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_S3_PRESCRIPTION_IMAGE_UPLOAD_PATH;
		for (let i = 0; i < imgArr.length; i++) {
			let fullFilePath = S3BucketMainFolder + imgArr[i].imageName;
			if (imgArr[i].imageId == '') {
				const buf = Buffer.from(imgArr[i].caseSheetImage.replace(/^data:image\/\w+;base64,/, ''), 'base64');
				imageList = imageList ? imageList + ',' + imgArr[i].imageName : imgArr[i].imageName;
				await s3.upload(
					{
						Key: fullFilePath,
						Body: buf,
						ContentEncoding: 'base64',
						ContentType: 'image/png',
					},
					(err, data) => {
						if (err) {
							toast.error('There was an error uploading your photo: ' + err.message);
						}
						//  else {
						// 	alert('File Uploaded Successfully');
						// }
					}
				);
			}
		}
		var temp = generalData;
		temp.map((data) => {
			data.genDescription = document.getElementById('txtRadiographGeneral' + data.genMemberReviewTypeId).value;
		});
		setGeneralData(temp);
		// var t = document.getElementById('cmbStatus');
		// var selectedText = t.options[t.selectedIndex].text;
		var temp = nonGeneralData;
		temp.map((data) => {
			data.nonGenDescription = document.getElementById('txtRadiograph' + data.nonGenMemberReviewTypeId).value;
		});
		setNonGeneralData(temp);

		const postData = {
			userId: window.sessionStorage.getItem('userId'),
			doctorId: getDrName ? getDrName : window.sessionStorage.getItem('patientDoctorId'),
			txtAppoimenttDate: moment(new Date()).format('YYYY-MM-DD hh:mm A'),
			memberId: window.sessionStorage.getItem('memberId'),
			hospitalId: localStorage.getItem('hospitalId'),
			scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
			scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
			txtFollowNote: appointmentNext,
			txtAppoimenttDateFuture: appointmentDate ? moment(appointmentDate).format('YYYY-MM-DD hh:mm A') : "",
			scheduledtimeFutureDateSlotStartTime: appointmentDate ? Date.parse(appointmentDate) : "",
			scheduledtimeFutureDateSlotEndTime: appointmentDate ? Date.parse(appointmentDate) + 15 * 60 * 1000 : "",
			hidFinalize: type == 'Save' ? 'false' : 'true',
			needFollowUp: followUpClick,
			hidMode: 'write',
			uploadedImageNames: imageList,
			cmbStatus: document.getElementById('cmbStatus').value,
			tag1: '',
			cheifDesc: '',
			txtCheifDate: '',
			tag2: '',
			txtAssociate: '',
			txtAssociateDate: '',
			tag3: '',
			txtKnown: '',
			txtKnownDate: '',
			txtObservations: '',
			txtInvestigations: '',
			txtRemarks: '',
			txtNeck: '',
			txtChest: '',
			txtEndo: '',
			txtAudiometry: '',
			txtEar: '',
			txtNose: '',
			txtThroat: '',
			txtDiagnosis: '',
			hidStatusId: '',
			currentDate: moment(new Date()).format('DD-MM-YYYY'),
			generalSection: generalData,
			nonGeneralSection: nonGeneralData,
		};
		callingAPI('commonUserFunctions/saveMedicalCaseSheet', postData).then((data) => {
			if (data.data.success === '1') {
				if (comments !== "" || recallDate !== "") {
					recallClickSave();
				}
				toast.success('Case Sheet Saved Successfully');
				setTimeout(() => {
				window.location.href = '/Patients/casesheetMedical';
				}, 1000)
				setDataFlag(true);

			} else {
				setDataFlag(true);
				toast.error(data.data.errorMessage);
			}
			setDisable(false);
		})
			.catch((err) => {
				setDisable(false);
			});
	};

	const updateClickFunction = async (e, type) => {
		setDataFlag(false);
		if (disable) {
			return;
		}
		setDisable(true);
		var array1 = imgArr
		if (imgFlag === false) {
			if ([imgArr1].length > 0 && imgArr1.imageName !== '') {
				array1.push(imgArr1)
				setImgArr(array1)

			}
		}
		let imageList = '';
		let S3BucketMainFolder =
			process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_S3_PRESCRIPTION_IMAGE_UPLOAD_PATH;
		for (let i = 0; i < imgArr.length; i++) {
			let fullFilePath = S3BucketMainFolder + imgArr[i].imageName;
			if (imgArr[i].imageId == '') {
				const buf = Buffer.from(imgArr[i].caseSheetImage.replace(/^data:image\/\w+;base64,/, ''), 'base64');
				imageList = imageList ? imageList + ',' + imgArr[i].imageName : imgArr[i].imageName;
				await s3.upload(
					{
						Key: fullFilePath,
						Body: buf,
						ContentEncoding: 'base64',
						ContentType: 'image/png',
					},
					(err, data) => {
						if (err) {
							toast.error('There was an error uploading your photo: ' + err.message);
						}
					}
				);
			}
		}
		var temp = generalData;
		temp.map((data) => {
			data.genDescription = document.getElementById('txtRadiographGeneral' + data.genMemberReviewTypeId).value;
		});
		setGeneralData(temp);
		// var t = document.getElementById('cmbStatus');
		// var selectedText = t.options[t.selectedIndex].text;
		var temp = nonGeneralData;
		temp.map((data) => {
			data.nonGenDescription = document.getElementById('txtRadiograph' + data.nonGenMemberReviewTypeId).value;
		});
		setNonGeneralData(temp);

		const postData = {
			userId: window.sessionStorage.getItem('userId'),
			doctorId: getDrName ? getDrName : window.sessionStorage.getItem('patientDoctorId'),
			memberId: window.sessionStorage.getItem('memberId'),
			hospitalId: localStorage.getItem('hospitalId'),
			txtAppoimenttDate: appointmentDate
				? moment(appointmentDate).format('YYYY-MM-DD hh:mm A')
				: "",
			scheduledtimeSlotStartTime: appointmentDate
				? Date.parse(appointmentDate).toString()
				: "",
			scheduledtimeSlotEndTime: appointmentDate
				? (Date.parse(appointmentDate) + 15 * 60 * 1000).toString()
				: "",
			needFollowUp: followUpClick,
			isChanged: isChanged,
			hidAptDocVisit: futureDrVisitId,
			hidDoctorVisitId1: window.sessionStorage.getItem('editImageId'),
			txtFollowNote: appointmentNext,
			hidFinalize: type == 'Save' ? 'false' : 'true',
			hidMode: 'write',
			uploadedImageNames: imageList,
			cmbStatus: document.getElementById('cmbStatus').value,
			hidUpdatePmcId: '',
			hidDiagnosisId: '',
			hidCurrentDate: moment(new Date()).format('YYYY-MM-DD hh:mm'),
			tag1: '',
			cheifDesc: '',
			txtCheifDate: '',
			tag2: '',
			txtAssociate: '',
			txtAssociateDate: '',
			tag3: '',
			txtKnown: '',
			txtKnownDate: '',
			txtObservations: '',
			txtInvestigations: '',
			txtRemarks: '',
			txtNeck: '',
			txtChest: '',
			txtEndo: '',
			txtAudiometry: '',
			txtEar: '',
			txtNose: '',
			txtThroat: '',
			txtDiagnosis: '',
			txtChiefReason: '',
			txtAssociatedReason: '',
			txtKnownCaseReason: '',
			txtObservationsReason: '',
			txtInvestigationsReason: '',
			txtRemarksReason: '',
			txtDiagnosisReason: '',
			hidStatusId: '',
			generalSection: generalData,
			nonGeneralSection: nonGeneralData,
		};
		callingAPI('commonUserFunctions/updateMedicalCaseSheet', postData).then((data) => {
			if (data.data.success === '1') {
				if (comments !== "" || recallDate !== "") {
					recallClickSave();
				}
				setDataFlag(true);
				setTimeout(() => {
				window.location.href = '/Patients/casesheetMedical';
				}, 1000)
				toast.success('Case Sheet Updated Successfully');
			} else {
				setDataFlag(true);
				toast.error(data.data.errorMessage);
			}
			setDisable(false);
		})
			.catch((err) => {
				setDisable(false);
			});
	};
	const editRecall = () => {
		const postData = {
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId')
		}
		callingAPI('patientProfile/getPatientRecallDetails', postData)
			.then((data) => {

				if (data.data.success === '1') {
					setRecallDate(data.data.result[0].recallDateAndTime)
					setComments(data.data.result[0].comments)
					setRecallToggle(true);
				}
			})
	}

	const editingId = (event) => {
		if (!window.sessionStorage.getItem('editImageId')) {
			document.getElementById('saveid').style.display = 'block';
			document.getElementById('lowsaveid').style.display = 'block';
			document.getElementById('updateid').style.display = 'none';
			document.getElementById('lowupdateid').style.display = 'none';
			// setDentalCaseSheetEditData([]);
			setDataFlag(true);
		} else {
			document.getElementById('saveid').style.display = 'none';
			document.getElementById('lowsaveid').style.display = 'none';
			document.getElementById('updateid').style.display = 'block';
			document.getElementById('lowupdateid').style.display = 'block';
			const postData = {
				doctorVisitId: window.sessionStorage.getItem('editImageId'),
				userId: window.sessionStorage.getItem('userId'),
				hospitalId: localStorage.getItem('hospitalId'),
				memberId: window.sessionStorage.getItem('memberId'),
			};
			callingAPI('commonUserFunctions/clinicalNotesAdd', postData)
				.then((data) => {
					if (data.data.success === '1') {
						editRecall();
						setDataFlag(true);
						let imageListArr = [];
						let dataImg = data.data.result.image ? data.data.result.image[0].imageName.split(',') : '';
						let dataImgId = data.data.result.image ? data.data.result.image[0].imageId.split(',') : '';
						let imgPath =
							process.env.REACT_APP_S3_BUCKET_URL +
							process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
							process.env.REACT_APP_S3_PRESCRIPTION_IMAGE_UPLOAD_PATH;
						for (let i = 0; i < dataImg.length; i++) {
							imageListArr.push({
								imageId: dataImgId[i],
								imageName: dataImg[i],
								caseSheetImage: imgPath + dataImg[i],
							});
						}
						setImgArr(imageListArr);
						setImageEdit(data.data.result.image[0].imageName.split(','));
						setGeneralData(data.data.result.generalSection);
						setNonGeneralData(data.data.result.nonGeneralSection);
						// setpmId(data.data.result.PmIdData);
						setCmbStatus(data.data.result.status);
						setFutureAppointmentDate(data.data.result.futureAppoinmentDateData);
						if (data.data.result.futureAppoinmentDateData.length > 0) {
							var date = data.data.result.futureAppoinmentDateData[0].appointDate;
							var d = new Date(date);
							var convertedDate = moment(d).format('YYYY-MM-DD hh:mm A');
							var nextappointmentdate = moment(convertedDate).format('DD-MM-YYYY hh:mm A')
							setSelectedAppointmentDate(convertedDate)
							document.getElementById('weee').style.visibility = 'hidden'
						}
						else {
							setSelectedAppointmentDate('')
						}

						setConvertedFutureDate(nextappointmentdate)
						if (data.data.result.generalSection.length > 0) {
							document.getElementById('radGeneral1').checked = true;
							let innerChkArr = data.data.result.generalSection[0].genSymptomsId
								? data.data.result.generalSection[0].genSymptomsId.toString().split(',')
								: [];
							for (let i = 0; i < innerChkArr.length; i++) {
								document.getElementById('chkgenSymptom1' + innerChkArr[i]).checked = true;
							}
							document.getElementById('txtRadiographGeneral1').value =
								data.data.result.generalSection[0].genDescription;
						}
						for (let i = 0; i < data.data.result.nonGeneralSection.length; i++) {
							document.getElementById(
								'one' + data.data.result.nonGeneralSection[i].nonGenMemberReviewTypeId
							).checked = true;

							document.getElementById(
								'two' + data.data.result.nonGeneralSection[i].nonGenMemberReviewTypeId
							).style.display =
								'block';

							let innerChkArr = data.data.result.nonGeneralSection[i].nonGenSymptomsId
								? data.data.result.nonGeneralSection[i].nonGenSymptomsId.toString().split(',')
								: [];
							for (let j = 0; j < innerChkArr.length; j++) {
								document.getElementById('chkSymptoms6' + innerChkArr[j]).checked = true;
							}
							document.getElementById(
								'txtRadiograph' + data.data.result.nonGeneralSection[i].nonGenMemberReviewTypeId
							).value =
								data.data.result.nonGeneralSection[i].nonGenDescription;
						}
					}

				})
				.catch();
		}
	};
	const finalizeClick = (e) => {
		if (window.sessionStorage.getItem('editImageId') === '') {
			saveClickFunction(e, 'Finalize');
		} else {
			updateClickFunction(e, 'Finalize');
		}
	};
	return (
		<div className="coverWraper">
			<ToastContainer position="bottom-right" style={{ zIndex: 99999 }} />
			<Header />
			<div className="contentWraper contentWraperScroll patientPayment pl_casesheetWrap caseshtMedAdd ">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />

					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										{dataFlag === false ? <Loader /> : ''}
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">CASE SHEET &nbsp;</span>
												{/*  */}
												<div class="radioAreaType prescriptionradioBtn_PTour" id="radioareaid">
													<span class="precriptionDetails_PTour" style={{ float: 'left' }}>
														<input
															type="radio"
															name="radiobtn"
															value="type"
															id="radio3Id"
															class="precriptionTypeDetails_PTour"
															onChange={onChangelinkType}
														/>
														{/* <a
															href="/Patients/casesheetMedicalAdd"
															style={{ color: '#000', float: 'left' }}
														> */}
														<label
															class="radiolabel"
															style={{ color: '#000', float: 'left' }}
														>
															Type&nbsp;&nbsp;
														</label>
														{/* </a> */}
														<input
															type="radio"
															name="radiobtn"
															value="write"
															id="radio4Id"
															class="precriptionWriteDetails_PTour"
															checked
														/>
														<a
															href="/patients/casesheetMedicalAddWrite"
															style={{ color: '#000' }}
														>
															<label class="radiolabel">Write</label>
														</a>
													</span>
												</div>
												{/*  */}
												{/* <span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Youtube} />
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Light} style={{ width: '25px' }} />
												</span> */}

												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: '#5bb75b' }}
													onClick={(e) => {
														finalizeClick(e);
													}}
												>
													Finalize
												</span>
												{/* <span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: '#5bb75b', border: '1x solid #5bb75b' }}
												>
													<a href="">Admit</a>
												</span> */}
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: '#5bb75b', border: '1x solid #5bb75b' }}
													id="saveid"
													disabled={disable}
													onClick={(e) => {
														saveClickFunction(e, 'Save');
													}}
												>
													<a>Save</a>
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													id="updateid"
													disabled={disable}
													style={{
														background: '#5bb75b',
														display: 'none',
														border: '1x solid #5bb75b',
													}}
													onClick={(e) => {
														updateClickFunction(e, 'Save');
													}}
												>
													Update
												</span>
												<a href="/Patients/casesheetMedical">	<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
													Cancel
												</span></a>
											</div>

											{/*  */}
											<div class="prescriptionContent prescriptionContentAdd precriptionAddedDrugsgroups_PTour">
												<div class="practiceManagementForm1">
													<div class="practiceManagementFormBlcok" id="typePreId">
														<div id="divJsFiles" />

														<div
															id="treatmentPlanSection"
															class="prescriptionAddedMedcne_PTour"
														>
															<div class="treatmentsList precscriptionTrtmntList">
																{/*  */}
																<div
																	class="practiceManagementForm"
																	style={{
																		padding: ' 0 10px',
																		background: '#ececec',
																	}}
																>
																	<div
																		class="practiceManagementFormBlcok"
																		id="typePreId"
																	>
																		<div
																			id="treatmentPlanSection"
																			class="prescriptionAddedMedcne_PTour"
																		>
																			<div
																				class="skechpadWrap"
																				style={{ width: '100%' }}
																			>
																				<div class="skechpadTop">
																					<div class="skechpadChoose">
																						<label for="line-color-input">
																							Choose Color
																						</label>
																						<div class="palette-box">
																							<div
																								class="palette red"
																								onClick={() =>
																									setPenColor('red')}
																							/>
																							<div
																								class="palette blue"
																								onClick={() =>
																									setPenColor('blue')}
																							/>
																							<div
																								class="palette black"
																								onClick={() =>
																									setPenColor(
																										'black'
																									)}
																							/>
																							<span
																								class="u-full-width"
																								id="clear"
																								onClick={
																									clearCnvasImage
																								}
																							>
																								&nbsp;Clear
																							</span>
																						</div>
																					</div>
																				</div>

																				<div class="sketchpadDiv">
																					<div id="sketchpad">
																						<ReactSketchCanvas
																							ref={canvas}
																							id="sketchpadP"
																							class="sketchpadP"
																							width={100}
																							onChange={(e) =>
																								setImagePath(e)}
																							style={{
																								width: '780px',
																								height: '350px',
																							}}
																							height={100}
																							strokeWidth={5}
																							strokeColor={penColor}
																						/>
																					</div>
																				</div>
																			</div>

																			<span
																				id="canvasBtnP"
																				onclick="return saveCanvas()"
																				class="practiceGreenButton prescriptionAddMoreBtn_PTour"
																				style={{ cursor: 'pointer' }}
																				onClick={clearImage}
																			>
																				Add More
																			</span>
																			<br />
																			<br />
																		</div>
																	</div>
																	{/*  */}
																</div>

																{/* ===== */}
																<div className="addmoreImgs">
																	{imgArr.map((val, index) => (
																		<div className="plcanvasclose">
																			<span
																				className="close_row"
																				onClick={(e) => removeImgArr(e, index)}
																			>
																				x
																			</span>

																			<img
																				src={val.caseSheetImage}
																				style={{
																					width: '120px',
																					height: '120px',
																					objectFit: 'contain',
																					objectPosition: 'center',
																					background: '#fff',

																					border: '1px solid grey',
																				}}
																			/>
																		</div>
																	))}
																</div>

																{/* <div class="treatmentDivSecWrap prescriptionAddDiv_PTour" id="prescriptionNoDataDiv" style="display: none;">
	    	<span class="treatmentCommonTxt" id="treatmentCommonTxt"><span>Add a medicine from the right. Multiple medicine(s) can be added</span></span>


	    </div> */}
															</div>
														</div>
													</div>
												</div>
											</div>
											{/*  */}

											<div class="treatmentClinicContent" id="treatmentClinicContentId">
												<div class="clinicContentList dentalCaseSheetAppontStatus_PTour">
													<div class="clinicArrived">
														<label class="clinicalLabel">Appointment Status</label>
														<div class="clinicalField">
															<select name="cmbStatus" id="cmbStatus" value={cmbStatus}
																onChange={(e) => setCmbStatus(e.target.value)}>
																<option value="1">OPEN</option>
																<option value="2">CONFIRM</option>
																<option value="3" selected="selected">
																	ARRIVED
																</option>
																<option value="4">DOCTOR CHANGED</option>
																<option value="8">ENGAGED</option>
																<option value="5">ADMITTED</option>
																<option value="7">DISCHARGE</option>
																<option value="6">COMPLETED</option>
																<option value="9">CANCELLED</option>
																<option value="10">CALL COMPLETED-DOCTOR</option>
															</select>
														</div>
													</div>
												</div>
												{futureAppointmentDate.map((item, i) => (
													<div class="clinicFollowUp">
														<span class="followUpTxtDate" id="followUpTxtDateLabel">
															Next Appointment On {convertedFutureDate}
														</span>
														<span
															class="followUpTxt"
															onClick={() => setToggle(!toggle)}
														>
															<span onClick={() =>
																setIsChanged(true)}

															>

																Change
															</span>
														</span>
														<br />
														<span class="followUpTxtDate">
															Doctor : {item.doctor_name}
														</span>
														<br />
														{item.followUpNotes ? (
															<span class="followUpTxtDate">
																Bring For Next Appointment : {item.followUpNotes}
															</span>
														) : ('')}
													</div>

												))}
												<div class="clinicFollowUp caseSheetNeedFollowUp_PTour caseSheetWNeedFollowUp_PTour">
													<span class="followUpTxt needfollowup-inline" id="weee" onClick={() => setToggle(!toggle)} style={{ visibility: "visible" }}>
														<div onClick={() =>
															setFollowUpClick(true)}
															className='followup-button'
														>
															Add Appointment Follow Up
														</div>
													</span>
													<span class="followUpTxt recall-inline" id="weee" onClick={() => setRecallToggle(!recallToggle)} style={{ visibility: "visible" }}>
														<div style={{ paddingLeft: '15px' }} onClick={() =>
															setRecallClick(true)}
															className='recall-button'
														>
															Add Patient Recall
														</div>
													</span>
													{toggle && (
														<div id="needFollowDiv" style={{ display: 'block' }}>
															<div class="clinicContentList">
																<div class="clinicalLabel">
																	Appointment Date &amp; Time
																</div>
																<div class="clinicalField">
																	<Datepicker
																		required
																		id="appoinmentDateTime"
																		className="Datepicker pa2 inputField"
																		placeholderText={new Date()}
																		dateFormat="dd-MM-y h:mm aa"
																		calendarClassName="rasta-stripes"
																		showTimeSelect
																		selected={appointmentDate}
																		onChange={(date) => setAppointmentDate(date)}
																	/>
																</div>
															</div>
															<div class="clinicContentList">
																<div class="clinicalLabel">By</div>
																<div class="clinicalField">
																	<select
																		name="cmbDoctor"
																		id="cmbDoctor"
																		value={getDrName}
																		onChange={(e) => doctorClickFunction1(e.target.value)}
																	>
																		<option >
																			Select Doctor
																		</option>
																		{doctorList.map((index, i) => (
																			<option value={index.doctor_id}>
																				{index.doctor_name}
																			</option>
																		))}
																	</select>
																</div>
															</div>

															<div class="clinicContentList">
																<div class="clinicalLabel">
																	Bring For Next Appointment
																</div>
																<div class="clinicalField">
																	<textarea
																		name="txtFollowNote"
																		id="txtFollowNote"
																		maxlength="100"
																		style={{ resize: 'none' }}
																		value={appointmentNext}
																		onChange={(e) =>
																			setAppointmentNext(e.target.value)}
																	/>
																</div>
															</div>
														</div>
													)}

													{recallToggle && (
														<div id="needFollowDiv" style={{ display: 'block' }}>
															<div class="clinicContentList">
																<div class="clinicalLabel pb-2 pt-1">
																	Recall Date &amp; Time<span className='starimp'>*</span>
																</div>
																<div class="clinicalField">
																	<Datepicker
																		required
																		id="appoinmentDateTime"
																		className="Datepicker pa2 inputField"
																		placeholderText="Date Time"
																		dateFormat="dd-MM-y h:mm aa"
																		calendarClassName="rasta-stripes"
																		showTimeSelect
																		minDate={new Date()}
																		selected={recallDate}
																		onChange={(date) => setRecallDate(date)}
																	/>
																</div>
															</div>

															<div class="clinicContentList">
																<div class="clinicalLabel">
																	Comments
																</div>
																<div class="clinicalField">
																	<textarea
																		name="txtFollowNote"
																		id="txtFollowNote"
																		maxlength="100"
																		style={{ resize: 'none' }}
																		value={comments}
																		onChange={(e) =>
																			setComments(e.target.value)}
																	/>
																</div>
															</div>
														</div>
													)}

												</div>

												<div class="medicalWrap">
													<span class="medicalHeading caseSheetSystemReview_PTour caseSheetWSystemReview_PTour">
														System Review
													</span>
													{general.map((item, i) => (
														<div>
															<div class="medicalCheck caseSheetMedicalChk_PTour caseSheetWMedicalChk_PTour">
																<span class="medicalGeneral">
																	<input
																		type="checkbox"
																		name="radGeneral"
																		id={'radGeneral' + item.prtId}
																	/>

																	<span>{item.prtName}</span>
																</span>
																<ul>
																	{item.genGrpSymptoms.map((data, i) => (
																		<li>
																			<input
																				type="checkbox"
																				name="chkgenSymptom1"
																				id={'chkgenSymptom1' + data.prsId}
																				onChange={(event) =>
																					generalClick(
																						event,
																						data.prsId,
																						item.prtId
																					)}
																			/>

																			<span>{data.symptomName}</span>
																		</li>
																	))}
																</ul>
															</div>

															<div
																class="clinicContentList"
																style={{ border: 'none', padding: '0px' }}
															>
																<div class="caseSheetRadioText dentalCaseSheetRadiographs_PTour">
																	<label>Description</label>
																	<textarea
																		id={'txtRadiographGeneral' + item.prtId}
																		name="txtRadiographGeneral"
																	/>
																</div>
															</div>
														</div>
													))}
													<div
														class="medicalPartWrap caseSheetMedicalPartWrap_PTour caseSheetWMedicalPartWrap_PTour"
														data-position="top"
													>
														{image.map((item, i) => (
															<div class="medicalPartList">
																<div class="medicalPartListMain">
																	<input
																		type="checkbox"
																		id={'one' + item.prtId}
																		name={'one' + item.prtId}
																		onChange={(e) => handleClick(e, item.prtId)}
																	/>

																	<span>
																		<img
																			src={getS3folder + '/' + item.imageName}
																			alt=""
																			title={item.prtName}
																		/>
																	</span>
																</div>
																<div class="medicalPartListSub" />
															</div>
														))}

														{/*  */}
														<br />
														<div id="medicalListDrpcontent">
															{nonGeneral.map((item, i) => (
																<div
																	class="medicalListDrpcontent"
																	id={'two' + item.prtId}
																	style={{ display: 'none' }}
																>
																	<div class="medicalListTitle">{item.prtName}</div>
																	<div class="medicalListcontent">
																		<ul>
																			{item.nonGenGrpSymptoms.map((data, j) => (
																				<li>
																					<input
																						type="checkbox"
																						name="chkSymptoms6"
																						id={'chkSymptoms6' + data.prsId}
																						onChange={(e) =>
																							handleClickNonGeneral(
																								e,
																								data.prsId,
																								item.prtId
																							)}
																					/>
																					<span>{data.symptomName}</span>
																				</li>
																			))}
																		</ul>
																	</div>
																	<div
																		class="clinicContentList"
																		style={{ border: 'none', padding: '0px' }}
																	>
																		<div class="caseSheetRadioText dentalCaseSheetRadiographs_PTour">
																			<label>Description</label>
																			<textarea
																				id={'txtRadiograph' + item.prtId}
																				name="txtRadiograph"
																			/>
																		</div>
																	</div>
																</div>
															))}
														</div>
													</div>
												</div>

												<div
													class="treatmentSelect treatmentPlanHead"
													id="bottomDiv"
													style={{ border: 'none', float: 'right' }}
												>
													<div class="treatmentSelectBtns caseSheetMAdd">
														<a
															href="/Patients/casesheetMedical"
															type="button"
															class="clinicalCancel dentalCaseSheetCancelBtn_PTour"
															style={{ textDecoration: 'none', float: 'left' }}
														>
															Cancel
														</a>

														<input
															type="submit"
															value="Save"
															id="lowsaveid"
															disabled={disable}
															onClick={(e) => {
																saveClickFunction(e, 'Save');
															}}
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
														/>
														<input
															type="submit"
															value="Update"
															id="lowupdateid"
															disabled={disable}
															style={{
																background: '#5bb75b',
																display: 'none',
																border: '1px solid #5bb75b',
															}}
															onClick={(e) => {
																updateClickFunction(e, 'Save');
															}}
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
														/>
														{/* <input
															type="submit"
															value="Admit"
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
															style={{
																background: '#5bb75b',
																border: '1px solid #5bb75b',
															}}
														/> */}
														<input
															type="submit"
															onClick={(e) => {
																finalizeClick(e);
																// saveClickFunction(e, 'Finalize');
																// updateClickFunction(e, 'Finalize')
															}}
															value="Finalize"
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
															style={{
																background: '#5bb75b',
																border: '1px solid #5bb75b',
															}}
														/>
													</div>
												</div>
												{/*  */}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
});

export default CasesheetMedicalAddWrite;
