import React, { useEffect, useState } from 'react';
import { callingAPI } from '../config';
import Editicon from '../images/icons/edit-bg.png';
import Close from '../images/icons/close-sm.png';
import tick from '../images/icons/tick.png';
import Pagination from 'react-js-pagination';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AddSpecimenType() {
	const [specimenList, setSpecimenList] = useState([]);
	const [count, setTotalCount] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchType, setSearchType] = useState("0");
	const [isEnabled, setIsEnabled] = useState(true);
	const [saveValue, setSaveValue] = useState('');
	const [displayName, setDisplayname] = useState('');
	const [popupHeading, setPopupHeading] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [pageOffset, setPageOffset] = useState(0);
	const [specimenId, setSpecimenId] = useState('');
	const [dataFlag, setDataFlag] = useState(false);
	const [disable, setDisable] = useState(false);
	const getSpecimenListingDataValues = () => {
		const postData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: 10,
			Offset: pageOffset,
			searchId: searchType,
			searchValue: searchValue,
		};
		callingAPI('specimen//specimenList', postData).then((data) => {
			setSpecimenList(data.data.result.specimenList);
			setTotalCount(data.data.result.count);
			setDataFlag(true);
		}).catch();
	};
	useEffect(() => {
		getSpecimenListingDataValues();
		document.getElementById('plpopup1').style.display = 'none';
	}, []);
	const addButtonClick = (name) => {
		document.getElementById('plpopup1').style.display = '';
		document.getElementById('valueSp').focus();
		if (name == 'Save') {
			setDisplayname('Save');
			setSaveValue('');
			document.getElementById("valueSp").focus()
		} else if (name == 'Update') {
			setDisplayname('Update');
			document.getElementById("valueSp").focus()
		}
	};
	const searchTypeOnChange = (event) => {
		if (event.target.value === "0") {
			setSearchType(event.target.value);
			setIsEnabled(true);
			getSpecimenListingDataValues()
			setSearchValue('');
		} else if (event.target.value == "1") {
			document.getElementById("inputBox").focus()
			setSearchType(event.target.value);
			setIsEnabled(false);
			setSearchValue('');
		}
		if (document.getElementById('selectName').value !== '') {
			document.getElementById('inputBox').disabled = false;
			document.getElementById('inputBox').focus();
		} else {
			document.getElementById('inputBox').disabled = true;
		}
		document.getElementById('inputBox').value = '';
	};
	const searchValueFunction = (event) => {
		// document.getElementById("inputBox").focus()
		event.target.value = event.target.value.split('').length === 1 ? event.target.value.replace(/\s/, '') : event.target.value;
		setSearchValue(event.target.value);
	};
	const searchClickFunction = () => {
		setActivePage(1);
		if (searchType === '1' && searchValue.split('').length === 0) {
			toast.error('Enter Specimen Type');
			document.getElementById("inputBox").focus();
			return false;
		}
		const postData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: 10,
			Offset: 0,
			searchId: searchType,
			searchValue: searchValue,
		};
		callingAPI('specimen//specimenList', postData).then((data) => {
			if (data.data.success === '1') {
				setPageOffset(0);
				setSpecimenList(data.data.result.specimenList);
				setTotalCount(data.data.result.count);
			} else {
				setSpecimenList([]);
			}
		}).catch();
	};
	const selectedStatusTypeChange = (id, status) => {
		var x = false;
		if (status === true) {
			x = window.confirm('Do you want to Disable this?');
		} else {
			x = window.confirm('Do you want to Enable this?');
		}
		if (x === true) {
			const postData = {
				specimenTypeIsActive: status == true ? false : true,
				specimenTypeId: id,
			};
			callingAPI('specimen/activateSpecimen', postData).then((data) => {
				if (data.data.success === '1') {
					getSpecimenListingDataValues();
				} else {
					setSpecimenList([]);
				}
			}).catch();
		}
	};
	const saveValueFunction = (event) => {
		event.target.value = event.target.value.split('').length === 1 ? event.target.value.replace(/\s/, '') : event.target.value;
		setSaveValue(event.target.value);
	};
	const editButtonClick = (id, name) => {
		document.getElementById("valueSp").focus();
		document.getElementById('plpopup1').style.display = '';
		setSpecimenId(id);
		setSaveValue(name);
	};
	const popUpSave = () => {
		setDataFlag(false)
		document.getElementById('plpopup1').style.display = 'none';
		document.getElementById("valueSp").focus();
		if (displayName == 'Save') {
			if (saveValue.split('').length === 0) {
				toast.error('Enter Specimen Type');
				setDataFlag(true)
				// document.getElementById('valueSp').value = '';
				document.getElementById('plpopup1').style.display = 'block';
				document.getElementById("valueSp").focus();
				return false;
			}
			const postData = {
				hospitalId: localStorage.getItem("hospitalId"),
				specimenTypeName: saveValue,
			};
			callingAPI('specimen/saveSpecimen', postData).then((data) => {
				if (data.data.success === '1') {
					setPageOffset(0);
					document.getElementById('plpopup1').style.display = 'none';
					document.getElementById("valueSp").focus();
					setSaveValue('');
					getSpecimenListingDataValues(saveValue);
					toast.success('Specimen Type Saved Successfully');
					setDataFlag(true)
				} else if (data.data.success === '0') {
					setPageOffset(0);
					document.getElementById('plpopup1').style.display = 'block';
					document.getElementById("valueSp").focus();
					setSaveValue('');
					toast.error('Specimen Type Already Exist');
					setDataFlag(true)
					getSpecimenListingDataValues();
					setSaveValue('');
					// return false;

					// setSpecimenList([])
				}
			}).catch();
		}
		else {
			if (saveValue.split('').length === 0) {
				toast.error('Enter Specimen Type');
				setDataFlag(true)
				document.getElementById('plpopup1').style.display = 'block';
				return false;
			}
			const postData = {
				specimenTypeId: specimenId,
				hospitalId: localStorage.getItem("hospitalId"),
				specimenTypeName: saveValue,
			};
			callingAPI('specimen/saveSpecimen', postData).then((data) => {
				if (data.data.success === '1') {
					setPageOffset(0);
					document.getElementById('plpopup1').style.display = 'none';
					setSaveValue('');
					getSpecimenListingDataValues(saveValue);
					toast.success('Specimen Type Updated Successfully');
					setDataFlag(true)
				} else if (data.data.success === '0') {
					setPageOffset(0);
					document.getElementById('plpopup1').style.display = 'block';
					setSaveValue('');
					toast.error('Specimen Type Already Exist');
					setDataFlag(true)
					getSpecimenListingDataValues();
					setSaveValue('');
				}
			});
		}
	};
	const paginationHandling = (pageNumber) => {
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		let postData = {
			hospitalId: localStorage.getItem("hospitalId"),
			searchValue: searchValue,
			Offset: offset,
			max: max,
			searchId: searchType,
		};
		callingAPI('specimen//specimenList', postData).then((data) => {
			if (data.data.success === '1') {
				setSpecimenList(data.data.result.specimenList);
				setTotalCount(data.data.result.count);
			}
		});
	};
	const enterKey = (e) => {
		if (e.key === 'Enter') {
			searchClickFunction();
		}
	}
	return (
		<div className="dashboardCover">
			<ToastContainer position="bottom-right" style={{zIndex:"999999999"}}/>
			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle">Add Specimen Type</div>
				</div>

				<div className="col-md-2">
					<a
						href="#plpopup1"
						className="dashButtonBorder"
						style={{ background: '#22a2fd', border: 'none', textDecoration: 'none' }}
						onClick={() => {
							addButtonClick('Save');
							setPopupHeading('Add Specimen Type');
						}}
					>
						<a href="#plpopup1" style={{ color: '#fff', textDecoration: 'none' }}>
							Add +
						</a>
					</a>
				</div>
				{count > 10 && (
					<div className="paginationSection">
						<Pagination
							// hideNavigation
							activePage={activePage}
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={count}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={paginationHandling}
							hideDisabled
						/>
					</div>
				)}
			</div>
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select className="inputField" id="selectName" value={searchType} onChange={searchTypeOnChange}>
									<option value="0">Search In</option>
									<option value="1">Specimen Type</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<input
									type="text"
									className="inputField"
									id="inputBox"
									onKeyDown={enterKey}
									value={searchValue}
									disabled={isEnabled}
									onChange={searchValueFunction}
									autoComplete="off"
									style={{ background: searchType === "0" ? "transparent" : "white" }}
								/>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<button className="dashButtonBorder" value={saveValue} onClick={searchClickFunction} title="Search" style={{ width: '100%' }}>
									Search
								</button>
							</div>
							<div className="col-md-6 dashboardSearchList">
								<div className="searchCount">
									<b>Total : {specimenList.length == 0 ? 0 : count}</b>
								</div>
							</div>
						</div>
					</div>

					<div className="dashboardtable">
						{dataFlag == false ? (
							<Loader />
						) : specimenList.length > 0 ? (
							<table className="addSpcmnTble">
								<thead>
									<tr>
										<th>Specimen Type</th>
										<th> Status </th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{specimenList.map((item, index) => (
										<tr>
											<td>{item.specimenTypeName}</td>
											<td>{item.specimenTypeIsActive === true ? 'Active' : 'InActive'}</td>
											<td>
												<a
													href="#plpopup1"
													style={{ color: '#fff', textDecoration: 'none' }}
													onClick={() => {
														addButtonClick('Update');
														editButtonClick(item.specimenTypeId, item.specimenTypeName);
														setPopupHeading('Edit Specimen Type');
													}}
												>
													<img src={Editicon} title="Edit" />
												</a>
												{item.specimenTypeIsActive === true ? (
													<img
														src={tick}
														title="Click to Deactivate"
														alt=""
														onClick={() => {
															selectedStatusTypeChange(item.specimenTypeId, true);
														}}
														style={{ width: '20px' }}
													/>
												) : (
													<img
														src={Close}
														alt=""
														title="Click to Activate"
														className="mr-2  conf-cursorpointer"
														width="20"
														onClick={() => {
															selectedStatusTypeChange(item.specimenTypeId, false);
														}}
													/>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<p>
								<NoData />
							</p>
						)}
						{/* {specimenList.length == 0 && (
							<NoData />
						)} */}

						<br />
						{count > 10 && (
							<div className="paginationSection">
								<Pagination
									// hideNavigation
									activePage={activePage}
									itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
									totalItemsCount={count}
									pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
									onChange={paginationHandling}
									hideDisabled
								/>
							</div>
						)}
						{/* Popup Begins */}
						<div id="plpopup1" class="ploverlay">
							<div class="plpopup ">
								<h2>
									{popupHeading}{' '}
									<a class="close" href="#">
										<span class="popUpClose">x</span>
									</a>
								</h2>
								<div class="plcontent">
									<div class="addDataContent">
										<div class="addDataDiv">
											<label>
												Specimen Type<span class="mandatory">*</span>
											</label>
											<input
												type="text"
												id="valueSp"
												placeholder="Enter Specimen Type"
												value={saveValue}
												onChange={saveValueFunction}
												autoComplete="off"
											/>
										</div>
										<div class="summaryBtnDiv">
											<button type="submit" onClick={popUpSave} class="practiceGreenButton">
												{displayName}
											</button>
											<a class="close" href="#">
												<input
													type="submit"
													value="Cancel"
													class=" practiceGreyButton"
													style={{ fontFamily: 'Montserrat-SemiBold' }}
												/>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Popup Ends */}
					</div>
				</div>
			</div>
		</div>
	);
}
export default AddSpecimenType;
