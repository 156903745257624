import React, { useState, useEffect } from 'react';
import DatePicker, { CalendarContainer } from "react-datepicker";
import { callingAPI } from '../config';
import Pagination from 'react-js-pagination';
import Editicon from '../images/icons/edit-bg.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function PurchaseLists() {
	const navigate = useNavigate();

	const [filterName, setFilterInput] = useState("0");
	const [selectedPurchaseList, setSelectedPurchaseList] = useState({});
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [hospitalPurchaseList, sethospitalPurchaseListData] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [count, setCount] = useState('');

	const [statusType, setStatusType] = useState('UnPaid');
	const [supplierInvoiceAmount, setsupplierInvoiceAmount] = useState('');
	const [invoiceDate, setinvoiceDate] = useState('');
	const [invoiceNo, setinvoiceNo] = useState('');
	const [netAmount, setnetAmount] = useState('');
	const [serviceProviderId, setserviceProviderId] = useState('');
	const [supplierId, setsupplierId] = useState('');
	const [supplierName, setsupplierName] = useState('');
	const [tax, settax] = useState('');
	const [supplierInvoiceId, setsupplierInvoiceId] = useState('');

	const [pageOffset, setPageOffset] = useState(0);
	const [dataFlag, setDataFlag] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(false);

	const onStatusChange = (e) => {
		setStatusType(e.target.value);
	};

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		window.sessionStorage.setItem("pageNumberPurchaseDetails", pageNumber);
		window.sessionStorage.setItem("offsetPurchaseDetails", offset);
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: max,
			offset: offset,
			searchId: document.getElementById('selectName').value,
			keyWord: document.getElementById('searchText').value,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
		};

		callingAPI('inventoryStockUpdate/inventoryPurchaseLists', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					sethospitalPurchaseListData(data.data.result.purchaseList);
					setCount(data.data.result.totalCount);
				}
			})
			.catch();
	};
	const changeText = (value) => {
		setFilterInput(value);
		if (document.getElementById('selectName').value !== '0') {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		} else {
			document.getElementById('searchText').disabled = true;
		}
		document.getElementById('searchText').value = '';
	};
	const formatInvoiceDate = (dateStr) => {

		const [day, month, year] = dateStr.split('-');
		let newDate = `${year}-${month}-${day}`;
		return newDate;
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}
	const searchValueChange = () => {
		if (startDate && endDate) {
			if (startDate > endDate) {
				toast.error('End date must be greater than start date');
				document.getElementById("todate").focus();
				return false;
			}
		}
		if (document.getElementById('selectName').value !== '0' && document.getElementById('searchText').value === '') {
			var t = document.getElementById('selectName');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('searchText').focus();
			toast.error(selectedText + ' required');
			return false;
		}
		setActivePage(1);
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: 0,
			searchId: document.getElementById('selectName').value,
			keyWord: document.getElementById('searchText').value,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
		};

		callingAPI('inventoryStockUpdate/inventoryPurchaseLists', PostData)
			.then((data) => {

				if (data.data.success === '1') {
					sethospitalPurchaseListData(data.data.result.purchaseList);
					setCount(data.data.result.totalCount);
				}
				else {
					sethospitalPurchaseListData([]);
					setCount([]);
				}
			})
			.catch();
	};
	const purchaselisting = () => {
		setActivePage(Number(window.sessionStorage.getItem("pageNumberPurchaseDetails")));
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: window.sessionStorage.getItem("offsetPurchaseDetails"),
		};

		callingAPI('inventoryStockUpdate/inventoryPurchaseLists', PostData)
			.then((data) => {
				sethospitalPurchaseListData(data.data.result.purchaseList);
				setCount(data.data.result.totalCount);
				setDataFlag(true);
			})
			.catch();
	};
	const MyContainerFromDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const MyContainerToDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const FromDateClear = () => {
		setStartDate("")

	};

	const ToDateClear = () => {
		setEndDate("")

	};
	useEffect(() => {
		//	changeText();
		purchaselisting();
	}, []);

	const editPurchase = () => {
		let flagEditPurchase = true;
		setButtonDisable(true)
		if (!document.getElementById('invoiceNumber').value) {
			toast.error('InvoiceNumber Required');
			flagEditPurchase = false;
			setButtonDisable(false)
		} else if (!document.getElementById('invoiceDate').value) {
			toast.error('InvoiceDate Required');
			flagEditPurchase = false;
			setButtonDisable(false)
		} else if (!document.getElementById('supplierName').value) {
			toast.error('Supplier Name Required');
			flagEditPurchase = false;
			setButtonDisable(false)
		}

		if (flagEditPurchase === true) {
			const postdata = {
				supplierInvoiceAmount: supplierInvoiceAmount,
				userId: window.sessionStorage.getItem('userId'),
				hospitalId: localStorage.getItem("hospitalId"),
				invoiceDate: invoiceDate,
				invoiceNo: invoiceNo,
				netAmount: netAmount,
				serviceProviderId: serviceProviderId,
				supplierId: supplierId,
				tax: tax,
				status: statusType,
				supplierInvoiceId: supplierInvoiceId,
			};

			callingAPI('inventoryStockUpdate/savePurchase', postdata).then((res) => {
				if (res.data.success === '1') {
					toast.success('Invoice Details Updated Successfully');
					setButtonDisable(false)
					setTimeout(() => {
						window.location.href = '/settings/purchaseLists';
					}, 3000);
					purchaselisting();
				}
				else if (res.data.success === "0") {
					toast.error(res.data.errorMessage);
					setButtonDisable(true)
				}
			});
		}
	};
	const purchaseListsClick = (data) => {
		navigate('/settings/addItemDetails', { state: data })
	}
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}
	const enterKey = (e) => {
		if (e.key === 'Enter') {
			searchValueChange();
		}
	}
	return (
		<div className="dashboardCover purchaselistWrapper">
			<ToastContainer position="bottom-right" style={{ zIndex: "999999999" }} />
			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle">Purchase List</div>
				</div>
				<div className="col-md-2">
					<span className="dashButtonBorder">
						<Link to="/settings/addPurchase" style={{ color: '#393939', textDecoration: 'none' }}>
							Add Purchase
						</Link>
					</span>
				</div>
			</div>
			{count > 10 && (
				<div className="paginationSection">
					<Pagination
						// hideNavigation
						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePageChange}
					/>
				</div>
			)}
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select className="inputField"
									id="selectName"
									name="selectName"
									onChange={(e) => changeText(e.target.value)}
								>
									<option value="0">Select</option>
									<option value="1">Supplier Name</option>
									<option value="2">Invoice Number</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<input className="inputField"
									type="text" id="searchText"
									name="searchText"
									onKeyDown={enterKey}
									disabled={filterName === "0" ? true : false}
									style={{ background: filterName === "0" ? "transparent" : "white" }}
								/>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<DatePicker
									required
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									className="Datepicker pa2 inputField"
									maxDate={new Date()}
									placeholderText="Invoice-From"
									calendarClassName="rasta-stripes"
									selected={startDate}
									dateFormat="dd-MM-yyyy"
									onChange={(date) => setStartDate(date)}
									id="fromdate"
									autoComplete='off'
									onChangeRaw={handleDateChangeRaw}
									calendarContainer={MyContainerFromDate}
								/>{' '}
								<label
									className="icon iconCalendar calendar plcalendar"
									style={{ fontSize: '16px' }}
									htmlFor="fromdate"
								>
									<FontAwesomeIcon icon={faCalendarAlt} />
								</label>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<DatePicker
									required
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									className="Datepicker pa2 inputField"
									maxDate={new Date()}
									placeholderText="Invoice-To"
									calendarClassName="rasta-stripes"
									selected={endDate}
									dateFormat="dd-MM-yyyy"
									onChange={(date) => setEndDate(date)}
									id="todate"
									autoComplete='off'
									onChangeRaw={handleDateChangeRaw}
									calendarContainer={MyContainerToDate}
								/>{' '}
								<label
									className="icon iconCalendar calendar plcalendar"
									style={{ fontSize: '16px' }}
									htmlFor="todate"
								>
									<FontAwesomeIcon icon={faCalendarAlt} />
								</label>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<button className="dashButtonBorder"

									onClick={searchValueChange} style={{ width: '100%' }}>
									Search
								</button>
							</div>
						</div>
					</div>
					{dataFlag == false ? (
						<Loader />
					) : hospitalPurchaseList.length > 0 ? (
						<div className="dashboardtable">
							<table>
								<thead>
									<tr>
										<th>Invoice No</th>
										<th>Invoice Date</th>
										<th>Supplier Name</th>
										<th style={{ textAlign: 'right' }}> Net Amount</th>
										<th style={{ textAlign: 'right' }}>Gross Amount </th>
										<th style={{ width: "90px" }}>Status</th>
										<th>Actions</th>
										<th style={{ width: "60px" }}></th>
									</tr>
								</thead>
								<tbody>
									{hospitalPurchaseList.map((item, index) => (
										<tr>
											<td>
												<span title={item.supplierInvoiceInvoiceNo}
													className="refundReason elipsis">
													{item.supplierInvoiceInvoiceNo}
												</span>
											</td>
											<td title={item.supplierInvoiceInvoiceDate}>{item.supplierInvoiceInvoiceDate}</td>
											<td>
												<span title={item.supplierName}
													className="refundReason elipsis" style={{ width: "97%" }}>{item.supplierName}</span>
											</td>
											<td style={{ textAlign: 'right' }}>
												<span title={item.supplierInvoiceNetAmount}
													className="refundReason elipsis" style={{ textAlign: 'right', width: "100%" }}>
													{item.supplierInvoiceNetAmount ?
														<>&#8377;{item.supplierInvoiceNetAmount}</>
														: ""
													}

												</span>
											</td>
											<td style={{ textAlign: 'right' }}>
												<span title={item.supplierInvoiceAmount}
													className="refundReason elipsis" style={{ textAlign: 'right', width: "100%" }}>
													{item.supplierInvoiceAmount ?
														<>&#8377; {item.supplierInvoiceAmount}</>
														: ""
													}

												</span>
											</td>

											<td title={item.supplierInvoiceStatus}>{item.supplierInvoiceStatus}</td>
											<td>
												<nav
													onClick={() => purchaseListsClick({
														supplierInvoiceMedicinesId: item.supplierInvoiceMedicinesId,
														supplierInvoiceId: item.supplierInvoiceId,
														supplierInvoiceNumber: item.supplierInvoiceInvoiceNo,
														supplierInvoiceDate: item.supplierInvoiceInvoiceDateNew,
														supplierName: item.supplierName,
														supplierInvoiceStatus: item.supplierInvoiceStatus,
													})}

												>
													Add Item Details
												</nav>
											</td>
											<td>
												<a
													href="#editPurchasepopup1"
													onClick={() => {
														setSelectedPurchaseList(item);
														setsupplierInvoiceAmount(item.supplierInvoiceAmount);
														setinvoiceDate(item.supplierInvoiceInvoiceFormattedDate);
														setinvoiceNo(item.supplierInvoiceInvoiceNo);
														setnetAmount(item.supplierInvoiceNetAmount);
														setserviceProviderId('');
														setsupplierId(item.supplierId);
														setsupplierName(item.supplierName);
														setStatusType(item.supplierInvoiceStatus);
														settax('0');
														setsupplierInvoiceId(item.supplierInvoiceId);
													}}
												>
													<img src={Editicon} title="Edit" />
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<p>
							<NoData />
						</p>
					)}
				</div>
			</div>
			{count > 10 && (
				<div className="paginationSection">
					<Pagination
						// hideNavigation
						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePageChange}
					/>
				</div>
			)}
			{/* {hospitalPurchaseList.length == 0 && <NoData />} */}
			{/* Popup Begins */}

			<div id="editPurchasepopup1" class="ploverlay">
				<div class="plpopup">
					<h2>
						Edit Invoice Details
						<a class="close" href="#">
							<span class="popUpClose">x</span>
						</a>
					</h2>
					<div class="plcontent">
						<div class="addDataContent">
							<div class="addDataDiv">
								<label>
									Invoice Number<span class="mandatory">*</span>
								</label>
								<input type="text" id="invoiceNumber" value={invoiceNo} placeholder="Invoice Number" tabindex="-1" />
							</div>

							<div class="addDataDiv">
								<label>
									<span>Invoice Date *</span>
								</label>
								<input type="text" name="invoiceDate" id="invoiceDate" value={invoiceDate} tabindex="-1" />
							</div>

							<div class="addDataDiv">
								<label>
									Supplier Name<span class="mandatory">*</span>
								</label>
								<input type="text" id="supplierName" placeholder="Supplier Name" value={supplierName} tabindex="-1" />
							</div>

							<div class="addDataDiv">
								<label>
									Status<span class="mandatory">*</span>
								</label>
								<select
									class="fulWidthSelect"
									name="statuscategory"
									id="statuscategory"
									value={statusType}
									onChange={onStatusChange} tabindex="-1"
								>
									<option value="UnPaid">UnPaid</option>
									<option value="Paid">Paid</option>
									{/* <option value="Open">Open</option> */}
								</select>
							</div>

							<div class="summaryBtnDiv" style={{ display: buttonDisable === false ? '' : 'none' }}>
								<span className="saveBtnModal">
									<input
										type="submit"
										value="Update"
										onClick={editPurchase}
										class="practiceGreenButton" tabindex="-1"
									/>
								</span>

								<a class="close" href="#">
									<input type="submit" value="Cancel" class=" practiceGreyButton" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Popup Ends */}
		</div>
	);
}

export default PurchaseLists;
