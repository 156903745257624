import React from 'react';
import { useState, useEffect } from 'react';
import DatePicker, { CalendarContainer } from "react-datepicker";
import Editicon from '../images/icons/edit-bg.png';
import Delete from '../images/icons/delete_btn.png';
import { callingAPI, callingDeleteAPI } from '../config';
import AWS from "aws-sdk";
import moment from 'moment';
import $ from 'jquery'
import { Visibility } from '@material-ui/icons';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var s3;

function ToDoList() {
	// const [setDataFlag, setDataFlag] = useState(false);
	const [type, setType] = useState(false);
	const [dataFlag, setDataFlag] = useState(false);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [category, setCategory] = useState("");
	const [description, setDescription] = useState("");
	const [fromDate, setFromDate] = useState();
	const [dueDate, setDueDate] = useState("");
	const [file, setFile] = useState("");
	const [imageName, setImageName] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const [role, setRole] = useState(window.sessionStorage.getItem('role'));
	const [todoList, setTodoList] = useState([]);
	const [todoCatagoryList, setTodoCatagoryList] = useState([]);
	const [todoId, setTodoId] = useState("");
	const [imageNameEdit, setImageNameEdit] = useState("");
	const currentDateInMillis = Date.now();

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: "us-east-1",
	});

	var getS3healthInfo =
		process.env.REACT_APP_S3_BUCKET_URL +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH;
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
	useEffect(() => {
		toDoList();
	}, []);

	const toDoList = () => {
		let postData = {
			todo_id: "",
			hospital_id: localStorage.getItem('hospitalId')

		}
		callingAPI('commonUserFunctions/todoListListing', postData).then((response) => {

			if (response.data.success === "1") {
				setDataFlag(true);
				setTodoList(response.data.result)
			}
			else {
				setDataFlag(true);
				setTodoList([])
			}

		})
	}

	const toDoAddSave = () => {
		if (imageName !== "") {

			imageS3Save();
		}
		setDataFlag(false)

		if ($("#category").val() === "") {
			toast.error("Please Enter Category")
			$("#category").focus()
			setDataFlag(true)
			return false;
		}
		else if ($("#duedate").val() === "") {
			toast.error("Please Enter Due Date")
			$("#duedate").focus()
			setDataFlag(true)
			return false;
		}
		else {
			let postData = {
				todo_id: todoId,
				todo_category_id: "",
				todo_category_name: category,
				created_on: Date.parse(moment(currentDateInMillis).format('YYYY-MM-DD hh:mm A')),
				created_by: window.sessionStorage.getItem('role') === "ROLE_DOCTOR" ? (window.sessionStorage.getItem('doctorId')) : (window.sessionStorage.getItem('role') === "ROLE_HOSPITALADMIN" ? (window.sessionStorage.getItem('userId')) : ""),
				hospitaId: localStorage.getItem('hospitalId'),
				due_date: dueDate !== "" ? Date.parse(moment(dueDate).format('YYYY-MM-DD hh:mm A')) : "",
				discription: description,
				image_name: imageName
			}

			callingAPI('commonUserFunctions/addOrUpdateTodoList', postData).then((response) => {

				if (response.data.success === "1") {
					setDataFlag(true)
					// if (imageName !== "") {

					// 	imageS3Save();
					// }

					toDoList();
					closepopup();
					setTodoId("")

					// $("#plpopup1").css("display", "none");

					if (todoId === "") {
						toast.success("Added Successfully");

					}
					else {
						toast.success("Updated Successfully");

					}

				}
				setDataFlag(true)
			})
		}
	}




	const todoImageupLoad = (e) => {
		// document.getElementById("imageThumb1").style.display = "none";
		setFile(e.target.files[0]);
		setImageName(e.target.files[0].name.replace(/ /g, "-"));
		setImagePreview(URL.createObjectURL(e.target.files[0]));
		// setImagePreview("")
		setImageNameEdit("")
	}


	const imageS3Save = (e) => {
		var S3BucketMainFolder = process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH;
		var fullFilePath = S3BucketMainFolder + '/' + imageName;
		s3.upload(
			{
				Key: fullFilePath,
				Body: file,
				ACL: "public-read",
			},
			(err, data) => {
				if (err) {
					toast.error("There was an error uploading your photo: " + err.message);
					return null;
				} else {
					// alert("Profile Uploaded Successfully")
				}
			}
		);

	};

	const getCategoryNameAutoComplete = (e) => {
		let postData = {
			searchKeyword: e
		}
		callingAPI('commonUserFunctions/todoCategoryList', postData).then((response) => {

			if (response.data.success === "1") {
				setTodoCatagoryList(response.data.result)
				document.getElementById("categorysearch").style.display = 'block';
				document.addEventListener('mouseup', function (e) {
					var container = document.getElementById("categorysearch");
					if (container !== null) {
						if (!container.contains(e.target)) {
							container.style.display = 'none';
						}
					}
				});
			}
		})
	}
	const setCategoryNameListing = (itemName, mode, dropdownId) => {
		document.getElementById(mode).value = itemName;
		setTodoCatagoryList([])
		setCategory(itemName);
		// document.getElementById(dropdownId).style.display = 'none';
	}

	const deleteTodolist = (data) => {
		var x = window.confirm("Do you want to remove?");
		if (x) {
			const postData = {
				todo_id: data.todo_id,
			};
			callingAPI("/commonUserFunctions/deleteTodoList", postData).then((response) => {

				if (response.data.success === "1") {
					toDoList();
					toast.success("Deleted Successfully");
				}
			}
			);
		}
	};

	const editTodolist = (data, type) => {


		$("#plpopup1").css("visibility", "visible");
		$("#plpopup1").css("opacity", "1");
		setDescription(data.discription);
		setCategory(data.todoCategoryName);
		setDueDate(parseInt(data.due_date))
		setFromDate(data.created_on)
		setImageNameEdit(data.image_name)
		setImageName(data.image_name)
		setType(type)
		// setImagePreview(data.image_name)
		setTodoId(data.todo_id)
	}

	const closepopup = () => {
		$("#plpopup1").css("visibility", "hidden");
		$("#plpopup1").css("opacity", "0");
		setDescription("");
		setCategory("");
		setFromDate("");
		setDueDate("");
		setImageName("");
		setImagePreview("")
		setImageNameEdit("")
		$("#fileUploadCourseMaster1").val("");
		setTodoCatagoryList([])

	};

	const openPopup = (type) => {

		$("#plpopup1").css("visibility", "visible");
		$("#plpopup1").css("opacity", "1");
		setType(type)
	}
	const handleKeypress = (event) => {
		if (event.key === 'Enter') {
		}
	};
	const MyContainerToDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	const MyContainerFromDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	const MyContainerFromDatePopup = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const FromDateClear = () => {
		setStartDate("")

	};

	const ToDateClear = () => {
		setEndDate("")

	};


	return (
		<div className="dashboardCover pharmacyManufactureWrap pharmacyManuf ">
			<ToastContainer position="bottom-right"  style={{ zIndex: 99999999 }} />
			{dataFlag === false ? <Loader /> : ''}
			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle"> To-Do</div>
				</div>
				<div className="col-md-2">
					<span
						className="dashButtonBorder"
						style={{ background: '#22a2fd', color: '#fff', border: 'none' }}
						// onClick={openPopup}
						onClick={() => openPopup("", "Save")}
					>
						Add +
					</span>
				</div>
			</div>
			<div className="dashboardContent">
				<div className="reportWrap">

					{/* <div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select className="inputField"
									onKeyPress={handleKeypress}>
									<option value="">Search In</option>
									<option value="1">Category</option>
									<option value="2">Category</option>
									<option value="3">Category</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<DatePicker
									className="inputField"
									placeholderText="From Date"
									maxDate={new Date()}
									calendarClassName="rasta-stripes"
									selected={fromDate}
									value={fromDate}
									dateFormat="dd/MM/yyyy"
									onChange={(date) => setFromDate(date)}
									id="fromdate"
									showMonthDropdown
									showYearDropdown
									autoComplete='off'
									dropdownMode="select"
								/>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<DatePicker
									className="inputField"
									placeholderText="From Date"
									maxDate={new Date()}
									calendarClassName="rasta-stripes"
									selected={fromDate}
									value={fromDate}
									dateFormat="dd/MM/yyyy"
									onChange={(date) => setFromDate(date)}
									id="fromdate"
									showMonthDropdown
									showYearDropdown
									autoComplete='off'
									dropdownMode="select"
								/>
							</div>

							<div className="col-md-2 dashboardSearchList" >
								<span className="dashButtonBorder">Search</span>
							</div>
						</div>
					</div> */}
					{dataFlag == false ? (
						<Loader />
					) : todoList.length > 0 ? (
						<div className="dashboardtable">
							<table>
								<thead>
									<tr>
										<th>Category</th>
										<th>Description </th>
										<th>Created Date </th>
										<th>Due Date</th>
										<th>Image</th>
										<th>Action</th>

									</tr>
								</thead>
								<tbody>
									{todoList.map((data) =>
										<tr>
											<td>{data.todoCategoryName}</td>
											<td>{data.discription !== "undefined" ? data.discription : ""}</td>
											<td>{moment(data.created_on).format('DD-MM-YYYY')}</td>
											<td>{data.due_date !== "" ? moment(parseInt(data.due_date)).format('DD-MM-YYYY') : ""}</td>
											<td className='img-todolist'>{data.image_name !== "" && data.image_name !== "null" ?
												<img src={getS3healthInfo + "/" + data.image_name} alt={data.image_name} width={50} height={50} className='img-fluid' />
												: ""}
											</td>
											<td>
												<span onClick={() => editTodolist(data, "update")}><img src={Editicon} title="Edit" /></span>
												<img src={Delete} alt="" title="Delete" style={{ width: "22px" }} onClick={() => deleteTodolist(data)} />
											</td>

										</tr>
									)}

								</tbody>
							</table>
						</div>
					) : (
						<p>
							<NoData />
						</p>
					)}
					<div id="plpopup1" class="ploverlay toDoListPop toDoListPop-wrpr">
						<div class="plpopup">
							<h2>To-Do</h2>
							<span title="Close" class="close"
								onClick={closepopup}>
								<span class="popUpClose" style={{ right: '25px', position: "absolute" }}>
									x
								</span>
							</span>
							<div class="plcontent">
								<div class="addDataContent">
									<div class="addDataDiv">
										<label>
											Category<span class="mandatory">*</span>
										</label>
										<div className="patientSearchitem todolist-sectn" style={{ width: "68.9%" }}>
											<input
												type="text"
												id="category"
												className="inputField patientSearchBox "
												placeholder="Search Category"
												autoComplete="off"
												value={category}
												onChange={(e) => setCategory(e.target.value)}
												onKeyUp={(e) =>
													getCategoryNameAutoComplete(e.target.value)}
												onClick={(e) =>
													getCategoryNameAutoComplete(e.target.value)}

											/>

											{/* =============== AUTOSEARCH BEGINS HERE ==================== */}
											<ul className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front autoSearch1 autoSearch-p0 appointmentNumberSearch ul-todolist"
												id="categorysearch"
												style={{ display: 'block', width: "100%", borderTop: "unset" }}>

												<div>
													{todoCatagoryList.map((list) =>
														<li
															class="memberListAuto ui-menu-item"
															onClick={(e) =>
																setCategoryNameListing(list.todo_category_name, 'category', 'categorysearch')}
														>

															<div
																class="memberNAme  ui-menu-item-wrapper"
																id="ui-id-2"
																tabindex="-1" style={{ width: "100%", float: "inherit", maxWidth: "100%" }}
															>
																<p style={{ margin: "0px" }} >{list.todo_category_name} </p>
															</div>
														</li>
													)}
												</div>



											</ul>
											{/* =============== AUTOSEARCH ENDS HERE ==================== */}
										</div>
									</div>
									<div class="addDataDiv">
										<label>Description</label>
										<textarea
											placeholder="Description"
											style={{ height: '85px', resize: 'none' }}
											autoComplete="off"
											id='description'
											name='description'
											value={description}
											onChange={(e) => setDescription(e.target.value)}

										/>
									</div>
									{/* <div class="addDataDiv">
										<label>From Date</label>
										<DatePicker
											className="inputField"
											placeholderText="From Date"
											maxDate={new Date()}
											calendarClassName="rasta-stripes"
											selected={fromDate}
											value={fromDate}
											dateFormat="dd/MM/yyyy"
											onChange={(date) => setFromDate(date)}
											id="fromdate"
											showMonthDropdown
											showYearDropdown
											autoComplete='off'
											dropdownMode="select"
										/>
									</div> */}

									<div class="addDataDiv">
										<label>Due Date<span className='starimp'>*</span></label>
										<DatePicker
											className="inputField"
											placeholderText="Due Date"
											minDate={new Date()}
											calendarClassName="rasta-stripes"
											selected={dueDate}
											value={dueDate}
											dateFormat="dd/MM/yyyy"
											onChange={(date) => setDueDate(date)}
											id="duedate"
											showMonthDropdown
											showYearDropdown
											autoComplete='off'
										// dropdownMode="select"
										/>
									</div>


									<div class="addDataDiv d-flex">
										<label>Image Upload </label>
										<div class="clinicalField" style={{ paddingLeft: "0", borderLeft: "unset" }}>
											<div class="webCamImageWrap" style={{ float: "left" }}>
												<div class="btnFileUpload practiceGreenButton dentalFilesUpload_PTour uploadbtnleft" style={{ verticalAlign: "middle" }}>
													<span class="fileUpload">
														<input class="fileUploadCourse" type="file" id="fileUploadCourseMaster1" onChange={(e) => todoImageupLoad(e)} />
													</span>
													<label id="uploadButton" htmlFor='fileUploadCourseMaster1' style={{ padding: "5px 17px" }}>
														Upload
													</label>
												</div>
												<span className=''>
													{imageNameEdit !== "" && imageNameEdit !== "null" ?

														<img src={getS3healthInfo + "/" + imageNameEdit} alt={imageNameEdit} width={60} height={60} className='img-fluid' />
														:
														<img src={imagePreview} alt={imagePreview} width={60} height={60} className='img-fluid' style={{ display: (imagePreview) ? 'inline-block' : 'none' }} />
													}
												</span>
												{/* )} */}
												<div className="uploadCondtiontext">Maximum 3MB</div>
											</div>
										</div>
									</div>

								</div>
							</div>
							<span class="close ">
								<input
									type="submit"
									value="Cancel"
									onClick={closepopup}
									class=" practiceGreyButton closeTodo"
									style={{ margin: "0px" }}
								/>
							</span>
							<input
								type="submit"
								value={type === "update" ? "Update" : "Save"}
								class="practiceGreenButton"
								onClick={toDoAddSave}
							/>

						</div>
					</div>

				</div>

			</div>
		</div>
	);
}
export default ToDoList;

