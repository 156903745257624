import React, { useState, useEffect } from 'react';
import { callingAPI, callingPostAPI } from '../config';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import Loader from '../Common/loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddInventory() {
	const [isData, setIsData] = useState(true);
	const [CategoryData, setCategoryData] = useState([])
	const [taxCalculation, setTaxCalculation] = useState(0);
	const [manufactureAuto, setManufactureAuto] = useState([]);
	const [medicineAuto, setMedicineAuto] = useState([]);
	const [disposableAuto, setDisposableAuto] = useState([]);
	const [medicineUnits, setMedicineUnits] = useState([]);
	const [medicineTypes, setMedicineTypes] = useState([]);
	const [taxListdata, setTaxListData] = useState([]);
	const [isShowPharmacy, setShowPharmacy] = useState(false);
	const [isShowGeneric, setIsGeneric] = useState(false);

	const [categoryType, setcategoryType] = useState(0);

	const [brandName, setBrandName] = useState('');
	const [medicineId, setMedicineId] = useState('');
	const [genericName, setGenericName] = useState('');
	const [hsnCode, setHsnCode] = useState('');
	const [medType, setMedType] = useState('');
	const [strength, setStrength] = useState('');
	const [medUnit, setMedUnit] = useState('');
	const [medTypeOld, setMedTypeOld] = useState('');
	const [strengthOld, setStrengthOld] = useState('');
	const [medUnitOld, setMedUnitOld] = useState('');
	const [interactions, setInteractions] = useState('');
	const [drugReactions, setDrugReactions] = useState('');
	const [majorSideEffects, setMajorSideEffects] = useState('');
	const [dosage, setDosage] = useState('');
	const [precautions, setPrecautions] = useState('');
	const [stripCount, setStripCount] = useState('');
	const [batchNo, setBatchNo] = useState('');
	const [stockQuantity, setStockQuantity] = useState('');
	const [expireDate, setExpireDate] = useState('');
	const [reOrderQuantity, setReorderQuantitu] = useState('');

	const [itemName, setItemName] = useState('');
	const [itemNameID, setItemNameId] = useState('');
	const [masterCode, setMasterCode] = useState('');
	const [noOfUse, setnoOfUse] = useState('');
	const [disposablehsnCode, setDisposableHsnCode] = useState('');
	const [disposablebatchNo, setDisposableBatchNo] = useState('');
	const [disposablestockQuantity, setDisposableStockQuantity] = useState('');
	const [disposableexpireDate, setDisposableExpireDate] = useState('');
	const [disposablereOrderQuantity, setDisposableReorderQuantitu] = useState('');

	// const [mrp, setMrp] = useState(0);
	// const [mrpUnitPrice, setmrpUnitPrice] = useState(0);
	// const [sellingPriceWithoutTax, setSellingPriceWithoutTax] = useState(0);
	// const [sellingUnitPriceWithoutTax, setSellingUnitPriceWithoutTax] = useState(0);
	// const [sellingPriceWithTax, setSellingPriceWithTax] = useState(0);
	// const [sellingUnitPriceWithTax, setSellingUnitPriceWithTax] = useState(0);

	const [mrp, setMrp] = useState("");
	const [mrpUnitPrice, setmrpUnitPrice] = useState("");
	const [sellingPriceWithoutTax, setSellingPriceWithoutTax] = useState("");
	const [sellingUnitPriceWithoutTax, setSellingUnitPriceWithoutTax] = useState("");
	const [sellingPriceWithTax, setSellingPriceWithTax] = useState("");
	const [sellingUnitPriceWithTax, setSellingUnitPriceWithTax] = useState("");

	const [onlycheckedTaxList, setOnlyCheckedTaxList] = useState([]);
	const [popVisible, setPopVisible] = useState(false)
	const [manufactError, setManufactError] = useState('');
	var date = new Date();
	var requiredDate = moment(date).format('YYYY-MM');

	const onSelectChange = (e) => {
		setcategoryType(e.target.value);
		setBrandName('');
		setGenericName('');
		setHsnCode('');
		setMedType('');
		setStrength('');
		setMedUnit('');
		setInteractions('');
		setDrugReactions('');
		setMajorSideEffects('');
		setDosage('');
		setPrecautions('');
		setStripCount('');
		setBatchNo('');
		setStockQuantity('');
		setExpireDate('');
		setReorderQuantitu('');
		setMrp('');
		setmrpUnitPrice('');
		setSellingPriceWithoutTax('');
		setSellingUnitPriceWithoutTax('');
		setSellingPriceWithTax('');
		setSellingUnitPriceWithTax('');

		setItemName('');
		setMasterCode('');
		setnoOfUse('');
		setDisposableHsnCode('');
		setDisposableBatchNo('');
		setDisposableStockQuantity('');
		setDisposableExpireDate('');
		setDisposableReorderQuantitu('');
	};
	const onautoCompletItemChange = (e) => {

		document.getElementById("hdnMedicineGenericName").value = ""



	};
	const onChangeShowPharmacy = (event) => {
		setShowPharmacy(event.target.checked);
	};
	const onChangeisGeneric = (event) => {
		setIsGeneric(event.target.checked);
	};
	const onChangeBrandName = (event) => {
		setBrandName(event.target.value);
	};
	const onChangeGenericName = (event) => {
		setGenericName(event.target.value);
	};
	const onChangeHsnCode = (event) => {
		setHsnCode(event.target.value);
	};
	const onChangeMedType = (event) => {
		setMedType(event.target.value);
	};
	const onChangeStrength = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setStrength(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const onChangeMedUnit = (event) => {
		setMedUnit(event.target.value);
	};
	const onChangeInteraction = (event) => {
		setInteractions(event.target.value);
	};
	const onChangeDrugReactions = (event) => {
		setDrugReactions(event.target.value);
	};
	const onChangeMajorSideEffects = (event) => {
		setMajorSideEffects(event.target.value);
	};
	const onChangeDosage = (event) => {
		setDosage(event.target.value);
	};
	const onChangePrecautions = (event) => {
		setPrecautions(event.target.value);
	};
	const onChangeStripCount = (event) => {
		if (!categoryType) {
			toast.error('Select Category');
			window.scrollTo(0, 200);
			return;
		}
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setStripCount(event.target.value);
			setMrp('');
			setmrpUnitPrice('');
			setSellingPriceWithoutTax('');
			setSellingUnitPriceWithoutTax('');
			setSellingPriceWithTax('');
			setSellingUnitPriceWithTax('');
		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const onChangeBatchNo = (event) => {
		setBatchNo(event.target.value);
	};
	const onChangeStockQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setStockQuantity(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const onChangeExpireDate = (event) => {
		setExpireDate(event.target.value);
	};
	const onChangeReOrderQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setReorderQuantitu(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
		}
	};

	const onBlurItemCategory = () => {
		document.getElementById('disposableList').style.display = 'none';
		setDisposableAuto([]);
	};

	const onBlurBrandCategory = () => {
		document.getElementById('medicineList').style.display = 'none';
		setMedicineAuto([]);
	};

	const onBlurManufacture = () => {
		setManufactureAuto([]);
	};

	const stripCountBy = () => {
		if (categoryType == 1) {
			var price = mrp;
			var quantity = stripCount;
			var totalCost = price / quantity;
			setmrpUnitPrice(parseFloat(totalCost).toFixed(2));
			setSellingUnitPriceWithoutTax(parseFloat(totalCost).toFixed(2));
			setSellingUnitPriceWithTax(parseFloat(totalCost).toFixed(2));
		}
	};

	const stripCountBySellingArea = (event) => {
		var quantity = stripCount;
		if (document.getElementById("sellingPricewithoutTax").value == "") {
			setSellingUnitPriceWithoutTax(sellingPriceWithoutTax);
			setSellingPriceWithTax(sellingPriceWithoutTax);
			setSellingUnitPriceWithTax(sellingPriceWithoutTax);
		}
		else if (taxListdata.length != 0) {
			let obj = [...taxListdata]
			let taxCalc1 = 0;
			var quantity = stripCount;
			var totalCost = 0;
			obj.map((val, index) => {
				if (val.isChecked == true) {
					taxCalc1 = taxCalc1 + (sellingPriceWithoutTax * Number(val.hospitalTaxDetailsTaxValue) / 100);
				}

				setSellingPriceWithTax(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1));
				setSellingUnitPriceWithTax(parseFloat(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1)).toFixed(2));
				setSellingUnitPriceWithoutTax((parseFloat(sellingPriceWithoutTax)))
				if (categoryType == 1) {
					setSellingPriceWithTax(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1));
					totalCost = (parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1)) / quantity;
					setSellingUnitPriceWithTax(parseFloat(totalCost).toFixed(2));
					setSellingUnitPriceWithoutTax((parseFloat(sellingPriceWithoutTax)) / quantity)
				}
			});
		}
		else {
			if (categoryType == 1) {
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value / quantity);
				setSellingUnitPriceWithoutTax(event.target.value / quantity);
			}
			else {
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
			}



		}
	};

	const stripCountBySellingAreawithTax = (event) => {
		var stripcount = stripCount;
		if (document.getElementById("sellingPricewithTax").value == "") {
			setSellingPriceWithoutTax(sellingPriceWithTax);
			setSellingUnitPriceWithoutTax(sellingPriceWithTax);
			setSellingUnitPriceWithTax(sellingPriceWithTax)
		}
		else if (taxListdata.length != 0) {
			let obj = [...taxListdata]
			let taxCalc = 0;
			let sellingPriceMultiple = 0;
			let totalTaxValue = 0;
			let totalTaxCalculation = 0;
			obj.map((val, index) => {
				if (val.isChecked == true) {
					taxCalc = taxCalc + Number(val.hospitalTaxDetailsTaxValue);
				}
				sellingPriceMultiple = sellingPriceWithTax * 100;
				totalTaxValue = taxCalc + 100;
				totalTaxCalculation = sellingPriceMultiple / totalTaxValue;

				setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
				setSellingUnitPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
				setSellingUnitPriceWithTax((parseFloat(sellingPriceWithTax)))
				if (categoryType == 1) {
					var stripcount = stripCount;
					var unitSellingPice = totalTaxCalculation / stripcount;
					setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
					setSellingUnitPriceWithoutTax(Math.round(unitSellingPice.toFixed(2) * 100) / 100);
					setSellingUnitPriceWithTax((parseFloat(sellingPriceWithTax)) / stripcount)
				}
			});
		}
		else {
			if (categoryType == 1) {
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value / stripCount);
				setSellingUnitPriceWithTax(event.target.value / stripCount);
			}
			else {
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			}
		}
	};



	const onChangemrp = (event) => {
		if (!categoryType) {
			toast.error('Select Category');
			window.scrollTo(0, 200);
			return;
		}
		if (categoryType == 1) {
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				setMrp(event.target.value);
				setSellingPriceWithoutTax(event.target.value);
				setSellingPriceWithTax(event.target.value);
			} else {
				toast.error('Only Numbers Accepted');
				return
			}
			if (!stripCount) {
				toast.error('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				setMrp('');
				setmrpUnitPrice('');
				setSellingPriceWithoutTax('');
				setSellingUnitPriceWithoutTax('');
				setSellingPriceWithTax('');
				setSellingUnitPriceWithTax('');
				return;
			}


			onlycheckedTaxList.map((val, index) => {
				if (taxListdata.includes(val.hospitalTaxDetailsId)) {
					val.isChecked = true;
				}
				else {
					val.isChecked = false;
				}
				return val;
			})
		}
		else {
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				setMrp(event.target.value);
				setmrpUnitPrice(event.target.value);
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			} else {
				toast.error('Only Numbers Accepted');
			}
			onlycheckedTaxList.map((val, index) => {
				if (taxListdata.includes(val.hospitalTaxDetailsId)) {
					val.isChecked = true;
				}
				else {
					val.isChecked = false;
				}
				return val;
			})
		}
	};

	const onChangeSellingPriceAreawithTax = (event) => {
		if (categoryType == 1) {
			if (!stripCount) {
				toast.error('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				setMrp('');
				setmrpUnitPrice('');
				setSellingPriceWithoutTax('');
				setSellingUnitPriceWithoutTax('');
				setSellingPriceWithTax('');
				setSellingUnitPriceWithTax('');

			}
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				//setSellingPriceWithoutTax(event.target.value);
				setSellingPriceWithTax(event.target.value);
			} else {
				toast.error('Only Numbers Accepted');
			}


		} else {
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				//setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			} else {
				toast.error('Only Numbers Accepted');
			}

		}
	};
	const onChangeSellingPriceArea = (event) => {
		if (categoryType == 1) {
			if (!stripCount) {
				toast.error('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				setMrp('');
				setmrpUnitPrice('');
				setSellingPriceWithoutTax('');
				setSellingUnitPriceWithoutTax('');
				setSellingPriceWithTax('');
				setSellingUnitPriceWithTax('');

			}
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				setSellingPriceWithoutTax(event.target.value);
				//setSellingPriceWithTax(event.target.value);
			} else {
				toast.error('Only Numbers Accepted');
			}




		} else {
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				//setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			} else {
				toast.error('Only Numbers Accepted');
			}

		}

	};
	const onChangeItemNameName = (event) => {
		setItemName(event.target.value);
	};
	const onChangeMasterCode = (event) => {
		setMasterCode(event.target.value);
	};
	const onChangeNoOfUse = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setnoOfUse(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const onChangeDisposableHsnCode = (event) => {
		setDisposableHsnCode(event.target.value);
	};
	const onChangeDisposableBatchNo = (event) => {
		setDisposableBatchNo(event.target.value);
	};
	const onChangeDisposableStockQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setDisposableStockQuantity(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const onChangeDisposableExpireDate = (event) => {
		setDisposableExpireDate(event.target.value);
	};
	const onChangeDisposableReOrderQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setDisposableReorderQuantitu(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const saveInventoryDetails = () => {
		setIsData(false);
		let flag = true;
		if (categoryType == 0) {
			toast.error('Select Category');
			flag = false;
			setIsData(true);
			window.scrollTo(0, 200);
			return;

		}
		if (!categoryType) {
			toast.error('Select Category');
			flag = false;
			setIsData(true);
			window.scrollTo(0, 200);
			return;
		} else {
			if (categoryType == 1) {
				if (!brandName) {
					toast.error('Medicine/Brand Name Required');
					document.getElementById('hdnMedicineName').focus();
					flag = false;
					setIsData(true);

				} else if (!medType) {
					toast.error('Select Type');
					document.getElementById('cmbMedicineType').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!stripCount) {
					toast.error('NO: of Units Required');
					// document.location.href = "#hdnMedicineQuantity";
					document.getElementById('hdnMedicineQuantity').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!batchNo) {
					toast.error('Batch NO: Required');
					document.getElementById('hdnMedicineBatchNo').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!stockQuantity) {
					toast.error('Stock Quantity Required');
					document.getElementById('hdnMedicineStockQuantity').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!expireDate) {
					toast.error('Expire Date Required');
					document.getElementById('scheduleDate').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!reOrderQuantity) {
					toast.error('Reorder quantity Required');
					document.getElementById('hdnMedicineThreshold').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!mrp) {
					toast.error('MRP Required');
					document.getElementById('mrp').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 250);
				} else if (!mrpUnitPrice) {
					toast.error('MRP UnitPrice Required');
					document.getElementById('mrpUnitPrice').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 250);
				} else if (!sellingPriceWithoutTax) {
					toast.error('Selling Price WithoutTax Required');
					document.getElementById('sellingPricewithoutTax').focus();
					flag = false;
					setIsData(true);
				} else if (!sellingUnitPriceWithoutTax) {
					toast.error('Selling UnitPrice WithoutTax Required');
					document.getElementById('sellingUnitPricewithoutTax').focus();
					flag = false;
					setIsData(true);
				} else if (!sellingPriceWithTax) {
					toast.error('Selling Price WithTax Required');
					document.getElementById('sellingPricewithTax').focus();
					flag = false;
					setIsData(true);
				} else if (!sellingUnitPriceWithTax) {
					toast.error('Selling UnitPrice WithTax Required');
					document.getElementById('sellingUnitPricewithTax').focus();
					flag = false;
					setIsData(true);
				}
				else if (parseFloat(sellingPriceWithoutTax) > parseFloat(mrp)) {
					toast.error('MRP should not be less than Selling Price');
					flag = false;
					setIsData(true);
				}
				else if (parseFloat(sellingPriceWithTax) > parseFloat(mrp)) {
					toast.error('MRP should not be less than Selling Price');
					flag = false;
					setIsData(true);
				}



			} else {
				//// disposable and other category
				if (!itemName) {
					toast.error('Item Name Required');
					document.getElementById('itemName').focus();
					flag = false;
					setIsData(true); window.scrollTo(0, 200);
				} else if (!masterCode) {
					toast.error('Master Code Required');
					document.getElementById('masterCode').focus();
					flag = false;
					setIsData(true); window.scrollTo(0, 200);
				} else if (!noOfUse) {
					toast.error('No Of Use Required');
					document.getElementById('noOfUse').focus();
					flag = false;
					setIsData(true); window.scrollTo(0, 200);
				}
				// else if (!disposablehsnCode) {
				// 	alert('hsnCode Required');
				// 	document.getElementById('hsnCode').focus();
				// 	flag = false;
				// 	setIsData(true);
				// }
				else if (!disposablebatchNo) {
					toast.error('Batch NO: Required');
					document.getElementById('batchNum').focus();
					flag = false;
					setIsData(true); window.scrollTo(0, 200);
				} else if (!disposablestockQuantity) {
					toast.error('Stock Quantity Required');
					document.getElementById('stockQuantity').focus();
					flag = false;
					setIsData(true); window.scrollTo(0, 200);
				}
				// else if (!disposableexpireDate) {
				// 	alert('Expire Date Required');
				// 	document.getElementById('expireDate').focus();
				// 	flag = false;
				// 	setIsData(true);
				// }
				else if (!disposablereOrderQuantity) {
					toast.error('Reorder quantity Required');
					document.getElementById('threshold').focus();
					flag = false;
					setIsData(true); window.scrollTo(0, 200);
				} else if (!mrp) {
					toast.error('MRP Required');
					document.getElementById('mrp').focus();
					flag = false;
					setIsData(true); window.scrollTo(0, 250);
				} else if (!mrpUnitPrice) {
					toast.error('MRP UnitPrice Required');
					document.getElementById('mrpUnitPrice').focus();
					flag = false;
					setIsData(true); window.scrollTo(0, 250);
				} else if (!sellingPriceWithoutTax) {
					toast.error('Selling Price WithoutTax Required');
					document.getElementById('sellingPricewithoutTax').focus();
					flag = false;
					setIsData(true);
				} else if (!sellingUnitPriceWithoutTax) {
					toast.error('Selling UnitPrice WithoutTax Required');
					document.getElementById('sellingUnitPricewithoutTax').focus();
					flag = false;
					setIsData(true);
				} else if (!sellingPriceWithTax) {
					toast.error('Selling Price WithTax Required');
					document.getElementById('sellingPricewithTax').focus();
					flag = false;
					setIsData(true);
				} else if (!sellingUnitPriceWithTax) {
					toast.error('Selling UnitPrice WithTax Required');
					document.getElementById('sellingUnitPricewithTax').focus();
					flag = false;
					setIsData(true);
				}
				else if (parseFloat(sellingPriceWithoutTax) > parseFloat(mrp)) {
					toast.error('MRP should not be less than Selling Price');
					flag = false;
					setIsData(true);
				}
				else if (parseFloat(sellingPriceWithTax) > parseFloat(mrp)) {
					toast.error('MRP should not be less than Selling Price');
					flag = false;
					setIsData(true);
				}


			}
		}
		if (flag === true) {
			var selectedtaxvaluesArr = [];
			var selectedtaxvaluesArr = taxListdata.reduce(
				(a, o) => (o.isChecked && a.push(o.hospitalTaxDetailsId), a),
				[]
			);
			var today = new Date();

			var currentDate = today.getDate();
			if(currentDate <= 9)
			{
				currentDate = '0'+currentDate;
			}
			const postData = {
				pmHospitalMedicineId: '',
				medicine: categoryType == 1 ? brandName : '',
				role: window.sessionStorage.getItem('role'),
				isPurchase: false,
				hospitalInventoryCategoryId: categoryType,
				cmbSpeci: '',
				medicineId: categoryType == 1 ? (medType == medTypeOld && medUnit == medUnitOld && strength == strengthOld ? medicineId : '') : '',
				medicineGenericName: categoryType == 1 ? genericName : '',
				medicineIndicationAndDosage: categoryType == 1 ? dosage : '',
				userId: window.sessionStorage.getItem('userId'),
				medicineBrandId: '',
				medicineQuantity: categoryType == 1 ? stockQuantity : disposablestockQuantity,
				medicineDrugReactions: categoryType == 1 ? drugReactions : '',
				medicineMajorSideEffects: categoryType == 1 ? majorSideEffects : '',
				manufactureId: categoryType == 1 ? document.getElementById('hiddenManufacId').value : '',
				medicinePrecautions: categoryType == 1 ? precautions : '',
				medicineTypeId: categoryType == 1 ? '' + medType : '',
				medicineUnitId: categoryType == 1 ? '' + medUnit : '',
				medicineStrength: categoryType == 1 ? '' + strength : '',
				medicineHsnCode: categoryType == 1 ? hsnCode : '',
				medicineForDentalAndMedical: '',
				medicineSpecializationId: '',
				medicineInteractions: categoryType == 1 ? interactions : '',

				pmHospitalMedicineBatchNo: categoryType == 1 ? batchNo : disposablebatchNo,
				hospitalInventoryMasterHsnCode: categoryType == 1 ? '' : disposablehsnCode,
				hospitalInventoryMasterId: categoryType == 1 ? '' : itemNameID,
				hospitalInventoryMasterCode: categoryType == 1 ? '' : masterCode,
				hospitalInventoryMasterName: categoryType == 1 ? '' : itemName,
				hospitalInventoryMasterNoOfUse: categoryType == 1 ? '' : noOfUse,

				pmHospitalMedicineMrp: mrp,
				pmHospitalMedicineMrpUnitPrice: mrpUnitPrice,
				pmHospitalMedicineQty: categoryType == 1 ? stripCount : '',
				// pmHospitalMedicineQty: categoryType == 1 ? stockQuantity : disposablestockQuantity,
				pmHospitalMedicineCode: '',
				pmHospitalMedicineNoOfUse: categoryType == 1 ? '' : noOfUse,
				pmHospitalMedicineIsDisposableItem: categoryType == 1 ? false : isShowPharmacy,
				isGeneric: categoryType == 1 ? isShowGeneric : false,
				pmHospitalMedicineHsnCode: categoryType == 1 ? hsnCode : disposablehsnCode,
				pmHospitalMedicineExpiryDate: categoryType == 1 ? expireDate + "-" + currentDate : disposableexpireDate + "-" + currentDate,
				pmHospitalMedicineThreshold: categoryType == 1 ? reOrderQuantity : disposablereOrderQuantity,
				pmHospitalMedicinePrice: sellingPriceWithoutTax,
				pmHospitalMedicineUnitPrice: sellingUnitPriceWithoutTax,
				pmHospitalMedicineStockQty: categoryType == 1 ? stockQuantity : disposablestockQuantity,
				hospitalId: localStorage.getItem("hospitalId"),
				selectedTax: selectedtaxvaluesArr,

				supplierInvoiceMedicinesMrp: '',
				supplierInvoiceMedicinesMrpUnitPrice: '',
				supplierInvoiceMedicinesSellingPrice: '',
				supplierInvoiceMedicinesSellingUnitPrice: '',
				supplierInvoiceId: '',
				supplierInvoiceMedicinesTotalTax: '',
				supplierInvoiceMedicinesPrice: '',
				supplierInvoiceMedicinesUnitPrice: '',
				supplierInvoiceMedicinesPriceWithTax: '',
				supplierInvoiceMedicinesUnitPriceWithTax: '',
			};
			callingAPI('inventoryStockUpdate/addInventoryStock', postData).then((data) => {
				setIsData(true);
				if (data.data.success === '1') {
					toast.success('Inventory Stock Added Successfully');
					setTimeout(() => {
					  window.location.href = '/settings/hospitalInventory';
					}, 3000);
				} else {
					toast.error(data.data.errorMessage);
					setTimeout(() => {
					  window.location.href = '/settings/hospitalInventory';
					}, 3000);
				}
			});
		}
	};
	useEffect(() => {
		categoryDropDownApi();
		getMedicineUnitsTypes();
		getTaxListingDataValues();


	}, []);

	const categoryDropDownApi = () => {
		callingAPI('inventoryStockUpdate/categoryListing',
		).then((response) => {
			if (response.data.success === "1") {
				setCategoryData(response.data.result.categoryList)
			}
		}
		)
			.catch()
	}

	const setManufactureClick = (name, id) => {
		document.getElementById('manufactureInput').value = name;
		document.getElementById('hiddenManufacId').value = id;
		setManufactureAuto([]);
		document.getElementById('manufactureList').style.display = 'none';
	};
	const setMedicineClick = (medName, medId) => {
		document.getElementById('hdnMedicineName').value = medName;
		setBrandName(medName);
		document.getElementById('hdnMedicineId').value = medId;
		setMedicineId(medId);
		setMedicineAuto([]);
		document.getElementById('medicineList').style.display = 'none';
		getSelectedDetailsComplete(medId);
	};
	const setDisposableClick = (DisposableName, DisposableId) => {
		document.getElementById('itemName').value = DisposableName;
		setItemName(DisposableName);
		document.getElementById('hdnDisposableId').value = DisposableId;
		setItemNameId(DisposableId);
		setDisposableAuto([]);
		document.getElementById('disposableList').style.display = 'none';
		getSelectedDetailsComplete(DisposableId);
	};
	const taxesChange = (item, index) => {
		if (!categoryType) {
			toast.error('Select Category');
			window.scrollTo(0, 200);
			return;
		} else {
			if (categoryType == 1) {
				if (!stripCount) {
					toast.error('NO: of Units Required');
					document.getElementById('hdnMedicineQuantity').focus();
					return;
				} else if (!mrp) {
					toast.error('MRP Required');
					document.getElementById('mrp').focus();
					return;
				}
			} else {
				//// disposable and other category
				if (!mrp) {
					toast.error('MRP Required');
					document.getElementById('mrp').focus();
					return;
				}
			}
		}
		const checkedTaxArrCopy = [...taxListdata];
		checkedTaxArrCopy[index].isChecked = !item.isChecked;
		setTaxListData(checkedTaxArrCopy);
		let onlyCheckedTaxArrCopy = [...checkedTaxArrCopy];
		onlyCheckedTaxArrCopy = checkedTaxArrCopy.filter((val, idx) => {
			if (val.isChecked == true) {
				return true;
			} else {
				return false;
			}
		});
		setOnlyCheckedTaxList(onlyCheckedTaxArrCopy)
		////////////////////////    tax Calculation     ////////////////////////
		let taxCalc = 0;
		let sellingPriceMultiple = 0;
		let totalTaxValue = 0;
		let totalTaxCalculation = 0;

		checkedTaxArrCopy.map((val, index) => {
			if (val.isChecked == true) {
				taxCalc = taxCalc + Number(val.hospitalTaxDetailsTaxValue);
				setTaxCalculation(taxCalc);
			}
			sellingPriceMultiple = sellingPriceWithTax * 100;
			totalTaxValue = taxCalc + 100;
			totalTaxCalculation = sellingPriceMultiple / totalTaxValue;

			setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
			setSellingUnitPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
			if (categoryType == 1) {
				var stripcount = stripCount;
				var unitSellingPice = totalTaxCalculation / stripcount;
				setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
				setSellingUnitPriceWithoutTax(Math.round(unitSellingPice.toFixed(2) * 100) / 100);
			}
		});
	};
	const getTaxListingDataValues = () => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};

		callingAPI('inventoryStockUpdate/taxListing', postdata)
			.then((res) => {
				var result = [];
				if (res.data.success === '1') {
				result = res.data.result.hospitalTaxDetailsList.map((val, idx) => {
					val.isChecked = false;
					return val;
				});
			}
				if (res.data.success === '1') {
					setTaxListData(res.data.result.hospitalTaxDetailsList);
				} else {
					setTaxListData([]);
				}
			})
			.catch();
	};
	const getMedicineUnitsTypes = () => {
		callingPostAPI('inventoryStockUpdate/getMedicineDetails')
			.then((data) => {
				setMedicineUnits(data.data.result.medicineUnit);
				setMedicineTypes(data.data.result.medicineType);
			})
			.catch();
	};

	const getInventoryAutoComplete = () => {
		if (!categoryType) {
			toast.error('Select Category');
			setBrandName('');
			setItemName('');
			window.scrollTo(0, 200);
			return;
		}
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			master: categoryType,
			keyWord:
				categoryType == 1
					? document.getElementById('hdnMedicineName').value
					: document.getElementById('itemName').value,
		};

		if (categoryType == 1) {
			if (document.getElementById('hdnMedicineName').value.length > 1) {

				callingAPI('inventoryStockUpdate/getInventoryAutocomplete', postdata)
					.then((res) => {
						if (res.data.success === '1' && res.data.result.inventoryList.length > 0) {
							document.getElementById('medicineList').style.display = 'block';
							setMedicineAuto(res.data.result.inventoryList);
						} else {
							document.getElementById('medicineList').style.display = 'none';
							setMedicineAuto([]);

						}
					})
					.catch();
			}
			else if (document.getElementById('hdnMedicineName').value.length === 0) {
				setGenericName('');
				setMedType('');
				setStrength('');
				setMedUnit('');
				setInteractions('');
				setDrugReactions('');
				setMajorSideEffects('');
				setDosage('');
				setPrecautions('');
				setStripCount('');
				setBatchNo('');
				setHsnCode('')
				document.getElementById('medicineList').style.display = 'none';
				setMedicineAuto([]);
			}
		} else {
			if (document.getElementById('itemName').value.length > 1) {
				callingAPI('inventoryStockUpdate/getInventoryAutocomplete', postdata)
					.then((res) => {
						if (res.data.success === '1' && res.data.result.inventoryList.length > 0) {
							document.getElementById('disposableList').style.display = 'block';
							setDisposableAuto(res.data.result.inventoryList);
						} else {
							document.getElementById('disposableList').style.display = 'none';
							setDisposableAuto([]);
						}
					})
					.catch();
			}
			else if (document.getElementById('itemName').value.length === 0) {
				setMasterCode('');
				setnoOfUse('');
				setDisposableHsnCode('');
				document.getElementById('disposableList').style.display = 'none';
				setDisposableAuto([]);

			}
		}
	};

	const getSelectedDetailsComplete = (mediId) => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			medicineId: categoryType == 1 ? mediId : '',
			hospitalInventoryMasterId: categoryType == 1 ? '' : mediId,
		};

		callingAPI('inventoryStockUpdate/getMedicineDetailsById', postdata)
			.then((res) => {
				if (res.data.success === '1') {
					if (categoryType == 1) {
						setBrandName(res.data.result.inventoryList[0].medicine);
						setMedicineId(res.data.result.inventoryList[0].medicineId);
						setGenericName(res.data.result.inventoryList[0].medicineGenericName);
						setManufactureClick(
							res.data.result.inventoryList[0].manufactureName,
							res.data.result.inventoryList[0].manufactureId
						);
						setMedType(res.data.result.inventoryList[0].medicineTypeId);
						setStrength(res.data.result.inventoryList[0].medicineStrength);
						setMedUnit(res.data.result.inventoryList[0].medicineUnitId);
						setMedTypeOld(res.data.result.inventoryList[0].medicineTypeId);
						setStrengthOld(res.data.result.inventoryList[0].medicineStrength);
						setMedUnitOld(res.data.result.inventoryList[0].medicineUnitId);
						setInteractions(res.data.result.inventoryList[0].medicineInteractions);
						setDrugReactions(res.data.result.inventoryList[0].medicineDrugReactions);
						setMajorSideEffects(res.data.result.inventoryList[0].medicineMajorSideEffects);
						setDosage(res.data.result.inventoryList[0].medicineIndicationAndDosage);
						setPrecautions('');
						setStripCount('');
						setBatchNo(res.data.result.inventoryList[0].pmHospitalMedicineBatchNo);
						setHsnCode(res.data.result.inventoryList[0].hsnCode);
					} else {
						setItemName(res.data.result.inventoryList[0].hospitalInventoryMasterName);
						setItemNameId(res.data.result.inventoryList[0].hospitalInventoryMasterId);
						setMasterCode(res.data.result.inventoryList[0].hospitalInventoryMasterCode);
						setnoOfUse(res.data.result.inventoryList[0].hospitalInventoryMasterNoOfUse);
						setDisposableHsnCode(res.data.result.inventoryList[0].hospitalInventoryMasterhsnInvCode);
					}
				} else {
					setMedicineAuto([]);
					setDisposableAuto([]);
				}
			})
			.catch();
	};
	const getManufactureAutoComplete = () => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			keyWord: document.getElementById('manufactureInput').value,
		};
		if (document.getElementById('manufactureInput').value.length > 1) {

			callingAPI('inventoryStockUpdate/getManuFactureAutocomplete', postdata)
				.then((res) => {
					if (res.data.success === '1' && res.data.result.manufactureDetails.length > 0) {
						document.getElementById('manufactureList').style.display = 'block';
						setManufactureAuto(res.data.result.manufactureDetails);
						setManufactError('');
					} else {
						document.getElementById('manufactureList').style.display = 'none';
						setManufactureAuto([]);
						setManufactError('Please Add Manufacturer');
					}
				})
				.catch();
		}
		else if (document.getElementById('manufactureInput').value.length === 0) {
			document.getElementById('manufactureList').style.display = 'none';
			setManufactureAuto([]);
			setManufactError('Please Add Manufacturer');
		}
	};
	const addNewManufacture = () => {
		var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var phNum = /^\d{10}$/;
		let flagManufacture = true;
		if (!document.getElementById('manufactureName').value) {
			toast.error('Manufacturer Name Required');
			flagManufacture = false;
			return;
		}
		if (!document.getElementById('manufactureNumber').value) {
			toast.error('Contact Number Required');
			flagManufacture = false;
			return;
		}
		if (document.getElementById('manufactureNumber').value !== '') {
			if (!document.getElementById('manufactureNumber').value.match(phNum)) {
				toast.error('Valid Contact Number Required');
				document.getElementById('manufactureNumber').focus();
				return;
			}
		}
		if (document.getElementById('manufactureEmail').value !== '') {
			if (!document.getElementById('manufactureEmail').value.match(email)) {
				toast.error('Valid Email Required');
				document.getElementById('manufactureEmail').focus();
				return;
			}
		}
		if (flagManufacture === true) {
			const postdata = {
				manufactureName: document.getElementById('manufactureName').value,
				manufactureAddress: document.getElementById('manufactureAddress').value,
				manufactureEmail: document.getElementById('manufactureEmail').value,
				manufactureContactNo: document.getElementById('manufactureNumber').value,
				manufactureContactPerson: document.getElementById('manufactureContactPerson').value,
				hospitalId: localStorage.getItem("hospitalId"),
				userId: window.sessionStorage.getItem('userId'),
			};
			setIsData(false);
			callingAPI('manufacturer/manufacturerAddUpdate', postdata).then((res) => {
				if (res.data.success === '1') {
					setIsData(true);
					toast.success('Manufacturer Saved Successfully');
					ManufactureClose()
				} else if (res.data.success === '0') {
					toast.success(res.data.successMessage);

				}
			});


		}
	};

	const ManufacturePopUpClick = () => {
		setPopVisible(true)
	}
	const ManufactureClose = () => {
		setPopVisible(false)
	}

	return (
		<div class="pl_editInventory practiceManagementForm practiceManagementFormNew edtinv " >
			<ToastContainer position="bottom-right" style={{zIndex:"999999999"}}/>
			{isData === false ? <Loader /> : ''}
			<h3 class="practiceHead" style={{ color: 'blue', fontSize: '16px' }}>
				Inventory Stock Update
			</h3>

			<div class="practiceManagementFormBlcok inventoryStockDiv">
				<h3 class="practiceHead">Item Details</h3>
				<form>
					<ul className="row">
						<li class="col-md-4 col-lg-4">
							<label>Category*</label>

							<select
								autocomplete="off"
								class="fulWidthSelect"
								name="category"
								id="category"
								onChange={onSelectChange}
							>
								<option value="0">Select Category</option>
								{CategoryData.map((item, i) => (
									<option value={item.id}>{item.categoryName}</option>
								))}

							</select>
						</li>
						{categoryType == 2 || categoryType == 3 || categoryType == 4 || categoryType == 5 || categoryType == 0 ? (
							<div className="row" onClick={onBlurItemCategory}>
								<li class="col-md-4 col-lg-4">
									<label id="medBid">Item Name*</label>

									<input
										autocomplete="off"
										type="text"
										id="itemName"
										name="itemName"
										autofocus="autofocus"
										value={itemName}
										onChange={onChangeItemNameName}
										placeholder="Enter Name"
										onKeyUp={() => getInventoryAutoComplete()}
										//onBlur={onBlurItemCategory}

										tabindex="-1"
									/>
									<ul
										className="autoSearch autoSearch-p0 appointmentNumberSearch itemNameSearch"
										id="disposableList"
										style={{ display: 'none' }}
									>
										{disposableAuto.map((item, i) => (
											<li
												onClick={() =>
													setDisposableClick(
														item.hospitalInventoryMasterName,
														item.hospitalInventoryMasterId
													)}
											>
												{item.hospitalInventoryMasterName}
											</li>
										))}
									</ul>
									<input type="hidden" id="hdnDisposableId" />
								</li>

								<li class="col-md-4 col-lg-4">
									<label>Master Code*</label>
									<input
										autocomplete="off"
										type="text"
										id="masterCode"
										name="masterCode"
										autofocus="autofocus"
										value={masterCode != '' ? masterCode : ''}
										onChange={onChangeMasterCode}
										placeholder="Enter Master Code" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>No Of Use*</label>

									<input
										autocomplete="off"
										type="text"
										maxlength="2"
										id="noOfUse"
										name="noOfUse"
										autofocus="autofocus"
										value={noOfUse != '' ? noOfUse : ''}
										onChange={onChangeNoOfUse}
										placeholder="Quantity" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Show In Pharmacy</label>
									<div class="procedureCatType">
										<input
											autocomplete="off"
											type="checkbox"
											id="chkShowPharmacy"
											name="chkShowPharmacy"
											value={isShowPharmacy}
											onChange={onChangeShowPharmacy} tabindex="-1"
										/>
										<label for="Show In Pharmacy"> </label>
									</div>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Hsn Code</label>

									<input
										autocomplete="off"
										type="text"
										id="hsnCode"
										name="hsnCode"
										autofocus="autofocus"
										value={disposablehsnCode != '' ? disposablehsnCode : ''}
										onChange={onChangeDisposableHsnCode}
										placeholder="Hsn Code" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Batch No*</label>

									<input
										autocomplete="off"
										type="text"
										id="batchNum"
										name="batchNum"
										autofocus="autofocus"
										value={disposablebatchNo}
										onChange={onChangeDisposableBatchNo}
										placeholder="Enter Batch No" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Stock Quantity*</label>

									<input
										autocomplete="off"
										type="text"
										id="stockQuantity"
										name="stockQuantity"
										autofocus="autofocus"
										maxlength="100"
										value={disposablestockQuantity}
										onChange={onChangeDisposableStockQuantity}
										placeholder="Enter Stock Quantity" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label id="expDatY">Expiry Date</label>
									<input
										autocomplete="off"
										type="month"
										name="expireDate"
										id="expireDate"
										autofocus="autofocus"
										value={disposableexpireDate}
										min={requiredDate}
										onChange={onChangeDisposableExpireDate}
										onKeyDown={(e) => e.preventDefault()}
										placeholder='Expiry Date' tabindex="-1"
										// required
										style={{ display: "flex", justifyContent: "space-between" }}
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Reorder Quantity*</label>
									<input
										autocomplete="off"
										type="text"
										id="threshold"
										name="threshold"
										autofocus="autofocus"
										value={disposablereOrderQuantity}
										onChange={onChangeDisposableReOrderQuantity}
										placeholder="Enter Threshold" tabindex="-1"
									/>
								</li>
							</div>
						) : (
							<div className="row" onClick={onBlurBrandCategory}>

								<li class="col-md-4 col-lg-4">
									<label id="medBid">Medicine/Brand Name*</label>
									<input
										autoComplete="off"
										type="text"
										id="hdnMedicineName"
										name="hdnMedicineName"
										autofocus="autofocus"
										value={brandName}
										onChange={onChangeBrandName}
										placeholder="Enter Medicine Name"
										onKeyUp={() => getInventoryAutoComplete()}
										tabindex="-1"
									//	onBlur={onBlurBrandCategory}
									/>
									<ul
										className="autoSearch autoSearch-p0 appointmentNumberSearch itemNameSearch"
										id="medicineList"
										style={{ display: 'none' }}
									>
										{medicineAuto.map((item, i) => (
											<li onClick={() => setMedicineClick(item.medicineDetails, item.medicineId)}>
												{item.medicineDetails}
											</li>
										))}
									</ul>
									<input type="hidden" id="hdnMedicineId" />
								</li>

								<li class="col-md-4 col-lg-4">
									<label>Generic Name</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineGenericName"
										name="hdnMedicineGenericName"
										autofocus="autofocus"
										value={genericName != '' ? genericName : ''}
										onChange={onChangeGenericName}
										placeholder="Enter Generic Name" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Hsn Code</label>

									<input
										autocomplete="off"
										type="text"
										maxlength="100"
										id="hdnMedicineHsnCode"
										name="hdnMedicineHsnCode"
										autofocus="autofocus"
										//	value={hsnCode}
										value={hsnCode != '' ? hsnCode : ''}
										onChange={onChangeHsnCode}
										placeholder="Hsn Code" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Manufacture</label>
									<input
										type="text"
										id="manufactureInput"
										name="cityName"
										placeholder="Item Name"
										className="input-design"
										autoComplete="off"
										onKeyUp={() => getManufactureAutoComplete()}
										style={{ marginBottom: '5px' }} tabindex="-1"
									//	onBlur={onBlurManufacture}
									/>
									<ul
										className="autoSearch autoSearch-p0 appointmentNumberSearch itemNameSearch"
										id="manufactureList"
										style={{ display: 'none' }}
									>
										{manufactureAuto.map((item, i) => (
											<li
												onClick={() =>
													setManufactureClick(item.manufactureName, item.manufactureId)}
											>
												{item.manufactureName + " - " + item.phoneNumber}
											</li>
										))}
									</ul>
									<input type="hidden" id="hiddenManufacId" />
									<p
										id=""
										style={{
											color: 'red',
											display: 'flex',
											justifyContent: 'left',
											fontSize: '12px',
											marginBottom: '4px',
										}}
									>
										{manufactError}
									</p>
									<span class="addManufLink addLinkTxt">
										<a onClick={() => ManufacturePopUpClick()} style={{ color: '#0093cc', textDecoration: 'none' }}>
											Add Manufacturer +
										</a>
									</span>
								</li>

								<li class="col-md-4 col-lg-4">
									<label>Type*</label>
									<select
										autocomplete="off"
										class="fulWidthSelect"
										name="cmbMedicineType"
										id="cmbMedicineType" tabindex="-1"
										//	value={medType}
										value={medType != '' ? medType : ''}
										onChange={onChangeMedType}
									>
										<option value="0">Select Type</option>
										{medicineTypes.map((item, index) => (
											<option value={item.medicineTypeId}>{item.medicineTypeName}</option>
										))}
									</select>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Strength</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineStrength"
										name="hdnMedicineStrength"
										autofocus="autofocus"
										//	value={strength}
										value={strength != '' ? strength : ''}
										onChange={onChangeStrength}
										placeholder="Enter Strength" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Unit</label>
									<select
										autocomplete="off"
										class="fulWidthSelect"
										name="cmbMedicineUnit"
										id="cmbMedicineUnit" tabindex="-1"
										//value={medUnit}
										value={medUnit != '' ? medUnit : ''}
										onChange={onChangeMedUnit}
									>
										<option value="0">Select Unit</option>
										{medicineUnits.map((item, idix) => (
											<option value={item.medicineUnitId}>{item.medicineUnitName}</option>
										))}
									</select>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Interactions</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineInteractions"
										name="hdnMedicineInteractions"
										autofocus="autofocus"
										//value={interactions}
										value={interactions != '' ? interactions : ''}
										onChange={onChangeInteraction}
										placeholder="Enter Interactions" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Drug reactions</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineDrugReactions"
										name="hdnMedicineDrugReactions"
										autofocus="autofocus"
										//value={drugReactions}
										value={drugReactions != '' ? drugReactions : ''}
										onChange={onChangeDrugReactions}
										placeholder="Enter Drug reactions" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Major side effects</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineSideEffect"
										name="hdnMedicineSideEffect"
										autofocus="autofocus"
										//value={majorSideEffects}
										value={majorSideEffects != '' ? majorSideEffects : ''}
										onChange={onChangeMajorSideEffects}
										placeholder="Enter Side Effect" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Indication and dosage</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineIndication"
										name="hdnMedicineIndication"
										autofocus="autofocus"
										//value={dosage}
										value={dosage != '' ? dosage : ''}
										onChange={onChangeDosage}
										placeholder="Enter Indication" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Precautions</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicinePrecautions"
										name="hdnMedicinePrecautions"
										autofocus="autofocus"
										//value={precautions}
										value={precautions != '' ? precautions : ''}
										onChange={onChangePrecautions}
										placeholder="Enter Precautions" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Number Of Units per Strip/Box Required *</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineQuantity"
										name="hdnMedicineQuantity"
										autofocus="autofocus"
										value={stripCount}
										onChange={onChangeStripCount}
										placeholder="Enter Quantity" tabindex="-1"
									/>
								</li>

								<li class="col-md-4 col-lg-4">
									<label>Batch No*</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineBatchNo"
										name="hdnMedicineBatchNo"
										autofocus="autofocus"
										value={batchNo}
										onChange={onChangeBatchNo}
										placeholder="Enter Batch No" tabindex="-1"
									/>
								</li>

								<li class="col-md-4 col-lg-4">
									<label>Stock Quantity*</label>

									<input
										autocomplete="off"
										type="text"
										maxlength="100"
										id="hdnMedicineStockQuantity"
										name="hdnMedicineStockQuantity"
										autofocus="autofocus"
										value={stockQuantity}
										onChange={onChangeStockQuantity}
										placeholder="Enter Stock Quantity" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label id="expDatY">Expiry Date*</label>

									<input
										autocomplete="off"
										name="scheduleDate"
										id="scheduleDate"
										value={expireDate}
										min={requiredDate}
										onChange={onChangeExpireDate}
										onKeyDown={(e) => e.preventDefault()}
										type="month"
										placeholder='Expiry Date'
										style={{ display: "flex", justifyContent: "space-between" }} tabindex="-1"

									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Reorder Quantity*</label>
									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineThreshold"
										name="hdnMedicineThreshold"
										autofocus="autofocus"
										value={reOrderQuantity}
										onChange={onChangeReOrderQuantity}
										placeholder="Enter Threshold" tabindex="-1"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Is generic</label>
									<div class="procedureCatType">
										<input
											autocomplete="off"
											type="checkbox"
											id="chkgeneric"
											name="chkgeneric"
											value={isShowGeneric}
											onChange={onChangeisGeneric} style={{ width: "15px" }} tabindex="-1"
										/>
										<label for="Is generic"> </label>
									</div>
								</li>
							</div>
						)}
					</ul>

					<div class="borderSeparate">
						<span />
					</div>
					<h3 class="practiceHead">Pricing Details</h3>
					<ul className="row">
						<li class="col-md-4 col-lg-4">
							<label>MRP*</label>

							<input
								autocomplete="off"
								type="text"
								id="mrp"
								name="mrp"
								autofocus="autofocus"
								value={mrp}
								onChange={onChangemrp}
								onKeyUp={stripCountBy}
								placeholder="Enter MRP" tabindex="-1"
							/>
						</li>
						<li class="col-md-4 col-lg-4">
							<label>MRP Unit Price*</label>

							<input
								autocomplete="off"
								type="text"
								readonly=""
								id="mrpUnitPrice"
								name="mrpUnitPrice"
								autofocus="autofocus"
								value={mrpUnitPrice}
								placeholder="MRP Unit Price" tabindex="-1"
							/>
						</li>
						<div class="borderSeparate">
							<span />
						</div>
						<div class="subTitileNAme">
							<h3 class="practiceHead">Taxes</h3>
						</div>

						<div class="borderSeparate">
							<span />
						</div>
						{onlycheckedTaxList.length > 0 ? (
							<div className="taxType">
								<div className="taxDataOuter">
									{onlycheckedTaxList.map((option, idx) => {
										return (
											<div className="taxDataSelected">
												{option.isChecked ? (
													option.taxNames +
													': ' +
													(sellingPriceWithoutTax *
														(option.hospitalTaxDetailsTaxValue / 100)).toFixed(2)
												) : (
													''
												)}
											</div>
										);
									})}
								</div>
							</div>
						) :
							(
								''
							)
						}
						<div class="procedureCatList" id="divTaxDetails">
							<div
								class="applicabletaxDiv pl_applicableTax_scroll row"
								style={{ flexWrap: 'nowrap', margin: '0px' }}
							>
								<div class="col-lg-3 procedureCatName">Applicable Taxes</div>

								<div class=" col-lg-9 procedureCatType procedureCatListWrap">
									<div className="row">
										{taxListdata.map((option, idx) => {
											return (
												<div class="col-lg-4 procedureCatType1  procedureCatListWrap ">
													<input
														type="checkbox"
														id={option.hospitalTaxDetailsId}
														// id={option.hospitalTaxDetailsTaxValue}
														value={option.hospitalTaxDetailsTaxName}
														checked={option.isChecked}
														onChange={() => taxesChange(option, idx)} tabindex="-1"
													/>
													<label>{option.taxNames}</label>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<div class="borderSeparate">
							<span />
						</div>
						<div class="subTitileNAme">
							<h3 class="practiceHead">Selling Details</h3>
						</div>
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Selling Price (without tax)*</label>

							<input
								autocomplete="off"
								type="text"
								id="sellingPricewithoutTax"
								name="sellingPricewithoutTax"
								autofocus="autofocus"
								value={sellingPriceWithoutTax}
								onChange={onChangeSellingPriceArea}
								onKeyUp={stripCountBySellingArea}
								placeholder="Enter Price" tabindex="-1"
							/>
						</li>
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Selling Unit Price (without tax)*</label>

							<input
								autocomplete="off"
								type="text"
								readonly=""
								id="sellingUnitPricewithoutTax"
								name="sellingUnitPricewithoutTax"
								autofocus="autofocus"
								value={sellingUnitPriceWithoutTax}
								placeholder="Enter Selling Unit Price" tabindex="-1"
							/>
						</li>
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Selling Price(with tax)*</label>
							<input
								autocomplete="off"
								type="text"
								id="sellingPricewithTax"
								name="sellingPricewithTax"
								autofocus="autofocus"
								value={sellingPriceWithTax}
								onChange={onChangeSellingPriceAreawithTax}
								onKeyUp={stripCountBySellingAreawithTax}
								placeholder="Enter Selling Price" tabindex="-1"
							/>
						</li>
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Selling Unit Price(with tax)*</label>
							<input
								autocomplete="off"
								type="text"
								readonly=""
								id="sellingUnitPricewithTax"
								name="sellingUnitPricewithTax"
								autofocus="autofocus"
								value={sellingUnitPriceWithTax}
								placeholder="Enter Selling Unit Price" tabindex="-1"
							/>
						</li>
					</ul>
					<div class="clearfix" />
					<br />

					<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
						<span className="dashButtonBorder" onClick={saveInventoryDetails} tabindex="-1">
							Save{' '}
						</span>
					</div>
					<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
						<span className="dashButtonBorder" style={{ border: '1px solid #b22222' }}>
							<Link to="/settings/hospitalInventory" style={{ textDecoration: 'none', color: '#393939' }} tabindex="-1">
								Cancel
							</Link>
						</span>
					</div>
				</form>
			</div>
			{popVisible === true &&
				<div id="plpopup12" class="ploverlay" style={{ visibility: 'visible', opacity: 1 }}>
					<div class="plpopup">
						<h2>
							Add Manufacturer{' '}
							<a class="close" onClick={() => ManufactureClose()}>
								&times;
							</a>
						</h2>
						<div class="plcontent">
							<div class="addDataContent">
								<div class="addDataDiv">
									<label>
										Manufacturer Name<span class="mandatory">*</span>
									</label>
									<input autocomplete="off" type="text" id="manufactureName"
										placeholder="Manufacture Name" />
								</div>
								<div class="addDataDiv">
									<label>Address</label>
									<textarea
										autocomplete="off"
										id="manufactureAddress"
										placeholder="Enter Address"
										style={{ height: '85px' }}
									/>
								</div>
								<div class="addDataDiv">
									<label>Email</label>
									<input autocomplete="off" type="text" id="manufactureEmail"
										placeholder="Enter Email" />
								</div>
								<div class="addDataDiv">
									<label>Contact Number<span class="mandatory">*</span></label>
									<input
										maxLength="10"
										autocomplete="off"
										class=""
										style={{ boxShadow: 'none' }}
										type="text"
										id="manufactureNumber"
										placeholder="Mobile Number"
										onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) event.preventDefault(); }}

									/>
								</div>
								<div class="addDataDiv">
									<label>Contact Person</label>
									<input
										autocomplete="off"
										class=""
										style={{ boxShadow: 'none' }}
										type="text"
										id="manufactureContactPerson"
										placeholder="Contact Person"
									/>
								</div>

								<div class="summaryBtnDiv">
									<input
										type="submit"
										value="Save"
										onClick={addNewManufacture}
										class="practiceGreenButton"
									/>

									<a class="close" onClick={() => ManufactureClose()}>
										<input type="submit" value="Cancel" class=" practiceGreyButton" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
}

export default AddInventory;
