import React from 'react';
import { useEffect, useState } from 'react';
import { callingAPI } from '../config';
import $ from 'jquery'
import { Link, useLocation } from 'react-router-dom';
import Loader from '../Common/loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function SimplifiedFormSettings() {
	const [Prescription, setPrescription] = useState()
	const [simplifiedSettingsList, setsimplifiedSettingsList] = useState([])
	const [simplifiedSettingsPrescriptions, setsimplifiedSettingsPrescriptions] = useState('')
	const [simplifiedSettingsLabOrder, setsimplifiedSettingsLabOrder] = useState('')
	const [PrescriptionmodulesId, setPrescriptionmodulesId] = useState()
	const [isTab, setisTab] = useState(true)
	const [isTabPrescriptions, setisTabPrescriptions] = useState(true)
	const [isTabLab, setisTabLab] = useState(true)
	const [isActivePrescriptions, setisActivePrescriptions] = useState(true)
	const [isActiveLab, setActiveLab] = useState(true)
	const [isActiveCasesheet, setisActiveCasesheet] = useState(true)
	const [isActivesubarray, setisActivesubarray] = useState([])
	const [element, setelement] = useState(false)
	const [load, setLoad] = useState(false);
	const [docHosList, setDocHosListList] = useState([])
	const [hospitalIDd, sethospitalIDd] = useState('');
	useEffect(() => {
		getHospitalList();
		simplifiedListing();
	}, []);
	const simplifiedListing = () => {
		const postdata = {

			"doctorId": window.sessionStorage.getItem('userId'),
			"role": window.sessionStorage.getItem('role'),
			"hospitalId": localStorage.getItem("hospitalId"),


		};
		callingAPI('commonUserFunctions/simplifiedSettingsList', postdata).then((res) => {
			if (res.data.success === "1") {
				setisActivesubarray(res.data.result.main[0].sudArray)
				if (window.sessionStorage.getItem('siteBranchId') !== '4') {
					setsimplifiedSettingsLabOrder(res.data.result.main[3].name)
					setisTabLab(res.data.result.main[3].isTab)
					setActiveLab(res.data.result.main[3].isActive)
				}
				setisTab(res.data.result.main[0].isTab)
				setisActiveCasesheet(res.data.result.main[0].isActive)
				setsimplifiedSettingsList(res.data.result.main[0].sudArray)

				setisTabPrescriptions(res.data.result.main[2].isTab)
				setisActivePrescriptions(res.data.result.main[2].isActive)
				setsimplifiedSettingsPrescriptions(res.data.result.main[2].name)

				setLoad(true)
			}
			else {
				setLoad(true)
			}

		});
	}
	const Activesubarray = (event, checkedid) => {

		var currentData = isActivesubarray
		var index = currentData.findIndex(x => x.subId == checkedid)
		currentData[index].isActive = event.target.checked ? checkedid : ""
		setisActivesubarray(isActivesubarray)

	}
	const savedata = () => {
		setLoad(false)
		const postdata =
		{
			"doctorId": window.sessionStorage.getItem('userId'),
			"userId": window.sessionStorage.getItem('userId'),
			"role": window.sessionStorage.getItem('role'),
			"hospitalId": localStorage.getItem("hospitalId"),
			"modules": [
				{
					"tabExpandedForm": isTab,
					"modulesId": isActiveCasesheet == true ? 3 : "",
					"subModules": [
						{
							"subModulesId": isActivesubarray[0].isActive == false ? "" : "1"
						},
						{
							"subModulesId": isActivesubarray[1].isActive == false ? "" : "2"
						},
						{
							"subModulesId": isActivesubarray[2].isActive == false ? "" : "7"
						},
						{
							"subModulesId": isActivesubarray[3].isActive == false ? "" : "10"
						},
						{
							"subModulesId": isActivesubarray[4].isActive == false ? "" : "11"
						},
						{
							"subModulesId": isActivesubarray[5].isActive == false ? "" : "12"
						},
						{
							"subModulesId": isActivesubarray[6].isActive == false ? "" : "30"
						}

					]
				},
				{
					"tabExpandedForm": isTabPrescriptions,
					"modulesId": isActivePrescriptions == true ? 8 : "",
					"subModules": []
				},
				{
					"tabExpandedForm": isTabLab,
					"modulesId": isActiveLab == true ? 9 : "",
					"subModules": []
				}
			]

		}

		callingAPI('commonUserFunctions/saveSimplifiedSettings', postdata).then((res) => {
			if (res.data.success) {
				toast.success('Updated successfully');
				setLoad(true);
				simplifiedListing();
			} else {
				toast.error('failed');
			}

		});
	}
	const saveSettings = (e) => {

		var checkboxCasesheet = document.myform.checkData1.checked



		var subcheckboxCasesheet = document.myform.checkForm112
		var flag = false;
		for (var i = 0; i < subcheckboxCasesheet.length; i++) {
			if (subcheckboxCasesheet[i].checked) {
				flag = true;
				break;

			}
		}
		var subPrescriptions = document.myform.checkData3.checked
		if (window.sessionStorage.getItem('siteBranchId') !== '4') {
			var subLabOrder = document.myform.checkData4.checked
		}
		else {
			var subLabOrder = false;
		}

		if (checkboxCasesheet == false && subPrescriptions == false && subLabOrder == false) {
			toast.error("Atleast One Data Required")
			e.preventDefault();
		}
		else if (checkboxCasesheet == true && flag == false) {
			toast.error("Atleast One Case Sheet Data Required")
			e.preventDefault();
		}
		else if ((checkboxCasesheet && flag == true) || (subPrescriptions || subLabOrder)) {
			e.preventDefault();
			savedata()
		}



		// var checkflag = false

		// if(isActivePrescriptions == false && isActiveLab == false  ){

		// 	 checkflag = false

		// }else{
		// 	checkflag = true
		// }

		// var mediumCheckboxes = document.getElementsByName("checkForm11");
		// 		var flag = false;
		// 		for (var i = 0;  i < mediumCheckboxes.length; i++) {
		// 			if (mediumCheckboxes[i].checked) {
		// 				flag = true;
		// 				break;
		// 			}
		// 		}

		// 		if (flag == false && checkflag == false  ) {
		// 			alert("Atleast One Case Sheet Data Required");
		// 		}




	}
	const Validation = () => {

		var data = document.getElementById("checkTab00")
		if (data.checked === true
			&& document.getElementById('checkData1').checked === false) {
			toast.error("Case Sheet Required")
			setisTab(false)
		}
	}
	const ValidationPrescriptions = () => {

		var data = document.getElementById("checkTab3")
		if (data.checked === true
			&& document.getElementById('checkData3').checked === false) {
			toast.error("Prescriptions Required")
			setisTabPrescriptions(false)
		}
	}

	const ValidationLab = () => {

		var data = document.getElementById("checkTab4")
		if (data.checked === true
			&& document.getElementById('checkData4').checked === false) {
			toast.error("Lab Order Required")
			setisTabLab(false)
		}
	}
	const checkValidationPrescriptions = () => {

		if (
			document.getElementById('checkData3').checked === false) {
			setisTabPrescriptions(false)
			document.getElementById("checkTab3").checked = false
		}
	}
	const checkValidationLab = () => {

		if (document.getElementById('checkData4').checked === false) {
			document.getElementById("checkTab4").checked = false
			setisTabLab(false)
		}
	}
	const checkValidationCasesheet = () => {


		if (document.getElementById('checkData1').checked === false) {
			document.getElementById("checkTab00").checked = false
			setisTab(false)
		}
	}

	const myArray = simplifiedSettingsList.filter(function (obj) {
		return obj.subName !== 'ENT Examination'
	});
	const myArrayData = myArray.filter(function (data) {
		if (window.sessionStorage.getItem('medicineBranchId') === '4') {
			return data.subName !== 'Investigations'
		} else {
			return data.subName !== 'Observations'
		}
	})
	const hospitalChange = (event) => {
		sethospitalIDd(event.target.value);
	};
	const getHospitalList = () => {
		const postData = {
			role: window.sessionStorage.getItem('role'),
			userId: window.sessionStorage.getItem('userId'),
		};
		callingAPI('calenderListing/getHospitalDetails', postData)
			.then((res) => {
				setDocHosListList(res.data.result);
			})
			.catch();
	};
	return (
		<form name="myform">

			<div className="dashboardCover SettingsConsent">
				<ToastContainer position="bottom-right" />
				<div className="dashboardContentCenter">
					<div className="dashboardTitle">Simplified Form Settings</div>
					<div className="dashboardContent">
						{load === false ? <Loader /> :
							<div class="SummaryDivCover">
								<input type="hidden" name="hndRole" id="hndRole" value="ROLE_DOCTOR" />
								{window.sessionStorage.getItem('role') != 'ROLE_HOSPITALADMIN' &&
									docHosList.length > 0 &&
									<select
										name="selHosp"
										id="selHosp"
										autocomplete="off"
										value={hospitalIDd}
										onChange={hospitalChange} tabIndex="1" autoFocus tabindex="-1" style={{ border: '1px solid #ccc', height: "30px", width: '50%' }}
									>

										{docHosList.map((item, idix) => (
											<option value={item.hospitalId}>
												{item.hospitalName}
											</option>
										))}
									</select>
								}

								{/* <input type="submit" name="_action_simplifiedform" value="search" id="search" style="display:none;">        	                 */}



								<div class="moduleCvrDiv">
									<div class="moduleDiv">
										<div class="moduleDivItem">
											<div class="optionWrapper chkSection1">

												<input type="checkbox"
													onClick={() => {
														setisActiveCasesheet(!isActiveCasesheet);

													}}

													checked={isActiveCasesheet} id="checkData1" name="checkData1" />

												<label for="checkData1"></label>
											</div>
											<span class="moduleSelect" id="lblDispName1" style={{ paddingRight: "25px" }}>Casesheet</span>
											{/* <div class="optionWrapper">
											<input type="checkbox"
												checked={isTab}
												onChange={() => {
													setisTab(!isTab);
													Validation()
												}}
												id="checkTab00" name="checkTab00" />

											<label for="checkTab00"></label>
										</div>
										<span>Tab in Expanded Form</span> */}
										</div>

										{myArrayData.map((item) => (

											<div class="moduleDivItem moduleDivItemCvr" style={{ padding: "5px 10px 5px 28px" }}>

												<div class="moduleAddPortion">

													<div class="optionWrapper chkSubForm1">

														<input type="checkbox"
															value={item.subId}
															onChange={(event) => { Activesubarray(event, item.subId); }}

															defaultChecked={item.isActive}

															id={"checkForm11" + item.subId}
															name="checkForm112" />

														<label for={"checkForm11" + item.subId}></label>
													</div>

													<span>{item.subName}</span>

												</div>

											</div>

										))}
									</div>




									<div class="moduleDiv">
										<div class="moduleDivItem">
											<div class="optionWrapper chkSection3">

												<input type="checkbox"
													checked={isActivePrescriptions}
													onClick={() => {
														setisActivePrescriptions(!isActivePrescriptions);

													}}
													id="checkData3" name="checkData3" value="8" />

												<label for="checkData3"></label>
											</div>
											<span class="moduleSelect" id="lblDispName3" style={{ paddingRight: "25px" }}>{simplifiedSettingsPrescriptions}</span>
											{/* <div class="optionWrapper">

											<input type="checkbox"
												onClick={() => {
													setisTabPrescriptions(!isTabPrescriptions);
													ValidationPrescriptions()

												}}
												checked={isTabPrescriptions}
												id="checkTab3" name="checkTab3" />

											<label for="checkTab3"></label>
										</div>
										<span>Tab in Expanded Form</span> */}
										</div>

									</div>
									{window.sessionStorage.getItem("siteBranchId") !== "4" ?
										<div class="moduleDiv">
											<div class="moduleDivItem">
												<div class="optionWrapper chkSection4">

													<input type="checkbox" id="checkData4" name="checkData4" value="9"
														checked={isActiveLab}
														onClick={() => {
															setActiveLab(!isActiveLab);

														}} />

													<label for="checkData4"></label>
												</div>
												<span class="moduleSelect" id="lblDispName4" style={{ paddingRight: "25px" }}>{simplifiedSettingsLabOrder}</span>
												{/* <div class="optionWrapper">

											<input type="checkbox"
												onClick={() => {
													setisTabLab(!isTabLab);
													ValidationLab()
												}}
												checked={isTabLab}

												id="checkTab4" name="checkTab4" />

											<label for="checkTab4"></label>
										</div>
										<span>Tab in Expanded Form</span> */}
											</div>

										</div>
										: ""}


								</div>
								<div class="summaryBtnDiv pt-2 pb-4">
									<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
										<span
											className="dashButtonBorder"
											id="saveTax"
											onClick={(e) => saveSettings(e)}
										>
											Save{' '}
										</span>
									</div>

									{/*	<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
											<span
												className="dashButtonBorder"
												style={{ border: '1px solid #b22222' }}
											>
												Cancel
											</span>

										</div> */}
									{/* 
									<input type="submit" value="Save" id="btnSave" class="practiceGreenButton" onClick={(e) => { saveSettings(e) }} />
									<input type="submit" value="Cancel" id="btnCancell" class="practiceGreyButton" style={{ height: "auto" }} /> */}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</form>

	);
}
export default SimplifiedFormSettings;
