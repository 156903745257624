import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import pulseLoader from '../images/icons/pulseLoader.png';
import { callingAPI } from "../config";


import { useSearchParams } from 'react-router-dom';


function PrescriptiomMessage() {
  // alert("111")

  const [searchParams] = useSearchParams();
  const token = searchParams.get('T');


  useEffect(() => {


    if (token) {
      decreptURL()
    }
  }, [])
  const decreptURL = () => {
    const apiJson = {
      minifiedId: token
    };
    callingAPI("commonUserFunctions/generateSiteUrlByMinifiedUrl", apiJson).then((data) => {

      if (data.data.success === 1) {
        window.location.href = data.data.siteUrl
      }
    })
  }
  return (
    <div class="fullpage-loader">
      <div class="fullpage-loader__logo">
        <div class="loaderWrap" id="loader" style={{ display: 'block' }}>
          <div class="loaderPurple" />
          <img src={pulseLoader} />
        </div>
      </div>
    </div>
  )
}

export default PrescriptiomMessage