import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { callingAPI } from '../config'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function StaffSetPermission() {
	const [staffdropdowndata, setStaffdropdowndata] = useState([])
	const [TopMenuRoleData, setTopMenuRoleData] = useState([]);
	const [LeftMenuRoleData, setLeftMenuRoleData] = useState([])
	//
	const TopMenuLeftMenuDisplay = () => {
		var e = document.getElementById("cmbRole");
		var index = e.selectedIndex;
		if (index === 0) {
			document.getElementById("NursePermission").style.display = 'none'
			document.getElementById("DoctorPermission").style.display = 'none'
			document.getElementById("ReceptionPermission").style.display = 'none'
			document.getElementById("resetSettings").style.display = 'none'
		}
		else if (index === 1) {
			document.getElementById("NursePermission").style.display = 'none'
			document.getElementById("DoctorPermission").style.display = 'block'
			document.getElementById("ReceptionPermission").style.display = 'none'
			document.getElementById("resetSettings").style.display = 'inline-block'
		}
		else if (index === 2) {
			document.getElementById("NursePermission").style.display = 'none'
			document.getElementById("DoctorPermission").style.display = 'none'
			document.getElementById("ReceptionPermission").style.display = 'block'
			document.getElementById("resetSettings").style.display = 'inline-block'
		}
		else if (index === 3) {
			document.getElementById("NursePermission").style.display = 'block'
			document.getElementById("DoctorPermission").style.display = 'none'
			document.getElementById("ReceptionPermission").style.display = 'none'
			document.getElementById("resetSettings").style.display = 'inline-block'
		}
	}
	const [Flag, setFlag] = useState(true)
	const TopMenuDetailsDoctorCheckbox = (event, checkedId, CheckboxFlag) => {
		var currentStateData = TopMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);
		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainIsActive = event.target.checked ? 1 : 0
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainIsActive = event.target.checked ? 1 : 0
			setTopMenuRoleData(currentStateData);
		}
	}
	const TopMenuDetailsDoctorSubCheckbox = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = TopMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsDoctorCheckbox = (event, checkedId, CheckboxFlag) => {
		var currentStateData = LeftMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainDetails.isActive = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainDetails.isActive = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsDoctorSubCheckbox = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = LeftMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);
		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}

	}
	const TopMenuDetailsReceptionCheckbox = (event, checkedId, CheckboxFlag) => {
		var currentStateData = TopMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);
		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainIsActive = event.target.checked ? 1 : 0
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainIsActive = event.target.checked ? 1 : 0
			setTopMenuRoleData(currentStateData);
		}
	}
	const TopMenuDetailsReceptionSubCheckbox = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = TopMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
	}
	const TopMenuDetailsReceptionSubCheckboxForAdd = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = TopMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasAddOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasAddOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsReceptionSubCheckboxForEdit = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = TopMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);


		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasEditOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasEditOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
	}
	const TopMenuDetailsReceptionSubCheckboxForDelete = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = TopMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);


		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasDeleteOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasDeleteOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
	}
	const TopMenuDetailsReceptionSubCheckboxForPrint = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = TopMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasPrintOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasPrintOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsReceptionCheckbox = (event, checkedId, CheckboxFlag) => {
		var currentStateData = LeftMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);
		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainDetails.isActive = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainDetails.isActive = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsReceptionSubCheckbox = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = LeftMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsReceptionSubCheckboxForAdd = (event, checkedId, CheckboxFlag) => {
		var currentStateData = LeftMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);


		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainDetails.hasAddOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainDetails.hasAddOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsReceptionCheckboxForEdit = (event, checkedId, CheckboxFlag) => {
		var currentStateData = LeftMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainDetails.hasEditOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainDetails.hasEditOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsReceptionCheckboxForDelete = (event, checkedId, CheckboxFlag) => {
		var currentStateData = LeftMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainDetails.hasDeleteOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainDetails.hasDeleteOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsReceptionCheckboxForPrint = (event, checkedId, CheckboxFlag) => {
		var currentStateData = LeftMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainDetails.hasPrintOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainDetails.hasPrintOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsReceptionSubCheckboxForSubAdd = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = LeftMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);
		currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasAddOption = event.target.checked ? 1 : 0;
		setLeftMenuRoleData(currentStateData);
		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasAddOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasAddOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsReceptionSubCheckboxForSubEdit = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = LeftMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);


		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasEditOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasEditOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsReceptionSubCheckboxForSubDelete = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = LeftMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasDeleteOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasDeleteOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsReceptionSubCheckboxForSubPrint = (event, checkedId, menuSubId, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = LeftMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);


		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasPrintOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasPrintOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
	}
	const TopMenuDetailsNurseCheckbox = (event, checkedId, CheckboxFlag) => {
		var currentStateData = TopMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainIsActive = event.target.checked ? 1 : 0
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainIsActive = event.target.checked ? 1 : 0
			setTopMenuRoleData(currentStateData);
		}
	}
	const TopMenuDetailsNurseSubCheckbox = (event, checkedId, menuSubId, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = TopMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
	}
	const TopMenuDetailsNurseSubCheckboxForAdd = (event, checkedId, menuSubId, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = TopMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasAddOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasAddOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
	}
	const TopMenuDetailsNurseSubCheckboxForEdit = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = TopMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);

		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasEditOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasEditOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
	}
	const TopMenuDetailsNurseSubCheckboxForDelete = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = TopMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);


		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasDeleteOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasDeleteOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
	}
	const TopMenuDetailsNurseSubCheckboxForPrint = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = TopMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);



		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasPrintOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasPrintOption = event.target.checked ? 1 : 0;
			setTopMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsNurseCheckbox = (event, checkedId, CheckboxFlag) => {
		var currentStateData = LeftMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);


		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainDetails.isActive = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainDetails.isActive = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsNurseSubCheckbox = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = LeftMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);


		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.isActive = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsNurseSubCheckboxForAdd = (event, checkedId, CheckboxFlag) => {
		var currentStateData = LeftMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);


		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainDetails.hasAddOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainDetails.hasAddOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsNurseSubCheckboxForEdit = (event, checkedId, CheckboxFlag) => {
		var currentStateData = LeftMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);



		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainDetails.hasEditOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainDetails.hasEditOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsNurseSubCheckboxForDelete = (event, checkedId, CheckboxFlag) => {
		var currentStateData = LeftMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);



		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainDetails.hasDeleteOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainDetails.hasDeleteOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsNurseSubCheckboxForPrint = (event, checkedId, CheckboxFlag) => {
		var currentStateData = LeftMenuRoleData;
		var index = currentStateData.findIndex(x => x.menuMainId == checkedId);


		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[index].menuMainDetails.hasPrintOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[index].menuMainDetails.hasPrintOption = event.target.checked ? 1 : 0
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsNurseSubCheckboxForSubAdd = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = LeftMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);


		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasAddOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasAddOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsNurseSubCheckboxForSubEdit = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = LeftMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);



		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasEditOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasEditOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsNurseSubCheckboxForSubDelete = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = LeftMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);



		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasDeleteOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasDeleteOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
	}
	const LeftMenuDetailsNurseSubCheckboxForSubPrint = (event, checkedId, menuSubIds, CheckboxFlag) => {
		var SubIdFromValue = event.target.value;
		var currentStateData = LeftMenuRoleData;
		var MainMenuData = currentStateData.findIndex(x => x.menuMainId == checkedId);
		var SubMenuData = currentStateData[MainMenuData].menuSub.findIndex(x => x.menuSubId == SubIdFromValue);



		if (CheckboxFlag) {
			setFlag(false)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasPrintOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
		else {
			setFlag(true)
			currentStateData[MainMenuData].menuSub[SubMenuData].menuSubDetails.hasPrintOption = event.target.checked ? 1 : 0;
			setLeftMenuRoleData(currentStateData);
		}
	}
	const SaveStaffPermission = () => {
		const PostData = {
			"hospitalId": localStorage.getItem("hospitalId"),
			"secRoleId": document.getElementById('cmbRole').value,
			"topMenuDetails": TopMenuRoleData,
			"leftMenuDetails": LeftMenuRoleData,
		}
		callingAPI('staff/saveModuleSettingsHospital', PostData,
		).then((response) => {
			if (response.data.success === "1") {
				toast.success(response.data.successMessage)
				window.location.reload();

			} else {
				toast.error(response.data.errorMessage)
			}
		}
		)
			.catch()

	}

	const roleData = () => {
		setTopMenuRoleData([])
		setLeftMenuRoleData([])
		const PostData = {
			"pmSiteId": window.sessionStorage.getItem("siteId"),
			"hospitalId": localStorage.getItem("hospitalId"),
			"secRoleId": document.getElementById('cmbRole').value,
			"reset": ""
		}
		callingAPI('staff/getOrResetSetPermissions', PostData,
		).then((response) => {
			if (response.data.success === "1") {
				setTopMenuRoleData(response.data.result.topMenuDetails)
				setLeftMenuRoleData(response.data.result.leftMenuDetails)
			}
		}
		)
			.catch()
	}
	const resetSettings = () => {
		setTopMenuRoleData([])
		setLeftMenuRoleData([])
		const PostData = {
			"pmSiteId": window.sessionStorage.getItem("siteId"),
			"hospitalId": localStorage.getItem("hospitalId"),
			"secRoleId": document.getElementById('cmbRole').value,
			"reset": "1"
		}
		callingAPI('staff/getOrResetSetPermissions', PostData,
		).then((response) => {
			if (response.data.success === "1") {
				setTopMenuRoleData(response.data.result.topMenuDetails)
				setLeftMenuRoleData(response.data.result.leftMenuDetails)
			}
		}
		)
			.catch()
	}
	useEffect(() => {
		callingAPI('staff/getRoles',
		).then((response) => {
			if (response.data.success === "1") {
				setStaffdropdowndata(response.data.result)
			}
		}
		)
			.catch()
	}, []);


	return (
		<div className="coverWraper">
			<ToastContainer position="bottom-right" />
			<Header />
			<div className="contentWraper contentWraperScroll pl_staffList">
				<div class="pulseContentCoverWraper">
					<div class="container">
						<div class="content4">
							<div class="container">
								<div class="container">
									<div />
									<div class="tableHolder StaffList-MainDiv">
										<br />
										<h3>Set Permissions</h3>
										<div class="siteSection">
											<div class="siteSelectList">
												<label>Role</label>{' '}
												<span class="siteSelectionField">
													{' '}
													<select name="cmbRole" id="cmbRole" onChange={(event) => { roleData(event); TopMenuLeftMenuDisplay(event) }}>
														<option value="0" selected="selected" >
															Select Role
														</option>
														{staffdropdowndata.map((item, i) =>
															<option value={item.secRoleId} >{item.secRoleDescription}</option>

														)}
													</select>
												</span>
												<span
													class="blueButton"
													id="resetSettings"
													style={{ display: "none" }}
													onClick={(e) => { (resetSettings(e)) }}
												>
													Reset Settings
												</span>
											</div>
										</div>

										<div className="setPermission" >
											{/* ====================  Selected role = Doctor (starts) =================================== */}

											<Tabs id="DoctorPermission" style={{ display: "none" }}  >
												<TabList>
													<Tab>Top Menu</Tab>
													<Tab>Left Menu </Tab>

												</TabList>


												<TabPanel>
													<div class="siteTabContent" >
														<div class="tab-content">
															<div id="topMenu" class="tab-pane fade active in">
																<div class="menuSelectSection">
																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			controller="moduleSettings"
																			class="saveBtn"


																		/>

																	</div>

																	<div class="menuMainSelect" name="cmbRoles" id="cmbRoles">
																		{TopMenuRoleData.map((item, i) =>
																			<ul>
																				<li>
																					<span class="optionWrapper" >
																						<input
																							value={item.menuMainId}
																							type="checkbox"
																							name={item.menuMainName}
																							id={"moduleMainTop7" + item.menuMainId}
																							defaultChecked={item.menuMainIsActive == 1 ? true : false}
																							onChange={(event) => { TopMenuDetailsDoctorCheckbox(event, item.menuMainId, Flag); }}

																						/>

																						<label for={"moduleMainTop7" + item.menuMainId} />
																					</span>{' '}
																					<span class="menuLabelTxt"> {item.menuMainName}</span>{' '}

																					<div class="menuSelectSub">
																						{item.menuSub.map((data, i) =>
																							<ul>
																								<li>
																									<span class="optionWrapper">
																										<input
																											value={data.menuSubId}
																											name={data.menuSubName}
																											type="checkbox"
																											id={"moduleSubTop8" + data.menuSubId}
																											onChange={(event) => { TopMenuDetailsDoctorSubCheckbox(event, item.menuMainId, data.menuSubId, Flag); }}
																											defaultChecked={data.menuSubDetails.isActive == 1 ? true : false}
																										/>
																										<label for={"moduleSubTop8" + data.menuSubId} />
																									</span>
																									<span class="menuLabelTxt">
																										{' '}
																										{data.menuSubName}
																									</span>
																								</li>
																							</ul>
																						)}
																					</div>
																				</li>
																			</ul>
																		)}
																	</div>
																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			class="saveBtn"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</TabPanel>
												<TabPanel>
													<div class="siteTabContent">
														<div class="tab-content">
															<div id="leftMenu" class="tab-pane fade active in">
																<div class="menuSelectSection">
																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			controller="moduleSettings"
																			class="saveBtn"
																		/>
																	</div>
																	<div class="menuMainSelect">
																		{LeftMenuRoleData.map((item, i) =>
																			<ul>
																				<li>
																					<span class="optionWrapper">
																						<input
																							value={item.menuMainId}
																							type="checkbox"
																							name={item.menuMainName}
																							id={"moduleMainLeft105" + item.menuMainId}
																							defaultChecked={item.menuMainDetails.isActive === 1 ? true : false}
																							onChange={(event) => { LeftMenuDetailsDoctorCheckbox(event, item.menuMainId, Flag); }}
																						/>
																						<label for={"moduleMainLeft105" + item.menuMainId} />
																					</span>{' '}
																					<span class="menuLabelTxt"> {item.menuMainName}</span>

																					<div class="menuSelectSub">
																						{item.menuSub.map((data, i) =>
																							<ul>
																								<li>
																									<span class="optionWrapper">
																										<input
																											value={data.menuSubId}
																											type="checkbox"
																											name={data.menuSubName}
																											id={"moduleSubLeft72" + data.menuSubId}
																											defaultChecked={data.menuSubDetails.isActive == 1 ? true : false}
																											onChange={(event) => { LeftMenuDetailsDoctorSubCheckbox(event, item.menuMainId, data.menuSubId, Flag); }}
																										/>
																										<label for={"moduleSubLeft72" + data.menuSubId} />
																									</span>
																									<span class="menuLabelTxt">
																										{' '}
																										{data.menuSubName}
																									</span>
																								</li>

																							</ul>
																						)}
																					</div>

																				</li>



																			</ul>
																		)}
																	</div>
																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			class="saveBtn"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</TabPanel>
											</Tabs>
											{/* ====================  Selected role = Doctor (ends) =================================== */}
											{/* ====================  Selected role = Reception (starts) =================================== */}
											<Tabs id="ReceptionPermission" style={{ display: "none" }}>
												<TabList>
													<Tab>Top Menu</Tab>
													<Tab>Left Menu</Tab>
												</TabList>
												<TabPanel>
													<div class="siteTabContent">
														<div class="tab-content">
															<div id="topMenu" class="tab-pane fade in active">
																<div class="menuSelectSection">
																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			class="saveBtn"
																		/>
																	</div>
																	<div class="menuMainSelect">
																		{TopMenuRoleData.map((item, i) =>
																			<ul>
																				<li>
																					<span class="optionWrapper">
																						<input
																							value={item.menuMainId}
																							type="checkbox"
																							name={item.menuMainName}
																							id={"moduleMainTop28" + item.menuMainId}
																							onChange={(event) => { TopMenuDetailsReceptionCheckbox(event, item.menuMainId, Flag); }}
																							defaultChecked={item.menuMainIsActive == 1 ? true : false}
																						/>
																						<label for={"moduleMainTop28" + item.menuMainId} />
																					</span>{' '}
																					<span class="menuLabelTxt"> {item.menuMainName}</span>
																					<div class="menuSelectSub">
																						{item.menuSub.map((data, i) =>
																							<ul>
																								<li>
																									<span class="optionWrapper">
																										<input
																											value={data.menuSubId}
																											type="checkbox"
																											name={data.menuSubName}
																											id={"moduleSubTop20" + data.menuSubId}
																											defaultChecked={data.menuSubDetails.isActive == 1 ? true : false}
																											onChange={(event) => { TopMenuDetailsReceptionSubCheckbox(event, item.menuMainId, data.menuSubId, Flag); }}
																										/>
																										<label for={"moduleSubTop20" + data.menuSubId} />
																									</span>
																									<span class="menuLabelTxt">
																										{' '}
																										{data.menuSubName}
																									</span>
																									<div class="subList">
																										<div class="subListBlk">
																											<span class="optionWrapper">
																												<input
																													value={data.menuSubId}
																													type="checkbox"
																													name={data.menuSubName}
																													id={"moduleSubTopSubAdd2820" + data.menuSubId}
																													defaultChecked={data.menuSubDetails.hasAddOption == 1 ? true : false}
																													onChange={(event) => { TopMenuDetailsReceptionSubCheckboxForAdd(event, item.menuMainId, data.menuSubId, Flag); }}
																												/>

																												<label for={"moduleSubTopSubAdd2820" + data.menuSubId} />
																											</span>
																											<span class="menuLabelTxt">
																												Add
																											</span>
																										</div>
																										<div class="subListBlk">
																											<span class="optionWrapper">
																												<input
																													value={data.menuSubId}
																													type="checkbox"
																													name={data.menuSubName}
																													id={"moduleSubTopSubEdit2820" + data.menuSubId}
																													defaultChecked={data.menuSubDetails.hasEditOption == 1 ? true : false}
																													onChange={(event) => { LeftMenuDetailsReceptionSubCheckboxForEdit(event, item.menuMainId, data.menuSubId, Flag); }}
																												/>

																												<label for={"moduleSubTopSubEdit2820" + data.menuSubId} />
																											</span>
																											<span class="menuLabelTxt">
																												Edit
																											</span>
																										</div>
																										<div class="subListBlk">
																											<span class="optionWrapper">
																												<input
																													value={data.menuSubId}
																													type="checkbox"
																													name={data.menuSubName}
																													onChange={(event) => { TopMenuDetailsReceptionSubCheckboxForDelete(event, item.menuMainId, data.menuSubId, Flag); }}
																													id={"moduleSubTopSubDelete2820" + data.menuSubId}
																													defaultChecked={data.menuSubDetails.hasDeleteOption == 1 ? true : false}
																												/>

																												<label for={"moduleSubTopSubDelete2820" + data.menuSubId} />
																											</span>
																											<span class="menuLabelTxt">
																												Delete
																											</span>
																										</div>
																										<div class="subListBlk">
																											<span class="optionWrapper">
																												<input
																													value={data.menuSubId}
																													type="checkbox"
																													name={data.menuSubName}
																													id={"moduleSubTopSubPrint2820" + data.menuSubId}
																													onChange={(event) => { TopMenuDetailsReceptionSubCheckboxForPrint(event, item.menuMainId, data.menuSubId, Flag); }}
																													defaultChecked={data.menuSubDetails.hasPrintOption === 1 ? true : false}
																												/>

																												<label for={"moduleSubTopSubPrint2820" + data.menuSubId} />
																											</span>
																											<span class="menuLabelTxt">
																												Print
																											</span>
																										</div>
																									</div>
																								</li>
																							</ul>
																						)}
																					</div>

																				</li>
																			</ul>
																		)}
																	</div>

																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			class="saveBtn"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</TabPanel>
												<TabPanel>
													<div class="siteTabContent">
														<div class="tab-content">
															<div id="leftMenu" class="tab-pane fade active in">
																<div class="menuSelectSection">
																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			class="saveBtn"
																		/>
																	</div>
																	<div class="menuMainSelect">
																		{LeftMenuRoleData.map((item, i) =>
																			<ul>

																				<li>
																					<span class="optionWrapper">
																						<input
																							value={item.menuMainId}
																							type="checkbox"
																							defaultChecked={item.menuMainDetails.isActive == 1 ? true : false}
																							onChange={(event) => { LeftMenuDetailsReceptionCheckbox(event, item.menuMainId, Flag); }}
																							name={item.menuMainName}
																							id={"moduleMainLeft160" + item.menuMainId}
																						/>
																						<label for={"moduleMainLeft160" + item.menuMainId} />
																					</span>{' '}
																					<span class="menuLabelTxt"> {item.menuMainName}</span>


																					{/* if menuSub have data render this */}
																					{item.menuSub.length === 0 && <div class="subList">
																						<div class="subListBlk">
																							<span class="optionWrapper">
																								<input
																									value={item.menuMainId}
																									type="checkbox"
																									name={item.menuMainName}
																									id={"moduleSubTopSubAdd2820" + item.menuMainId}
																									onChange={(event) => { LeftMenuDetailsReceptionSubCheckboxForAdd(event, item.menuMainId, Flag); }}
																									defaultChecked={item.menuMainDetails.hasAddOption == 1 ? true : false}

																								/>

																								<label for={"moduleSubTopSubAdd2820" + item.menuMainId} />
																							</span>
																							<span class="menuLabelTxt">
																								Add
																							</span>
																						</div>
																						<div class="subListBlk">
																							<span class="optionWrapper">
																								<input
																									value={item.menuMainId}
																									type="checkbox"
																									name={item.menuMainName}
																									onChange={(event) => { LeftMenuDetailsReceptionCheckboxForEdit(event, item.menuMainId, Flag); }}
																									id={"moduleSubTopSubEdit2820" + item.menuMainId}
																									defaultChecked={item.menuMainDetails.hasEditOption == 1 ? true : false}
																								/>

																								<label for={"moduleSubTopSubEdit2820" + item.menuMainId} />
																							</span>
																							<span class="menuLabelTxt">
																								Edit
																							</span>
																						</div>
																						<div class="subListBlk">
																							<span class="optionWrapper">
																								<input
																									value={item.menuMainId}
																									type="checkbox"
																									name={item.menuMainName}
																									onChange={(event) => { LeftMenuDetailsReceptionCheckboxForDelete(event, item.menuMainId, Flag); }}
																									id={"moduleSubTopSubDelete2820" + item.menuMainId}
																									defaultChecked={item.menuMainDetails.hasDeleteOption === 1 ? true : false}
																								/>

																								<label for={"moduleSubTopSubDelete2820" + item.menuMainId} />
																							</span>
																							<span class="menuLabelTxt">
																								Delete
																							</span>
																						</div>
																						<div class="subListBlk">
																							<span class="optionWrapper">
																								<input
																									value={item.menuMainId}
																									type="checkbox"
																									name={item.menuMainName}
																									id={"moduleSubTopSubPrint2820" + item.menuMainId}
																									onChange={(event) => { LeftMenuDetailsReceptionCheckboxForPrint(event, item.menuMainId, Flag); }}
																									defaultChecked={item.menuMainDetails.hasPrintOption === 1 ? true : false}
																								/>

																								<label for={"moduleSubTopSubPrint2820" + item.menuMainId} />
																							</span>
																							<span class="menuLabelTxt">
																								Print
																							</span>
																						</div>
																					</div>}
																					{item.menuSub.length > 0 &&
																						<div class="menuSelectSub">
																							{item.menuSub.map((data, i) =>
																								<ul>
																									<li>
																										<span class="optionWrapper">
																											<input
																												value={data.menuSubId}
																												type="checkbox"
																												defaultChecked={data.menuSubDetails.isActive == 1 ? true : false}
																												name={data.menuSubName}
																												onChange={(event) => { LeftMenuDetailsReceptionSubCheckbox(event, item.menuMainId, data.menuSubId, Flag); }}
																												id={"moduleSubLeft113" + data.menuSubId}
																											/>
																											<label for={"moduleSubLeft113" + data.menuSubId} />
																										</span>
																										<span class="menuLabelTxt">
																											{' '}
																											{data.menuSubName}
																										</span>

																										<div class="subList">
																											<div class="subListBlk">
																												<span class="optionWrapper">
																													<input
																														value={data.menuSubId}
																														type="checkbox"
																														name={data.menuSubName}
																														id={"moduleSubLeftSubAdd160113" + data.menuSubId}
																														onChange={(event) => { LeftMenuDetailsReceptionSubCheckboxForSubAdd(event, item.menuMainId, data.menuSubId, Flag); }}
																														defaultChecked={data.menuSubDetails.hasAddOption == 1 ? true : false}
																													/>

																													<label for={"moduleSubLeftSubAdd160113" + data.menuSubId} />
																												</span>
																												<span class="menuLabelTxt">
																													Add
																												</span>
																											</div>
																											<div class="subListBlk">
																												<span class="optionWrapper">
																													<input
																														value={data.menuSubId}
																														type="checkbox"
																														name={data.menuSubName}
																														defaultChecked={data.menuSubDetails.hasEditOption == 1 ? true : false}
																														onChange={(event) => { LeftMenuDetailsReceptionSubCheckboxForSubEdit(event, item.menuMainId, data.menuSubId, Flag); }}
																														id={"moduleSubLeftSubEdit160113" + data.menuSubId}
																													/>

																													<label for={"moduleSubLeftSubEdit160113" + data.menuSubId} />
																												</span>
																												<span class="menuLabelTxt">
																													Edit
																												</span>
																											</div>
																											<div class="subListBlk">
																												<span class="optionWrapper">
																													<input
																														value={data.menuSubId}
																														type="checkbox"
																														name={data.menuSubName}
																														onChange={(event) => { LeftMenuDetailsReceptionSubCheckboxForSubDelete(event, item.menuMainId, data.menuSubId, Flag); }}
																														defaultChecked={data.menuSubDetails.hasDeleteOption == 1 ? true : false}
																														id={"moduleSubLeftSubDelete160113" + data.menuSubId}
																													/>
																													<label for={"moduleSubLeftSubDelete160113" + data.menuSubId} />
																												</span>
																												<span class="menuLabelTxt">
																													Delete
																												</span>
																											</div>
																											<div class="subListBlk">
																												<span class="optionWrapper">
																													<input
																														value={data.menuSubId}
																														type="checkbox"
																														name={data.menuSubName}
																														onChange={(event) => { LeftMenuDetailsReceptionSubCheckboxForSubPrint(event, item.menuMainId, data.menuSubId, Flag); }}
																														defaultChecked={data.menuSubDetails.hasPrintOption == 1 ? true : false}
																														id={"moduleSubLeftSubPrint160113" + data.menuSubId}
																													/>
																													<label for={"moduleSubLeftSubPrint160113" + data.menuSubId} />
																												</span>
																												<span class="menuLabelTxt">
																													Print
																												</span>
																											</div>
																										</div>
																									</li>

																								</ul>
																							)}
																						</div>}
																				</li>


																			</ul>
																		)}
																	</div>
																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			class="saveBtn"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</TabPanel>
											</Tabs>
											{/* ====================  Selected role = Reception (ends) =================================== */}
											{/* ====================  Selected role = Nurse (starts) =================================== */}
											<Tabs id="NursePermission" style={{ display: "none" }}>
												<TabList>
													<Tab>Top Menu</Tab>
													<Tab>Left Menu</Tab>
												</TabList>
												<TabPanel>
													<div class="siteTabContent">
														<div class="tab-content">
															<div id="topMenu" class="tab-pane fade in active">
																<div class="menuSelectSection">
																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			class="saveBtn"
																		/>
																	</div>
																	<div class="menuMainSelect">
																		{TopMenuRoleData.map((item, i) =>
																			<ul>
																				<li>
																					<span class="optionWrapper">
																						<input
																							value={item.menuMainId}
																							type="checkbox"
																							name={item.menuMainName}
																							id={"moduleMainTop281" + item.menuMainId}
																							onChange={(event) => { TopMenuDetailsNurseCheckbox(event, item.menuMainId, Flag); }}
																							defaultChecked={item.menuMainIsActive == 1 ? true : false}
																						/>
																						<label for={"moduleMainTop281" + item.menuMainId} />
																					</span>{' '}
																					<span class="menuLabelTxt"> {item.menuMainName}</span>

																					<div class="menuSelectSub">
																						{item.menuSub.map((data, i) =>
																							<ul>
																								<li>
																									<span class="optionWrapper">
																										<input
																											value={data.menuSubId}
																											type="checkbox"
																											name={data.menuSubName}
																											onChange={(event) => { TopMenuDetailsNurseSubCheckbox(event, item.menuMainId, data.menuSubId, Flag); }}
																											id={"moduleSubTop201" + data.menuSubId}
																											defaultChecked={data.menuSubDetails.isActive == 1 ? true : false}
																										/>
																										<label for={"moduleSubTop201" + data.menuSubId} />
																									</span>
																									<span class="menuLabelTxt">
																										{' '}
																										{data.menuSubName}
																									</span>

																									<div class="subList">
																										<div class="subListBlk">
																											<span class="optionWrapper">
																												<input
																													value={data.menuSubId}
																													type="checkbox"
																													name={data.menuSubName}
																													defaultChecked={data.menuSubDetails.hasAddOption == 1 ? true : false}
																													onChange={(event) => { TopMenuDetailsNurseSubCheckboxForAdd(event, item.menuMainId, data.menuSubId, Flag); }}
																													id={"moduleSubTopSubAdd28201" + data.menuSubId}
																												/>

																												<label for={"moduleSubTopSubAdd28201" + data.menuSubId} />
																											</span>
																											<span class="menuLabelTxt">
																												Add
																											</span>
																										</div>
																										<div class="subListBlk">
																											<span class="optionWrapper">
																												<input
																													value={data.menuSubId}
																													type="checkbox"
																													name={data.menuSubName}
																													id={"moduleSubTopSubEdit28201" + data.menuSubId}
																													onChange={(event) => { TopMenuDetailsNurseSubCheckboxForEdit(event, item.menuMainId, data.menuSubId, Flag); }}
																													defaultChecked={data.menuSubDetails.hasEditOption == 1 ? true : false}
																												/>
																												<label for={"moduleSubTopSubEdit28201" + data.menuSubId} />
																											</span>
																											<span class="menuLabelTxt">
																												Edit
																											</span>
																										</div>
																										<div class="subListBlk">
																											<span class="optionWrapper">
																												<input
																													value={data.menuSubId}
																													type="checkbox"
																													name={data.menuSubName}
																													onChange={(event) => { TopMenuDetailsNurseSubCheckboxForDelete(event, item.menuMainId, data.menuSubId, Flag); }}
																													id={"moduleSubTopSubDelete28201" + data.menuSubId}
																													defaultChecked={data.menuSubDetails.hasDeleteOption === 1 ? true : false}
																												/>
																												<label for={"moduleSubTopSubDelete28201" + data.menuSubId} />
																											</span>
																											<span class="menuLabelTxt">
																												Delete
																											</span>
																										</div>
																										<div class="subListBlk">
																											<span class="optionWrapper">
																												<input
																													value={data.menuSubId}
																													type="checkbox"
																													name={data.menuSubName}
																													onChange={(event) => { TopMenuDetailsNurseSubCheckboxForPrint(event, item.menuMainId, data.menuSubId, Flag); }}
																													id={"moduleSubTopSubPrint28201" + data.menuSubId}
																													defaultChecked={data.menuSubDetails.hasPrintOption === 1 ? true : false}
																												/>

																												<label for={"moduleSubTopSubPrint28201" + data.menuSubId} />
																											</span>
																											<span class="menuLabelTxt">
																												Print
																											</span>
																										</div>
																									</div>
																								</li>
																							</ul>
																						)}
																					</div>

																				</li>
																			</ul>
																		)}
																	</div>

																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			class="saveBtn"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</TabPanel>
												<TabPanel>
													<div class="siteTabContent">
														<div class="tab-content">
															<div id="leftMenu" class="tab-pane fade active in">
																<div class="menuSelectSection">
																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			class="saveBtn"
																		/>
																	</div>
																	<div class="menuMainSelect">
																		{LeftMenuRoleData.map((item, i) =>
																			<ul>
																				<li>
																					<span class="optionWrapper">
																						<input
																							value={item.menuMainId}
																							type="checkbox"
																							onChange={(event) => { LeftMenuDetailsNurseCheckbox(event, item.menuMainId, Flag); }}
																							defaultChecked={item.menuMainDetails.isActive === 1 ? true : false}
																							name={item.menuMainName}
																							id={"moduleMainLeft1601" + item.menuMainId}
																						/>
																						<label for={"moduleMainLeft1601" + item.menuMainId} />
																					</span>{' '}
																					<span class="menuLabelTxt"> {item.menuMainName}</span>
																					{/* if menuSub have data render this */}
																					{item.menuSub.length === 0 && <div class="subList">
																						<div class="subListBlk">
																							<span class="optionWrapper">
																								<input
																									value={item.menuMainId}
																									type="checkbox"
																									name={item.menuMainName}
																									onChange={(event) => { LeftMenuDetailsNurseSubCheckboxForAdd(event, item.menuMainId, Flag); }}
																									id={"moduleSubTopSubAdd282011" + item.menuMainId}
																									defaultChecked={item.menuMainDetails.hasAddOption === 1 ? true : false}

																								/>

																								<label for={"moduleSubTopSubAdd282011" + item.menuMainId} />
																							</span>
																							<span class="menuLabelTxt">
																								Add
																							</span>
																						</div>
																						<div class="subListBlk">
																							<span class="optionWrapper">
																								<input
																									value={item.menuMainId}
																									type="checkbox"
																									name={item.menuMainName}
																									onChange={(event) => { LeftMenuDetailsNurseSubCheckboxForEdit(event, item.menuMainId, Flag); }}
																									id={"moduleSubTopSubEdit282011" + item.menuMainId}
																									defaultChecked={item.menuMainDetails.hasEditOption === 1 ? true : false}
																								/>

																								<label for={"moduleSubTopSubEdit282011" + item.menuMainId} />
																							</span>
																							<span class="menuLabelTxt">
																								Edit
																							</span>
																						</div>
																						<div class="subListBlk">
																							<span class="optionWrapper">
																								<input
																									value={item.menuMainId}
																									type="checkbox"
																									name={item.menuMainName}
																									onChange={(event) => { LeftMenuDetailsNurseSubCheckboxForDelete(event, item.menuMainId, Flag); }}
																									id={"moduleSubTopSubDelete282011" + item.menuMainId}
																									defaultChecked={item.menuMainDetails.hasDeleteOption === 1 ? true : false}
																								/>

																								<label for={"moduleSubTopSubDelete282011" + item.menuMainId} />
																							</span>
																							<span class="menuLabelTxt">
																								Delete
																							</span>
																						</div>
																						<div class="subListBlk">
																							<span class="optionWrapper">
																								<input
																									value={item.menuMainId}
																									type="checkbox"
																									name={item.menuMainName}
																									onChange={(event) => { LeftMenuDetailsNurseSubCheckboxForPrint(event, item.menuMainId, Flag); }}
																									id={"moduleSubTopSubPrint282011" + item.menuMainId}
																									defaultChecked={item.menuMainDetails.hasPrintOption === 1 ? true : false}
																								/>

																								<label for={"moduleSubTopSubPrint282011" + item.menuMainId} />
																							</span>
																							<span class="menuLabelTxt">
																								Print
																							</span>
																						</div>
																					</div>}
																					{/* if menuSub have data render this */}
																					{item.menuSub.length > 0 &&
																						<div class="menuSelectSub">
																							{item.menuSub.map((data, i) =>
																								<ul>
																									<li>
																										<span class="optionWrapper">
																											<input
																												value={data.menuSubId}
																												type="checkbox"
																												onChange={(event) => { LeftMenuDetailsNurseSubCheckbox(event, item.menuMainId, data.menuSubId, Flag); }}
																												name={data.menuSubName}
																												defaultChecked={data.menuSubDetails.isActive == 1 ? true : false}
																												id={"moduleSubLeft1131" + data.menuSubId}
																											/>
																											<label for={"moduleSubLeft1131" + data.menuSubId} />
																										</span>
																										<span class="menuLabelTxt">
																											{' '}
																											{data.menuSubName}
																										</span>
																										<div class="subList">
																											<div class="subListBlk">
																												<span class="optionWrapper">
																													<input
																														value={data.menuSubId}
																														type="checkbox"
																														name={data.menuSubName}
																														onChange={(event) => { LeftMenuDetailsNurseSubCheckboxForSubAdd(event, item.menuMainId, data.menuSubId, Flag); }}
																														id={"moduleSubLeftSubAdd1601131" + data.menuSubId}
																														defaultChecked={data.menuSubDetails.hasAddOption == 1 ? true : false}
																													/>

																													<label for={"moduleSubLeftSubAdd1601131" + data.menuSubId} />
																												</span>
																												<span class="menuLabelTxt">
																													Add
																												</span>
																											</div>
																											<div class="subListBlk">
																												<span class="optionWrapper">
																													<input
																														value={data.menuSubId}
																														type="checkbox"
																														name={data.menuSubName}
																														onChange={(event) => { LeftMenuDetailsNurseSubCheckboxForSubEdit(event, item.menuMainId, data.menuSubId, Flag); }}
																														id={"moduleSubLeftSubEdit1601131" + data.menuSubId}
																														defaultChecked={data.menuSubDetails.hasEditOption == 1 ? true : false}
																													/>

																													<label for={"moduleSubLeftSubEdit1601131" + data.menuSubId} />
																												</span>
																												<span class="menuLabelTxt">
																													Edit
																												</span>
																											</div>
																											<div class="subListBlk">
																												<span class="optionWrapper">
																													<input
																														value={data.menuSubId}
																														type="checkbox"
																														name={data.menuSubName}
																														onChange={(event) => { LeftMenuDetailsNurseSubCheckboxForSubDelete(event, item.menuMainId, data.menuSubId, Flag); }}
																														id={"moduleSubLeftSubDelete1601131" + data.menuSubId}
																														defaultChecked={data.menuSubDetails.hasDeleteOption == 1 ? true : false}
																													/>

																													<label for={"moduleSubLeftSubDelete1601131" + data.menuSubId} />
																												</span>
																												<span class="menuLabelTxt">
																													Delete
																												</span>
																											</div>
																											<div class="subListBlk">
																												<span class="optionWrapper">
																													<input
																														value={data.menuSubId}
																														type="checkbox"
																														name={data.menuSubName}
																														onChange={(event) => { LeftMenuDetailsNurseSubCheckboxForSubPrint(event, item.menuMainId, data.menuSubId, Flag); }}
																														id={"moduleSubLeftSubPrint1601131" + data.menuSubId}
																														defaultChecked={data.menuSubDetails.hasPrintOption == 1 ? true : false}
																													/>

																													<label for={"moduleSubLeftSubPrint1601131" + data.menuSubId} />
																												</span>
																												<span class="menuLabelTxt">
																													Print
																												</span>
																											</div>
																										</div>
																									</li>

																								</ul>
																							)}
																						</div>}
																				</li>


																			</ul>
																		)}
																	</div>
																	<div class="tabButton">
																		<input
																			onClick={SaveStaffPermission}
																			type="submit"
																			name="_action_saveModuleSettingsHospital"
																			value="Save"
																			class="saveBtn"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</TabPanel>
											</Tabs>
											{/* ====================  Selected role = Reception (ends) =================================== */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="dividerSml" />
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default StaffSetPermission;
