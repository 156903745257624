import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { callingAPI } from "../config";
import AWS from "aws-sdk";
import Datepicker from "react-datepicker";
import moment from "moment";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import $ from 'jquery'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var s3;

function MemberDetails(props) {
  var getS3folder =
    process.env.REACT_APP_S3_URI +
    process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
    process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH;

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    region: "us-east-1",
  });
  var bucketParams = {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
  };
  s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
  const [file, setFile] = useState('');
  const [imageName, setImageName] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [dOB, setDOB] = useState("");
  const [doctorDOB, setDoctorDOB] = useState(new Date());
  const [dataflag, setdataflag] = useState(false);
  const [dOB1, setDOB1] = useState("");
  const [displayErrMsg1, setDisplayErrMsg1] = useState("");

  const [state, setState] = useState({
    email: "",
    mobileno: "",
    firstname: "",
    lastname: "",
    gender: "Male",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const onChange = (event) => {
    const { name, value } = event.target;
    const re = /^[0-9\b]+$/;
    if (name == "mobileno" || name == "zip") {
      if (value === "" || re.test(value)) {
        setState((prevState) => ({ ...prevState, [name]: value }));
      }
    } else setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleImageUploadOnChange = (e) => {
    setFile(e.target.files[0]);
    setImageName(e.target.files[0].name.replace(/ /g, "-"));
    // setImagePreview(URL.createObjectURL(e.target.files[0]));
    if (e.target.files[0].size > 3000000) {
      toast.error("Image should be less than 3 MB");
      setImagePreview("");
      return false;
    }
    else if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
      toast.error("Please attach jpg,jpeg or png files");
      return false;
    }

    else {
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    }
  };
  const change = (e) => {
    window.location.href = "/Dashboard/changepassword";
  };
  const fileUpload = () => {

    let S3BucketMainFolder =
      process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
      process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH;
    let fullFilePath = S3BucketMainFolder + "/" + imageName;
    if (file.size < 1000000) {
      s3.upload(
        {
          Key: fullFilePath,
          Body: file,
          ACL: "public-read",
        },
        (err, data) => {
          if (err) {
            toast.error("There was an error uploading your photo: " + err.message);
            return false;
          }
        }

      );
    }

  };

  const saveMemberDetails = async () => {
    if (file != "") {
      await fileUpload();
    }
    await saveMemberDetails1();
    //await costAndQuantityCalculation(j)
  };
  const saveMemberDetails1 = () => {
    setdataflag(false)
    if (file.size > 3000000) {
      setdataflag(false)
      toast.error("Image should be less than 3 mb");
      return false;
    }
    // fileUpload();
    var dateofbirths;
    if (doctorDOB === "") {
      setdataflag(false)
      // dateofbirths=moment(dOB).format('YYYY/MM/DD')
      var fromDateSplit = dOB.split("/");
      fromDateSplit =
        fromDateSplit[2] + "-" + fromDateSplit[1] + "-" + fromDateSplit[0];
      dateofbirths = fromDateSplit;
    } else {
      setdataflag(false)
      dateofbirths = moment(doctorDOB).format("YYYY-MM-DD");
    }

    var flag = true;
    var emailregex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var phNum = /^\d{10}$/;
    // if (!state.email) {
    // 	props.errFunctionCallback('Please enter Email');
    // 	document.getElementById('email').focus();
    // 	flag = false;
    // } else if (!emailregex.test(state.email)) {
    // 	props.errFunctionCallback('Please enter valid Email');
    // 	document.getElementById('email').focus();
    // 	flag = false;
    // } else
    if (!state.mobileno) {
      setdataflag(false)
      setDisplayErrMsg1("Please enter Mobile Number");
      document.getElementById("mobileno").focus();
      alertlanguage();
      flag = false;
    } else if (!state.mobileno.match(phNum)) {
      setdataflag(false)
      setDisplayErrMsg1("Enter a Valid Mobile Number");
      document.getElementById("mobileno").focus();
      alertlanguage();
      flag = false;
    } else if (!state.firstname) {
      setdataflag(false)
      setDisplayErrMsg1("Please enter First Name");
      alertlanguage();
      document.getElementById("firstname").focus();
      flag = false;
    } else if (!state.lastname) {
      setdataflag(false)
      setDisplayErrMsg1("Please enter Last Name");
      alertlanguage();
      document.getElementById("lastname").focus();
      flag = false;
    } else if (document.getElementById("hospitalExpensecreatedOn").value === "") {
      setdataflag(false)
      setDisplayErrMsg1("Please enter Date of Birth");
      alertlanguage();
      document.getElementById("hospitalExpensecreatedOn").focus();

      flag = false;
    }
    if (flag === true) {
      setdataflag(true)
      setDisplayErrMsg1("");

      let valuesToSend = {};
      valuesToSend.Pmsiteid = window.sessionStorage.getItem("siteId");
      valuesToSend.doctorEmail = state.email;
      valuesToSend.doctorId = window.sessionStorage.getItem("doctorId");
      valuesToSend.secUserMobileNumber = state.mobileno;
      valuesToSend.doctorFirstName = state.firstname;
      valuesToSend.doctorLastName = state.lastname;
      valuesToSend.doctorGender = state.gender;

      // valuesToSend.secUserSignature = ""
      valuesToSend.secUserSignature = imageName;
      valuesToSend.doctorDateOfBirth = dateofbirths;
      valuesToSend.doctorAddress = state.address;
      valuesToSend.doctorCountry = state.country;
      valuesToSend.doctorState = state.state;
      valuesToSend.doctorCity = state.city;
      valuesToSend.doctorZip = state.zip;
      valuesToSend.subFunctionName = "updateMemberDetails";
      callingAPI("doctorProfile/updateDoctorProfile", valuesToSend)
        .then((res) => {
          if (res.data.success === "1") {
            // if (file != '') {
            // 	fileUpload();
            // }
            toast.success(res.data.successMessage);

            window.location.href = "/Dashboard/profile";


          } else {

            toast.error(res.data.errorMessage);
            setdataflag(false)
          }

          // alert(res.data.successMessage);

        })
        .catch();

      // setdataflag(false)
    }

  };
  // const formatDate = (dateStr) => {
  // 	const [ day, month, year ] = dateStr.split('-');
  // 	let newDate = `${year}-${month}-${day}`;
  // 	return newDate;
  // };
  const ageChange = (date) => {
    setDoctorDOB(date);
  };
  const alertlanguage = () => {

    setTimeout(() => {

      setDisplayErrMsg1("");
    }, 2000);
  };

  useEffect(() => {
    if (props.memberDetails) {
      setState({
        email: props.memberDetails.secUserEmail,
        mobileno: props.memberDetails.secUserMobileNumber,
        firstname: props.memberDetails.doctorFirstName,
        lastname: props.memberDetails.doctorLastName,
        gender: props.memberDetails.doctorGender,
        address: props.memberDetails.doctorAddress,
        city: props.memberDetails.doctorCity,
        state: props.memberDetails.doctorState,
        country: props.memberDetails.doctorCountry,
        zip: props.memberDetails.doctorZip,
        // userTitle:props.memberDetails.userTitle
      });
      window.sessionStorage.setItem("userName", props.memberDetails.userTitle + " " + props.memberDetails.doctorFirstName + " " + props.memberDetails.doctorLastName)
      setImagePreview(
        process.env.REACT_APP_S3_BUCKET_URL +
        process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
        process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH +
        "/" +
        props.memberDetails.secUserSignature
      );

      setImageName(props.memberDetails.secUserSignature);
      if (props.memberDetails.doctorDateOfBirth !== "01/01/1900") {
        var dateofbirths1;
        if (props.memberDetails.doctorDateOfBirth) {

          var fromDateSplit = props.memberDetails.doctorDateOfBirth.split("-");

          dateofbirths1 = fromDateSplit;
          setDoctorDOB(new Date(fromDateSplit[2] + "," + fromDateSplit[1] + "," + fromDateSplit[0]))
        }

        setDOB(dateofbirths1)

      } else {
        setDOB("");

      }


    }

  }, [props.memberDetails]);
  return (
    <div>
      <div className="homeProfileDetails">
        <ToastContainer position="bottom-right" />
        <div className="practiceManagementGrid profileForm">
          <div className="row rowMargin">
            <div className="col-md-12">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  padding: "0px",
                  margin: "0px",
                  fontSize: "13px",
                  paddingBottom: "15px",
                }}
              >
                {displayErrMsg1}
              </p>
            </div>
            <div
              className="col-md-6 col-lg-6 practiceManagementLi
            st"
            >
              <label className="labelTextField labelMarginBottom">
                Email ID (Username)<sup>*</sup>
              </label>

              <input
                readOnly
                type="text"
                name="email"
                id="email"
                value={state.email}
                onChange={onChange}
                className="inputField"
                style={{ background: "#f1f1f1" }}
              />
            </div>
            <div className="col-md-6 col-lg-6 practiceManagementList">
              <label className="labelTextField labelMarginBottom">
                Mobile Number<sup>*</sup>
              </label>
              <input
                type="text"
                maxLength="10"
                value={state.mobileno}
                id="mobileno"
                onChange={onChange}
                name="mobileno"
                className="inputField"
                placeholder="Mobile Number"
              />
            </div>
            <div className="col-md-6 col-lg-6 practiceManagementList">
              <label className="labelTextField labelMarginBottom">
                First Name<sup>*</sup>
              </label>
              <input
                type="text"
                value={state.firstname}
                id="firstname"
                onChange={onChange}
                name="firstname"
                className="inputField"
                placeholder="First Name"
              />
            </div>
            <div className="col-md-6 col-lg-6 practiceManagementList">
              <label className="labelTextField labelMarginBottom">
                Last Name<sup>*</sup>
              </label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                onChange={onChange}
                value={state.lastname}
                className="inputField"
                placeholder="Last Name"
              />
            </div>
            <div className="col-md-6 col-lg-6 practiceManagementList">
              <label className="labelTextField labelMarginBottom">Gender</label>
              <select
                className="inputField"
                onChange={onChange}
                value={state.gender}
                name="gender"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="col-md-6 col-lg-6 practiceManagementList">
              <label className="labelTextField labelMarginBottom">
                Date Of Birth<sup>*</sup>
              </label>
              <Datepicker
                // required
                className="Datepicker pa2 inputField"
                placeholderText="Date of birth"
                calendarClassName="rasta-stripes"
                dateFormat="dd/MM/yyyy"
                name="hospitalExpensecreatedOn"
                id="hospitalExpensecreatedOn"
                autofocus="autofocus"
                // onFocus={new Date(20/12/1992)}
                // focusedDate={new Date(1997, 1, 20)}

                selected={doctorDOB}
                //value={doctorDOB ||dOB}
                onChange={(date) => ageChange(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"

              />

            </div>
            <div className="col-md-6 col-lg-6 practiceManagementList">
              <label className="labelTextField labelMarginBottom">
                Address
              </label>
              <textarea
                disabled
                onChange={onChange}
                value={state.address}
                id="address"
                name="address"
                className="textareaField"
                placeholder="Address"
              />
            </div>
            <div className="col-md-6 col-lg-6 practiceManagementList">
              <label className="labelTextField labelMarginBottom">City</label>
              <input
                disabled
                type="text"
                value={state.city}
                name="city"
                className="inputField"
                onChange={onChange}
                placeholder="City"
              />
              <div className="profileSplit" />
              <label className="labelTextField labelMarginBottom">State</label>
              <input
                disabled
                type="text"
                value={state.state}
                name="state"
                onChange={onChange}
                className="inputField"
                placeholder="State"
              />
            </div>
            <div className="col-md-6 col-lg-6 practiceManagementList">
              <label className="labelTextField labelMarginBottom">
                Country
              </label>
              <input
                disabled
                type="text"
                value={state.country}
                onChange={onChange}
                name="country"
                className="inputField"
                placeholder="Country"
              />
            </div>
            <div className="col-md-6 col-lg-6 practiceManagementList">
              <label className="labelTextField labelMarginBottom">Zip</label>
              <input
                disabled
                type="text"
                placeholder="Zip"
                value={state.zip}
                onChange={onChange}
                name="zip"
                className="inputField"
              />
            </div>
            <div class="col-md-12">
              <label for="Image" className="labelTextField labelMarginBottom">
                <div class="healthpageDocSpl">Signature(jpeg,png,image should be less than 3 mb)</div>
              </label>

              <div
                class="fileUpload_upload btn_upload btn-primary"
                style={{ display: "inline-block", float: "left" }}
              >
                <span style={{ color: "#222", paddingLeft: "0px", cursor: "pointer" }}>
                  Upload
                </span>
                <input
                  type="file"
                  id="file3"
                  name="file3"
                  // value=""
                  class="upload_upload"
                  onChange={(e) => handleImageUploadOnChange(e)} style={{ cursor: "pointer" }}
                />
              </div>
              {imagePreview !== null && (
                <div
                  class="bannerTumb mrg_top"
                  id="divThumb3"
                  title=""
                  style={{
                    display: imagePreview ? '' : 'none',
                    height: '50px',
                    width: '50px',
                    overflow: 'hidden',
                    objectFit: 'contain',
                    margin: '0 10px',
                  }}
                >
                  <img
                    id="imageThumb3"
                    src={imagePreview || getS3folder + imageName}
                    style={{ width: "35px", height: "30px", marginLeft: "15px" }}
                  />
                </div>
              )}
            </div>

            <div className="col-md-4 col-lg-4 practiceManagementList">
              <button
                disabled={dataflag}
                class="borderButton borderButtonLarge legoButton_green"
                onClick={saveMemberDetails}
                style={{ width: "100%" }}
              >
                Save
              </button>
            </div>
            <div className="col-md-12 col-lg-12 practiceManagementList">
              {/* <Link
                to="/Dashboard/changepassword"
                className="profileLink"
                style={{ fontSize: "14px" }}
              >
                Change Password
              </Link> */}
              <span
                style={{ fontSize: "14px" }}
                className="profileLink"
                onClick={change}
              //style={{ width: "100%" }}
              >
                Change Password
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberDetails;
