import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Img from '../images/icons/chart.jpg';
import Addmore from '../images/icons/addmore.png';
import close from '../images/icons/close.png';
import { callingAPI } from '../config';
import AWS from 'aws-sdk';
import DoctorTimeScedules from '../Doctor/DoctorTimeScedules';
import Loader from '../Common/loader';
import { render } from 'preact';
import useStateWithCallback from 'use-state-with-callback';
import $ from 'jquery'
import { Link, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var s3Path =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH;
var s3;
function DoctorListAdd(props) {
	const location = useLocation();
	const setStateCountrychangeTrigger = () => {
		if (Countrychange) {
			getAllCountryData(Countrychange.split('-')[0], 'state');
		}
	}
	const [specialisationlist, setSpecialisation] = useState([]);
	var a;
	//	const year = (new Date()).getFullYear();

	const [getyear, setyear] = useState((new Date()).getFullYear());
	const [doctorsMainSpecialisation, setdoctorsMainSpecialisation] = useState([]);
	const [SearchDoctorgo, setSearchDoctor] = useState('');
	const [DoctorTitle, setDoctorTitle] = useState("Dr");
	const [DoctorMrs, setDoctorMrs] = useState([]);
	const [DoctorFirstName, setDoctorFirstName] = useState('');
	const [disableflag, setdisableflag] = useState(false);
	const [DoctorLastName, setDoctorLastName] = useState('');
	const [DoctorDisplayName, setDoctorDisplayName] = useState('');
	const [DoctorEmail, setDoctorEmail] = useState('');
	const [DoctorAdress, setDoctorAddress] = useState('');
	const [DoctorPinCode, setDoctorPinCode] = useState('');
	const [DoctorPhone, setDoctorPhone] = useState('');
	const [DoctorMobile, setDoctorMobile] = useState('');
	const [DoctorMedicalRegisterNo, setMedicalRegisterNo] = useState('');
	const [DoctorAbout, setAboutDoc] = useState('');
	const [state, setStateData] = useState([]);
	const [Country, setCountryData] = useState([]);
	const [city, setcitychange] = useState([]);
	const [locations, setlocation] = useState([]);
	const [Language, setlanguageData] = useState([]);
	// const [unsortedArray, setUnsortedArray] = useState([]);
	const [EducationDetails, seteducation] = useState([]);
	const [file, setFile] = useState('');
	const [imagePreview, setImagePreview] = useState(null);
	const [imageName, setImageName] = useState(null);
	const [ThisDoctorisOwner, setThisDoctorisOwner] = useState(false);
	const [LaguageChangedId, setLaguageChangeIds] = useState([]);
	const [WorkTimeFilter, setWorkTimeFilter] = useState([]);
	const [Countrychange, setStateCountrychange] = useState('');//useStateWithCallback('', setStateCountrychangeTrigger);
	const [statechanged, setStateChanged] = useState('');
	const [CityIdOnselect, setCityId] = useState('');
	const [locationId, setlocationId] = useState('');
	const [PracticeStartyearDatechange, setPracticeStartyearDatechange] = useState('');
	const [Selectgender, setgender] = useState('');
	const [eductionspecificationID, setEducationSpecificationID] = useState(0);
	const [EducationYearChangeOnSelect, setyearDatachange] = useState('');
	const [EducationDetailsCollageName, setEducationDetailsCollageName] = useState([]);
	const [disposableitemsaddedarr, setDisposableItemsAddedArr] = useState([]);
	const [Medicinebranchclickselect, setMedicinebranchclick] = useState('');
	const [primarySpecialCheck, setprimarySpecialCheck] = useState(false);
	const [DataFlag, setDataFlag] = useState('');
	const [ClickName, setClickName] = useState(window.sessionStorage.getItem('userName'));
	const [hospitalId, sethospitalId] = useState('');
	const [flagValue, setflagValue] = useState(true);
	const [DoctorSchDetails, setDoctorSchDetails] = useState([
		{
			IsMonday: false,
			IsTuesday: false,
			IsWednesday: false,
			IsThursday: false,
			IsFriday: false,
			IsSaturday: false,
			IsSunday: false,
			schedule1from: '',
			schedule1to: '',
			schedule2from: '',
			schedule2to: '',
		},
	]);
	////Medicine Branch
	const medicinebranchclick = async (value, data) => {
		setMedicinebranchclick(value);
		let PostData = {
			medicineBranchId: value,
		};
		callingAPI('commonUserFunctions/specialization', PostData).then((response) => {
			if (response.data.success === '1') {
				// const unsortedArray = [response.data.result.mainSpecialization]
				setdoctorsMainSpecialisation(response.data.result.mainSpecialization.sort());
				// setTimeout(special1(data), 500);
				setTimeout(() => special1(data), 500);
			}
		});
	};
	//////primarySpecial
	const primarySpecial = (id, value) => {
		if (value === true) {
			setprimarySpecialCheck(true);
		}
		if (
			document.getElementById('primarySpecialId' + id).checked === true &&
			document.getElementById('chkInsurance' + id).checked === false
		) {
			toast.error('Please add corresponding Specialisation');
			document.getElementById('primarySpecialId' + id).checked = false;
		}
	};
	const getAllCountryData = (value, type, countryID) => {
		let countryId = '';
		let stateId = '';
		let cityId = '';
		if (type === 'country') {
			countryId = '';
		} else if (type === 'state') {
			countryId = value;
		} else if (type === 'city') {
			stateId = value;
			countryId = countryID;
		} else if (type === 'location') {
			stateId = statechanged.split('-')[0];
			countryId = Countrychange.split('-')[0];
			cityId = value;
		}
		let PostData = {
			country: countryId,
			state: stateId,
			city: cityId,
		};
		callingAPI('doctorProfile/getCountryBased', PostData).then((response) => {
			if (response.data.success === '1') {
				setCountryData(response.data.result.country);
				if (countryId !== '') {
					setStateData(response.data.result.state);
				}
				if (countryId && stateId !== '') {
					setcitychange(response.data.result.city);
				}
				if (countryId && stateId && cityId !== '') setlocation(response.data.result.location);
			}
		});
	};
	////Doctor SChedule
	const NewChangeAdd = (e, index) => {
		const { name, value, checked } = e.target;
		if (name == 'IsMonday') {
			DoctorSchDetails.map((item, idx) => {
				item.IsMonday = false;
				return item;
			});
		} else if (name == 'IsTuesday') {
			DoctorSchDetails.map((item, idx) => {
				item.IsTuesday = false;
				return item;
			});
		} else if (name == 'IsWednesday') {
			DoctorSchDetails.map((item, idx) => {
				item.IsWednesday = false;
				return item;
			});
		} else if (name == 'IsThursday') {
			DoctorSchDetails.map((item, idx) => {
				item.IsThursday = false;
				return item;
			});
		} else if (name == 'IsFriday') {
			DoctorSchDetails.map((item, idx) => {
				item.IsFriday = false;
				return item;
			});
		} else if (name == 'IsSaturday') {
			DoctorSchDetails.map((item, idx) => {
				item.IsSaturday = false;
				return item;
			});
		} else if (name == 'IsSunday') {
			DoctorSchDetails.map((item, idx) => {
				item.IsSunday = false;
				return item;
			});
		}
		const values = [...DoctorSchDetails];
		if (name == 'IsMonday') {
			values[index].IsMonday = checked;
		} else if (name == 'IsTuesday') {
			values[index].IsTuesday = checked;
		} else if (name == 'IsWednesday') {
			values[index].IsWednesday = checked;
		} else if (name == 'IsThursday') {
			values[index].IsThursday = checked;
		} else if (name == 'IsFriday') {
			values[index].IsFriday = checked;
		} else if (name == 'IsSaturday') {
			values[index].IsSaturday = checked;
		} else if (name == 'IsSunday') {
			values[index].IsSunday = checked;
		} else {
			values[index] = { ...values[index], [name]: value };
		}
		setDoctorSchDetails(values);
	};
	const AddmoreButtone = () => {
		var copy = [...DoctorSchDetails];
		var arrayScheduleObject = {
			IsMonday: false,
			IsTuesday: false,
			IsWednesday: false,
			IsThursday: false,
			IsFriday: false,
			IsSaturday: false,
			IsSunday: false,
			schedule1From1: '',
			schedule1To1: '',
			schedule2From2: '',
			schedule2To2: '',
		};
		if (DoctorSchDetails.length < 7) {
			copy.push(arrayScheduleObject);
		}
		setDoctorSchDetails(copy);
	};
	const RemoveButton = (e, i) => {
		let copyAppendValuesSch = [...DoctorSchDetails];
		copyAppendValuesSch.splice(i, 1);
		setDoctorSchDetails(copyAppendValuesSch);
	};
	const onChangeOwner = (event) => {
		setThisDoctorisOwner(event.target.checked);
	};
	//////////////////////////
	const categoryTypeNewChange = (e, i) => {
		//	yearDropdown(i)
		const { name, value } = e.target;
		const values = [...disposableitemsaddedarr];
		values[i] = { ...values[i], [name]: value };
		setDisposableItemsAddedArr(values);
	};
	const setEducationFirtRow = (eductionspecificationID) => {
		setEducationSpecificationID(eductionspecificationID);
		var copy = [...disposableitemsaddedarr];
		var obj = {
			educationCategoryId: eductionspecificationID,
			educationYear: EducationYearChangeOnSelect,
			educationCollege: EducationDetailsCollageName,
		};
		copy.push(obj);
	};
	const addRow = (e, index) => {
		var flag = true;
		if (!eductionspecificationID) {
			toast.error('Educational Details Required');
			flag = false;
		}
		var copy = [...disposableitemsaddedarr];
		var selectededucationCollege = copy.find((object) => object.educationCollege == EducationDetailsCollageName);
		var selectededucationYear = copy.find((object) => object.educationYear == EducationYearChangeOnSelect);
		var selectededucationCategoryId = copy.find((object) => object.educationCategoryId == eductionspecificationID);
		if (selectededucationCollege !== undefined &&
			selectededucationYear !== undefined &&
			selectededucationCategoryId !== undefined) {
			if (selectededucationCollege.educationCollege == EducationDetailsCollageName
			) {
				toast.error('College Name is already added');
				flag = false;
				// window.scrollTo(0, 0)
			}
			else if (selectededucationYear.educationYear == EducationYearChangeOnSelect) {
				toast.error('Year is already added');
				flag = false;
			}
			else {
				toast.error('Education is already added');
				flag = false;
			}
		}
		if (flag == true) {
			var copy = [...disposableitemsaddedarr];
			var obj = {
				educationCategoryId: eductionspecificationID,
				educationYear: EducationYearChangeOnSelect,
				educationCollege: EducationDetailsCollageName,
			};
			copy.push(obj);
			setDisposableItemsAddedArr(copy);
			setEducationSpecificationID('');
			setyearDatachange('');
			setEducationDetailsCollageName('');
		}
	};
	const removeRow = (e, i) => {
		let copyAppendValues = [...disposableitemsaddedarr];
		copyAppendValues.splice(i, 1);
		setDisposableItemsAddedArr(copyAppendValues);
		setEducationSpecificationID('');
		setyearDatachange('');
		setEducationDetailsCollageName('');
	};
	//////picture Upload
	AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
	/////////////////////////////////////////////////////
	const handleImageUploadOnChange = (e) => {
		setFile(e.target.files[0]);
		setImageName(e.target.files[0].name.replace(/ /g, '-'));
		setImagePreview(URL.createObjectURL(e.target.files[0]));
	};
	////imageSave
	const imageS3Save = (e) => {
		let S3BucketMainFolder =
			process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH;
		let fullFilePath = S3BucketMainFolder + '/' + imageName;
		s3.upload(
			{
				Key: fullFilePath,
				Body: file,
				ACL: 'public-read',
			},
			(err, data) => {
				if (err) {
					toast.error('There was an error uploading your photo: ' + err.message);
					return null;
				} else {
					// alert("success")
				}
			}
		);
	};
	const setLanguageData = (e) => {
		const selectedLanguageId = [];
		let selectedOption = e.target.selectedOptions;
		for (let i = 0; i < selectedOption.length; i++) {
			selectedLanguageId.push(selectedOption.item(i).value);
		}
		setLaguageChangeIds(selectedLanguageId);
	};
	const WorkFilter = (e) => {
		const WorkTimeFilterIdsSelected = [];
		let selectedOption = e.target.selectedOptions;
		for (let i = 0; i < selectedOption.length; i++) {
			WorkTimeFilterIdsSelected.push(selectedOption.item(i).value);
		}
		setWorkTimeFilter(WorkTimeFilterIdsSelected);
	};
	const Title = (value) => {
		setDoctorTitle(value);
	};
	const Mrs = (value) => {
		setDoctorMrs(value);
	};
	const FirstName = (value) => {
		setDoctorFirstName(value);
	};
	const LastName = (value) => {
		setDoctorLastName(value);
	};
	const DisplayName = (value) => {
		setDoctorDisplayName(value);
	};
	const Email = (value) => {
		setDoctorEmail(value);
	};
	const DoctorAddress = (value) => {
		setDoctorAddress(value);
	};
	const PinCode = (value) => {
		setDoctorPinCode(value);
	};
	const Phone = (value) => {
		setDoctorPhone(value);
	};
	const Mobile = (value) => {
		setDoctorMobile(value);
	};
	const MedicalRegisterNo = (value) => {
		setMedicalRegisterNo(value);
	};
	const AboutDoc = (value) => {
		setAboutDoc(value);
	};
	const StateDatachange = (value) => {
		getAllCountryData(value, 'city', Countrychange.split('-')[0]);
		setStateChanged(value);
	};
	const onCityDataChange = (value) => {
		setCityId(value);
		getAllCountryData(value, 'location');
	};
	const PracticeStartyearDateOnchange = (value) => {
		setPracticeStartyearDatechange(value);
	};
	const searchDoctorByGo = () => {
		let PostData = {
			searchByEmailId: SearchDoctorgo,
		};
		callingAPI('commonUserFunctions/searchDoctorByEmailId', PostData).then((response) => {
			if (response.data.success === '1') {
				setdoctorsMainSpecialisation(response.data.result.mainSpecialization);
			}
		});
	};
	////Onsave Validations
	var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	var phNum = /^\d{10}$/;
	var NumberValidation = /^\d+$/;
	const OnSave = () => {

		setdisableflag(false)
		var citychangedselect = $('#citychanged option:selected').text();
		var statechangedselect = $('#statechanged option:selected').text();
		var Countrychangeselect = $('#Countrychange option:selected').text();
		var copy = [...disposableitemsaddedarr];
		var specialisationsSelect = [];
		if (document.getElementById("EducationDetails").value != "") {
			// if(document.getElementById("EducationDetails").value != " "){
			// if (flag == true) {

			var obj = {
				educationCategoryId: document.getElementById("EducationDetails").value,
				educationYear: document.getElementById("educationYear").value,
				educationCollege: document.getElementById("EducationDetailsCollageName").value,
			};
			copy.push(obj);
			setDisposableItemsAddedArr(copy);
			setEducationSpecificationID('');
			setyearDatachange('');
			setEducationDetailsCollageName('');
			// }
		}
		for (var s = 0; s < doctorsMainSpecialisation.length; s++) {
			if (document.getElementById('chkInsurance' + doctorsMainSpecialisation[s].spid).checked === true) {
				var a = {
					hidSpeciality: doctorsMainSpecialisation[s].spid.toString(),
					primarySpecial:
						document.getElementById('primarySpecialId' + doctorsMainSpecialisation[s].spid).checked === true
							? 'true'
							: 'false',
				};
				specialisationsSelect.push(a);
			}
		}
		var specialisationsSelect1 = [];
		for (var i = 0; i < specialisationsSelect.length; i++) {
			if (specialisationsSelect[i].primarySpecial === 'true') {
				specialisationsSelect1.push(i);
			}
		}

		// var flag = true;
		// if (!eductionspecificationID) {
		// 	alert('Educational Details Required');
		// 	flag = false;
		// }

		if (!DoctorTitle) {
			setdisableflag(false)
			toast.error('Title Required');
			window.document.getElementById('Title').focus();
			window.scrollTo(0, 100)
		} else if (!DoctorFirstName) {
			setdisableflag(false)
			toast.error('First Name Required');
			document.getElementById('DoctorFirstName').focus();
			window.scroll(0, 0)
		} else if (Selectgender == '') {
			setdisableflag(false)
			window.scroll(0, 200)
			toast.error('Select Gender');
		} else if (!DoctorEmail) {
			setdisableflag(false)
			toast.error('Add Doctor Email');
			window.scroll(0, 800)
		} else if (!email.test(DoctorEmail)) {
			setdisableflag(false)
			toast.error('Add a Valid Doctor Email');
			window.document.getElementById('usernameId').focus();
			window.scroll(0, 800)
		} else if (!DoctorAdress) {
			setdisableflag(false)
			toast.error('Address Required');
			window.document.getElementById('DoctorAdress').focus();
			window.scroll(0, 800)
		} else if (Countrychangeselect == "Select") {
			toast.error('Select Country');
			window.document.getElementById('Countrychange').focus();
			window.scroll(0, 800)
		} else if (statechangedselect == "Select") {
			setdisableflag(false)
			toast.error('Select state');
			window.document.getElementById('statechanged').focus();
			window.scroll(0, 800)
		} else if (citychangedselect == "Select") {
			setdisableflag(false)
			toast.error('Select city');
			window.document.getElementById('cityid').focus();
			window.scroll(0, 800)

		} else if (!DoctorPinCode) {
			setdisableflag(false)
			toast.error('Pin Code Required');
			window.document.getElementById('DoctorPinCode').focus();
			window.scroll(0, 800)
		} else if (!DoctorPinCode.match(NumberValidation)) {
			setdisableflag(false)
			toast.error('Pin Code Must be a Number');
			window.document.getElementById('DoctorPinCode').focus();
			window.scroll(0, 800)
		} else if (!DoctorPhone) {
			setdisableflag(false)
			toast.error('Phone Number Required');
			window.document.getElementById('PhoneNumber').focus();
			window.scroll(0, 800)
		} else if (!DoctorPhone.match(phNum)) {
			setdisableflag(false)
			toast.error('Enter a Valid Mobile Number');
			window.document.getElementById('PhoneNumber').focus();
			window.scroll(0, 800)
		} else if (!DoctorMobile) {
			setdisableflag(false)
			toast.error('Mobile Number Required');
			window.document.getElementById('MobileNumber').focus();
			window.scroll(0, 800)
		} else if (!DoctorMobile.match(phNum)) {
			setdisableflag(false)
			toast.error('Mobile Number must be 10 digits');
			window.document.getElementById('MobileNumber').focus();
			window.scroll(0, 800)
		} else if (LaguageChangedId.length == '') {
			setdisableflag(false)
			toast.error('Select Language');
			window.document.getElementById('LaguageChangedId').focus();
			window.scroll(0, 1000)
		} else if (WorkTimeFilter.length == '') {
			setdisableflag(false)
			toast.error('Work Time Required ');
			window.document.getElementById('WorkTimeFilter').focus();
			window.scroll(0, 1000)
		}
		else if (!Medicinebranchclickselect) {
			setdisableflag(false)
			toast.error('Select medicine Branch');
			window.document.getElementById('medicineBranch').focus();
			window.scroll(0, 1600)
		} else if (specialisationsSelect.length == 0) {
			setdisableflag(false)
			toast.error('Choose a Specialisation Field');
		} else if (specialisationsSelect1.length == 0) {
			setdisableflag(false)
			window.scroll(0, 1400)
			toast.error('Choose Atleast One  Primary Specialization Field');
		} else if (specialisationsSelect1.length > 1) {
			setdisableflag(false)
			window.scroll(0, 1400)
			toast.error('Choose  One  Primary Specialization Field');
		} else if (!DoctorAbout) {
			setdisableflag(false)
			toast.error('About Doctor Required');
			window.document.getElementById('DoctorAbout').focus();
			window.scroll(0, 1600)
		} else if (!copy.length) {
			setdisableflag(false)
			toast.error('Educational Details Required');
			document.getElementById('EducationDetails').focus();
			window.scroll(0, 1300)
		}
		else {
			setdisableflag(true)
			var FinaleFormat = [];
			for (var i = 0; i < DoctorSchDetails.length; i++) {
				var ConvertionToSaveFormat = {
					IsMonday: DoctorSchDetails[i].IsMonday,
					IsTuesday: DoctorSchDetails[i].IsTuesday,
					IsWednesday: DoctorSchDetails[i].IsWednesday,
					IsThursday: DoctorSchDetails[i].IsThursday,
					IsFriday: DoctorSchDetails[i].IsFriday,
					IsSaturday: DoctorSchDetails[i].IsSaturday,
					IsSunday: DoctorSchDetails[i].IsSunday,
					schedule1From1: DoctorSchDetails[i].schedule1From1,
					schedule1To1: DoctorSchDetails[i].schedule1To1,
					schedule2From2: DoctorSchDetails[i].schedule2From2,
					schedule2To2: DoctorSchDetails[i].schedule2To2,
				};
				FinaleFormat.push(ConvertionToSaveFormat);
			}
			console.log("location.state doctorList", location.state)
			if (Object.keys(location.state === null ? [] : location.state).length === 0) {
				let PostData = {
					doctorFirstName: DoctorFirstName,
					doctorPhone: DoctorPhone,
					secUserMobileNumber: DoctorMobile,
					doctorlastName: DoctorLastName,
					displayName: DoctorFirstName + " " + DoctorLastName,
					doctorTitle: DoctorTitle,
					secUserTitle: DoctorMrs,
					doctorCity: CityIdOnselect.split('-')[1],
					doctorState: statechanged.split('-')[1],
					doctorCountry: Countrychange.split('-')[1],
					doctorEmail: DoctorEmail,
					doctorSearchKeyWords: SearchDoctorgo,
					username: DoctorEmail,
					doctorAddress: DoctorAdress,
					doctorGender: Selectgender,
					doctorMedicalRegNumber: DoctorMedicalRegisterNo,
					isOwner: ThisDoctorisOwner,
					doctorKeyWords: DoctorAbout,
					doctorPracticeStartYear: PracticeStartyearDatechange,
					doctorZip: DoctorPinCode,
					doctorLocation: locationId,
					// education: disposableitemsaddedarr,
					education: copy,
					hospitalId: localStorage.getItem("hospitalId"),
					doctorMedicineBranch: Medicinebranchclickselect,
					specialization: specialisationsSelect,
					workTimeFilter: WorkTimeFilter.toString(),
					language: LaguageChangedId.toString(),
					doctorImage: imageName,
					doctorPhoto: imageName,
					dayList: FinaleFormat,
				};
				setDataFlag(false);
				callingAPI('commonUserFunctions/saveDoctor', PostData).then((response) => {
					if (response.data.success === '1') {
						imageS3Save();
						const PostData = {
							functionName: "createAppointmentMail",
							jsonMailOptions: {
								to: response.data.result.jsonMailOptions.to,
								AdminEmailId: response.data.result.jsonMailOptions.AdminEmailId,
								SupportEmailId: response.data.result.jsonMailOptions.SupportEmailId,
								subject: response.data.result.jsonMailOptions.subject,
								html: response.data.result.jsonMailOptions.html,
								bccEmailRequired: "",
								ccEmailRequired: ""
							},
						};
						callingAPI("commonUserFunctions/createAppointmentMail", PostData).then((response) => {
							if (response.data.success === "0") {
								toast.error("Error");
							}
							else if (response.data.success === "1") {
								toast.success("Email Send Successfully")
							}
						})
							.catch();
						window.location.href = '/Doctor/doctorsList';
						setDataFlag(true);
						setflagValue(true)
						toast.success(' Doctor Details Saved Successfully');
					} else if (response.data.success === '0') {
						toast.error(response.data.errorMessage);
						setdisableflag(false)
						window.scroll(0, 900)
						setDataFlag(true);
						setflagValue(false)
					}
				});
			} else if (location.state && Object.keys(location.state).length !== 0) {
				let PostData = {
					userId: window.sessionStorage.getItem('userId'),
					doctorId: location.state.doctorEditId.toString(),
					doctorFirstName: DoctorFirstName,
					doctorPhone: DoctorPhone,
					secUserMobileNumber: DoctorMobile,
					doctorlastName: DoctorLastName,
					displayName: DoctorFirstName + " " + DoctorLastName,
					doctorTitle: DoctorTitle,
					secUserTitle: DoctorMrs,
					doctorCity: CityIdOnselect.split('-')[1],
					doctorState: statechanged.split('-')[1],
					doctorCountry: Countrychange.split('-')[1],
					doctorEmail: DoctorEmail,
					//"doctorSearchKeyWords": SearchDoctorgo,
					username: DoctorEmail,
					doctorAddress: DoctorAdress,
					doctorGender: Selectgender,
					doctorMedicalRegNumber: DoctorMedicalRegisterNo,
					isOwner: ThisDoctorisOwner,
					doctorKeyWords: DoctorAbout,
					doctorPracticeStartYear: PracticeStartyearDatechange,
					doctorZip: DoctorPinCode,
					doctorLocation: locationId,
					education: copy,
					hospitalId: localStorage.getItem("hospitalId"),
					doctorMedicineBranch: Medicinebranchclickselect,
					specialization: specialisationsSelect,
					workTimeFilter: WorkTimeFilter.toString(),
					language: LaguageChangedId.toString(),
					doctorImage: imageName,
					doctorPhoto: imageName,
					dayList: FinaleFormat,
				};
				setDataFlag(false);
				callingAPI('commonUserFunctions/updateDoctor', PostData).then((response) => {
					if (response.data.success === '1') {
						window.location.href = '/Doctor/doctorsList';
						setDataFlag(true);
						setflagValue(true)
						if (file !== '' || imageName !== '') {
							imageS3Save();
						}
						toast.success('Doctor Details Updated Successfully');
						window.location.href = '/Doctor/doctorsList';
					}
					else {
						toast.error(response.data.errorMessage);
						setDataFlag(true);
						setflagValue(false)
						window.scroll(0, 900)
					}
				});
			}
		}
	};

	///////DropDownCall
	const hospitalListing = () => {
		// return new Promise((resolve, reject) => {
		let postData = {
			"functionName": "getHospitalDetailsById",
			"hospitalId": localStorage.getItem("hospitalId")
		}
		callingAPI('calenderListing/getHospitalDetailsById', postData).then((response) => {
			if (response.data.success === '1') {

				getAllCountryData(response.data.result[0].countryId, 'state');
				getAllCountryData(response.data.result[0].stateId, 'city', response.data.result[0].countryId);
				setStateChanged(response.data.result[0].stateId + '-' + response.data.result[0].stateName)
				setStateCountrychange(response.data.result[0].countryId + '-' + response.data.result[0].countryName)
				setCityId(response.data.result[0].cityId + '-' + response.data.result[0].city)
				setDoctorAddress(response.data.result[0].address)
				setDoctorPhone(response.data.result[0].phone)
				setDoctorPinCode(response.data.result[0].zip)
				// setDoctorEmail(response.data.result[0].emailId)
				// resolve(true)
			}
		});
		// })
	}
	const doctorform = () => {
		if (location.state && Object.keys(location.state).length !== 0) {
			const doctorEditId = location.state.doctorEditId.toString();
			if (location.state && Object.keys(location.state).length !== 0) {
			}
			const postdata = {
				userId: window.sessionStorage.getItem('userId'),
				hospitalId: localStorage.getItem("hospitalId"),
				role: window.sessionStorage.getItem('role'),
				doctorId: doctorEditId,
			};
			setDataFlag(false);
			callingAPI('commonUserFunctions/pm_doctorsForm', postdata).then((response) => {
				if (response.data.success === '1') {
					setDataFlag(true);
					window.document.getElementById('DoctorFirstName').focus();
					getAllCountryData(response.data.hosDocLink[0].countryId, 'state');
					getAllCountryData(response.data.hosDocLink[0].stateId, 'city', response.data.hosDocLink[0].countryId);
				}
				else if (response.data.success !== '1') {
					toast.error(response.data.errorMessage);
					window.location.href = '/Doctor/doctorsList';
					setDataFlag(true)
				};
				/////////////////////////////////////////////////////////
				medicinebranchclick(
					response.data.hosDocLink[0].doctorMedicineBranchId,
					response.data.doctorSpecialization
				);
				setThisDoctorisOwner(response.data.isOwner);
				setDoctorMrs(response.data.secName[0].secUserTitle);
				setLaguageChangeIds(response.data.doctorLanguages.toString().split(','));
				setWorkTimeFilter(response.data.timefilterName.toString());
				setDoctorFirstName(response.data.hosDocLink[0].doctorFirstName);
				setDoctorLastName(response.data.hosDocLink[0].doctorLastName);
				setDoctorDisplayName(response.data.secName[0].displayName);
				setDoctorMobile(response.data.secName[0].secUserMobileNumber);
				setgender(response.data.hosDocLink[0].doctorGender);
				setDoctorAddress(response.data.hosDocLink[0].doctorAddress);
				setMedicalRegisterNo(response.data.hosDocLink[0].doctorMedicalRegNumber);
				setStateCountrychange(response.data.hosDocLink[0].countryId + '-' + response.data.hosDocLink[0].doctorCountry);
				setlocationId(response.data.hosDocLink[0].doctorSubCity);
				setStateChanged(response.data.hosDocLink[0].stateId + '-' + response.data.hosDocLink[0].doctorState);
				setCityId(response.data.hosDocLink[0].cityId + '-' + response.data.hosDocLink[0].doctorCity);
				setDisposableItemsAddedArr(response.data.education);
				setDoctorPinCode(response.data.hosDocLink[0].doctorZip);
				setDoctorPhone(response.data.hosDocLink[0].phone);
				if (response.data.doctorImage.length > 0) { setImageName(response.data.doctorImage[0].image_name) }
				setPracticeStartyearDatechange(response.data.hosDocLink[0].practiceStartYear);
				setAboutDoc(response.data.hosDocLink[0].aboutDoctor);
				setDoctorEmail(response.data.secName[0].emailId);
				setDoctorTitle(response.data.hosDocLink[0].title);
				sethospitalId(response.data.hosDocLink[0].hospitalId);
				setDoctorSchDetails(response.data.dayList);
			});
		} else {
			window.document.getElementById('DoctorFirstName').focus();
			getAllCountryData('', 'country');
		}
	}
	const hospitalListingvalue = () => {
		//  hospitalListing()
		let postData = {
			"functionName": "getHospitalDetailsById",
			"hospitalId": localStorage.getItem("hospitalId")
		}
		callingAPI('calenderListing/getHospitalDetailsById', postData).then((response) => {
			if (response.data.success === '1') {

				getAllCountryData(response.data.result[0].countryId, 'state');
				getAllCountryData(response.data.result[0].stateId, 'city', response.data.result[0].countryId);
				setStateChanged(response.data.result[0].stateId + '-' + response.data.result[0].stateName)
				setStateCountrychange(response.data.result[0].countryId + '-' + response.data.result[0].countryName)
				setCityId(response.data.result[0].cityId + '-' + response.data.result[0].city)
				setDoctorAddress(response.data.result[0].address)
				setDoctorPhone(response.data.result[0].phone)
				setDoctorPinCode(response.data.result[0].zip)

				let PostDatas = {
					country: Countrychange === "" ? response.data.result[0].countryId : Countrychange,
					state: statechanged === "" ? response.data.result[0].stateId : statechanged,
					city: CityIdOnselect === "" ? response.data.result[0].cityId : CityIdOnselect,
				};

				callingAPI('doctorProfile/getCountryBased', PostDatas).then((response) => {
					if (response.data.success === '1') {
						setCountryData(response.data.result.country);
						setStateData(response.data.result.state);
						setcitychange(response.data.result.city);
						setlocation(response.data.result.location);
					}
				});
				// setDoctorEmail(response.data.result[0].emailId)
				// resolve(true)
			}
		});

		//////////////////////////////
		callingAPI('doctorProfile/getLanguages').then((res) => {
			setlanguageData(res.data.result.language);
		});
		let PostData = {
			medicineBranchId: '',
		};
		callingAPI('commonUserFunctions/specialization', PostData).then((response) => {
			if (response.data.success === '1') {
				setSpecialisation(response.data.result.specialization);
			}
		});
		callingAPI('doctorProfile/getEducationCategory', PostData).then((response) => {
			if (response.data.success === '1') {
				seteducation(response.data.result.education);
			}
		});
		var startDate = document.getElementById("startDate");
		var currentYear = (new Date()).getFullYear();
		var option1 = document.createElement("OPTION");
		option1.innerHTML = "Select";
		option1.value = "0";
		if (startDate !== null && startDate !== "null") {
			startDate.appendChild(option1);
			for (var i = currentYear; i >= 1950; i--) {
				var option = document.createElement("OPTION");
				option.innerHTML = i;
				option.value = i;
				startDate.appendChild(option);
			}
		}
		yearDropdown("")
	}
	useEffect(() => {
		setStateChanged('');
		setCityId('');
		doctorform()
		hospitalListingvalue()
	}, [props])
	const yearDropdown = (index) => {
		var ddlYears = document.getElementById("educationYear" + index)
		var currentYear = (new Date()).getFullYear();
		var option1 = document.createElement("OPTION");
		option1.innerHTML = "Select";
		option1.value = "0";
		if (ddlYears !== null && ddlYears !== "null") {
			ddlYears.appendChild(option1);
			for (var i = currentYear; i >= 1950; i--) {
				var option = document.createElement("OPTION");
				option.innerHTML = i;
				option.value = i;
				ddlYears.appendChild(option);
			}
		}
	}
	const special1 = (data) => {
		for (var s = 0; s < data.length; s++) {
			if (document.getElementById('chkInsurance' + data[s].specializationId) !== null) {
				document.getElementById('chkInsurance' + data[s].specializationId).checked = true;
				if (data[s].isPrimarySpecialization === 'true') {
					document.getElementById('primarySpecialId' + data[s].specializationId).checked = true;
				}
			}
		}
	};
	const StateCountrychange = (value) => {
		var e = document.getElementById("Countrychange");
		var strUser = e.value;
		getAllCountryData(value.split('-')[0], 'state');
		setStateCountrychange(value);
	};
	return (
		<div className="coverWraper">
			<ToastContainer position="bottom-right" />
			<Header />
			<div className="contentWraper contentWraperScroll pl_staffList pl_drAdd drListAdd">
				<div class="pulseContentCoverWraper">
					<div class="container">
						<div class="content4">
							{location.state && Object.keys(location.state).length !== 0 && DataFlag == false && flagValue == false ? (
								<div align="center">
									<Loader />
								</div>
							) : (
								<div class="container">
									{/* <h3 style={{ marginTop: '40px', fontFamily: 'OpenSans-Bold', fontSize: '22px' }}>
										Doctor{' '}
									</h3> */}
									<div style={{ marginTop: '10px' }} class="serviceInfoWrap">
										{' '}
										{/* <h4
											style={{
												paddingBottom: '10px',
												paddingLeft: '8px',
												fontSize: '16px',
											}}
										>
											Search Doctor(s)
											<br />
										</h4> */}
										{/* <div class="serviceListMain" id="findDoctorHid">
											<label for="" class="smalLbl">
												Enter Email id{' '}
											</label>
											<input
												placeholder="Enter Email id"
												class="smyLargstField"
												onChange={(e) => setSearchDoctor(e.target.value)}
												type="text"
												id=" Email"
												// value={SearchDoctorgo}
												autofocus="autofocus"
											/>
										</div> */}
										{/* <div class="serviceListMain">
											<label for="" class="smalLbl" />
											<input
												type="submit"
												name="_action_goAndFindDoctor"
												onClick={searchDoctorByGo}
												value="Go"
												id="DoctorEmail"
												class="practiceGreenButton"
												style={{ float: 'left', height: '40px', right: '0px' }}
											/>
										</div> */}
										<h4 style={{ paddingBottom: '10px', paddingLeft: '8px', fontSize: '16px' }}>
											Doctor Information
											<br />
										</h4>
										<div class="serviceListMain" style={{ width: "10%", float: "left" }}>
											<label for="" class="smalLbl">
												{' '}
												Title<span class="mandatory">*</span>
											</label>
											{/* <input
												placeholder="Title"
												id="Title"
												class="smyLargstField"
												type="text"
												value={DoctorTitle}
												onChange={(e) => Title(e.target.value)}
												autofocus="autofocus"
											/> */}
											<select placeholder="Title"
												id="Title"
												class="smyLargstField"
												type="text"
												value={DoctorTitle}
												// onChange={setDoctorTitle}
												onChange={(e) => Title(e.target.value)}
												autofocus="autofocus">
												<option value="Dr">Dr</option>
												<option value="Mr">Mr</option>
												<option value="Mrs">Mrs</option>
											</select>
										</div>
										{/* <div class="serviceListMain">
											<label for="" class="smalLbl">
												Ms./Mr./Mrs
											</label>
											<input
												placeholder="Dr."
												class="smyLargstField"
												type="text"
												value={DoctorMrs}
												onChange={(e) => Mrs(e.target.value)}
												autofocus="autofocus"
											/>
										</div> */}
										<div class="serviceListMain" style={{ width: "40%" }}>
											<label for="" class="smalLbl">
												First Name<span class="mandatory">*</span>
											</label>
											<input
												placeholder="First Name"
												id="DoctorFirstName"
												class="smyLargstField"
												type="text"
												onChange={(e) => FirstName(e.target.value)}
												value={DoctorFirstName}
												autofocus="autofocus"
											/>
										</div>
										<div class="serviceListMain">
											<label for="" class="smalLbl">
												Last Name{' '}
											</label>
											<input
												placeholder="Last Name"
												class="smyLargstField"
												type="text"
												value={DoctorLastName}
												onChange={(e) => LastName(e.target.value)}
											/>
										</div>
										{/* <div class="serviceListMain">
											<label for="" class="smalLbl">
												Display Name<span > </span>
											</label>
											<input
												// disabled
												placeholder="Display Name"
												id="DoctorDisplayName"
												class="smyLargstField"
												type="text"
												onChange={(e) => DisplayName(e.target.value)}
												autofocus="autofocus"
												value={DoctorDisplayName}
											/>
										</div> */}
										<div class="serviceListMain">
											<label for="" class="smalLbl" value="0">
												Sex<span class="mandatory">*</span>
											</label>
											<input
												type="radio"
												name="gender"
												id="genderId"
												value="Male"
												checked={Selectgender === 'Male'}
												onClick={(e) => setgender(e.target.value)}
											/>
											<span>&nbsp;Male&nbsp;</span>
											<input
												type="radio"
												name="gender"
												id="genderIds"
												value="Female"
												checked={Selectgender === 'Female'}
												onClick={(e) => setgender(e.target.value)}
											/>
											<span>&nbsp;Female&nbsp;</span>
										</div>
										<div class="serviceListMain">
											<label for="" class="smalLbl"></label>
											<label for="" class="smalLbl" style={{ width: 'auto' }}>
												<span style={{ margin: "-3px 5px 0 0px", float: "left", fontWeight: "inherit" }}>This Doctor is Owner</span><span class="mandatory" />
											</label>
											<input
												checked={ThisDoctorisOwner}
												type="checkbox"
												name="isOwner"
												id="isOwner"
												onChange={onChangeOwner}
												style={{ marginTop: '4px' }}
											/>
										</div>
										<h4 style={{ paddingBottom: '10px', paddingLeft: '8px', fontSize: '16px', width: '100%', float: 'left' }}>
											Official Information
											<br />
										</h4>
										<div class="serviceListMain">
											<label for="" class="smalLbl">
												Practice Start Year
											</label>
											<select id="startDate" class="form-control " value={PracticeStartyearDatechange}
												onChange={(e) => PracticeStartyearDateOnchange(e.target.value)} style={{ width: "100%" }}></select>
											{/* <select
												value={PracticeStartyearDatechange}
												onChange={(e) => PracticeStartyearDateOnchange(e.target.value)}>  */}
											{/* <option value="0">Year</option>
												<option value="2015">2015</option>
												<option value="2016">2016</option>
												<option value="2017">2017</option>
												<option value="2018">2018</option>
												<option value="2019">2019</option>
												<option value="2020">2020</option>
												<option value="2021">2021</option>
												<option value="2022">2022</option>  */}
											{/* {EducationyearDatafirst.map((yearDatalist, i) => (
													<option value={yearDatalist.educationYear}>
														{yearDatalist.educationYear}
													</option>
												))}  */}
										</div>
										<div class="serviceListMain">
											<label for="Image" class="smalLbl">
												Image(jpeg,png)
											</label>
											<div
												class="fileUpload_upload btn_upload btn-primary"
												style={{ float: 'left' }}
											>
												<span>Upload</span>
												<input
													type="file"
													id="file1"
													accept="image/png, image/jpeg"
													name="file1"
													onChange={(e) => handleImageUploadOnChange(e)}
													class="upload_upload"
												/>
											</div>
											{imageName !== null && (
												<div class="bannerTumb mrg_top" id="divThumb" title="">
													<img
														id="imageThumbdemo"
														src={imagePreview ? imagePreview : s3Path + '/' + imageName}
														style={{
															width: '35px',
															height: '35px',
															float: 'left',
															marginLeft: '20px',
															objectFit: 'contain',
														}}
													/>
												</div>
											)}
										</div>
										{/*  */}
										<br />
										<div class="spotlightArea spotlightAreaMain">
											<div class="storeLocationHldr">
												<div class="dividBox">
													<label for="" class="smalLbl" style={{ paddingLeft: '10px' }}>
														Clinic Name&nbsp;
														<span class="mandatory">*</span>&nbsp;&nbsp;
													</label>
													<input
														id="ClinicName"
														type="text"
														class="smyLargstField clinicinpt"
														value={ClickName}
														readonly=""
													/>
												</div>
												<br />
												<h4
													className="officeHours"
													style={{
														paddingBottom: '10px',
														paddingLeft: '8px',
														fontSize: '16px',
														textAlign: 'center',
													}}
												>
													Office Hours
												</h4>
												<br />
												{DoctorSchDetails.map((DoctorSchDetailsCheckBox, idx) => {
													return (
														<div
															id="divSubLevelSchedule"
															class="subLevelMAin"
															style={{ display: 'block' }}
														>
															<div
																id="innerSubLevelSectionSchedule1"
																class="divMultipleBlockHold fixNwBlck"
															>
																<div class="nextLevelBlock" style={{ marginTop: '0' }}>
																	<div class="leftSplitDate">
																		<input
																			type="checkbox"
																			name={'IsMonday'}
																			id={'IsMonday'}
																			maxlength="7"
																			checked={DoctorSchDetailsCheckBox.IsMonday}
																			onChange={(e) => NewChangeAdd(e, idx)}
																		/>{' '}
																		<label for="" class="vrySmalLbl3 blue">
																			Monday
																		</label>
																	</div>
																	<div class="leftSplitDate">
																		<input
																			type="checkbox"
																			name={'IsTuesday'}
																			id={'IsTuesday'}
																			onChange={(e) => NewChangeAdd(e, idx)}
																			checked={DoctorSchDetailsCheckBox.IsTuesday}
																		/>{' '}
																		<label for="" class="vrySmalLbl3 blue">
																			Tuesday
																		</label>
																	</div>
																	<div class="leftSplitDate">
																		<input
																			type="checkbox"
																			name="IsWednesday"
																			id="IsWednesday"
																			onChange={(e) => NewChangeAdd(e, idx)}
																			checked={
																				DoctorSchDetailsCheckBox.IsWednesday
																			}
																		/>{' '}
																		<label for="" class="vrySmalLbl3 blue">
																			Wednesday
																		</label>
																	</div>
																	<div class="leftSplitDate">
																		<input
																			type="checkbox"
																			name="IsThursday"
																			id="IsThursday"
																			onChange={(e) => NewChangeAdd(e, idx)}
																			checked={
																				DoctorSchDetailsCheckBox.IsThursday
																			}
																		/>{' '}
																		<label for="" class="vrySmalLbl3 blue">
																			Thursday
																		</label>
																	</div>
																	<div class="leftSplitDate">
																		<input
																			type="checkbox"
																			name="IsFriday"
																			id="IsFriday"
																			onChange={(e) => NewChangeAdd(e, idx)}
																			checked={DoctorSchDetailsCheckBox.IsFriday}
																		/>{' '}
																		<label for="" class="vrySmalLbl3 blue">
																			Friday
																		</label>
																	</div>
																	<div class="leftSplitDate">
																		<input
																			type="checkbox"
																			name="IsSaturday"
																			id="IsSaturday"
																			onChange={(e) => NewChangeAdd(e, idx)}
																			checked={
																				DoctorSchDetailsCheckBox.IsSaturday
																			}
																		/>{' '}
																		<label for="" class="vrySmalLbl3 blue">
																			Saturday
																		</label>
																	</div>
																	<div class="leftSplitDate">
																		<input
																			type="checkbox"
																			name="IsSunday"
																			id="IsSunday"
																			onChange={(e) => NewChangeAdd(e, idx)}
																			checked={DoctorSchDetailsCheckBox.IsSunday}
																		/>{' '}
																		<label for="" class="vrySmalLbl3 blue">
																			Sunday
																		</label>
																	</div>
																	<div class="leftSplitDate">
																		<label for="" class="vrySmalLbl3 blue">
																			{' '}
																		</label>
																	</div>
																	<div>
																		{idx == 0 ? (
																			<div class="right">
																				<label for="" class="vrySmalLbl3 green">
																					Add More
																				</label>&nbsp;
																				<img
																					onClick={AddmoreButtone}
																					title="Add More"
																					style={{ cursor: 'pointer' }}
																					src={Addmore}
																				/>{' '}
																			</div>
																		) : (
																			<div class="right" id="removeBut2">
																				<label for="" class="vrySmalLbl3 green">
																					Remove
																				</label>&nbsp;&nbsp;&nbsp;
																				<img
																					onClick={(e) =>
																						RemoveButton(e, idx)}
																					title="Remove"
																					style={{ cursor: 'pointer' }}
																					src={close}
																				/>{' '}
																			</div>
																		)}
																	</div>
																</div>
																<div class="leftSplit scheduleMainWrapper">
																	<div class="nextLevelBlock">
																		<label for="" class="vrySmalLbl3 Silver">
																			Schedule1{' '}
																		</label>
																	</div>
																	<div class="nextLevelBlock">
																		<DoctorTimeScedules
																			name="schedule1From1"
																			selectedScheduleFrom={
																				DoctorSchDetailsCheckBox.schedule1From1
																			}
																			onChange={(e) => NewChangeAdd(e, idx)}
																			selctedSheduledDateFrom={NewChangeAdd}
																			index={idx}
																			id="schedule1From1"
																			class="smlmdmField mrg_rgt"
																			tabindex="From"
																			style={{ width: '80px' }}
																		/>
																	</div>
																	<div class="nextLevelBlock">
																		<label for="" class="vrySmalLbl3 Silver">
																			TO{' '}
																		</label>
																	</div>
																	<div class="nextLevelBlock">
																		<DoctorTimeScedules
																			name="schedule1To1"
																			selectedScheduleFrom={
																				DoctorSchDetailsCheckBox.schedule1To1
																			}
																			onChange={(e) => NewChangeAdd(e, idx)}
																			selctedSheduledDateFrom={NewChangeAdd}
																			id={'schedule1To1'}
																			class="smlmdmField mrg_rgt"
																			tabindex=""
																			index={idx}
																			style={{ width: '80px' }}
																		/>
																	</div>
																	<div class="nextLevelBlock">
																		<label for="" class="vrySmalLbl3 Silver">
																			Schedule2{' '}
																		</label>
																	</div>
																	<div class="nextLevelBlock">
																		<DoctorTimeScedules
																			name="schedule2From2"
																			selectedScheduleFrom={
																				DoctorSchDetailsCheckBox.schedule2From2
																			}
																			selctedSheduledDateFrom={NewChangeAdd}
																			onChange={(e) => NewChangeAdd(e, idx)}
																			id="schedule2From2"
																			class="smlmdmField mrg_rgt"
																			tabindex=""
																			index={idx}
																			style={{ width: '80px' }}
																		/>
																	</div>
																	<div class="nextLevelBlock">
																		<label for="" class="vrySmalLbl3 Silver">
																			TO{' '}
																		</label>
																	</div>
																	<div class="nextLevelBlock">
																		<DoctorTimeScedules
																			name="schedule2To2"
																			selectedScheduleFrom={
																				DoctorSchDetailsCheckBox.schedule2To2
																			}
																			onChange={(e) => NewChangeAdd(e, idx)}
																			selctedSheduledDateFrom={NewChangeAdd}
																			id="schedule2To2"
																			class="smlmdmField mrg_rgt"
																			tabindex=""
																			index={idx}
																			style={{ width: '80px' }}
																		/>
																	</div>
																</div>
															</div>
														</div>
													);
												})}
												<div id="divSubLevelDefaultSchedule" />
												<div class="serviceListMain">
													<label for="" class="smalLbl">
														Email<span class="mandatory">*</span>
													</label>
													<input
														value={DoctorEmail}
														name="username"
														placeholder="Email"
														id="usernameId"
														class="smyLargstField"
														type="text"
														onChange={(e) => Email(e.target.value)}
													/>
												</div>
												<div class="serviceListMain" id="DoctorAdressss">
													<label for="" class="smalLbl">
														Address<span class="mandatory">*</span>
													</label>
													<input
														name="address"
														placeholder="Address"
														id="DoctorAdress"
														maxlength="255"
														class="smyLargstField"
														type="text"
														onChange={(e) => DoctorAddress(e.target.value)}
														value={DoctorAdress}
													/>
												</div>
												<div class="serviceListMain" >
													<label for="" class="smalLbl">
														Country<span class="mandatory">*</span>
													</label>
													<select
														id="Countrychange"
														class="smyLargstField"
														name="country"
														tabindex="24"
														onChange={(e) => StateCountrychange(e.target.value)}
														value={Countrychange}
													>
														<option value="0">Select</option>
														{Country.map((Countrylist, i) => (
															<option
																value={Countrylist.countryId + '-' + Countrylist.countryName}
															// defaultValue={Countrylist.countryId}
															>
																{Countrylist.countryName}
															</option>
														))}
													</select>
												</div>
												<div class="serviceListMain">
													<label for="" class="smalLbl">
														State<span class="mandatory">*</span>
													</label>
													<select
														id="statechanged"
														class="smyLargstField"
														name="state"
														onChange={(e) => StateDatachange(e.target.value)}
														value={statechanged}
													>
														<option value="0">Select</option>
														{state.map((staterlist, i) => (
															// <option value={staterlist.stateId+ '-' +staterlist.stateName}>
															<option value={staterlist.stateId + '-' + staterlist.stateName}>
																{staterlist.stateName}
															</option>
														))}
													</select>
												</div>
												<div class="serviceListMain" id="cityid">
													<label for="" class="smalLbl">
														City<span class="mandatory">*</span>
													</label>
													<select
														id="citychanged"
														value={CityIdOnselect}
														onChange={(e) => onCityDataChange(e.target.value)}
													>
														<option value="0">Select</option>
														{city.map((citylist, i) => (
															<option value={citylist.cityId + '-' + citylist.cityName}>{citylist.cityName}</option>
														))}
													</select>
												</div>
												<div class="serviceListMain" id="locationIds">
													<label for="" class="smalLbl">
														Location
													</label>
													<select
														value={locationId}
														onChange={(e) => setlocationId(e.target.value)}
													>
														<option value="">Select</option>
														{locations.map((locationlist, i) => (
															<option value={locationlist.locationId}>
																{locationlist.locationName}
															</option>
														))}
													</select>
												</div>
												<div class="serviceListMain">
													<label for="" class="smalLbl">
														Pin Code<span class="mandatory">*</span>
													</label>
													<input
														autoFocus="autofocus"
														name="zip"
														placeholder="Pin Code"
														id="DoctorPinCode"
														maxlength="20"
														class="smyLargstField"
														type="text"
														value={DoctorPinCode}
														onChange={(e) => PinCode(e.target.value)}
													/>
												</div>
												<div class="serviceListMain">
													<label for="" class="smalLbl">
														Phone<span class="mandatory">*</span>
													</label>
													<input
														name="phone"
														placeholder="Phone"
														id="PhoneNumber"
														maxlength="10"
														class="smyLargstField"
														type="text"
														value={DoctorPhone}
														onChange={(e) => Phone(e.target.value)}
													/>
												</div>
												<div class="serviceListMain">
													<label for="" class="smalLbl">
														Mobile<span class="mandatory">*</span>
													</label>
													<input
														name="mobileNumber"
														placeholder="Mobile"
														id="MobileNumber"
														maxlength="10"
														class="smyLargstField"
														type="text"
														value={DoctorMobile}
														onChange={(e) => Mobile(e.target.value)}
													/>
												</div>
												<div class="serviceListMain">
													<label for="" class="smalLbl">
														Medical Register No.
													</label>
													<input
														name="medicalRegNumber"
														placeholder="Medical Register No."
														id="medicalRegNumberId"
														class="smyLargstField"
														type="text"
														value={DoctorMedicalRegisterNo}
														onChange={(e) => MedicalRegisterNo(e.target.value)}
													/>
												</div>
												<div class="serviceListMain">
													<label for="First Name" class="smalLbl">
														Language<span class="mandatory">*</span>
													</label>
													<select
														multiple
														id="LaguageChangedId"
														tabindex="11"
														name="LaguageChangedId"
														class="smyLargstFieldMulti"
														value={LaguageChangedId}
														onChange={(e) => setLanguageData(e)}
													>
														{Language.map((Languagelist) => (
															<option value={Languagelist.languageId}>
																{Languagelist.languageDescription}
															</option>
														))}
													</select>
												</div>
												<div class="serviceListMain">
													<label for="First Name" class="smalLbl">
														Work Time Filter<span class="mandatory">*</span>
													</label>
													<select
														multiple
														id="WorkTimeFilter"
														tabindex="11"
														class="smyLargstFieldMulti"
														name="WorkTimeFilter"
														value={WorkTimeFilter}
														onChange={(e) => WorkFilter(e)}
													>
														<option value="1">Morning</option>
														<option value="2">Afternoon</option>
														<option value="3">After 7PM</option>
														<option value="4">Sunday</option>
														<option value="5">Weekends</option>
													</select>
												</div>
												<div class="clearfix" />
												<div class="dividBox" />
												<div class="dividBox" />
												<div class="clearfix" />
												<div class="dividBox heightAdjust" />
												<div class="clearfix" />
												<div class="dividBox heightAdjust" />
												<div class="clearfix" />
											</div>
											<div class="spotlightAreaMain">
												<h4
													style={{
														paddingBottom: '10px',
														paddingLeft: '8px',
														fontSize: '16px',
													}}
												>
													Education Details
												</h4>
												<br />
												<div
													id="divSubLevelSchedule"
													class="subLevelMAin pl_drAddEdu"
													style={{ display: 'block' }}
												>
													<div
														id="innerSubLevelSectionSchedule1"
														class="divMultipleBlockHold fixNwBlck"
													>
														<div class="leftSplit scheduleMainWrapper">
															<div class="nextLevelBlock">
																<label for="" class="vrySmalLbl3 Silver">
																	Education<span class="red">*</span>
																</label>
															</div>
															<div class="nextLevelBlock">
																<select
																	value={eductionspecificationID}
																	onChange={(e) =>
																		setEducationFirtRow(e.target.value)}
																	name="EducationDetails"
																	id="EducationDetails"
																	class="smlmdmField mrg_rgt"
																	tabindex=""
																>
																	<option value="">Select</option>
																	{EducationDetails.map((EducationDetailslist, i) => (
																		<option
																			value={
																				EducationDetailslist.educationCategoryId
																			}
																		>
																			{
																				EducationDetailslist.educationCategoryDescription
																			}
																		</option>
																	))}
																</select>
															</div>
															<div class="nextLevelBlock">
																<label for="" class="vrySmalLbl3 Silver">
																	Year
																</label>
															</div>
															<div class="nextLevelBlock">
																<select
																	// id="startDate" class="form-control w-25"
																	name="educationYear"
																	id="educationYear"
																	class="smlmdmField mrg_rgt"
																	tabindex=""
																	value={EducationYearChangeOnSelect}
																	onChange={(e) => setyearDatachange(e.target.value)}
																>
																	{/* <option value="0">Year</option>
												<option value="2015">2015</option>
												<option value="2016">2016</option>
												<option value="2017">2017</option>
												<option value="2018">2018</option>
												<option value="2019">2019</option>
												<option value="2020">2020</option>
												<option value="2021">2021</option>
												<option value="2022">2022</option> */}
																	{/* {EducationyearDatafirst.map((yearDatalist, i) => (
																		<option value={yearDatalist.educationYear}>
																			{yearDatalist.educationYear}
																		</option>
																	))} */}
																</select>
															</div>
															<div class="nextLevelBlock">
																<label for="" class="vrySmalLbl3 Silver">
																	College
																</label>
															</div>
															<div class="nextLevelBlock">
																<input
																	name="EducationDetailsCollageName"
																	placeholder="College Name"
																	id="EducationDetailsCollageName"
																	//maxlength="12"
																	class="smlmdmField mrg_rgt inputField plCollege"
																	type="text"
																	value={EducationDetailsCollageName}
																	onChange={(e) =>
																		setEducationDetailsCollageName(e.target.value)}
																/>
															</div>
															<div class="nextLevelBlock">
																<div class="right">
																	<img
																		title="Add More"
																		style={{ cursor: 'pointer' }}
																		src={Addmore}
																		onClick={(e) => addRow(e)}
																	/>{' '}
																</div>
															</div>
														</div>
													</div>
												</div>
												<div
													id="divSubLevelSchedule"
													class="subLevelMAin pl_drAddEdu"
													style={{ display: 'block' }}
												>
													<div
														id="innerSubLevelSectionSchedule1"
														class="divMultipleBlockHold fixNwBlck"
													>
														{disposableitemsaddedarr.map((item, idx) => (
															<div>
																<div class="leftSplit scheduleMainWrapper">
																	<div class="nextLevelBlock">
																		<label for="" class="vrySmalLbl3 Silver">
																			Education<span class="red">&nbsp;</span>
																		</label>
																	</div>
																	<div class="nextLevelBlock">
																		<select
																			name="educationCategoryId"
																			id="educationCategoryId"
																			value={item.educationCategoryId}
																			class="smlmdmField mrg_rgt"
																			onChange={(e) =>
																				categoryTypeNewChange(e, idx)}
																		>
																			<option value="0">Select</option>
																			{EducationDetails.map((item, idxd) => (
																				<option
																					value={item.educationCategoryId}
																				>
																					{item.educationCategoryDescription}
																				</option>
																			))}
																		</select>
																	</div>
																	<div class="nextLevelBlock">
																		<label for="" class="vrySmalLbl3 Silver">
																			Year
																		</label>
																	</div>
																	<div class="nextLevelBlock">
																		<select class="smlmdmField mrg_rgt" name="educationYear"
																			value={item.educationYear} id={"educationYear" + idx} onChange={(e) =>
																				categoryTypeNewChange(e, idx)}
																			onClick={(e) =>
																				yearDropdown(idx)} >
																			<option value="0">Select</option>
																			<option value={item.educationYear}>{item.educationYear}</option>
																			{/* <select
																		// id="startDate" class="form-control w-25"
																			name="educationYear"
																			id="educationYear"
																			class="smlmdmField mrg_rgt"
																			tabindex=""
																			value={item.educationYear}
																			onChange={(e) =>
																				categoryTypeNewChange(e, idx)}
																		> */}
																			{/* <option value="">Select</option>
																			<option value="0">Year</option>
												<option value="2015">2015</option>
												<option value="2016">2016</option>
												<option value="2017">2017</option>
												<option value="2018">2018</option>
												<option value="2019">2019</option>
												<option value="2020">2020</option>
												<option value="2021">2021</option>
												<option value="2022">2022</option> */}
																			{/* {EducationyearDatafirst.map(
																				(yearDatalist, i) => (
																					<option
																						value={
																							yearDatalist.educationYear
																						}
																					>
																						{yearDatalist.educationYear}
																					</option>
																				)
																			)} */}
																		</select>
																	</div>
																	<div class="nextLevelBlock">
																		<label for="" class="vrySmalLbl3 Silver">
																			College
																		</label>
																	</div>
																	<div class="nextLevelBlock">
																		<input
																			onChange={(e) =>
																				categoryTypeNewChange(e, idx)}
																			name="educationCollege"
																			placeholder="College Name"
																			id="educationCollege"
																			//maxlength="12"
																			class="smlmdmField mrg_rgt inputField plCollege"
																			type="text"
																			value={item.educationCollege == "null" ? "" : item.educationCollege}
																		/>
																	</div>
																	<div class="nextLevelBlock">
																		<div class="right">
																			<img
																				title="Remove"
																				style={{ cursor: 'pointer' }}
																				src={close}
																				onClick={(e) => removeRow(e, idx)}
																			/>{' '}
																		</div>
																	</div>
																</div>
															</div>
														))}
													</div>
												</div>
												{/*  */}
											</div>
											{/*  */}
											{/* //////////////////////////////////////////////////////// */}
											<div class="spotlightAreaMain">
												<h4
													style={{
														paddingBottom: '10px',
														paddingLeft: '8px',
														fontSize: '16px',
													}}
												>
													Specialisation
												</h4>
												<br />
											</div>
											<div class="container">
												<div
													class="dividBox"
													onChange={(e) => medicinebranchclick(e.target.value, [])}
												>
													<label for="" class="smalLbl">
														Medicine branch<span class="red">*</span>&nbsp;
													</label>
													<select
														value={Medicinebranchclickselect}
														id="medicineBranch"
														class="smyLargstField plstatus"
														name="medicineBranch"
														style={{ width: '150px' }}
													>
														<input type="checkbox" on />
														<option value="">Select</option>
														{specialisationlist.map((list, item) => (
															<option value={list.id}>{list.name}</option>
														))}
													</select>
												</div>
												<div class="spotlightAreaMain">
													<h2 style={{ fontSize: '14px' }}>
														{' '}
														<span class="red">
															(Choose atleast one Primary Specialisation)
														</span>
													</h2>
													<div class="bigScrolldiv" id="bigScrolldiv2">
														{doctorsMainSpecialisation.map((main, i) => (
															<div class="table-bordered">
																<table class="table table-bordered mainBx">
																	<tbody id="specialDiv">
																		<tr class="subBx">
																			<td class="tdBorder">
																				<span class="spanLeft">
																					<input
																						type="checkbox"
																						name={
																							'chkInsurance' + main.spid
																						}
																						id={'chkInsurance' + main.spid}
																					/>
																					<span class="tdtxt" id="label0">
																						{main.speciality}
																					</span>
																				</span>
																				<span class="spanRight">
																					<input
																						type="checkbox"
																						class="radiobtnindex3 spl0"
																						name="primarySpecial56"
																						id={
																							'primarySpecialId' +
																							main.spid
																						}
																						onChange={(e) =>
																							primarySpecial(
																								main.spid,
																								e.target.checked
																							)}
																					/>
																					<span class="tdtxt" id="primary56">
																						Primary Specialization
																					</span>
																				</span>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														))}
													</div>
												</div>
											</div>
											<div className="container">
												<div class="spotlightAreaMain">
													<label for="" class="smalLbl">
														About Doctor<span class="red">*</span>&nbsp;
													</label>
													<p />
													<p />
													<div class="clearfix" />
													<textarea
														name="professionalStatement"
														rows="20"
														cols="20"
														id="DoctorAbout"
														placeholder="About Doctor"
														class="mgalrgTxtareaNew"
														value={DoctorAbout}
														onChange={(e) => AboutDoc(e.target.value)}
													/>
												</div>
											</div>
											{/*  */}
										</div>
										{/* ===================== */}
										<div className="container">
											{console.log("🚀 ~ OnSave ~ location.state:", location.state)}
											{location.state && Object.keys(location.state).length !== 0 ? (
												<div class="right" style={{ float: 'left', paddingLeft: '10px' }}>
													<a href="/Doctor/doctorsList">
														<input
															type="submit"
															value="Cancel"
															href="/Doctor/doctorsList"
															class="practiceGreyButton"
															title="Cancel"
														/>
													</a>
													<input
														disabled={disableflag}
														type="submit"
														value="Update"
														href="#close"
														class="practiceGreenButton"
														title="Update"
														onClick={OnSave}
													/>
												</div>

											) : (
												<div class="right" style={{ float: 'left', paddingLeft: '18px' }}>
													<a href="/Doctor/doctorsList">
														<input
															type="submit"
															value="Cancel"
															class="practiceGreyButton"
															title="Cancel"
														/>
													</a>
													<input
														type="submit"
														value="Save"
														disabled={disableflag}
														href="#close"
														class="practiceGreenButton"
														title="Save"
														onClick={OnSave}
														style={{ width: '110px', fontWeight: 'normal' }}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							)}

						</div>
					</div>
					<div class="dividerSml" />
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default DoctorListAdd;
