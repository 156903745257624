import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import Editicon from '../images/icons/edit-bg.png';
import Youtube from '../images/icons/youtube.png';
import tick from '../images/icons/tick.png';
import removeicon from '../images/icons/close-sm.png';
import { Link } from 'react-router-dom';
import { callingAPI } from '../config';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import * as XLSX from 'xlsx';
import DownArrow from '../images/icons/downArrow.png';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function TreatmentProcedure() {
	const navigate = useNavigate();
	const [speciality, setSpeciality] = useState([]);
	const [show, setShow] = useState(false);
	const [flagOption, setflagOption] = useState(false);
	const [treatmentproclistdata, setTreatmentProcListData] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchType, setSearchType] = useState(0);
	const [searchStatusType, setSearchStatusType] = useState(0);
	const [isEnabled, setIsEnabled] = useState(true);
	const [pageCount, setPageCount] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [pageOffset, setPageOffset] = useState(0);
	const [SelectSpeciality, setSelectSpeciality] = useState('');
	const [flag, setFlag] = useState(0);
	const [CsvfileData, setFile] = useState('');
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [booliansearchType, setbooliansearchType] = useState("");
	const [booliansearchStatusType, setbooliansearchStatusType] = useState("");

	const paginationOnChange = (pageNumber) => {

		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;

		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;

		}

		setPageOffset(offset)
		setActivePage(pageNumber);
		window.sessionStorage.setItem('activePagevalue', pageNumber)
		window.sessionStorage.setItem("treatmentprosedureoffset", offset);
		if (!booliansearchType) { setSearchType("") }
		if (!booliansearchValue) { setSearchValue("") }
		if (!booliansearchStatusType) { setSearchStatusType("") }

		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			searchKeyword: booliansearchValue,
			selectDropdownSearchType: booliansearchType,
			offset: offset,
			maxlimit: max,
			status: booliansearchStatusType,
			role: window.sessionStorage.getItem('role'),
		};
		callingAPI('treatmentProcedure/treatmentProcedureListing', postdata).then((response) => {

			if (response.data.success === '1') {
				setTreatmentProcListData(response.data.result.treatmentProcedureListData);
				setPageCount(response.data.result.totalCount);

			}
			else {
				setPageOffset(0)
				setTreatmentProcListData([]);
			}
		});
	};
	const setactivepagenumber = (activepagenew, data) => {
		window.sessionStorage.setItem("activepagenew", activepagenew);
		navigate('/settings/addProcedure', { state: data })
	}
	const searchTypeOnChenge = (event) => {
		if (event.target.value == 0) {
			setIsEnabled(true);
			setSearchType(event.target.value);
			setSearchValue('');

		} else {
			setSearchType(event.target.value);
			setIsEnabled(false);
		}

		if (document.getElementById('searchType').value !== '') {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		} else {
			document.getElementById('searchText').disabled = true;
		}
		document.getElementById('searchText').value = '';

	};
	const selectedStatusTypeChange = (id, status, procedureId) => {
		var x = false;
		if (status !== true) {
			x = window.confirm('Do you want to Disable this?');
		} else {
			x = window.confirm('Do you want to Enable this?');
		}
		if (x === true) {
			const postData = {
				pmTreatmentsId: id,
				isActive: status == false ? false : true,
				procedureId: procedureId
			};
			callingAPI('treatmentProcedure/activateTreatmentProcedure', postData).then((res) => {
				if (res.data.success === '1') {
					if (show === false) {
						getTreatmentProcedureListingDataValues();
					}
					else {
						searchClickFunction()
					}
				} else {
					setTreatmentProcListData([]);
					setPageOffset(0);
				}

			});
		}
	};
	const getTreatmentProcedureListingDataValues = () => {
		// setActivePage(Number(window.sessionStorage.getItem('pageNumber')))
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			searchKeyword: searchValue,
			selectDropdownSearchType: searchType,
			// offset: window.sessionStorage.getItem('treatmentprosedureoffset'),
			offset: 0,
			maxlimit: process.env.REACT_APP_PAGINATION_COUNT,
			status: searchStatusType,
			role: window.sessionStorage.getItem('role'),
		};
		callingAPI('treatmentProcedure/treatmentProcedureListing', postdata).then((res) => {
			if (res.data.success === '1') {
				setTreatmentProcListData(res.data.result.treatmentProcedureListData);
				setPageCount(res.data.result.totalCount);
				setFlag(1);
			} else {
				setTreatmentProcListData([]);
				setFlag(1);
			}
		});
	};
	const searchStatusChange = (event) => {
		setSearchStatusType(event.target.value);
	};
	const searchValueFunction = (event) => {
		setSearchValue(event.target.value);
	};
	const searchClickFunction = () => {
		// if (searchType != '' && searchValue == '') {
		// 	var t = document.getElementById('searchType');
		// 	var selectedText = t.options[t.selectedIndex].text;
		// 	alert(selectedText + ' Required');
		// 	document.getElementById('searchText').focus();
		// 	return false;
		// }
		setShow(true)
		if (document.querySelector('#searchType').value !== '0' && document.querySelector('#searchText').value === '') {
			var t = document.getElementById('searchType');
			var selectedText = t.options[t.selectedIndex].text;
			// alert(selectedText + ' Required');
			toast.error("Enter" + " " + selectedText)
			document.querySelector('#searchText').focus();
			return false;
		}
		else if (document.querySelector('#searchType').value === '0') {
			//  alert("Select Value Required");
		}




		setbooliansearchValue(searchValue);
		setbooliansearchType(searchType);
		setbooliansearchStatusType(searchStatusType);
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			searchKeyword: searchValue,
			selectDropdownSearchType: searchType,
			offset: 0,
			maxlimit: process.env.REACT_APP_PAGINATION_COUNT,
			status: searchStatusType,
			role: window.sessionStorage.getItem('role'),
		};
		callingAPI('treatmentProcedure/treatmentProcedureListing', postdata).then((res) => {
			if (res.data.success === '1') {
				// setPageOffset(1);
				setTreatmentProcListData(res.data.result.treatmentProcedureListData);
				setPageCount(res.data.result.totalCount);
				setActivePage(1);
			}
			if (res.data.success !== '1') {
				setTreatmentProcListData([]);
				setPageOffset(1);
				setPageCount(0);
			}
		});
	};
	useEffect(() => {
		selectSpeciality()

		setActivePage(Number(window.sessionStorage.getItem('activePagevalue')))
		getTreatmentProcedureListingDataValues();
		// window.addEventListener("beforeunload", handleBeforeUnload);
		// return () => {
		// 	window.removeEventListener("beforeunload", handleBeforeUnload);
		// };


	}, []);



	const handleBeforeUnload = (e) => {
		e.preventDefault();
		window.sessionStorage.setItem('activePagevalue', 1)
		window.sessionStorage.setItem('treatmentprosedureoffset', 0)
	};

	//upoload
	// e.preventDefault();
	// if (e.target.files) {
	// 	const reader = new FileReader();
	// 	reader.onload = (e) => {
	// 		const data = e.target.result;
	// 		const workbook = xlsx.read(data, { type: 'array' });
	// 		const sheetName = workbook.SheetNames[0];
	// 		const worksheet = workbook.Sheets[sheetName];
	// 		const json = xlsx.utils.sheet_to_json(worksheet);
	// 		setFile(json);
	// 	};
	// 	reader.readAsArrayBuffer(e.target.files[0]);
	// 	alert("CSV file added successfully")
	// }
	const handleexcelUpload = (e) => {
		// setIsData(false);
		e.preventDefault();
		if (e.target.files) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = e.target.result;
				const workbook = XLSX.read(data, { type: 'array' });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json = XLSX.utils.sheet_to_json(worksheet);
				if (typeof workbook.Sheets[sheetName].A1 !== "undefined" && typeof workbook.Sheets[sheetName].B1 !== "undefined" && typeof workbook.Sheets[sheetName].C1 !== "undefined") {
					if (workbook.Sheets[sheetName].A1.v !== "ProcedureName" || workbook.Sheets[sheetName].B1.v !== "ProcedureCode" || workbook.Sheets[sheetName].C1.v !== "Amount") {
						toast.error("Please upload a valid CSV file.")
						document.getElementById("uploadProcedureCSVFile").value = ""
						// setIsData(true);
					} else if (json.length > 0) {
						var ProcedureFlag = 0
						for (let i = 0; i < json.length; i++) {

							if (json[i].ProcedureName == undefined) {
								ProcedureFlag = ProcedureFlag + 1
							}
						}
						if (json.length == ProcedureFlag) {
							toast.error("Atlest Add One Procedure Name")
							// setIsData(true);
							document.getElementById("uploadProcedureCSVFile").value = ""
						}
						else {
							setFile(json);
						}

					} else {
						toast.error("Procedure Name required")
						// setIsData(true);
					}
				} else {
					toast.error("Please upload a valid CSV file.")
					document.getElementById("uploadProcedureCSVFile").value = ""
					// setIsData(true);
				}
			};
			reader.readAsArrayBuffer(e.target.files[0]);

		}

	};
	//saveCsv
	const Savefunction = () => {
		if (document.getElementById('SelectSpeciality').value == "0") {
			toast.error('Speciality Required');
		} else if (!CsvfileData.length) {
			toast.error('Please select csv file');
			return '';
		}
		var saveformat = [];
		for (var i = 0; i < CsvfileData.length; i++) {
			if (document.getElementById('SelectSpeciality').value != '') {
				var CsvsaveForm = {
					pmTreatmentsMedicalSpeciality: document.getElementById('SelectSpeciality').value,
					pmHospitalTreatmentDetailsProcedureCode: CsvfileData[i].ProcedureCode === undefined ? "" : CsvfileData[i].ProcedureCode.toString(),
					pmTreatmentsName: CsvfileData[i].ProcedureName,
					pmHospitalTreatmentDetailsPrice: CsvfileData[i].Amount,
					pmHospitalTreatmentDetailsMultiplyCostWithTeeth: false,
					pmHospitalTreatmentDetailsShowTeeth: false,
					pmHospitalTreatmentDetailsConsentForm: false,
					pmHospitalTreatmentDetailsDefaultNotes: '',
					taxDetails: '',
					categoryData: [],
				};

				saveformat.push(CsvsaveForm);
			}
		}
		if (document.getElementById('SelectSpeciality').value != '0' && CsvfileData.length !== '') {
			let PostData = {
				hospitalId: localStorage.getItem("hospitalId"),
				procedureList: saveformat,

			};
			setFlag(0);
			callingAPI('commonUserFunctions/procedureBulkUpload', PostData).then((response) => {
				if (response.data.success === '1') {
					toast.success('Treatment Procedure Saved Successfully');
					window.location.href = "/settings/treatmentProcedure"
					// getTreatmentProcedureListingDataValues();
					setFlag(1);

				}
				else {
					toast.error('Error')
				}
			});
		}
	};
	function selectSpeciality() {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('commonUserFunctions/doctorsMedicineBranch', postdata)
			.then((res) => {
				setSpeciality(res.data.result);
				if (res.data.result.length > 1 || res.data.result.length == 1) {

					for (let i = 0; i < res.data.result.length; i++) {
						// array.push(speciality[i].medicineBranchId == 4?'true':'false')
						if (res.data.result[i].medicineBranchId === 4) {
							setflagOption(true)

						}
						else {

							setflagOption(false)
						}



					}

				}

			})
			.catch();


	};

	//Export function
	const ExportDataValues = (props) => {
		var re
		const csvRow = [];
		// {window.sessionStorage.getItem("siteBranchId") === "4"  && (<th>Branch Name</th>)}
		if (window.sessionStorage.getItem("siteId") === "1") {
			var A = [
				// ['SlNo:', 'Speciality', Procedurehtmlvalue.props.children, Procedurecodehtmlvalue.props.children, 'Cost', Multiplyhtmlvalue.props.children, 'Status'],
				['SlNo:', 'Speciality', 'Procedure%20Name', 'Procedure%20Code', 'Branch Name', 'Cost', 'Multiply%20Cost%20With%20Tooth', 'Status'],
			];
		} else {
			var A = [
				// ['SlNo:', 'Speciality', Procedurehtmlvalue.props.children, Procedurecodehtmlvalue.props.children, 'Cost', Multiplyhtmlvalue.props.children, 'Status'],
				['SlNo:', 'Speciality', 'Procedure%20Name', 'Procedure%20Code', 'Cost', 'Multiply%20Cost%20With%20Tooth', 'Status'],
			];
		}

		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			searchKeyword: searchValue,
			selectDropdownSearchType: searchType,
			// offset: window.sessionStorage.getItem('treatmentprosedureoffset'),
			offset: 0,
			maxlimit: "",
			status: searchStatusType,
			role: window.sessionStorage.getItem('role'),
		};

		callingAPI('treatmentProcedure/treatmentProcedureListing', postdata).then((res) => {
			if (res.data.success === '1') {
				re = res.data.result.treatmentProcedureListData;

				for (var item = 0; item < re.length; item++) {
					if (window.sessionStorage.getItem("siteId") === "1") {
						A.push([
							re[item].pmTreatmentsId,
							re[item].pmTreatmentsMedicalSpeciality.replace(/[^a-zA-Z0-9/g]/g, " ").split(/\s{1,}/g).join(" %20"),
							re[item].pmTreatmentsName.replace(/[^a-zA-Z0-9/g]/g, " ").split(/\s{1,}/g).join(" %20"),
							re[item].pmHospitalTreatmentDetailsProcedureCode.replace(/[^a-zA-Z0-9/g]/g, " ").split(/\s{1,}/g).join(" %20"),
							re[item].pmTreatmentsBranchName,
							"Rs." + "" + re[item].pmHospitalTreatmentDetailsPrice,
							re[item].pmHospitalTreatmentDetailsMultiplyCostWithTeeth === false ? 'NO' : 'YES',
							re[item].pmTreatmentsIsActive === true ? 'Active' : 'InActive',
						]);
					}
					else {
						A.push([
							re[item].pmTreatmentsId,
							re[item].pmTreatmentsMedicalSpeciality.replace(/[^a-zA-Z0-9/g]/g, " ").split(/\s{1,}/g).join(" %20"),
							re[item].pmTreatmentsName.replace(/[^a-zA-Z0-9/g]/g, " ").split(/\s{1,}/g).join(" %20"),
							re[item].pmHospitalTreatmentDetailsProcedureCode.replace(/[^a-zA-Z0-9/g]/g, " ").split(/\s{1,}/g).join(" %20"),
							"Rs." + "" + re[item].pmHospitalTreatmentDetailsPrice,
							re[item].pmHospitalTreatmentDetailsMultiplyCostWithTeeth === false ? 'NO' : 'YES',
							re[item].pmTreatmentsIsActive === true ? 'Active' : 'InActive',
						]);
					}
				}

				for (var i = 0; i < A.length; ++i) {
					csvRow.push(A[i].join(','));
				}
				var csvString = csvRow.join('%0A');
				var a = document.createElement('a');
				a.href = 'data:attachment/csv,' + csvString;
				a.target = '_Blank';
				a.download = 'treatmentprocedure' + '.csv';
				document.body.appendChild(a);
				a.click();
			}
		})
	};
	const handleKeypress = (event) => {
		if (event.key === 'Enter') {
			searchClickFunction();
		}
	};
	return (
		<div className="dashboardCover">
			<ToastContainer position="bottom-right" />
			<div className="row">
				<div className="col-md-4">
					<div className="dashboardTitle">Treatment Procedure</div>
				</div>
				<div className="col-md-8">
					<div className="row" style={{ justifyContent: "flex-end" }}>
						<div className="col-md-3 dashboardSearchList">
							<select
								id="SelectSpeciality"
								value={SelectSpeciality}
								className="inputField"
								onChange={(e) => setSelectSpeciality(e.target.value)}
								style={{
									border: '1px solid #222',
									color: '#393939',
									fontFamily: 'Montserrat-SemiBold',
									fontSize: '12px',
									height: '35px'
								}}
							>
								{(speciality.length > 1) ?
									flagOption === true ?
										<><option value="0">Select Speciality</option>
											<option value="Dentistry">Dentistry</option>
											<option value="Other">Other</option></>
										:

										<><option value="Other" selected="selected">Other</option></>
									:

									flagOption === true ?
										<><option value="Dentistry" selected="selected">Dentistry</option></>
										:
										<><option value="Other" selected="selected">Other</option></>}
							</select>
						</div>
						<div class="fileUpload_upload btn_upload btn-primary" style={{ float: 'left' }}>
							<span style={{ fontFamily: 'Montserrat-SemiBold', fontSize: '12px' }}	>Browse CSV</span>
							<input
								type="file"
								id="uploadProcedureCSVFile"
								name="file1"
								onChange={(e) => handleexcelUpload(e)}
								accept=".csv,.xlsx,xls"
								class="upload_upload"
							/>
						</div>
						<div className="col-md-2 dashboardSearchList">
							<span className="dashButtonBorder" onClick={Savefunction} style={{ fontWeight: 'normal', background: "#046b29", color: "#fff", fontSize: "12px", width: "110px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} title='Save'>
								Save
							</span>
						</div>
						<div className="col-md-3">
							<span className="dashButtonBorder" style={{ fontWeight: 'normal', padding: "6px 7px" }}>
								<a href="/settings/addProcedure" style={{ color: '#000', textDecoration: 'none', fontFamily: 'Montserrat-SemiBold', fontSize: '12px' }} title='Add Procedure'>
									Add Procedure
								</a>
							</span>
						</div>
						{/* <div className="col-md-1">
							<span>
								<a>
									<img src={Youtube} />{' '}
								</a>{' '}
							</span>
						</div> */}
					</div>
				</div>
			</div>
			<br />
			{pageCount > 10 && (
				<div className="paginationSection">
					<Pagination
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={pageCount}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={paginationOnChange}
					/>
				</div>
			)}
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select
									className="inputField"
									id="searchType"
									value={searchType}
									onChange={searchTypeOnChenge}
								>
									<option value="0">Select Value</option>
									<option value="1">Procedure Name</option>
									<option value="2">Procedure Code</option>
									<option value="3">Speciality</option>
									{window.sessionStorage.getItem("siteBranchId") === "4" && (<option value="4">Branch Name</option>)}
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								{/* <input
									type="text"
									id="searchValue"
									value={searchValue}
									disabled={isEnabled}
									onChange={searchValueFunction}
									className="inputField"
									placeholder="Enter Value"
									style={{ background:searchType === "0" ? "transparent" : "white" }}
								/> */}
								{/* {searchType == "0" ? (
									<input

										type="text"
										className="inputField"
										id="searchText"
										name="searchText"
										value={searchValue}
										disabled={isEnabled}
										onChange={searchValueFunction}
										placeholder="Enter Value"
										style={{ background: "transparent" }}
									/>
								) : ( */}
								<input
									type="text"
									className="inputField"
									id="searchText"
									name="searchText"
									autoComplete='off'
									value={searchValue}
									disabled={isEnabled}
									onKeyPress={handleKeypress}
									onChange={searchValueFunction}
									autofocus="autofocus"
									style={{ background: searchType == "0" ? "transparent" : "white" }}
								/>
								{/* )} */}
							</div>
							<div className="col-md-2 dashboardSearchList">
								<select className="inputField" onKeyPress={handleKeypress} value={searchStatusType} onChange={searchStatusChange}>
									<option value="0">Status</option>
									<option value="true">Active</option>
									<option value="false">Inactive</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span className="dashButtonBorder" onClick={searchClickFunction} onKeyPress={handleKeypress} title='Search'>
									Search
								</span>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<a
									href="https://purplehealthfiles.s3.amazonaws.com/production/pulse/csv/procedure_template.csv"
									download style={{ width: "100%" }}
								>
									<button
										className="dashButtonBorder"
										// src={DownArrow}
										title="Download Template" style={{ width: "100%" }}
									// class="treatmntPlanCSV_PTour completedPlanCSV_PTour"
									>Download</button>
								</a></div>
							{treatmentproclistdata.length ? (
								<div className="col-md-2 dashboardSearchList">
									<span className="dashButtonBorder" onClick={ExportDataValues} title='Export'>
										Export
									</span>
								</div>
							) : null}
						</div>
					</div>
					{flag == 0 ? (
						<Loader />
					) : treatmentproclistdata.length > 0 ? (
						<div className="dashboardtable">
							<table className="treatmentProcedureTble">
								<thead>
									<tr>
										<th> SI.No</th>
										<th> Speciality </th>
										<th>Procedure Name</th>
										<th>Procedure Code</th>
										{window.sessionStorage.getItem("siteBranchId") === "4" && (<th>Branch Name</th>)}
										<th style={{ textAlign: "right" }}>Cost</th>
										<th>Multiply Cost with Tooth</th>
										<th>Status</th>
										<th>Actions</th>
									</tr>
								</thead>

								<tbody>
									{treatmentproclistdata.map((listItem, index) => (
										<tr>
											<td>{listItem.pmTreatmentsId}</td>
											<td>{listItem.pmTreatmentsMedicalSpeciality}</td>
											<td>{listItem.pmTreatmentsName}</td>
											<td>{listItem.pmHospitalTreatmentDetailsProcedureCode}</td>
											{window.sessionStorage.getItem("siteBranchId") === "4" && (<td>{listItem.pmTreatmentsBranchName}</td>)}
											{/* {doctorVisistMedicineTotalAmount === "" ? "0.00" : doctorVisistMedicineTotalAmount}</b> */}
											<td style={{ textAlign: "right" }}>{listItem.pmHospitalTreatmentDetailsPrice.toFixed(2)}</td>
											<td>
												{listItem.pmHospitalTreatmentDetailsMultiplyCostWithTeeth === false ? (
													'NO'
												) : (
													'YES'
												)}
											</td>
											<td>{listItem.pmTreatmentsIsActive === true ? 'ACTIVE' : 'INACTIVE'}</td>
											<td>
												<span
													onClick={() => setactivepagenumber(pageOffset, { data: listItem })}
												// to={{
												// 	pathname: '/settings/addProcedure',
												// 	state: { data: listItem },
												// }}
												>
													<img src={Editicon} title="Edit" />
												</span>
												{listItem.pmTreatmentsIsActive == false ? (
													<img
														src={removeicon}
														title="Click to Activate"
														alt=""
														width="20"
														onClick={() => {
															selectedStatusTypeChange(listItem.pmTreatmentsId, true, listItem.procedureId);
														}}
													/>
												) : (
													<img
														src={tick}
														alt=""
														title="Click to Deactivate"
														style={{ cursor: 'pointer' }}
														className="mr-2  conf-cursorpointer"
														width="20"
														onClick={() => {
															selectedStatusTypeChange(listItem.pmTreatmentsId, false, listItem.procedureId);
														}}
													/>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<p>
							<NoData />
						</p>
					)}
				</div>
				{pageCount > 10 && (
					<div className="paginationSection">
						<Pagination
							activePage={activePage}
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={pageCount}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={paginationOnChange}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
export default TreatmentProcedure;