import React, { useState, useEffect } from 'react';
import Datepicker, { CalendarContainer } from 'react-datepicker';
import { callingAPI } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import IncomeExpenseInvoice from "./Reports/incomeExpenseInvoice"
// import IncomeExpenseInvoice from './incomeExpenseInvoice';
{
	/* <Route path="/reports/incomeExpenseInvoice" component={IncomeExpenseInvoice}/> */
}
function IncomeExpense() {
	const [incomeExpenseArray, setIncomeExpenseArray] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchType, setSearchType] = useState(0);
	const [isEnabled, setIsEnabled] = useState(true);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [functionName] = useState('incomedetails');
	const [totalIncome, setTotalIncome] = useState();
	const [totalExpense, setTotalExpense] = useState();
	const [netBalance, setNetBalance] = useState();
	const [dataFlag, setDataFlag] = useState(false);
	const csvDownload = "Income Expense";
	function csvFileDownload() {
		if (incomeExpenseArray.length === 0) {
			toast.error("No Data to Export");
		}
		else {
			const csvRow = [];
			var incomeexpensivelist = [
				[
					'Date',
					'Receipt No',
					'Towards',
					'Receipt Amount',
					'Expenses',
					'Expense Amount'

				],
			];

			let PostData = {
				userId: window.sessionStorage.getItem('userId'),
				// window.sessionStorage.getItem('usercomeAndexpenseId'),
				role: window.sessionStorage.getItem('role'),
				max: 1000,
				offset: 0,
				fromDate: startDate ? formatDate(startDate) : '',
				toDate: endDate ? formatDate(endDate) : '',
				cmbSelect: searchType,
				txtInput: searchValue
			};
			callingAPI("reports/incomeExpenseNew", PostData)
				.then((response) => {

					for (var item = 0; item < response.data.result.incomeExpenseArray.length; item++) {
						let date = "%20"
						if (typeof response.data.result.incomeExpenseArray[item].date !== "undefined") {
							date = response.data.result.incomeExpenseArray[item].date.replace(/,/g, "%20").replace(/ /g, "%20")
						}
						incomeexpensivelist.push([
							date,
							"%20",
							"%20",
							"%20",
							"%20",
							"%20",
						]);
						// for both income and expense coming
						if (response.data.result.incomeExpenseArray[item].expenseRecieptNumber.length > 0 && response.data.result.incomeExpenseArray[item].incRecptArray.length > 0) {
							let recieptNo = "%20"
							let towards = "%20"
							let amount = "%20"
							let ExpenserecieptNo = "%20"
							let Expenseamount = "%20"
							let recieptNo1 = []
							let towards1 = []
							let amount1 = []
							let ExpenserecieptNo1 = []
							let Expenseamount1 = []

							for (var j = 0; j < response.data.result.incomeExpenseArray[item].incRecptArray.length; j++) {

								if (typeof response.data.result.incomeExpenseArray[item].incRecptArray[j].recieptNo !== "undefined") {
									recieptNo = response.data.result.incomeExpenseArray[item].incRecptArray[j].recieptNo
								}
								else {
									recieptNo = "%20"
								}

								if (typeof response.data.result.incomeExpenseArray[item].incTowardsArray[j].towards !== "undefined") {
									towards = response.data.result.incomeExpenseArray[item].incTowardsArray[j].towards
								}

								if (typeof response.data.result.incomeExpenseArray[item].incAmountArray[j].amount !== "undefined") {
									amount = response.data.result.incomeExpenseArray[item].incAmountArray[j].amount
								}
								recieptNo1.push(recieptNo)
								towards1.push(towards)
								amount1.push(amount)
							}

							for (var k = 0; k < response.data.result.incomeExpenseArray[item].expenseRecieptNumber.length; k++) {

								if (typeof response.data.result.incomeExpenseArray[item].expenseRecieptNumber[k].recieptNo !== "undefined") {
									ExpenserecieptNo = response.data.result.incomeExpenseArray[item].expenseRecieptNumber[k].recieptNo
								}

								if (typeof response.data.result.incomeExpenseArray[item].expenseAmount[k].amount !== "undefined") {
									Expenseamount = response.data.result.incomeExpenseArray[item].expenseAmount[k].amount
								}
								ExpenserecieptNo1.push(ExpenserecieptNo)
								Expenseamount1.push(Expenseamount)


							}

							if (recieptNo1.length >= ExpenserecieptNo1.length) {
								for (let index = 0; index < recieptNo1.length; index++) {
									incomeexpensivelist.push([
										"%20",
										recieptNo1[index],
										towards1[index],
										amount1[index],
										((index + 1) > ExpenserecieptNo1.length ? "" : ExpenserecieptNo1[index]),
										((index + 1) > ExpenserecieptNo1.length ? "" : Expenseamount1[index]),
									]);
								}
							}
							else {
								for (let index = 0; index < ExpenserecieptNo1.length; index++) {
									incomeexpensivelist.push([
										"%20",
										((index + 1) > recieptNo1.length ? "" : recieptNo1[index]),
										((index + 1) > recieptNo1.length ? "" : towards1[index]),
										((index + 1) > recieptNo1.length ? "" : amount1[index]),
										ExpenserecieptNo1[index],
										Expenseamount1[index]
									]);
								}
							}

						}
						// income only comming
						else if (response.data.result.incomeExpenseArray[item].incRecptArray.length > 0 && response.data.result.incomeExpenseArray[item].expenseRecieptNumber.length == 0) {
							for (var j = 0; j < response.data.result.incomeExpenseArray[item].incRecptArray.length; j++) {
								let recieptNo = "%20"
								if (typeof response.data.result.incomeExpenseArray[item].incRecptArray[j].recieptNo !== "undefined") {
									recieptNo = response.data.result.incomeExpenseArray[item].incRecptArray[j].recieptNo
								}
								let towards = "%20"
								if (typeof response.data.result.incomeExpenseArray[item].incTowardsArray[j].towards !== "undefined") {
									towards = response.data.result.incomeExpenseArray[item].incTowardsArray[j].towards
								}
								let amount = "%20"
								if (typeof response.data.result.incomeExpenseArray[item].incAmountArray[j].amount !== "undefined") {
									amount = response.data.result.incomeExpenseArray[item].incAmountArray[j].amount
								}

								incomeexpensivelist.push([
									"%20",
									recieptNo,
									towards,
									amount,
									"%20",
									"%20",
								]);
							}


						}
						// expense only coming`
						else if (response.data.result.incomeExpenseArray[item].incRecptArray.length == 0 && response.data.result.incomeExpenseArray[item].expenseRecieptNumber.length > 0) {
							for (var k = 0; k < response.data.result.incomeExpenseArray[item].expenseRecieptNumber.length; k++) {
								let ExpenserecieptNo = "%20"
								if (typeof response.data.result.incomeExpenseArray[item].expenseRecieptNumber[k].recieptNo !== "undefined") {
									ExpenserecieptNo = response.data.result.incomeExpenseArray[item].expenseRecieptNumber[k].recieptNo
								}
								let Expenseamount = "%20"
								if (typeof response.data.result.incomeExpenseArray[item].expenseAmount[k].amount !== "undefined") {
									Expenseamount = response.data.result.incomeExpenseArray[item].expenseAmount[k].amount
								}

								incomeexpensivelist.push([
									"%20",
									"%20",
									"%20",
									"%20",
									ExpenserecieptNo,
									Expenseamount,
								]);
							}

						}
						let income = "%20"

						if (typeof response.data.result.incomeExpenseArray[item].totalIncome !== "undefined") {
							income = response.data.result.incomeExpenseArray[item].totalIncome
						}
						let expenseamount = "%20"
						if (typeof response.data.result.incomeExpenseArray[item].totalExpense !== "undefined") {
							expenseamount = response.data.result.incomeExpenseArray[item].totalExpense
						}
						incomeexpensivelist.push([
							"%20",
							"%20",
							"Income Amount :",
							income,
							"Expenses Amount :",
							expenseamount,
						]);
						let netBal = "%20"
						if (typeof response.data.result.incomeExpenseArray[item].netBalance !== "undefined") {
							netBal = response.data.result.incomeExpenseArray[item].netBalance
						}
						incomeexpensivelist.push([
							"%20",
							"%20",
							"%20",
							"Net Balance :",
							netBal,
							"%20",
						]);

					}
					for (var i = 0; i < incomeexpensivelist.length; ++i) {
						csvRow.push(incomeexpensivelist[i].join(','));
					}
					var csvString = csvRow.join('%0A');
					var create = document.createElement('a');
					create.href = 'data:attachment/csv,' + csvString;
					// create.target = '_Blank';
					create.download = csvDownload + '.csv';
					document.body.appendChild(create);
					create.click();
				}
				)
		}
	}
	const searchTypeOnChenge = (event) => {
		if (event.target.value == 0) {
			setIsEnabled(true);
			setSearchType(event.target.value);
			setSearchValue('');
		} else {
			setSearchType(event.target.value);
			setIsEnabled(false);
		}
	};
	const searchValueFunction = (event) => {
		event.target.value =
			event.target.value.split('').length === 1 ? event.target.value.replace(/\s/, '') : event.target.value;
		setSearchValue(event.target.value);
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	const searchClickFunction = () => {
		if (startDate && endDate) {
			if (startDate > endDate) {
				toast.error('End date must be greater than start date');
				document.getElementById("todate").focus();
				return false;
			}
		}
		if (searchType !== 0 && searchValue.split('').length === 0) {
			if (searchType === '1') {
				toast.error("Enter receipt number ")
				return false;
			}
		}
		const postdata = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: 1000,
			offset: 0,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
			cmbSelect: searchType,
			txtInput: searchValue,
		};
		callingAPI('reports/incomeExpenseNew', postdata)
			.then((data) => {
				if (data.data.success === '1') {
					setIncomeExpenseArray(data.data.result.incomeExpenseArray);
					setTotalIncome(data.data.result.totalIncome);
					setTotalExpense(data.data.result.totalExpense);
					setNetBalance(data.data.result.netBalance);
				}
			})
			.catch();
	};
	useEffect(() => {
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: 1000,
			offset: 0,
			fromDate: '',
			toDate: '',
			cmbSelect: '',
			txtInput: ''
		};
		callingAPI('reports/incomeExpenseNew', PostData)
			.then((data) => {


				if (data.data.success === '1') {
					setDataFlag(true)
					setIncomeExpenseArray(data.data.result.incomeExpenseArray);
					setTotalIncome(data.data.result.totalIncome);
					setTotalExpense(data.data.result.totalExpense);
					setNetBalance(data.data.result.netBalance);
				}
				else { setDataFlag(true) }
			})
			.catch();
	}, []);

	const setReceiptExpenses = (htmlData) => {
		//  window.sessionStorage.setItem("htmlData", htmlData);
		var a = `${process.env.PUBLIC_URL}/patients/RecieptPrint/Refund/${htmlData + '|' + window.sessionStorage.getItem("doctorId") + '|' + window.localStorage.getItem('hospitalId')}`
		window.open(a, "_blank")
	};
	const setReceiptRecptno = (htmlData) => {
		// window.sessionStorage.setItem("htmlData", htmlData);
		var a = `${process.env.PUBLIC_URL}/patients/RecieptPrint/Payment/${htmlData + '|' + window.sessionStorage.getItem("doctorId") + '|' + window.localStorage.getItem('hospitalId')}`
		window.open(a, "_blank")
	};
	const FromDateClear = () => {
		setStartDate("")
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>

				</div>
			</CalendarContainer>
		);
	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	const ToDateClear = () => {
		setEndDate("")
	};

	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	return (
		<div className="dashboardCover incomeexpenseTble">
			<ToastContainer position="bottom-right" />
			<div className="dashboardTitle"> Income & Expense</div>
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select className="inputField" value={searchType} onChange={searchTypeOnChenge}>
									<option value="0">Search In</option>
									<option value="1">Receipt Number</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								{searchType == 0 ? (
									<input
										type="text"
										className="inputField"
										value={searchValue}
										disabled={isEnabled}
										style={{ background: "transparent" }}
										onChange={searchValueFunction}
									/>
								) : (
									<input
										type="text"
										className="inputField"
										value={searchValue}
										style={{ background: "white" }}
										onChange={searchValueFunction}
									/>

								)}
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div>
									<Datepicker
										required
										className="Datepicker pa2 inputField"
										maxDate={new Date()}
										placeholderText="From"
										calendarClassName="rasta-stripes"
										dateFormat="dd-MM-yyyy"
										selected={startDate}
										onChange={(date) => setStartDate(date)}
										id="fromdate" calendarContainer={MyContainer}
										onChangeRaw={handleDateChangeRaw}
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="fromdate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div>
									<Datepicker
										required
										className="Datepicker pa2 inputField"
										maxDate={new Date()}
										placeholderText="To"
										calendarClassName="rasta-stripes"
										dateFormat="dd-MM-yyyy"
										selected={endDate}
										onChange={(date) => setEndDate(date)}
										id="todate" calendarContainer={MyContainerTo}
										onChangeRaw={handleDateChangeRaw}
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="todate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span className="dashButtonBorder" onClick={searchClickFunction}>
									Search
								</span>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span
									onClick={csvFileDownload}
									className="dashButtonBorder dashButtonBorderGrey"
									style={{ border: "1px solid gainsboro", padding: "6px 5px" }}
								>
									Export
								</span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="searchCount" style={{ marginRight: '10px' }}>
								Net Balance : &#8377; {incomeExpenseArray.length == 0 ? 0 : netBalance}
							</div>
						</div>
					</div>
					{dataFlag === true ?
						<>
							{incomeExpenseArray.length > 0 ?
								<div className="dashboardtable">
									<table
										className="incomeExpnsTbl"
										cellspacing="0"
										style={{ textAlign: 'left', width: '100%', margin: '0 auto' }}
									>
										<thead style={{ borderBottom: '0px!important;' }}>
											<tr class="inFirst">
												<td
													colspan="1"
													style={{ padding: '10px', border: '0px', borderBottom: '1px solid #ddd' }}
												>
													&nbsp;
												</td>
												<td
													colspan="3"
													style={{
														padding: '10px',
														textAlign: 'right',
														color: '#22a2fd',
														fontWeight: '600',
													}}
												>
													Total Income : &#8377; {incomeExpenseArray.length == 0 ? 0 : totalIncome}
												</td>
												<td
													colspan="3"
													style={{
														padding: '10px',
														textAlign: 'right',
														color: '#22a2fd',
														fontWeight: '600',
													}}
												>
													Total Expenses : &#8377; {incomeExpenseArray.length == 0 ? 0 : totalExpense}
												</td>
											</tr>
											<tr>
												<th
													colspan="1"
													style={{
														padding: '10px',
														border: '1px solid #ddd',
														borderBottom: '1px solid #fff',
													}}
												>
													Date
												</th>
												<th colspan="3" style={{ padding: '10px', border: '1px solid #ddd' }}>
													Income
												</th>
												<th colspan="3" style={{ padding: '10px', border: '1px solid #ddd' }}>
													Expense
												</th>
											</tr>
										</thead>
										<tbody style={{ borderBottom: '0px!important;' }}>
											<tr>
												<th style={{ padding: '10px', border: '1px solid #ddd' }} />
												<th style={{ padding: '10px', border: '1px solid #ddd' }}>Receipt No</th>
												<th style={{ padding: '10px', border: '1px solid #ddd' }}>Towards</th>
												<th style={{ padding: ' 10px', border: '1px solid #ddd' }}>Receipt Amount</th>
												<th colspan="2" style={{ padding: ' 10px', border: '1px solid #ddd' }}>Expenses</th>
												<th colspan="1" style={{ padding: ' 10px', border: '1px solid #ddd' }}>Expense Amount</th>
											</tr>
										</tbody>
										{incomeExpenseArray.map((item, i) => (
											<tbody>
												<tr class="inReci">
													<React.Fragment>
														<td style={{ padding: '0 10px', border: '1px solid #ddd', fontFamily: "OpenSans-Regular", fontWeight: "600" }}>{item.date}</td>
														<td style={{ padding: '0 10px', border: '1px solid #ddd' }}>

															{item.incRecptArray.map((value, i) => (
																<>
																	{" "}
																	{value.recieptNo != "" ? (
																		<Link
																			to="#"
																			onClick={() =>
																				setReceiptRecptno(value.id)
																			} style={{
																				width: '100%', float: 'left'
																			}}
																		>
																			{" "}
																			{value.recieptNo}
																		</Link>
																	) : (
																		""
																	)}
																</>
															))}


														</td>
														<td style={{ padding: '15px 10px 0', border: '1px solid #ddd', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
															{item.incTowardsArray.map((values, i) => (
																<React.Fragment>
																	<span
																		class="elipsis"
																		title="Procedure"
																		style={{
																			color: '#000',
																			maxWidth: '136px',
																			width: 'auto !important',
																		}}
																	>
																		{values.towards}
																		{/* <br /> */}
																	</span>{' '}
																</React.Fragment>
															))}{' '}
															<br />
														</td>
														<td
															style={{
																padding: '15px 10px 0',
																border: '1px solid #ddd',
																textAlign: 'right',
															}}
														>
															{item.incAmountArray.map((valuee, i) => (
																<React.Fragment>
																	{valuee.amount.toFixed(2)}
																	<br />
																</React.Fragment>
															))}
															<br />
														</td>
														{/*  */}
													</React.Fragment>
													<React.Fragment>
														<td colspan="2" style={{ padding: '0 10px', border: '1px solid #ddd' }}>
															{item.expenseRecieptNumber.map((valuea, i) => (
																<React.Fragment>
																	{valuea.type !== 'Refund' ? (
																		<span
																			class="elipsis"
																			title="Procedure"
																			style={{
																				color: '#000',
																				maxWidth: '150px',
																				width: 'auto !important',
																			}}
																		>
																			{valuea.recieptNo}
																			{/* <br /> */}
																		</span>
																	) : (
																		<Link
																			to="#"
																			onClick={() =>
																				setReceiptExpenses(valuea.id)
																			} style={{
																				width: '100%', float: 'left'
																			}}
																			// to={{
																			// 	pathname: '/patients/RecieptPrint/Refund',
																			// 	state: {
																			// 		memberProcedurePaymentReceiptId: valuea.id,
																			// 	},
																			// }}
																			className="mr-2 tableThumbAction"
																		>
																			{valuea.recieptNo}
																		</Link>
																	)}
																	<br />
																</React.Fragment>
															))}
														</td>
														<td colspan="1"
															style={{
																padding: '0 10px',
																border: '1px solid #ddd',
																textAlign: 'right',
															}}
														>
															{item.expenseAmount.map((valuem, i) => (
																<React.Fragment>
																	{valuem.amount}
																	<br />
																</React.Fragment>
															))}
															<br />
														</td>

													</React.Fragment>

												</tr>
												<tr class="inFirst">
													<td
														style={{
															padding: '10px',
															border: '1px solid #ddd',
															textAlign: 'right',
															color: '#6b6767',
															borderRight: '0px', fontFamily: "OpenSans-Regular", fontSize: "14px", fontWeight: "600"
														}}
														colspan="3"
													>
														Income Amount :
													</td>
													<td
														style={{
															padding: '10px',
															border: '1px solid #ddd',
															textAlign: 'right',
															color: '#000',
															borderLeft: '0px',
															fontWeight: '600',
														}}
													>
														&#8377; {item.totalIncome.toFixed(2)
															.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
													</td>
													<td colspan="2"
														style={{
															padding: ' 10px',
															border: '1px solid #ddd',
															textAlign: 'right',
															color: '#6b6767',
															borderRight: '0px',
															fontWeight: '600',
														}}
													>
														Expenses Amount :
													</td>
													<td
														style={{
															padding: '10px',
															border: '1px solid #ddd',
															textAlign: 'right',
															color: '#000',
															borderLeft: '0px',
															fontWeight: '600',
														}}
													>
														&#8377; {item.totalExpense.toFixed(2)
															.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
													</td>
												</tr>
												<tr class="inReci" style={{ borderBottom: '2px solid #a6a0a0' }}>
													{item.netBalance == '0' || item.netBalance < '0' ? (
														<td
															style={{
																padding: ' 10px',
																border: '1px solid #ddd',
																textAlign: 'right',
																color: 'red',
																borderRight: '0px',
																fontWeight: 'bold',
															}}
															colspan="4"
														>
															<span style={{ color: "rgb(107, 103, 103)" }}>Net Balance : </span>{item.netBalance.toFixed(2)
																.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
														</td>
													) : (
														<td
															style={{
																padding: '10px',
																border: '1px solid #ddd',
																textAlign: 'right',
																color: '#6b6767',
																borderRight: '0px',
																fontWeight: 'bold',
															}}
															colspan="4"
														>
															Net Balance : {item.netBalance.toFixed(2)
																.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
														</td>)}
													<td
														style={{
															padding: ' 10px',
															border: '1px solid #ddd',
															textAlign: 'right',
															color: '#6b6767',
															borderLeft: '0px',
															fontWeight: 'bold',
														}}
														colspan="3"
													>

													</td>
												</tr>

											</tbody>
										))}

									</table>
								</div>
								: <NoData />}
						</>
						: <Loader />}
				</div>

			</div>
		</div>
	);
}
export default IncomeExpense;
